import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  BudgetsState,
  selectBudgetsIdsSelector,
  selectBudgetsEntitiesSelector,
  selectAllBudgetsSelector,
  selectBudgetsTotalSelector,
} from '../reducers/budget.reducer';

// Get the budgets state from the store, it used to make queries
export const selectBudgetsState = createFeatureSelector<BudgetsState>(
  'budgets',
);
export const selectAllBudgets = createSelector(
  selectBudgetsState,
  selectAllBudgetsSelector,
);
export const selectBudgetsIds = createSelector(
  selectBudgetsState,
  selectBudgetsIdsSelector,
);
export const selectBudgetsEntities = createSelector(
  selectBudgetsState,
  selectBudgetsEntitiesSelector,
);
export const selectBudgetsTotal = createSelector(
  selectBudgetsState,
  selectBudgetsTotalSelector,
);

export const selectBudgetById = (id) =>
  createSelector(selectBudgetsEntities, (entities) => {
    return entities[id];
  });

export const selectBudgetsByIDs = (ids) =>
  createSelector(selectBudgetsEntities, (entities) =>
    ids.map((id) => entities[id]),
  );

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'obfuscate',
})
export class ObfuscatePipe implements PipeTransform {
  transform(value: string, activated: boolean): any {
    if (!value) {
      return;
    }

    if (!activated) {
      return value;
    }

    const subString = value.slice(1, value.length - 1);
    const obfuscation = subString.replace(/\w/g, 'X');

    return value.replace(subString, obfuscation);
  }
}

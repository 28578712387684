import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { AgentService } from '@core/services/agent.service';
import { ClientService } from '@core/services/client.service';

@Component({
  selector: 'app-agents-list-modal',
  templateUrl: './agents-list-modal.component.html',
  styleUrls: ['./agents-list-modal.component.scss'],
})
export class AgentsListModalComponent implements OnInit {
  inputForm: UntypedFormGroup;
  agents: any = [];
  agentsOriginal: any = [];
  clients: any = [];

  @ViewChild('agentSelector') agentSelector: MatSelectionList;
  @ViewChild('clientSelector') clientSelector: MatSelectionList;

  constructor(
    private fb: UntypedFormBuilder,
    private agentService: AgentService,
    private clientService: ClientService,
    public dialogRef: MatDialogRef<AgentsListModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.inputForm = this.fb.group({
      finder: new UntypedFormControl(''),
      agentSelected: new UntypedFormControl('', [Validators.required]),
    });

    this.agentService.getAgentsNames().subscribe((agents) => {
      this.agents = agents;
      this.agentsOriginal = agents;
    });

    this.inputForm.valueChanges.subscribe(({ finder }) => {
      if (!finder || (finder && finder.length < 3)) {
        this.agents = this.agentsOriginal;

        return;
      }

      this.agents = this.agents.filter(
        (agent) =>
          agent.tradeName.toLowerCase().includes(finder.toLowerCase()) ||
          agent.contact.email.toLowerCase().includes(finder.toLowerCase()) ||
          agent.contact.phoneNumber.includes(finder),
      );
    });

    this.clientService
      .getClientsNames()
      .subscribe((clients) => (this.clients = clients));
  }

  ngAfterViewInit() {
    this.agentSelector.selectionChange.subscribe((selection) =>
      this.clientSelector.deselectAll(),
    );

    this.clientSelector.selectionChange.subscribe((selection) =>
      this.agentSelector.deselectAll(),
    );
  }

  submit() {
    if (
      this.agentSelector.selectedOptions.selected.length === 0 &&
      this.clientSelector.selectedOptions.selected.length === 0
    ) {
      return;
    }

    if (this.agentSelector.selectedOptions.selected.length > 0) {
      this.dialogRef.close({
        agentSelected: this.agentSelector.selectedOptions.selected[0]?.value,
      });

      return;
    }

    if (this.clientSelector.selectedOptions.selected.length > 0) {
      this.dialogRef.close({
        clientSelected: this.clientSelector.selectedOptions.selected[0]?.value,
      });

      return;
    }
  }
}

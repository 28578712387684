import { Component, OnInit, Input, Inject } from '@angular/core';
import { Lead, LeadInterface } from '@core/sdk';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
  UntypedFormGroup,
  AbstractControl,
} from '@angular/forms';
import { LeadService } from '@core/services/lead.service';
import { NotificationsService } from '@core/services/notifications.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { PhoneNumberPipe } from '@shared/pipes/phone-number.pipe';
import { ResponsiveService } from '@core/services/responsive.service';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
})
export class CreateProjectModalComponent implements OnInit {
  newProjectForm: UntypedFormGroup;
  steps = {
    actualStep: 1,
    step1: {
      completed: false,
    },
    step2: {
      completed: false,
    },
    step3: {
      completed: false,
    },
    step4: {
      completed: false,
    },
  };

  getObjectKeys = Object.keys;

  projectTypes = {
    'kitchen-renovation': {
      value: 'Cocina',
      slug: 'kitchen-renovation',
      icon: 'jobType:kitchen',
    },
    'bathroom-renovation': {
      value: 'Baño',
      slug: 'bathroom-renovation',
      icon: 'jobType:bathroom',
    },
    'complete-home-renovation': {
      value: 'Integral',
      slug: 'complete-home-renovation',
      icon: 'jobType:integral',
    },
    'new-build': {
      value: 'Obra nueva',
      slug: 'new-build',
      icon: 'jobType:newWork',
    },
    'bathtub-to-shower': {
      value: 'Bañera por plato ducha',
      slug: 'bathtub-to-shower',
      icon: 'jobType:plate',
    },
    others: {
      value: '',
      slug: '',
    },
  };

  disableSubmit = false;
  viewMode: string;
  showCancelView = false;

  @Input() upsertButton: string;
  @Input() title: string;

  constructor(
    private translateService: TranslateService,
    private fb: UntypedFormBuilder,
    private projectService: LeadService,
    private notificationsService: NotificationsService,
    private dialogRef: MatDialogRef<CreateProjectModalComponent>,
    private phoneNumberPipe: PhoneNumberPipe,
    private responsiveService: ResponsiveService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.initializeForm();
    this.getProjectTypeTranslations();

    this.responsiveService.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );
  }

  /**
   * It returns the project type based on the lead name.
   * @param leadName - The name of the lead.
   */
  private getProjectType(leadName) {
    if (!leadName) {
      this.projectTypes['others'].value = leadName;
      this.projectTypes['others'].slug = leadName;

      return this.projectTypes['others'];
    }

    let result = null;

    Object.entries(this.projectTypes).forEach(
      ([projectTypeKey, projectTypeValues]) => {
        if (projectTypeValues.value === leadName) {
          result = this.projectTypes[projectTypeKey];
        }
      },
    );

    if (!result) {
      this.projectTypes['others'].value = leadName;
      this.projectTypes['others'].slug = leadName;

      return this.projectTypes['others'];
    }

    return result;
  }

  /**
   * It initializes the form.
   */
  private initializeForm() {
    const { lead } = this.data;

    const defaultProjectType = lead ? this.getProjectType(lead.leadName) : '';
    const defaultDescription = lead ? lead.description : '';
    const defaultClientName = lead ? lead.contact.name : '';
    const defaultClientEmail = lead ? lead.contact.email : '';
    const defaultClientPhoneNumber = lead
      ? this.phoneNumberPipe.transform(lead.contact.phoneNumber)
      : '';
    const defaultClientAddress1 = lead ? lead.address.address1 : '';
    const defaultClientAddress2 = lead ? lead.address.address2 : '';
    const defaultClientCity = lead ? lead.address.city : '';
    const defaultClientZipCode = lead ? lead.address.zipCode : '';
    const defaultClientCountry = lead ? lead.address.country : '';
    const defaultClientRegion = lead ? lead.address.region : '';
    const defaultClientprojectState = lead ? lead.state : '';

    this.newProjectForm = this.fb.group({
      projectType: new UntypedFormControl(defaultProjectType, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
      ]),
      description: new UntypedFormControl(defaultDescription, [
        Validators.maxLength(1000),
      ]),
      clientContact: this.fb.group({
        clientName: new UntypedFormControl(defaultClientName, [
          Validators.required,
          Validators.maxLength(100),
        ]),
        clientEmail: new UntypedFormControl(defaultClientEmail, [
          Validators.email,
          Validators.maxLength(100),
        ]),
        clientPhoneNumber: new UntypedFormControl(defaultClientPhoneNumber, [
          Validators.maxLength(11),
          Validators.pattern(/^([0-9]{3} ){2}[0-9]{3}$/),
        ]),
      }),
      clientAddress: this.fb.group({
        clientAddress1: new UntypedFormControl(defaultClientAddress1, [
          Validators.required,
        ]),
        clientAddress2: new UntypedFormControl(defaultClientAddress2, []),
        clientCity: new UntypedFormControl(defaultClientCity, []),
        clientZipCode: new UntypedFormControl(defaultClientZipCode, [
          Validators.maxLength(5),
        ]),
        clientCountry: new UntypedFormControl(defaultClientCountry, [
          Validators.maxLength(100),
        ]),
        clientRegion: new UntypedFormControl(defaultClientRegion, [
          Validators.maxLength(100),
        ]),
      }),
      projectState: [defaultClientprojectState],
    });

    this.newProjectForm
      .get('projectType')
      .statusChanges.subscribe((newStatus) => {
        if (this.steps.actualStep > 1) {
          return;
        }

        this.disableSubmit = newStatus === 'INVALID';
      });

    this.newProjectForm
      .get('description')
      .statusChanges.subscribe((newStatus) => {
        if (this.steps.actualStep > 1) {
          return;
        }

        this.disableSubmit = newStatus === 'INVALID';
      });

    this.newProjectForm
      .get('clientContact')
      .statusChanges.subscribe((newStatus) => {
        if (this.steps.actualStep > 2) {
          return;
        }

        const isInvalid = newStatus === 'INVALID';

        this.steps.step2.completed = !isInvalid;
        this.disableSubmit = isInvalid;
      });

    this.newProjectForm
      .get('clientAddress')
      .statusChanges.subscribe((newStatus) => {
        if (this.steps.actualStep > 3) {
          return;
        }

        const isInvalid = newStatus === 'INVALID';

        this.steps.step3.completed = !isInvalid;
        this.disableSubmit = isInvalid;
      });

    this.disableSubmit =
      this.newProjectForm.get('projectType').status === 'INVALID';
    this.steps.step1.completed = !(
      this.newProjectForm.get('projectType').status === 'INVALID'
    );
    this.steps.step2.completed = !(
      this.newProjectForm.get('clientContact').status === 'INVALID'
    );
    this.steps.step3.completed = !(
      this.newProjectForm.get('clientAddress').status === 'INVALID'
    );
  }

  /**
   * Get the translations for the project types and store them in the projectTypes object
   */
  private getProjectTypeTranslations() {
    this.translateService
      .get(Object.keys(this.projectTypes))
      .subscribe((translations) => {
        Object.keys(translations).forEach((translationKey) => {
          this.projectTypes[translationKey]['translation'] =
            translations[translationKey];
        });
      });
  }

  /**
   * If the project type is "others", return the value of the project type. Otherwise, return the
   * translation of the project type
   * @param projectType - The project type object.
   * @returns The translation of the project type.
   */
  displayFn(projectType) {
    if (projectType.translation === 'others') {
      return projectType.value;
    }

    return projectType.translation;
  }

  /**
   * It sets the value of the projectType form control to the value of the projectType object that has
   * the same key as the projectTypeKey parameter.
   * @param projectTypeKey - The key of the project type that you want to select.
   */
  selectProjectType(projectTypeKey) {
    this.newProjectForm
      .get('projectType')
      .setValue(this.projectTypes[projectTypeKey]);
  }

  /**
   * If the control has a validator, and the validator has the required flag set to true, return true
   * @param controlName - The name of the control to validate.
   */
  requiredValidator(controlName) {
    let { validator } = this.newProjectForm.get(controlName)
      ? this.newProjectForm.get(controlName)
      : this.newProjectForm.get('clientContact').get(controlName)
      ? this.newProjectForm.get('clientContact').get(controlName)
      : this.newProjectForm.get('clientAddress').get(controlName);

    if (!validator) {
      return false;
    }

    const validation = validator({} as AbstractControl);

    if (validation && validation.required) {
      return true;
    }

    return false;
  }

  /**
   * If the user is on the second step, then the first step is marked as completed. If the user is on
   * the third step, then the second step is marked as completed. If the user is on the fourth step,
   * then the third step is marked as completed. If the user is on the fifth step, then the fourth step
   * is marked as completed
   * @returns Nothing.
   */
  saveStep() {
    this.steps.actualStep++;

    if (this.steps.actualStep === 2) {
      this.steps.step1.completed = true;
      this.checkStepValid();

      return;
    }

    if (this.steps.actualStep === 3) {
      this.steps.step2.completed = true;
      this.checkStepValid();

      return;
    }

    if (this.steps.actualStep === 4) {
      this.steps.step3.completed = true;
      this.checkStepValid();
      this.setProjectState('new');

      return;
    }

    if (this.steps.actualStep === 5) {
      this.steps.step4.completed = true;

      if (this.data.lead) {
        this.editProject();
        return;
      }

      this.createNewProject();

      return;
    }
  }

  /**
   * It creates a new Lead object with the form values.
   * @returns A Lead object.
   */
  private parseProjectFormValues() {
    const {
      projectType,
      description,
      projectState,
    } = this.newProjectForm.value;

    const {
      clientName,
      clientEmail,
      clientPhoneNumber,
    } = this.newProjectForm.get('clientContact').value;

    const {
      clientAddress1,
      clientAddress2,
      clientCity,
      clientZipCode,
      clientCountry,
      clientRegion,
    } = this.newProjectForm.get('clientAddress').value;

    const project = new Lead({
      leadName: projectType.value ? projectType.value : projectType,
      own: false,
      description,
      contact: {
        name: clientName,
        email: clientEmail,
        phoneNumber: clientPhoneNumber.replace(/\W/, '').replace('+', ''),
      },
      address: {
        address1: clientAddress1,
        address2: clientAddress2,
        city: clientCity,
        zipCode: clientZipCode,
        country: clientCountry,
        region: clientRegion,
      },
      state: projectState,
    });

    return project;
  }

  /**
   * Create a new project
   */
  createNewProject() {
    if (this.newProjectForm.invalid) {
      return;
    }

    const project = this.parseProjectFormValues();

    this.projectService
      .createNewProject(project)
      .subscribe((newProject: LeadInterface) => {
        this.notificationsService.showSuccess('Oportunidad creada.');
        this.dialogRef.close();
      });
  }

  /**
   * It updates the project in the database.
   */
  editProject() {
    const project = this.parseProjectFormValues();

    this.projectService
      .updateProject(this.data.lead.id, project)
      .subscribe((newProject: LeadInterface) => {
        this.notificationsService.showSuccess('Oportunidad editada.');
        this.dialogRef.close();
      });
  }

  /**
   * Set the project state to the given state
   * @param {string} state - The state of the project.
   */
  setProjectState(state: string) {
    this.projectState.setValue(state);
  }

  get projectState() {
    return this.newProjectForm.get('projectState');
  }

  /**
   * Go to the previous step
   * @returns Nothing.
   */
  goToBackStep() {
    if (this.steps.actualStep === 1) {
      return;
    }

    this.steps.actualStep--;
  }

  /**
   * If the user is on the last step, do nothing. Otherwise, increment the step by 1
   * @returns Nothing.
   */
  goToNextStep() {
    if (this.steps.actualStep === 4 || this.disableSubmit) {
      return;
    }

    this.steps.actualStep++;
    this.checkStepValid();
  }

  /**
   * If the actual step is 2, then disable the submit button if the client contact field is invalid. If
   * the actual step is 3, then disable the submit button if the client address field is invalid. If
   * the actual step is 4, then disable the submit button if the project state field is invalid
   */
  private checkStepValid() {
    if (this.steps.actualStep === 2) {
      this.disableSubmit =
        this.newProjectForm.get('clientContact').status === 'INVALID';

      return;
    }

    if (this.steps.actualStep === 3) {
      this.disableSubmit =
        this.newProjectForm.get('clientAddress').status === 'INVALID';

      return;
    }

    if (this.steps.actualStep === 4) {
      this.disableSubmit =
        this.newProjectForm.get('projectState').status === 'INVALID';

      return;
    }
  }

  /**
   * Toggle the cancel view
   */
  toggleCancelView() {
    this.showCancelView = !this.showCancelView;
  }
}

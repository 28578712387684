import { ErrorHandlerStoreModule } from './../error-handler/error-handler.module';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ContextFacadeService } from './context-facade.service';
import { contextReducer } from './context.reducer';
import { ContextEffects } from './context.effects';

@NgModule({
  providers: [ContextFacadeService],
  imports: [
    StoreModule.forFeature('context', contextReducer),
    EffectsModule.forFeature([ContextEffects]),
    ErrorHandlerStoreModule
  ],
})
export class ContextStoreModule {}
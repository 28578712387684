import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, timer, BehaviorSubject, forkJoin, of } from 'rxjs';
import environment from '@environment';
import { LocalStorageService } from './local-storage.service';
import { exhaustMap, tap, takeUntil, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Agent } from 'http';

@Injectable({
  providedIn: 'root',
})
export class RemindersCenterService {
  private reminders: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private remindersCenterIsToggled: BehaviorSubject<any> = new BehaviorSubject<any>(
    { toggle: false },
  );
  private unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private httpClient: HttpClient,
    private localStorage: LocalStorageService,
    private store: Store<{ agent: Agent; context: any; user: any }>,
  ) {}

  getNewReminders() {
    return this.reminders.asObservable();
  }

  private removeOneSignalUserId() {
    this.store.select('user').subscribe((user) => {
      const url = `${environment.apiBaseUrl}/api/People/${user.userId}`;

      this.httpClient
        .patch<any>(url, {
          oneSignalID: null,
        })
        .subscribe();
    });
  }

  private saveOneSignalUserID(oneSignalID) {
    this.localStorage.set('oneSignalUserID', oneSignalID);

    this.store.select('user').subscribe((user) => {
      const url = `${environment.apiBaseUrl}/api/People/${user.userId}`;

      this.httpClient
        .patch<any>(url, {
          oneSignalUserId: oneSignalID,
        })
        .subscribe();
    });
  }

  private getOneSignalUserId() {
    OneSignal.getUserId(this.saveOneSignalUserID.bind(this));
  }

  private getOneSignalEvents(isSubscribed) {
    if (!isSubscribed) {
      this.localStorage.remove('oneSignalUserID');
      this.removeOneSignalUserId();

      return;
    }

    OneSignal.push(this.getOneSignalUserId.bind(this));
  }

  private startOneSignalUserIDSubscription() {
    try {
      OneSignal.on('subscriptionChange', this.getOneSignalEvents.bind(this));
    } catch (error) {}
  }

  private loadReminders() {
    const filterHeader = {
      where: {
        closed: false,
      },
      order: 'createdAt DESC',
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    timer(0, 30000)
      .pipe(
        takeUntil(this.unsubscribe$),
        exhaustMap(() => {
          const remindersQueueID = this.localStorage.get('remindersQueueID');

          if (!remindersQueueID) {
            return of([]);
          }

          const url = `${environment.apiBaseUrl}/api/RemindersQueues/${remindersQueueID}/reminders`;
          return this.httpClient.get<any[]>(url, httpOptions);
        }),
      )
      .subscribe(
        (reminders) => {
          this.reminders.next(reminders);
        },
        () => {},
      );
  }

  startUserRemindersSubscription() {
    this.startOneSignalUserIDSubscription();

    this.loadReminders();
  }

  getToggleRemindersCenter() {
    return this.remindersCenterIsToggled.asObservable();
  }

  toggleRemindersCenter(reminders = null) {
    const { toggle } = this.remindersCenterIsToggled.getValue();

    this.remindersCenterIsToggled.next({
      toggle: !toggle,
      reminders,
    });
  }

  closeReminder(reminderID) {
    const url = `${environment.apiBaseUrl}/api/Notifications/${reminderID}`;

    return this.httpClient
      .patch<any>(url, {
        closed: true,
      })
      .pipe(tap(() => this.loadReminders()));
  }

  markReminderAsClicked(reminderID) {
    const url = `${environment.apiBaseUrl}/api/Notifications/${reminderID}`;

    return this.httpClient
      .patch<any>(url, {
        clicked: true,
      })
      .pipe(tap(() => this.loadReminders()));
  }

  markRemindersAsRead(reminders) {
    if (!reminders || reminders.length === 0) {
      return;
    }

    const calls = reminders.map((notification) => {
      const url = `${environment.apiBaseUrl}/api/Notifications/${notification.id}`;

      return this.httpClient.patch(url, {
        read: true,
      });
    });

    forkJoin(calls).subscribe(() => this.loadReminders());
  }

  // createRemindersQueue(personID) {
  //   const url = `${environment.apiBaseUrl}/api/People/${personID}/remindersQueue`;

  //   this.httpClient.post<any>(url, {}).subscribe(
  //     (remindersQueue) => this.localStorage.set('remindersQueueID', remindersQueue.id)
  //   );
  // }

  getRemindersByModelId(modelID) {
    return this.reminders.pipe(
      map((reminders) => {
        return reminders.filter(
          (notification) => notification.targetId === modelID,
        );
      }),
    );
  }
}

/* eslint-disable */
import {
  Budget,
  Lead,
  Action,
  Product,
  Event,
  Activity,
  Person,
  Position,
  Document,
} from './index';
import { Message } from './Message';

declare var Object: any;
export interface ConversationInterface {
  id?: string;
  createdAt: Date;
  updatedAt: Date;
  modelActive?: any;
  message?: Message[];
}

export class Conversation implements ConversationInterface {
  'id': string;
  'createdAt': Date;
  'updatedAt': Date;
  'modelActive': any;
  message?: Message[];
  constructor(data?: ConversationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Agent`.
   */
  public static getModelName() {
    return 'Conversation';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Agent for dynamic purposes.
   **/
  public static factory(data: ConversationInterface): Conversation {
    return new Conversation(data);
  }
}

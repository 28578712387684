import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ResponsiveService } from '@core/services/responsive.service';

@Component({
  selector: 'app-budget-sent-client-dialog',
  templateUrl: './budget-sent-client-dialog.component.html',
  styleUrls: ['./budget-sent-client-dialog.component.scss'],
})
export class BudgetSentClientDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<BudgetSentClientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private responsiveService: ResponsiveService,
  ) {}

  viewMode: string;
  avoidSendToClient = new UntypedFormControl(false);
  saveTemplates = new UntypedFormControl(false);
  email = new UntypedFormControl(this.data.email, [
    Validators.email,
    Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'),
  ]);
  own = new UntypedFormControl(this.data.own);
  comments = new UntypedFormControl(this.data.comments, [
    Validators.minLength(2),
    Validators.maxLength(2000),
  ]);

  ngOnInit() {
    this.responsiveService.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );
  }

  send() {
    const result = {
      optionSelected: this.saveTemplates.value,
      send: !this.avoidSendToClient.value,
      own: this.own.value,
      comments: this.comments.value,
    };

    if (this.own.value) {
      this.dialogRef.close({
        ...result,
        email: this.email.value,
      });

      return;
    }

    this.dialogRef.close(result);
  }
}

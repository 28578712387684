import { Permission } from "./Permission";

export interface RoleInterface {
  id: string;
  name: string;
  companyCode?: string;
  routes: string[];
  leadStates?: string[];
  permissions?: Permission[];
  roleAssignments?: any[];
  createdAt?: Date;
}

export class Role implements RoleInterface {
  'id': string;
  'name': string;
  'companyCode': string;
  'routes': string[];
  'leadStates': string[];
  'permissions': Permission[];
  'roleAssignments': any[];
  createdAt?: Date;

  constructor(data?: RoleInterface) {
    Object.assign(this, data);
  }
}
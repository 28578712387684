import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CertificationService } from '@core/services/certification.service';
import { HEADER_LINKS } from '../../core/constants';
import { Badge, Link } from '@core/sdk/models/Navigation';
import { HasPermissionService } from '@core/services/has-permission.service';
import { DocumentationService } from '@core/services/documentation.service';

@Component({
  selector: 'cub-header-link',
  templateUrl: './header-link.component.html',
  styleUrls: ['./header-link.component.scss'],
  imports: [CommonModule, MatBadgeModule, MatTooltipModule],
  standalone: true
})
export class HeaderLinkComponent implements OnInit {
  @Input() linkText: string = 'Link';
  @Input() isActive: boolean = false;
  @Input() dataCy: string;
  @Input() permission:string;
  @Output() linkClicked = new EventEmitter<void>();
  @Input() badge: Badge = { text: null, color: null, hide: 'none' };

  hasPermission: boolean = false;

  totalCertifications$ = this.certificationService.certificationCount$;
  totalDocuments$ = this.documentationService.documentationCount$;

  constructor(
    private certificationService: CertificationService, 
    private hasPermissionService: HasPermissionService,
    private documentationService: DocumentationService) {
  }

  handleToolTip() {
    if(this.linkText == 'Documentación' && this.badge?.text > 0){
      return `Tienes ${this.badge.text} documentos nuevos`
    }

    if(this.linkText ==  HEADER_LINKS.CERTIFICATIONS && this.badge?.text > 0){
        return `Tienes ${this.badge.text} certificación pendiente`
    }

    return;
  }

  ngOnInit(): void {
    this.hasPermission = this.permission ? this.hasPermissionService.checkPermission(this.permission) : true
    if (this.linkText == HEADER_LINKS.CERTIFICATIONS) {
      this.totalCertifications$
        .subscribe((result) => {
          this.badge = {
            text: result?.text,
            color: result?.color,
            hide: 'block'
          }

        })
      this.certificationService.getCertificationTotal()
    }

    if (this.linkText == 'Documentación') {
      this.totalDocuments$
        .subscribe((result: any) => {
          result?.text > 0 ? this.badge = {
            text: result?.text,
            color: result?.color,
            hide: result.hide
          } : ''
        })
      this.documentationService.getNotificableDocuments()
    }
  }

  
  handleClick(event: Event): void {
    event.preventDefault();
    this.linkClicked.emit();
  }

  handleBadgeText(){
    return this.badge.text > 0 ? this.badge?.text : null;
  }
}

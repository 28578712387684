import { createAction, props } from '@ngrx/store';
import { Certification } from '@core/sdk';
import { Update } from '@ngrx/entity';

export const getCertificationsRequestSuccess = createAction(
  '[Certifications Component] GET_CERTIFICATIONS_SUCCESS',
  props<{ payload: Certification[] }>(),
);

export const getCertificationsRequestStarted = createAction(
  '[Certifications Component] GET_CERTIFICATIONS_REQUEST_STARTED',
  props<{ payload ; certifications?: Certification[]  }>()
);

export const certificationAddAction = createAction(
  '[Certifications Component] Add',
  props<Certification>(),
);

export const certificationUpdateAction = createAction(
  '[Certifications/API] Update Certufucation',
  props<{ update: Update<Certification> }>(),
);

export const certificationsAddAction = createAction(
  '[Certifications/API] Add Certifications',
  props<{ certifications: Certification[] }>(),
);

export const certificationDeleteAction = createAction(
  '[Certifications/API] Delete Certification',
  props<{ id: string }>(),
);
export const certificationsClearAction = createAction('[Certification/API] Clear Certifications');

/* eslint-disable */

import { Role } from "./Role";
import { BannedPermission } from "./RoleWithAssignedPeople";
import { TeamInterface } from "./Team";

declare var Object: any;
export interface UserInterface {
  role?: string;
  realm?: string;
  username?: string;
  email: string;
  emailVerified?: boolean;
  id?: string;
  password?: string;
  superAdmin?: boolean;
  accessTokens?: any[];
  userId?: string;
  roles?: Role[];
  companyId?: string;
  teams?: TeamInterface[];
  bannedPermissions?: BannedPermission[];
  agentId?: string;
  clientId?: string;
}

export class User implements UserInterface {
  'realm': string;
  'username': string;
  'email': string;
  'emailVerified': boolean;
  'id': string;
  'password': string;
  'superAdmin'?: boolean;
  roles?: Role[];
  accessTokens?: any[];
  userId?: string;
  role?: string;
  companyId?: string;
  teams?: TeamInterface[];
  bannedPermissions?: BannedPermission[];

  constructor(data?: UserInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `User`.
   */
  public static getModelName() {
    return 'User';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of User for dynamic purposes.
   **/
  public static factory(data: UserInterface): User {
    return new User(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'User',
      plural: 'Users',
      path: 'Users',
      idName: 'id',
      properties: {
        realm: {
          name: 'realm',
          type: 'string',
        },
        username: {
          name: 'username',
          type: 'string',
        },
        email: {
          name: 'email',
          type: 'string',
        },
        emailVerified: {
          name: 'emailVerified',
          type: 'boolean',
        },
        id: {
          name: 'id',
          type: 'number',
        },
        password: {
          name: 'password',
          type: 'string',
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'userId',
        },
      },
    };
  }
}

import { Injectable } from '@angular/core';
import {
  BudgetItem,
  BudgetItemInterface,
  Chapter,
  ChapterInterface,
} from '../sdk';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import environment from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { addChapters } from 'src/app/store/chapter/chapter-editor.actions';
import { addBudgetItems } from 'src/app/store/budget-item/budget-item-editor.actions';
@Injectable({
  providedIn: 'root',
})
export class ChapterService {
  constructor(
    private httpClient: HttpClient,
    private store: Store
  ) {}

  createItem(chapterId: string, item: BudgetItemInterface) {
    return this.httpClient.post(
      `${environment.apiBaseUrl}/api/Chapters/${chapterId}/budgetItems`,
      item,
    );
  }

  updateItem(
    chapterId: string,
    itemId: string,
    budgetItem: BudgetItemInterface,
  ) {
    return this.httpClient.put<BudgetItem>(
      `${environment.apiBaseUrl}/api/Chapters/${chapterId}/budgetItems/${itemId}`,
      budgetItem,
    );
  }

  getChapter(chapterId: string, include?: object): Observable<Chapter> {
    const filterHeader = {
      include,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    return this.httpClient.get<Chapter>(
      `${environment.apiBaseUrl}/api/Chapters/${chapterId}`,
      httpOptions,
    );
  }

  updateChapter(chapterId: string, chapter: ChapterInterface) {
    return this.httpClient.patch<ChapterInterface>(
      `${environment.apiBaseUrl}/api/Chapters/${chapterId}`,
      chapter,
    );
  }

  deleteChapter(chapterId: string) {
    return this.httpClient.delete<ChapterInterface>(
      `${environment.apiBaseUrl}/api/Chapters/${chapterId}`,
    );
  }

  deleteChapterItem(chapterId: string, itemId: string) {
    return this.httpClient.delete<BudgetItemInterface>(
      `${environment.apiBaseUrl}/api/Chapters/${chapterId}/budgetItems/${itemId}`,
    );
  }

  getChapterItem({
    budgetItemId,
    chapterId,
  }: {
    budgetItemId: string;
    chapterId: string;
  }) {
    return this.httpClient.get<BudgetItem>(
      `${environment.apiBaseUrl}/api/Chapters/${chapterId}/budgetItems/${budgetItemId}`,
    );
  }

  getChapterItems({ chapterId }: { chapterId: string }) {
    const filterHeader = {
      include: [
        'measurements'
      ]
    };

    const headers = {
      filter: JSON.stringify(filterHeader),
    };

    const httpOptions = {
      headers: new HttpHeaders(headers),
    };

    return this.httpClient.get<BudgetItem[]>(
      `${environment.apiBaseUrl}/api/Chapters/${chapterId}/budgetItems`,
      httpOptions
    );
  }

  saveItemAsTemplateItem(agentID, product) {
    return this.httpClient.post<BudgetItemInterface>(
      `${environment.apiBaseUrl}/api/Agents/${agentID}/products`,
      product,
    );
  }

  getChapterTemplateByChapterId(chapterId) {
    return this.httpClient.get<ChapterInterface>(
      `${environment.apiBaseUrl}/api/Chapters/${chapterId}/chapterTemplate`,
    );
  }

  getChapters(budgetId: string, filter: any){
    const headers = {
      filter: JSON.stringify(filter),
    };

    const httpOptions = {
      headers: new HttpHeaders(headers),
    };

    this.httpClient.get<ChapterInterface[]>(
      `${environment.apiBaseUrl}/api/Chapters`,
      httpOptions
    ).subscribe((chapters: ChapterInterface[]) => {
      const budgetItems = [];

      chapters.forEach((chapter) =>{
        budgetItems.push(chapter.budgetItems);
      });

      const flatBudgetItems = budgetItems.flat();

      this.store.dispatch(addBudgetItems({payload: flatBudgetItems}))
      this.store.dispatch(addChapters({payload: chapters}))
    })
  }
}

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BudgetEditionApiService } from './budget-edition-api.service';
import { BudgetEditionEffects } from './budget-edition.effects';
import { budgetEditionReducer } from './budget-edition.reducer';
import { BudgetEditionFacadeService } from './budget-facade.service';

@NgModule({
  providers: [BudgetEditionFacadeService, BudgetEditionApiService],
  imports: [
    StoreModule.forFeature('budget-editor', budgetEditionReducer),
    EffectsModule.forFeature([BudgetEditionEffects]),
  ],
})
export class BudgetEditorStoreModule {}

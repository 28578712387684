import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ProjectsState,
  selectProjectsIdsSelector,
  selectProjectsEntitiesSelector,
  selectAllProjectsSelector,
  selectProjectsTotalSelector,
} from '../reducers/project.reducer';

// Get the projects state from the store, it used to make queries
export const selectProjectsState = createFeatureSelector<ProjectsState>(
  'projects',
);
export const selectAllProjects = createSelector(
  selectProjectsState,
  selectAllProjectsSelector,
);
export const selectProjectsIds = createSelector(
  selectProjectsState,
  selectProjectsIdsSelector,
);
export const selectProjectsEntities = createSelector(
  selectProjectsState,
  selectProjectsEntitiesSelector,
);
export const selectProjectsTotal = createSelector(
  selectProjectsState,
  selectProjectsTotalSelector,
);

export const selectProjectById = (id) =>
  createSelector(selectProjectsEntities, (entities) => entities[id]);

export const selectProjectsByIDs = (ids) =>
  createSelector(selectProjectsEntities, (entities) =>
    ids.map((id) => entities[id]),
  );

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { Store } from '@ngrx/store';
import { ContextUpdateBudgetAction, ContextUpdateClientAction, ContextUpdateProjectAction } from "src/app/store/context/context.actions";
import { clearEntities as clearEntitiesTracking} from "src/app/store/tracking/tracking.actions";
import { clearEntities as clearEntitiesBudgetItem } from "src/app/store/budget-item/budget-item-editor.actions";
import { clearEntities as clearEntitiesBudgetEditor } from "src/app/store/budget/budget-editor.actions";
import { clearEntities as clearEntitiesCertification } from "src/app/store/certification/certification.actions";
import { clearEntities as clearEntitiesChapterEditor } from "src/app/store/chapter/chapter-editor.actions";
import { BudgetsClearAction } from 'src/app/store/actions/budget.actions';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  constructor(private store: Store) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    // TODO Sacar estas URLS a un archivo de constantes
    
    const URLS_TO_CLEAN_CONTEXT = ['projects','templates','tracking-list'];
    if (URLS_TO_CLEAN_CONTEXT.some((url) => state.url.endsWith(`/${url}`))) {
      this.setCurrentProjectId(null);
      this.setCurrentClientId(null);

      this.clearAllEntities()
    }

    return true;
  }

  public setCurrentClientId(projectId: string | null) {  
    this.store.dispatch(ContextUpdateClientAction({currentClientId: projectId}));
  }

  public setCurrentProjectId(projectId: string | null) {  
    this.store.dispatch(ContextUpdateProjectAction({currentProjectId: projectId}));
  }

  public setCurrentBudgetId(budgetId: string | null) {
    this.store.dispatch(ContextUpdateBudgetAction({currentBudgetId: budgetId}));
  }


  private clearAllEntities(): void{
    this.store.dispatch(clearEntitiesTracking());
    this.store.dispatch(clearEntitiesBudgetItem());
    this.store.dispatch(clearEntitiesBudgetEditor());
    this.store.dispatch(clearEntitiesCertification());
    this.store.dispatch(clearEntitiesChapterEditor());
    this.store.dispatch(BudgetsClearAction());
  }
}
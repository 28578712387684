<!-- carousel -->
<div class="carousel">
  <ng-container *ngIf="slides?.length > 1">
    <button
      class="control prev"
      (click)="onPreviousClick(); $event.stopPropagation()"
    >
      <span class="arrow left"></span>
    </button>
  </ng-container>

  <ng-container *ngFor="let slide of slides; let i = index">
    <img
      *ngIf="i === currentSlide"
      [src]="slide.src"
      class="slide"
      (click)="$event.stopPropagation()"
    />
  </ng-container>

  <ng-container *ngIf="slides?.length > 1">
    <button
      class="control next"
      (click)="onNextClick(); $event.stopPropagation()"
    >
      <span class="arrow right"></span>
    </button>
  </ng-container>

  <button mat-flat-button color="primary" class="download" (click)="downloadImg($event)">
    {{ 'download-image' | translate }}
  </button>
</div>

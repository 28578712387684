import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationsService } from '@core/services/notifications.service';
import { BudgetInterface } from '@core/sdk';
import { ResponsiveService } from '@core/services/responsive.service';

@Component({
  selector: 'app-budget-template-dialog',
  templateUrl: './budget-template-dialog.component.html',
  styleUrls: ['./budget-template-dialog.component.scss'],
})
export class BudgetTemplateDialogComponent implements OnInit {
  budgetCreateOptionForm: UntypedFormGroup;
  checked = true;
  nameTemplate = new UntypedFormControl('');
  saveTemplates = new UntypedFormControl();
  budgetSelected: BudgetInterface;
  checkSelected: string = 'new';
  createDisabled = false;
  showKInput = false;
  viewMode: string;

  constructor(
    public dialogRef: MatDialogRef<BudgetTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationsService: NotificationsService,
    private fb: UntypedFormBuilder,
    private responsiveService: ResponsiveService
  ) {}

  ngOnInit() {
    this.responsiveService.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );
    this.initializeForm();
  }

  private initializeForm() {
    this.budgetCreateOptionForm = this.fb.group({
      budgetOption: new UntypedFormControl('', [Validators.required]),
    });

    this.budgetCreateOptionForm.valueChanges.subscribe(({ budgetOption }) => {
      this.data.optionSelected = budgetOption;

      this.data.budget = null;
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  save() {
    if (!this.nameTemplate.value) {
      this.notificationsService.showError(
        'Debe introducir un nombre para guardar la plantilla',
      );

      return;
    }

    const result = {
      optionSelected: this.checkSelected,
      nameTemplate: this.nameTemplate.value,
      file: this.data.file,
      indirectPercent: 0,
      industrialBenefit: 0,
      overheads: 0
    };

    this.dialogRef.close(result);
  }

  ifChecked(selected) {
    this.checkSelected = selected;
    this.budgetCreateOptionForm.get('budgetOption').setValue(selected);
    this.createDisabled = false;
  }

  private handleKBC3(evt) {
    const { result } = evt.target as any;
    const registers = result.split('~');

    if (!registers || registers.length === 0) {
      return;
    }

    let KIsDefined = false;
    this.createDisabled = false;

    for (let index = 0; index < registers.length; index++) {
      const register = registers[index];
      const [letter, emptySpace, data] = register.split('|');
      const [parts] = data ? data.split('|') : '';

      //K||3\13\6\0\16\|2\2\\-1\3\\2\2\2\-1\2\2\3\2\EUR\| (K BIEN DEFINIDO)
      //K|\2\2\3\2\2\2\2\EUR\|0| (K MAL DEFINIDO)

      if (letter === 'K') {
        if (emptySpace) {
          break;
        }

        KIsDefined = true;

        const [indirectPercent, overheads, industrialBenefit] = parts.split(
          '\\',
        );

        break;
      }
    }

    if (!KIsDefined) {
      this.showKInput = true;
      this.createDisabled = true;
    }
  }

  handleDrop(evt) {
    const files = evt.target.files;
    const file: File = files.length > 0 ? files.item(0) : null;

    if (!file) {
      return;
    }

    this.showKInput = false;

    if (!file.name.includes('.bc3')) {
      this.createDisabled = true;
      this.notificationsService.showError(
        'El archivo elegido no es de tipo BC3',
      );

      return;
    }

    this.data.file = file;

    const reader = new FileReader();

    reader.onload = this.handleKBC3.bind(this);
    reader.readAsText(files[0]);
  }
}

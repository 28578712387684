/* eslint-disable */
import { Lead, Agent, Action } from './index';

declare var Object: any;
export interface EventInterface {
  date: Date;
  observations?: string;
  id?: number;
  leadId?: number;
  agentId?: number;
  state?: string;
  stateLog?: Array<any>;
  createdAt: Date;
  updatedAt: Date;
  modelActive?: any;
  lead?: Lead;
  agent?: Agent;
  actions?: Action[];
}

export class Event implements EventInterface {
  'date': Date;
  'observations': string;
  'id': number;
  'leadId': number;
  'agentId': number;
  'state': string;
  'stateLog': Array<any>;
  'createdAt': Date;
  'updatedAt': Date;
  'modelActive': any;
  lead?: Lead;
  agent?: Agent;
  actions?: Action[];
  constructor(data?: EventInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Event`.
   */
  public static getModelName() {
    return 'Event';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Event for dynamic purposes.
   **/
  public static factory(data: EventInterface): Event {
    return new Event(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Event',
      plural: 'Events',
      path: 'Events',
      idName: 'id',
      properties: {
        date: {
          name: 'date',
          type: 'Date',
        },
        observations: {
          name: 'observations',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'number',
        },
        leadId: {
          name: 'leadId',
          type: 'number',
        },
        agentId: {
          name: 'agentId',
          type: 'number',
        },
        state: {
          name: 'state',
          type: 'string',
          default: 'pending',
        },
        stateLog: {
          name: 'stateLog',
          type: 'Array&lt;any&gt;',
        },
        createdAt: {
          name: 'createdAt',
          type: 'Date',
        },
        updatedAt: {
          name: 'updatedAt',
          type: 'Date',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
      },
      relations: {
        lead: {
          name: 'lead',
          type: 'Lead',
          model: 'Lead',
          relationType: 'belongsTo',
          keyFrom: 'leadId',
          keyTo: 'id',
        },
        agent: {
          name: 'agent',
          type: 'Agent',
          model: 'Agent',
          relationType: 'belongsTo',
          keyFrom: 'agentId',
          keyTo: 'id',
        },
        actions: {
          name: 'actions',
          type: 'Action[]',
          model: 'Action',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'referenceId',
        },
      },
    };
  }
}

<div class="header">
  <span>
    {{ note.updatedAt | date: 'shortDate':'UTC' }}
    {{ note.updatedAt | momentDate: 'shortTime':'Europe/Madrid' }}h
  </span>

  <div class="icons">
    <button mat-icon-button (click)="removeNote()" data-cy="remove-note">
      <mat-icon svgIcon="24px-delete"></mat-icon>
    </button>
  </div>
</div>

<!-- TODO: MIGRATION 16 -->
<!-- <div class="body" [innerHTML]="note.body | linky"></div> -->

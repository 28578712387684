import { Component, OnInit, Input } from '@angular/core';
import { ForgeService } from '@core/services/forge.service';
import environment from '@environment';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-forge',
  templateUrl: './forge.component.html',
  styleUrls: ['./forge.component.scss'],
})
export class ForgeComponent implements OnInit {
  private viewer: any;

  @Input('urn') urn: string;

  buckets: any[];
  objects: any[];

  production: boolean = environment.production;

  constructor(
    private forgeService: ForgeService
  ) {}

  ngOnInit() {
    if(!this.production){
      return;
    }
    
    this.initializeComponent();
  }

  /**
   * If the project has a briefing, it will be filtered to remove the commentsHousing and lowerRange
   * and topRange fields
   * @returns The return is the value of the field.
   */
  private initializeComponent() {
    if (this.urn) {
      this.selectObject(this.urn);

      return;
    }

    this.prepareAppBucketTree();
  }

  private prepareAppBucketTree() {
    // $('#appBuckets').jstree({
    //   'core': {
    //     'themes': { "icons": true },
    //     'data': {
    //       "url": '/api/forge/oss/buckets',
    //       "dataType": "json",
    //       'multiple': false,
    //       "data": function (node) {
    //         return { "id": node.id };
    //       }
    //     }
    //   },
    //   'types': {
    //     'default': {
    //       'icon': 'glyphicon glyphicon-question-sign'
    //     },
    //     '#': {
    //       'icon': 'glyphicon glyphicon-cloud'
    //     },
    //     'bucket': {
    //       'icon': 'glyphicon glyphicon-folder-open'
    //     },
    //     'object': {
    //       'icon': 'glyphicon glyphicon-file'
    //     }
    //   },
    //   "plugins": ["types", "state", "sort", "contextmenu"],
    //   contextmenu: { items: autodeskCustomMenu }
    // }).on('loaded.jstree', function () {
    //   $('#appBuckets').jstree('open_all');
    // }).bind("activate_node.jstree", function (evt, data) {
    //   if (data != null && data.node != null && data.node.type == 'object') {
    //     $("#forgeViewer").empty();
    //     var urn = data.node.id;
    //     getForgeToken(function (access_token) {
    //       jQuery.ajax({
    //         url: 'https://developer.api.autodesk.com/modelderivative/v2/designdata/' + urn + '/manifest',
    //         headers: { 'Authorization': 'Bearer ' + access_token },
    //         success: function (res) {
    //           if (res.status === 'success') launchViewer(urn);
    //           else $("#forgeViewer").html('The translation job still running: ' + res.progress + '. Please try again in a moment.');
    //         },
    //         error: function (err) {
    //           var msgButton = 'This file is not translated yet! ' +
    //             '<button class="btn btn-xs btn-info" onclick="translateObject()"><span class="glyphicon glyphicon-eye-open"></span> ' +
    //             'Start translation</button>'
    //           $("#forgeViewer").html(msgButton);
    //         }
    //       });
    //     })
    //   }
    // });

    this.forgeService.getBuckets().subscribe(
      (buckets: any[]) => {
        this.buckets = buckets;

        combineLatest([
          this.forgeService.getBuckets(this.buckets[0]?.id),
          this.forgeService.getBuckets(this.buckets[1]?.id)
        ]).subscribe(
          (objectsPrueba) => {
            this.objects = objectsPrueba.flat();
            this.selectObject(this.objects[0]?.id);
          }
        )
      }
    );
  }

  selectObject(objectId: any) {
    // const urn = objectToRender.id;
    this.forgeService.getForgeToken().subscribe(
      ({access_token}: any) => {
        this.forgeService.getDesignData(access_token, objectId).subscribe(
          (designData) => {
            console.log({designData});

            this.launchViewer(objectId);

            // 'The translation job still running: ' + res.progress + '. Please try again in a moment.');
          }
        )
      }
    );
  }

  getForgeToken(callback) {
    const url = `${environment.apiBaseUrl}/api/forge/oauth/token`;

    fetch(url).then(res => {
      res.json().then(data => {
        callback(data.access_token, data.expires_in);
      });
    });
  }

  launchViewer(urn: string) {
    var options = {
      env: 'AutodeskProduction',
      getAccessToken: this.getForgeToken
    };

    Autodesk.Viewing.Initializer(options, () => {
      this.viewer = new Autodesk.Viewing.GuiViewer3D(document.getElementById('forgeViewer'), { extensions: [ 'Autodesk.DocumentBrowser'] });

      this.viewer.start();

      var documentId = 'urn:' + urn;
      Autodesk.Viewing.Document.load(documentId, this.onDocumentLoadSuccess.bind(this), this.onDocumentLoadFailure.bind(this));
    });
  }

  onDocumentLoadSuccess(doc) {
    var viewables = doc.getRoot().getDefaultGeometry();

    this.viewer.loadDocumentNode(doc, viewables).then(i => {
      // documented loaded, any action?
    });
  }

  onDocumentLoadFailure(viewerErrorCode, viewerErrorMsg) {
    console.error('onDocumentLoadFailure() - errorCode:' + viewerErrorCode + '\n- errorMessage:' + viewerErrorMsg);
  }
}

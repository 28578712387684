import { DocumentInterface } from "../../core/sdk";

export type Folder = {
  id: number;
  title: string;
  name: string;
  isDraggable: boolean;
  isSubfolder: boolean;
  parentId?: number;
  active?: boolean;
  tooltip?: string;
  subitems?: DocumentInterface[];
  imageItems?: DocumentInterface[];
  videoItems?: DocumentInterface[];
}

export type Phase = {
  name: string;
  active: boolean;
  uploader?: string,
  tooltip?: string;
  folders: Folder[];
}

export const DOCUMENTATION_FOLDER_STRUCTURE: Phase[] = [
  //TODO: Remove first phase when old docs disappear
  {
    name: 'Antigua documentación',
    tooltip: "En esta sección se encuentra la documentación subida a la plataforma antes de la actualización",
    active: false,
    folders: [
      {id: 0, title: 'Imágenes y videos de la vivienda', name: 'images', isDraggable: false, isSubfolder: false},
      {id: 1, title: 'Planos y documentos de interés', name: 'documents', isDraggable: false, isSubfolder: false},
    ]
  },
  {
    name: 'Mi espacio',
    tooltip: "En 'Documentación previa' y 'Enlaces compartidos' puedes subir toda la documentación sobre la vivienda que creas oportuna",
    active: false,
    folders: [
      {id: 2, title: 'Mi documentación', name:'documentation', isDraggable: true, isSubfolder: false},
      {id: 3, title: 'Enlaces compartidos', name: 'links', isDraggable: false, isSubfolder: false},
    ]
  },
  {
    name:'Proyecto',
    active: false,
    folders: [
      {id: 4, title: 'Documentación legal', name:'legal', isDraggable: true, isSubfolder: false},
      {id: 5, title: 'Huella de carbono', name:'co2', isDraggable: true, isSubfolder: false},
      {id: 6, title: 'Moodboard', name:'proposal', isDraggable: true, isSubfolder: false},
      {id: 9, title: 'Hoja de pedidos', name:'orders', isDraggable: false, isSubfolder: false},
      {id: 7, title: 'Planos', name:'planimetry', isDraggable: true, isSubfolder: false},
      { id: 15, title: 'Versiones Anteriores', name: 'planimetry/versions', isDraggable: true, isSubfolder: true, parentId: 7 },
      {id: 8, title: 'Renders', name:'renders', isDraggable: true, isSubfolder: false},
      { id: 16, title: 'Versiones Anteriores', name: 'renders/versions', isDraggable: true, isSubfolder: true, parentId: 8 },
    ]
  },
  {
    name: 'Obra',
    active: false,
    folders: [
      {id: 10, title: 'Planos actualizados en obra', name:'updated-plan', isDraggable: true, isSubfolder: false },
      { id: 17, title: 'Versiones Anteriores', name: 'updated-plan/versions', isDraggable: true, isSubfolder: true, parentId: 10 },
      {id: 11, title: 'Videos de seguimiento', name:'tracking', isDraggable: true, isSubfolder: false},
      {id: 12, title: 'Acta final de obra', name:'final-repport', isDraggable: true, isSubfolder: false},
    ]
  },
  {
    name: 'CubicUP',
    active: false,
    folders: [
      {id: 13, title: 'Video visita', name:'house-video', isDraggable: true, isSubfolder: false },
      {id: 14, title: 'Magic Plan', name:'magic-plan', isDraggable: true, isSubfolder: false},
    ]
  }
];


export const getInitialFolderStructure = (): Phase[] => {
  return DOCUMENTATION_FOLDER_STRUCTURE.map(phase => {
    return {
      ...phase,
      active: false,
      folders: phase.folders.map(folder => {
        return {
          ...folder,
          active: false,
          subitems: []
        }
      })
    }
  });
}

export const getPhaseIndex = (folderName: string): number => {
  return DOCUMENTATION_FOLDER_STRUCTURE.findIndex(phase => phase.folders.some(f => f.name === folderName));
}

export const getFolderIndex = (folderName: string): number => {
  const phaseIndex = getPhaseIndex(folderName);
  return DOCUMENTATION_FOLDER_STRUCTURE[phaseIndex].folders.findIndex(f => f.name === folderName);
}

export const getFolderIndexById = (phaseIndex: number, folderId: number): number => DOCUMENTATION_FOLDER_STRUCTURE[phaseIndex].folders.findIndex(f => f.id === folderId);


export const isShoppingListFolder = (folderIndex: number, phaseIndex: number): boolean => DOCUMENTATION_FOLDER_STRUCTURE[phaseIndex].folders[folderIndex].name === 'orders';

export const isSharedLinksFolder = (folderIndex: number, phaseIndex: number): boolean => DOCUMENTATION_FOLDER_STRUCTURE[phaseIndex].folders[folderIndex].name === 'links';

import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { IMAGE_EXTENSIONS } from '@core/constants';
import { Document } from '@core/sdk';
import { CarouselService } from '@core/services/carousel.service';

@Component({
  selector: 'app-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss'],
})
export class FileDropComponent implements OnInit, OnChanges {
  dropzoneActive = false;

  @Output() fileUploaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileDeleted: EventEmitter<any> = new EventEmitter<any>();
  @Input() documents: Document[];

  carouselImages = [];

  constructor(
    private carouselService: CarouselService,
  ) {}

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.documents) {
      return;
    }

    this.documents?.forEach(
      (document) => {
        const {fileName, fileUrl} = document?.versions[0];
        const fileExtension = fileName.split('.').pop();

        if (IMAGE_EXTENSIONS.includes(fileExtension)) {
          this.carouselImages.push({src: fileUrl, name: fileName});
        }
      }
    );
  }

  dropzoneState($event: boolean) {
    this.dropzoneActive = $event;
  }

  handleDrop(fileList: FileList) {
    const files = Array.from(fileList);

    if (!files) {
      return;
    }

    files.map((file) => this.fileUploaded.emit(file));
  }

  handleRemoveFile(event) {
    this.fileDeleted.emit(event);
  }

  openCarousel(event: any, image: any) {
    event.stopPropagation();

    const carouselData = {
      images: this.carouselImages,
      imageSelected: {src: image.versions[0].fileUrl, name: image.versions[0].fileName},
    };

    this.carouselService.toggleCarousel(carouselData);
  }
}

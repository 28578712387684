import { Component, OnInit, Input } from '@angular/core';
import { CubicupStaff } from '@core/sdk';
import { ResponsiveService } from '@core/services/responsive.service';

@Component({
  selector: 'app-request-visit',
  templateUrl: './request-visit.component.html',
  styleUrls: ['./request-visit.component.scss'],
})
export class RequestVisitComponent implements OnInit {
  @Input() staff: CubicupStaff;
  @Input() hasNewMessages: boolean;

  viewMode: string;

  constructor(private responsiveService: ResponsiveService) {}

  ngOnInit() {
    this.responsiveService.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import environment from './environments/environment';
import { loadHotjar } from './assets/js/hotjar';
import { loadFacebookPixel } from './assets/js/facebook-pixel';
import { loadStripe } from './assets/js/stripe';
import { loadOneSignal } from './assets/js/one-signal';
import { loadPinterest } from './assets/js/pinterest';
import * as Sentry from "@sentry/angular-ivy";
import * as mixpanel from 'mixpanel-browser';
import * as LogRocket from 'logrocket';
import { googleAnalyticsHeadScripts } from './assets/js/google-analytics';

if (environment.production) {
  enableProdMode();
  loadHotjar();
  loadFacebookPixel();
  loadPinterest();
  initializeSentry(environment);

  LogRocket.init(environment.logRocket.appID);
}



if (environment.mixpanel.active && environment.mixpanel.projectToken) {
  mixpanel.init(environment.mixpanel.projectToken);
}

googleAnalyticsHeadScripts(environment);
loadStripe(environment);
loadOneSignal(environment);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => console.log(`${environment.name} bootstrap success`))
  .catch((err) => console.error(err));

function initializeSentry(env) {
  const {dsn, replaysSessionSampleRate, replaysOnErrorSampleRate, tracePropagationTargets} = env.sentry
  
  Sentry.init({
    dsn,
    environment: env.name,
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets,
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay()
      
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });  
}
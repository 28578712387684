<div *ngIf="project">
  <div class="title">
    <p class="text-lg">
      <span>
         <mat-icon [svgIcon]="projectIcon"></mat-icon>
         <input matInput type="text" (change)="updateLeadName($event)" value="{{project.leadName}}">
      </span>
    </p>

    <div class="scoring items-center">
      <span class="label"> {{ 'your-assesment' | translate }}: </span>

      <div class="stars">
        <ng-container *ngFor="let star of stars; let feelingStarIndex = index">
          <mat-icon
            svgIcon="{{
              feelingStarIndex >= agentFeeling ? '12px-emptyStar' : '12px-star'
            }}"
            (click)="setProjectFeeling(feelingStarIndex)"
          ></mat-icon>
        </ng-container>
      </div>
    </div>

    <div *ngIf="availableBudget">
      <div class="project-data">
        <p>Dispone de:</p>
        <span>{{ availableBudget | currency: 'EUR':'symbol':'1.0-2' }}</span>
      </div>
    </div>

    <ng-container *ngIf="project.ambientalCost">
      <div class="ambiental-cost">
        <span>{{ 'ambiental-cost' | translate }}</span>
  
        <div class="data-container">
          <div class="weight">
            <span *ngIf="!isEditableField" (click)="editableField()">{{ project.ambientalCost.weight.toFixed(2) }} T CO2 / {{ project.ambientalCost.price | currency:
              'EUR':'symbol':'1.2-2' }}</span>
              <div *ngIf="isEditableField">
                <input class="weight-input" type="text" (blur)="updateProjectCo2($event,'weight')" [placeholder]="project.ambientalCost.weight.toFixed(2)"> T CO2 /
                <input class="price-input" type="text" (blur)="updateProjectCo2($event,'price')" [placeholder]="project.ambientalCost.price | currency: 'EUR':'symbol':'1.2-2'"> €
              </div>      
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  
  <mat-divider></mat-divider>
  
  <div class="location">
    <div class="location-details">
      <div>
        <mat-icon svgIcon="24px-location"></mat-icon>
      </div>
      <div class="w-[100%] min-h-[100px]">
        <p class="location-title">{{ 'localization' | translate }}</p>
        <div *ngIf="!editLocationMode">
          <p class="location-items">{{ project.address.address1 }}</p>
          <p *ngIf="project.address.address2" class="location-items">{{ project.address.address2 }}</p>
          <p class="location-items">{{ project.address.city }}, {{ project.address.zipCode }}</p>
          <p class="location-items">{{ project.address.region }}</p>
        </div>

        <mat-form-field *ngIf="editLocationMode" appearance="outline">
          <input #locationInput (click)="selectAll(locationInput)" matInput value="{{ project.address.address1 }}"/>
        </mat-form-field>
      </div>
      <div class="flex flex-col justify-between">
        <mat-icon *ngIf="!editLocationMode" (click)="toggleLocationMode('init')" class="cursor-pointer" svgIcon="24px-edit"></mat-icon>
        <mat-icon *ngIf="editLocationMode && newAddress" (click)="toggleLocationMode('save')" class="cursor-pointer" svgIcon="24px-checkcircleOutline"></mat-icon>
        <mat-icon *ngIf="editLocationMode" (click)="toggleLocationMode('delete')" class="cursor-pointer" svgIcon="24px-close"></mat-icon>
      </div>
    </div>
  
    <div class="contact-data p-1" *ngIf="
        (project.state !== 'pending' && project.state !== 'canceled') ||
        eventStatus === 'accepted'
      ">
      <mat-icon svgIcon="24px-profile"></mat-icon>
      <div>
        <p class="location-title">{{ 'contact-data' | translate }}</p>
        <p class="primary-link">
          {{ project.client?.contact.name }}
        </p>
        <p class="primary-link">
          {{ project.client?.contact.phoneNumber }}
        </p>
        <p class="primary-link">
          {{ project.client?.contact.email }}
        </p>
      </div>
    </div>
  
    <div appMapLocation [position]="project.address.location" [address]="project.address" class="map"></div>
  </div>
  
  <ng-container *ngIf="project.own; else cubicupLead">
    <ng-container *ngIf="project.description">
      <mat-divider></mat-divider>
  
      <div class="location-description">
        <p class="location-title">{{ 'description' | translate }}</p>
        <p class="text-sm">
          {{ project.description }}
        </p>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #cubicupLead>
    <ng-container *ngIf="commentsHousing">
      <mat-divider></mat-divider>
  
      <div class="location-description">
        <p class="location-title">{{ 'client-comments' | translate }}</p>
        <p class="text-sm">
          {{ commentsHousing }}
        </p>
      </div>
    </ng-container>
  </ng-template>
  
  <div class="attached-document" *ngIf="false">
    <p class="location-title">Documentos adjuntos</p>
  </div>
  
  <ng-container *ngIf="!project.own">
    <mat-divider></mat-divider>
    <div class="project-details">
      <div class="original-briefing">
        <p class="location-title">{{ 'calculator-briefing' | translate }}</p>
  
        <ng-container *ngIf="originalBriefing?.length > 0; else originalBriefingIsQuiz">
          <div *ngFor="let field of originalBriefing" class="briefing-field">
            <p class="field-title">{{ field.description | translate }}</p>
            <ng-container [ngSwitch]="field.type">
              <p *ngSwitchCase="'int'">
                {{ isArray(field.value) ? field.value.join(', ') : field.value }}
                <ng-container [ngSwitch]="field.name">
                  <span *ngSwitchCase="'squareMeter'"> m<sup>2</sup> </span>
                </ng-container>
              </p>
  
              <p *ngSwitchCase="'stringArray'" class="label-list">
                <ng-container *ngIf="isArray(field.value)">
                  <span *ngFor="let item of field.value">{{
                    item | translate
                    }}</span>
                </ng-container>
                <ng-container *ngIf="!isArray(field.value)">
                  <span *ngFor="let item of field.value.split(',')">{{
                    item | translate
                    }}</span>
                </ng-container>
              </p>
  
              <p *ngSwitchCase="'boolean'" class="label-list">
                {{ field.value ? 'Si' : 'No' }}
              </p>
  
              <p *ngSwitchCase="'string'" class="label-list">
                {{ field.value === true ? 'Si' : field.value }}
              </p>
  
            </ng-container>
          </div>
        </ng-container>
  
        <ng-template #originalBriefingIsQuiz>
          <div *ngFor="let question of originalBriefingQuiz" class="briefing-field">
            <p class="field-title">{{ question.questionLabel | translate }}</p>
            <p class="">{{ question.responseLabel }}</p>
          </div>
        </ng-template>
      </div>
  
      <div class="checked-briefing">
        <ng-container *ngIf="checkedBriefing">
          <p class="location-title">{{ 'verify-briefing' | translate }}</p>
          <div *ngFor="let field of checkedBriefing" class="briefing-field">
            <ng-container *ngIf="field.value && field.value !== 'false'">
              <p class="field-title">{{ field.description }}</p>
  
              <ng-container [ngSwitch]="field.type">
                <p *ngSwitchCase="'int'">
                  {{ field.value }}
                  <ng-container [ngSwitch]="field.name">
                    <span *ngSwitchCase="'squareMeter'"> m<sup>2</sup> </span>
                  </ng-container>
                </p>
  
                <p *ngSwitchCase="'stringArray'" class="label-list">
                  <ng-container *ngIf="field.value">
                    <span *ngFor="let item of field.value.split(',')">{{
                      item
                      }}</span>
                  </ng-container>
                </p>
  
                <p *ngSwitchCase="'boolean'" class="label-list">
                  {{ field.value && field.value !== 'false' ? 'Si' : 'No' }}
                </p>
  
                <p *ngSwitchCase="'price'" class="label-list">
                  {{ field.value | currency: 'EUR':'symbol':'1.0-2' }}
                </p>
  
                <p *ngSwitchDefault>{{ field.value }}</p>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="originalBriefingQuestions">
  <div class="project-details">
    <div class="original-briefing">
      <p class="location-title">{{ 'description-calculator-briefing' | translate }}</p>
        <div *ngFor="let question of originalBriefingQuestions" class="briefing-field">
          <p class="field-title">{{ question.questionLabel | translate }}</p>
          <p>
            {{question.responseLabel}}
          </p>
        </div>
    </div>
  </div>
</div>
import { CONTEXTS } from '@core/constants';
import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { Agent, ClientInterface, Client, LeadInterface, Budget, Lead } from '@core/sdk';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import environment from '@environment';
import { ResponsiveService } from '@core/services/responsive.service';
import { SidenavService } from '@core/services/sidenav.service';
import { NotificationsCenterService } from '@core/services/notifications-center.service';
import { RemindersCenterService } from '@core/services/reminders-center.service';
import { Store, select } from '@ngrx/store';
import { MixpanelService } from '@core/services/mixpanel.service';
import { filter, first, take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Logout } from 'src/app/store/actions/meta.action';
import { AgentService } from '@core/services/agent.service';
import { selectUrl } from 'src/app/store/router.selectors';
import { EASY_CALENDAR_SELECTORS, EASY_CALENDAR_URL, EASY_CALENDAR_LM_URL, EASY_CALENDAR_DEVELOPMENT } from '@shared/easy-calendar/constants';
import { UtilsService } from '@shared/services/utils.service';
import * as _ from 'lodash';
import { User } from '@shared/easy-calendar/easy-calendar';
import { addObservers, fillOutUserForm } from '@shared/easy-calendar/helper';
import { LeadService } from '@core/services/lead.service';
import { StripeOnboardingState } from '@shared/types/stripe.types';
import { LocalStorageService } from '@core/services/local-storage.service';
import { ROLES } from '@core/constants';
import { CompanyService } from '@core/services/company.service';
import { AgentAddOrUpdateAction } from 'src/app/store/actions/agent.actions';
import { LoaderService } from '@core/services/loader.service';
import { BudgetService } from '@core/services/budget.service';
import { LEAD_STATES } from '@core/constants';
import { selectProjectById } from 'src/app/store/selectors/project.selectors';
import { ContextState } from 'src/app/store/context/context.reducer';
import { Link } from '@core/sdk/models/Navigation';
import { ContextFacadeService } from 'src/app/store/context/context-facade.service';
import { HasPermissionService } from '@core/services/has-permission.service';
import { ProjectUpdateAction } from 'src/app/store/actions/project.actions';
import { Update } from '@ngrx/entity';

declare const EasyCalendarParentObj;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  public links$ = this.contextFacadeService.headerLinksSubject$;

  private _showLogo = false;
  private _hideMenu = false;
  private _easyCalendarAlreadyInitialized = false;

  private contact: User;
  private styleObserver: MutationObserver;
  currentProjectId: string;
  currentProject$ = new BehaviorSubject<LeadInterface>(null);



  role: string;
  agents: Agent[];
  agentSelectedEmail: string;
  allowedRoleToSwitchAgent: boolean = false;

  filter = {};

  @ViewChild('easyCalendarDiv') easyCalendarDiv: ElementRef;
  ppv: boolean;
  ppv$: Subject<boolean> = new Subject<boolean>();

  @Input()
  set showLogo(value) {
    this._showLogo = typeof value !== 'undefined' && value !== false;
  }
  get showLogo(): boolean {
    return this._showLogo;
  }
  @Input()
  set hideMenu(value) {
    this._hideMenu = typeof value !== 'undefined' && value !== false;
  }
  get hideMenu(): boolean {
    return this._hideMenu;
  }

  miniLogoUrl = 'assets/img/logo-mini.svg';
  primaryLogoPath = 'assets/img/logo-primary-dark.svg';
  primaryLogoUrl = '';
  context: any;
  agent: Agent;
  loginClient: ClientInterface;
  client: ClientInterface;
  isMenuClosed$ = new BehaviorSubject<boolean>(true);
  mainLogoUrl$ = new BehaviorSubject<string>(this.primaryLogoPath);
  iconMode$ = new BehaviorSubject<boolean>(false);
  notifications: any[];
  reminders: any[];
  notReadNotifications: any[];
  notReadReminders: any[];
  displayLogo = true;
  viewMode: string;
  stripeOnboardingState: any;
  stripeOnboardingStateTooltip = '';
  impersonatedName = '';
  showEasyCalendarModal = false;
  showEasyCalendarCloseButton = false;
  firstTimeEasyCalendarModalShow = false;
  projectId: string;
  isMobile: boolean;
  newClientData: any;
  showRequest: boolean = false;
  unsubscriber$ = new Subject<void>();

  editShortCode: boolean = false;

  isLeroyMerlin: boolean = false;

  callMixpanel = "call-cta-used";

  hasAnnex$: Observable<boolean>;

  annexCreated$ = new BehaviorSubject<boolean>(false);

  isTrackingProject: Boolean = false;

  hasChecked : boolean = false;
  public links: Link[];
  constructor(
    private authService: AuthService,
    private responsiveService: ResponsiveService,
    private sidenavService: SidenavService,
    private notificationsCenterService: NotificationsCenterService,
    private remindersCenterService: RemindersCenterService,
    private agentService: AgentService,
    private mixpanelService: MixpanelService,
    private utilsService: UtilsService,
    private leadService: LeadService,
    private companyService: CompanyService,
    private localStorageService: LocalStorageService,
    private loader: LoaderService,
    private store: Store<{
      agent: Agent;
      client: Client;
      projects: LeadInterface;
      context: any;
      router: any;
      user:any
    }>,
    private contextStore: Store<ContextState>,
    private router: Router,
    private budgetService : BudgetService,
    private contextFacadeService: ContextFacadeService,
    private hasPermissionService: HasPermissionService
  ) {
  }

  ngOnInit() {
    this.responsiveService.mode.subscribe((mode) => {
      mode === 'web'
        ? this.mainLogoUrl$.next(this.primaryLogoPath)
        : this.mainLogoUrl$.next(this.miniLogoUrl);

      this.viewMode = mode;
    });
    this.isMobile = this.responsiveService.mobileCheck();

    combineLatest([
      this.sidenavService.getIconMode(),
      this.responsiveService.mode,
    ]).subscribe(([iconMode, responsiveMode]) => {
      this.iconMode$.next(iconMode);
      iconMode && responsiveMode === 'web'
        ? this.mainLogoUrl$.next(this.miniLogoUrl)
        : this.mainLogoUrl$.next(this.primaryLogoPath);
    });

    combineLatest([
      this.store.select('context'),
      this.store.select('user'),
      this.store.select('client')
    ])
    .pipe(
      filter(([context, user, client]) =>
        context.value !== this.context || context.currentProjectId !== this.currentProjectId)
    )
    .subscribe(([{ value: context, currentProjectId }, user, client]) => {
      if (!context) {
        return;
      }

      this.currentProjectId = currentProjectId;
      this.context = context;

      this.ppv = context === CONTEXTS.AGENT ? this.hasPermissionService.checkRole(ROLES.SALES) : client?.ppv ?? false;
      this.ppv$.next(this.ppv);

      this.showRequest = context === CONTEXTS.LOGIN_CLIENT ? this.ppv : false;

      if (this.currentProjectId) {
        this.store
          .select(selectProjectById(this.currentProjectId))
          .pipe(filter<LeadInterface>(Boolean))
          .subscribe((currentLead) => {
            this.isTrackingProject = currentLead?.tracking;
            this.currentProject$.next(currentLead);
            if(context === CONTEXTS.LOGIN_CLIENT){
              this.showRequest = this.ppv || currentLead?.state === LEAD_STATES.PENDING || currentLead?.state === LEAD_STATES.NEW;
            }
          });
      }

      this.initializeHeader();
    });

    this.isLeroyMerlin = this.localStorageService.get('userEmail')?.endsWith('@leroymerlin.es');
  }

  ngAfterViewInit() {
    if (this.context === CONTEXTS.LOGIN_CLIENT) {
      this.parseEasyCalendarIndexLink();
      return;
    }

    if (this.context === CONTEXTS.AGENT) {
      this.parseAgentEasyCalendarIndexLink();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
    this.styleObserver?.disconnect();
  }

  shouldShowLink(): boolean {
    const currentRoutePath = this.router.routerState.snapshot.url;
    return this.viewMode === 'web' ? true : !currentRoutePath.includes('logged-client/');
  }

  public goToProjectHome(): void {
    if (!this.currentProjectId) return;
    this.leadService.navigateToProjectHomeRoute();
  }
  checkOutsideEasyCalendarClick(event) {
    const isClickInsideElement = this.easyCalendarDiv.nativeElement.contains(
      event.target,
    );


    if (
      !isClickInsideElement &&
      this.showEasyCalendarModal &&
      this.firstTimeEasyCalendarModalShow
    ) {
      this.hideEasyCalendar();
    }
  }

  private parseEasyCalendarIndexLink() {
    this.store
      .select('client')
      .pipe(take(1))
      .subscribe(({ contact, ppv }) => {
        document.addEventListener(
          'click',
          this.checkOutsideEasyCalendarClick.bind(this),
        );
        this.ppv = ppv;
        const { name: clientName, email: clientEmail } = contact;
        let attribute = `${EASY_CALENDAR_URL}email=${clientEmail}&fname=${clientName}`;

        if (ppv){
            attribute = this.utilsService.isDevelopment() ? EASY_CALENDAR_DEVELOPMENT : EASY_CALENDAR_LM_URL;
            this.callMixpanel = "call-request-visit-ppv"
            if (this.isLeroyMerlin){
              this.callMixpanel = "call-request-visit-ppv-leroymerlin"
            }
        }
        const newAttribute = encodeURI(attribute);

        this.easyCalendarDiv.nativeElement.attributes[
          'data-link'
        ].value = newAttribute;

        setTimeout(() => {
          this.showEasyCalendarModal = true;
        }, 500);
      });
  }

  private async parseAgentEasyCalendarIndexLink() {
    const userInfo = await this.store.select('user').toPromise();

    if (this.hasPermissionService.checkRole(ROLES.SALES)) return;

    document.addEventListener(
      'click',
      this.checkOutsideEasyCalendarClick.bind(this),
    );

    const { name: clientName, email: clientEmail } = userInfo;
    let attribute = `${EASY_CALENDAR_URL}email=${clientEmail}&fname=${clientName}`;

    if (this.ppv) {
      attribute = EASY_CALENDAR_LM_URL;
      this.callMixpanel = "call-request-visit-ppv";

      if (this.isLeroyMerlin) {
        this.callMixpanel = "call-request-visit-ppv-leroymerlin"
      }
    }

    const newAttribute = encodeURI(attribute);

    this.easyCalendarDiv.nativeElement.attributes[
      'data-link'
    ].value = newAttribute;

    setTimeout(() => {
      this.showEasyCalendarModal = true;
    }, 500);
  }

  hideEasyCalendar() {
    this.showEasyCalendarModal = false;
    this.showEasyCalendarCloseButton = false;
  }

  private initializeHeader() {
    switch (this.context) {
      case 'client':
        this.initializeClientHeader();
        break;
      case 'login-client':
        this.notificationsCenterService.startUserNotificationsSubscription();
        this.initializeLoginClientHeader();

        break;
      case 'agent':
        this.notificationsCenterService.startUserNotificationsSubscription();
        this.initializeAgentHeader();
        break;
      default:
        break;
    }
  }

  private showStripeNotice(notice) {
    this.agentService
      .getStripeDashboardLink(this.agent.id)
      .subscribe(({ url: stripeUrl }) =>
        this.authService.openNotice({
          ...notice,
          ctaUrl: stripeUrl,
        }),
      );
  }

  private initializeAgentHeader() {
    this.store
      .select('agent', 'tradeName')
      .pipe(take(1))
      .subscribe((tradeName) => {
        if (!tradeName) {
          return;
        }

        this.impersonatedName = tradeName;
      });

    this.store
      .select('agent')
      .pipe(take(1))
      .subscribe(async (agent) => {
        if (!agent || Object.keys(agent).length === 0) {
          return;
        }

        this.agent = agent;
        this.agentSelectedEmail = agent.contact?.email;
        this.displayLogo = true;

        this.agents = await this.companyService.getCompanyAgents(agent.companyId).toPromise();

        this.agentService
          .getStripeOnboardingState()
          .subscribe((stripeOnboardingState: StripeOnboardingState) => {
            const {
              charges_enabled,
              payouts_enabled,
              verification,
              state,
            } = stripeOnboardingState;
            const mainSlug = 'stripe-state-';

            if (state) {
              return;
            }

            // // Complete
            if (
              charges_enabled &&
              payouts_enabled &&
              verification?.errors.length === 0
            ) {
              this.stripeOnboardingState = mainSlug + 'complete';

              return;
            }

            // Revision
            if (
              !payouts_enabled &&
              verification?.disabled_reason === 'under_review'
            ) {
              this.stripeOnboardingState = mainSlug + 'revision';
              this.stripeOnboardingStateTooltip =
                'stripe-state-revision-tooltip';

              return;
            }

            // Limited soon
            if (payouts_enabled && verification?.due_by) {
              this.stripeOnboardingState = mainSlug + 'limited-soon';

              const utcSeconds = verification.due_by;
              const dueBy = new Date(0); // The 0 there is the key, which sets the date to the epoch
              dueBy.setUTCSeconds(utcSeconds);

              this.stripeOnboardingStateTooltip = `La cuenta será restringida el ${dueBy.toLocaleDateString(
                'es-ES',
              )}. Haz click aquí y completa la información que se requiere de tu cuenta.`;

              this.showStripeNotice({
                title: this.stripeOnboardingState,
                subtitle: this.stripeOnboardingStateTooltip,
                ctaUrl: '/profile',
              });

              return;
            }

            if (verification?.errors.length > 0) {
              const errorsHash = [];

              verification.errors.forEach((error) =>
                errorsHash.push(error.code),
              );

              //   // Limited
              if (
                !charges_enabled &&
                !payouts_enabled &&
                errorsHash.includes('verification_failed_keyed_identity')
              ) {
                this.stripeOnboardingState = mainSlug + 'limited';
                this.stripeOnboardingStateTooltip =
                  'stripe-state-limited-tooltip';

                this.showStripeNotice({
                  title: this.stripeOnboardingState,
                  subtitle: this.stripeOnboardingStateTooltip,
                  ctaUrl: '/profile',
                });

                return;
              }

              // Enabled
              if (
                payouts_enabled &&
                !verification?.due_by &&
                errorsHash.includes('verification_failed_keyed_match')
              ) {
                this.stripeOnboardingState = mainSlug + 'enabled';
                this.stripeOnboardingStateTooltip =
                  'stripe-state-enabled-tooltip';

                return;
              }
            }
          });

        this.notificationsCenterService
          .getNewNotifications()
          .subscribe((notifications) => {
            this.notifications = [];
            this.reminders = [];

            this.notReadNotifications = [];
            this.notReadReminders = [];

            notifications.forEach((notification) => {
              if (notification.type === 'activity') {
                this.reminders.push(notification);

                if (notification.read) {
                  return;
                }

                this.notReadReminders.push(notification);
                return;
              }

              this.notifications.push(notification);

              if (!notification.read) {
                this.notReadNotifications.push(notification);
              }
            });
          });
      });
  }

  private initializeLoginClientHeader() {
    this.displayLogo = true;

    this.notificationsCenterService
      .getNewNotifications()
      .subscribe((notifications) => {
        this.notifications = [];
        this.reminders = [];
        this.notReadNotifications = [];
        this.notReadReminders = [];
        notifications.forEach((notification) => {
          if (notification.type === 'activity') {
            this.reminders.push(notification);

            if (notification.read) {
              return;
            }

            this.notReadReminders.push(notification);
            return;
          }

          this.notifications.push(notification);

          if (!notification.read) {
            this.notReadNotifications.push(notification);
          }
        });
      });

    // this.store
    //   .select('client')
    //   .pipe(take(1))
    //   .subscribe((client) => {
    //     this.loginClient = client;
    //   });

     this.leadService.getProjectsFromStore().subscribe(async (projects) => {
      if (!projects) {
        return;
      }

      const leadFromStore: any = projects;

      if(leadFromStore.ids?.length > 0){
        leadFromStore.ids.forEach((id) => {
          this.projectId = id;
        });

      }
    });

    this.store.select('client', 'contact').subscribe((contact) => {
      if (!contact) {
        return;
      }

      this.impersonatedName = `${contact.name} - ${contact.email}`;
      this.contact = contact;
    });
  }

  private initializeClientHeader() {
    this.store.select('client').subscribe((client) => {
      this.client = client;
    });
  }

  simulateBudget(simulateType) {
    if (this.ppv) {
      this.router.navigate(['estimator', 'registerClient']);

      return;
    }

    if (!this.currentProjectId) return;

    this.router.navigate(['logged-client', 'quiz', simulateType, this.currentProjectId]);
  }

  goToHelp() {
    if (this.context === CONTEXTS.CLIENT || this.context === CONTEXTS.LOGIN_CLIENT) {
      this.mixpanelService.eventEmitter('help-open', {});
      window.open(environment.helpLink.client, '_blank');
      return;
    }

    if (this.context === CONTEXTS.AGENT) {
      window.open(environment.helpLink.agent, '_blank');
      return;
    }
  }

  goToPortfolio():void {
    this.router.navigate(['landing', this.agent.id]);
  }

  logout() {
    this.authService.logout();
  }

  sidenavToggle() {
    this.sidenavService.sidenavToggle();
  }

  menuClose() {
    this.isMenuClosed$.next(true);
  }

  menuOpen() {
    this.isMenuClosed$.next(false);
  }

  iconModeToggle() {
    this.sidenavService.iconModeToggle();
  }

  toggleNotificationsCenter() {
    this.notificationsCenterService.toggleNotificationsCenter(
      this.notifications,
    );
  }

  toggleRemindersCenter() {
    this.remindersCenterService.toggleRemindersCenter(this.reminders);
  }

  goToShop() {
    window.open(environment.shopLink, '_blank');
  }

  goToProfile(){
    this.router.navigate(['profile'])
  }

  requestVisit() {
    this.mixpanelService.eventEmitter(this.callMixpanel, { personType: 'client' });

    this.showEasyCalendarCloseButton = true;
    this.firstTimeEasyCalendarModalShow = true;
    if (!this._easyCalendarAlreadyInitialized) {
      EasyCalendarParentObj.init({ type: 'inline' });
      this._easyCalendarAlreadyInitialized = true;
      this.newClientData = this.localStorageService.get('newClientData');
      _.delay(addObservers, 1000, this.ppv ? this.newClientData : this.contact, this.utilsService, this.styleObserver, EASY_CALENDAR_SELECTORS.modalHeader, fillOutUserForm);

      return;
    }

    this.showEasyCalendarModal = true;
  }

  goToLogin() {
    const url = ""
    let email = ""
    combineLatest([this.store.select(selectUrl), this.store.select('client')])
      .pipe(
        takeUntil(this.unsubscriber$),
        filter((url) => Boolean(url)),
      )
      .subscribe(([url, client]) => {
        this.store.dispatch(new Logout());
        url = url
        email = client?.contact?.email;
      });
      this.navigateToLogin(url, email);
  }

  private navigateToLogin(url: string, email: string) {
    this.router.navigate(['/login'], {
      queryParams: {
        redirectUrl: url,
        userEmail: email,
      },
    });
  }

  returnToOldAgent() {
    this.store
      .select('agent', 'fromOldAgent')
      .pipe(take(1))
      .subscribe((fromOldAgent) =>
        this.authService.impersonateAgent(fromOldAgent, false),
      );
  }
  
  async switchTo(email) {
    this.loader.enable();
    (await this.companyService.switchAgent(this.filter, email))
      .subscribe((result:any) => {
        if (!result) return;

        this.localStorageService.set('agent', result);
        this.localStorageService.set('agentId', result.id);
        this.store.dispatch(AgentAddOrUpdateAction(result));

        setTimeout(() => {
          this.refreshPage()
        }, 600);
      });
  }

  refreshPage() {
    this.router.navigate(['projects']);
  }

  handleNewAnnex(subType) {
    this.loader.enable();

    const filter = {
      where: {
          leadId: this.currentProjectId,
          subType: subType
      },
    };

    this.store
    .select(selectProjectById(this.currentProjectId))
    .pipe(
      first() //TODO: Handle first() error
    ).subscribe((currentLead) => {
      this.budgetService.getBudgetsByFilter(filter)
      .subscribe((budgetsAnnex)=>{
        this.budgetService.createDraft(currentLead, subType, budgetsAnnex['count'])
        .subscribe(async (budgetResult:Budget)=>{
            await this.budgetService.createPristineItems(budgetResult.id, subType, budgetsAnnex['count'])
            this.router.navigate(['budget-edition/', budgetResult.id]);
          })
        })
      })
  }

  public isAgent(){
    return this.context === CONTEXTS.AGENT && this.agent;
  }

  redirectTo(route: string) {
    this.router.navigate([route]);
  }

  goToDocumentation(){
    switch(this.context){
      case 'agent':
        //this.router.navigate(['documentation', this.currentProjectId]);
        this.router.navigate(['documentation2']);
        break;

      case 'login-client':
        this.mixpanelService.eventEmitter('customer-web-header-clicked-documentation', {});
        this.router.navigate(['logged-client','documentation2']);
        //this.router.navigate(['logged-client','documentation2', this.currentProjectId]);
        break;
    }
  }

  hideHeaderButton(link: string) {
    const currentRoute = this.router.url;
    return !currentRoute.includes(link);
  }

  goToBudgetsTable(){
    switch(this.context){
      case 'login-client':
        this.router.navigate(['logged-client','budgets',this.currentProjectId]);
        break;
      case 'agent':
        this.router.navigate(['budgets',this.currentProjectId]);
    }
  }

  getActiveLink(): string {
    let activeLink;
    this.links$.subscribe(links => {
      activeLink = links.find(link => link.isActive)?.slug;
    });
    return activeLink;
  }


  updateShortCode(event){

    const shortCode = event.target.value
    
    const projectToUpdate: Update<Lead> = {
            id: this.currentProjectId,
            changes: {
              shortCode
            }
        };

    this.store.dispatch(ProjectUpdateAction({ update: projectToUpdate }));
    this.editShortCode = false
  }
  

  toggleEditShortCode = () => this.editShortCode = !this.editShortCode;

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { ClientInterface } from '@core/sdk';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class PpvGuard  {
  private canActivateResolver: boolean;

  constructor(
    private router: Router,
    private clientStore: Store<{ client: ClientInterface }>,
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.clientStore.select('client').subscribe(({ppv, b2b}) => {
      this.canActivateResolver = ppv && !b2b ? false : true;
    })
    if (!this.canActivateResolver) {
      this.router.navigate(['/logged-client/estimation']);

      return false;
    }

    return true;
  }

}
<div class="dialog-component-container">
  <p mat-dialog-title>{{ data.titleLabel | translate }}</p>
  <p mat-dialog-title>{{ data.subtitleLabel | translate }}</p>
  <p mat-dialog-title>{{ data.body }}</p>
  <p *ngFor='let item of data.body'>
    Dynamo Id: {{ item.id }}<br>
    Project Code: {{ item.name }}<br>
  </p>
  <div class="actions-area" *ngIf='data.actions.length > 0'>
    <div class="buttons" *ngFor='let action of data.actions'>
      <button
        mat-stroked-button
        (click)='cta(action)'>{{ action.label | translate }}</button>
    </div>
  </div>
</div>


import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Gallery, GalleryItem, GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { DocumentInterface } from '@core/sdk';
import { MatIconModule } from '@angular/material/icon';
import { VIEW_MODES } from '@core/context/constants';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'cub-media-gallery',
  standalone: true,
  imports: [CommonModule, GalleryModule, LightboxModule, MatIconModule, MatTooltipModule],
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss']
})
export class MediaGalleryComponent implements OnInit, OnChanges {
  @Input() images: DocumentInterface[];
  @Input() videos: DocumentInterface[];
  @Input() viewMode: string;
  @Input() context: string;
  @Input() galleryMode?: 'webTemplate' | 'galleryTemplate' = 'webTemplate';
  

  @Output() removeFileEmitter?: EventEmitter<{index: number, mediaType: string}> = new EventEmitter<{index: number, mediaType: string}>();
  @Output() downloadFileEmitter?: EventEmitter<{item: any, index: number}> = new EventEmitter<{item: any, index: number}>();

  imageGallery = 'image-gallery';
  videoGallery = 'video-gallery';

  itemsGallery = 'items-gallery';
  items: GalleryItem[] = [];

  galleryForImages;
  galleryForVideos;

  constructor(public gallery: Gallery) { }

  ngOnInit(): void {
    this.configGallery();

    this.loadGalleryItems(this.images, this.videos);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.configGallery();

    this.loadGalleryItems(changes.images?.currentValue, changes.videos?.currentValue);
  }

  configGallery() {
    this.gallery.resetAll();

    this.galleryForImages = this.useGalleryMode() ? this.itemsGallery : this.imageGallery;
    this.galleryForVideos = this.useGalleryMode() ? this.itemsGallery : this.videoGallery;

    if(this.useGalleryMode()) {
      this.gallery.ref(this.itemsGallery).setConfig({
        imageSize: this.galleryMode === 'galleryTemplate' ? 'contain' : 'cover',
        thumbImageSize: 'contain',
      });

    } else {
      this.gallery.ref(this.imageGallery).setConfig({
        imageSize: 'contain'
      });

      this.gallery.ref(this.videoGallery).setConfig({
        imageSize: 'contain'
      });
    }
  }
 
  loadGalleryItems(images?: DocumentInterface[], videos?: DocumentInterface[]) {
    if(this.useGalleryMode()) {
      this.gallery.ref(this.itemsGallery).reset();
    } else {
      this.gallery.ref(this.galleryForImages).reset();
      this.gallery.ref(this.galleryForVideos).reset();
    }

    if(images){
      images.forEach((image) => {
        this.gallery.ref(this.galleryForImages).addImage({
          src: image.versions[0].fileUrl,
          thumb: image.versions[0].fileUrl,
        });
      });
    }
    
    if(videos){   
      videos.forEach((video) => {
        this.gallery.ref(this.galleryForVideos).addVideo({
          src: video.versions[0].fileUrl,
          controls: true
        });
      });  
    }

    this.items = this.useGalleryMode() ? 
      this.gallery.ref(this.itemsGallery).stateSnapshot.items : 
      [...this.gallery.ref(this.galleryForImages).stateSnapshot.items, ...this.gallery.ref(this.galleryForVideos).stateSnapshot.items];
  }

  removeImage(index: number, mediaType: string) {
    event.stopPropagation();

    this.removeFileEmitter.emit({index, mediaType});
  }

  useGalleryMode() {
    return this.viewMode === VIEW_MODES.Handset || this.galleryMode === 'galleryTemplate';
  }

  downloadFile(item: any, index: number) {
    this.downloadFileEmitter.emit({item, index});
  }

}

export type certificationStateType = 'pending' | 'canceled' | 'accepted';
export type StateLog = {
  state: certificationStateType;
  date: string;
};

export interface TrackingSummary {
  /** First card  */
  emittedCertification: {
    /** Percent  */
    totalCertificationAccepted: number;
    totalBudget: number;
  };
  /** Second card  */
  paymentPendingInvoices: {
    /** Percent  */
    totalCertificationPending: number;
    projectTotal: number;
  };
  /** Third card  */
  nextInvoice: {
    /** Percent  */
    totalNextCertificationPrice: number;
    totalRemainingBalance: number;
  };
  /** Fourth card  */
  remainingBalance: {
    /** Percent  */
    totalRemainingBalance: number;
    projectTotal: number;
  };
  /** Timeline events array  */
  certificationsMilestones: {
    state: certificationStateType;
    stateLog: StateLog | StateLog[];
    total: number;
    totalPercentageVsProject: number;
  }[];
  punchListData: PunchListDataType;
  canCertificate: boolean;
  totalAverageAdvancePercentage: number;
  projectTitle: string;
  leadClientTitle: string;
  resumePaymentMethod: {
    price: number;
    percent: number;
  };
}

export type PunchListDataType = {
  isPunchListTime: boolean;
  isPenultimateCertificationTime: boolean;
  isLastCertification: boolean;
  penultimateCertificationId: string;
  lastCertificationIsAccepted: boolean;
  metadata: {
    aheadSignalProgress: number;
    aheadSignalProgressPercentage: number;
    totalCertificatePrice: number;
    totalCertificatePricePercentage: number;
  };
  penultimateCertificationIsAccepted: boolean;
  punchListSubtractPercentage: number;
  allTrackingsPunchListAreValidated: boolean;
  //showTrackingStates: boolean;
};

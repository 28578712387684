import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  AbstractControl,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Person } from 'src/app/core/sdk';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Params } from '@angular/router';
import { AuthService } from '../../../core/services/auth.service';
import { NotificationsService } from '@core/services/notifications.service';
import { Store } from '@ngrx/store';
import {
  AuthRegisterClientAction,
  AuthRegisterClientFailureCleanAction,
} from 'src/app/store/actions/auth.actions';
import { selectQueryParams } from 'src/app/store/router.selectors';

@Component({
  selector: 'app-register-client',
  templateUrl: './register-client.component.html',
  styleUrls: ['./register-client.component.scss'],
})
export class RegisterClientComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();

  registerForm: UntypedFormGroup;
  firstCheck = false;
  sendingForm = false;
  registerButtonLabel = 'start';
  externalAgent = false;
  isFacebookLead = false;
  utmSource = {};
  hidePassword: boolean = true;
  fromQuiz = false;

  constructor(
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private store: Store<{ router: any; user: any }>,
    private notificationsService: NotificationsService,
  ) {}

  ngOnInit() {
    this.getUrlParams();
    this.initializeTranslations();
    this.handleErrors();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * The function sets the default language to Spanish and then sets the current language to Spanish
   */
  private initializeTranslations() {
    this.translateService.setDefaultLang('es_ES');
    this.translateService.use('es_ES');
  }

  /**
   * It subscribes to the user state and checks if there is an error. If there is an error, it checks
   * if the error is a server error. If it is a server error, it checks if the error is a 422 error. If
   * it is a 422 error, it checks if the error is a uniqueness error. If it is a uniqueness error, it
   * shows an error message.
   */
  private handleErrors() {
    this.store.select('user').subscribe(({ error }) => {
      if (!error) {
        return;
      }

      const { statusText, error: nestedError } = error;

      if (statusText !== 'Unknown error') {
        this.registerForm.controls.email.setErrors({ incorrect: true });
        this.registerForm.controls.email.markAsTouched();
      }

      if (nestedError) {
        const { error: subNestedError } = nestedError;

        if (subNestedError) {
          const { error: subSubNestedError } = subNestedError;

          if (subSubNestedError) {
            const { statusCode, details } = subSubNestedError;

            if (statusCode === 422 && details) {
              const {
                codes: { email, username },
              } = details;

              if (email && email[0] === 'uniqueness') {
                this.notificationsService.showError(
                  'El email introducido ya existe.',
                );
              }

              if (username && username[0] === 'uniqueness') {
                this.notificationsService.showError(
                  'El nombre de usuario introducido ya existe.',
                );
              }
            }
          }
        }
      }

      this.toggleRegisterButtonLabel();
      this.store.dispatch(AuthRegisterClientFailureCleanAction());
    });
  }

  /**
   * If the query params contain an email, initialize the form with that email
   */
  private getUrlParams() {
    this.store
      .select(selectQueryParams)
      .pipe(takeUntil(this.unsubscribe$), filter(Boolean))
      .subscribe((queryParams: Params) => {
        if (
          queryParams &&
          queryParams.utm_source &&
          queryParams.utm_medium &&
          queryParams.utm_campaign
        ) {
          const { utm_source, utm_medium, utm_campaign } = queryParams;

          this.isFacebookLead = true;
          this.utmSource = {
            utm_source,
            utm_medium,
            utm_campaign,
          };
        }

        if (queryParams && queryParams.email) {
          const { email: newUserEmail } = queryParams;

          this.initializeForm(newUserEmail);
        }
      });
  }

  /**
   * The function initializes the form with the email and phone number from the quiz if the user has
   * taken the quiz.
   * If the user has not taken the quiz, the function initializes the form with the email and phone
   * number from the user's input.
   * The function also initializes the form with the terms and conditions checkbox.
   * The function also initializes the form with the password.
   * The function also initializes the form with the username from the quiz if the user has taken the
   * quiz.
   * If the user has not taken the quiz, the function initializes the form with the username from the
   * user's input.
   * The function also initializes the form with the terms and conditions checkbox.
   * The function also initializes the form with the password.
   * The function also initializes the form with the terms and conditions checkbox.
   * The function also initializes the form with the password.
   * The function also initial
   * @param newUserEmail - The email address of the user that is registering.
   */
  private initializeForm(newUserEmail) {
    const quizAnswer = this.authService.quizResponse;
    let quizEmail;
    let quizPhone;
    let quizUserName;

    if (quizAnswer) {
      this.fromQuiz = true;
      quizEmail =
        quizAnswer.questions[quizAnswer.questions.length - 2].responseLabel;
      quizPhone =
        quizAnswer.questions[quizAnswer.questions.length - 1].responseLabel;
      quizUserName =
        quizAnswer.questions[quizAnswer.questions.length - 3].responseLabel;
    }

    this.registerForm = this.formBuilder.group({
      email: [
        quizEmail ? quizEmail : newUserEmail ? newUserEmail : '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          ),
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
      clientN: [
        quizUserName ? quizUserName : '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
        ],
      ],
      phoneNumber: [
        quizAnswer ? quizPhone : '',
        [
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
          Validators.pattern(/^([0-9]{3} ){2}[0-9]{3}$/),
        ],
      ],
      termsAndConditions: [false, Validators.required],
    });

    if (this.registerForm.get('email').value) {
      this.registerForm.get('email').disable();
    }

    this.registerForm.controls.termsAndConditions.valueChanges.subscribe(
      (newValue) => {
        if (!newValue) {
          return;
        }

        this.firstCheck = true;
      },
    );
  }

  /**
   * Toggle the label of the register button depending on whether the form is sending or not
   * @returns Nothing.
   */
  private toggleRegisterButtonLabel() {
    this.sendingForm = !this.sendingForm;

    if (!this.sendingForm) {
      this.registerForm.controls.email.enable();
      this.registerForm.controls.password.enable();
      this.registerForm.controls.phoneNumber.enable();
      this.registerForm.controls.termsAndConditions.enable();
      this.registerButtonLabel = 'create-account';

      return;
    }

    this.registerForm.controls.email.disable();
    this.registerForm.controls.password.disable();
    this.registerForm.controls.phoneNumber.disable();
    this.registerForm.controls.termsAndConditions.disable();
    this.registerButtonLabel = 'creating-account';
  }

  /**
   * If the control has a validator, and the validator has the required validator, return true
   * @param controlName - The name of the control to be validated.
   * @returns The validation object.
   */
  requiredValidator(controlName) {
    const { validator } = this.registerForm.controls[controlName];

    if (!validator) {
      return false;
    }

    const validation = validator({} as AbstractControl);

    if (validation && validation.required) {
      return true;
    }

    return false;
  }

  /**
   * It registers a client.
   * @returns Nothing.
   */
  registerClient() {
    if (
      this.registerForm.invalid ||
      !this.registerForm.controls.termsAndConditions.value
    ) {
      return;
    }

    this.toggleRegisterButtonLabel();

    const newPerson = {
      ...this.registerForm.getRawValue(),
      phoneNumber: this.registerForm.controls.phoneNumber.value
        .replace(/\s+/g, '')
        .replace('+', ''),
      name: this.registerForm.controls.clientN.value,
    };

    delete newPerson.clientN;

    const person = new Person(newPerson);

    this.store.dispatch(
      AuthRegisterClientAction({
        person,
        utmSource: this.utmSource,
        isFacebookLead: this.isFacebookLead,
      }),
    );
  }
}

<div class="header">
  <div class="left">
    <mat-icon *ngIf="data.svgIcon" [svgIcon]="data.svgIcon"></mat-icon>
    <div class="title">
      <span>{{ data.title | translate }}</span>
      <span class="subtitle">{{ data.subtitle | translate }}</span>
    </div>
  </div>
  <button mat-icon-button mat-dialog-close *ngIf="data.showCloseButton">
    <mat-icon svgIcon="24px-close"></mat-icon>
  </button>
</div>
<div class="iframe-content">
  <div>
    <iframe
      width="560"
      height="315"
      [src]="data.link | safeUrl"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  LeadInterface,
  Agent,
  PositionInterface,
  ClientInterface,
  AgentApi,
  AgentInterface,
  DocumentInterface,
  BudgetInterface,
  Document,
  Lead,
} from '@core/sdk';
import environment from '@environment';
import { of, forkJoin, timer, Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import {
  tap,
  map,
  switchMap,
  concatMap,
  take,
  catchError,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  AgentAddOrUpdateAction,
  AgentAddOrUpdateFieldAction,
} from 'src/app/store/actions/agent.actions';
import { ContextAddOrUpdateAction } from 'src/app/store/context/context.actions';
import { ProjectsAddAction } from 'src/app/store/actions/project.actions';
import * as applyFilter from 'loopback-filters';
import { StripeOnboardingState } from '@shared/types/stripe.types';
import { CONTEXTS } from '@core/constants';

@Injectable({
  providedIn: 'root',
})
export class AgentService {
  agentProfileStatus = false;
  subscribedToNotices = false;

  constructor(
    private httpClient: HttpClient,
    private localStorage: LocalStorageService,
    private localStorageService: LocalStorageService,
    private agentApi: AgentApi,
    private store: Store<{ agent: Agent; context: any; user: any }>,
  ) {}

  /**
   * It sets the agent in the store.
   * @param agent - The agent to add or update.
   */
  setAgent(agent) {
    this.store.dispatch(AgentAddOrUpdateAction(agent));
  }

  /**
   * * If there is no agent, return false.
   * * If there is an agent, but no modelActive, return false.
   * * If there is an agent, but modelActive is inactive, return false.
   * * If there is an agent, and modelActive is active, return true
   * @returns The agent is being returned.
   */
  isAgentValid() {
    return this.store.select('agent').pipe(
      map((agent) => {
        const anyAgentFound = !agent || Object.keys(agent).length === 0;

        if (anyAgentFound) {
          const agentModelActive = this.localStorageService.get(
            'agentModelActive',
          );

          if (!agentModelActive) {
            return false;
          }

          const { active } = agentModelActive;

          return active;
        }

        const anyModelActiveFound = agent && !agent.modelActive;

        if (anyModelActiveFound) {
          return false;
        }

        const agentInactive =
          agent && agent.modelActive && !agent.modelActive.active;

        if (agentInactive) {
          return false;
        }

        return true;
      }),
    );
  }

  /**
   * It gets the agent from the API and then image logo
   * @param {string} [agentID=null] - string = null, server = false
   * @param [server=false] - boolean
   * @returns The Agent model with the agent logo.
   */
  getAgent(agentID: string = null, server = false) {
    return this.store.select('agent').pipe(
      take(1),
      switchMap((agent) => {
        const agentWithValues = Object.keys(agent).length > 0;

        if (!agentID && agentWithValues && !server) {
          return of(agent);
        }

        if (agentID || !agent || !agentWithValues || server) {
          let realAgentId = agentID;

          if (!realAgentId) {
            realAgentId = this.localStorage.get('agentId');
          }

          const url = `${environment.apiBaseUrl}/api/Agents/${realAgentId}`;

          const httpOptions = {
            headers: new HttpHeaders({
              filter: JSON.stringify({
                fields: {
                  image: false,
                  portfolio: false,
                  emailWhitelist: false,
                  offices: false,

                },
              }),
            }),
          };

          return forkJoin([
            this.httpClient.get<Agent>(`${url}`, httpOptions),

            this.httpClient.get<Document[]>(`${url}/documents`, {
              headers: new HttpHeaders({
                filter: JSON.stringify({
                  where: {
                    key: 'log'
                  },
                  include: 'versions',
                }),
              }),
            }),
          ]).pipe(
            tap(
              ([agent, agentLogoDocument]: [
                Agent,
                Document[]
              ]) => {
                const { modelActive } = agent;
                const agentLogo = agentLogoDocument?.length && agentLogoDocument[0].versions?.length ? agentLogoDocument[0].versions[0] : null;

                const agentExtended = {
                  ...agent,
                  image: agentLogo ? agentLogo.fileUrl : '',
                  documents: agentLogoDocument
                };

                this.localStorageService.set('agentModelActive', modelActive);
                this.store.dispatch(
                  ContextAddOrUpdateAction({ value: 'agent' }),
                );

                this.store.dispatch(AgentAddOrUpdateAction(agentExtended));
              },
            ),
          );
        }
      }),
    );
  }

  /**
   * Get the agent server from the agent
   * @returns The agent server is being returned.
   */
  updateAgentServer() {
    return this.getAgent(null, true);
  }

  /**
   * Get the agent's landing page info
   * @param agentID - The ID of the agent you want to get the info for.
   * @returns The Agent object with the following fields:
   */
  getAgentLandingInfo(agentID) {
    const url = `${environment.apiBaseUrl}/api/Agents/${agentID}/info`;

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify({
          include: [
            { team: 'person' },
            {
              relation: 'portfolioImages',
              scope: {
                where: {
                  key: { like: 'portfolio-image' },
                },
                include: 'versions',
              },
            },
            {
              relation: 'reviews',
              scope: {
                order: 'createdAt DESC',
                include: 'authorOwner',
              },
            },
            {
              relation: 'documents',
              scope: {
                where: {
                  or: [
                    { key: 'src' },
                    { key: 'rpsrc' },
                    { key: 'rea' },
                    { key: 'ins' },
                    { key: 'rpins' },
                    { key: 'dni' },
                    { key: 'mis' },
                    { key: 'log' },
                  ],
                },
                include: 'versions',
              },
            },
          ],
        }),
      }),
    };

    return this.httpClient.get<Agent>(url, httpOptions);
  }

  /**
   * It returns the agent ID from the store.
   * @returns The agent ID.
   */
  getAgentID() {
    return this.store.select('agent').pipe(
      take(1),
      map((agent) => {
        if (!agent || Object.keys(agent).length === 0) {
          const agentID = this.localStorage.get('agentId');

          if (!agentID) {
            throw new Error(`Agent not found!`);
          }

          return agentID;
        }

        return agent.id;
      }),
      catchError(error => {
        //console.log('Caught in CatchError. Returning 0')
        return of(0);     //return from(['A','B','C'])
      })
    );
  }

  /**
   * It gets the agent ID from the store, and then makes a request to the API to get the agent's leads.
   * @param {object} filter - object = {},
   * @param {number} [page=null] - number = null
   * @returns The observable of the projects.
  */
 getAgentLeads(filter: object = {}, page: number = null, limit: number = null): Observable<Lead[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
      }),
    };

    return this.getAgentID().pipe(
      concatMap((agentID) => {
        if (!agentID) {
          return of([]);
        }

        let url = `${environment.apiBaseUrl}/api/Agents/${agentID}/leads/count`;

        return this.httpClient.get<any>(url, httpOptions).pipe(
          concatMap(({ count } ) => {

            if (count === 0) {
              return of([]);
            }

            let url = `${environment.apiBaseUrl}/api/Agents/${agentID}/leads`;

            let concurrency = 5;
            
            const projectsPerCall =  Math.ceil(count / concurrency);
            const requestsConfig = [];

            const newFilter = {
              ...filter,
              order: 'updatedAt DESC',
              limit: projectsPerCall,
            };
            
            if(!limit){
              for (let index = 0; index < concurrency; index++) {
                const newFilter = {
                  ...filter,
                  skip: projectsPerCall * index,
                  limit: projectsPerCall,
                };
  
                const httpOptions = {
                  headers: new HttpHeaders({
                    filter: JSON.stringify(newFilter),
                  }),
                };
  
                requestsConfig.push(httpOptions);
              }
            }

            if(limit){
              const newFilter = {
                ...filter,
                order: 'updatedAt DESC',
                limit,
              };
              
              const httpOptions = {
                headers: new HttpHeaders({
                  filter: JSON.stringify(newFilter),
                }),
              };
              
              requestsConfig.push(httpOptions);
            }
            
            return forkJoin(
              requestsConfig.map((urlHttpOptions) =>
                this.httpClient.get<Lead[]>(`${url}`, urlHttpOptions),
            ),
          );
        }),
        tap((projects) =>
          this.store.dispatch(
            ProjectsAddAction({ projects: projects.flat() }),
          ),
        ),
      );
    }),
  );
}
  /**
   * It gets the agent ID from the local storage and then gets the agent dashboard data from the API.
   * @returns The dashboard data.
   */
  getAgentDashboard() {
    return this.getAgentID().pipe(
      concatMap((agentID) => {
        if (!agentID) {
          return of();
        }

        const url = `${environment.apiBaseUrl}/api/Agents/${agentID}/dashboard`;

        return this.httpClient.get<any>(url);
      }),
    );
  }

  /**
   * Get all events for a given agent
   * @param [filter=null] - A filter object that specifies the query parameters.
   * @returns An Observable of the AgentEvents.
   */
  getAgentEvents(filter = null) {
    return this.getAgentID().pipe(
      concatMap((agentID) => {
        const url = `${environment.apiBaseUrl}/api/Agents/${agentID}/events`;

        if (!filter) {
          filter = {
            where: {
              and: [
                { state: { neq: 'lost' } },
                { state: { neq: 'inactive' } },
                { state: { neq: 'rejected' } },
                { 'modelActive.active': true },
              ],
            },
            excludeIfEmpty: true,
            include: {
              relation: 'lead',
              scope: {
                fields: [
                  'leadName',
                  'contact',
                  'address',
                  'state',
                  'archived',
                  'modelActive',
                  'id'
                ],
                where: {
                  and: [
                    { state: { neq: 'canceledVisit' } },
                    { state: { neq: 'canceled' } },
                    { archived: false },
                    { 'modelActive.active': true },
                  ],
                },
              },
            },
            order: 'date DESC',
          };
        }

        const httpOptions = {
          headers: new HttpHeaders({
            filter: JSON.stringify(filter),
          }),
        };

        return this.httpClient.get(url, httpOptions);
      }),
    );
  }

  /**
   * Get the event with the given ID, and return the event and the lead associated with it
   * @param eventID - The ID of the event to get.
   * @returns The agent event.
   */
  getAgentEvent(eventID) {
    return this.getAgentID().pipe(
      concatMap((agentID) => {
        const url = `${environment.apiBaseUrl}/api/Agents/${agentID}/events/${eventID}`;

        const filter = {
          where: {
            and: [
              { state: { neq: 'lost' } },
              { state: { neq: 'inactive' } },
              { state: { neq: 'rejected' } },
              { 'modelActive.active': true },
            ],
          },
          excludeIfEmpty: true,
          include: {
            relation: 'lead',
            scope: {
              fields: [
                'id',
                'leadName',
                'contact',
                'state',
                'archived',
                'modelActive',
              ],
              where: {
                and: [
                  { state: { neq: 'canceledVisit' } },
                  { state: { neq: 'canceled' } },
                  { archived: false },
                  { 'modelActive.active': true },
                ],
              },
            },
          },
        };

        const httpOptions = {
          headers: new HttpHeaders({
            filter: JSON.stringify(filter),
          }),
        };

        return this.httpClient.get(url, httpOptions);
      }),
    );
  }

  /**
   * It gets all the team members of an agent.
   * @param {string} agentId - The agent ID.
   * @param {object} filter - object
   * @returns An array of PositionInterface objects.
   */
  getAgentTeamMembers(agentId: string, filter: object) {
    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
      }),
    };

    return this.httpClient.get<PositionInterface[]>(
      `${environment.apiBaseUrl}/api/Agents/${agentId}/team`,
      httpOptions,
    );
  }

  /**
   * It creates a Stripe account for the agent.
   * @param {string} agentId - The ID of the agent to create the Stripe account for.
   * @returns The response is a JSON object with the following properties:
   */
  createStripeAccount(agentId: string) {
    const url = `${environment.apiBaseUrl}/api/Agents/${agentId}/stripe`;

    return this.httpClient.post<any>(url, {});
  }

  /**
   * It returns a promise that resolves to an object with a property called dashboardLink.
   * @param agentId - The ID of the agent you want to get the Stripe dashboard link for.
   * @returns An Observable of type any.
   */
  getStripeDashboardLink(agentId) {
    const url = `${environment.apiBaseUrl}/api/Agents/${agentId}/stripe/dashboard`;

    return this.httpClient.get<any>(url);
  }

  /**
   * It gets the state of the Stripe onboarding process for the agent.
   * @returns The agent's onboarding state.
   */
  getStripeOnboardingState(): Observable<StripeOnboardingState> | Observable<Object> {
    if(!environment.stripe.enabled) {
      return of(
        { charges_enabled: false,
          payouts_enabled: false,
        } as StripeOnboardingState
      );
    }
    return this.store.select('agent').pipe(
      take(1),
      switchMap((agent) =>
        this.httpClient.get(
          `${environment.apiBaseUrl}/api/Agents/${agent.id}/getAgentStripeState`,
        ),
      ),
    );
  }


  /**
   * If the user is an agent, then update the agent's field. Otherwise, update the user's field
   * @param field - The name of the field to update.
   * @param value - The value to update the field to.
   * @returns The return value is a `Observable` of type `any`.
   */
  updateAgentField(field, value) {
    return this.store.select('context').pipe(
      switchMap(({ value: context }) => {
        if (context === 'agent') {
          return this.getAgentID().pipe(
            switchMap((agentID) => {
              const url = `${environment.apiBaseUrl}/api/Agents/${agentID}`;
              const body = {};

              body[field] = value;

              return this.httpClient.patch<any>(url, body);
            }),
            tap((newAgent) => {
              this.store.dispatch(
                AgentAddOrUpdateFieldAction({
                  field,
                  value,
                }),
              );
            }),
          );
        }

        return this.store.select('user').pipe(
          switchMap(({ agentIdToCheck, revisionToken }) => {
            const httpOptions = {
              headers: new HttpHeaders({
                revisionToken,
              }),
            };

            const url = `${environment.apiBaseUrl}/api/Agents/${agentIdToCheck}`;
            const body = {};

            body[field] = value;

            return this.httpClient.patch<any>(url, body, httpOptions);
          }),
        );
      }),
    );
  }

  /**
   * It gets the agent ID from the user and then gets the clients for that agent.
   * @returns An Observable of an array of clients.
   */
  getAgentClients() {
    return this.getAgentID().pipe(
      map((agentID) => {
        const url = `${environment.apiBaseUrl}/api/Agents/${agentID}/clients`;

        const filter = {
          where: {
            'modelActive.active': true,
          },
        };

        const httpOptions = {
          headers: new HttpHeaders({
            filter: JSON.stringify(filter),
          }),
        };

        return this.httpClient.get<any>(url, httpOptions);
      }),
    );
  }

  /**
   * It gets the client by ID.
   * @param clientID - The ID of the client you want to get.
   * @returns The client object.
   */
  getAgentClientById(clientID) {
    return this.getAgentID().pipe(
      concatMap((agentID) => {
        const url = `${environment.apiBaseUrl}/api/Agents/${agentID}/clients/${clientID}`;

        const filter = {
          where: {
            'modelActive.active': true,
          },
        };

        const httpOptions = {
          headers: new HttpHeaders({
            filter: JSON.stringify(filter),
          }),
        };

        return this.httpClient.get<ClientInterface>(url, httpOptions);
      }),
    );
  }

  /**
   * It gets the client with the given ID from the API.
   * @param clientID - The ID of the client you want to get.
   * @returns The client object.
   */
  getAgentCubicupClientById(clientID) {
    const url = `${environment.apiBaseUrl}/api/Clients/${clientID}`;

    const filter = {
      where: {
        'modelActive.active': true,
      },
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
      }),
    };

    return this.httpClient.get<any>(url, httpOptions);
  }

  /**
   * It creates a new client for the agent.
   * @param newValues - The new values for the client.
   * @param [clientID=null] - The ID of the client to update. If null, a new client will be created.
   * @returns The observable of the client.
   */
  saveClient(newValues, clientID = null) {
    return this.getAgentID().pipe(
      map((agentID) => {
        if (clientID) {
          return this.httpClient.patch<ClientInterface>(
            `${environment.apiBaseUrl}/api/Clients/${clientID}`,
            {
              contact: newValues,
              agentId: agentID,
            },
          );
        }

        return this.httpClient.post<ClientInterface>(
          `${environment.apiBaseUrl}/api/Agents/${agentID}/clients`,
          {
            contact: newValues,
            own: true,
          },
        );
      }),
    );
  }

  /**
   * It deletes the client with the given ID.
   * @param clientID - The ID of the client to be deleted.
   * @returns The response is a JSON object with the following structure:
   */
  deleteAgentClientById(clientID) {
    const url = `${environment.apiBaseUrl}/api/Clients/${clientID}`;

    return this.httpClient.patch<any>(url, {
      'modelActive.active': false,
      'modelActive.description': 'Borrado lógico',
    });
  }

  /**
   * It checks if the agent has a valid profile.
   * @returns The observable of a boolean value.
   */
  checkAgentProfile() {
    return this.getAgentID().pipe(
      map((agentID) => {
        return this.agentApi.findById(agentID).pipe(
          switchMap((agent: AgentInterface) => {
            const {
              planSelected,
              tradeName,
              vatId,
              address,
              sinceYear,
            } = agent;
            const isValid =
              planSelected === "quality" || (tradeName && vatId && address && address.zipCode && sinceYear);

            return of(isValid);
          }),
        );
      }),
    );
  }

  /**
   * It creates a new portfolio image.
   * @param imageKey - The key of the image to be uploaded.
   * @param imageName - The name of the image.
   * @returns The observable of the newly created document.
   */
  createPortfolioImage(imageKey, imageName) {
    return this.store.select('context').pipe(
      take(1),
      switchMap(({ value: context }) => {
        if (context === 'agent') {
          const agentID = this.localStorage.get('agentId');

          return this.httpClient.post<DocumentInterface>(
            `${environment.apiBaseUrl}/api/Agents/${agentID}/portfolioImages`,
            {
              key: imageKey,
              name: imageName,
              description: '',
              valueDate: null,
              dueDate: null,
              showDates: false,
            },
          );
        }

        return this.store.select('user').pipe(
          take(1),
          switchMap(({ revisionToken, agentIdToCheck }) => {
            const agentID = agentIdToCheck;

            const httpOptions = {
              headers: new HttpHeaders({
                revisionToken: revisionToken,
              }),
            };

            return this.httpClient.post<DocumentInterface>(
              `${environment.apiBaseUrl}/api/Agents/${agentID}/portfolioImages`,
              {
                key: imageKey,
                name: imageName,
                description: '',
                valueDate: null,
                dueDate: null,
                showDates: false,
              },
              httpOptions,
            );
          }),
        );
      }),
    );
  }

  /**
   * It uploads a document to the project.
   * @param imageKey - The key of the image that was uploaded.
   * @param imageName - The name of the image file.
   * @param projectID - The ID of the project you want to upload the document to.
   * @returns The observable of the response from the server.
   */
  uploadProjectDocument(imageKey, imageName, projectID: string, path?: string, visible: boolean = true, context?: string) {
    return this.store.select('agent').pipe(
      take(1),
      switchMap((agent) => {
        return this.httpClient.post<DocumentInterface>(
          `${environment.apiBaseUrl}/api/Agents/${agent.id}/projectDocuments`,
          {
            key: encodeURI(imageKey),
            name: encodeURI(imageName),
            description: '',
            valueDate: null,
            dueDate: null,
            showDates: false,
            leadId: projectID,
            ownerType: 'Agent',
            path,
            visible,
            clientNotification: context === CONTEXTS.AGENT,
            agentNotification: context === CONTEXTS.LOGIN_CLIENT

          },
        );
      }),
    );
  }

  /**
   * It uploads a url to a project.
   * @param {string} urlName - The name of the URL.
   * @param {string} url - The URL that you want to upload to.
   * @param {string} projectID - The ID of the project you want to upload the document to.
   * @returns The observable of the http request.
   */
  uploadUrlProjectDocument(urlName: string, url: string, projectID: string, tag?: string) {
    return this.store.select('agent').pipe(
      take(1),
      switchMap((agent) => {
        return this.httpClient.post<DocumentInterface>(
          `${environment.apiBaseUrl}/api/Agents/${agent.id}/uploadUrls`,
          {
            urlName,
            url,
            targetId: projectID,
            ownerId: agent.id,
            ownerType: 'Agent',
            targetType: 'Lead',
            tag
          },
        );
      }),
    );
  }

  /**
   * It returns the project url documents for a given project id.
   * @param projectID - The ID of the project you want to get the documents for.
   * @returns An array of objects.
   */
  getProjectUrlDocuments(projectID) {
    return this.httpClient.get<any>(
      `${environment.apiBaseUrl}/api/Leads/${projectID}/project-url-documents`,
    );
  }

  getProjectUrlDocumentsWithFilter(projectID: string, filter: object) {
    const filterHeader = {
      ...filter,
    };
    
    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };


    return this.httpClient.get<any>(
      `${environment.apiBaseUrl}/api/Leads/${projectID}/project-url-documents`,
      httpOptions
    );
  }


  /**
   * It returns the tracking url documents for a given project.
   * @param projectID - The ID of the project you want to get tracking url documents for.
   */
  getTrackingUrlDocuments(projectID) {
    return this.httpClient.get<any>(
      `${environment.apiBaseUrl}/api/Leads/${projectID}/tracking-url-documents`,
    );
  }

  /**
   * Get the agent ID from local storage and use it to get the agent
   * @returns The agent object.
   */
  resetAgent() {
    const agentID = this.localStorage.get('agentId');

    return this.getAgent(agentID);
  }

  /**
   * Send a review request to the agent
   * @param  - clientName: The name of the client.
   * @returns The response is a boolean value.
   */
  sendReviewRequest({ clientName, email, phoneNumber }) {
    const agentID = this.localStorage.get('agentId');
    const url = `${environment.apiBaseUrl}/api/Agents/${agentID}/reviewRequest`;

    return this.httpClient.post<any>(url, { clientName, email, phoneNumber });
  }

  /**
   * Send a review response to the agent
   * @param token - The token that was returned by the login API.
   * @param agentID - The ID of the agent that the review is for.
   * @param userEmail - The email of the user who is submitting the review.
   * @param review - The review that the user is responding to.
   * @returns The response is a JSON object with the following properties:
   */
  sendReviewResponse(token, agentID, userEmail, review) {
    const url = `${environment.apiBaseUrl}/api/Agents/${agentID}/reviewResponse`;

    return this.httpClient.post<any>(url, {
      userEmail,
      review,
      token,
    });
  }

  /**
   * It requests a portfolio revision for a specific agent.
   * @param agentID - The ID of the agent you want to request a portfolio revision for.
   * @returns The response is a JSON object with the following properties:
   */
  requestPortfolioRevision(agentID) {
    const url = `${environment.apiBaseUrl}/api/Agents/${agentID}/requestPortfolioRevision`;

    return this.httpClient.post<any>(url, {});
  }

  /**
   * It subscribes to the pending notices endpoint and adds the notices to the store.
   * @returns The timer observable is being returned.
   */
  startPendingNoticesSubscription() {
    if (this.subscribedToNotices) {
      return of(false);
    }

    return timer(0, environment.subscriptions.notices.frequency).pipe(
      switchMap(() => {
        this.subscribedToNotices = true;
        return this.getPendingNotices();
      }),
      tap((notices) =>
        this.store.dispatch(
          AgentAddOrUpdateFieldAction({
            field: 'notices',
            value: notices,
          }),
        ),
      ),
    );
  }

  /**
   * It gets the pending notices for the current agent.
   * @returns An observable of the agent's pending notices.
   */
  getPendingNotices() {
    return this.store.select('agent').pipe(
      take(1),
      switchMap((agent) => {
        if (!agent || Object.keys(agent).length <= 1) {
          return of();
        }

        const url = `${environment.apiBaseUrl}/api/Agents/${agent.id}/notices`;

        return this.httpClient.get<any>(url, {
          headers: new HttpHeaders({
            filter: JSON.stringify({
              where: {
                and: [
                  {
                    or: [
                      { startDate: { lte: Date.now() } },
                      { startDate: { exists: false } },
                    ],
                  },
                  {
                    or: [
                      {
                        frequency: 'read',
                        read: false,
                      },
                      {
                        frequency: 'completed',
                        completed: false,
                      },
                      {
                        frequency: 'dueDate',
                        dueDate: { gt: Date.now() },
                      },
                      {
                        frequency: 'oncePerDay',
                        or: [
                          { dueDate: { gt: Date.now() } },
                          { dueDate: { exists: false } },
                        ],
                      },
                      {
                        frequency: 'oncePerWeek',
                        dueDate: { gt: Date.now() },
                      },
                    ],
                  },
                ],
              },
            }),
          }),
        });
      }),
    );
  }

  /**
   * `markNoticeAsRead(noticeID)`
   * 
   * This function will mark a notice as read
   * @param noticeID - The ID of the notice to mark as read.
   * @returns The response is a JSON object with the following properties:
   */
  markNoticeAsRead(noticeID) {
    const url = `${environment.apiBaseUrl}/api/Notices/${noticeID}`;

    return this.httpClient.patch<any>(url, {
      read: true,
    });
  }

  /**
   * * If the condition is not provided, return true.
   * * If the condition is provided, return the result of applying the condition to the agent.
   * * If the result is empty, return false.
   * * If the result is not empty, return true
   * @param condition - The condition to check against.
   * @param hasConditionModel - boolean
   * @returns The observable of the result of the query.
   */
  evalNoticeModalCondition(condition, hasConditionModel) {
    return this.store.select('agent').pipe(
      take(1),
      switchMap((agent) => {
        if (hasConditionModel) {
          return of(true);
        }

        const filter = { where: condition };
        const result = applyFilter([agent], filter);

        if (!result || (result && result.length === 0)) {
          return of(false);
        }

        return of(result);
      }),
    );
  }

  /**
   * It gets the names of the agents that are active and have a quality plan.
   * @returns An array of objects with the following structure:
   */
  getAgentsNames() {
    const url = `${environment.apiBaseUrl}/api/Agents`;
    const filter = {
      where: {
        'modelActive.active': true,
        tradeName: { neq: null },
        planSelected: 'quality',
      },
      fields: ['tradeName', 'id', 'contact'],
      order: 'tradeName ASC',
    };
    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
      }),
    };

    return this.httpClient.get<any>(url, httpOptions);
  }

/**
 * It gets the count of budgets for the agent.
 * @returns The observable returns an array of budgets.
 */
  getAgentBudgetsCount() {
    return this.getAgentID().pipe(
      switchMap((agentID) => {
        const url = `${environment.apiBaseUrl}/api/Agents/${agentID}/budgets-count`;

        const filter = {
          where: {
            'modelActive.active': true,
          },
        };

        const httpOptions = {
          headers: new HttpHeaders({
            filter: JSON.stringify(filter),
          }),
        };

        return this.httpClient.get<any>(url, httpOptions);
      }),
    );
  }

  /**
   * It returns an array of budgets.
   * @param  - agentId: The agentId of the budget.
   * @returns An array of Budget objects.
   */
  getBudgetsWithConf({
    agentId,
    include,
    skip,
    limit,
  }: {
    agentId: string;
    include?: object;
    skip?: number;
    limit?: number;
  }) {
    const filterHeader = {
      include,
      skip,
      limit,
      where: { agentId },
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    return this.httpClient.get<BudgetInterface[]>(
      `${environment.apiBaseUrl}/api/Budgets`,
      httpOptions,
    );
  }

  /**
   * It gets the agent ID, then gets the budgets for that agent, then flattens the array.
   * @returns An array of budgets.
   */
  getAgentBudgets() {
    return this.getAgentBudgetsCount().pipe(
      switchMap(({ count }) => {
        const countLimit = 50;
        const requestArr: Observable<BudgetInterface[]>[] = [];

        return this.getAgentID().pipe(
          switchMap((agentID) => {
            if (count <= countLimit && count > 0) {
              return this.getBudgetsWithConf({
                agentId: agentID,
                // where,
                // include,
                limit: countLimit,
              });
            }

            if (count > countLimit) {
              for (let index = 0; index < count; index += countLimit) {
                requestArr.push(
                  this.getBudgetsWithConf({
                    agentId: agentID,
                    skip: index,
                    limit: countLimit,
                  }),
                );
              }
            }

            return forkJoin(requestArr);
          }),
        );
      }),
      map((budgets: BudgetInterface[]) => budgets.flat()),
    );
  }

  /**
   * It removes a document from the project.
   * @param linkID - The ID of the document to remove.
   * @returns The observable of the HTTP response.
   */
  removeProjectUrlDocument(linkID) {
    return this.store.select('agent').pipe(
      switchMap((agent) => {
        return this.httpClient.delete<DocumentInterface>(
          `${environment.apiBaseUrl}/api/Agents/${agent.id}/uploadUrls/${linkID}`,
        );
      }),
    );
  }
}

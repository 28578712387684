import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { CONTEXTS, ROUTES } from "@core/constants";
import { ClientInterface, LeadInterface } from "@core/sdk";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { filter, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class HomeGuard  {

  constructor(
    private router: Router,
    private store: Store<{ client: ClientInterface, context: any, project: LeadInterface }>
  ) { }
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select('context').pipe(
      filter(({ value: context }) => context === CONTEXTS.LOGIN_CLIENT),
      switchMap(() => this.store.select('client')),
      switchMap(({ b2b, ppv }) => {
        if (b2b || ppv) {
          this.router.navigate(['logged-client', 'projects']);
          return of(true);
        } else {
          this.router.navigate(ROUTES.CLIENT_HOME);
          return of(false);
        }
      })
    );
  }

}
import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

const translateConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
};

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  const date = Date.now();
  const i18nFolder = './assets/i18n/';

  return new TranslateHttpLoader(http, i18nFolder, `.json?${date}`);
}

@NgModule({
  imports: [TranslateModule.forRoot(translateConfig)],
  exports: [TranslateModule],
})
export class SharedTranslateModule {}

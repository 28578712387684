<div class="client-description">
  <!-- <img [src]="clientProfileImage" alt="" /> -->
  <app-initial-letters [name]="clientName"></app-initial-letters>
  <div class="client-description-info">
    <span class="client-description-info-name">
      {{ context !== 'login-client' ? clientName : ('my-project' | translate) }}
    </span>

    <span
      class="client-description-info-claim"
      *ngIf="context !== 'login-client'"
    >
      <mat-icon svgIcon="16px-phone"></mat-icon>
      <span>{{ project.contact?.phoneNumber }}</span>
    </span>

    <span
      *ngIf="project.own && context !== 'login-client'"
      class="client-description-info-claim"
    >
      <mat-icon svgIcon="24px-mailClosed"></mat-icon>
      <span>{{ project.contact?.email }}</span>
    </span>
    
  </div>
</div>
<div class="project">
  <div class="project-agent-feeling">
    <div class="scoring" *ngIf="visitScoring && visitScoring > 0">
      <span class="label">
        {{ 'client-assesment' | translate }}
        <span
          *ngIf="project.visitComment"
          class="visit-comment"
          [matTooltip]="project.visitComment"
          matTooltipPosition="above"
        >
          {{ 'see-visit-comment' | translate }}
        </span>
        :
      </span>

      <div class="stars visit">
        <ng-container *ngFor="let star of stars; let visitStarIndex = index">
          <mat-icon
            svgIcon="{{
              visitStarIndex >= visitScoring ? '12px-emptyStar' : '12px-star'
            }}"
          ></mat-icon>
        </ng-container>
      </div>
    </div>

    <div class="scoring" *ngIf="context === 'agent'; else clientScoringView">
      <span class="label"> {{ 'your-assesment' | translate }}: </span>

      <div class="stars">
        <ng-container *ngFor="let star of stars; let feelingStarIndex = index">
          <mat-icon
            svgIcon="{{
              feelingStarIndex >= agentFeeling ? '12px-emptyStar' : '12px-star'
            }}"
            (click)="setProjectFeeling(feelingStarIndex)"
          ></mat-icon>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-template #clientScoringView>
    <!-- <div class="scoring">
      <span class="label">
        {{ 'your-assesment-client' | translate }}:
      </span>

      <div class="stars">
        <ng-container *ngFor="let star of stars; let clientScoringIndex = index">
          <mat-icon
            svgIcon="{{clientScoringIndex >= clientScoring ? '12px-emptyStar' : '12px-star'}}"
            (click)="setProjectClientScoring(clientScoringIndex)"
          ></mat-icon>
        </ng-container>
      </div>
    </div> -->
  </ng-template>

  <div class="project-data" *ngIf="avaliableBudget">
    <mat-icon svgIcon="24px-location"></mat-icon>
    <div class="location">
      <p>{{ project.address.address1 }}</p>
      <p *ngIf="project.address.address2">{{ project.address.address2 }}</p>
      <p>{{ project.address.city }}, {{ project.address.zipCode }}</p>
      <p>{{ project.address.region }}</p>
    </div>
  </div>

  <div class="project-data" *ngIf="avaliableBudget">
    <mat-icon svgIcon="docType-budget"></mat-icon>
    <div>
      <p>Dispone de:</p>
      <span>{{ avaliableBudget | currency: 'EUR':'symbol':'1.0-2' }}</span>
    </div>
  </div>

  <div class="write-agent-review" *ngIf="context === 'login-client'">
    <span class="cta" (click)="writeAgentReview()">
      {{ 'write-a-review' | translate }}
    </span>
  </div>
</div>

<button
  mat-flat-button
  color="primary"
  (click)="generateBudget()"
  *ngIf="context === 'agent'"
>
  {{ 'new-budget' | translate }}
</button>

<button
  mat-flat-button
  color="primary"
  (click)="simulateBudget()"
  *ngIf="context === 'login-client'"
>
  {{ 'estimate-budget' | translate }}
</button>

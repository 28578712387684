<section
  class="conversation"
  [ngClass]="{ discussion: isDiscussion }"
  data-cy="conversation-section"
>
  <div class="header">
    <div class="receptor">
      <img
        *ngIf="conversation.receptor?.avatar"
        [src]="conversation.receptor.avatar"
        alt=""
      />
      <div class="data">
        <span
          class="subject"
          [matTooltip]="headerData?.title"
          matTooltipPosition="above"
        >
          {{ headerData?.title }}
        </span>
        <span
          class="name"
          [matTooltip]="headerData?.subtitle.name"
          matTooltipPosition="above"
        >
          {{ headerData?.subtitle.name }}
        </span>
      </div>
    </div>

    <button mat-icon-button (click)="closeConversation()">
      <mat-icon svgIcon="24px-close"></mat-icon>
    </button>
  </div>

  <div
    class="messages-list"
    [ngClass]="{ empty: messages?.length === 0 }"
    #scrollConversation
    [scrollTop]="scrollConversation.scrollHeight"
  >
    <ng-container *ngIf="messages?.length > 0; else emptyState">
      <ng-container *ngFor="let message of messages">
        <app-message [message]="message"></app-message>
      </ng-container>
    </ng-container>

    <ng-template #emptyState>
      <div class="empty-state">
        <mat-icon svgIcon="illustration-budgetComment"></mat-icon>
        <p>
          {{
            (isDiscussion
              ? 'discussion-empty-state'
              : 'conversation-empty-state'
            ) | translate
          }}
        </p>
      </div>
    </ng-template>
  </div>

  <div class="message-editor-wrapper">
    <div class="message-editor" *ngIf="emitterForm">
      <div class="tools" *ngIf="showTools">
        <img #clipboardImageUploaded alt="">

        <button
          mat-icon-button
          [matTooltip]="'upload-image' | translate"
          matTooltipPosition="above"
          mat-stroked-button
          (click)="fileInput.click()"
          class="upload-button"
        >
          <mat-icon>attach_file</mat-icon>
          <input
            hidden
            (change)="handleDrop($event)"
            #fileInput
            type="file"
            id="file"
          />
        </button>
      </div>

      <div class="emitter-wrapper">
        <div class="emitter">
          <ng-container *ngIf="viewMode === 'web'">
            <img
              *ngIf="emitter.avatar; else initialLetters"
              [src]="emitter.avatar"
              alt=""
            />
            <ng-template #initialLetters>
              <app-initial-letters
                [name]="emitter.username"
              ></app-initial-letters>
            </ng-template>
          </ng-container>

          <form [formGroup]="emitterForm">
            <div class="custom-input text-xs service-description">
              <mat-form-field appearance="outline" color="primary">
                <textarea
                  matInput
                  placeholder="{{ 'write-your-message' | translate }}"
                  formControlName="draftMessage"
                  data-cy="description"
                >
                </textarea>
              </mat-form-field>
            </div>
          </form>
        </div>
        <button
          mat-icon-button
          (click)="sendMessage()"
          [disabled]="emitterForm.invalid"
          data-cy="send-message"
        >
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </div>
  </div>
</section>

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';
import { LocalStorageService } from '@core/services/local-storage.service';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private localStorageService: LocalStorageService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          console.log('this is client side error');
          return throwError(error);
        }

        // Forbidden endpoint? --> To the fucking street
        if (error.status === 401) {
          if (request.url.includes('logout')) {
            return throwError(error);
          }

          if (this.authService.isAuthenticated()) {
            this.authService.logout();
          }


          return throwError(error);
        }

        return throwError(error);
      }),
    );
  }
}

import { RoleService } from '@core/services/role.service';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
  tap,
  catchError,
  map,
  mergeMap,
  switchMap,
  exhaustMap,
  filter,
  take,
} from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';
import { Observable, of } from 'rxjs';
import {
  AuthLoginAction,
  AuthLoginSuccessAction,
  AuthLoginFailureAction,
  AuthRegisterClientAction,
  AuthRegisterClientSuccessAction,
  AuthRegisterClientFailureAction,
  AuthRegisterAgentAction,
  AuthRegisterAgentSuccessAction,
  AuthRegisterAgentFailureAction,
  AuthRegisterAgentFailureCleanAction,
  AuthLoginFailureCleanAction,
  AuthUserUpdateTeamsRequestStarted,
  AuthUserUpdateTeamsRequestSuccess,
  AuthUserRemoveTeamRequestStarted,
  AuthUserRemoveTeamRequestSuccess,
} from '../actions/auth.actions';
import { LoaderService } from '@core/services/loader.service';
import { NotificationsService } from '@core/services/notifications.service';
import { HasPermissionService } from '@core/services/has-permission.service';
import { TeamService } from '@core/services/team.service';
import { TeamInterface } from '@core/sdk/models/Team';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private loaderService: LoaderService,
    private notificationsService: NotificationsService,
    private hasPermissionService: HasPermissionService,
    private teamService: TeamService,
    private translateService: TranslateService
  ) {
    this.translateService.setDefaultLang('es_ES');
    this.translateService.use('es_ES');
  }

  /************************/
  /******** LOGIN *********/
  /************************/

  // effects go here
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthLoginAction),
      exhaustMap((payload) => {
        this.loaderService.enable();

        return this.authService.login(payload.email, payload.password, payload.remember).pipe(
          map((result) =>
            AuthLoginSuccessAction({
              ...result,
              redirectUrl: payload.redirectUrl,
              remember: payload.remember,
            }),
          ),
          catchError((error) => [AuthLoginFailureAction({ error })]),
        );
      }),
    ),
  );

  loginSucces$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthLoginSuccessAction),
        tap((loginInfo) => {
          this.authService.handleLoginInfo(loginInfo);
          this.hasPermissionService.updateRoles(loginInfo.roles)
        }),
      ),
    { dispatch: false },
  );

  loginFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthLoginFailureAction),
        tap(() => this.loaderService.disable()),
      ),
    { dispatch: false },
  );

  loginFailClean$: Observable<any> = createEffect(
    () => this.actions$.pipe(ofType(AuthLoginFailureCleanAction)),
    { dispatch: false },
  );

  /***********************************/
  /******** REGISTER AGENT  *********/
  /***********************************/

  registerAgent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthRegisterAgentAction),
      exhaustMap((payload) =>
        this.authService
          .register(
            payload.person,
            payload.externalAgent,
            payload.utmSource,
            payload.isFacebookLead,
          )
          .pipe(
            map((result) => AuthRegisterAgentSuccessAction(result)),
            catchError((error) => [AuthRegisterAgentFailureAction({ error })]),
          ),
      ),
    ),
  );

  registerAgentSucces$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthRegisterAgentSuccessAction),
        tap((loginInfo) => {
          this.authService.handleLoginInfo(loginInfo);
        }),
      ),
    { dispatch: false },
  );

  registerAgentFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthRegisterAgentFailureAction),
        tap(({ error }) => {
          console.log(error);
          const { statusText, error: nestedError } = error;

          if (nestedError) {
            const { error: subNestedError } = nestedError;

            if (subNestedError) {
              const { statusCode, details } = subNestedError;

              if (statusCode === 422 && details) {
                const {
                  codes: { email, username },
                } = details;

                if (email && email[0] === 'uniqueness') {
                  this.notificationsService.showError(
                    'El email introducido ya existe.',
                  );
                }

                if (username && username[0] === 'uniqueness') {
                  this.notificationsService.showError(
                    'El nombre de usuario introducido ya existe.',
                  );
                }
              }
            }
          }
        }),
      ),
    { dispatch: false },
  );

  registerAgentFailClean$: Observable<any> = createEffect(
    () => this.actions$.pipe(ofType(AuthRegisterAgentFailureCleanAction)),
    { dispatch: false },
  );

  /***********************************/
  /******** REGISTER CLIENT  *********/
  /***********************************/

  registerClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthRegisterClientAction),
      exhaustMap((payload) =>
        this.authService
          .registerClient(
            payload.person,
            payload.utmSource,
            payload.isFacebookLead,
          )
          .pipe(
            map((result) => AuthRegisterClientSuccessAction(result)),
            catchError((error) => [AuthRegisterClientFailureAction({ error })]),
          ),
      ),
    ),
  );

  registerClientSucces$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthRegisterClientSuccessAction),
        tap((loginInfo) => {
          this.authService.handleLoginInfo(loginInfo);
        }),
      ),
    { dispatch: false },
  );

  registerClientFailure$: Observable<any> = createEffect(
    () => this.actions$.pipe(ofType(AuthLoginFailureAction)),
    { dispatch: false },
  );

  addTeamToUserRequestStarted$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUserUpdateTeamsRequestStarted),
        mergeMap(
          ({ teamId, userId }) =>
            this.teamService.assignPersonToTeam(teamId, userId) as Observable<TeamInterface>,
        ),
        filter(Boolean),
        map((team: TeamInterface) => AuthUserUpdateTeamsRequestSuccess({ team })
        ),
      ));

  removeUserFromTeamRequestStarted$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUserRemoveTeamRequestStarted),
        exhaustMap(
          ({ teamId, userId }) =>
            this.teamService.removeMemberFromTeam(teamId, userId).pipe(
              map(() => ({ teamId }))
            )
        ),
        filter(Boolean),
        map(({ teamId }) => AuthUserRemoveTeamRequestSuccess({ teamId }))
      )
  );

  addTeamToUserRequestSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUserUpdateTeamsRequestSuccess),
        tap(() =>
          this.notificationsService.showSuccess(this.translateService.instant("assigned-to-project"))
        )
      ),
    { dispatch: false }
  );

  removeUserFromTeamRequestSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUserRemoveTeamRequestSuccess),
        tap(() =>
          this.notificationsService.showSuccess(this.translateService.instant("removed-self-from-team"))
        )
      ),
    { dispatch: false }
  );


}

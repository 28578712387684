/* eslint-disable */

declare var Object: any;
export interface SeriesInterface {
  name: string;
  seek: number;
  id?: number;
  modelActive?: any;
}

export class Series implements SeriesInterface {
  'name': string;
  'seek': number;
  'id': number;
  'modelActive': any;
  constructor(data?: SeriesInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Series`.
   */
  public static getModelName() {
    return 'Series';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Series for dynamic purposes.
   **/
  public static factory(data: SeriesInterface): Series {
    return new Series(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Series',
      plural: 'Series',
      path: 'Series',
      idName: 'id',
      properties: {
        name: {
          name: 'name',
          type: 'string',
        },
        seek: {
          name: 'seek',
          type: 'number',
          default: 0,
        },
        id: {
          name: 'id',
          type: 'number',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
      },
      relations: {},
    };
  }
}

import { ResponsiveService } from '@core/services/responsive.service';
import { getBudgetsRequestStarted } from 'src/app/store/actions/budget.actions';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnChanges,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  Budget,
  Lead,
  AgentInterface,
  BudgetInterface,
  ClientInterface,
  LeadInterface,
  Chapter,
  ChapterInterface,
} from 'src/app/core/sdk/models';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { BudgetService } from '@core/services/budget.service';
import { NotificationsService } from '@core/services/notifications.service';
import * as _ from 'lodash-es';
import { Store, select } from '@ngrx/store';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { selectRouteParams } from 'src/app/store/router.selectors';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UserContextService } from '@core/services/user-context.service';
import { ChapterService } from '@core/services/chapter.service';
import { BUDGET_STATES_CLASSES, BUDGET_TYPES, ROLES } from '@core/constants';
import { selectProjectById } from 'src/app/store/selectors/project.selectors';
import { CONTEXTS, STATES_SHOW_CLIENT_BUDGETS } from '@core/constants';
import { LoaderService } from '@core/services/loader.service';
import { selectAllBudgets } from 'src/app/store/selectors/budget.selectors';
import { ContextUpdateActiveView } from 'src/app/store/context/context.actions';
import { selectActions } from 'src/app/store/selectors/context.selectors';
import { ActionSelect } from '@core/sdk/models/Navigation';
import { ContextState } from 'src/app/store/context/context.reducer';
import { Role } from '@core/sdk/models/Role';
import { HasPermissionService } from '@core/services/has-permission.service';
@Component({
  selector: 'app-budget-table',
  templateUrl: './budget-table.component.html',
  styleUrls: ['./budget-table.component.scss'],
})
export class BudgetTableComponent implements OnInit, OnChanges, OnDestroy {
  caption: string;
  project: Lead;
  @Input() currentMode = false;
  budgets: Budget[] | any;
  budgets$: BehaviorSubject<any> = new BehaviorSubject([]);
  actionsBudgets$: Observable<ActionSelect[]>;

  @Input() isDraft = false;
  @Input() pageSize = 10;
  @Output() reloadBudgets = new EventEmitter<any>();
  currentProjectId: string;
  displayedColumns: string[];
  dataSource = new MatTableDataSource<Budget>;
  budgetLink: string;
  clientToken: string;
  budgetAccepted: Budget;
  form = new UntypedFormGroup({
    showArchivedControl: new UntypedFormControl(),
  });
  showArchived: boolean = false;
  openAnnexToTrackingModal: boolean = false;
  currentBudget: Budget;

  private sort: MatSort;
  private paginator: MatPaginator
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  context: string;
  role: Role;
  firstColumnSlug = 'agent';
  isMobile: boolean;

  unsubscriber$ = new Subject<void>();

  constructor(
    private router: Router,
    private userContextService: UserContextService,
    private budgetService: BudgetService,
    private notificationsService: NotificationsService,
    private chapterService: ChapterService,
    private store: Store<{
      agent: AgentInterface;
      client: ClientInterface;
      projects: LeadInterface[];
      context: any;
      router: any;
      budgets: Budget[];
    }>,
    private loader: LoaderService,
    private contextStore: Store<ContextState>,
    private responsiveService: ResponsiveService,
    private hasPermissionService: HasPermissionService
  ) {
    this.actionsBudgets$ = this.contextStore.pipe(select(selectActions));
  }

  ngOnInit() {
    this.store.dispatch(ContextUpdateActiveView({ activeView: 'budgets' }));
    this.loader.enable();
    setTimeout(() => {
      this.loader.disable();
    }, 500);

    this.isMobile = this.responsiveService.mobileCheck();

    this.initializeListeners();
    this.getUserViewContext();

    this.store
      .select('context')
      .pipe(take(1))
      .subscribe(({ value: context, currentProjectId }) => {
        if (!context) {
          return;
        }

        this.context = context;
        this.currentProjectId = currentProjectId;
        this.currentMode = this.context === CONTEXTS.AGENT;
        this.caption = this.context === CONTEXTS.AGENT ? 'budgets' : 'current-budgets';

        if (currentProjectId) {
          this.loadBudgets();

          switch (context) {
            case 'agent':
              this.setBudgetLinks();
              break;
            case 'login-client':
              this.firstColumnSlug = 'professional';
              this.setLoginClientBudgetLinks();
              break;
            default:
              break;
          }
        }
      });

    this.store
      .select(selectRouteParams)
      .pipe(
        takeUntil(this.unsubscriber$),
        filter((params) => Boolean(params && params.clientToken)),
        map(({ clientToken }) => clientToken),
      )
      .subscribe((clientToken: string) => (this.clientToken = clientToken));


  }

  ngOnChanges(): void {
    this.setDisplayedColumns();

    if (this.context) {
      this.initializeTable();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();

  }

  initializeListeners() {
    this.form.get("showArchivedControl").valueChanges.subscribe((value) => {
      this.changeShowArchived(value);
    })
  }

  changeShowArchived(value) {
    this.showArchived = value;
    this.loadBudgets();
  }


  private getUserViewContext() {
    const projectViewContext = this.userContextService.getViewContext('projectView') ?
      this.userContextService.getViewContext('projectView') : {};

    const {
      showArchivedSelected
    } = projectViewContext;

    this.showArchived = showArchivedSelected ? showArchivedSelected : false;
    this.form.get("showArchivedControl").setValue(showArchivedSelected, { emitEvent: false });
  }


  /**
   * It sets the displayed columns for the table.
   * @returns The return is the displayedColumns array.
   */
  private setDisplayedColumns() {
    if (this.context === 'agent') {
      if ((this.project && this.project.own) || !this.project) {
        this.displayedColumns = [
          'title',
          'type',
          'subType',
          'price',
          'total',
          'createdAt',
          'sendingDate',
          'contextual',
        ];
      }

      if ((this.project && !this.project.own) || !this.project) {
        this.displayedColumns = [
          'title',
          'type',
          'subType',
          'state',
          'total',
          'createdAt',
          'sendingDate',
          'contextual',
        ];
      }

      return;
    }

    if (this.context === 'login-client') {
      if (this.caption.includes('estimations')) {
        this.displayedColumns = [
          'title',
          'type',
          'total',
          'createdAt',
          'contextual',
        ];

        return;
      }
    }

    this.displayedColumns = [
      'agent',
      'title',
      'state',
      'total',
      'createdAt',
      'sendingDate',
      'contextual',
    ];
  }

  /**
   * It sets the budget link to the logged-client/budget/
   */
  private setLoginClientBudgetLinks() {
    this.budgetLink = 'logged-client/budget/';
  }

  /**
   * If the context is agent and the budget is a draft, then the budget link is /budget-edition/.
   * Otherwise, the budget link is /budget/<clientToken>/
   * @returns The budgetLink is being returned.
   */
  private setBudgetLinks() {
    if (this.context === 'agent' && this.isDraft) {
      this.budgetLink = '/budget-edition/';
      return;
    }

    if (this.context === 'agent' && !this.isDraft) {
      this.budgetLink = '/budget/';
      return;
    }

    this.budgetLink = `/budget/${this.clientToken}/`;
  }

  /**
   * Initialize the budgets table with the budgets data
   */
  private initializeTable() {
    this.initializeBudgets();
    this.removeDuplicatesBudgets();
    this.dataSource = new MatTableDataSource(this.budgets);
  }

  private removeDuplicatesBudgets() {
    // TODO: Avoid case this.budgets = undefined
    this.budgets = this.budgets?.filter((budget, indice, array) => {
      const index = array.findIndex(item => item.id === budget.id);
      return index === indice;
    });
  }


  navigateToBudget(budget) {
    this.router.navigate(['logged-client', 'budget', budget.id]);
  }
  /**
   * It filters the budgets array to only show the ones that are in the current mode.
   */
  private initializeBudgets() {
    const hasProject = this.project;
    const hasBudgets = this.budgets && this.budgets.length > 0;
    const budgetsFinal = hasBudgets
      ? this.budgets
      : hasProject
        ? this.project.budgets
        : this.budgets;

    // TODO: Avoid case this.budgets = undefined
    this.budgets = budgetsFinal?.filter((budget) => {
      const { state: budgetState, subType, archived, type } = budget;

      if (this.context === CONTEXTS.LOGIN_CLIENT) {
        return (
          (type === BUDGET_TYPES.BUDGET) &&
          (STATES_SHOW_CLIENT_BUDGETS.includes(budgetState) &&
            (subType == null) &&
            !(this.showArchived === false && archived === true))
        );
      }

      if (this.context === CONTEXTS.AGENT) {
        return !(this.showArchived === false && archived && archived === true)
      }
    });

    return;
  }

  /**
   * It creates a draft from a budget.
   * @param budgetID - The ID of the budget to create a draft from.
   */
  openBudgetAsDraft(budgetID) {
    this.budgetService
      .createDraftFromBudget(this.project.agentId, this.project.id, budgetID)
      .subscribe((newDraft) =>
        this.router.navigate(['/budget-edition', newDraft.id]),
      );
  }

  /**
   * Navigate to the budget edition page for the budget with the given ID
   * @param budgetID - The ID of the budget to edit.
   */
  goToBudgetEdition(budgetID) {
    this.router.navigate(['/budget-edition', budgetID]);
  }

  /**
   * *The function takes a budget as an argument and then navigates to the budget preview page.*
   * @param budget - The budget to preview.
   * @returns Nothing.
   */
  goToBudgetPreview(budget) { //TODO: Use budget guard instead of hardcoding the navigation
    switch (this.context) {
      case CONTEXTS.AGENT:
        this.router.navigate(['budget/', budget.id]);
        break;
      case CONTEXTS.LOGIN_CLIENT:
        this.router.navigate([this.budgetLink, budget.id]);
        break;
      case CONTEXTS.CLIENT:
        if (budget.type === 'draft') {
          this.goToBudgetEdition(budget.id);

          return;
        }

        this.router.navigate(['budget/', this.clientToken, budget.id]);
        break;
      default:
        break;
    }
  }

  /**
   * The function takes a budget object as an argument and then checks the context of the user.
   * If the context is agent, then it will navigate to the budget edition page.
   * If the context is login-client, then it will navigate to the budget page.
   * If the context is client, then it will navigate to the budget page with the client token
   * @param budget - The budget to navigate to.
   * @returns Nothing.
   */
  goToBudget(budget) {
    switch (this.context) {
      case CONTEXTS.AGENT:
        if (this.hasPermissionService.checkRole(ROLES.B2B)) {
          this.router.navigate(['budget/', budget.id]);
          return;
        }

        if (budget.type === 'draft') {
          this.goToBudgetEdition(budget.id);
          return;
        }

        this.router.navigate(['budget/', budget.id]);
        break;
      case CONTEXTS.LOGIN_CLIENT:
        this.router.navigate(['logged-client/budget/', budget.id]);
        break;
      case CONTEXTS.CLIENT:
        if (budget.type === 'draft') {
          this.goToBudgetEdition(budget.id);
          return;
        }

        this.router.navigate(['budget/', this.clientToken, budget.id]);
        break;
      default:
        break;
    }
  }

  /**
   * Delete a budget
   * @param budget - The budget to be deleted.
   * @returns Nothing.
   */
  deleteBudget(budget) {
    if (budget.code && budget.subType === 'simulation') {
      this.budgetService.deleteBudgetEstimation(budget.id).subscribe(() => {
        _.remove(this.budgets, { id: budget.id });

        this.initializeTable();
        this.notificationsService.showSuccess('Documento eliminado');
      });

      return;
    }

    this.budgetService.deleteBudget(budget.id).subscribe(() => {
      _.remove(this.budgets, { id: budget.id });

      this.initializeTable();
      this.notificationsService.showSuccess('Borrador eliminado');
    });
  }

  canBeUnarchived(budget: BudgetInterface): boolean {
    return budget.state !== '';
  }

  unarchiveBudget(budget: BudgetInterface): void {
    this.budgetService.updateBudget(budget.id, { archived: false }).subscribe(() => {
      this.notificationsService.showSuccess('Presupuesto Desarchivado');
    });
  }

  archiveBudget(budget: BudgetInterface): void {
    this.budgetService.updateBudget(budget.id, { archived: true }).subscribe(() => {
      this.notificationsService.showSuccess('Presupuesto Archivado');
    });
  }

  /**
   * This funcion opens the modal to confirm the annexation of a budget to a tracking and modify the project end date.
   * @param budget - The selected budget to be annexed.
   */
  openAnnexToBudgetAndTrackingModal(budget: Budget): void {
    this.currentBudget = budget;
    this.openAnnexToTrackingModal = true;
  }

  addAnnexToBudgetAndTracking(): void {
    this.openAnnexToTrackingModal = false;
    this.budgetAccepted = this.budgets.find((budget) => budget.state === 'contractSended');

    const filter = [
      {
        relation: 'budgetItems',
        scope: {
          fields: { id: true, order: true },
          order: 'order ASC',
        },
      },
    ];

    this.budgetService.getBudgetChapters(this.budgetAccepted.id).subscribe((chaptersBudgetAccepted) => {
      const order = chaptersBudgetAccepted.length + 1;

      this.budgetService.getBudgetChapters(this.currentBudget.id, filter)
        .subscribe((chapters: Chapter[]) => {
          chapters.forEach((chapter: Chapter) => {
            const newChapter = { ...chapter, order: order, type: 'annex', state: 'pending', budgetId: this.budgetAccepted.id }
            delete newChapter.createdAt
            delete newChapter.id

            this.createNewAnnexChapters(this.budgetAccepted.id, newChapter)
          });

          this.budgetService.updateBudget(this.currentBudget.id, { state: 'annexed', type: 'budget' })
            .subscribe(() => {
              this.router.navigate(['tracking/', this.currentBudget.leadId]);
            })
        })
    })
  }

  createNewAnnexChapters(budgetId: string, newChapter: Chapter) {
    const budgetItems = newChapter.budgetItems;

    delete newChapter.budgetItems;

    this.budgetService.createChapter(budgetId, {
      ...newChapter
    }).subscribe((chapter: Chapter) => {
      budgetItems.forEach((budgetItem) => {
        const data = {
          chapterId: chapter.id,
          product: budgetItem.product,
          costPrice: budgetItem.costPrice,
          quantity: budgetItem.quantity,
          taxPercent: budgetItem.taxPercent
        }
        this.chapterService.createItem(chapter.id, data).subscribe();
      });
      //this.updateAnnexChapterState(chapter,'accepted')
    })
  }

  updateAnnexChapterState(acceptChapter: ChapterInterface, state: string) {
    acceptChapter.state = state;

    this.chapterService.updateChapter(acceptChapter.id, acceptChapter).subscribe(() => {
      this.notificationsService.showSuccess('Anexo (' + acceptChapter.title + ') añadido al presupuesto aceptado y al seguimiento');
    })
  }

  loadBudgets() {
    this.store.dispatch(getBudgetsRequestStarted({ projectId: this.currentProjectId, budgets: this.budgets }));
    this.store.select(selectAllBudgets).subscribe((budgets) => {
      this.budgets = budgets

      this.store.select(selectProjectById(this.currentProjectId))
        .pipe(take(1))
        .subscribe((project) => {
          this.project = project;

          this.setDisplayedColumns();
          this.initializeTable();
        })
    })
  }
  getBudgetStateClasses(budgetState: string): { [key: string]: boolean } {
    return {
      'state--pending': BUDGET_STATES_CLASSES['state--pending'].includes(budgetState),
      'state--blocked': BUDGET_STATES_CLASSES['state--blocked'].includes(budgetState),
      'state--accepted': BUDGET_STATES_CLASSES['state--accepted'].includes(budgetState)
    };
  }
  getTableRowClass({ state, type, subType, archived }) {
    return {
      'bg-budgets-annex': subType === 'annex',
      'bg-budgets-annexed': state === 'annexed',
      'border-budgets-draft': type === 'draft',
      'border-budgets-budget': type === 'budget',
      'bg-budgets-archived': archived
    }
  }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  unblockBudget(budget: BudgetInterface): void {
    this.budgetService.updateBudget(budget.id, { state: "pendingOfAnswer" })
    .pipe(take(1))
    .subscribe(() => {
      this.notificationsService.showSuccess('Presupuesto desbloqueado');
    });
  }
}
<form [formGroup]="formGroup">
  <div formArrayName="images" class="images">
    <ng-container *ngFor="let image of images.value; index as photoIndex">
      <app-portfolio-image-preview
        [document]="image"
        (removeFileEmitter)="remove(photoIndex)"
        (openCarouselEmitter)="openCarousel($event)"
      >
      </app-portfolio-image-preview>
    </ng-container>
  </div>

  <div class="add-button">
    <button mat-icon-button class="add" (click)="fileInput.click()">
      <mat-icon>add</mat-icon>
    </button>
    <input
      hidden
      (change)="handleDrop($event)"
      #fileInput
      type="file"
      id="file"
    />
  </div>
</form>

import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Action, ActionInterface, Lead, LeadInterface, Reason, UserInterface } from '@core/sdk';
import { ActionService } from '@core/services/action.service';
import { ReasonService } from '@core/services/reason.service';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { ProjectUpdateAction } from 'src/app/store/actions/project.actions';
import { selectProjectById } from 'src/app/store/selectors/project.selectors';

@Component({
  selector: 'app-decision-modal',
  templateUrl: './decision-modal.component.html',
  styleUrls: ['./decision-modal.component.scss'],
})
export class DecisionModalComponent implements OnInit {
  mustShowForm: UntypedFormGroup;
  constructor(
    public dialogRef: MatDialogRef<DecisionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.mustShowForm = this.formBuilder.group({
      dontShowModal: new UntypedFormControl(false)
    });
  }


  submit() {
    if (!this.data.userCanHideModal) {
      return;
    }


    this.dialogRef.close({
      dontShowModal: this.mustShowForm.get('dontShowModal').value,
    });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import environment from '@environment';
import * as _ from 'lodash';

const GOOGLE_MAPS_API_URL = 'https://maps.googleapis.com';
const GOOGLE_MAPS_API_URL_GEOCODE = '/maps/api/geocode/json?';
const GOOGLE_MAPS_API_URL_PARAMS = `&sensor=true&key=${environment.google.maps}&libraries=drawing,places`;

type GoogleAddress = {
  route?: string,
  street_number?: string,
  locality?: string,
  postal_code?: string,
  country?: string,
  administrative_area_level_1?: string,
  administrative_area_level_2?: string
}

export type GoogleAddressParsed = {
  address: string,
  zipCode: string,
  locality?: string,
  city?: string
  region: string,
  country: string,
}

@Injectable({
  providedIn: 'root',
})
export class AddressOrganizerService {
  constructor(private http: HttpClient){}

  getFullAddress = (address: string): Promise<google.maps.GeocoderResult> => {
    if(!address) {
      return new Promise((resolve, reject) => {
        reject();
      });
    }

    const url: string = `${GOOGLE_MAPS_API_URL}${GOOGLE_MAPS_API_URL_GEOCODE}address=${this.parseSpecialCharacters(address)}${GOOGLE_MAPS_API_URL_PARAMS}`;

    return this.http.get(url).toPromise().then((res: {results: [google.maps.GeocoderResult], status: google.maps.GeocoderStatus}) => {
      return new Promise((resolve, reject) => {
        if (res.status === google.maps.GeocoderStatus.OK) {
          const address = _.first(res.results);
          this.existZipCode(address.formatted_address) ? resolve(address) : reject();
        }
        reject();
      });
    });
  }

  private existZipCode = (address: string): boolean => /(\d{5})|(\d{9})|(\d{5}-\d{4})/.test(address);

  private getStringBetween(stringComplete, firstvariable, secondvariable) {
    const regExString = new RegExp(
      '(?:' + firstvariable + ')((.[\\s\\S]*))(?:' + secondvariable + ')',
      'ig',
    );
    const testRE = regExString.exec(stringComplete);

    if (!testRE) {
      return;
    }

    const [, finalSentence] = testRE;

    if (!finalSentence) {
      return;
    }

    const searchIndex = finalSentence.indexOf('</span>');

    if (searchIndex === -1) {
      return testRE[1];
    }

    const result = finalSentence.substring(0, searchIndex);

    return result;
  }

  private parseSpecialCharacters = (text: string): string => new DOMParser().parseFromString(text, "text/html").documentElement.textContent;

  getAddress = async (place: string): Promise<GoogleAddressParsed> => {
    try {
      const address: google.maps.GeocoderAddressComponent[] = (await this.getFullAddress(place)).address_components;
      return this.parseAddress(address);
    } catch(error) {
      return;
    }
  }

  parseAddress = (googleAddress: google.maps.GeocoderAddressComponent[]): GoogleAddressParsed => {
    const processedAddress: GoogleAddress = googleAddress.reduce((prev: GoogleAddress, current: google.maps.GeocoderAddressComponent) => {
      prev[_.first(current.types)] = current.long_name || current.short_name;
      return prev;
    }, {});
    return {
      address: [processedAddress.route, processedAddress.street_number].filter(item => item).join(', '),
      zipCode: processedAddress.postal_code,
      city: processedAddress.locality || processedAddress.administrative_area_level_2,
      region: processedAddress.administrative_area_level_2 || processedAddress.administrative_area_level_1,
      country: processedAddress.country
    };
  }
}

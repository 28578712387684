
<div *ngIf="events$ | async as events">
  <span *ngIf="(events?.length <= 0); else projectPendingEvents" class="title">
    {{ 'no-project-pending-events' | translate }}
  </span>

  <ng-template #projectPendingEvents>
    <span class="title">
      {{ 'project-pending-events' | translate }}
    </span>

    <ng-container *ngFor="let visit of events">
      <app-visit-activity [visit]="visit"></app-visit-activity>
    </ng-container>
  </ng-template>
</div>
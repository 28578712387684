import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { getBudgetsRequestSuccess, getBudgetsRequestStarted } from '../actions/budget.actions';
import { Budget } from '@core/sdk/models/Budget';
import { Observable } from 'rxjs';
import { BudgetService } from '@core/services/budget.service';

@Injectable()
export class BudgetEffects {
  public budgets$ = this.getBudgets() 
  
  constructor(
    private actions$: Actions, 
    private budgetService: BudgetService, 
    ) {}
  
  private getBudgets() {
    return createEffect(() => this.actions$.pipe(
      ofType(getBudgetsRequestStarted),
      filter(({ budgets }) => budgets === undefined || budgets.length === 0),
      mergeMap(
        ({ projectId }) =>
          this.budgetService.getBudgetsByLeadId(projectId) as Observable<Budget>,
      ),
      filter(Boolean),
      map((budgets: any) => getBudgetsRequestSuccess({budgets})),
    ));

  }
}
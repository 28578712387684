<ng-container *ngIf="viewMode === 'web'; else mobileView">
  <div class="avatar" data-cy="cubicup-staff">
    <div class="text">
      <span>{{ 'request-call' | translate }}</span>
    </div>
    <mat-icon
      svgIcon="24px-customerService"
      [ngClass]="{ 'new-messages': hasNewMessages }"
    ></mat-icon>
  </div>
</ng-container>

<ng-template #mobileView>
  <div class="avatar">
    <div class="text">
      <span>{{ 'request-call' | translate }}</span>
    </div>
    <div class="content">
      <mat-icon
        svgIcon="24px-customerService"
        [ngClass]="{ 'new-messages': hasNewMessages }"
      ></mat-icon>
    </div>
  </div>
</ng-template>

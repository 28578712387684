import { ErrorOccurredAction } from './../error-handler/error-handler.actions';
import { Injectable } from "@angular/core";
import { BudgetInterface, LeadInterface } from "@core/sdk";
import { ActionSelect, Link } from "@core/sdk/models/Navigation";
import { Store } from "@ngrx/store";
import { catchError, filter, map, mergeMap, take, tap } from "rxjs/operators";
import { selectProjectById } from "../selectors/project.selectors";
import { BehaviorSubject, Observable, of } from "rxjs";
import { ALL_STATES, CERTIFICATION_STATES, STATES_ACTIVATE_TRACKING } from "@core/constants";
import { selectBudgetById } from "../selectors/budget.selectors";
import { selectCertificationById } from "../certification/certification.selectors";
import { getProjectRequestStarted } from '../actions/project.actions';

const TEMPLATES_ROUTES = ['templates/products', 'templates/chapters', 'templates/budget-templates' ,  'templates/budget-templates/ppv'];
@Injectable()
export class ContextFacadeService {
  components = new Array<Object>();
  currentProjectId: string;
  pendingCertifications = [];
  public headerLinksSubject$: BehaviorSubject<Link[]> = new BehaviorSubject<Link[]>([]);
  context: string;

  constructor(private store: Store<{ context: any; project: LeadInterface }>) {
  }

  updateHeaderLinks(): Observable<Link[]> {
    return this.store
      .select("context")
      .pipe(
        filter(({ value: context, currentProjectId, }) => !!context && !!currentProjectId),
        take(1),
        mergeMap(({ currentProjectId, activeView }) => {
          this.currentProjectId = currentProjectId;
          return this.store.select(selectProjectById(currentProjectId)).pipe(
            tap((project) => {
              if(!project){
                this.store.dispatch(getProjectRequestStarted({ projectId: currentProjectId }));
              }
            }),
            filter<LeadInterface>(Boolean),
            take(1),
            map((project) => this.calculateFilter(activeView, project?.tracking)),
            catchError((error) => {
              ErrorOccurredAction({ payload: error })

              return of([])
            })
          )
        }
        )
      );
  }

  updateActionButton(): Observable<ActionSelect[]> {
    return this.store
      .select("context")
      .pipe(
        filter(({ value: context, currentProjectId, currentBudgetId, currentCertificationId }) => !!context && !!currentProjectId),
        take(1),
        mergeMap(({ currentProjectId, currentBudgetId, currentCertificationId, activeView }) =>
          this.getDataActionsSelect(activeView, currentProjectId, currentBudgetId, currentCertificationId)
        )
      );
  }

  getDataActionsSelect(activeView, currentProjectId, currentBudgetId, currentCertificationId): Observable<ActionSelect[]> {
    if (['tracking', 'project', 'budgets', 'documentation', 'certifications', 'invoices'].includes(activeView)) {
      return this.store.select(selectProjectById(currentProjectId)).pipe(
        filter<LeadInterface>(Boolean),
        take(1),
        map((project) => this.getOptionsByState(activeView, project?.state)),
        catchError((error) => {
          ErrorOccurredAction({ payload: error })
          return of([])
        })
      )
    }

    if (['budget'].includes(activeView)) {
      return this.store.select(selectBudgetById(currentBudgetId)).pipe(
        filter<BudgetInterface>(Boolean),
        take(1),
        map((budget) => this.getOptionsByState(activeView, budget?.state)),
        catchError((error) => {
          ErrorOccurredAction({ payload: error })
          return of([])
        })
      )
    }

    if (['certification'].includes(activeView)) {
      return this.store.select(selectCertificationById(currentCertificationId)).pipe(
        filter<BudgetInterface>(Boolean),
        take(1),
        map((certification) => this.getOptionsByState(activeView, certification?.state)),
        catchError((error) => {
          ErrorOccurredAction({ payload: error })
          return of([])
        })
      )
    }
  }

  calculateFilter(route: string, tracking: Boolean): Link[] {
    const headerLinks: Link[] = [];

    if(TEMPLATES_ROUTES.includes(route)) {
      headerLinks.push({ order: 1, slug: "products", routeTo: "templates/products", isActive: route === "templates/products", dataCy: "cy-navigation-products" });
      headerLinks.push({ order: 2, slug: "chapters", routeTo: "templates/chapters", isActive: route === "templates/chapters", dataCy: "cy-navigation-chapters" });
      headerLinks.push({ order: 3, slug: "budget-templates", routeTo: "templates/budget-templates", isActive: route === "templates/budget-templates", dataCy: "cy-navigation-budgets-templates" });
      headerLinks.push({ order: 4, slug: "ppv-templates", routeTo: "templates/budget-templates/ppv", isActive: route === "templates/budget-templates/ppv", dataCy: "cy-navigation-ppv-templates", permission:'ppv.create' });

      this.headerLinksSubject$.next(headerLinks);

      return headerLinks;
    }

    headerLinks.push({ order: 4, slug: "project", routeTo: "project", isActive: route === "project", dataCy: "cy-navigation-project" });
    headerLinks.push({ order: 3, slug: "documentation", routeTo: "documentation2", isActive: route === "documentation", dataCy: "cy-navigation-documentation" });
    headerLinks.push({ order: 5, slug: "budgets", routeTo: "budgets", isActive: route === "budgets", dataCy: "cy-navigation-budgets" });
    headerLinks.push({ order: 6, slug: "invoices", routeTo: "invoices", isActive: route === "invoices", dataCy: "cy-navigation-invoice" });


    if (tracking) {
      headerLinks.push({ order: 2, slug: "certifications", routeTo: "certifications", isActive: route === "certifications", dataCy: "cy-navigation-certifications" });
      headerLinks.push({ order: 1, slug: "tracking", routeTo: "tracking", isActive: route === "tracking", dataCy: "cy-navigation-tracking" });
    }

    this.headerLinksSubject$.next(headerLinks);

    return headerLinks;
  }




  getOptionsByState(page: string, state: string): ActionSelect[] {
    const options: ActionSelect[] = [];
    switch (page) {
      case 'tracking':
        options.push({ slug: 'create-annex', method: 'create-annex', icon: '24px-notes', states: STATES_ACTIVATE_TRACKING, permission: 'budget.create', dataCy: "cy-create-annex-action" });
        options.push({ slug: 'generate-certification', method: 'generate-certification', icon: '24px-draft', states: ALL_STATES, permission: 'certification.create', dataCy: "cy-create-ertification-action" });
        break;
      case 'project':
        options.push({ slug: 'create-budget', method: 'create-budget', icon: '24px-draft', states: ALL_STATES, permission: 'budget.create', dataCy: "cy-create-budget-action" });
        options.push({ slug: 'create-annex', method: 'create-annex', icon: '24px-notes', states: STATES_ACTIVATE_TRACKING, permission: 'budget.create', dataCy: "cy-create-annex-action" });
        options.push({ slug: 'create-budget-option', method: 'create-budget-options', icon: '24px-options', states: ALL_STATES, permission: 'budget.create', dataCy: "cy-create-budget-option" });
        break;
      case 'budget':
        options.push({ slug: 'download-pdf', method: 'download-pdf', icon: '16px-download', states: ALL_STATES, permission: 'budget.read', dataCy: "cy-download-pdf-action" });
        options.push({ slug: 'download-excel', method: 'download-excel', icon: '24px-downloadDocument', states: ALL_STATES, permission: 'budget.read', dataCy: "cy-download-excel-action" });
        options.push({ slug: 'duplicate', method: 'duplicate-budget', icon: '24px-notes', states: ALL_STATES, permission: 'budget.create', dataCy: "cy-duplicate-budget-action" });
        break;
      case 'budgets':
        options.push({ slug: 'create-budget-automated-budget', method: 'create-budget-automated-budget', icon: '24px-draft', states: ALL_STATES, permission: 'ppv.create' });
        options.push({ slug: 'create-budget-configured-calculator', method: 'create-budget-configured-calculator', icon: '24px-draft', states: ALL_STATES, permission: 'ppv.config' });
        options.push({ slug: 'create-budget', method: 'create-budget', icon: '24px-draft', states: ALL_STATES, permission: 'budget.create' });
        options.push({ slug: 'create-annex', method: 'create-annex', icon: '24px-notes', states: STATES_ACTIVATE_TRACKING, permission: 'certification.create' });
        options.push({ slug: 'create-budget-option', method: 'create-budget-options', icon: '24px-options', states: ALL_STATES, permission: 'budget.create' });
        break;
      case 'certification':
        options.push({ slug: 'create-annex', method: 'create-annex', icon: '24px-notes', states: ALL_STATES, permission: 'certification.create', dataCy: "cy-create-annex-action" });
        options.push({ slug: 'download-pdf', method: 'download-pdf-certification', icon: '16px-download', states: ALL_STATES, permission: 'certification.read', dataCy: "cy-download-pdf-action" });
        options.push({ slug: 'send-certification', method: 'send-certification', icon: '24px-send', states: [CERTIFICATION_STATES.CREATED], permission: 'certification.update', dataCy: "cy-send-certification-action" });
        break;
    }

    return this.getsOptionsFilterState(options, state);
  }

  getsOptionsFilterState(options, state: string) {
    return options.filter((option) => option.states.includes(state) || option.states.includes('all'));
  }
}

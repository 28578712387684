import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import environment from '@environment';
import { Permission, Role } from '@core/sdk';
import { take, tap } from 'rxjs/operators';
import { getRoleByIdRequestStarted } from 'src/app/store/role/role.actions';
import { RolesWithAssignedPeopleView } from '@core/sdk/models/RoleWithAssignedPeople';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private roleInfoIsToggled: BehaviorSubject<any> = new BehaviorSubject<any>(
    { toggle: false },
  );

  constructor(
    private store: Store<{ context: any; user: any }>,
    private httpClient: HttpClient,
  ) {  }

  getToggleRoleInfo() {
    return this.roleInfoIsToggled.asObservable();
  }

  toggleRoleInfo(role = null) {
    const { toggle } = this.roleInfoIsToggled.getValue();

    this.roleInfoIsToggled.next({
      toggle: !toggle,
      role,
    });
  }

  getRolesByCompany(companyCode: string) {
    const filter = {
      companyCode
    }

    const headers = new HttpHeaders({
      filter: JSON.stringify(filter)
    })

    return this.httpClient.get<any>(
      `${environment.apiBaseUrl}/api/roles`,
      {headers}
    );
  }

  createRole(roleName: string, permissions: any[]) {
    return this.httpClient.post<any>(
      `${environment.apiBaseUrl}/api/roles`,
      {
        name: roleName,
        permissions
      }
    );
  }

  updateRole(roleId, roleUpdates) {
    return this.httpClient.patch<any>(
      `${environment.apiBaseUrl}/api/roles/${roleId}`,
      roleUpdates
    );
  }

  deleteRole(roleId) {
    return this.httpClient.delete<any>(
      `${environment.apiBaseUrl}/api/roles/${roleId}`
    );
  }

  asignRoleToPerson(roleId, personId) {
    return this.httpClient.post<any>(
      `${environment.apiBaseUrl}/api/roles/${roleId}/asign`,
      {personId}
    )}

  getRoleById(roleId: string) {
    return this.httpClient.get<any>(
      `${environment.apiBaseUrl}/api/roles/${roleId}`
    )
  }

  getPeopleByRole(roleId: string): Observable<RolesWithAssignedPeopleView> {
    return this.httpClient.get<RolesWithAssignedPeopleView>(
      `${environment.apiBaseUrl}/api/roles/${roleId}/people`
    )
  }

  modifyBannedPermissionsByPersonId(personId: string, bannedPermissions: string[], recoveredPermissions: string[]): Observable<any> {
    return this.httpClient.patch<any>(
      `${environment.apiBaseUrl}/api/people/${personId}/bannedpermissions`,
      {
        bannedPermissions,
        recoveredPermissions
      }
    );
  }
}

import { Address } from './../../core/sdk/models/Address';
import { UtilsService } from "@shared/services/utils.service";
import * as _ from "lodash";
import { EASY_CALENDAR_SELECTORS, USER_FORM_MAP } from "./constants";
import { EasyCalendarUser, User } from "./easy-calendar";

export const addObservers = (user: User, utilsService: UtilsService, styleObserver: MutationObserver, easyCalendarSelector: string, formFunction: Function, successFunction?: Function): void => {
  const easyCalendarModal: HTMLIFrameElement = document.querySelector(easyCalendarSelector) as HTMLIFrameElement;
  if (!easyCalendarModal) return;

  utilsService.waitForHtmlElement(easyCalendarModal, EASY_CALENDAR_SELECTORS.iframe).then((easyCalendarIframe: HTMLIFrameElement) => {
    if (!easyCalendarIframe) return;

    const easyCalendarElement = easyCalendarIframe.contentWindow.document;
    if (!easyCalendarElement) return;

    observeStyleChanges(user, styleObserver, easyCalendarElement.querySelector(EASY_CALENDAR_SELECTORS.form), formFunction);
    successFunction && observeStyleChanges(user, styleObserver, easyCalendarElement.querySelector(EASY_CALENDAR_SELECTORS.success), successFunction);
  });
}

export const observeStyleChanges = (user: User, styleObserver: MutationObserver, element: HTMLElement, f: Function): void => {
  styleObserver = new MutationObserver((elements: [MutationRecord]) => {
    const htmlElement: MutationRecord = _.first(elements);
    if (!(htmlElement.target as HTMLElement)?.style.display) {
      f(user, htmlElement.target);
    }
  });

  styleObserver.observe(element, {
    attributes: true,
    attributeFilter: ['style']
  });
}

export const fillOutUserForm = (user: any, form: HTMLDivElement) => {
  const [ firstName, lastName ] = user?.name?.split(' ');

  const easyCalendarUser: EasyCalendarUser = { ...user,
    firstName,
    lastName: lastName ?? '',
    phoneNumber: user?.phoneNumber.replace(/\((\+\d*)\)/, ''),
  };

  Object.entries(USER_FORM_MAP).forEach(([userAttribute, selector]) => {
    (<HTMLInputElement>form.querySelector(selector)).value = easyCalendarUser[userAttribute];
  });
}

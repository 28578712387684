import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-dialog-component',
  templateUrl: './dialog-general.component.html',
  styleUrls: ['./dialog-general.component.scss']
})
export class DialogGeneralComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogGeneralComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){}

  cta (action) {
    action.closeModal ? this.closeModal() : action.resolveAction
  }
  closeModal() {this.dialogRef.close()}
  ngOnInit(): void {
  }

}

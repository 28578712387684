import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormControl } from '@angular/forms';
import { NotificationsService } from '@core/services/notifications.service';
import { ResponsiveService } from '@core/services/responsive.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-urn-dialog',
  templateUrl: './urn-dialog.component.html',
  styleUrls: ['./urn-dialog.component.scss'],
})
export class UrnDialogComponent implements OnInit {
  urnLink = new UntypedFormControl('');
  viewMode: string;

  constructor(
    public dialogRef: MatDialogRef<UrnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationsService: NotificationsService,
    private responsiveService: ResponsiveService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.responsiveService.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );
  }

  close() {
    this.dialogRef.close(false);
  }

  save() {
    if (!this.urnLink.value) {
      this.notificationsService.showError(
        'Debe introducir un URN para guardar',
      );

      return;
    }

    const result = {
      urnLink: this.urnLink.value,
    };

    this.dialogRef.close(result);
  }

}
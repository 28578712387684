import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AddressInterface, BudgetInterface, Lead, LeadInterface } from '@core/sdk';
import { AddressOrganizerService, GoogleAddressParsed } from '@core/services/address-organizer.service';
import { LeadService } from '@core/services/lead.service';
import { NotificationsService } from '@core/services/notifications.service';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ProjectUpdateAction } from 'src/app/store/actions/project.actions';

const CITIES = [
  'València',
  'Valencia',
  'Madrid',
  'Barcelona',
]

interface BriefingField {
  type: string;
  name: string;
  description: string;
  value: string;
}
interface BriefingFieldArray {
  type: string;
  name: string;
  description: string;
  value: any[];
}
@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.scss'],
})
export class OpportunityComponent implements OnInit {
  @Input() project: LeadInterface = null;
  @Input() eventStatus: string = null;
  @Input() budget: BudgetInterface = null;

  @ViewChild('locationInput') locationInput: ElementRef;

  originalBriefing: BriefingField[] = [];
  originalBriefingQuiz: any;
  checkedBriefing: BriefingField[] = [];
  commentsHousing: string;
  lowerRange: number;
  topRange: number;
  projectIcon: string;
  originalBriefingQuestions: any[] = [];
  stars = [
    { filled: false },
    { filled: false },
    { filled: false },
    { filled: false },
    { filled: false },
  ];
  
  agentFeeling = 0;
  
  availableBudget : string;
  isEditableField: boolean = false;
  
  editLocationMode: boolean = false;
  newAddress: AddressInterface;

  currentProjectId: string;

  constructor(
    private leadService: LeadService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private store: Store,
    private addressOrganizerService: AddressOrganizerService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if(this.budget) {
      this.initializeComponentWhenBudget();
    } else {
      this.initializeComponent();
    }
    this.setAvaliableBudget();
    this.currentProjectId = this.project.id;
  }
  
  ngAfterViewInit(){
    this.getPlaceAutocomplete();
  }

  private initializeComponentWhenBudget() {
    this.originalBriefingQuestions = this.budget.originalBriefing?.questions;
  }
  
  /**
   * If the project has a briefing, it will be filtered to remove the commentsHousing and lowerRange
   * and topRange fields
   * @returns The return is the value of the field.
   */
  private initializeComponent() {
    this.agentFeeling = this.project.agentFeeling || 0;

    switch (this.project.leadName) {
      case 'Baño':
        this.projectIcon = 'jobType:bathroom';
        break;
      case 'Cocina':
        this.projectIcon = 'jobType:kitchen';
        break;
      case 'Integral':
        this.projectIcon = 'jobType:integral';
        break;
      default:
        this.projectIcon = 'jobType:integral';
        break;
    }

    if (this.project.checkedBriefing) {
      this.checkedBriefing = this.project.checkedBriefing.filter(
        (field) =>
          field.name !== 'commentsHousing' &&
          field.name !== 'lowerRange' &&
          field.name !== 'topRange',
      );
    }

    if (this.project.originalBriefing) {
      if (!this.project.originalBriefing.length) {
        // Is quiz
        this.handleOriginalBriefingQuiz();
        return;
      }

      this.originalBriefing = this.project.originalBriefing.filter(
        (field) =>
          field.name !== 'commentsHousing' &&
          field.name !== 'lowerRange' &&
          field.name !== 'topRange',
      );

      const [
        filteredCommentsHousing,
      ]: BriefingField[] = this.project.originalBriefing.filter(
        (field) => field.name === 'commentsHousing',
      );
      if (filteredCommentsHousing) {
        this.commentsHousing = filteredCommentsHousing.value;
      }
    }

    this.setLowerTopRange();
  }

  /**
   * It takes the originalBriefing.questions array and transforms it into a new array of objects.
   */
  private handleOriginalBriefingQuiz() {
    const questions = this.project.originalBriefing.questions.map(
      (question) => {
        const newResponseLabel = question.responseLabel.replace
          ? question.responseLabel
              .replace('  ', ' ')
              .replace(/#/g, ',')
              .replace(/, $/, '')
          : question.responseLabel;

        return {
          ...question,
          responseLabel: newResponseLabel,
        };
      },
    );

    this.originalBriefingQuiz = questions;
  }

  /**
   * If the project has a briefing, and the briefing has a lowerRange and topRange field, then set the
   * lowerRange and topRange properties to the values of those fields
   */
  setLowerTopRange() {
    if (this.project.originalBriefing) {
      const [lowerRange, topRange] = this.project.originalBriefing.filter(
        (field) => field.name === 'lowerRange' || field.name === 'topRange',
      );

      if (
        !lowerRange ||
        !topRange ||
        lowerRange.value === 'false' ||
        topRange.value === 'false'
      ) {
        return;
      }

      this.lowerRange = this.formatRangeNumbers(lowerRange.value);
      this.topRange = this.formatRangeNumbers(topRange.value);
    }
  }

  /**
   * It removes the € sign and removes the decimal point if there are only two digits after the decimal
   * point.
   * @param {string} rangeNumber - The range number to be formatted.
   * @returns a number.
   */
  formatRangeNumbers(rangeNumber: string) {
    rangeNumber = rangeNumber.replace('€', '').trim();
    const decimalIndex = rangeNumber.lastIndexOf('.');

    if (
      decimalIndex !== -1 &&
      rangeNumber.slice(decimalIndex).replace('.', '').length <= 2
    ) {
      rangeNumber = rangeNumber.slice(0, decimalIndex);
    }

    return parseInt(rangeNumber.replace('.', ''), 10);
  }

  /**
   * Returns true if the object is an array, false otherwise
   * @param {any} object - The object to check.
   * @returns The result of the isArray function.
   */
  isArray(object: any) {
    return Array.isArray(object);
  }

  setProjectFeeling(agentFeelingIndex) {
    const finalFeeling = agentFeelingIndex + 1;

    this.leadService
      .setProjectFeeling(this.project.id, finalFeeling)
      .subscribe(() => {
        this.notificationsService.showSuccess(this.translateService.instant('updated-score'));
        this.agentFeeling = finalFeeling;
      });
  }

  setAvaliableBudget() {
    if(!this.project){
      return;
    }
    
    if ( !this.project.originalBriefing?.quizId ) {
      const [budgetAvailable] = this.project.originalBriefing.filter(
        (field) => field.name === 'budgetAvailable',
      );

      if (budgetAvailable) {
        this.availableBudget = budgetAvailable;
      }
    }

    if (this.project.checkedBriefing) {
      const [budgetAvailable] = this.project.checkedBriefing.filter(
        (field) => field.name === 'budgetAvailable',
      );

      if (budgetAvailable) {
        this.availableBudget = budgetAvailable.value;
      }
    }
  }
  
  editableField = () => this.isEditableField = !this.isEditableField;

  updateProjectCo2(event, field) {
    let regex: RegExp;
    let errorMessage: string;

    if (field === 'weight') {
        regex = /^[0-9]{1,2}\.[0-9]{2}$/;
        errorMessage = 'co2.update-weight-error';
    } else if (field === 'price') {
        regex = /^\d+(\.\d{1,2})?$/;
        errorMessage = 'co2.update-price-error';
    }

    if (!regex.test(event.target.value)) {
        this.notificationsService.showError(this.translateService.instant(errorMessage));
        return;
    }

    const ambientalCost = {
        weight: field === 'weight' ? parseFloat(event.target.value) : this.project.ambientalCost.weight,
        price: field === 'price' ? parseFloat(event.target.value) : this.project.ambientalCost.price
    };

    const projectToUpdate: Update<Lead> = {
        id: this.project.id,
        changes: {
            ambientalCost
        }
    };

    this.store.dispatch(ProjectUpdateAction({ update: projectToUpdate }));
}

  updateLeadName(event){

    const leadName = event.target.value
    
    const projectToUpdate: Update<Lead> = {
            id: this.project.id,
            changes: {
                leadName
            }
        };

    this.store.dispatch(ProjectUpdateAction({ update: projectToUpdate }));
  }

  toggleLocationMode = (type: string) => {
    this.editLocationMode = !this.editLocationMode;

    if(type === 'init'){
      setTimeout(() => {
        this.getPlaceAutocomplete();
      }, 100);

      return;
    }

    if(!CITIES.includes(this.newAddress.city)){
      this.notificationsService.showSuccess(this.translateService.instant('not-service-address'))

      return;
    }
    
    if(type === 'save'){
      this.store.dispatch(ProjectUpdateAction({update: {
        id: this.currentProjectId,
        changes: {
          address:this.newAddress
        }
      } as Update<Lead>}))
    }

    this.newAddress = null;
  }

  getPlaceAutocomplete() {
    if (!this.locationInput || !this.locationInput.nativeElement) {

      return;
    }

    const autocomplete = new google.maps.places.Autocomplete(
      this.locationInput.nativeElement,
      {
        componentRestrictions: { country: 'ES' },
        types: ['geocode'],
      },
    );
    
    return autocomplete.addListener('place_changed', async () => {
      const { address_components, geometry } = autocomplete.getPlace();
      const address: GoogleAddressParsed = this.addressOrganizerService.parseAddress(address_components);
      this.newAddress = {
          address1:address.address,
          city:address.city,
          location:{
            lat:geometry.location.lat(),
            lng:geometry.location.lng(),
          },
          region:address.region,
          zipCode:address.zipCode,
        }
        this.changeDetector.detectChanges();
      });
  }

  selectAll(inputElement: HTMLInputElement) {
    inputElement.select();
  }

}

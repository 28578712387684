import { Directive, Input, ElementRef, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Address } from '@core/sdk';

@Directive({
  selector: '[appMapLocation]',
})
export class MapLocationDirective implements OnInit, OnChanges {
  @Input() position: google.maps.LatLng;
  @Input() address: Address;

  geocoder = new google.maps.Geocoder();

  map: google.maps.Map;
  marker: google.maps.Marker;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.map = this.setMap();
    this.setMarker();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('position' in changes) {
      this.updateMapPosition(changes.position.currentValue);
    }
  }

  setMap() {
    return new google.maps.Map(this.el.nativeElement, {
      center: this.position,
      zoom: 15,
      streetViewControl: false,
    });
  }

  setMarker() {
    if (!this.position) {
      this.geocodeAddress();

      return;
    }

    this.marker = new google.maps.Marker({
      position: this.position,
      map: this.map,
    });
  }

  updateMapPosition(newPosition: google.maps.LatLng) {
    if(!this.marker){

      return;
    }
    
    this.marker.setPosition(newPosition);
    this.map.setCenter(newPosition);
  }

  geocodeAddress() {
    if (!this.address) {
      throw new Error('Address is empty in map');
    }

    const addressToFind = `${this.address.address1} ${this.address.city} ${this.address.zipCode} ${this.address.country}`;

    this.geocoder.geocode(
      { address: addressToFind },
      this.geocodeCallback.bind(this),
    );
  }

  private geocodeCallback(results, status) {
    if (status !== 'OK') {
      throw new Error(
        `Geocode was not successful for the following reason: ${status}`,
      );
    }

    const [
      {
        geometry: { location: newPosition },
      },
    ] = results;

    this.updateMapPosition(newPosition);
  }
}

import { Certification } from "@core/sdk";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { logoutAction } from "../actions/meta.action";
import { certificationAddAction, certificationUpdateAction, certificationsAddAction, certificationDeleteAction, certificationsClearAction, getCertificationsRequestSuccess } from "./certifications.actions";


export interface CertificationsState extends EntityState<Certification> {}

export const certificationsAdapter: EntityAdapter<Certification> = createEntityAdapter<Certification>();
export const initialCertificationsState: CertificationsState = certificationsAdapter.getInitialState();

const certificationsReducerFn = createReducer(
  initialCertificationsState,
  on(certificationAddAction, (state, { ...certification }) => {
    return certificationsAdapter.addOne(certification, state);
  }),
  on(certificationUpdateAction, (state, { update }) => {
    return certificationsAdapter.updateOne(update, state);
  }),
  on(certificationsAddAction, (state, { certifications }) => {
    return certificationsAdapter.addMany(certifications, state);
  }),
  on(certificationDeleteAction, (state, { id }) => {
    return certificationsAdapter.removeOne(id, state);
  }),
  on(certificationsClearAction, (state) => {
    return certificationsAdapter.removeAll(state);
  }),
  on(getCertificationsRequestSuccess, (state, { payload }) => {
    return certificationsAdapter.setAll(payload, state);
  }),
  on(logoutAction, () => initialCertificationsState)
);

export function certificationsReducer(state: CertificationsState | undefined, action: Action) {
  return certificationsReducerFn(state, action);
}

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = certificationsAdapter.getSelectors();

// select the array of user ids
export const selectCertificationsIdsSelector = selectIds;

// select the dictionary of user entities
export const selectCertificationsEntitiesSelector = selectEntities;

// select the array of users
export const selectAllCertificationsSelector = selectAll;

// select the total user count
export const selectCertificationsTotalSelector = selectTotal;

import { Injectable } from '@angular/core';
import environment from '@environment';

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class TestInterceptor implements HttpInterceptor {
  constructor(
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {

    const modified = req.url.includes(environment.apiBaseUrl)
      ? req.clone({setHeaders: {'Test': environment.test.toString()}})
      : req.clone();

    return next.handle(modified);
}
}
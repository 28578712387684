/* eslint-disable */

export interface TrackingInterface {
  id: string;
  budgetItemId?: string;
  certificatePercentage?: number;
  certificatePrice?: number;
  accumulatedPercentage?: number;
  accumulatedPrice?: number;
  nextCertificationPercentage?: number;
  nextCertificationPrice?: number;
  remainingBalance?: number;
  state?: string;
  total?: number;
  advance?: number;
  canceled?: boolean;
  createdAt?: string;
  updatedAt?: Date;
  discussionId?: number;
  discussion?: any; //Future conversationinterface
  notReadMessages?: number;
  budgetItem?: any;
  chapter?: any;
}

export class Tracking implements TrackingInterface {
  id!: string;
  budgetItemId?: string;
  certificatePercentage?: number;
  certificatePrice?: number;
  accumulatedPercentage?: number;
  accumulatedPrice?: number;
  nextCertificationPercentage?: number;
  nextCertificationPrice?: number;
  remainingBalance?: number;
  advance?: number;
  canceled?: boolean;
  state?: string;
  total?: number;
  createdAt?: string;
  updatedAt?: Date;
  discussionId?: number;
  discussion?: any; //Future conversationinterface
  notReadMessages?: number;
  budgetItem?: any;
  chapter?: any;
  constructor(data?: TrackingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Tracking`.
   */
  public static getModelName() {
    return 'Tracking';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Tracking for dynamic purposes.
   **/
  public static factory(data: TrackingInterface): Tracking {
    return new Tracking(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Tracking',
      plural: 'Trackings',
      path: 'Trackings',
      idName: 'id',
      properties: {
        quantity: {
          name: 'quantity',
          type: 'number',
        },
        product: {
          name: 'product',
          type: 'any',
        },
        taxPercent: {
          name: 'taxPercent',
          type: 'number',
          default: 2100,
        },
        id: {
          name: 'id',
          type: 'number',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
        chapterId: {
          name: 'chapterId',
          type: 'number',
        },
      },
      relations: {
        chapter: {
          name: 'chapter',
          type: 'Chapter',
          model: 'Chapter',
          relationType: 'belongsTo',
          keyFrom: 'chapterId',
          keyTo: 'id',
        },
      },
    };
  }
}

<mat-chip-list #chipList>
  <div class="wrapper">
    <form [formGroup]="chipFormGroup">
      <div class="custom-input text-xs" class="chip-control">
        <mat-form-field
          appearance="outline"
          color="primary"
          class="limit-width"
        >
          <input
            matInput
            placeholder="{{ inputPlaceholderSlug | translate }}"
            class="input"
            #input
            [matAutocomplete]="profilesAutocomplete"
            formControlName="chipName"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addChip($event)"
            data-cy="chip-list"
          />
        </mat-form-field>

        <mat-autocomplete
          #profilesAutocomplete="matAutocomplete"
          (optionSelected)="selectedOption($event)"
        >
          <mat-option
            *ngFor="let option of filteredOptions | async"
            [value]="option"
          >
            {{ option | translate }}
          </mat-option>
        </mat-autocomplete>

        <button
          mat-flat-button color="accent" 
          (click)="addChip()"
          data-cy="chip-list-add">
          {{ (viewMode === 'web' ? buttonSlug : '+') | translate }}
        </button>
      </div>
    </form>

    <div class="list-chip">
      <mat-chip
        *ngFor="let chip of chips"
        [selectable]="false"
        [removable]="true"
        (removed)="removeChip(chip)"
        data-cy="chip-name"
      >
        {{ chip | translate }}
        <mat-icon matChipRemove data-cy="remove-chip-name">cancel</mat-icon>
      </mat-chip>
    </div>
  </div>
</mat-chip-list>

import { createAction, props } from '@ngrx/store';

export const ErrorOccurredAction = createAction(
  '[ERROR] Error occurred',
  props<{ payload }>()
);

export const ErrorLoggedAction = createAction(
  '[ERROR] Error logged');

export const ErrorSendingToSentryAction = createAction(
  '[ERROR] Error sending error to Sentry',
  props<{ payload }>()
);
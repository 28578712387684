import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private _sidenavToggle$ = new Subject<void>();
  private _sidenavOpen$ = new Subject<void>();
  private _sidenavClose$ = new Subject<void>();
  private _iconMode$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  getSidenavToggle() {
    return this._sidenavToggle$.asObservable();
  }

  sidenavToggle() {
    this._sidenavToggle$.next();
  }

  getSidenavOpen() {
    return this._sidenavOpen$.asObservable();
  }

  sidenavOpen() {
    this._sidenavOpen$.next();
  }

  getSidenavClose() {
    return this._sidenavClose$.asObservable();
  }

  sidenavClose() {
    this._sidenavClose$.next();
  }

  getIconMode() {
    return this._iconMode$;
  }

  iconModeToggle() {
    this._iconMode$.next(!this._iconMode$.getValue());
  }
}

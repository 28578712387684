<div class="header">
  <div class="left">
    <mat-icon *ngIf="data.svgIcon" [svgIcon]="data.svgIcon"></mat-icon>
    <div class="title">
      <span>{{ data.title | translate }}</span>
      <span class="subtitle">{{ data.subtitle | translate }}</span>
    </div>
  </div>
  <button mat-icon-button mat-dialog-close *ngIf="data.showCloseButton">
    <mat-icon svgIcon="24px-close"></mat-icon>
  </button>
</div>

<form class="body" [formGroup]="textForm">
  <mat-form-field appearance="outline" color="primary" class="description">
    <textarea
      matInput
      rows="4"
      type="text"
      formControlName="longText"
    ></textarea>

    <mat-error
      *ngIf="textForm.get('longText').hasError('minlength')"
      [innerHTML]="'2-characters-error' | translate"
      data-cy="long-text-min-length"
    >
    </mat-error>
    <mat-error
      *ngIf="textForm.get('longText').hasError('maxlength')"
      [innerHTML]="'1000-characters-error' | translate"
      data-cy="long-text-max-length"
    >
    </mat-error>
  </mat-form-field>
</form>

<div class="buttons">
  <button
    mat-stroked-button
    color="primary"
    mat-dialog-close
    *ngIf="data.showCancelButton"
  >
    {{ data.cancel ? data.cancel : ('cancel' | translate) }}
  </button>

  <button
    mat-flat-button
    color="accent"
    [mat-dialog-close]="textForm.get('longText').value"
    [disabled]="textForm.invalid"
  >
    {{ (data.cta ? data.cta : 'accept') | translate }}
  </button>
</div>

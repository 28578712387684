import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BudgetInterface } from '@core/sdk';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { LocalStorageService } from '@core/services/local-storage.service';
import { BudgetsTemplateService } from '@core/services/budgets-template.service';


@Component({
  selector: 'app-budget-template-selector',
  templateUrl: './budget-template-selector.component.html',
  styleUrls: ['./budget-template-selector.component.scss'],
})
export class BudgetTemplateSelectorComponent implements OnInit {
  @Output()
  budgetClicked: EventEmitter<BudgetInterface> = new EventEmitter<BudgetInterface>();

  duplicateOptionForm: UntypedFormGroup;
  selected: number;
  budgets = [];
  _filteredBudgets = [];
  filteredBudgets$ = new BehaviorSubject<BudgetInterface[]>([]);
  budgetSelected = '';
  isCubicupAgent:boolean = false;
  agentID;

  constructor(
    private fb: UntypedFormBuilder,
    private budgetsTemplateService: BudgetsTemplateService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit() {
    this.agentID = this.localStorageService.get('agentId');
    this.isCubicupAgent = this.localStorageService.get('userEmail')?.endsWith('@cubicup.com');
    this.initializeForm();
    this.getAgentTemplates();
  }

  private initializeForm() {
    this.duplicateOptionForm = this.fb.group({
      searchText: new UntypedFormControl('', []),
    });

    this.duplicateOptionForm
      .get('searchText')
      .valueChanges.subscribe(() => this.handleSearch());
  }
  private checkFilter(){
    if (this.isCubicupAgent){
      return {
        where: {
          type: 'template',
          or: [{
            agentId: this.agentID
          }, {
              cype: true
          }],
        },
      };
    }
    return {
      where: {
        type: 'template',
        agentId:this.agentID,
      },
    };
  }


  private getAgentTemplates() {

    const filter = this.checkFilter();
    if (this.isCubicupAgent){
      this.budgetsTemplateService
      .getBudgetTemplatesByFilter(filter)
      .subscribe((budgets: BudgetInterface[]) => {
      this.budgets = budgets['budgets'];
      const realBudgets = this.budgets.filter(
        (budgets) => budgets.agentId !== null,
      );
      this._filteredBudgets = realBudgets;

      this.filteredBudgets$.next([...this._filteredBudgets]);
      });
    }else{
      this.budgetsTemplateService
      .getBudgetTemplates(filter)
      .subscribe((budgets) => {
      this.budgets = budgets;
      this._filteredBudgets = budgets;
      this.filteredBudgets$.next(budgets);
      });
    }
  }

  handleSearch() {
    let searchResult = this._filteredBudgets;
    let searchTextValue = this.duplicateOptionForm.get('searchText').value;

    if (searchTextValue) {
      searchTextValue = searchTextValue.toLowerCase();

      searchResult = this._filteredBudgets.filter((budget) =>
        budget.title.toLowerCase().includes(searchTextValue),
      );
    }

    this.filteredBudgets$.next(searchResult);
  }

  clearSearch() {
    this.duplicateOptionForm.get('searchText').setValue('');
  }

  selectBudget(budget, budgetIndex) {
    this.selected = budgetIndex;
    this.budgetSelected = `${budget.title}`;
    this.budgetClicked.emit(budget);
  }
}

import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver-es';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'src/assets/font/pdfMakeFonts/vfs_fonts';
import { AdblockService } from './adblock.service';
import { NotificationsService } from './notifications.service';
import * as _ from 'lodash-es';
import environment from '@environment';
import { AgentInterface, LeadInterface } from '@core/sdk';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  HkGrotesk: {
    normal: 'hkgrotesk-regular.ttf',
    bold: 'hkgrotesk-medium.woff',
    italics: 'hkgrotesk-italic.ttf',
    bolditalics: 'hkgrotesk-mediumitalic.ttf',
  },
};

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor(
    private adblockService: AdblockService,
    private notificationsService: NotificationsService,
    private httpClient: HttpClient,
  ) {}

  private parseAgent(agent: AgentInterface) {
    const { tradeName, documents, contact, address, vatId, image } = agent;
    const logoDocument = documents?.filter((doc) => doc.key === 'log');

    return {
      tradeName,
      documents: logoDocument,
      contact,
      address,
      vatId,
      image
    };
  }

  parseLead(lead: LeadInterface) {
    const { address, contact, agent } = lead;
    const result = lead.agent ? { address, contact, agent } : { address, contact };

    return result;
  }

  async pdfmake(agent, objectToPrint, lead, templateType, showImage = true) {
    const adBlockActivated = this.adblockService.detectAdBlock(1);

    if (adBlockActivated) {
      this.notificationsService.showError(
        'Por favor, desactiva el Ad Blocker para poder descargar el presupuesto.',
      );
      return;
    }

    switch (templateType.toLowerCase()) {
      case 'budget':
        this.httpClient
          .post(
            `${environment.microservices.pdfBaseUrl}/downloadpdf`,
            {
              agent: agent ? this.parseAgent(agent) : null,
              budget: objectToPrint,
              lead: this.parseLead(lead),
              showImage
            },
            { responseType: 'blob' },
          )
          .subscribe((res) => {
            const blob = new Blob([res], { type: 'application/pdf;base64' });
            const file = new File([blob], `${objectToPrint.title}.pdf`, {
              type: 'application/pdf;base64',
            });

            saveAs(file);
          });
        break;
      case 'certification':
        this.httpClient
          .post(
            `${environment.microservices.pdfBaseUrl}/downloadpdf`,
            { agent: this.parseAgent(agent), certification: objectToPrint, lead: this.parseLead(lead), showImage },
            { responseType: 'blob' },
          )
          .subscribe((res) => {
            const blob = new Blob([res], { type: 'application/pdf;base64' });
            const fileName = objectToPrint.title ??
              `Certificación ${lead.address.address1} ${objectToPrint.order}`;
            const file = new File([blob], `${fileName}.pdf`, {
              type: 'application/pdf;base64',
            });

            saveAs(file);
          });
        break;
      case 'ppv-leroy':
        return this.httpClient
          .post(
            `${environment.microservices.pdfBaseUrl}/downloadpdf`,
            {
              agent: agent ? this.parseAgent(agent) : null,
              budget: objectToPrint,
              lead: this.parseLead(lead),
              showImage,
              company: 'leroy'
            },
            { responseType: 'blob' },
          )
          .subscribe((budget) => {
            const blob = new Blob([budget], { type: 'application/pdf;base64' });
            const file = new File([blob], `estimacion.pdf`, {
              type: 'application/pdf;base64',
            });

            const mergeOrder = ['header_leroy.pdf', `estimacion.pdf`, 'footer_leroy.pdf'];

            const formData = new FormData();
            formData.append('order', JSON.stringify(mergeOrder));
            formData.append('context', 'leroy');
            formData.append('pdfs', file);

            this.httpClient
            .post(
              `${environment.microservices.pdfBaseUrl}/mergepdf`,
              formData,
              { responseType: 'blob' },
            )
            .subscribe((mergedBudgetBinary) => {
              const file = new File([mergedBudgetBinary], `Estimacion de Presupuesto.pdf`, {
                type: 'application/pdf;base64',
              });

              saveAs(file);
            })
          });
      default:
        break;
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';
import {
  BudgetInterface,
  LeadInterface,
  ClientInterface,
  Agent,
} from 'src/app/core/sdk';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { BUDGET_STATES_CLASSES } from '@core/constants';

@Component({
  selector: 'app-preview-header',
  templateUrl: './preview-header.component.html',
  styleUrls: ['./preview-header.component.scss'],
})
export class PreviewHeaderComponent implements OnInit {
  @Input() lead: LeadInterface;
  @Input() budget: BudgetInterface;
  @Input() client: ClientInterface;

  detailsExpanded$ = new BehaviorSubject<boolean>(false);
  context: string;
  agentBudget: Agent;

  constructor(private store: Store<{ agent: Agent; context: any }>) {}

  ngOnInit() {
    this.store.select('context').subscribe(({ value: context }) => {
      this.context = context;

      if (this.context === 'agent') {
        this.store
          .select('agent')
          .subscribe((agent) => (this.agentBudget = agent));
        return;
      }

      this.agentBudget = this.lead.agent;
    });
  }

  toggleDetails() {
    this.detailsExpanded$.next(!this.detailsExpanded$.getValue());
  }

  getBudgetStateClass(state: string): { [key: string]: boolean} {
    return {
      'state--pending': BUDGET_STATES_CLASSES['state--pending'].includes(state),
      'state--blocked': BUDGET_STATES_CLASSES['state--blocked'].includes(state),
      'state--accepted': BUDGET_STATES_CLASSES['state--accepted'].includes(state),
    }
  }
}

import { Action, createReducer, on, State } from '@ngrx/store';
import {
  ClientAddOrUpdateAction,
  ClientAddOrUpdateFieldAction,
  ClientRemoveAction,
} from '../actions/client.actions';
import { Client } from '@core/sdk';
import * as _ from 'lodash-es';
import { logoutAction } from '../actions/meta.action';

export const initialState: Client | any = {};

const clientReducer = createReducer(
  initialState,
  on(ClientAddOrUpdateAction, (state, client) => ({
    ...state,
    ...client,
  })),
  on(ClientRemoveAction, (state, action) => ({})),
  on(ClientAddOrUpdateFieldAction, (state, { field, value }) => {
    const newState = _.cloneDeep(state);

    _.set(newState, field, value);

    return {
      ...newState,
    };
  }),
  on(logoutAction, (state) => initialState),
);

export function reducer(state: State<any> | undefined, action: Action) {
  return clientReducer(state, action);
}

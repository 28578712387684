import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AuthService } from '@core/services/auth.service';
import { matchPassword } from '@shared/validators';
import { LoaderService } from '@core/services/loader.service';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {
  private userEmail: string;
  private token: string;
  hidePassword: boolean = true;
  // private agentID: string;
  // private isAgent = false;

  newPasswordFormGroup: UntypedFormGroup;

  private unsubscriber : Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private loader: LoaderService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.initializeForm();

    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  /**
   * Initialize the form with the email and token from the URL
   */
  private initializeForm() {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
    this.userEmail = this.activatedRoute.snapshot.paramMap.get('email');

    if (!this.token) {
      throw new Error('User token not found!');
    }

    if (!this.userEmail) {
      throw new Error('User email ID not found!');
    }

    this.newPasswordFormGroup = this.formBuilder.group(
      {
        email: new UntypedFormControl(this.userEmail, [
          Validators.required,
          Validators.email,
        ]),
        newPassword: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
        ]),
        confirmPassword: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
        ]),
      },
      {
        validators: [matchPassword],
      },
    );

    this.newPasswordFormGroup.controls.email.disable();

    this.loader.disable();
  }

  /**
   * It calls the changePersonPassword function from the authService.
   * @returns Nothing.
   */
  submitChangePassword() {
    if (this.newPasswordFormGroup.invalid) {
      return;
    }

    const newPassword = this.newPasswordFormGroup.get('newPassword').value;

    this.authService
      .changePersonPassword(this.token, this.userEmail, newPassword)
      .subscribe(() =>
        this.router.navigate(['/login'])
      );
  }
}

<section [ngClass]="{ accepted: visit.state === 'accepted' }">
  <div class="signal">
    <div
      class="circle"
      [ngClass]="{ accepted: visit.state === 'accepted' }"
    ></div>
  </div>

  <div class="body">
    <div class="header">
      <div class="icon-wrapper">
        <mat-icon svgIcon="24px-calendar"></mat-icon>
      </div>
      <span class="title">
        {{
          (visit.state !== 'accepted' ? 'event-soon' : 'accepted-event')
            | translate
        }}
      </span>
    </div>

    <div class="subbody">
      <span
        class="info"
        [ngClass]="{
          'portfolio-link':
            visit.agent?.portfolio && visit.agent?.portfolio.state === 'public'
        }"
        [matTooltip]="
          (visit.agent?.portfolio && visit.agent?.portfolio.state === 'public'
            ? 'see-landing'
            : ''
          ) | translate
        "
        matTooltipPosition="above"
        (click)="goToAgentPortfolio()"
      >
        {{ visit.agent?.tradeName }}
      </span>

      <div class="info">
        {{ visit.date | date: 'shortDate':'UTC' }}
        {{ visit.date | momentDate: 'shortTime':'Europe/Madrid' }}
      </div>
    </div>
  </div>
</section>

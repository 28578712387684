import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import environment from '@environment';
import { UserInterface } from '@core/sdk';
import { Store } from '@ngrx/store';
import { take, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ForgeService {

  constructor(
    private httpClient: HttpClient,
    private store: Store<{user: UserInterface}>
  ) {

  }

  getBuckets(id: string | null = null) {
    let url = `${environment.apiBaseUrl}/api/forge/oss/buckets`;

    if (id) {
      url += `?id=${id}`
    }

    return this.httpClient.get(url);
  }

  getForgeToken() {
    const url = `${environment.apiBaseUrl}/api/forge/oauth/token`;

    return this.httpClient.get(url);
  }

  getDesignData(accessToken: string, urn: string) {
    const url = 'https://developer.api.autodesk.com/modelderivative/v2/designdata/' + urn + '/manifest';

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + accessToken
      }),
    };

    return this.httpClient.get(url, httpOptions);
  }
}

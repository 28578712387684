import { Component, OnInit, OnDestroy, ViewChild, OnChanges } from '@angular/core';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { Router, RouterEvent, NavigationEnd, Event } from '@angular/router';

import {
  Observable,
  Subject,
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  Subscription,
} from 'rxjs';

import environment from '@environment';

import {
  Lead,
  AgentInterface,
  Client,
  UserInterface,
} from '@core/sdk/models';
import { LeadService } from '@core/services/lead.service';
import { ResponsiveService } from '@core/services/responsive.service';
import { SidenavService } from '@core/services/sidenav.service';
import { takeUntil, filter, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ClientService } from '@core/services/client.service';
import { selectAllProjects } from 'src/app/store/selectors/project.selectors';
import { MixpanelService } from '@core/services/mixpanel.service';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})

export class SidenavComponent implements OnInit, OnDestroy {

  _sidenavMode: 'web' | 'handset' | 'tablet';

  miniLogoPath = 'assets/img/logo-mini-light.svg';
  primaryLogoPath = 'assets/img/logo-logo-primary-light.svg';
  primaryLogoUrl = '';
  clientToken: string;
  projectID: string;
  project$: Observable<Lead>;
  loginClient$: Observable<Client>;
  sidebarType = 'agent';
  context$ = new ReplaySubject<string>(1);
  agentCubicupPartnerCode$ = new Subject<string>();
  agent$ = new BehaviorSubject<AgentInterface>(null);
  sidenavMode$: Observable<'web' | 'tablet' | 'handset'>;
  iconMode$ = new BehaviorSubject<boolean>(false);
  loaderWidth$ = new BehaviorSubject<string>('83.3333333333%');
  mainLogoUrl$ = new BehaviorSubject<string>('mini');
  showUpButton = false;
  currentUrl: string;
  unsubscriber$ = new Subject<void>();
  isMobile: boolean;
  agent: AgentInterface;
  projects$ = new BehaviorSubject<Lead[]>([]);
  superAdmin = false;
  role: string = '';

  public b2b: boolean;
  public ppv: boolean;
  private clientSubscription: Subscription;



  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('thatOtherSideNav', { static: true })
  sidenavContent: MatSidenavContent;
  context: any;

  constructor(
    private projectService: LeadService,
    private router: Router,
    private responsiveService: ResponsiveService,
    private sidenavService: SidenavService,
    private store: Store<{
      client: Client;
      context: any;
      agent: AgentInterface;
      projects: Lead[];
      user: UserInterface;
    }>,
    private storeUser: Store<{ user: UserInterface }>,
    private clientService: ClientService,
    private mixpanelService: MixpanelService,
  ) { }

  ngOnInit() {
    this.initializeSideBar();
    this.store
      .select('context')
      .pipe(take(1))
      .subscribe(({ value: context }) => {
        if (!context) {
          return;
        }

        this.context$.next(context);
      });
    this.getClientDataFromStore();
    this.currentUrl = this.router.routerState.snapshot.url.split('/')[1];
    this.router.events
      .pipe(
        takeUntil(this.unsubscriber$),
        filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        this.currentUrl = event.url.split('/')[1];
      })

    this.isMobile = this.responsiveService.mobileCheck();
    this.storeUser
      .select('user')
      .subscribe((user) => {
        this.superAdmin = user.superAdmin;
        this.role = user.roles ?
          user.roles[0].name
          : user.role;
      });  
  }

  private getClientDataFromStore():void {
    this.clientSubscription = this.store.select('client').subscribe(({b2b, ppv}) => {
      this.b2b = b2b;
      this.ppv = ppv;
    });
  }

  private getClientProjects() {
    this.store.select(selectAllProjects).subscribe((projects) => {
      this.projects$.next(projects);
    });
  }

  private initializeSideBar() {
    this.sidenavMode$ = this.responsiveService.mode;

    combineLatest([this.sidenavMode$, this.iconMode$]).subscribe(
      ([sidenaveMode, iconMode]) => {
        this._sidenavMode = sidenaveMode;

        if (sidenaveMode === 'web' && iconMode) {
          this.loaderWidth$.next('100%');
          this.mainLogoUrl$.next('mini');
        }

        if (sidenaveMode === 'web' && !iconMode) {
          this.loaderWidth$.next('83.3333333333%');
          this.mainLogoUrl$.next('primary');
        }

        if (sidenaveMode === 'handset') {
          this.loaderWidth$.next('100%');
          this.mainLogoUrl$.next('primary');
        }
      },
    );

    this.sidenavService
      .getSidenavToggle()
      .subscribe(() => (this.sidenav ? this.sidenav.toggle() : null));

    this.sidenavService
      .getSidenavClose()
      .subscribe(() => (this.sidenav ? this.sidenav.close() : null));

    this.sidenavService
      .getSidenavOpen()
      .subscribe(() => (this.sidenav ? this.sidenav.open() : null));

    this.context$.subscribe((context) => {
      this.context = context;

      switch (context) {
        case 'agent':
          this.initializeAgentSideBar();
          break;
        case 'client':
          this.initializeClientSideBar();
          break;
        case 'login-client':
          this.initializeLoginClientSideBar();
          break;
        default:
          break;
      }
    });
  }

  private initializeAgentSideBar() {
    this.store.select('agent').subscribe((agent) => {
      if (!agent || Object.keys(agent).length === 0) {
        return;
      }

      this.agent = agent;
      this.agent$.next(agent);
    });
  }

  private initializeClientSideBar() {
    this.sidebarType = 'client';
    this.project$ = this.projectService.getProjectsFromStore();

    this.project$.subscribe((project) => {
      if (!project) {
        return;
      }

      this.projectID = project.id;
    });
  }

  private initializeLoginClientSideBar() {
    this.sidebarType = 'login-client';
    this.loginClient$ = this.store.select('client');

    this.getClientProjects();
  }

  toggleIconMode() {
    this.iconMode$.next(!this.iconMode$.getValue());
  }

  goTo(url) {
    if (url === 'help') {
      const urlToGo =
        this.sidebarType === 'client' || this.context === 'login-client'
          ? environment.helpLink?.client
          : environment.helpLink?.agent;

      window.open(urlToGo, '_blank');

      return;
    }

    if (this._sidenavMode && this._sidenavMode === 'handset') {
      this.sidenav.close();
    }

    switch (this.context) {
      case 'agent':
        if (url === 'landing') {
          this.router.navigate([url, this.agent.id]);

          return;
        }

        this.router.navigate([url]);
        break;
      case 'login-client':
        this.mixpanelService.eventEmitter('click-sidenav-'+ url, { context: this.context });
        if (url === 'projects') {
          const projectsCount = this.projects$.value.length
          if (projectsCount > 1) {
            this.router.navigate(['logged-client', 'projects']);

            return;
          }

          const [firstProject] = this.projects$.value;

          this.router.navigate(['logged-client', 'project', firstProject.id]);

          break;
        }

        this.router.navigate(['logged-client', url]);
        break;
      default:
        this.router.navigate([url]);
        break;
    }
  }

  handleScroll(event) {
    const { scrollTop } = event.srcElement;

    if (scrollTop > 200) {
      this.showUpButton = true;

      return;
    }

    this.showUpButton = false;
  }

  goToTop() {
    this.sidenavContent.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  ngOnDestroy() {
    this.responsiveService.unsubscribeAll();
    this.unsubscriber$.next();
    this.clientSubscription.unsubscribe();
  }
}

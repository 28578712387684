import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  AbstractControl,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Person } from 'src/app/core/sdk';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Params, Router } from '@angular/router';
import { AuthService } from '../../../core/services/auth.service';
import { NotificationsService } from '@core/services/notifications.service';
import environment from '../../../../environments/environment';
import { Store } from '@ngrx/store';
import {
  AuthRegisterAgentAction,
  AuthRegisterAgentFailureCleanAction,
} from 'src/app/store/actions/auth.actions';
import { selectQueryParams } from 'src/app/store/router.selectors';

declare function sendFacebookPixel(registerType): any;

@Component({
  selector: 'app-register-agent',
  templateUrl: './register-agent.component.html',
  styleUrls: ['./register-agent.component.scss'],
})
export class RegisterAgentComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();

  registerForm: UntypedFormGroup;
  firstCheck = false;
  sendingForm = false;
  registerButtonLabel = 'start';
  externalAgent = false;
  isFacebookLead = false;
  utmSource = {};
  hidePassword: boolean = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private store: Store<{ router: any; user: any }>,
 
  ) {}

  ngOnInit() {
    this.getUrlParams();
    this.initializeTranslations();
    this.handleErrors();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * The function sets the default language to Spanish and then sets the current language to Spanish
   */
  private initializeTranslations() {
    this.translateService.setDefaultLang('es_ES');
    this.translateService.use('es_ES');
  }

  /**
   * If the user is not logged in, then the user is redirected to the login page
   */
  private handleErrors() {
    this.store.select('user', 'error').subscribe((error) => {
      if (!error) {
        return;
      }

      const { nestedError } = error;

      if (nestedError) {
        const { statusText } = nestedError;

        if (statusText !== 'Unknown error') {
          this.registerForm.controls.email.setErrors({ incorrect: true });
          this.registerForm.controls.email.markAsTouched();
        }
      }

      this.toggleRegisterButtonLabel();
      this.store.dispatch(AuthRegisterAgentFailureCleanAction());
    });
  }

  /**
   * If the query params contain the utm_source, utm_medium, and utm_campaign, then we know that this
   * is a Facebook lead. Otherwise, we initialize the form with the default values
   */
  private getUrlParams() {
    this.store
      .select(selectQueryParams)
      .pipe(takeUntil(this.unsubscribe$), filter(Boolean))
      .subscribe((queryParams: Params) => {
        if (
          queryParams &&
          queryParams.utm_source &&
          queryParams.utm_medium &&
          queryParams.utm_campaign
        ) {
          const { utm_source, utm_medium, utm_campaign } = queryParams;

          this.isFacebookLead = true;
          this.utmSource = {
            utm_source,
            utm_medium,
            utm_campaign,
          };
        }

        if (queryParams && queryParams.cpc && queryParams.email) {
          const { cpc: cubicupPartnerCode, email: newUserEmail } = queryParams;

          this.initializeForm(cubicupPartnerCode, newUserEmail);
          return;
        }

        this.initializeForm();
        this.externalAgent = true;
      });
  }

  /**
   * It initializes the form.
   * @param [cubicupPartnerCode] - The partner code that the user entered.
   * @param [newUserEmail] - The email address of the new user.
   */
  private initializeForm(cubicupPartnerCode?, newUserEmail?) {
    this.registerForm = this.formBuilder.group({
      cubicupPartnerCode: [cubicupPartnerCode ? cubicupPartnerCode : '', []],
      email: [
        newUserEmail ? newUserEmail : '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          ),
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
      agentN: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
        ],
      ],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
          Validators.pattern(/^([0-9]{3} ){2}[0-9]{3}$/),
        ],
      ],
      zipCode: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
          Validators.pattern(/^(?:0?[1-9]|[1-4]\d|5[0-2])\d{3}$/),
        ],
      ],
      termsAndConditions: [false, Validators.required],
    });

    if (
      this.registerForm.get('cubicupPartnerCode').value &&
      this.registerForm.get('email').value
    ) {
      this.registerForm.get('cubicupPartnerCode').disable();
      this.registerForm.get('email').disable();
    }

    this.registerForm.controls.termsAndConditions.valueChanges.subscribe(
      (newValue) => {
        if (!newValue) {
          return;
        }

        this.firstCheck = true;
      },
    );
  }

  /**
   * Toggle the label of the register button depending on whether the form is being sent or not
   * @returns Nothing.
   */
  private toggleRegisterButtonLabel() {
    this.sendingForm = !this.sendingForm;

    if (!this.sendingForm) {
      this.registerForm.controls.email.enable();
      this.registerForm.controls.password.enable();
      this.registerForm.controls.phoneNumber.enable();
      this.registerForm.controls.termsAndConditions.enable();
      this.registerButtonLabel = 'create-account';

      return;
    }

    this.registerForm.controls.email.disable();
    this.registerForm.controls.password.disable();
    this.registerForm.controls.phoneNumber.disable();
    this.registerForm.controls.termsAndConditions.disable();
    this.registerButtonLabel = 'creating-account';
  }

  /**
   * If the control has a validator, and the validator has a required property, then return true
   * @param controlName - The name of the control to be validated.
   * @returns The validation object.
   */
  requiredValidator(controlName) {
    const { validator } = this.registerForm.controls[controlName];

    if (!validator) {
      return false;
    }

    const validation = validator({} as AbstractControl);

    if (validation && validation.required) {
      return true;
    }

    return false;
  }

  /**
   * It registers the agent.
   * @returns The registerAgent() method is called when the user clicks on the register button.
   *     The method checks if the form is valid and if the terms and conditions checkbox is checked.
   *     If the form is valid and the checkbox is checked, the method will call the
   * AuthRegisterAgentAction
   *     action.
   *     The AuthRegisterAgentAction action will dispatch the register agent
   */
  registerAgent() {
    if (
      this.registerForm.invalid ||
      !this.registerForm.controls.termsAndConditions.value
    ) {
      return;
    }

    this.toggleRegisterButtonLabel();

    const newPerson = {
      ...this.registerForm.getRawValue(),
      phoneNumber: this.registerForm.controls.phoneNumber.value
        .replace(/\s+/g, '')
        .replace('+', ''),
      username: this.registerForm.controls.agentN.value,
      address: {
        zipCode: this.registerForm.get('zipCode').value,
      },
    };

    delete newPerson.agentN;

    const person = new Person(newPerson);

    this.store.dispatch(
      AuthRegisterAgentAction({
        person,
        utmSource: this.utmSource,
        externalAgent: this.externalAgent,
        isFacebookLead: this.isFacebookLead,
      }),
    );
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContextState } from '../context/context.reducer';

export const selectContextState = createFeatureSelector<ContextState>('context');

export const selectHeaderLinks = createSelector(
  selectContextState,
  (contextState: ContextState) => {
    return Object.values(contextState.navigation.header.links);
  }
);
export const selectActions = createSelector(
  selectContextState,
  (contextState: ContextState) => {
    return Object.values(contextState.navigation.actions);
  }
  
)
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { ClientService } from '@core/services/client.service';
import { Store } from '@ngrx/store';
import { ClientInterface } from '@core/sdk';

@Injectable({
  providedIn: 'root',
})
export class ClientAuthGuard  {
  constructor(
    private router: Router,
    private clientService: ClientService,
    private authService: AuthService,
    private clientStore: Store<{ client: ClientInterface }>,
  ) {}

  async canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    const userIsAuthenticated = this.authService.isAuthenticated();
    const userRole = await this.authService.getUserContext();
    const fromURL = this.router.url;
    const toURL = state.url;
    
    if (
      ((fromURL.includes('logged-client') || fromURL.includes('/')) &&
        !toURL.includes('logged-client')) ||
      (fromURL.includes('/') && toURL === '/logged-client')
    ) {
      if (userRole === 'login-client' && userIsAuthenticated) {
        if(fromURL === '/logged-client/projects') {
          return true;
        }
        this.router.navigate(['logged-client', 'projects']);
        return;
      }
    }

    if (!fromURL.includes('logged-client') && toURL.includes('logged-client')) {
      if (userRole === 'agent' && userIsAuthenticated) {
        this.router.navigate(['/events']);
        return;
      }
    }

    if (state.url.includes('login') || state.url.includes('register')) {
      if (!userIsAuthenticated) {
        return true;
      }

      this.router.navigate(['logged-client', 'projects']);

      return;
    }

    if (!userIsAuthenticated) {
      this.router.navigate(['/login']);

      return;
    }

    const clientValid = this.clientService.isClientValid();

    if (!clientValid) {
      this.authService.logout();

      return;
    }

    return true;
  }
}

import { Injectable } from '@angular/core';
import { Lead } from '@core/sdk';
import { LeadService } from '@core/services/lead.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import {
  addProject,
  getProjectRequestStarted,
  getProjectRequestSuccess,
  getProjectTimelineRequestStarted,
  getProjectTimelineRequestSuccess,
  ProjectUpdateAction,
  ProjectUpdateFailureAction,
  ProjectUpdateSuccessAction,
} from '../actions/project.actions';
import { NotificationsService } from '@core/services/notifications.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ProjectEffects {
  public getProject$ = this.getProject();
  public addProject$ = this.addProject();
  public addProjectTimeline$ = this.addProjectTimeline();
  public getProjectTimeline$ = this.getProjectTimeline();
  public updateProject$ = this.updateProject();
  private showSuccessNotifications$ = this.showSuccessNotification();
  private showErrorNotifications$ = this.showErrorNotification();

  constructor(
    private actions$: Actions, 
    private leadService: LeadService, 
    private notificationsService: NotificationsService,
    private translateService: TranslateService
    ) { }

  private getProject() {
    return createEffect(() => this.actions$.pipe(
      ofType(getProjectRequestStarted),
      mergeMap(
        ({ projectId }: { projectId: string }) =>
          this.leadService.getProjectById(projectId) as Observable<Lead>,
      ),
      filter(Boolean),
      map((project: Lead) => getProjectRequestSuccess({ project })),
    ));
  }

  private addProject() {
    return createEffect(() => this.actions$.pipe(
      ofType(getProjectRequestSuccess),
      map(({ project }: { project: Lead }) =>
        addProject({
          project,
        }),
      ),
    ));
  }

  private getProjectTimeline() {
    return createEffect(() => this.actions$.pipe(
      ofType(getProjectTimelineRequestStarted),
      mergeMap(
        ({ projectId }: { projectId: string }) =>
          this.leadService.getProjectTimelineById(projectId) as Observable<Lead>,
      ),
      filter(Boolean),
      map((project: Lead) => getProjectTimelineRequestSuccess({ project })),
    ));
  }

  private addProjectTimeline() {
    return createEffect(() => this.actions$.pipe(
      ofType(getProjectTimelineRequestSuccess),
      map(({ project }: { project: Lead }) => {
        const projectUpdate: Update<Lead> = {
          id: project.id,
          changes: { timeline: project.timeline },
        };

        return ProjectUpdateAction({
          update: projectUpdate,
        });
      }),
    ));
  }

  updateProject() {
    return createEffect(() => this.actions$.pipe(
      ofType(ProjectUpdateAction),
      mergeMap(({ update, showNotification }) => {
        return this.leadService.updateProject(update.id, update.changes).pipe(
          map(() => {
            return ProjectUpdateSuccessAction({showNotification});
          }),
          catchError((error) => {
            return of(ProjectUpdateFailureAction({ error }));
          })
        );
      })
    ));
  }

  showSuccessNotification() {
    return createEffect(() => this.actions$.pipe(
      ofType(ProjectUpdateSuccessAction),
      filter(({ showNotification }) => showNotification !== false),
      tap(() => {
        this.notificationsService.showSuccess(this.translateService.instant('project-updated-successfully'));
      })
    ), { dispatch: false });
  }

  showErrorNotification() {
    return createEffect(() => this.actions$.pipe(
      ofType(ProjectUpdateFailureAction),
      tap((action) => {
        this.notificationsService.showError(this.translateService.instant('error-updating-project'));
      })
    ), { dispatch: false })
  }
}

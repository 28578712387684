import { Action, createAction, props } from '@ngrx/store';
import { ClientInterface } from '@core/sdk';

export const ClientAddOrUpdateAction = createAction(
  '[Client Component] Add',
  props<ClientInterface>(),
);

export const ClientRemoveAction = createAction(
  '[Client Component] Remove',
  props<any>(),
);

export const ClientAddOrUpdateFieldAction = createAction(
  '[Client Component] AddOrUpdateFieldAction',
  props<any>(),
);

import {
  Directive,
  Input,
  ElementRef,
  OnChanges,
  Optional,
  Self,
  OnInit,
} from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';
declare const google: any;

enum Kind {
  Radius,
  Poligon,
}

@Directive({
  selector: '[appMapZoneDefinition]',
})
export class MapZoneDefinitionDirective
  implements OnInit, OnChanges, ControlValueAccessor {
  private _paths: google.maps.LatLng[];
  private _onChange: any;
  private _onTouched: any;

  @Input() position: google.maps.LatLng | any;
  @Input() zipCode: string | number;
  @Input() zoom: number;
  // @Input() zoomRadiusPairs: {radius: number; zoom: number}[];
  @Input() radius: number;
  @Input() editable = false;

  // @Input()
  // get paths(): google.maps.LatLng[] {
  //   return this._paths;
  // }

  // set paths(paths: google.maps.LatLng[]) {
  //   this._onChange(paths);
  //   this._paths = paths;
  // }

  map: google.maps.Map;
  polygon: google.maps.Polygon;
  drawingManager: google.maps.drawing.DrawingManager;

  constructor(
    private el: ElementRef,
    @Self() @Optional() public controlDir: NgControl,
  ) {
    controlDir.valueAccessor = this;
  }

  ngOnInit() {
    this.setMap();
    this.setPolygon();
    this.setZipCodePosition();
  }

  writeValue(paths: google.maps.LatLng[]) {
    this._paths = paths;
  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  ngOnChanges() {
    if (this.map) {
      this._paths = [];
      this.map.setZoom(this.zoom);
      this.setZipCodePosition();
    }
  }

  setZipCodePosition() {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { address: `${this.zipCode}, spain` },
      this.geoCodeProcess.bind(this),
    );
  }

  geoCodeProcess(result) {
    if (!result.length) {
      return;
    }

    const [firstResult] = result;
    const { location } = firstResult.geometry;

    this.position = {
      lat: location.lat(),
      lng: location.lng(),
    };

    this.map.setCenter(this.position);
    this.setPaths();
  }

  setMap() {
    this.map = new google.maps.Map(this.el.nativeElement, {
      center: this.position,
      zoom: this.zoom,
    });
  }

  setPolygon() {
    this.polygon = new google.maps.Polygon({
      strokeColor: 'black',
      strokeOpacity: 0.8,
      strokeWeight: 0.5,
      fillColor: 'black',
      fillOpacity: 0.25,
      editable: this.editable,
      map: this.map,
    });

    if (!this.editable) {
      return;
    }

    google.maps.event.addListener(this.polygon, 'mouseup', () => {
      this._paths = this.polygon.getPaths().getAt(0).getArray();
      this._onChange(this._paths);
    });
  }

  setPaths() {
    if (!this.polygon) {
      return;
    }

    const paths =
      this._paths && this._paths.length
        ? this._paths
        : this.drawCircle(this.position, this.radius, 1);

    this.polygon.setPaths([paths]);
  }

  setDrawing() {
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.MARKER,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ['marker', 'circle', 'polygon', 'polyline', 'rectangle'],
      },
    });
    this.drawingManager.setMap(this.map);
  }

  drawCircle(point, radius, dir): google.maps.LatLng[] {
    const degreesToRadians = Math.PI / 180; // degrees to radians
    const radiansToDegrees = 180 / Math.PI; // radians to degrees
    const earthRadius = 6378; // 6378 is the radius of the earth in kilometers
    const points = 8;

    // find the radius in lat/lon
    const rlat = (radius / earthRadius) * radiansToDegrees;
    const rlng = rlat / Math.cos(point.lat * degreesToRadians);

    const extp = new Array();
    const start = dir === 1 ? 0 : points + 1; // one extra here makes sure we connect the path
    const end = dir === 1 ? points + 1 : 0;

    for (let i = start; dir === 1 ? i < end : i > end; i = i + dir) {
      const theta = Math.PI * (i / (points / 2));
      const ey = point.lng + rlng * Math.cos(theta); // center a + radius x * cos(theta)
      const ex = point.lat + rlat * Math.sin(theta); // center b + radius y * sin(theta)

      extp.push(new google.maps.LatLng(ex, ey));
    }

    return extp;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MtxTooltipModule } from '@ng-matero/extensions/tooltip';
import { MtxPopoverModule } from '@ng-matero/extensions/popover';
import { MtxLoaderModule } from '@ng-matero/extensions/loader';


@NgModule({
  declarations: [],
  imports: [CommonModule, MtxTooltipModule, MtxPopoverModule, MtxLoaderModule],
  exports: [MtxTooltipModule, MtxPopoverModule, MtxLoaderModule],
})
export class MaterialExtensionModule {}

/* eslint-disable */

declare var Object: any;
export interface ChapterTemplateInterface {
  title?: string;
  description?: string;
  order?: number;
  id?: string;
  agentId?: number;
  modelActive?: any;
  createdAt?: string;
}

export class ChapterTemplate implements ChapterTemplateInterface {
  'title': string;
  'description': string;
  'order': number;
  'id': string;
  'agentId': number;
  'modelActive': any;
  createdAt?: string;

  constructor(data?: ChapterTemplateInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Chapter`.
   */
  public static getModelName() {
    return 'ChapterTemplate';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Chapter for dynamic purposes.
   **/
  public static factory(data: ChapterTemplateInterface): ChapterTemplate {
    return new ChapterTemplate(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ChapterTemplate',
      plural: 'ChapterTemplates',
      path: 'ChapterTemplates',
      idName: 'id',
      properties: {
        title: {
          name: 'title',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        order: {
          name: 'order',
          type: 'number',
        },
        id: {
          name: 'id',
          type: 'number',
        },
        agentId: {
          name: 'agentId',
          type: 'number',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
      },
    };
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import environment from '@environment';
import { UserInterface } from '@core/sdk';
import { Store } from '@ngrx/store';
import { take, switchMap } from 'rxjs/operators';
import { SessionStorageService } from './session-storage.service';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private dateModalOpenSource = new BehaviorSubject<boolean>(false);
  dateModal$ = this.dateModalOpenSource.asObservable();
  
  constructor(
    private httpClient: HttpClient,
    private sessionStorage: SessionStorageService,
    private store: Store<{user: UserInterface}>
  ) {

  }

  checkModal(modalName): any {
    return this.store.select('user')
    .pipe(
      take(1),
      switchMap((user) => {
        const url = `${environment.apiBaseUrl}/api/Modals`;

        const httpOptions = {
          headers: new HttpHeaders({
            filter: JSON.stringify({
              where: {
                title: modalName,
                personId: user.userId
              },
            }),
          }),
        };

        return this.httpClient.get(url, httpOptions);
      })
    );
  }

  setNotShowModal(modalId): any {
    const url = `${environment.apiBaseUrl}/api/Modals/${modalId}`;

    return this.httpClient.patch(url, { mustShow: false });
  }

  getReasons(nameCollection: string) {
    const filter = {
      where: {
        type: nameCollection,
      },
    };
    
    const clientToken = this.sessionStorage.get('clientToken');
    const httpOptions = !clientToken ? {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
      }),
    } :  {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
        'anonymous-client-token': clientToken,
      }),
    }; 

    const url = `${environment.apiBaseUrl}/api/Reasons`;

    return this.httpClient.get(url, httpOptions);
  }

  openDateModal() {
    this.dateModalOpenSource.next(true);
  }

  closeDateModal() {
    this.dateModalOpenSource.next(false);
  }
}

import { Directive, OnInit, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appInputPhoneFormat]',
})
export class InputPhoneFormatDirective implements OnInit {
  constructor(private control: NgControl) {}

  ngOnInit() {
    this.format();
  }

  @HostListener('keyup') onInputChange() {
    this.format();
  }

  private parseRawPhoneNumber(raw) {
    // Spanish mobile number form: XXX XXX XXX
    return `${raw.slice(0, 3)} ${raw.slice(3, 6)} ${raw.slice(6, 9)}`;
  }

  format() {
    const { value } = this.control;

    if (!value) {
      return;
    }

    const raw = value.replace(/\W/g, '').replace(/[^0-9]/g, '');

    if (raw.length > 9) {
      const newRaw = (raw as string).slice(0, raw.length - 1);
      const newFormatted = this.parseRawPhoneNumber(newRaw);

      this.control.valueAccessor.writeValue(newFormatted);
      this.control.control.setValue(newFormatted);

      return;
    }

    const formatted = this.parseRawPhoneNumber(raw);

    this.control.valueAccessor.writeValue(formatted);
    this.control.control.setValue(formatted);
  }
}

import { ChapterInterface } from './Chapter';
import { DiscountItem } from './DiscountItem';

type stateStatus = 'created' | 'pending' | 'accepted' | 'rejected';

export interface CertificationInterface {
  id: string;
  leadId?: string;
  budgetItems?: string[];
  discountItems?: string[];
  discounts?: DiscountItem[];
  total?: number;
  state?: stateStatus;
  createdAt?: Date;
  updatedAt?: Date;
  modelActive?: {
    active: boolean;
    description: string;
  };
  terms?: string;
  comments?: string;
  stateLog?: {
    date: Date;
    state: stateStatus;
  }[];
  credit?: number;
  endPaymentMethodCredit?: number;
}

export class Certification implements CertificationInterface {
  id!: string;
  leadId?: string;
  budgetItems?: string[];
  discountItems?: string[];
  discounts?: DiscountItem[];
  total?: number;
  state?: stateStatus;
  createdAt?: Date;
  updatedAt?: Date;
  modelActive?: {
    active: boolean;
    description: string;
  };
  terms?: string;
  comments?: string;
  stateLog?: {
    date: Date;
    state: stateStatus;
  }[];
  credit?: number;
  endPaymentMethodCredit?: number;

  constructor(data?: CertificationInterface) {
    Object.assign(this, data);
  }

  public static getModelName() {
    return 'Certification';
  }
  
}

export class CertificationView {
  total?: number;
  terms?: string;
  id?: string;
  stateLog?: object[];
  state?: string;
  order?: number;
  modelActive?: object;
  lastModifiedBy?: string;
  createdBy?: string;
  chapters?: ChapterInterface[];
  createdAt?: Date;
  updatedAt?: Date;
  credit?: number;
  taxPercent?: number;
  totalWithTaxes?: number;
  
  constructor(data?: Partial<CertificationView>) {
    this.total = data?.total;
    this.terms = data?.terms;
    this.id = data?.id;
    this.stateLog = data?.stateLog || [];
    this.state = data?.state;
    this.order = data?.order;
    this.modelActive = data?.modelActive;
    this.lastModifiedBy = data?.lastModifiedBy;
    this.createdBy = data?.createdBy;
    this.chapters = data?.chapters || [];
    this.createdAt = data?.createdAt ? new Date(data.createdAt) : undefined;
    this.updatedAt = data?.updatedAt ? new Date(data.updatedAt) : undefined;
    this.taxPercent = data?.taxPercent;
    this.totalWithTaxes = data?.totalWithTaxes;
    this.credit = data?.credit;
  }
}


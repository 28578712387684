import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
})
export class ReadMoreComponent implements OnInit, AfterViewInit {
  private _collapsed = false;

  @Input()
  set collapsed(value) {
    this._collapsed = typeof value !== 'undefined' && value !== false;
  }
  get collapsed(): boolean {
    return this._collapsed;
  }

  @Input() height: number = 100;
  @Input() collapsedText: string = 'Leer Más';
  @Input() expandedText: string = 'Leer Menos';
  @Input() show: boolean = true;
  @ViewChild('content') content!: ElementRef;

  isCollapsed = false;
  _show = false;
  elementWrapped!: HTMLDivElement;

  constructor(private renderer: Renderer2, private cdref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.isCollapsed = this.collapsed;
  }

  ngAfterViewInit(): void {
    this.elementWrapped = this.content.nativeElement;

    if (this.elementWrapped.clientHeight > this.height && this.show) {
      this._show = true;

      if (this.isCollapsed) {
        this.renderer.addClass(this.elementWrapped, 'collapsed');
        this.renderer.setStyle(
          this.elementWrapped,
          'height',
          this.height + 'px',
        );
        this.cdref.detectChanges();
      }
    }
  }

  isOverflown({ clientWidth, clientHeight, scrollWidth, scrollHeight }) {
    return scrollHeight > clientHeight || scrollWidth > clientWidth;
  }

  collapse(isCollapsed: boolean) {
    this.isCollapsed = isCollapsed;

    if (isCollapsed) {
      this.renderer.addClass(this.elementWrapped, 'collapsed');
      this.renderer.setStyle(this.elementWrapped, 'height', this.height + 'px');
    }

    if (!isCollapsed) {
      this.renderer.addClass(this.elementWrapped, 'collapsed');
      this.renderer.removeStyle(this.elementWrapped, 'height');
    }
  }
}

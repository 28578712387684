import { Injectable } from '@angular/core';
import environment from '@environment';
import * as mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  constructor() {}

  register(personInfo) {
    if (!environment.mixpanel.active) {
      return;
    }

    mixpanel.register({ ...personInfo });
  }

  eventEmitter(eventName: string, eventMetadata: object) {
    if (!environment.mixpanel.active) {
      return;
    }

    mixpanel.track(eventName, eventMetadata);
  }

  reset() {
    if (!environment.mixpanel.active) {
      return;
    }

    mixpanel.reset();
  }

  identify(uuid) {
    if (!environment.mixpanel.active) {
      return;
    }

    mixpanel.identify(uuid);
  }

  setPeople(peopleData) {
    if (!environment.mixpanel.active) {
      return;
    }

    mixpanel.people.set({ ...peopleData });
  }
}

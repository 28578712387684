import { Component, OnInit, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NotesModalComponent } from './notes-modal/notes-modal.component';
import * as _ from 'lodash-es';
import { Store } from '@ngrx/store';
import { AgentInterface, ClientInterface, LeadInterface, UserInterface } from '@core/sdk';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notes-center',
  templateUrl: './notes-center.component.html',
  styleUrls: ['./notes-center.component.scss'],
})
export class NotesCenterComponent implements OnInit {
  @Input() notes: any[] = [];
  @Input() target: any;
  @Input() targetType: string;

  context: any;
  isSuperAdmin = false;

  constructor(
    private dialog: MatDialog,
    private store: Store<{
      agent: AgentInterface;
      client: ClientInterface;
      projects: LeadInterface[];
      context: any;
      user: UserInterface
    }>,
    private router: Router
  ) {}

  ngOnInit() {
    this.store
      .select('context')
      .subscribe(({ value: context }) => (this.context = context));

    this.store
      .select('user')
      .subscribe((user) => (this.isSuperAdmin = user.superAdmin));
  }

  openNotesModal(note = null) {
    const googleContext = this.context === 'agent' ? 'agent' : 'client';

    const notesModal = this.dialog.open(NotesModalComponent, {
      width: '800px',
      height: '600px',
      data: {
        target: this.target,
        targetType: this.targetType,
        note,
      },
    });

    notesModal.afterClosed().subscribe(({ note }) => {
      if (!note) {
        return;
      }

      this.notes.push(note);
    });
  }

  goToTracking() {
    if (this.context === 'agent') {
      this.router.navigate(['tracking', this.target]);

      return;
    }

    if (this.context === 'login-client') {
      const { id, trackingLeadId } = this.target;
      const finalTrackingLeadId = trackingLeadId ?? id;

      this.router.navigate(['logged-client', 'tracking', finalTrackingLeadId]);
      
      return;
    }
  }
}

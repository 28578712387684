import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap } from 'rxjs/operators';
import { getCertificationsRequestSuccess, getCertificationsRequestStarted } from '../certifications/certifications.actions'
import { LeadService } from '@core/services/lead.service';
import { Certification } from '@core/sdk/models/Certification';
import { CertificationService } from '@core/services/certification.service';

@Injectable()
export class CertificationsEffects {
  public certifications$ = this.getCertifications() 
  
  constructor(
    private actions$: Actions, 
    private certificationService: CertificationService
    ) {}
  
    private getCertifications() {
      return createEffect(() => this.actions$.pipe(
        ofType(getCertificationsRequestStarted),
        filter(({ certifications }) => certifications === undefined || certifications.length === 0),
        mergeMap(({ payload }) =>
          this.certificationService.getCertificationsByProject(payload)
        ),
        map((certifications: Certification[]) => { 
          return getCertificationsRequestSuccess({ payload: certifications });
        }),
      ));
    }
}
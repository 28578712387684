import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AgentService } from '@core/services/agent.service';
import { NotificationsService } from '@core/services/notifications.service';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { selectRouteParams } from 'src/app/store/router.selectors';

@Component({
  selector: 'app-review-request',
  templateUrl: './review-request.component.html',
  styleUrls: ['./review-request.component.scss'],
})
export class ReviewRequestComponent implements OnInit, OnDestroy {
  private userEmail: string;
  private token: string;
  private agentID: string;

  reviewRequestForm: UntypedFormGroup;
  disabledAll = false;
  stars = [
    { filled: false },
    { filled: false },
    { filled: false },
    { filled: false },
    { filled: false },
  ];
  agentName = { value: '' };

  unsubscriber$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private agentService: AgentService,
    private notificationsService: NotificationsService,
    private store: Store<{ router: any }>,
  ) {}

  ngOnInit() {
    this.initializeForm();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  private initializeForm() {
    this.store
      .select(selectRouteParams)
      .pipe(
        takeUntil(this.unsubscriber$),
        filter((params) =>
          Boolean(params && params.token && params.email && params.agentID),
        ),
      )
      .subscribe(
        ({
          token,
          email,
          agentID,
        }: {
          token: string;
          email: string;
          agentID: string;
        }) => {
          this.token = token;
          this.userEmail = email;
          this.agentID = agentID;

          if (!this.token) {
            throw new Error('User token not found!');
          }

          if (!this.userEmail) {
            throw new Error('User email not found!');
          }

          if (!this.agentID) {
            throw new Error('User agent ID not found!');
          }

          this.agentService
            .getAgentLandingInfo(this.agentID)
            .subscribe((agent) => (this.agentName.value = agent.tradeName));

          this.reviewRequestForm = this.formBuilder.group({
            score: ['', Validators.required],
            comment: ['', Validators.required],
          });
        },
      );
  }

  setReviewScoring(reviewScoringIndex) {
    const finalClientScore = reviewScoringIndex + 1;

    this.reviewRequestForm.get('score').setValue(finalClientScore);
  }

  sendResponse() {
    if (this.reviewRequestForm.invalid) {
      return;
    }

    this.agentService
      .sendReviewResponse(
        this.token,
        this.agentID,
        this.userEmail,
        this.reviewRequestForm.value,
      )
      .subscribe(() => {
        this.notificationsService.showSuccess('Valoración enviada');
        this.disabledAll = true;

        window.location.href = 'https://cubicup.com/';
      });
  }
}

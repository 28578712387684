<div *ngIf="viewMode === 'handset' || galleryMode === 'galleryTemplate'; else webTemplate" >
  <gallery
    gallerize="items-gallery"
    [items]="items"
  >
    <ng-container *galleryThumbDef="let item; let type = type; let i = index;" class="bg-black">
      <span *ngIf="type === 'video'" class="bg-black">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM4 5v14h16V5H4zm6.622 3.415l4.879 3.252a.4.4 0 0 1 0 .666l-4.88 3.252a.4.4 0 0 1-.621-.332V8.747a.4.4 0 0 1 .622-.332z" fill="rgba(255,255,255,1)"/></svg>
      </span>
      <button *ngIf="type === 'image'" class="download-icon" (click)="downloadFile(item , i)">
        <mat-icon svgIcon="24px:download"></mat-icon>
      </button>
    </ng-container>
  </gallery>
</div>

<ng-template #webTemplate>
  <div *ngIf="images.length" class="gallery-web" gallerize="image-gallery">
    <div *ngFor="let image of images; let i = index" class="relative image-item">
      <button *ngIf="galleryMode === 'galleryTemplate'" mat-icon-button class="button-remove-image" (click)="removeImage(i, 'image')">
        <mat-icon svgIcon="24px:delete"></mat-icon>
      </button>
      <mat-icon *ngIf="context === 'agent' && image.visible !== null && !image.visible" matTooltipPosition="above"  class="absolute top-0 right-0 bg-[#909090A0] text-red-600 border border-[#007958] m-2.5" svgIcon="24px-hide"></mat-icon>
        <mat-icon *ngIf="context === 'agent' && image.visible !== null && image.visible" matTooltipPosition="above"  class="absolute top-0 right-0 bg-[#909090A0] border border-[#007958] m-2.5" svgIcon="24px-show"></mat-icon>
      <img ngClass="viewMode === 'last-updates' ? !max-w-full !h-auto : ''"
        [src]="image.versions[0].fileUrl"
      />
    </div>

  </div>

  <div *ngIf="videos.length" class="flex wrap gap-2.5" ngClass="viewMode === 'handset' ? pt-4 : !pt-1 !gap-0.9" gallerize="video-gallery">
    <div *ngFor="let video of videos; let i = index" class="video-item relative bg-white p-1.5 rounded">
        <button *ngIf="galleryMode === 'galleryTemplate'" mat-icon-button class="button-remove-image" (click)="removeImage(i, 'video')">
          <mat-icon svgIcon="24px:delete"></mat-icon>
        </button>
        <mat-icon *ngIf="context === 'agent' && video.visible !== null && !video.visible" matTooltipPosition="above"  class="absolute top-0 right-0 bg-[#909090A0] text-red-600 border border-[#007958] m-2.5" svgIcon="24px-hide"></mat-icon>
        <mat-icon *ngIf="context === 'agent' && video.visible !== null && video.visible" matTooltipPosition="above"  class="absolute top-0 right-0 bg-[#909090A0] border border-[#007958] m-2.5" svgIcon="24px-show"></mat-icon>
      <video  ngClass="viewMode === 'last-updates' ? !w-full !max-h-full : ''" controls>
        <source [src]="video.versions[0].fileUrl" type="video/mp4">
      </video>
      <span class="text-sm inline-block w-full text-center">{{video.versions[0]?.fileName ?? ''}}</span>
    </div>
  </div>
</ng-template>





import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LeadInterface, AgentInterface, ClientInterface } from '../../core/sdk';
import { Observable, Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-mini-person-data',
  templateUrl: './mini-person-data.component.html',
  styleUrls: ['./mini-person-data.component.scss'],
})
export class MiniPersonDataComponent implements OnInit, OnDestroy {
  @Input() lead$: Observable<LeadInterface>;
  @Input() agent: AgentInterface;
  @Input() client: ClientInterface;
  @Input() lead: LeadInterface;

  image: string;
  name: string;
  idNumber = '&nbsp;';
  address: string;
  city: string;
  zipCode: number;
  email: string;
  phoneNumber: string;
  code: string
  shortCode: string

  unsubscriber = new Subject<void>();

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.client && this.lead) {

      if (!this.lead.client) {
        this.name = this.client.contact.name;
        this.initializeLeadData(this.lead, this.client);
      }else{
        this.name = this.lead.client.contact.name;

        this.initializeLeadData(this.lead, this.lead.client);
      }

      return;
    }

    if (this.lead$) {
      this.lead$
        .pipe(takeUntil(this.unsubscriber))
        .subscribe((lead) => this.initializeLeadData(lead,  this.client));
    }

    if (this.agent && Object.keys(this.agent).length > 0) {
      this.initializeAgentData();
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private initializeAgentData() {
    let defaultAgentAddress = '';

    if (this.agent.address) {
      defaultAgentAddress = this.agent.address.address2
        ? `${this.agent.address.address1}, ${this.agent.address.address2}`
        : this.agent.address.address1;
    }

    this.name = this.agent.tradeName;
    this.idNumber = this.agent.vatId;
    this.address = defaultAgentAddress;
    this.city = this.agent.address ? this.agent.address.city : '';
    this.zipCode = this.agent.address ? this.agent.address.zipCode : '';
    this.email = this.agent.contact.email;
    this.phoneNumber = this.agent.contact.phoneNumber;
    // this.image = this.agent.image ? this.agent.image : 'assets/img/default-avatar.png';
    const agentHasDocumentLogo = this.agent.documents && this.agent.documents[0].versions?.length > 0;
    const documentLogo = agentHasDocumentLogo
        ? this.agent.documents[0].versions[0].fileUrl
        : 'assets/img/default-avatar.png';
    

    this.image = this.agent.image
      ? (this.domSanitizer.bypassSecurityTrustUrl(this.agent.image) as string)
      : documentLogo;
  }

  private initializeLeadData(leadData:LeadInterface, clientData:ClientInterface) {
    if (!leadData) {
      return;
    }

    let defaultLeadAddress = '';


    if (leadData.address) {
      defaultLeadAddress = leadData.address.address2
        ? `${leadData.address.address1}, ${leadData.address.address2}`
        : leadData.address.address1;
    }

    this.name = clientData.contact.name || this.lead.contact.name;

    if (clientData) {
      this.idNumber = clientData.contact.idNumber ?? '&nbsp;';
    }

    this.address = clientData.address.address1 
    this.city = clientData.address.city 
    this.zipCode = clientData.address.zipCode 
    this.email = clientData.contact.email 
    this.phoneNumber = clientData.contact.phoneNumber 
    this.code = leadData.code;
    this.shortCode = leadData.shortCode;
    // this.image = 'assets/img/default-avatar.png';
  }
}

<div class="header" *ngIf="context==='agent'">
    <app-go-back></app-go-back>
    <app-select-action [actions$]="actionsBudgets$"></app-select-action>
</div>

<table *ngIf="!isMobile; else mobileListView"
  mat-table [dataSource]="dataSource"
  matSort data-cy="budget-table"
>
    <caption>
      <section id="caption-section">
        <section id="table-caption">
          {{
            caption | translate
          }}

        </section>
        <ng-container [formGroup]="form" *ngIf="context === 'agent'">
          <section id="show-archived-section" data-cy="show-archived">
            <mat-checkbox
              formControlName="showArchivedControl"
              id="show-archived-checkbox"
            >
              <span>
                {{ 'show-archived' | translate }}
              </span>
            </mat-checkbox>
          </section>
        </ng-container>
      </section>
    </caption>

    <ng-container matColumnDef="agent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ firstColumnSlug | translate }}
      </th>
      <td mat-cell *matCellDef="let budget" class="agent-name">
        <div class="agent-name-wrapper">
          <div
            *ngIf="!budget?.code?.includes('simulation'); else simulationTest"
          >
            <ng-template #cubicupLogo>
            </ng-template>
          </div>
          <mat-icon  class="image" svgIcon="24px:logoCircle"></mat-icon>
          <span>{{ budget?.agent?.tradeName }}</span>
        </div>
        <ng-template #simulationTest>No aplica</ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'name' | translate }}
      </th>
      <td mat-cell *matCellDef="let budget">
        <span class="title-truncate">{{ budget.title }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'type' | translate }}
      </th>
      <td mat-cell *matCellDef="let budget" >
        <span class="title-truncate" *ngIf="budget.type">{{  budget.type | translate }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="subType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'subType' | translate }}
      </th>
      <td mat-cell *matCellDef="let budget" >
        <span class="title-truncate" >{{  budget.subType | translate }}</span>
      <td mat-cell *matCellDef="let budget">
        <span
        [ngClass]="{
          'bg-budgets-annex p-1 text-budgets-draftText': budget.type === 'draft',
        }"
        >{{ budget.type | translate }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'state' | translate }}
      </th>
      <td mat-cell *matCellDef="let budget">
        {{
          (budget?.code?.includes('simulation') ? 'No aplica' : budget.state)
            | translate
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'price' | translate }}
      </th>
      <td mat-cell *matCellDef="let budget">
        {{ budget.total | currency: 'EUR' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'create-date' | translate }}
      </th>
      <td mat-cell *matCellDef="let budget">
        {{ budget.createdAt | date: 'dd/MM/yy':'Europe/Madrid' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sendingDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'sendingDate' | translate }}
      </th>
      <td mat-cell *matCellDef="let budget">
        {{ budget.stateLog | stateLog: 'pendingOfAnswer'| date: 'dd/MM/yy':'Europe/Madrid' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="contextual">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let budget" class="contextual">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          (click)="$event.stopPropagation()"
          data-cy="more-details"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            (click)="goToBudgetPreview(budget)"
            data-cy="details-budget"
          >
            {{ 'presentation-budget' | translate }}
          </button>

          <div *appHasPermission="'budget.update'">
            <button
              mat-menu-item
              *ngIf="budget.type === 'draft' && context !== 'login-client'"
              (click)="goToBudgetEdition(budget.id)"
              data-cy="edit-budget"
            >
              {{ 'edit-budget' | translate }}
            </button>

            <button
              mat-menu-item
              *ngIf="
                budget.type === 'budget' &&
                !clientToken &&
                context !== 'login-client' &&
                project &&
                !project.archived
              "
              (click)="openBudgetAsDraft(budget.id)"
            >
              {{ 'open-as-draft' | translate }}
            </button>

            <button
              mat-menu-item
              *ngIf="
                (!budget.archived ||
                budget.archived===false) &&
                !clientToken &&
                context !== 'login-client' &&
                project &&
                !project.archived
              "
              (click)="archiveBudget(budget)"
              data-cy="archive-budget"
            >
              {{ 'archive-budget' | translate }}
            </button>

            <button
              mat-menu-item
              *ngIf="
                canBeUnarchived(budget) &&
                budget.archived===true &&
                !clientToken &&
                context !== 'login-client' &&
                project &&
                !project.archived
              "
              (click)="unarchiveBudget(budget)"
              data-cy="unarchive-budget"
            >
              {{ 'unarchive-budget' | translate }}
            </button>

            <ng-container *ngIf="budget.state==='blocked' && project && !project.tracking">
              <button
                mat-menu-item
                *appHasPermission="'budget.unlock'"
                (click)="unblockBudget(budget)"
                data-cy="unblock"
              >
                {{ 'unblock' | translate }}
              </button>
            </ng-container>

            <ng-container *ngIf="budget?.subType==='annex' && budget.type === 'draft'">
              <button
                *appHasPermission="'budget.update'"
                mat-menu-item
                (click)="openAnnexToBudgetAndTrackingModal(budget)"
              >
                {{ 'add-annex-tracking' | translate }}
              </button>
            </ng-container>
          </div>

          <div *appHasPermission="'budget.delete'">
            <button
              mat-menu-item
              *ngIf="
                (budget.type === 'draft' || budget?.code?.includes('simulation')) &&
                !clientToken
              "
              (click)="deleteBudget(budget)"
              data-cy="delete-budget"
            >
              <ng-container *ngIf="budget.type === 'draft'; else removeSimulation">
                {{ 'delete-draft' | translate }}
              </ng-container>

              <ng-template #removeSimulation>
                {{ 'delete-simulation' | translate }}
              </ng-template>
            </button>
          </div>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let budget;let i = index; columns: displayedColumns"
      [attr.data-cy]="'cy-row-' + i"
      [ngClass]="{
        annex: budget?.subType === 'annex',
        ppv: budget?.subType === 'ppv',
        draft: budget.type === 'draft',
        budget: budget.type === 'budget',
        archived: budget.archived
      }"
      class="border-l-4"
      [ngClass]="getTableRowClass(budget)"
      (click)="goToBudget(budget)"
    >
  </tr>
</table>
<mat-paginator
  *ngIf="!isMobile"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 100]"
></mat-paginator>


<ng-template #mobileListView>
  <div class="text-xl font-semibold text-neutral-darkest mt-2 ml-3">{{ 'budgets' | translate }}</div>

  <div *ngIf="budgets$ | async" class="flex-col pl-3 pb-3 pr-3 pt-0">
    <div *ngFor="let budget of budgets" class="flex-col indicator mt-4 w-full" (click)="goToBudgetPreview(budget)">
      <span class="indicator-item indicator-center badge bg-emerald-500" [ngClass]="getBudgetStateClasses(budget.state)">{{budget.state | translate }}</span>

      <div class="stats shadow bg-white">
        <div class="stat" (onClick)="navigateToBudget(budget)">
          <div class="stat-title text-lg text-gray-800 truncate title-collapse-start">{{ budget.title }}</div>
          <div class="stat-value font-medium text-xl text-center">{{ budget.total | number:'1.2-2' }} €</div>
          <div class="stat-desc text-center">{{ 'sendingDate' | translate }} <span>{{budget.stateLog | stateLog: "pendingOfAnswer" | date:'dd MMMM yyyy' }}</span></div> 
          <div *ngIf="context==='agent'" class="contextual flex justify-end">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
              data-cy="more-details"
            >
              <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="goToBudgetPreview(budget)"
                data-cy="details-budget"
              >
                {{ 'presentation-budget' | translate }}
              </button>
    
              <div *appHasPermission="'budget.update'">
                <button
                  mat-menu-item
                  *ngIf="budget.type === 'draft'"
                  (click)="goToBudgetEdition(budget.id)"
                  data-cy="edit-budget"
                >
                  {{ 'edit-budget' | translate }}
                </button>
    
                <button
                  mat-menu-item
                  *ngIf="
                    budget.type === 'budget' &&
                    !clientToken && project &&
                    !project.archived
                  "
                  (click)="openBudgetAsDraft(budget.id)"
                >
                  {{ 'open-as-draft' | translate }}
                </button>
    
                <button
                  mat-menu-item
                  *ngIf="
                    (!budget.archived ||
                    budget.archived===false) &&
                    !clientToken && project &&
                    !project.archived
                  "
                  (click)="archiveBudget(budget)"
                  data-cy="archive-budget"
                >
                  {{ 'archive-budget' | translate }}
                </button>
    
                <button
                  mat-menu-item
                  *ngIf="
                    canBeUnarchived(budget) &&
                    budget.archived===true &&
                    !clientToken && project &&
                    !project.archived
                  "
                  (click)="unarchiveBudget(budget)"
                  data-cy="unarchive-budget"
                >
                  {{ 'unarchive-budget' | translate }}
                </button>
    
                <ng-container *ngIf="budget?.subType==='annex' && budget.type === 'draft'">
                  <button
                    *appHasPermission="'budget.update'"
                    mat-menu-item
                    (click)="addAnnexToBudgetAndTracking()"
                  >
                    {{ 'add-annex-tracking' | translate }}
                  </button>
                </ng-container>
              </div>
    
              <div *appHasPermission="'budget.delete'">
                <button
                  mat-menu-item
                  *ngIf="
                    (budget.type === 'draft' || budget?.code?.includes('simulation')) &&
                    !clientToken
                  "
                  (click)="deleteBudget(budget)"
                  data-cy="delete-budget"
                >
                  <ng-container *ngIf="budget.type === 'draft'; else removeSimulation">
                    {{ 'delete-draft' | translate }}
                  </ng-container>
    
                  <ng-template #removeSimulation>
                    {{ 'delete-simulation' | translate }}
                  </ng-template>
                </button>
              </div>
            </mat-menu>
          </div>         
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="py-3 px-4 my-6 mx-3 bg-[#FFFFFF] lg:flex flex-row gap-4 items-center justify-between w-2/5 hidden" *ngIf="context === 'agent'">
  <div class="flex gap-2 items-center">
    <div class="bg-budgets-annexed min-w-6 min-h-6 border rounded-md"></div>
    <p class="text-xs">{{ 'annexed' | translate }}</p>
  </div>
  <div class="divider divider-horizontal"></div>
  <div class="flex gap-2 items-center">
    <div class="bg-budgets-annex min-w-6 min-h-6 border rounded-md"></div>
    <p class="text-xs">{{ 'proposal' | translate }}</p>
  </div>
  <div class="divider divider-horizontal"></div>
  <div class="flex gap-2 items-center">
    <div class="bg-budgets-archived min-w-6 min-h-6 border rounded-md"></div>
    <p class="text-xs">{{ 'archived-element' | translate }}</p>
  </div>
</div>

<cub-annex-to-tracking *ngIf="openAnnexToTrackingModal" 
(closeModalEmitter)="openAnnexToTrackingModal = false"
(addAnnexToTrackingEmitter)="addAnnexToBudgetAndTracking()" 
[project]="project"
></cub-annex-to-tracking>

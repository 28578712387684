import { Action, createAction, props } from '@ngrx/store';

export class ActionTypes {
  static LOGOUT = '[App] logout';
}

export class Logout implements Action {
  readonly type = ActionTypes.LOGOUT;
}

export const logoutAction = createAction(ActionTypes.LOGOUT);

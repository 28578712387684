<div class="header">
  <div class="left">
    <mat-icon svgIcon="24px-delete"></mat-icon>
    <div class="title">
      <span>{{ 'remove-note' | translate }}</span>
      <span class="subtitle">{{ 'remove-note-subtitle' | translate }}</span>
    </div>
  </div>
  <button mat-icon-button mat-dialog-close>
    <mat-icon svgIcon="24px-close"></mat-icon>
  </button>
</div>

<div class="buttons">
  <button 
    mat-stroked-button color="primary" 
    mat-dialog-close 
    data-cy="cancel-remove"
  >
    {{ 'cancel' | translate }}
  </button>

  <button 
    mat-flat-button color="accent" 
    [mat-dialog-close]="true" 
    data-cy="submit-remove"
  >
    {{ 'yes-remove-note' | translate }}
  </button>
</div>

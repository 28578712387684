import { OnInit, Component, Input, OnDestroy } from '@angular/core';
import { ResponsiveService } from '@core/services/responsive.service';
import { NotificationsCenterService } from '@core/services/notifications-center.service';

@Component({
  selector: 'app-notifications-center',
  templateUrl: './notifications-center.component.html',
  styleUrls: ['./notifications-center.component.scss'],
})
export class NotificationsCenterComponent implements OnInit, OnDestroy {
  @Input() notifications: any[];

  notReadNotifications: any[];
  fixedNotifications: any[];
  normalNotifications: any[];
  viewMode: string;

  constructor(
    private responsiveservice: ResponsiveService,
    private notificationsCenterService: NotificationsCenterService,
  ) {}

  ngOnInit() {
    this.responsiveservice.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );

    this.getFixedNotifications();
  }

  ngOnDestroy() {
    const iterations = this.viewMode === 'web' ? 9 : 4;
    const readNotifications = this.notifications.slice(0, iterations);

    this.notificationsCenterService.markNotificationsAsRead(readNotifications);
  }

  private getFixedNotifications() {
    this.fixedNotifications = [];
    this.normalNotifications = [];

    for (let index = 0; index < this.notifications.length; index++) {
      const notification = this.notifications[index];

      if (notification.fixed) {
        this.fixedNotifications.push(notification);

        continue;
      }

      this.normalNotifications.push(notification);
    }
  }

  toggleNotificationsCenter() {
    this.notificationsCenterService.toggleNotificationsCenter();
  }

  handlerNotificationClosed(notificationID) {
    this.notifications = this.notifications.filter(
      (notification) => notification.id !== notificationID,
    );
    this.getFixedNotifications();
  }
}

import { Certification } from './../../../core/sdk/models/Certification';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
} from 'rxjs/operators';
import { BudgetEditionFacadeService } from '../../../store/budget/budget-facade.service';
import { Budget, BudgetInterface } from '@core/sdk';
import { merge, Subject } from 'rxjs';
import { CertificationFacadeService } from 'src/app/store/certification/certification-facade.service';
import { CONTEXTS, DEBOUNCE_TIME } from '@core/constants';
import { BudgetService } from '@core/services/budget.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-custom-terms',
  templateUrl: './custom-terms.component.html',
  styleUrls: ['./custom-terms.component.scss'],
})
export class CustomTermsComponent implements OnInit, OnDestroy {
  @Input() currentBudgetId: string;
  @Input() budget: BudgetInterface;
  @Input() currentCertificationId: string;
  @Input() disableField = false;
  @Input() state: string;
  @Input() terms: string;
  @Output() termsChange = new EventEmitter<string>();
  unsubscriber$ = new Subject<void>();
  termsForm: UntypedFormControl = new UntypedFormControl('');

  constructor(
    private budgetEditionFacade: BudgetEditionFacadeService,
    private certificationFacade: CertificationFacadeService,
    private budgetService: BudgetService,
    private store: Store<{ context: any }>,

  ) {}

  ngOnInit() {
    this.termsForm.enable();

    this.store.select('context').pipe(takeUntil(this.unsubscriber$))
    .subscribe(({value: context}) => {
      if (!context) {
        return;
      }

      context !== CONTEXTS.AGENT ? this.termsForm.disable() : this.termsForm.enable();

    })

    this.initializeForm();
    this.initializeFormListeners()
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
  }

  onInputChange(event) : any{
    this.terms = event.target.value;

    if(this.currentCertificationId){
      this.termsChange.emit(this.terms);
    }

    this.saveTerms(this.terms)
  }

  initializeForm() {
    const filter = {
      where:{
        id: this.currentBudgetId
      }
    }

    if (this.currentBudgetId){
      this.budgetService.getBudgetsByFilter(filter).subscribe((budget)=>{
        budget['budgets'].forEach((budget)=>{
          this.budget = budget
        })

        this.checkState();

        this.termsForm = new UntypedFormControl({
          value: this.terms,
          disabled: this.disableField,
        });

        this.budgetEditionFacade.budget$
          .pipe(takeUntil(this.unsubscriber$), distinctUntilChanged())
          .subscribe((budget) => {
              this.termsForm.patchValue(budget.terms, { emitEvent: false });
          });

        return;
      })
    }

    if (this.currentCertificationId) {
      this.certificationFacade
        .getCertification(this.currentCertificationId)
        .pipe(takeUntil(this.unsubscriber$), distinctUntilChanged())
        .subscribe((certification) => {
          this.termsForm.patchValue(this.terms, {
            emitEvent: false,
          });
        });
    }
  }

  initializeFormListeners() {
    const termsCtrlValue$ = this.termsForm.valueChanges.pipe(
      takeUntil(this.unsubscriber$),
      debounceTime(DEBOUNCE_TIME),
      filter(() => this.termsForm.valid),
      map((terms: string) => ({ terms } as Partial<Budget>)),
    );

    merge(termsCtrlValue$).subscribe((payload: Partial<Budget | Certification>) => {
      if( this.currentBudgetId ){
        this.budgetEditionFacade.updateBudget({ ...payload, id: this.currentBudgetId });
      }

      if(this.currentCertificationId) {
        this.certificationFacade.updateCertification({id: this.currentCertificationId, terms: payload.terms});
      }

    });
  }
  checkState(){
    this.disableField = this.budget.state==='created' ? true : false;

    this.disableField =  this.budget?.subType==='annex' ? false : true;
  }

  saveTerms(terms:string){
    if(this.currentBudgetId){
      this.budgetService.updateBudget(this.currentBudgetId,{terms:terms})
      .pipe(
        takeUntil(this.unsubscriber$)
      )
      .subscribe()
    }
  }
}

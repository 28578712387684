<section>
  <div class="left" [ngClass]="{ clicked: notification.clicked }">
    <div>
      <div
        class="icon-wrapper"
        [ngClass]="notificationTypes[notification.type].class"
      >
        <mat-icon
          [svgIcon]="notificationTypes[notification.type].icon"
        ></mat-icon>
      </div>
    </div>

    <ng-container [ngSwitch]="notification.type">
      <div class="info project" *ngSwitchCase="'project'">
        <span class="title" [ngClass]="rejectedOrAccepted">
          {{ notification.title | translate: notification.metadata }}
        </span>
        <div class="body">
          <span>{{ notification.body.clientName }}</span>
          <span>{{ notification.body.address }}</span>
        </div>
        <span class="success-date">
          {{ notification.successDate | date: 'shortDate':'UTC' }} a las
          {{
            notification.successDate | momentDate: 'shortTime':'Europe/Madrid'
          }}
        </span>
      </div>

      <div class="info message" *ngSwitchCase="'message'">
        <span class="title">{{ notification.title | translate }}</span>
        <div class="body">
          <span>{{ notification.body.clientName }}</span>
          <span>{{ notification.body.address }}</span>
        </div>
        <span class="success-date">
          {{ notification.successDate | date: 'shortDate':'UTC' }} a las
          {{
            notification.successDate | momentDate: 'shortTime':'Europe/Madrid'
          }}
        </span>
      </div>

      <div class="info message" *ngSwitchCase="'review'">
        <span class="title">{{ notification.title | translate }}</span>
        <div class="body">
          <span>
            {{ 'client' | translate }}: {{ notification.body.clientName }}</span
          >
          <span>{{ notification.body.address }}</span>
        </div>
        <span class="success-date">
          {{ notification.successDate | date: 'shortDate':'UTC' }} a las
          {{
            notification.successDate | momentDate: 'shortTime':'Europe/Madrid'
          }}
        </span>
      </div>

      <div class="info activity" *ngSwitchCase="'activity'">
        <span class="title">{{ notification.title | translate }}</span>
        <div class="body">
          <span>{{ notification.body.clientName }}</span>
          <span>{{ notification.body.address }}</span>
        </div>
        <span class="success-date">
          {{ notification.successDate | date: 'shortDate':'UTC' }} -
          {{
            notification.successDate | momentDate: 'shortTime':'Europe/Madrid'
          }}
        </span>
      </div>
    </ng-container>
  </div>

  <div class="wrapper" *ngIf="notification.type !== 'activity'">
    <button
      mat-icon-button
      (click)="closeNotification(); $event.stopPropagation()"
    >
      <mat-icon svgIcon="24px-close"></mat-icon>
    </button>
  </div>
</section>

<section class="read" *ngIf="notification.read">
  {{ 'read-past' | translate }}
  <mat-icon>check</mat-icon>
</section>

/* eslint-disable */

declare var Object: any;
export interface PaymentMethodInterface {
  signal: number;
  ahead: number;
  progress: number;
  end: number;
  id?: number;
  modelActive?: any;
}

export class PaymentMethod implements PaymentMethodInterface {
  'signal': number;
  'ahead': number;
  'progress': number;
  'end': number;
  'id': number;
  'modelActive': any;
  constructor(data?: PaymentMethodInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PaymentMethod`.
   */
  public static getModelName() {
    return 'PaymentMethod';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of PaymentMethod for dynamic purposes.
   **/
  public static factory(data: PaymentMethodInterface): PaymentMethod {
    return new PaymentMethod(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'PaymentMethod',
      plural: 'PaymentMethods',
      path: 'PaymentMethods',
      idName: 'id',
      properties: {
        signal: {
          name: 'signal',
          type: 'number',
          default: 10,
        },
        ahead: {
          name: 'ahead',
          type: 'number',
          default: 40,
        },
        progress: {
          name: 'progress',
          type: 'number',
          default: 40,
        },
        end: {
          name: 'end',
          type: 'number',
          default: 10,
        },
        id: {
          name: 'id',
          type: 'number',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
      },
      relations: {},
    };
  }
}

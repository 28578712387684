
<div class="header">
  <div matRipple [matMenuTriggerFor]="menu" class="actions menu flex-row p-0.5" data-cy="cy-actions" >
    <div class="title-container">
      <div class="title">
        <span >{{ 'actions' | translate }}</span>
      </div>
    </div>
    <div class="menu p-0">
      <mat-icon class="toggle-icon">chevron_right</mat-icon>
    </div>
  </div>

  <mat-menu #menu="matMenu">
    <div *ngFor="let option of actions$ | async"
    >
      <button mat-menu-item
      *appHasPermission="option.permission"
      (click)="setMethod(option.method)"
      [attr.data-cy]="option.dataCy"
      >
      <mat-icon svgIcon="{{option.icon}}"
      ></mat-icon>
      {{ option.slug | translate }}
    </button>
    </div>
  </mat-menu>
</div>
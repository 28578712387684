import {
  AgentAddOrUpdateAction,
  AgentAddOrUpdateFieldAction,
  AgentRemoveAction,
} from '../actions/agent.actions';
import { Action, createReducer, on, State } from '@ngrx/store';
import { Agent } from '@core/sdk';
import { AnyLengthString } from 'aws-sdk/clients/comprehend';
import * as _ from 'lodash-es';
import { logoutAction } from '../actions/meta.action';

export const initialState: Agent | any = {};

const agentReducer = createReducer(
  initialState,
  on(AgentAddOrUpdateAction, (state, agent) => ({
    ...state,
    ...agent,
  })),
  on(AgentAddOrUpdateFieldAction, (state, { field, value }) => {
    const newState = _.cloneDeep(state);

    _.set(newState, field, value);

    return {
      ...newState,
    };
  }),
  on(AgentRemoveAction, (state, action) => ({})),
  on(logoutAction, (state) => initialState),
);

export function reducer(
  state: State<AnyLengthString> | undefined,
  action: Action,
) {
  return agentReducer(state, action);
}

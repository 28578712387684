import { Injectable } from '@angular/core';
import {
  LeadInterface,
  Agent,
  ClientInterface,
  Lead,
  DocumentInterface
} from '../sdk';
import environment from '@environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { switchMap, tap, concatMap, take, map } from 'rxjs/operators';
import { AgentService } from './agent.service';
import { ConversationInterface } from '@core/sdk/models/Conversation';
import { ConversationService } from './conversation.service';
import { LocalStorageService } from './local-storage.service';
import { Store } from '@ngrx/store';
import { TimelineInterface } from '@core/sdk/models/Timeline';
import { ContextUpdateProjectAction } from 'src/app/store/context/context.actions';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CONTEXT_VALUES } from '@core/context/constants';
import { BudgetsAddAction } from 'src/app/store/actions/budget.actions';

@Injectable({
  providedIn: 'root',
})
export class LeadService  {
  private openCubicupStaffConversation$: Subject<any> = new Subject<any>();
  private currentProjectId: string | null = null;
  private userType: string;

  constructor(
    private httpClient: HttpClient,
    private agentService: AgentService,
    private conversationService: ConversationService,
    private localStorage: LocalStorageService,
    private router: Router,
    private store: Store<{
      projects: LeadInterface[];
      context: any;
      agent: Agent;
      client: ClientInterface;
      user: any;
    }>,
  ) {

    this.store
      .select('context')
      .subscribe(({ value: context }) => (this.userType = context));
  }


  navigateToProjectHomeRoute(): void {
    const routePath = this.userType === CONTEXT_VALUES.loginClient
      ? `/logged-client/project/${this.currentProjectId}`
      : `/project/${this.currentProjectId}`;

    this.router.navigate([routePath]);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    //TODO: We use 'let' because it's necessary for the variable to be named projectID, and in some routes, it's called leadID
    let { projectID } = route.params;
    const { leadID } = route.params;
    if (!projectID && leadID) {
      projectID = leadID;
    }
    this.setCurrentProjectId(projectID || null);

    if (!projectID) {
      return false;
    }

    this.setCurrentProjectId(projectID);

    return true;
  }


  setCurrentProjectId(projectId: string | null) {
    this.currentProjectId = projectId;
    this.updateContextCurrentProject(projectId);
  }

  private updateContextCurrentProject(projectId: string) {
    this.store.dispatch(
      ContextUpdateProjectAction({
        currentProjectId: projectId,
      }),
    );
  }

  getClientProjectFromStore(projectID: string): Observable<any> {
    return this.store.select('client').pipe(
      switchMap((client) => {
        if (!client || Object.keys(client).length === 0) {
          return of({});
        }

        const project = client.leads.find((lead) => lead.id === projectID);

        if (!project) {
          throw new Error('Project not found!');
        }

        return of(project);
      }),
    );
  }

  getProjectsFromStore(): Observable<any> {
    return this.store.select('context').pipe(
      concatMap(({ value: context }) => {
        if (context === 'login-client') {
          return this.store.select('projects');
        }

        return this.store.select('client').pipe(
          switchMap((client) => {
            const [project] = client.leads;

            if (!project) {
              throw new Error('Project not found!');
            }

            return of(project);
          }),
        );
      }),
    );
  }

  getProjects(filter: object): Observable<LeadInterface> {
    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
      }),
    };

    return this.httpClient.get<LeadInterface>(
      `${environment.apiBaseUrl}/api/Leads`,
      httpOptions
    )
  }

  getClientProject(projectId: string): Observable<any> {
    return this.store.select('client', 'id').pipe(
      take(1),
      switchMap((clientId) =>
        this.httpClient.get<LeadInterface>(
          `${environment.apiBaseUrl}/api/Clients/${clientId}/getLoginClientLead/${projectId}`,
        ),
      ),
    );
  }

  getMagicPlanInfo(lead) {
    return new Promise((resolve, reject) => {
      this.httpClient.get<any>(`${environment.apiBaseUrl}/api/Leads/${lead.id}/magicplan`)
        .subscribe((res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getCodeProjectGenerated(lead) {
    return new Promise((resolve, reject) => {
      this.httpClient.get<any>(`${environment.apiBaseUrl}/api/Leads/${lead.id}/get-code-project`)
        .subscribe((res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }


  getProjectsFilter(filterHeader) {
    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    return this.httpClient
      .get<any>(`${environment.apiBaseUrl}/api/Leads/count-filter`, httpOptions)
      .pipe(
        concatMap(({ count }) => {
          if (count === 0) {
            return of([]);
          }

          let url = `${environment.apiBaseUrl}/api/Leads`;

          let concurrency = 5;

          const budgetsPerCall = Math.ceil(count / concurrency) || 1;
          const requestsConfig = [];

          for (let index = 0; index < concurrency; index++) {
            const newFilter = {
              ...filterHeader,
              skip: budgetsPerCall * index,
              limit: budgetsPerCall,
            };

            const httpOptions = {
              headers: new HttpHeaders({
                filter: JSON.stringify(newFilter),
              }),
            };

            requestsConfig.push(httpOptions);
          }

          return forkJoin(
            requestsConfig.map((urlHttpOptions) =>
              this.httpClient.get<any>(`${url}`, urlHttpOptions),
            ),
          );
        }),
        map((projects: any) => projects.flat()),
      );
  }

  getEstimationsByOwner(leadID: string, filter: object) {
    const filterHeader = {
      where: filter,
      include: [
        {
          relation: 'agent',
          scope: {
            fields: ['tradeName', 'documents', 'id'],
          },
        },
      ],
      order: 'updatedAt DESC',
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
        owner: this.localStorage.get('userId')
      }),
    };

    return this.httpClient
      .get<any>(
        `${environment.apiBaseUrl}/api/Leads/${leadID}/estimations`,
        httpOptions,
      )
  }

  getProjectNotes(projectID) {
    const filter = {
      order: 'updatedAt DESC',
      where: {
        targetId: projectID,
      },
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
      }),
    };

    return this.store.select('context')
      .pipe(
        take(1),
        switchMap(({ value: context }) => {
          if (context === 'agent') {
            const agent = this.localStorage.get('agentId');

            return this.httpClient.get<any[]>(
              `${environment.apiBaseUrl}/api/Agents/${agent}/notes`,
              httpOptions,
            );
          }

          if (context === 'login-client') {
            const client = this.localStorage.get('clientId');

            return this.httpClient.get<any[]>(
              `${environment.apiBaseUrl}/api/Clients/${client}/notes`,
              httpOptions,
            );
          }
        })
      )
  }

  createNewProject(lead) {
    return this.agentService.getAgentID().pipe(
      concatMap((agentID) => {
        const url = `${environment.apiBaseUrl}/api/leads`;

        lead.agentId = agentID;

        return this.httpClient.post<LeadInterface>(url, lead);
      }),
    );
  }

  updateProject(projectID, newValues) {
    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}`;

    return this.httpClient.patch<LeadInterface>(url, {
      ...newValues,
    });
  }

  deleteProject(projectID) {
    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}/delete-project`;

    return this.httpClient.delete<any>(url);
  }

  changeProjectState(projectID, newState) {
    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}`;

    return this.httpClient.patch<LeadInterface[]>(url, {
      state: newState,
    });
  }

  updateIsNew(projectID) {
    return this.updateProject(projectID, { isNew: false });
  }

  createCubicupStaffConversation(projectID) {
    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}/cubicupStaffConversation`;

    return this.store.select('user').pipe(
      concatMap((user) => {
        return this.httpClient.post<ConversationInterface>(url, {}).pipe(
          tap((conversation: ConversationInterface) => {
            const personID = user.userId;

            this.conversationService
              .addParticipantToConversation(conversation.id, personID)
              .subscribe();
          }),
        );
      }),
    );
  }

  getProjectStates() {
    return {
      new: {
        publicProjectState: 'new',
      },
      pending: {
        publicProjectState: 'new',
      },
      pendingOfVisit: {
        publicProjectState: 'pendingOfVisit',
      },
      pendingOfBudget: {
        publicProjectState: 'pendingOfBudget',
      },
      pendingOfAnswer: {
        publicProjectState: 'negotiation',
      },
      budgetSended: {
        publicProjectState: 'negotiation',
      },
      pendingOfSignature: {
        publicProjectState: 'negotiation',
      },
      pendingOfPayment: {
        publicProjectState: 'negotiation',
      },
      pendingOfAhead: {
        publicProjectState: 'negotiation',
      },
      pendingOfSignal: {
        publicProjectState: 'negotiation',
      },
      signalPaid: {
        publicProjectState: 'hiredProject',
      },
      aheadPaid: {
        publicProjectState: 'hiredProject',
      },
      negotiation: {
        publicProjectState: 'negotiation',
      },
      hired: {
        publicProjectState: 'hired',
      },
      lost: {
        publicProjectState: 'lost',
      },
      canceledVisit: {
        publicProjectState: 'lost',
      },
      won: {
        publicProjectState: 'won',
      },
    };
  }

  getPublicProjectStates() {
    return {
      new: {
        projectState: 'pending',
      },
      pendingOfVisit: {
        projectState: 'pendingOfVisit',
      },
      pendingOfBudget: {
        projectState: 'pendingOfBudget',
      },
      negotiation: {
        projectState: [
          'pendingOfAnswer',
          'budgetSended',
          'pendingOfSignature',
          'pendingOfPayment',
          'pendingOfSignal',
        ],
      },
      hired: {
        projectState: 'hired',
      },
      lost: {
        projectState: 'lost',
      },
      won: {
        projectState: ['signalPaid', 'aheadPaid'],
      },
    };
  }

  getProjectTypes() {
    return {
      Cocina: {
        icon: 'jobType:kitchen',
      },
      Baño: {
        icon: 'jobType:bathroom',
      },
      Integral: {
        icon: 'jobType:integral',
      },
      'Obra nueva': {
        icon: 'jobType:newWork',
      },
      'Bañera por plato ducha': {
        icon: 'jobType:plate',
      },
      others: {
        icon: 'jobType:integral',
      },
    };
  }

  getCubicupConversationState() {
    return this.openCubicupStaffConversation$.asObservable();
  }

  putOpenCubicupStaffConversation() {
    this.openCubicupStaffConversation$.next(1);
  }

  setProjectFeeling(projectID, agentFeeling) {
    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}`;

    return this.httpClient.patch<LeadInterface>(url, {
      agentFeeling,
    });
  }

  setProjectClientScoring(projectID, clientScore) {
    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}`;

    return this.httpClient.patch<LeadInterface>(url, {
      clientScore,
    });
  }

  getLoginClientProjectNotes(projectID) {
    const clientID = this.localStorage.get('clientId');
    const filter = {
      order: 'updatedAt DESC',
      where: {
        targetId: projectID,
        clientId: clientID,
      },
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
        referrer: `client-${clientID}`,
      }),
    };

    return this.httpClient.get<any[]>(
      `${environment.apiBaseUrl}/api/Clients/${clientID}/notes`,
      httpOptions,
    );
  }

  requestVisit(projectId) {
    return this.store.select('client').pipe(
      switchMap((client) =>
        this.httpClient.post<any[]>(
          `${environment.apiBaseUrl}/api/Leads/${projectId}/requestVisit`,
          { email: client.contact.email },
        ),
      ),
    );
  }

  getProjectDocuments(projectID: string, folderLevel: string | null = null) {
    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}/documents`;
    return this.httpClient.get<any>(url);
  }

  getProjectUrlDocuments(projectID) {
    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}/urlDocuments`;

    const filter = {
      where: {
        targetId: projectID,
        targetType: 'Lead',
      },
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
      }),
    };

    return this.httpClient.get<any>(url, httpOptions);
  }

  // @jero esto es para ir probando

  getProjectCertifications(projectID: string) {
    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}/certifications`;

    return this.httpClient.get<any>(url);
  }

  getProjectById(projectID: string) {
    const filter = {
      include: [
        {
          relation:'client',
        },
        {
          relation: 'agent',
          scope: {
            fields: [
              'id',
              'tradeName',
              'contact',
              'address',
              'image',
              'documents'
            ],
            include: {
              relation: 'documents',
              scope: {
                where: {
                  key: 'log'
                },
                fields: ['id', 'versions', 'key'],
                include: 'versions'
              }

            }
          }
        },
        'cubicupStaff',
        'cubicupStaffConversation',
        {
          relation: 'timeline',
          scope: {
            order: 'createdAt DESC'
          }
        }
      ],
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
      }),
    };

    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}`;

    return this.httpClient.get<Lead>(url, httpOptions);
  }

  markTimelineAsRead(timelineID: string): Observable<TimelineInterface> {
    const url = `${environment.apiBaseUrl}/api/Timelines/${timelineID}`;

    return this.httpClient.patch<TimelineInterface>(url, {
      read: true,
    });
  }

  getProjectTimelineById(projectID) {
    const filter = {
      include: {
        relation: 'timeline',
        scope: {
          order: 'createdAt DESC'
        }
      },
      fields: [
        'id',
        'timeline',
      ],
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
      }),
    };

    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}`;

    return this.httpClient.get<Lead>(url, httpOptions);
  }

  getGoogleProjectDocuments(pathToFolder: string, visible: boolean | null = null, limit?: number): Observable<DocumentInterface[]> {
    const url = `${environment.apiBaseUrl}/api/Documents/folder`;
    const filter: any = {
      where: {
        path: pathToFolder,
        s3Saved: true
      },
      order: 'createdAt DESC'
    };

    if (visible !== null) {
        filter.where.visible = visible;
    }

    if (limit !== null) {
      filter.limit = limit;
  }

    const httpOptions = {
      params: {
        filter: JSON.stringify(filter)
      },
      observe: 'body' as const // This is necessary to get the full response when you type the get and use a body
    };

    return this.httpClient.get<DocumentInterface[]>(url, httpOptions);
}

  updateEconomicStatus() {
    const url = `${environment.apiBaseUrl}/api/Leads/invoicing/update`;

    return this.httpClient.get<void>(url);
  }

  getActiveTrackingProjects(filterHeader) {
    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    const url = `${environment.apiBaseUrl}/api/Leads/tracking`;

    return this.httpClient.get<LeadInterface>(`${url}`, httpOptions);
  }
}

import { Injectable } from '@angular/core';
import {
  ClientInterface,
  BudgetInterface,
  Client,
  LeadInterface,
  DocumentInterface,
  Lead,
  Budget,
  ProjectData,
} from '../sdk';
import { SessionStorageService } from './session-storage.service';
import { Observable, of, timer } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  map,
  concatAll,
  tap,
  concatMap,
  take,
  switchMap,
} from 'rxjs/operators';
import environment from '../../../environments/environment';
import { ShopingCartDTO, PaymentDTO } from '../../shared/interfaces';
import { Store } from '@ngrx/store';
import { LocalStorageService } from './local-storage.service';
import {
  ProjectsAddAction,
  ProjectsClearAction,
} from 'src/app/store/actions/project.actions';
import { ContextAddOrUpdateAction } from 'src/app/store/context/context.actions';
import { NotificationsCenterService } from './notifications-center.service';
import {
  ClientAddOrUpdateAction,
  ClientAddOrUpdateFieldAction,
} from 'src/app/store/actions/client.actions';
import {
  BudgetsAddAction,
  BudgetsClearAction,
} from 'src/app/store/actions/budget.actions';
import * as applyFilter from 'loopback-filters';
import { InvoiceService } from './invoice.service';
import { result } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(
    private sessionStorage: SessionStorageService,
    private httpClient: HttpClient,
    private invoiceService: InvoiceService,
    private store: Store<{
      client: Client;
      projects: LeadInterface[];
      context: any;
      user: any;
    }>,
    private localStorageService: LocalStorageService,
  ) { }

  /**
   * Get the client token from the session storage if it exists, otherwise return an empty string
   * @param clientToken - A unique token that identifies the client.
   * @returns The clientToken is being returned.
   */
  getClientToken(clientToken) {
    if (clientToken && clientToken !== '') {
      this.sessionStorage.set('clientToken', clientToken);

      return clientToken;
    }

    return this.sessionStorage.get('clientToken');
  }

  /**
   * Loads the client by the given client token
   * @param {string} [clientToken] - The client token that was passed in the URL.
   * @returns The client object.
   */
  loadByToken(clientToken?: string) {
    const token = this.getClientToken(clientToken);
    const getClientUrl = `${environment.apiBaseUrl}/api/Clients/${token}/get`;

    return this.httpClient.get<ClientInterface>(getClientUrl)
      .pipe(
        tap(
          (client) => {
            this.store.dispatch(ClientAddOrUpdateAction(client));

            let clientBudgets = [];

            client.leads.forEach((lead) => {
              if (!lead.budgets) {
                return;
              }

              clientBudgets = clientBudgets.concat(lead.budgets);
            });

            this.store.dispatch(ProjectsClearAction());
            this.store.dispatch(BudgetsClearAction());
            this.store.dispatch(ProjectsAddAction({ projects: client.leads }));
            this.store.dispatch(BudgetsAddAction({ budgets: clientBudgets }));
          }
        )
      );
  }
  /**
   * Get the client from the store
   * @param {string} [clientToken] - The client token to load.
   * @returns The client state.
   */
  getClient(clientToken?: string) {
    if (clientToken && clientToken !== '') {
      return this.loadByToken(clientToken);
    }

    return this.store.select('client');
  }

  /**
   * Get the client by ID from the API, store it in the store, and start the user notifications
   * subscription
   * @param [clientID=null] - The ID of the client to get. If not provided, the client ID from the
   * local storage will be used.
   * @returns The client object.
   */
  getClientById(clientID = null) {
    const realClientID = clientID || this.localStorageService.get('clientId');
    const getClientUrl = `${environment.apiBaseUrl}/api/Clients/${realClientID}`;

    const httpOptions = {
      headers: new HttpHeaders({
        referrer: `client-${realClientID}`,
      }),
    };

    return this.httpClient.get<ClientInterface>(getClientUrl, httpOptions).pipe(
      tap((client) => {
        this.store.dispatch(ClientAddOrUpdateAction(client));
        this.store.dispatch(
          ContextAddOrUpdateAction({ value: 'login-client' }),
        );
      }),
    );
  }

  /**
   * It gets the client field from the client with the given ID.
   * @param clientField - The name of the field to get from the client.
   * @param [clientID=null] - The ID of the client you want to get.
   * @returns The client object.
   */
  getClientFieldById(clientField, clientID = null) {
    const realClientID = clientID || this.localStorageService.get('clientId');
    const getClientUrl = `${environment.apiBaseUrl}/api/Clients/${realClientID}`;

    const fieldsToGet: any = {};
    fieldsToGet[clientField] = true;

    const httpOptions = {
      headers: new HttpHeaders({
        referrer: `client-${realClientID}`,
        fields: fieldsToGet,
      }),
    };

    return this.httpClient.get<ClientInterface>(getClientUrl, httpOptions);
  }

  /**
   * It returns a stream of all the budgets for all the leads
   * @returns The budgets for all leads.
   */
  getClientBudgets() {
    return this.store.select('client').pipe(
      map((client) => client.leads.map((lead) => lead.budgets)),
      concatAll(),
    );
  }

  /**
   * It gets the budget for the client by ID.
   * @param {string} budgetID - string
   * @returns An Observable of the budget.
   */
  getClientBudgetByID(budgetID: string): Observable<BudgetInterface> {
    return this.store.select('client').pipe(
      take(1),
      concatMap((client) => {
        let clientBudget: BudgetInterface;

        client.leads.map((lead) => {
          const selectedBudget = lead.budgets.find(
            (budget) => budget.id === budgetID,
          );

          if (selectedBudget !== null && selectedBudget !== undefined) {
            clientBudget = selectedBudget;
          }
        });

        return of(clientBudget);
      }),
    );
  }

  /**
   * Given a budget ID, return the client lead that has that budget
   * @param {string} budgetID - string
   * @returns The lead object.
   */
  getClientLeadByBudgetID(budgetID: string) {
    return this.store.select('client').pipe(
      take(1),
      concatMap((client) => {
        const lead = client.leads.find((leads) =>
          leads.budgets.some((budget) => budget.id === budgetID),
        );

        return of(lead);
      }),
    );
  }

  /**
   * Get the leads for the current client
   * @param {object} filter - object = {
   * @param {number} [page=null] - number = null
   * @returns The observable of the projects.
   */
  


  getClientLeadsByClientId(clientID = null) {
    let realClientID = clientID || this.localStorageService.get('clientId');
    const url = `${environment.apiBaseUrl}/api/Clients/${realClientID}/leads`;

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify({}),
        referrer: `client-${realClientID}`,
      }),
    };

    return this.httpClient.get<LeadInterface[]>(url, httpOptions).pipe(
      tap((projects) => {
        this.store.dispatch(ProjectsAddAction({ projects }));
      }),
    );
  }

  /**
   * Get the default shopping cart for the given budgetId and defaultType
   * @param {string} defaultType - string, budgetId: string
   * @param {string} budgetId - The id of the budget that the shopping cart is associated with.
   */
  getDefaultShoppingCart(defaultType: string, budgetId: string) {
    const token = this.sessionStorage.get('clientToken');
    const getShoppingCartUrl = `${environment.apiBaseUrl}/api/Clients/${token}/payment-method/${budgetId}/${defaultType}`;

    return this.httpClient.get<ShopingCartDTO>(getShoppingCartUrl);
  }

  /**
   * It gets the shopping cart of the client.
   * @param {string} defaultType - string,
   * @param {string} budgetId - The budget ID of the shopping cart.
   */
  getLoginClientDefaultShoppingCart(defaultType: string, budgetId: string) {
    const clientID = this.localStorageService.get('clientId');
    const getShoppingCartUrl = `${environment.apiBaseUrl}/api/Clients/${clientID}/payment-method-login-client/${budgetId}/${defaultType}`;

    const httpOptions = {
      headers: new HttpHeaders({
        referrer: `client-${clientID}`,
      }),
    };

    return this.httpClient.get<ShopingCartDTO>(getShoppingCartUrl, httpOptions);
  }

  /**
   * It creates a payment method for the client.
   * @param {string} paymentMethodSelected - The payment method selected by the user.
   * @param {string} budgetId - The budget ID that the payment method is being added to.
   */
  private createLoginClientPaymentMethods(
    paymentMethodSelected: string,
    budgetId: string,
  ) {
    const clientID = this.localStorageService.get('clientId');
    const createPaymentUrl = `${environment.apiBaseUrl}/api/Clients/${clientID}/payment-method-login-client`;
    const body = {
      paymentMethodSelected,
      budgetId,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        referrer: `client-${clientID}`,
      }),
    };

    return this.httpClient.post<PaymentDTO>(
      createPaymentUrl,
      body,
      httpOptions,
    );
  }

  /**
   * It creates a payment method for a client.
   * @param {string} paymentMethodSelected - string,
   * @param {string} budgetId - The id of the budget that the payment method is being added to.
   * @returns The observable of the httpClient.post() method.
   */
  createPaymentMethods(paymentMethodSelected: string, budgetId: string) {
    return this.store.select('context').pipe(
      concatMap(({ value: context }) => {
        if (context === 'login-client') {
          return this.createLoginClientPaymentMethods(
            paymentMethodSelected,
            budgetId,
          );
        }

        const token = this.sessionStorage.get('clientToken');
        const createPaymentUrl = `${environment.apiBaseUrl}/api/Clients/${token}/payment-method`;
        const body = {
          paymentMethodSelected,
          budgetId,
        };

        return this.httpClient.post<PaymentDTO>(createPaymentUrl, body);
      }),
    );
  }

  /**
   * It checks if the user is logged in.
   * @returns The return value is a boolean value.
   */
  isClientValid() {
    return true;
  }

  /**
   * Send a review to the agent
   * @param review - The review object that will be sent to the server.
   * @param agentID - The ID of the agent you want to review.
   * @returns The response is a JSON object with the following properties:
   */
  sendAgentReview(review, agentID) {
    const clientID = this.localStorageService.get('clientId');
    const url = `${environment.apiBaseUrl}/api/Clients/${clientID}/reviews`;

    const httpOptions = {
      headers: new HttpHeaders({
        referrer: `client-${clientID}`,
      }),
    };

    const reviewData = {
      ownerId: agentID,
      ownerType: 'Agent',
      authorOwnerType: 'string',
      authorOwnerId: 'string',
    };

    return this.httpClient.post<any>(url, reviewData, httpOptions);
  }

  /**
   * It simulates a budget for a project.
   * @param quiz - The quiz object that was submitted by the user.
   * @param projectID - The ID of the project you want to simulate.
   * @returns The response is an object with the following properties:
   */
  simulateBuget(quiz, projectID) {
    const clientID = this.localStorageService.get('clientId');
    const url = `${environment.apiBaseUrl}/api/Clients/${clientID}/simulateBudget`;

    const httpOptions = {
      headers: new HttpHeaders({
        referrer: `client-${clientID}`,
      }),
    };

    return this.httpClient.post<any>(url, { quiz, projectID }, httpOptions);
  }

  /**
   * It simulates a budget for a project.
   * @param quiz - The quiz object that was submitted by the user.
   * @param projectID - The ID of the project you want to simulate.
   * @returns The response is an object with the following properties:
   */
  estimatePpvBudget(quiz, projectID, templateBudgetId) {
    const clientID = this.localStorageService.get('clientId');
    const newClientId = this.localStorageService.get('newClientId');

    const url = `${environment.apiBaseUrl}/api/Clients/${clientID}/estimate`;

    const httpOptions = {
      headers: new HttpHeaders({
        referrer: `client-${clientID}`,
      }),
    };

    return this.httpClient.post<any>(url, { quiz, projectID, newClientId, templateBudgetId }, httpOptions);
  }


/**
   * It simulates a budget for a project.
   * @param quiz - The quiz object that was submitted by the user.
   * @param projectID - The ID of the project you want to simulate.
   * @returns The response is an object with the following properties:
   */
  cubicupPpvBudget(quiz, newClientId, projectID, templateBudgetId) {
    const url = `${environment.apiBaseUrl}/api/Clients/${newClientId}/ppv`;

    const httpOptions = {
      headers: new HttpHeaders({
        referrer: `client-${newClientId}`,
      }),
    };

    return this.httpClient.post<any>(url, { quiz, projectID, newClientId, templateBudgetId }, httpOptions);
  }



  /**
   * Create a document for a client
   * @param clientID - The ID of the client to which the document is being uploaded.
   * @param documentDefinition - The document definition.
   */
  createClientDocument(clientID, documentDefinition) {
    if (!clientID) {
      // TODO:
      return;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        referrer: `client-${clientID}`,
      }),
    };

    const url = `${environment.apiBaseUrl}/api/Clients/${clientID}/documents`;

    this.httpClient.post(url, documentDefinition, httpOptions).subscribe();
  }

  /**
   * Update the client's field with the given value
   * @param field - The name of the field to update.
   * @param value - The value to update the client field with.
   * @returns The client object.
   */
  updateClientField(field, value) {
    return this.store.select('context').pipe(
      take(1),
      switchMap(({ value: context }) => {
        if (context !== 'login-client') {
          throw new Error('Operation forbidden');
        }

        return this.store.select('client').pipe(
          take(1),
          switchMap((client) => {
            const url = `${environment.apiBaseUrl}/api/Clients/${client.id}`;
            const body = {};

            body[field] = value;

            return this.httpClient.patch<any>(url, body);
          }),
          tap((newClient) => {
            this.store.dispatch(
              ClientAddOrUpdateFieldAction({
                field,
                value,
              }),
            );
          }),
        );
      }),
    );
  }

  updateClientProfile(clientId: string, updatedClient: any): Observable<Client> {
    const url = `${environment.apiBaseUrl}/api/Clients/${clientId}`;

    return this.httpClient.patch<Client>(url, updatedClient);
  }

  /**
   * It uploads a document to a project.
   * @param imageKey - The key of the image that was uploaded.
   * @param imageName - The name of the image file.
   * @param projectID - The ID of the project you want to upload the document to.
   * @returns The observable of the newly created document.
   */
  uploadProjectDocument(imageKey, imageName, projectID, path?) {
    return this.store.select('client').pipe(
      take(1),
      switchMap((client) => {
        return this.httpClient.post<DocumentInterface>(
          `${environment.apiBaseUrl}/api/Clients/${client.id}/projectDocuments`,
          {
            key: imageKey,
            name: imageName,
            description: '',
            valueDate: null,
            dueDate: null,
            showDates: false,
            leadId: projectID,
            ownerType: 'Client',
            path
          },
        );
      }),
    );
  }

  /**
   * It notificates backend about client files uploaded.
   * @param projectID - The ID of the project which files has been uploaded to.
   * @returns The observable of the newly created document.
   */
  async sendNotificationProjectDocuments(projectID) {
    return this.httpClient.post<any>(
      `${environment.apiBaseUrl}/api/leads/${projectID}/notificate-new-documents`, null
    );
  }

  /**
   * It uploads a url to a project.
   * @param urlName - The name of the URL.
   * @param url - The URL that you want to upload.
   * @param projectID - The ID of the project you want to upload the document to.
   * @returns The observable of the http request.
   */
  uploadUrlProjectDocument(urlName, url, projectID) {
    return this.store.select('client').pipe(
      take(1),
      switchMap((client) => {
        return this.httpClient.post<DocumentInterface>(
          `${environment.apiBaseUrl}/api/Clients/${client.id}/uploadUrls`,
          {
            urlName,
            url,
            targetId: projectID,
            ownerId: client.id,
            ownerType: 'Client',
            targetType: 'Lead',
          },
        );
      }),
    );
  }

  /**
   * It removes a document from the client's uploadUrls array.
   * @param linkID - The ID of the document to remove.
   * @returns The observable of the http request.
   */
  removeProjectUrlDocument(linkID) {
    return this.store.select('client').pipe(
      switchMap((client) => {
        return this.httpClient.delete<DocumentInterface>(
          `${environment.apiBaseUrl}/api/Clients/${client.id}/uploadUrls/${linkID}`,
        );
      }),
    );
  }

  /**
   * It subscribes to the pending notices endpoint and updates the store with the new notices.
   * @returns The timer observable is returning a stream of numbers.
   */
  startPendingNoticesSubscription() {
    return timer(0, environment.subscriptions.notices.frequency).pipe(
      switchMap(() => this.getPendingNotices()),
      tap((notices) =>
        this.store.dispatch(
          ClientAddOrUpdateFieldAction({
            field: 'notices',
            value: notices,
          }),
        ),
      ),
    );
  }

  /**
   * Get all the pending notices for the current client
   * @returns An Observable of Notice[]
   */
  getPendingNotices() {
    return this.store.select('client').pipe(
      take(1),
      switchMap((client) => {
        if (!client || Object.keys(client).length <= 1) {
          return of([]);
        }

        const url = `${environment.apiBaseUrl}/api/Clients/${client.id}`;

        return this.httpClient.get<any>(`${url}/notices`, {
          headers: new HttpHeaders({
            referrer: `client-${client.id}`,
            filter: JSON.stringify({
              where: {
                or: [
                  {
                    frequency: 'read',
                    read: false,
                  },
                  {
                    frequency: 'completed',
                    completed: false,
                  },
                  {
                    frequency: 'dueDate',
                    dueDate: { gt: Date.now() },
                  },
                ],
              },
            }),
          }),
        });
      }),
    );
  }

  /**
   * It marks a notice as read.
   * @param noticeID - The ID of the notice to mark as read.
   * @returns The response is a JSON object with the following properties:
   */
  markNoticeAsRead(noticeID) {
    const url = `${environment.apiBaseUrl}/api/Notices/${noticeID}`;

    return this.httpClient.patch<any>(url, {
      read: true,
    });
  }

  /**
   * If the condition is not met, return false. Otherwise, return true
   * @param condition - The condition to check.
   * @param hasConditionModel - boolean
   * @returns The observable of the result of the filter.
   */
  evalNoticeModalCondition(condition, hasConditionModel) {
    return this.store.select('client').pipe(
      take(1),
      switchMap((agent) => {
        if (hasConditionModel) {
          return of(true);
        }

        const filter = { where: condition };
        const result = applyFilter([agent], filter);

        if (!result) {
          return of(false);
        }

        return of(result);
      }),
    );
  }

  /**
   * It gets the names of the clients that have an active model and have a name and email.
   * @returns An array of objects with the following structure:
   */
  getClientsNames() {
    const url = `${environment.apiBaseUrl}/api/Clients`;
    const filter = {
      where: {
        'modelActive.active': true,
        'contact.name': { neq: null },
        'contact.email': { neq: null },
      },
      fields: ['contact', 'id'],
      order: 'contact.name ASC',
    };
    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
      }),
    };

    return this.httpClient.get<any>(url, httpOptions);
  }
  /**
   * @param client Client up to set the b2b atribute to true or false
   * @returns Client updated with the property b2b
   */
  setB2b( client ) {
    const url = `${environment.apiBaseUrl}/api/Clients/${client.id}`;

    const body = {"b2b": client?.b2b ? false : true };

    return this.httpClient.patch( url , body );
  }

  setPPV( client ) {
    const url = `${environment.apiBaseUrl}/api/Clients/${client.id}`;

    const body = {"ppv": client?.ppv ? false : true};

    return this.httpClient.patch<any>(url, body);
  }

  setTracking( lead ) {
    const url = `${environment.apiBaseUrl}/api/Leads/${lead.id}`;

    const body = {"tracking": true};

    return this.httpClient.patch<any>(url, body);
  }

  registerEstimationClient(person) {
    const ownerId = this.localStorageService.get('userId');
    const url = `${environment.apiBaseUrl}/api/clients/estimation/registerClient`;

    return this.httpClient.post<any>(url, {
      person,
      ownerId
    });
  }


  setCreateInvoicesStructure( lead:Lead, budgetAccepted: Budget ) : Observable<any>{
    const projectData: ProjectData = {
      contact: {
        name: lead.contact.name,
        email: lead.contact.email,
        phoneNumber: lead.contact.phoneNumber,
        idNumber: lead.contact.idNumber
      },
      address: {
        address1: lead.address.address1,
        city: lead.address.city,
        zipCode: lead.address.zipCode,
        region: lead.address.region,
        country: lead.address.country
      },
      tax: budgetAccepted.taxPercent.toString(),
      totalBudget: budgetAccepted.total.toString(),
      shortCode: lead.shortCode
    }

    return this.invoiceService.newProjectInvoice(lead.id, projectData)
  }

    /**
   * It gets the names of the clients that have an active model and have a name and email contain @leroymerlin.es and ppv = true.
   * @returns An array of objects with the following structure:
   */
    getClientsLMNames() {
      const url = `${environment.apiBaseUrl}/api/Clients`;
      const filter = {
        where: {
          'modelActive.active': true,
          'contact.name': { neq: null },
          'contact.email': {$regex : "@leroymerlin.es"},
          'ppv': true
        },
        fields: ['contact', 'id'],
        order: 'contact.name ASC',
      };
      const httpOptions = {
        headers: new HttpHeaders({
          filter: JSON.stringify(filter),
        }),
      };
  
      return this.httpClient.get<any>(url, httpOptions);
    }


     /**
   * It gets clients with filter.
   * @returns An array of objects with the following structure:
   */
  getClientsByFilter(filter) {
    const url = `${environment.apiBaseUrl}/api/Clients`;
    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
      }),
    };

    return this.httpClient.get<any>(url, httpOptions);
  }

  getClientLeads(client): Observable<any>{
    const url = client.b2b ?`${environment.apiBaseUrl}/api/Clients/${client.id}/b2b/getLoginClientLeads`
    : `${environment.apiBaseUrl}/api/clients/${client.id}/get-logged-client-lead`
    return this.httpClient.get<any>(url).pipe(
      tap((projects) => {
        this.store.dispatch(ProjectsAddAction({ projects }));
      })
    )
  }
}

import {
  Component,
  OnInit,
  Output,
  ContentChild,
  EventEmitter,
  ElementRef,
  OnDestroy,
  Input,
} from '@angular/core';
import { ViewModeDirective } from './view-mode.directive';
import { EditModeDirective } from './edit-mode.directive';
import { Subject, fromEvent } from 'rxjs';
import {
  filter,
  take,
  switchMapTo,
  takeUntil,
  debounceTime,
} from 'rxjs/operators';

@Component({
  selector: 'app-editable-field',
  templateUrl: './editable-field.component.html',
  styleUrls: ['./editable-field.component.scss'],
})
export class EditableFieldComponent implements OnInit, OnDestroy {
  @Input() disableEdit: boolean = false;
  @Output() update = new EventEmitter();
  @ContentChild(ViewModeDirective, { static: true })
  viewModeTpl: ViewModeDirective;
  @ContentChild(EditModeDirective, { static: true })
  editModeTpl: EditModeDirective;

  mode: 'view' | 'edit' = 'view';

  editMode = new Subject();
  editMode$ = this.editMode.asObservable();

  unsubscriber$ = new Subject<void>();

  constructor(private host: ElementRef) {}

  get currentView() {
    return this.mode === 'view' ? this.viewModeTpl?.tpl : this.editModeTpl?.tpl;
  }

  ngOnInit() {
    this.viewModeHandler();
    this.editModeHandler();
  }

  toViewMode() {
    this.update.next(1);
    this.mode = 'view';
  }

  private get element() {
    return this.host.nativeElement;
  }

  private viewModeHandler() {
    fromEvent(this.element, 'click')
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        if (!this.disableEdit) {
          this.mode = 'edit';
          this.editMode.next(true);
        }
      });
  }

  private editModeHandler() {
    const clickOutside$ = fromEvent(document, 'mousedown').pipe(
      debounceTime(100),
      filter(({ target }) => {
        return this.element.contains(target) === false;
      }),
      take(1),
    );
    this.editMode$
      .pipe(switchMapTo(clickOutside$), takeUntil(this.unsubscriber$))
      .subscribe((event) => {
        this.update.next(1);
        this.mode = 'view';
      });
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
  }
}

/* eslint-disable */

declare var Object: any;
export interface ActionInterface {
  action: string;
  data: any;
  id?: string;
  referenceId?: string | string[];
  referenceType?: string;
  authorId?: string;
  authorType?: string;
  createdAt: Date;
  updatedAt: Date;
  modelActive?: any;
  agentId?: string;
  reference?: any;
  author?: any;
  proccessed?: boolean;
  notificable?: boolean;
}

export class Action implements ActionInterface {
  'action': string;
  'data': any;
  'id': string;
  'referenceId': string | string[];
  'referenceType': string;
  'authorId': string;
  'authorType': string;
  'createdAt': Date;
  'updatedAt': Date;
  'modelActive': any;
  'agentId': string;
  reference: any;
  author: any;
  constructor(data?: ActionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Action`.
   */
  public static getModelName() {
    return 'Action';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Action for dynamic purposes.
   **/
  public static factory(data: ActionInterface): Action {
    return new Action(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Action',
      plural: 'Actions',
      path: 'Actions',
      idName: 'id',
      properties: {
        action: {
          name: 'action',
          type: 'string',
        },
        data: {
          name: 'data',
          type: 'any',
        },
        id: {
          name: 'id',
          type: 'string',
        },
        referenceId: {
          name: 'referenceId',
          type: 'string',
        },
        referenceType: {
          name: 'referenceType',
          type: 'string',
        },
        authorId: {
          name: 'authorId',
          type: 'string',
        },
        authorType: {
          name: 'authorType',
          type: 'string',
        },
        createdAt: {
          name: 'createdAt',
          type: 'Date',
        },
        updatedAt: {
          name: 'updatedAt',
          type: 'Date',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
        agentId: {
          name: 'agentId',
          type: 'string',
        },
      },
      relations: {
        reference: {
          name: 'reference',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'referenceId',
          keyTo: 'id',
        },
        author: {
          name: 'author',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'authorId',
          keyTo: 'id',
        },
      },
    };
  }
}

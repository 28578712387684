import { switchMap } from 'rxjs/operators';
import { ErrorOccurredAction } from './../error-handler/error-handler.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';  // Asegúrate de importar Action desde @ngrx/store
import { ContextUpdateActionButtonRequestStarted, ContextUpdateActionButtonRequestSuccess, ContextUpdateActiveView, ContextUpdateActiveViewSuccess, ContextUpdateHeaderLinksRequestStarted, ContextUpdateHeaderLinksRequestSuccess } from './context.actions';
import { ContextFacadeService } from './context-facade.service';
import { Link } from '@core/sdk/models/Navigation';
import { ActionSelect } from '@core/sdk/models/Navigation';


@Injectable()
export class ContextEffects {
  // public changeContextProject$ = this.changeContextProject();
  public updateActiveView$ = this.updateActiveView();
  public updateContextElements$ = this.updateContextElements();
  public headerLinks$ = this.getHeaderLinks();
  public actionButton$ = this.getActionSelect();

  constructor(
    private actions$: Actions,
    private contextFacadeService: ContextFacadeService,
  ) {}

  private getHeaderLinks(): Observable<Action> {
    return createEffect(() =>
      this.actions$.pipe(
        ofType(ContextUpdateHeaderLinksRequestStarted),
        mergeMap(() => this.contextFacadeService.updateHeaderLinks()),
        map((headerLinks: Link[]) => ContextUpdateHeaderLinksRequestSuccess({ payload : headerLinks})),
        catchError((error) => {
          return of(ErrorOccurredAction({ payload: error }))
        })
      )
    );
  }


  private getActionSelect(): Observable<Action> {
    return createEffect(() =>
      this.actions$.pipe(
        ofType(ContextUpdateActionButtonRequestStarted),
        mergeMap(() => this.contextFacadeService.updateActionButton()),
        map((actionSelect: ActionSelect[]) => ContextUpdateActionButtonRequestSuccess({ payload: actionSelect })),
        catchError((error) => {
          return of(ErrorOccurredAction({ payload: error }))
        })
      )
    );
  }

  private updateActiveView(): Observable<Action> {
    return createEffect(() => this.actions$.pipe(
      ofType(ContextUpdateActiveView),
      map((activeView: string) =>
      ContextUpdateActiveViewSuccess( { payload: activeView }) ),
      catchError((error) => {
        return of(ErrorOccurredAction({ payload: error }))
      })
    ));
  }

  private updateContextElements(): Observable<Action> {
    return createEffect(() =>
      this.actions$.pipe(
        ofType(ContextUpdateActiveViewSuccess),
        mergeMap((payload: any) =>
          [
            ContextUpdateHeaderLinksRequestStarted(),
            ContextUpdateActionButtonRequestStarted(),
          ]
        ),
        catchError((error) => {
          return of(ErrorOccurredAction({ payload: error }))
        })
      )
    );
  }

  // private changeContextProject(): Observable<Action>
  // {
  //   return createEffect(() => this.actions$.pipe(
  //       ofType(ContextUpdateProjectAction),
  //       map((currentProjectId:string) =>
  //       getProjectRequestStarted({ projectId: currentProjectId })
  //      )
  //    )
  //   );
  // }
}

export enum ContextValues {
  'login-client',
  'agent'
}

export const CONTEXT_VALUES = {
  loginClient: 'login-client',
  agent: 'agent'
}

export const VIEW_MODES = {
  Web: 'web',
  Handset: 'handset',
  Tablet: 'tablet'
}
import { createAction, props } from '@ngrx/store';
import { Agent } from '@core/sdk';

export const AgentAddOrUpdateAction = createAction(
  '[Agent Component] Add',
  props<Agent>(), 
);

export const AgentAddOrUpdateFieldAction = createAction(
  '[Agent Component] AddOrUpdateFieldAction',
  props<any>(),
);

export const AgentAddOrUpdateFieldActionOnboarding = createAction(
  '[Agent Onboarding] AddOrUpdateFieldAction',
  props<any>(),
);

export const AgentRemoveAction = createAction(
  '[Agent Component] Remove',
  props<any>(),
);

import { Component, OnInit, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NoteService } from '@core/services/note.service';
import { NotificationsService } from '@core/services/notifications.service';
import { NotesModalComponent } from '../notes-modal/notes-modal.component';
import * as _ from 'lodash-es';
import { Store } from '@ngrx/store';
import { LeadService } from '@core/services/lead.service';
import { LoaderService } from '@core/services/loader.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
  @Input() target;
  @Input() notes: any[] = [];
  @Input() targetType;
  @Input() context: string;

  constructor(
    private dialog: MatDialog,
    private noteService: NoteService,
    private notificationsService: NotificationsService,
    private store: Store<{ context: any }>,
    private leadService : LeadService,
    private loader: LoaderService
  ) {}

  ngOnInit() {
  }

  openNotesModal(note = null) {
    const notesModal = this.dialog.open(NotesModalComponent, {
      width: '800px',
      height: '600px',
      data: {
        target: this.target,
        targetType: this.targetType,
        note,
      },
    });

    notesModal.afterClosed().subscribe(({ result, note }) => {
      if (!note) {
        return;
      }

      if (result === 'update') {
        const index = _.findIndex(this.notes, { id: note.id });
        this.notes.splice(index, 1, note);

        return;
      }

      this.notes.push(note);
    });
  }

  handleNoteRemoved(noteID) {
    switch (this.context) {
      case 'agent':
        this.noteService.removeNote(noteID).subscribe(() => {
          this.notificationsService.showSuccess('Nota eliminada');
          _.remove(this.notes, { id: noteID });
        });
        break;
      case 'login-client':
        this.noteService.removeLoginClienteNote(noteID).subscribe(() => {
          this.notificationsService.showSuccess('Nota eliminada');
          _.remove(this.notes, { id: noteID });
        });
        break;
    }
  }

}

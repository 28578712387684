<div class="header">
  <div class="emitter">
    <img
      *ngIf="receptor?.avatar; else initialLetters"
      [src]="receptor.avatar"
      alt=""
    />
    <ng-template #initialLetters>
      <app-initial-letters [name]="receptor?.name"></app-initial-letters>
    </ng-template>
    <span class="name">{{ receptor?.name }}</span>
  </div>
  <div class="emition-date">
    {{ message.createdAt | date: 'shortDate':'UTC' }}
    {{ message.createdAt | momentDate: 'shortTime':'Europe/Madrid' }}
  </div>
</div>
<div class="body" [innerHTML]="message.body" data-cy="message"></div>

<div
  class="image-wrapper"
  *ngIf="(message.documents && imageAttached) || message.image"
  [matTooltip]="imageAttached ? 'Descargar ' + imageAttached.fileName : 'imagen'"
  matTooltipPosition="above"
  (click)="downloadImage()"
>
    <img #clipboardImageUploaded [src]="imageAttached?.fileUrl" alt="">
  
</div>

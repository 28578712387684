<div class='info-area-wrapper'>
  <mat-icon fontSet='cubicup-icons'>info</mat-icon>
  <br>
  <span class='subtitle'>
    {{ infoAreaData?.text + '.body1' | translate }}
    <ng-container *ngIf="infoAreaData?.cta">
      <span class="link" (click)='onCtaClicked()'>
       {{ infoAreaData?.text + '.body2' | translate }}
      </span>
    </ng-container>
  </span>
</div>

/* eslint-disable */
import { GeoPoint } from './index';

declare var Object: any;
export interface AddressInterface {
  address1: string;
  address2?: string;
  city: string;
  zipCode: string;
  region?: string;
  country?: string;
  location?: GeoPoint;
  modelActive?: any;
}

export class Address implements AddressInterface {
  'address1': string;
  'address2': string;
  'city': string;
  'zipCode': string;
  'region': string;
  'country': string;
  'location': GeoPoint;
  'modelActive': any;
  constructor(data?: AddressInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Address`.
   */
  public static getModelName() {
    return 'Address';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Address for dynamic purposes.
   **/
  public static factory(data: AddressInterface): Address {
    return new Address(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Address',
      plural: 'Addresses',
      path: 'Addresses',
      idName: 'id',
      properties: {
        address1: {
          name: 'address1',
          type: 'string',
        },
        address2: {
          name: 'address2',
          type: 'string',
        },
        city: {
          name: 'city',
          type: 'string',
        },
        zipCode: {
          name: 'zipCode',
          type: 'string',
        },
        region: {
          name: 'region',
          type: 'string',
        },
        country: {
          name: 'country',
          type: 'string',
        },
        location: {
          name: 'location',
          type: 'GeoPoint',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
      },
      relations: {},
    };
  }
}

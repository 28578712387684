import {
  Directive,
  HostListener,
  ElementRef,
  AfterContentInit,
  Input,
  OnDestroy,
} from '@angular/core';
import { EditableFieldComponent } from './editable-field.component';

@Directive({
  selector: '[appEditableOnEnter]',
})
export class EditableOnEnterDirective implements AfterContentInit, OnDestroy {
  @Input() appEditableOnEnter: 'default' | 'alternative' | '';

  constructor(
    private editable: EditableFieldComponent,
    public elementRef: ElementRef,
  ) {}

  @HostListener('keyup.enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (
      this.appEditableOnEnter === '' ||
      this.appEditableOnEnter === 'default'
    ) {
      event.preventDefault();
      this.elementRef.nativeElement.blur();
      this.editable.toViewMode();
    }
  }

  @HostListener('keyup.shift.enter', ['$event'])
  onShiftEnter(event) {
    if (this.appEditableOnEnter === 'alternative') {
      console.log(event);
      event.preventDefault();
      event.target.value.trim();
      this.elementRef.nativeElement.blur();
      this.editable.toViewMode();
    }
  }

  ngAfterContentInit() {
    this.elementRef.nativeElement.focus();
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.blur();
  }
}

<div class="header">
  <mat-icon svgIcon="illustration-brokenHeart"></mat-icon>
  <button mat-icon-button mat-dialog-close>
    <mat-icon svgIcon="24px-close"></mat-icon>
  </button>
</div>

<span>{{ questionTile | translate }}</span>

<p>{{ questionSubtitle | translate }}</p>

<form [formGroup]="rejectedForm">
  <mat-radio-group aria-label="Select an option" formControlName="reason">
    <mat-option
      *ngFor="let reason of reasons; index as reasonIndex"
      [value]="reason.id"
      (click)="ifChecked(reasonIndex, reason)"
    >
      <div class="radio-row" *ngIf="reasonIndex === selected; else elseButton">
        <mat-icon svgIcon="16px-checkFilled"></mat-icon>
        {{ reason.reason }}
      </div>
      <ng-template #elseButton>
        <div class="radio-row">
          <mat-icon svgIcon="16px-checkEmpty"></mat-icon>
          {{ reason.reason }}
        </div>
      </ng-template>
      <mat-divider></mat-divider>
    </mat-option>
  </mat-radio-group>
</form>

<div class="buttons">
  <button mat-stroked-button class="btn btn-warn" mat-dialog-close>
    {{ 'cancel' | translate }}
  </button>
  <button class="btn btn-success text-white"
       
      [mat-dialog-close]="rejectedForm.value"
      [disabled]="rejectedForm.invalid"
  >
    {{ 'send-answer' | translate }}
  </button>
</div>

<div *ngIf="hasPermission">
  <div matBadgePosition="after" [matTooltip]="handleToolTip()" [matBadgeColor]="badge?.color" matBadgeSize="small" [matBadge]="badge?.text">
  <a href="#" class="transition-colors duration-300 text-gray-500 hover:text-white-800 text-xs" 
    [class.text-blue-600]="isActive"
    [class.bg-cubi-primary]="isActive"
    [class.rounded-box]="isActive"
    [class.text-white]="isActive"
    
    [class.p-2]="isActive"
    (click)="handleClick($event)" [attr.data-cy]="dataCy" >
    {{ linkText }}
  </a>
</div>
</div>
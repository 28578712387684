<!-- {{client| json}} -->
<ng-container *ngIf="lead">
  <div class="desktop-view">
    <p class="budget-type text-md">
      <span data-cy="budget-title" *ngIf="budget">{{
        budget.title | translate | titlecase
      }}</span>

      <ng-container *ngIf="!lead.own">
        <span
          *ngIf="context !== 'login-client' && budget"
          class="text-xs"
          [ngClass]="getBudgetStateClass(budget.state)"
          >{{ budget.state | translate | uppercase }}</span
        >
      </ng-container>
    </p>

    <p class="budget-description" *ngIf="budget">
      <span>{{ budget.description }}</span>
      <span>
        <span class="budget-date">
          {{ budget.updatedAt | date: 'dd/MM/yyyy' }}
        </span>
        <span>Ref: {{ budget.code }}</span>
      </span>
    </p>

    <div class="budget-actors">
      <app-mini-person-data
        class="card-custom"
        [agent]="agentBudget"
      ></app-mini-person-data>

      <app-mini-person-data
        class="card-custom"
        [client]="client"
        [lead]="lead"
      ></app-mini-person-data>
    </div>
  </div>
  <div class="mobile-view">
    <div class="mobile-view-title">
      <app-initial-letters [name]="client?.contact.name"></app-initial-letters>
      <span *ngIf="budget; else certificationAddress" class="mb-2">{{
        budget.title | translate | titlecase
      }}</span>

      <ng-container *ngIf="!lead.own" class="mt-2">
        <span
          *ngIf="context !== 'login-client' && budget"
          class="text-xs"
          [ngClass]="getBudgetStateClass(budget.state)"
          >{{ budget.state | translate | uppercase }}</span
        >
      </ng-container>

      <ng-template #certificationAddress>
        <span class="certification-address">
          C/ {{ lead.address.address1 | translate | titlecase }},
          {{ lead.address.city }}
        </span>
      </ng-template>
    </div>

    <ng-container
      *ngIf="{ value: detailsExpanded$ | async } as detailsExpanded"
    >
      <mat-expansion-panel
        *ngIf="detailsExpanded !== null"
        disabled
        hideToggle
        #personDetails="matExpansionPanel"
        class="mat-elevation-z0"
        [expanded]="detailsExpanded.value"
      >
        <mat-expansion-panel-header
          collapsedHeight="auto"
          expandedHeight="auto"
        >
          <div class="mobile-view-person-details-header">
            <span class="from">{{ 'from' | translate }}</span>
            <p class="to">
              <span>{{ 'to' | translate }}</span>
              <span
                class="details-button primary-link"
                (click)="toggleDetails()"
              >
                {{
                  (detailsExpanded.value ? 'hide-details' : 'show-details')
                    | translate
                }}
              </span>
            </p>
            <span class="agent-name">{{ agentBudget?.tradeName }}</span>
            <span class="lead-name">{{ lead.contact.name }}</span>
          </div>
        </mat-expansion-panel-header>

        <div class="mobile-view-person-details-content">
          <div class="agent-details">
            <p>{{ agentBudget?.contact?.phoneNumber }}</p>

            <ng-container *ngIf="agentBudget?.address">
              <p>
                {{
                  agentBudget.address.address2
                    ? agentBudget.address.address1 +
                      ', ' +
                      agentBudget.address.address2
                    : agentBudget.address.address1
                }}
              </p>

              <p>
                {{
                  agentBudget.address.city
                    ? agentBudget.address.city + ', '
                    : ''
                }}
                {{ agentBudget.address.zipCode }}
              </p>
            </ng-container>
          </div>

          <div class="lead-details">
            <p>{{ lead.contact.phoneNumber }}</p>

            <ng-container *ngIf="lead.address">
              <p>
                {{
                  lead.address.address2
                    ? lead.address.address1 + ', ' + lead.address.address2
                    : lead.address.address1
                }}
              </p>

              <p>
                {{ lead.address.city ? lead.address.city + ', ' : '' }}
                {{ lead.address.zipCode }}
              </p>
            </ng-container>
          </div>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </div>
</ng-container>

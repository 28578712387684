import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RemoveNoteModalComponent } from './remove-note-modal/remove-note-modal.component';
import { ResponsiveService } from '@core/services/responsive.service';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent implements OnInit {
  @Input() note: any;
  @Output() noteRemoved: EventEmitter<string> = new EventEmitter<string>();

  viewMode: string;

  constructor(
    private dialog: MatDialog,
    private responsiveService: ResponsiveService,
  ) {}

  ngOnInit() {
    this.responsiveService.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );
  }

  removeNote() {
    const removeNoteModal = this.dialog.open(RemoveNoteModalComponent, {
      width: '650px',
      height: this.viewMode !== 'web' ? '270px' : '180px',
    });

    removeNoteModal.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      this.noteRemoved.emit(this.note.id);
    });
  }
}

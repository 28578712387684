<ng-container *ngIf="viewMode === 'default'">
  <div class="default-mode">
    <img
      class="image-preview-image"
      [src]="fileUrl ? fileUrl : emptyAvatarPath"
      (click)="fileInput.click()"
    />
    <div class="image-info">
      <span class="image-info-claim-title">
        {{ imagePreviewTitle | translate }}
      </span>
      <span class="image-info-claim-subtitle" (click)="fileInput.click()">
        {{ 'upload-image' | translate }} (max. 70KB)
      </span>
    </div>

    <input
      hidden
      (change)="handleFileSelect($event)"
      #fileInput
      type="file"
      id="file"
    />
  </div>
</ng-container>

<ng-container *ngIf="viewMode === 'add'">
  <div>
    <button mat-icon-button class="add" (click)="fileInput.click()">
      <mat-icon>add</mat-icon>
    </button>
    <input
      hidden
      (change)="handleFileSelect($event)"
      #fileInput
      type="file"
      id="file"
    />
  </div>
</ng-container>

<ng-container *ngIf="viewMode === 'mini'">
  <!-- <div *ngIf="viewMode === 'mini'" #miniMode class="mini-mode"> -->
  <div
    class="preview"
    (click)="getFile($event)"
    *ngIf="fileType$ | async as fileType"
  >
    <ng-container [ngSwitch]="fileType">
      <div
        class="full-svg"
        *ngSwitchCase="'pdf'"
        [ngStyle]="{
          'background-image': 'url(assets/icons/PDF.svg)'
        }"
      ></div>

      <div
        class="full-svg"
        *ngSwitchCase="'txt'"
        [ngStyle]="{
          'background-image': 'url(assets/icons/Details.svg)'
        }"
      ></div>

      <div
        class="full-svg"
        *ngSwitchCase="'docx'"
        [ngStyle]="{
          'background-image': 'url(assets/icons/Details.svg)'
        }"
      ></div>

      <div
        class="full-svg"
        *ngSwitchCase="'doc'"
        [ngStyle]="{
          'background-image': 'url(assets/icons/Details.svg)'
        }"
      ></div>

      <div
        class="full-svg"
        *ngSwitchCase="'odt'"
        [ngStyle]="{
          'background-image': 'url(assets/icons/Details.svg)'
        }"
      ></div>

      <div
        class="full-svg"
        *ngSwitchCase="'xls'"
        [ngStyle]="{
          'background-image': 'url(assets/icons/Details.svg)'
        }"
      ></div>

      <div
        class="full-svg"
        *ngSwitchCase="'xlsx'"
        [ngStyle]="{
          'background-image': 'url(assets/icons/Details.svg)'
        }"
      ></div>

      <div
        class="full-svg"
        *ngSwitchCase="'ods'"
        [ngStyle]="{
          'background-image': 'url(assets/icons/Details.svg)'
        }"
      ></div>

      <div
        class="full-svg"
        *ngSwitchCase="'ppt'"
        [ngStyle]="{
          'background-image': 'url(assets/icons/Details.svg)'
        }"
      ></div>

      <div
        class="full-svg"
        *ngSwitchCase="'pptx'"
        [ngStyle]="{
          'background-image': 'url(assets/icons/Details.svg)'
        }"
      ></div>

      <div
        class="full-svg"
        *ngSwitchCase="'odp'"
        [ngStyle]="{
          'background-image': 'url(assets/icons/Details.svg)'
        }"
      ></div>

      <div
        *ngSwitchCase="'image'"
        [ngStyle]="{
          'background-image': 'url(' + (fileUrl$ | async) + ')'
        }"
        class="full-image"
      ></div>

      <div
        class="full-svg"
        *ngSwitchDefault
        [ngStyle]="{
          'background-image': 'url(assets/icons/Details.svg)'
        }"
      ></div>
    </ng-container>

    <!-- <img src="assets/icons/Details.svg" /> -->
    <!-- <object
    type="image/svg+xml"
    data="assets/icons/sprite-24.svg?id='Details'"
    ></object> -->

    <div class="icons">
      <ng-container [ngSwitch]="fileType">
        <mat-icon *ngSwitchCase="'pdf'" svgIcon="16px-pdf"> </mat-icon>

        <mat-icon *ngSwitchCase="'txt'" svgIcon="16px-invoice"> TXT </mat-icon>
        <mat-icon *ngSwitchCase="'docx'" svgIcon="16px-invoice">
          docx
        </mat-icon>
        <mat-icon *ngSwitchCase="'doc'" svgIcon="16px-invoice"> doc </mat-icon>
        <mat-icon *ngSwitchCase="'odt'" svgIcon="16px-invoice"> odt </mat-icon>

        <mat-icon *ngSwitchCase="'xls'" svgIcon="16px-invoice"> xls </mat-icon>
        <mat-icon *ngSwitchCase="'xlsx'" svgIcon="16px-invoice">
          xlsx
        </mat-icon>
        <mat-icon *ngSwitchCase="'ods'" svgIcon="16px-invoice"> ods </mat-icon>

        <mat-icon *ngSwitchCase="'ppt'" svgIcon="16px-invoice"> ppt </mat-icon>
        <mat-icon *ngSwitchCase="'pptx'" svgIcon="16px-invoice">
          pptx
        </mat-icon>
        <mat-icon *ngSwitchCase="'odp'" svgIcon="16px-invoice"> odp </mat-icon>

        <div
          *ngSwitchCase="'image'"
          [ngStyle]="{
            'background-image': 'url(' + (fileUrl$ | async) + ')'
          }"
          class="mini-image"
        ></div>

        <mat-icon *ngSwitchDefault svgIcon="16px-invoice"> no type </mat-icon>
      </ng-container>

      <button
        mat-icon-button
        (click)="removeFile($event)"
        *ngIf="showRemoveButton"
        data-cy="remove-file"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <p *ngIf="fileName$" class="filename">
      {{ fileName$ | async }}
    </p>
  </div>
  <!-- </div> -->
</ng-container>

<ng-container *ngIf="viewMode === 'mini-list'">
  <div
    class="overflow-y-scroll align-center flex md:flex-row-reverse md:w-full card bg-base-100 preview-mini-list rounded h-20"
    appDebounceClick
    (debounceClick)="getFile($event)"
    [debounceTime]="700"
    *ngIf="fileType$ | async as fileType"
  >

    <div class="touch-pan-y card-body left pl-0.5 p-2 md:flex md:flex-row mb-1">
      <ng-container [ngSwitch]="fileType" class="w-2/5">
        <div
          class="full-svg"
          *ngSwitchCase="'pdf'"
          [ngStyle]="{
            'background-image': 'url(assets/icons/PDF.svg)'
          }"
        ></div>

        <div
          class="full-svg"
          *ngSwitchCase="'txt'"
          [ngStyle]="{
            'background-image': 'url(assets/icons/Details.svg)'
          }"
        ></div>

        <div
          class="full-svg"
          *ngSwitchCase="'docx'"
          [ngStyle]="{
            'background-image': 'url(assets/icons/Details.svg)'
          }"
        ></div>

        <div
          class="full-svg"
          *ngSwitchCase="'doc'"
          [ngStyle]="{
            'background-image': 'url(assets/icons/Details.svg)'
          }"
        ></div>

        <div
          class="full-svg"
          *ngSwitchCase="'odt'"
          [ngStyle]="{
            'background-image': 'url(assets/icons/Details.svg)'
          }"
        ></div>

        <div
          class="full-svg"
          *ngSwitchCase="'xls'"
          [ngStyle]="{
            'background-image': 'url(assets/icons/Details.svg)'
          }"
        ></div>

        <div
          class="full-svg"
          *ngSwitchCase="'xlsx'"
          [ngStyle]="{
            'background-image': 'url(assets/icons/Details.svg)'
          }"
        ></div>

        <div
          class="full-svg"
          *ngSwitchCase="'ods'"
          [ngStyle]="{
            'background-image': 'url(assets/icons/Details.svg)'
          }"
        ></div>

        <div
          class="full-svg"
          *ngSwitchCase="'ppt'"
          [ngStyle]="{
            'background-image': 'url(assets/icons/Details.svg)'
          }"
        ></div>

        <div
          class="full-svg"
          *ngSwitchCase="'pptx'"
          [ngStyle]="{
            'background-image': 'url(assets/icons/Details.svg)'
          }"
        ></div>

        <div
          class="full-svg"
          *ngSwitchCase="'odp'"
          [ngStyle]="{
            'background-image': 'url(assets/icons/Details.svg)'
          }"
        ></div>

        <div
          *ngSwitchCase="'image'"
          [ngStyle]="{
            'background-image': 'url(' + (fileUrl$ | async) + ')'
          }"
          class="full-image"
        ></div>

        <div
          class="full-svg"
          *ngSwitchDefault
          [ngStyle]="{
            'background-image': 'url(assets/icons/Details.svg)'
          }"
        ></div>
      </ng-container>

      <span *ngIf="fileName$" class="text-xs overflow-hidden w-4/5">
        {{ fileName$ | async }}
      </span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="viewMode === 'mobile'">
  <div class="p-2 rounded-lg bg-red-900">
    <mat-icon class="text-white">picture_as_pdf</mat-icon> <!-- Asumiendo que tienes un icono 'pdf-icon' -->
  </div>
</ng-container>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cleanPhoneNumber',
})
export class CleanPhoneNumberPipe implements PipeTransform {
  transform(value: string): string {
    if (value === null) {
      return '';
    }

    // Spanish mobile number form: XXX XXX XXX
    const newRaw = value.replace(/\W/g, '').trim().replace('-', '');
    return `${newRaw.slice(0, 3)} ${newRaw.slice(3, 6)} ${newRaw.slice(6, 9)}`;
  }
}

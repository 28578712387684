import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  AuthLoginAction,
  AuthLoginFailureCleanAction,
} from 'src/app/store/actions/auth.actions';
import { NotificationsService } from '@core/services/notifications.service';
import { selectQueryParams } from 'src/app/store/router.selectors';
import { Params } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();

  loginForm: UntypedFormGroup;
  firstCheck = false;
  sendingForm = false;
  loginButtonLabel = 'login';
  utmSource = {};
  hidePassword: boolean = true;
  redirectUrl: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private store: Store<{ user: any; router: any }>,
    private authService: AuthService,
  ) {
    if (this.authService.isLogged()) {
      this.authService.redirectUser();
    }
  }

  ngOnInit(): void {
    this.initializeTranslations();
    this.getQueryParams();
    this.handleErrors();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * This function is responsible for getting the query parameters from the URL and initializing the
   * form with the values from the query parameters
   */
  private getQueryParams() {
    this.store
      .select(selectQueryParams)
      .pipe(takeUntil(this.unsubscribe$), filter(Boolean))
      .subscribe((queryParams: Params) => {
        if (!queryParams) {
          this.initializeForm();
          return;
        }

        const { utm_source, utm_medium, utm_campaign, redirectUrl, userEmail } = queryParams;

        if (
          utm_source &&
          utm_medium &&
          utm_campaign &&
          redirectUrl &&
          userEmail
        ) {
          if (utm_source.includes('facebook')) {
            this.utmSource = {
              utm_source,
              utm_medium,
              utm_campaign,
            };
          }

          this.redirectUrl = redirectUrl;

          this.initializeForm(userEmail);

          return;
        }

        if (userEmail) {
          this.initializeForm(userEmail);

          return;
        }

        this.initializeForm();
      });
  }

  /**
   * The function sets the default language to Spanish and then sets the current language to Spanish
   */
  private initializeTranslations() {
    this.translateService.setDefaultLang('es_ES');
    this.translateService.use('es_ES');
  }

  /**
   * Initialize the form with the user's email if provided
   * @param [userEmail=null] - The email address of the user.
   */
  private initializeForm(userEmail = null) {
    const defaultUserEmailValue = userEmail || '';

    this.loginForm = this.formBuilder.group({
      email: [
        defaultUserEmailValue,
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          ),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
        ],
      ],
      remember: [false],
    });
  }

  /**
   * Toggle the label of the login button between "login" and "login-loading"
   * @returns Nothing.
   */
  private toggleLoginButtonLabel() {
    this.sendingForm = !this.sendingForm;

    if (!this.sendingForm) {
      this.loginForm.controls.email.enable();
      this.loginForm.controls.password.enable();
      this.loginButtonLabel = 'login';

      return;
    }

    this.loginForm.controls.email.disable();
    this.loginForm.controls.password.disable();
    this.loginButtonLabel = 'login-loading';
  }

  /**
   * * Subscribe to the errorMessage state in the user store.
   * * If there is no errorMessage, return.
   * * If there is an errorMessage, show it as a success notification.
   * * Dispatch the AuthLoginFailureCleanAction to the user store.
   * * Toggle the login button label back to the default.
   * * Initialize the form
   */
  private handleErrors() {
    this.store.select('user', 'errorMessage').subscribe(
      (errorMessage) => {
        this.store.dispatch(AuthLoginFailureCleanAction());

        if (!errorMessage) {
          return;
        }

        this.notificationsService.showSuccess(errorMessage);

        this.toggleLoginButtonLabel();
        this.initializeForm();
      }
    );
  }

  /**
   * If the control has a validator, and the validator has a required property, then return true
   * @param controlName - The name of the control to validate.
   * @returns The return value is a boolean value.
   */
  requiredValidator(controlName) {
    const { validator } = this.loginForm.controls[controlName];

    if (!validator) {
      return false;
    }

    const validation = validator({} as AbstractControl);

    if (validation && validation.required) {
      return true;
    }

    return false;
  }

  /**
   * It dispatches an action to the store.
   * @returns Nothing.
   */
  login() {
    if (this.loginForm.invalid) {
      return;
    }

    this.toggleLoginButtonLabel();

    const { email, password, remember } = this.loginForm.getRawValue();

    this.store.dispatch(
      AuthLoginAction({
        email,
        password,
        redirectUrl: this.redirectUrl,
        remember,
      }),
    );
  }
}

<div class="title">
  <p mat-dialog-title>{{ 'new-template' | translate }}</p>
  <button mat-icon-button (click)="close()">
    <mat-icon svgIcon="16px-close"></mat-icon>
  </button>
</div>
<div class="custom-input text-xs">
  <p>
    {{ 'name-new-template' | translate }}
  </p>
  <mat-form-field appearance="outline" color="primary">
    <input
      matInput
      type="text"
      [formControl]="nameTemplate"
      placeholder="Ej: Reforma Integral Gama Alta"
      data-cy="name-template"
    />
  </mat-form-field>
</div>
<form [formGroup]="budgetCreateOptionForm" *ngIf="budgetCreateOptionForm">
  <mat-radio-group aria-label="Select an option">
    <mat-option [value]="'new'" (click)="ifChecked('new')">
      <div
        class="radio-row"
        *ngIf="this.checkSelected === 'new'; else elseNew"
        data-cy="scratch-budget"
      >
        <mat-icon svgIcon="16px-checkFilled"></mat-icon>
        {{
          (viewMode === 'web'
            ? 'create-scratch-budget'
            : 'create-scratch-budget-mobile'
          ) | translate
        }}
      </div>
      <ng-template #elseNew>
        <div class="radio-row">
          <mat-icon svgIcon="16px-checkEmpty"></mat-icon>
          {{
            (viewMode === 'web'
              ? 'create-scratch-budget'
              : 'create-scratch-budget-mobile'
            ) | translate
          }}
        </div>
      </ng-template>
      <mat-divider></mat-divider>
    </mat-option>

    <mat-option [value]="'bc3'" (click)="ifChecked('bc3'); fileInput.click()">
      <div class="radio-row" *ngIf="this.checkSelected === 'bc3'; else elseBc3">
        <mat-icon svgIcon="16px-checkFilled"></mat-icon>
        {{
          (viewMode === 'web' ? 'select-a-bc3' : 'select-a-bc3-mobile')
            | translate
        }}
      </div>
      <ng-template #elseBc3>
        <div class="radio-row">
          <mat-icon svgIcon="16px-checkEmpty"></mat-icon>
          {{
            (viewMode === 'web' ? 'select-a-bc3' : 'select-a-bc3-mobile')
              | translate
          }}
        </div>
      </ng-template>
      <input
        hidden
        (change)="handleDrop($event)"
        #fileInput
        type="file"
        id="file"
      />
      <mat-divider></mat-divider>
    </mat-option>
  </mat-radio-group>
</form>

<ng-container *ngIf="this.checkSelected === 'bc3'">
  <div class="bc3-selected">
    <span class="label">{{ 'file-selected' | translate }}: </span>
    <span class="file">{{ this.data?.file?.name }}</span>
  </div>
</ng-container>
<div class="dialog-actions">
  <button mat-dialog-close mat-stroked-button color="accent">
    {{ 'cancel' | translate }}
  </button>

  <button (click)="save()" mat-flat-button color="primary" data-cy="save">
    {{ 'save' | translate }}
  </button>
</div>

import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormControlDirective } from '@angular/forms';

@Directive({
  selector: '[appAllowTab]',
})
export class AllowTabDirective {
  @Input() currentValue: string;

  constructor(
    private elementRef: ElementRef,
    private control: FormControlDirective,
  ) {}

  @HostListener('keydown.tab')
  onEnter() {
    const elementValue = this.elementRef.nativeElement.value;
    const cursorStart = this.elementRef.nativeElement.selectionStart;
    const cursorEnd = this.elementRef.nativeElement.selectionEnd;

    this.control.control.setValue(
      elementValue.substring(0, cursorStart) +
        '  ' +
        elementValue.substring(cursorEnd),
    );

    this.elementRef.nativeElement.focus();
    this.elementRef.nativeElement.setSelectionRange(
      cursorEnd + 2,
      cursorEnd + 2,
    );
    return false;
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CONTEXTS } from '@core/constants';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DocumentationGuard  {
  

  constructor(private store: Store<{context:any}>, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select('context'),
      take(1),
      map(({ value: context, currentProjectId }) => {
        switch(context){
          case CONTEXTS.AGENT:
            return true;
          case CONTEXTS.LOGIN_CLIENT:
            this.router.navigate(['logged-client','documentation2']);
            //this.router.navigate(['logged-client','documentation2', currentProjectId]);
            return false;
        }
      })
    );
  }
}

<section>
  <div class="left" [ngClass]="{ clicked: reminder.clicked }">
    <div>
      <div class="icon-wrapper" [ngClass]="reminderTypes[reminder.type].class">
        <mat-icon [svgIcon]="reminderTypes[reminder.type].icon"></mat-icon>
      </div>
    </div>

    <ng-container [ngSwitch]="reminder.type">
      <div class="info project" *ngSwitchCase="'project'">
        <span class="title" [ngClass]="rejectedOrAccepted">
          {{ reminder.title | translate }}
        </span>
        <div class="body">
          <span>{{ reminder.body.clientName }}</span>
          <span>{{ reminder.body.address }}</span>
        </div>
        <span class="success-date">
          {{ reminder.successDate | date: 'shortDate':'UTC' }} a las
          {{ reminder.successDate | momentDate: 'shortTime':'Europe/Madrid' }}
        </span>
      </div>

      <div class="info message" *ngSwitchCase="'message'">
        <span class="title">{{ reminder.title | translate }}</span>
        <div class="body">
          <span>{{ reminder.body.clientName }}</span>
          <span>{{ reminder.body.address }}</span>
        </div>
        <span class="success-date">
          {{ reminder.successDate | date: 'shortDate':'UTC' }} a las
          {{ reminder.successDate | momentDate: 'shortTime':'Europe/Madrid' }}
        </span>
      </div>

      <div class="info activity" *ngSwitchCase="'activity'">
        <span class="title">{{ reminder.title | translate }}</span>
        <div class="body">
          <span>{{ reminder.body.clientName }}</span>
          <span>{{ reminder.body.address }}</span>
        </div>
        <span class="success-date">
          {{ reminder.successDate | date: 'shortDate':'UTC' }} -
          {{ reminder.successDate | momentDate: 'shortTime':'Europe/Madrid' }}
        </span>
      </div>
    </ng-container>
  </div>

  <div class="wrapper" *ngIf="reminder.type !== 'activity'">
    <button mat-icon-button (click)="closeReminder(); $event.stopPropagation()">
      <mat-icon svgIcon="24px-close"></mat-icon>
    </button>
  </div>
</section>

<section class="read" *ngIf="reminder.read">
  {{ 'read-past' | translate }}
  <mat-icon>check</mat-icon>
</section>

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ClientInterface, DocumentInterface } from '@core/sdk';
import { AgentService } from '@core/services/agent.service';
import { ClientService } from '@core/services/client.service';
import { NotificationsService } from '@core/services/notifications.service';
import { ResponsiveService } from '@core/services/responsive.service';
import { S3Service } from '@core/services/s3.service';
import environment from '@environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DecisionModalComponent } from '@shared/dialogs/decision-modal/decision-modal.component';
import { InputModalComponent } from '@shared/dialogs/input-modal/input-modal.component';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ClientAddOrUpdateFieldAction } from 'src/app/store/actions/client.actions';
import { CONTEXTS, IMAGE_EXTENSIONS, VIDEO_EXTENSIONS } from '../../core/constants';
import { Gallery } from 'ng-gallery';
import { LoaderService } from '@core/services/loader.service';
import { Router } from '@angular/router';
import { MixpanelService } from '@core/services/mixpanel.service';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

interface IProjectDocumentsOptions {
  uploadImages?: boolean;
  removeImages?: boolean;
  imagesKey?: string;
  imagesSubtitleSlug?: string;
  uploadFiles?: boolean;
  filesKey?: string;
  removeFiles?: boolean;
  filesSubtitleSlug?: string;
  uploadUrls?: boolean;
  removeUrls?: boolean;
  urlsSubtitleSlug?: string;
}

@Component({
  selector: 'app-project-documents',
  templateUrl: './project-documents.component.html',
  styleUrls: ['./project-documents.component.scss'],
})
export class ProjectDocumentsComponent implements OnInit {

  @Input() documents$: BehaviorSubject<any> = new BehaviorSubject([]);
  @Input() urlDocuments$: BehaviorSubject<any> = new BehaviorSubject([]);
  @Input() projectId: string;
  @Input() options: IProjectDocumentsOptions = {
    uploadImages: false,
    removeImages: false,
    imagesKey: 'project',
    imagesSubtitleSlug: 'project',
    uploadFiles: false,
    filesKey: 'project',
    filesSubtitleSlug: '',
    removeFiles: false,
    uploadUrls: false,
    removeUrls: false,
    urlsSubtitleSlug: '',
  };

  @Output() reloadProjectDocuments: EventEmitter<boolean> = new EventEmitter();
  @Output()
  reloadProjectUrlDocuments: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('fileInput', { static: false }) fileInput;

  images = [];
  videos = [];
  files = [];
  links = [];
  context: string;
  viewMode: string;
  projectMemoryUse = 0;
  galleryId: string = 'project-documents-gallery';

  selectedFiles: number[] = [];
  deleteDocumentMode: boolean = false;
  deleteButtonMessage: string = 'delete-file';
  clientId: string;

  constructor(
    private responsiveService: ResponsiveService,
    private agentService: AgentService,
    private clientService: ClientService,
    private s3Service: S3Service,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private store: Store<{ context: any; client: ClientInterface }>,
    private matDialog: MatDialog,
    public gallery: Gallery,
    private loader: LoaderService,
    private router: Router,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit() {
    this.loader.enable();

    combineLatest([
      this.documents$,
      this.urlDocuments$,
    ]).subscribe(([documents, urlDocuments]) =>
      this.initializeComponent(documents, urlDocuments),
    );

    this.responsiveService.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );

    this.store
      .select('context')
      .pipe(take(1))
      .subscribe(({ value: context }) => (this.context = context));

    this.store.select('client').subscribe((client) => {
      if (!client || Object.keys(client).length === 0) {
        return;
      }

      this.clientId = client.id;
      this.projectMemoryUse = client.memoryUse
        ? (client.memoryUse.projectDocuments * 50) / 100
        : 0;
    });
  }

  public get isMobile() {
    return this.viewMode === 'handset';
  }

  /**
   * If there are no documents, do nothing. Otherwise, loop through the documents and add them to the
   * appropriate array
   * @param documents - The documents that are being displayed in the document list.
   * @param urlDocuments - An array of documents that are not in the database.
   * @returns The `initializeComponent` method returns nothing.
   */
  private initializeComponent(documents, urlDocuments) {
    this.images = [];
    this.files = [];
    this.links = [];

    if (documents.length === 0 && urlDocuments.length === 0) {
      this.loader.disable();

      return;
    }

    documents.forEach(
      (document: DocumentInterface) => {
        if (!document.versions) {
          console.error(`Document without version! Data: ${JSON.stringify(document)}`);
          return;
        }

        const [file] = document.versions;

        if (!file) {
          return;
        }

        const imageRegex = /\gif|jpe?g|tiff?|png|webp|bmp$/i;

        if (imageRegex.test(file.mime)) {
          // !! This is a workaround to avoid duplicated images in observable
          const isExistingImage = this.images.find((image) => image.id === document.id);

          if(isExistingImage) return;

          this.images.push(document);

          return;
        }

        const videoRegex = /\mp4|ogg|avi?|mkv$/i;

        if (videoRegex.test(file.mime)) {
          // !! This is a workaround to avoid duplicated images in observable
          const isExistingVideo = this.videos.find((video) => video.id === document.id);

          if(isExistingVideo) return;

          this.videos.push(document);

          return;
        }

        this.files.push(document);
      }
    );

    

    this.links = urlDocuments;
  }

  private isExtensionValidToInputType(
    fileName: string,
    inputType: 'images' | 'documents' | 'all'
  ) {
    const fileExtension = fileName.split('.').pop();

    if (
      inputType === 'images' &&
      !(VIDEO_EXTENSIONS.includes(fileExtension) ||
      IMAGE_EXTENSIONS.includes(fileExtension))
    ) {
      this.notificationsService.showError('Esta sección no admite documentos, sólo imágenes y videos');

      return;
    }

    if (
      inputType === 'documents' &&
      (IMAGE_EXTENSIONS.includes(fileExtension) ||
      VIDEO_EXTENSIONS.includes(fileExtension))
    ) {
      this.notificationsService.showError('Esta sección no admite imágenes o videos, sólo documentos');

      return;
    }

    return true;
  }

  
  /**
   * It handles the drop event.
   * @param evt - The event object.
   * @returns Nothing.
   */
  async handleDrop(evt: any, inputType: 'images' | 'documents' | 'all') {
    const files = evt.target.files;

    if(files.length > environment.maxFilesToUpload) {
      return this.translateService.get(['only-can-upload', 'files-concurrents'])
        .subscribe((labels) => {
          this.notificationsService.showError(labels[0] + environment.maxFilesToUpload + labels[1]);
        });
    }

    for(const file of files) {
      if (!file || !this.isExtensionValidToInputType(file.name, inputType)) {
        return;
      }

      await this.handleFileUploaded(file, inputType);
    }
  }

  /**
   * Uploads a file to S3 and then uploads the file to the database
   * @param fileInput - The file input element.
   * @param imageKey - The name of the image.
   * @param imageName - The name of the file that will be uploaded.
   */
  async uploadAgentProjectDocument(fileInput, imageKey, imageName, path?) {
    this.translateService.get('uploading-files').subscribe((uploadingFilesLabel) => {
      this.notificationsService.showSuccess(uploadingFilesLabel);
    })

    this.agentService
      .uploadProjectDocument(imageKey, imageName, this.projectId, path)
      .pipe(
        switchMap((image: any) =>
          this.s3Service.s3uploadfile(fileInput, image.id,  'google', path, fileInput.name),
        ),
      )
      .subscribe((s3FileResult: any) => {
        this.fileInput.nativeElement.value = '';

        const { result } = s3FileResult;

        if (result) {
          this.notificationsService.showSuccess(
            'No hay suficiente memoria disponible',
          );

          return;
        }

        this.fileInput.nativeElement.value = '';

        this.reloadProjectDocuments.emit(true);
      });
  }

  /**
   * Upload a file to S3 and add it to the project documents
   * @param fileInput - The file input element.
   * @param imageKey - The name of the image.
   * @param imageName - The name of the file that was uploaded.
   */
  async uploadClientProjectDocument(fileInput, imageKey, imageName, path) {
    this.translateService.get('uploading-files').subscribe((uploadingFilesLabel) => {
      this.notificationsService.showSuccess(uploadingFilesLabel);
    })

    this.clientService
      .uploadProjectDocument(imageKey, imageName, this.projectId, path)
      .pipe(
        switchMap((image: any) =>
          this.s3Service.s3uploadfile(fileInput, image.id, 'google', path, fileInput.name, this.clientId),
        ),
      )
      .subscribe((s3FileResult: any) => {
        this.fileInput.nativeElement.value = '';

        const { result } = s3FileResult;

        if (result) {
          this.notificationsService.showSuccess(
            'No hay suficiente memoria disponible',
          );

          return;
        }

        this.reloadProjectDocuments.emit(true);

        setTimeout(() => {
          this.clientService
            .getClientFieldById('memoryUse')
            .subscribe(({ memoryUse }) => {
              this.store.dispatch(
                ClientAddOrUpdateFieldAction({
                  field: 'memoryUse',
                  value: memoryUse,
                }),
              );
            });
        }, 500);
      });
  }

  /**
   * Uploads a file to the server and adds it to the list of images
   * @param file - The file list that will be uploaded.
   * @returns Nothing.
   */
  private async handleFileUploaded(file, folder?) {
    const imagesLength = this.images.length;
    const imageKey = `${this.options.imagesKey}-${this.projectId}-${imagesLength}`;
    const imageName = `${this.options.imagesKey}-${this.projectId}-attached-${imagesLength}`;
    const path = `lead_${this.projectId}/${folder}/`;

    if (this.context === CONTEXTS.AGENT) {
      await this.uploadAgentProjectDocument(file, imageKey, imageName, path);

      return;
    }

    if (this.context === CONTEXTS.LOGIN_CLIENT) {
      await this.uploadClientProjectDocument(file, imageKey, imageName, path);

      this.mixpanelService.eventEmitter('client-uploaded-document', {});
      
      return;
    }
  }

  /**
   * It removes an image from the project.
   * @param {number} index - The index of the image in the images array.
   */
  removeImage(data: { index: number; mediaType: string }) {
    const listToRemoveItem = data.mediaType === 'image' ? this.images : this.videos;

    const imageDocument = listToRemoveItem[data.index];
    const [{ id: versionId, fileName }] = imageDocument.versions;

    const dialog = this.matDialog.open(DecisionModalComponent, {
      width: this.viewMode !== 'web' ? '90%' : '835px',
      height: this.viewMode !== 'web' ? '50%' : '180px',
      data: {
        title: `¿Está seguro de eliminar el fichero "${fileName}"?`,
        cta: 'Eliminar',
        cancel: 'Cancelar',
        showCancelButton: true,
        showCloseButton: true,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      this.s3Service
        .removeFileAndDocument(versionId, imageDocument.id)
        .subscribe(() => {
          data.mediaType === 'image' ? this.images.splice(data.index, 1) : this.videos.splice(data.index, 1);
          this.notificationsService.showSuccess('Fichero eliminado');
          this.reloadProjectDocuments.emit(true);

          if (this.context === CONTEXTS.AGENT) {
            return;
          }

          this.mixpanelService.eventEmitter('client-removed-document', {});

          setTimeout(() => {
            this.clientService
              .getClientFieldById('memoryUse')
              .subscribe(({ memoryUse }) =>
                this.store.dispatch(
                  ClientAddOrUpdateFieldAction({
                    field: 'memoryUse',
                    value: memoryUse,
                  }),
                ),
              );
          }, 500);
        });
    });
  }

  /**
   * It removes a file from the project.
   * @param {number} index - number
   */
  removeFile(indexes: number[]) {
    event.stopPropagation();

    const fileDocument = indexes.map((index) =>  {return {...this.files[index],index}});

    const filteredFileDocument = fileDocument.filter((fileDocument) => {
      return !(this.context === CONTEXTS.LOGIN_CLIENT && fileDocument.ownerType === 'Agent')
    })

    const filesToDelete = filteredFileDocument.flatMap((file) => {
      return {
        id:file.id,
        versionId: file.versions[0]._id,
        fileName: file.versions[0].fileName,
        index:file.index,
      }
    });

    const dialog = this.matDialog.open(DecisionModalComponent, {
      width: this.viewMode !== 'web' ? '90%' : '835px',
      height: this.viewMode !== 'web' ? '50%' : '180px',
      data: {
        title: `¿Está seguro de eliminar el o los ficheros "${filesToDelete.map((file => { return ' '+file.fileName+' ' }))}"?`,
        cta: 'Eliminar',
        cancel: 'Cancelar',
        showCancelButton: true,
        showCloseButton: true,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (!result) {
        return;
        
      }

      filesToDelete.map((fileDocument) => {
        this.s3Service
          .removeFileAndDocument(fileDocument.versionId, fileDocument.id)
          .subscribe(() => {
            this.files.splice(fileDocument.index, 1);
            this.notificationsService.showSuccess('Fichero eliminado');
            this.reloadProjectDocuments.emit(true);
  
            if (this.context === CONTEXTS.AGENT) {
              return;
            }
  
            setTimeout(() => {
              this.clientService
                .getClientFieldById('memoryUse')
                .subscribe(({ memoryUse }) =>
                  this.store.dispatch(
                    ClientAddOrUpdateFieldAction({
                      field: 'memoryUse',
                      value: memoryUse,
                    }),
                  ),
                );
            }, 500);
          });
      })

    });
  }

  /**
   * It uploads a url to the project document.
   * @param urlName - The name of the url.
   * @param url - The URL to be uploaded.
   */
  uploadAgentUrlProjectDocument(urlName, url) {
    this.agentService
      .uploadUrlProjectDocument(urlName, url, this.projectId)
      .subscribe(() => {
        this.notificationsService.showSuccess('Enlace añadido');
        this.reloadProjectUrlDocuments.emit(true);
      });
  }

  /**
   * It uploads a new url to the project.
   * @param urlName - The name of the url.
   * @param url - The URL to be uploaded.
   */
  uploadClientUrlProjectDocument(urlName, url) {
    this.clientService
      .uploadUrlProjectDocument(urlName, url, this.projectId)
      .subscribe(() => {
        this.notificationsService.showSuccess('Enlace añadido');
        this.reloadProjectUrlDocuments.emit(true);
      });
  }

  /**
   * It opens a dialog box with a form that allows the user to enter the name of the url and the url
   * itself.
   */
  addLink() {
    const dialogRef: any = this.matDialog.open(InputModalComponent, {
      width: this.viewMode !== 'web' ? '95%' : '450px',
      height: this.viewMode !== 'web' ? '95%' : '430px',
      maxWidth: '100vw',
      data: {
        title: 'insert-url-info',
        cta: 'add',
        cancel: '',
        showCancelButton: true,
        showCloseButton: true,
      },
    });

    dialogRef.afterClosed().subscribe(
      ({ formValues }) => {
        if (!formValues) {
          return;
        }

        const { urlName, url } = formValues;

        if (this.context === CONTEXTS.AGENT) {
          this.uploadAgentUrlProjectDocument(urlName, url);

          return;
        }

        if (this.context === CONTEXTS.LOGIN_CLIENT) {
          this.uploadClientUrlProjectDocument(urlName, url);

          return;
        }
      }
    );
  }

  /**
   * `goToUrl` is a function that takes a string and opens it in a new tab
   * @param {string} url - The URL to open.
   * @returns Nothing.
   */
  goToUrl(url: string) {
    if (url.includes('https://') || url.includes('http://')) {
      window.open(url, '_blank');
      return;
    }

    window.open(`https://${url}`, '_blank');
  }

  /**
   * It removes a link from the project.
   * @param event - The event object that was triggered.
   * @param linkIndex - The index of the link in the links array.
   */
  removeLink(event, linkIndex) {
    event.stopPropagation();

    const dialog = this.matDialog.open(DecisionModalComponent, {
      width: this.viewMode !== 'web' ? '90%' : '400px',
      height: this.viewMode !== 'web' ? '50%' : '200px',
      data: {
        title: '¿Está seguro de eliminar el enlace?',
        cta: 'Eliminar',
        cancel: 'Cancelar',
        showCancelButton: true,
        showCloseButton: true,
      },
    });

    dialog.afterClosed().subscribe(
      (result) => {
        if (!result) {
          return;
        }

        const { id: linkID } = this.links[linkIndex];

        if (this.context === CONTEXTS.AGENT) {
          this.agentService.removeProjectUrlDocument(linkID).subscribe(() => {
            this.reloadProjectUrlDocuments.emit(true);
            this.notificationsService.showSuccess('Enlace eliminado');
          });

          return;
        }

        this.clientService.removeProjectUrlDocument(linkID).subscribe(() => {
          this.reloadProjectUrlDocuments.emit(true);
          this.notificationsService.showSuccess('Enlace eliminado');
        });
      }
    );
  }

  toggleFileSelection(checked: boolean, fileIndex: any) {
    if (checked) {
      this.selectedFiles.push(fileIndex);
      this.deleteButtonMessage = this.deleteDocumentMode && this.selectedFiles.length === 0 ? 'cancel' : 'deleting-files';
      return 
      
    }
    
    if(!checked) {
      const index = this.selectedFiles.findIndex(selectedFile => selectedFile === fileIndex);
      if (index !== -1) {
        this.selectedFiles.splice(index, 1);
      }
      this.deleteButtonMessage = this.deleteDocumentMode && this.selectedFiles.length === 0 ? 'cancel' : 'deleting-files';
    }

  }

  toggleDeleteDocumentMode = () => {
    if(this.deleteDocumentMode && this.selectedFiles.length > 0) {
      this.removeFile(this.selectedFiles)
    }
    this.deleteDocumentMode = !this.deleteDocumentMode;
    this.selectedFiles = [];
    this.deleteButtonMessage = !this.deleteDocumentMode ? 'delete-file' : 'cancel'
  }
}

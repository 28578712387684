export function loadStripe(environment) {
  const [head] = document.getElementsByTagName('head');
  const stripeScript = document.createElement('script');

  stripeScript.innerHTML = `
    const stripe = Stripe('${environment.stripe.publicSecret}'); // use your test publishable key
    const elements = stripe.elements();
  `;

  head.insertBefore(stripeScript, head.firstChild);
}

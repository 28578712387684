import { Injectable } from '@angular/core';
import {
  Action,
  BudgetInterface,
  ClientInterface,
  LeadInterface,
  Budget,
  ChapterInterface,
  DocumentInterface,
  AgentInterface,
  Chapter,
  BudgetItem,
} from '../sdk';
import { of, Observable, Subject, forkJoin } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { SessionStorageService } from './session-storage.service';
import environment from '../../../environments/environment';
import { ContractDataInterface } from '../../shared/interfaces';
import { LocalStorageService } from './local-storage.service';
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { catchError, map, tap, concatMap, take, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ChapterService } from './chapter.service';
import { PAYMENT_METHOD } from '@core/constants';
import { BudgetUpdateAction } from 'src/app/store/actions/budget.actions';
import { Update } from '@ngrx/entity';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ContextUpdateBudgetAction } from 'src/app/store/context/context.actions';

@Injectable({
  providedIn: 'root',
})
export class BudgetService  {
  budgetsErrors = new Map<string, BudgetsErrors>();
  budgetsErrors$ = new Subject<Map<string, BudgetsErrors>>();
  errorArray = [];
  fieldsArray = [];
  private currentBudgetId: string | null = null;


  constructor(
    private sessionStorage: SessionStorageService,
    private httpClient: HttpClient,
    private chapterService: ChapterService,
    private localStorage: LocalStorageService,
    private store: Store<{
      agent: AgentInterface;
      client: ClientInterface;
      project: LeadInterface[];
      context: any;
    }>,
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    this.setCurrentBudgetId(null);
    const URLS_TO_CLEAN_CONTEXT = ['budget-edition', 'budget'];
    if (URLS_TO_CLEAN_CONTEXT.some((url) => state.url.includes(`/${url}`))) {
      this.setCurrentBudgetId(route.params['budgetID']);
      return true;
    }
    return true;
  }

  setCurrentBudgetId(budgetId: string | null) {
    this.currentBudgetId = budgetId;
    this.updateContextCurrentBudget(budgetId);
  }

  private updateContextCurrentBudget(budgetId: string) {
    this.store.dispatch(
      ContextUpdateBudgetAction({
        currentBudgetId: budgetId ,
      }),
    );
  }

  getBudgetById(
    budgetID: string,
    client?: ClientInterface,
    lead?: LeadInterface,
  ): BudgetInterface {
    if (!lead) {
      lead = this.getLeadByBudgetId(budgetID, client);
    }

    return lead.budgets.find((budget) => budget.id === budgetID);
  }

  getLeadByBudgetId(budgetID: string, client: ClientInterface): LeadInterface {
    return client.leads.find((lead) =>
      lead.budgets.some((budget) => budget.id === budgetID),
    );
  }

  private acceptAnonymousClientBudget(budgetID: string,  userType: string) {
    const action: Action = new Action({
      action: 'accept',
      data: {},
      createdAt: new Date(),
      updatedAt: new Date(),
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'anonymous-client-token': this.sessionStorage.get(userType),
      }),
    };

    return this.httpClient
      .post(
        `${environment.apiBaseUrl}/api/Budgets/${budgetID}/actions`,
        action,
        httpOptions,
      )
      .pipe(
        catchError((err) => {
          console.log('error', err);
          return of(err);
        }),
      );
  }


  private acceptBudgetByUserYype(budgetID: string, userType: string) {
    const action: Action = new Action({
      action: 'accept',
      data: {},
      createdAt: new Date(),
      updatedAt: new Date(),
    });

    const user = this.localStorage.get(userType);

    const httpOptions = userType === 'clientId'  ? {
      headers: new HttpHeaders({
        referrer: `${userType}-${user}`,
      }),
    } : {};

    return this.httpClient
      .post(
        `${environment.apiBaseUrl}/api/Budgets/${budgetID}/actions`,
        action,
        httpOptions,
      )
      .pipe(
        catchError((err) => {
          console.log('error', err);
          return of(err);
        }),
      );
  }

  acceptBudget(budgetID: string) {
    return this.store.select('context').pipe(
      concatMap(({ value: context }) => {
        switch (context) {
          case 'client':
            return this.acceptAnonymousClientBudget(budgetID, 'clientToken');
          case 'login-client':
            return this.acceptBudgetByUserYype(budgetID, 'clientId');
          case 'agent':
            return this.acceptBudgetByUserYype(budgetID, 'agentId');
        }
      }),
    );
  }

  rejectAnonymousClientBudget(budgetID, reason) {
    const action: Action = new Action({
      action: 'reject',
      data: { reason },
      createdAt: new Date(),
      updatedAt: new Date(),
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'anonymous-client-token': this.sessionStorage.get('clientToken'),
      }),
    };

    return this.httpClient
      .post(
        `${environment.apiBaseUrl}/api/Budgets/${budgetID}/actions`,
        action,
        httpOptions,
      )
      .pipe(
        catchError((err) => {
          console.log('error', err);
          return of(err);
        }),
      );
  }

  rejectLoggedUserBudget(budgetID, reason, userType) {
    const action: Action = new Action({
      action: 'reject',
      data: { reason },
      createdAt: new Date(),
      updatedAt: new Date(),
    });

    const user = this.localStorage.get(userType);

    const httpOptions = userType === 'clientId'  ? {
      headers: new HttpHeaders({
        referrer: `${userType}-${user}`,
      }),
    } : {};

    return this.httpClient
      .post(
        `${environment.apiBaseUrl}/api/Budgets/${budgetID}/actions`,
        action,
        httpOptions,
      )
      .pipe(
        catchError((err) => {
          console.log('error', err);
          return of(err);
        }),
      );
  }

  rejectBudget(budgetID: string, reason) {
    return this.store.select('context').pipe(
      take(1),
      concatMap(({ value: context }) => {
        switch (context) {
          case 'client': // TO REMOVE: Deprecated user type
            return this.rejectAnonymousClientBudget(budgetID, reason);
          case 'login-client':
            return this.rejectLoggedUserBudget(budgetID, reason, 'clientId');
          case 'agent':
            return this.rejectLoggedUserBudget(budgetID, reason, 'agentId');
        }
      }),
    );
  }

  private updateLoginClientContractData(
    body: any,
    budgetID: string,
    contractData: ContractDataInterface,
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        referrer: `client-${this.localStorage.get('clientId')}`,
      }),
    };

    return this.httpClient
      .post(
        `${environment.apiBaseUrl}/api/Budgets/${budgetID}/updateContractData`,
        body,
        httpOptions,
      )
      .pipe(
        catchError((err) => {
          console.log('error', err);
          return of(err);
        }),
      );
  }

  private updateAnonymousClientContractData(
    body: any,
    budgetID: string,
    contractData: ContractDataInterface,
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'anonymous-client-token': this.sessionStorage.get('clientToken'),
      }),
    };

    return this.httpClient
      .post(
        `${environment.apiBaseUrl}/api/Budgets/${budgetID}/updateContractData`,
        body,
        httpOptions,
      )
      .pipe(
        catchError((err) => {
          console.log('error', err);
          return of(err);
        }),
      );
  }

  updateContractData(budgetID: string, contractData: ContractDataInterface) {
    const body = {
      newClientContact: contractData.contact,
      newLeadAddress: contractData.address,
      paymentMethod: contractData.paymentMethod,
    };

    return this.store.select('context').pipe(
      concatMap(({ value: context }) => {
        if (context === 'client') {
          return this.updateAnonymousClientContractData(
            body,
            budgetID,
            contractData,
          );
        }

        return this.updateLoginClientContractData(body, budgetID, contractData);
      }),
    );
  }

  private getDefaultChapterName(subType:string, count:number) {
     return subType === 'annex' ? 'Anexo ' + (++count) : 'newChapterPristine';;
  }

  private getDataDefaultDraft(lead: LeadInterface, subType:string, count:number){
    const { personProperties } = this.localStorage.get('user');
    const { contact, address } = lead;
    
    if (subType === 'annex'){
      return {terms: '', title: 'Anexo ' + (++count)}
    }
    return {terms: personProperties?.termsAndConditions ?? '', title: `${contact.name} - ${address.address1} ${address.city}`}
  }

  createDraftFromSratch(lead: LeadInterface, subType?:string,  numberBudgetsSubType? :number) {
    const draft = {
      leadName :lead.leadName,
      state : 'created',
      subType: subType,
      ...this.getDataDefaultDraft(lead, subType,  numberBudgetsSubType),
      paymentMethod: PAYMENT_METHOD,
    }
    return this.httpClient.post<BudgetInterface>(
      `${environment.apiBaseUrl}/${environment.apiVersion}/Budgets/agents/${lead.agentId}/lead/${lead.id}/create-draft`,
      draft,
    );
  }

  createDraft(project:LeadInterface, subType?:string, numberBudgetsSubType?:number){
      return this
      .createDraftFromSratch(project, subType, numberBudgetsSubType)
  }

  async createPristineItems(budgetId:string, subType?:string, numberBudgetsSubType?:number){
    let chapterId:string;
    await this.createChapter(budgetId, {
      title: this.getDefaultChapterName(subType, numberBudgetsSubType),
      order: 0,
    }).pipe(
      tap((chapter) => (chapterId = chapter.id)),
      switchMap(() =>
        this.chapterService.createItem(chapterId, {
          quantity: 1,
          product: {
            title: 'newBudgetItemPristine',
            units: 'u',
            unitPrice: 0,
          },
          order: 0,
        }),
      ),
    ).toPromise()
  }


  createBudgetFromDraft(
    draft: BudgetInterface,
    sendToClient: boolean = false,
    isOwn = false,
    emailToSend = null,
    commentsToSend = null,
  ) {
    // !! TO REMOVE
    draft.sendToClient = sendToClient;
    draft.isOwnProject = isOwn;
    draft.emailToSend = emailToSend;
    draft.commentsToSend = commentsToSend;

    const url = `${environment.apiBaseUrl}/${environment.apiVersion}/Budgets/agents/${draft.agentId}/leads/${draft.leadId}/create-budget-draft/${draft.id}`;

    return this.httpClient.post<BudgetInterface>(url, {
      sendToClient,
      isOwnProject: isOwn,
      emailToSend,
      commentsToSend,
    });
  }

  createDraftFromBudget(agentID, projectID, budgetID) {
    return this.httpClient.post<BudgetInterface>(
      `${environment.apiBaseUrl}/${environment.apiVersion}/Budgets/agent/${agentID}/leads/${projectID}/create-draft-budget`,
      { budgetID },
    );
  }

  getBudget(budgetId: string, include?: object) {
    const filterHeader = {
      include,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    return this.httpClient.get<Budget>(
      `${environment.apiBaseUrl}/${environment.apiVersion}/Budgets/${budgetId}`,
      httpOptions,
    );
  }
  countBudgetsByLeadId = (leadId: number) => this.httpClient.get<{ count: number }>(
    `${environment.apiBaseUrl}/api/budgets/lead/${leadId}/count`
  );

  getBudgetsByFilter(filter: object) {
    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
      }),
    };

    return this.httpClient.get<Budget>(`${environment.apiBaseUrl}/${environment.apiVersion}/budgets`, httpOptions
  );
  } 
 
  getLoginClientBudget(budgetId: string, include?: object) {
    const filterHeader = {
      include,
    };
    const clientID = this.localStorage.get('clientId');

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
        referrer: `client-${clientID}`,
      }),
    };

    return this.httpClient.get<BudgetInterface>(
      `${environment.apiBaseUrl}/api/Budgets/${budgetId}`,
      httpOptions,
    );
  }

  getBudgetChapters(
    budgetId: string,
    include?: object,
    skyp?: number,
    limit?: number,
  ) {
    const filterHeader = {
      include,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    return this.httpClient.get<Chapter[]>(
      `${environment.apiBaseUrl}/${environment.apiVersion}/Budgets/${budgetId}/chapters`,
      httpOptions,
    );
  }

  /**
   * @method getBudgetChaptersWithConf
   * @returns Chapter
   *
   * @param  {string} budgetId The id of the budget linked to the chapter we want to retrive
   * @param  {object} include Related elements to inlcude
   * @param  {number} skyp Number of element to avoid before pulling
   * @param  {number} limit Maximum number of element to pull
   *
   */

  getBudgetChaptersWithConf({
    budgetId,
    include,
    skip,
    limit,
  }: {
    budgetId: string;
    include?: object;
    skip?: number;
    limit?: number;
  }) {
    return this.store.select('context').pipe(
      take(1),
      switchMap(({ value: context }) => {
        const filterHeader = {
          include,
          skip,
          limit,
        };

        const headers = {
          filter: JSON.stringify(filterHeader),
        };

        // TODO: Move this to an interceptor
        if (context === 'login-client') {
          headers['referrer'] = `client-${this.localStorage.get('clientId')}`;
        }

        const httpOptions = {
          headers: new HttpHeaders(headers),
        };

        return this.httpClient.get<Chapter[]>(
          `${environment.apiBaseUrl}/${environment.apiVersion}/Budgets/${budgetId}/chapters`,
          httpOptions,
        );
      }),
    );
  }

  /**
   * @method getBudgetBudgetItemsWithConf
   * @returns BudgetItems
   *
   * @param  {string} budgetId The id of the budget linked to the chapter we want to retrive
   * @param  {object} include Related elements to inlcude
   * @param  {number} skyp Number of element to avoid before pulling
   * @param  {number} limit Maximum number of element to pull
   *
   */

  getBudgetBudgetItemsWithConf({
    budgetId,
    include,
    skip,
    limit,
    fields,
  }: {
    budgetId: string;
    include?: object;
    skip?: number;
    limit?: number;
    fields?: object;
  }) {
    const filterHeader = {
      include,
      skip,
      limit,
      fields,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    return this.httpClient.get<BudgetItem[]>(
      `${environment.apiBaseUrl}/api/Budgets/${budgetId}/budget-items`,
      httpOptions,
    );
  }

  getBudgetChaptersCount(budgetId: string) {
    return this.httpClient.get<{ count: number }>(
      `${environment.apiBaseUrl}/api/Budgets/${budgetId}/chapters/count`,
    );
  }

  getBudgetBudgetItemCount(budgetId: string) {
    return this.httpClient.get<{ count: number }>(
      `${environment.apiBaseUrl}/api/Budgets/${budgetId}/budget-items-count`,
    );
  }

  createChapter(budgetId: string, chapter: ChapterInterface, include?: object) {
    const filterHeader = {
      include,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    return this.httpClient.post<ChapterInterface>(
      `${environment.apiBaseUrl}/${environment.apiVersion}/Budgets/${budgetId}/chapters`,
      chapter,
      httpOptions,
    );
  }

  updateBudget(budgetId: string, budget: BudgetInterface) {
    const budgetUpdate: Update<Budget> = {
      id: budgetId,
      changes: { ...budget },
    };

    this.store.dispatch(BudgetUpdateAction({update: budgetUpdate}))

    return this.httpClient.patch<BudgetInterface>(
      `${environment.apiBaseUrl}/${environment.apiVersion}/Budgets/${budgetId}`,
      budget,
    );
  }

  createBudgetDocument(
    budgetId: string,
    documentKey: string,
    documentName: string,
  ) {
    return this.httpClient.post<DocumentInterface>(
      `${environment.apiBaseUrl}/api/Budgets/${budgetId}/documents`,
      {
        key: documentKey,
        name: documentName,
        description: '',
        valueDate: null,
        dueDate: null,
        showDates: false,
      },
    );
  }

  duplicateBudgetInProject(budgetId, projectID) {
    const agentID = this.localStorage.get('agentId');
    const url = `${environment.apiBaseUrl}/api/Agents/${agentID}/duplicate/${budgetId}/${projectID}`;

    return this.httpClient.post<any>(url, {});
  }

  updateOrders(budgetId, newOrders) {
    const url = `${environment.apiBaseUrl}/api/Budgets/${budgetId}/updateOrders`;

    return this.httpClient.put(url, newOrders);
  }

  deleteBudget(budgetID) {
    const agentID = this.localStorage.get('agentId');
    const url = `${environment.apiBaseUrl}/api/Agents/${agentID}/budgets/${budgetID}`;

    return this.httpClient.delete(url);
  }
  /**
   * Function that chnages the model active to false
   * @param budgetID ID of the budget to delete
   * @returns 
   */
  deleteBudgetEstimation(budgetID : Budget) {
        const url = `${environment.apiBaseUrl}/api/Budgets/${budgetID}`;
        const body = {
            modelActive:{
              "active": false,
              "description":""
            }
        }
        try{
          return this.httpClient.patch(url,body);
        }catch(error){
          return error;
        }
  }

  createDraftFromBC3(bc3Data: any, projectID: string): Observable<any> {
    const {
      file,
      indirectPercent,
      industrialBenefit,
      overheads,
      copyBC3BudgetsItems,
      taxPercent
    } = bc3Data;
    const agentID = this.localStorage.get('agentId');
    const formData = new FormData();

    formData.append('bc3', file, file.name);
    formData.append('indirectPercent', indirectPercent);
    formData.append('industrialPercent', industrialBenefit + overheads);
    formData.append('copyBC3BudgetsItems', copyBC3BudgetsItems);
    formData.append('taxPercent', taxPercent);

    return this.httpClient.post(
      `${environment.apiBaseUrl}/api/Agents/${agentID}/createDraftFromBC3/${projectID}`,
      formData,
    );
  }

  saveProductsAndChapters(budgetID: string): Observable<any> {
    const agentID = this.localStorage.get('agentId');
    const formData = new FormData();

    return this.httpClient.post(
      `${environment.apiBaseUrl}/api/Agents/${agentID}/saveProductsAndChapters/${budgetID}`,
      formData,
    );
  }

  clearErrors() {
    this.budgetsErrors.clear();
    this.budgetsErrors$.next(this.budgetsErrors);
  }

  addErrors(
    formGroup: UntypedFormGroup | UntypedFormArray | UntypedFormControl,
    budgetItemIdentification: string,
    type: string,
  ) {
    this.errorArray = [];
    this.fieldsArray = [];

    if (!formGroup.value.id) {
      return;
    }

    if (formGroup instanceof UntypedFormGroup || formGroup instanceof UntypedFormArray) {
      this.getNestedErrors(formGroup);
    }

    this.fieldsArray.filter(
      (item, index) => this.fieldsArray.indexOf(item) === index,
    );

    if(type === 'chapter-items') {
      this.errorArray.push({
        errors: { noItems: true },
        field: 'chapter-items',
      })

      this.fieldsArray.push('chapter-items');
    }

    const errorFieldValue = {
      budgetItemIdentification,
      fields: this.fieldsArray,
      errors: this.errorArray,
      type,
    };

    this.budgetsErrors.set(formGroup.value.id, errorFieldValue);
    this.budgetsErrors$.next(this.budgetsErrors);
    this.errorArray = [];
    this.fieldsArray = [];

    return errorFieldValue;
  }

  removeError(key: string) {
    this.budgetsErrors.delete(key);
    this.budgetsErrors$.next(this.budgetsErrors);
  }

  getErrors(): Observable<BudgetsErrors[]> {
    return this.budgetsErrors$
      .asObservable()
      .pipe(map((errors) => Array.from(errors.values())));
  }

  private getNestedErrors(formGroup: UntypedFormGroup | UntypedFormArray) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (
        (control instanceof UntypedFormControl && control.errors != null) ||
        control.value === 'newBudgetItemPristine' ||
        control.value === ''
      ) {
        this.errorArray.push({
          field,
          errors: control.errors ? control.errors : '',
        });

        this.fieldsArray.push(field);
      }

      if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
        this.getNestedErrors(control);
      }
    });
  }

  getBudgetsByLeadId(leadID: string, filter?: object, limit = null) {
    const filterHeader = {
      where: filter,
      include: [
        {
          relation: 'agent',
          scope: {
            fields: ['tradeName', 'documents', 'id'],
          },
        },
      ],
      order: 'updatedAt DESC',
      limit,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    return this.httpClient
      .get<any>(
        `${environment.apiBaseUrl}/api/Leads/${leadID}/budgets/count`,
        httpOptions,
      )
      .pipe(
        concatMap(({ count }) => {
          if (count === 0) {
            return of([]);
          }

          let url = `${environment.apiBaseUrl}/${environment.apiVersion}/Budgets/lead/${leadID}`;

          let concurrency = 5;

          const budgetsPerCall = Math.ceil(count / concurrency) || 1;
          const requestsConfig = [];

          if (count <= concurrency && count > 0) {
            const newFilter = {
              ...filterHeader,
              skip: 0,
              limit: count,
            };

            const httpOptions = {
              headers: new HttpHeaders({
                filter: JSON.stringify(newFilter),
              }),
            };

            return this.httpClient.get<any>(`${url}`, httpOptions);
          }

          for (let index = 0; index < concurrency; index++) {
            const newFilter = {
              ...filterHeader,
              skip: budgetsPerCall * index,
              limit: budgetsPerCall,
            };

            const httpOptions = {
              headers: new HttpHeaders({
                filter: JSON.stringify(newFilter),
              }),
            };

            requestsConfig.push(httpOptions);
          }

          return forkJoin(
            requestsConfig.map((urlHttpOptions) =>
              this.httpClient.get<any>(`${url}`, urlHttpOptions),
            ),
          );
        }),
        map((budgets: any) =>
        budgets
          .flat()
          .sort((a, b) =>
            a.updatedAt > b.updatedAt ? -1 : b.updatedAt > a.updatedAt ? 1 : 0,
          ),
        )
      )
  }

}

export interface BudgetsErrors {
  budgetItemIdentification: string;
  fields: string[];
  errors: { field: string; error: any }[];
  type: string;
}

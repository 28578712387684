import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mydecimals'
})
export class MyDecimalsPipe implements PipeTransform {

  transform(value: number, decimals: number = 2): string {

    const decimalValue = value.toFixed(decimals + 1);
    return decimalValue.slice(0, decimalValue.length-1).replace(".", ",");
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Tracking } from '@core/sdk/models/Tracking';
import { BudgetItem, TrackingSummary } from '@core/sdk';
import { Observable } from 'rxjs';
import environment from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(private httpClient: HttpClient) {}

  updateTracking({
    tracking,
    where,
  }: {
    tracking: Partial<Tracking>;
    where?: Record<string, unknown>;
  }): Observable<Tracking> {
    const filterHeader = {
      where,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    return this.httpClient.patch<Tracking>(
      `${environment.apiBaseUrl}/api/Trackings/${tracking.id}`,
      tracking,
      httpOptions,
    );
  }

  cancelTracking(trackingId: string): Observable<BudgetItem> {
    return this.httpClient.patch<BudgetItem>(
      `${environment.apiBaseUrl}/api/Trackings/${trackingId}`,
      {
        canceled: true,
        accumulatedPercentage: 0,
        advance: 0,
      },
    );
  }

  updateTrackings({
    tracking,
    where,
  }: {
    tracking: Partial<Tracking>;
    where?: Record<string, unknown>;
  }): Observable<Tracking[]> {
    return this.httpClient.put<Tracking[]>(
      `${environment.apiBaseUrl}/api/Trackings/update?where=${JSON.stringify(
        where,
      )}`,
      tracking,
    );
  }

  getTrackings({
    where,
    include,
    skip,
    limit,
  }: {
    where?: Record<string, unknown>;
    include?: Record<string, unknown> | string | string[];
    skip?: number;
    limit?: number;
  }): Observable<Tracking[]> {
    const filterHeader = {
      where,
      include,
      skip,
      limit,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    return this.httpClient.get<Tracking[]>(
      `${environment.apiBaseUrl}/api/Trackings`,
      httpOptions,
    );
  }

  getTrackingBudgetItemChapterView({
    where,
    skip,
    limit,
  }: {
    where?: Record<string, unknown>;
    skip?: number;
    limit?: number;
  }): Observable<Tracking[]> {
    const filterHeader = {
      where,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    return this.httpClient.get<Tracking[]>(
      `${environment.apiBaseUrl}/api/Trackings/views/budgetitem`,
      httpOptions,
    );
  }

  getTrackingsCount({
    where,
    include,
    skip,
    limit,
  }: {
    where?: Record<string, unknown>;
    include?: Record<string, unknown> | string | string[];
    skip?: number;
    limit?: number;
  }): Observable<{ count: number }> {
    const filterHeader = {
      where,
      include,
      skip,
      limit,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    return this.httpClient.get<{ count: number }>(
      `${environment.apiBaseUrl}/api/Trackings/get-trackings-count`,
      httpOptions,
    );
  }

  getTracking(
    trackingId: string,
    include?: Record<string, unknown> | string | string[],
  ): Observable<Tracking> {
    const filterHeader = {
      include,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    return this.httpClient.get<Tracking>(
      `${environment.apiBaseUrl}/api/Trackings/${trackingId}`,
      httpOptions,
    );
  }

  getTrackingDocuments(projectID: string): Observable<unknown> {
    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}/tracking-documents-agent`;

    return this.httpClient.get(url);
  }

  getTrackingSummary(projectID: string): Observable<TrackingSummary> {
    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}/tracking-resume`;

    return this.httpClient.get<TrackingSummary>(url);
  }
}

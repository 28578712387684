/* eslint-disable */
import { Company } from './Company';
import {
  Budget,
  Lead,
  Action,
  Product,
  Event,
  Activity,
  Person,
  Position,
  Document,
} from './index';
import { PersonProperty } from './PersonProperty';

declare var Object: any;
export interface AgentInterface {
  active?: boolean;
  bussinesName?: string;
  tradeName?: string;
  vatId?: string;
  image?: string;
  teamSize?: number;
  sinceYear?: number;
  paths?: Array<any>;
  productTypes?: any;
  projectTypes?: any;
  profiles?: Array<any>;
  minBudget?: number;
  maxBudget?: number;
  finance?: boolean;
  contact?: any;
  company?: Company;
  address?: any;
  rrss?: Array<any>;
  offices?: Array<any>;
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  modelActive?: any;
  budgets?: Budget[];
  leads?: Lead[];
  relatedActions?: Action[];
  products?: Product[];
  actions?: Action[];
  events?: Event[];
  activities?: Activity[];
  people?: Person[];
  team?: Position[];
  documents?: Document[];
  cubicupPartnerCode?: string;
  cubicupPartnerId?: number;
  emailWhitelist?: string[];
  certificationModified?: boolean;
  stripe_user_id?: string;
  planSelected?: string;
  score?: number;
  web?: string;
  reviews?: any;
  portfolio?: any;
  description?: any;
  dashboard?: any;
  landingIsVisible?: boolean;
  impersonated?: boolean;
  professionalType?: string;
  fromOldAgent?: string;
  personProperties?: PersonProperty;
  companyId: string;
}

export class Agent implements AgentInterface {
  active?: boolean;
  bussinesName?: string;
  tradeName?: string;
  vatId?: string;
  image?: string;
  teamSize?: number;
  sinceYear?: number;
  paths?: Array<any>;
  productTypes?: any;
  projectTypes?: any;
  profiles?: Array<any>;
  minBudget?: number;
  maxBudget?: number;
  finance?: boolean;
  contact?: any;
  address?: any;
  rrss?: Array<any>;
  offices?: Array<any>;
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  modelActive?: any;
  budgets?: Budget[];
  leads?: Lead[];
  relatedActions?: Action[];
  products?: Product[];
  actions?: Action[];
  events?: Event[];
  activities?: Activity[];
  people?: Person[];
  team?: Position[];
  documents?: Document[];
  cubicupPartnerCode?: string;
  cubicupPartnerId?: number;
  emailWhitelist?: string[];
  certificationModified?: boolean;
  stripe_user_id?: string;
  planSelected?: string;
  score?: number;
  web?: string;
  reviews?: any;
  portfolio?: any;
  description?: any;
  dashboard?: any;
  landingIsVisible?: boolean;
  impersonated?: boolean;
  professionalType?: string;
  fromOldAgent?: string;
  personProperties?: PersonProperty;
  companyId: string;

  constructor(data?: AgentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Agent`.
   */
  public static getModelName() {
    return 'Agent';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Agent for dynamic purposes.
   **/
  public static factory(data: AgentInterface): Agent {
    return new Agent(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Agent',
      plural: 'Agents',
      path: 'Agents',
      idName: 'id',
      properties: {
        active: {
          name: 'active',
          type: 'boolean',
          default: true,
        },
        bussinesName: {
          name: 'bussinesName',
          type: 'string',
        },
        tradeName: {
          name: 'tradeName',
          type: 'string',
        },
        vatId: {
          name: 'vatId',
          type: 'string',
        },
        image: {
          name: 'image',
          type: 'string',
        },
        teamSize: {
          name: 'teamSize',
          type: 'number',
        },
        sinceYear: {
          name: 'sinceYear',
          type: 'number',
        },
        paths: {
          name: 'paths',
          type: 'Array&lt;any&gt;',
        },
        productTypes: {
          name: 'productTypes',
          type: 'any',
        },
        projectTypes: {
          name: 'projectTypes',
          type: 'any',
        },
        profiles: {
          name: 'profiles',
          type: 'Array&lt;any&gt;',
        },
        minBudget: {
          name: 'minBudget',
          type: 'number',
        },
        maxBudget: {
          name: 'maxBudget',
          type: 'number',
        },
        finance: {
          name: 'finance',
          type: 'boolean',
        },
        contact: {
          name: 'contact',
          type: 'any',
        },
        address: {
          name: 'address',
          type: 'any',
        },
        rrss: {
          name: 'rrss',
          type: 'Array&lt;any&gt;',
        },
        offices: {
          name: 'offices',
          type: 'Array&lt;any&gt;',
        },
        id: {
          name: 'id',
          type: 'number',
        },
        createdAt: {
          name: 'createdAt',
          type: 'Date',
        },
        updatedAt: {
          name: 'updatedAt',
          type: 'Date',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
        certificationModified: {
          name: 'certificationModified',
          type: 'boolean',
        },
        personProperties: {
          name: 'personProperties',
          type: 'PersonProperty',
        },
      },
      relations: {
        budgets: {
          name: 'budgets',
          type: 'Budget[]',
          model: 'Budget',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'agentId',
        },
        leads: {
          name: 'leads',
          type: 'Lead[]',
          model: 'Lead',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'agentId',
        },
        relatedActions: {
          name: 'relatedActions',
          type: 'Action[]',
          model: 'Action',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'agentId',
        },
        products: {
          name: 'products',
          type: 'Product[]',
          model: 'Product',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'agentId',
        },
        actions: {
          name: 'actions',
          type: 'Action[]',
          model: 'Action',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'referenceId',
        },
        events: {
          name: 'events',
          type: 'Event[]',
          model: 'Event',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'agentId',
        },
        activities: {
          name: 'activities',
          type: 'Activity[]',
          model: 'Activity',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'agentId',
        },
        people: {
          name: 'people',
          type: 'Person[]',
          model: 'Person',
          relationType: 'hasMany',
          modelThrough: 'Position',
          keyThrough: 'personId',
          keyFrom: 'id',
          keyTo: 'agentId',
        },
        team: {
          name: 'team',
          type: 'Position[]',
          model: 'Position',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'agentId',
        },
        documents: {
          name: 'documents',
          type: 'Document[]',
          model: 'Document',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'ownerId',
        },
        personProperties: {
          name: 'personProperties',
          type: 'PersonProperty',
          model: 'PersonProperty',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'personId',
        },
      },
    };
  }
}

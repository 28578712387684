/* eslint-disable */
import { Chapter } from './index';
import { Measurement } from './Measurement';

declare var Object: any;
export interface BudgetItemInterface {
  quantity?: number;
  product?: any;
  taxPercent?: number;
  id?: string;
  modelActive?: any;
  chapterId?: string;
  chapter?: Chapter | string;
  createdAt?: string;
  order?: number;
  updatedAt?: Date;
  measurements?: Measurement[];
  costPrice?: number;
  benefitPercentage?: number;
  agentBenefit?: number;
}

export interface BudgetItemChapter{
  budgetItemId: string;
  chapterId: string;
}

export class BudgetItem implements BudgetItemInterface {
  'quantity': number;
  'product': any;
  'taxPercent': number;
  'id': string;
  'modelActive': any;
  'chapterId': string;
  chapter?: Chapter | string;
  createdAt?: string;
  order?: number;
  updatedAt?: Date;
  measurements?: Measurement[];
  costPrice?: number;
  benefitPercentage?: number;
  agentBenefit?: number;

  constructor(data?: BudgetItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `BudgetItem`.
   */
  public static getModelName() {
    return 'BudgetItem';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of BudgetItem for dynamic purposes.
   **/
  public static factory(data: BudgetItemInterface): BudgetItem {
    return new BudgetItem(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'BudgetItem',
      plural: 'BudgetItems',
      path: 'BudgetItems',
      idName: 'id',
      properties: {
        quantity: {
          name: 'quantity',
          type: 'number',
        },
        product: {
          name: 'product',
          type: 'any',
        },
        taxPercent: {
          name: 'taxPercent',
          type: 'number',
          default: 2100,
        },
        id: {
          name: 'id',
          type: 'number',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
        chapterId: {
          name: 'chapterId',
          type: 'number',
        },
      },
      relations: {
        chapter: {
          name: 'chapter',
          type: 'Chapter',
          model: 'Chapter',
          relationType: 'belongsTo',
          keyFrom: 'chapterId',
          keyTo: 'id',
        },
      },
    };
  }
}

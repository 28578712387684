import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'obfuscation'
})
export class ObfuscationPipe implements PipeTransform {
  transform(value: any, disabled: boolean): any {
    if (!value) {
      return;
    }

    if (disabled) {
      return value;
    }

    return value.replace(/./gi, 'X');
  }
}
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateTime } from 'luxon';
/**
 * A moment timezone pipe to support parsing based on time zone abbreviations
 * covers all cases of offset variation due to daylight saving.
 *
 * Same API as DatePipe with additional timezone abbreviation support
 * Official date pipe dropped support for abbreviations names from Angular V5
 */
@Pipe({
  name: 'momentDate',
})
export class MomentDatePipe extends DatePipe implements PipeTransform {
  transform(value: string | Date, format: string, timezone: string): string;
  transform(
    value: null,
    format?: string,
    timezone?: string,
    locale?: string,
  ): null;
  transform(
    value: string | number | Date,
    format?: string,
    timezone?: string,
    locale?: string,
  ): string {
    if (value === null) {
      return '';
    }

    if (!format) {
      format = 'mediumDate';
    }

    if (!timezone) {
      timezone = 'Europe/Madrid';
    }

    const timezoneOffset = DateTime.fromISO(value as string)
      .setZone(timezone)
      .toFormat('Z');

    return super.transform(value, format, timezoneOffset);
  }
}

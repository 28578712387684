
import { CONTEXTS, ROLES } from './../../core/constants';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AgentService } from '@core/services/agent.service';
import { AuthService } from '../../core/services/auth.service';
import { Routes } from './constants';
import { HasPermissionService } from '@core/services/has-permission.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    private router: Router,
    private agentService: AgentService,
    private authService: AuthService,
    private hasPermissionService: HasPermissionService,
  ) {}

  get origin(): string {
    return this.router.url;
  }

  get userIsAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  async canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    const userIsAuthenticated = this.authService.isLogged();
    const userRole = await this.authService.getUserContext();
    const fromURL = this.router.url;
    const toURL = state.url;

    console.log('User is authenticated', userIsAuthenticated);

    if (!userIsAuthenticated) {
      this.router.navigate(['/login'], {
        queryParams: {
          redirectUrl: state.url,
        },
      });

      return false;
    }

    // First logged time for an anonymous client
    if (fromURL.includes('new-password') && !userRole) {
      const clientId = JSON.parse(localStorage.getItem('user'))?.clientId

      if (!clientId) {
        return;
      }

      this.authService.continueAnonymousClientLogin(clientId);

      return;
    }

    if (!fromURL.includes('logged-client') && toURL.includes('logged-client')) {
      if (userRole === CONTEXTS.AGENT) {
        this.router.navigate(['/events']);

        return;
      }
    }

    if (state.url.includes('login') || state.url.includes('register')) {
      if (userRole === CONTEXTS.LOGIN_CLIENT) {
        this.router.navigate(['logged-client', 'projects']);

        return;
      }

      if (userRole === CONTEXTS.AGENT && this.hasPermissionService.checkRole(ROLES.SALES)) {
        this.router.navigate(['estimator', 'registerClient']);

        return;
      }

      this.router.navigate(['/events']);

      return;
    }

    if (toURL.includes(Routes.ONBOARDING)) return this.onboarding();

    const agentValid = this.agentService.isAgentValid();

    if (!agentValid) {
      this.authService.logout();

      return;
    }

    //if (this.roleService.checkRole(ROLES.SALES)) { this.companyService.checkRouteAcess(agentRole, toURL); }

    if (fromURL.includes('/project/') && toURL.includes('/events/')) {
      if (userRole === CONTEXTS.AGENT) {
        this.router.navigate(['/events']);

        return;
      }
    }

    return true;
  }

  onboarding = (): Promise<boolean> | boolean => this.userIsAuthenticated ? this.router.navigate([Routes.HOME]): true;
}

import { UserInterface } from './../../../../../core/sdk/models/User';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { LeadInterface, AgentInterface } from '@core/sdk';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CompanyService } from '@core/services/company.service';
import { LeadService } from '@core/services/lead.service';

@Component({
  selector: 'app-project-selector',
  templateUrl: './project-selector.component.html',
  styleUrls: ['./project-selector.component.scss'],
})
export class ProjectSelectorComponent implements OnInit {
  @Output()
  projectClicked: EventEmitter<LeadInterface> = new EventEmitter<LeadInterface>();

  duplicateOptionForm: UntypedFormGroup;
  selected: number;
  projects = [];
  _filteredProjects = [];
  filteredProjects$ = new BehaviorSubject<LeadInterface[]>([]);
  projectSelected = '';
  agentAvatar: string;
  agents: any[] = [];
  selectedAgent: AgentInterface;

  constructor(
    private fb: UntypedFormBuilder,
    private leadService: LeadService,
    private companyService: CompanyService,
    private store: Store<{ agent: AgentInterface; user: UserInterface }>,
  ) {}

  ngOnInit() {
    this.initializeForm();

    this.store.select('agent')
      .pipe(
        take(1),
        tap((agent) => this.selectedAgent = agent),
        map((agent) => (agent.companyId)),
        switchMap((companyId) => {
          return this.companyService.getCompanyAgents(companyId)
        })
      ).subscribe((agents) => {
        this.agents = agents;
      })

    this.getAgentLeads();
  }

  private initializeForm() {
    this.duplicateOptionForm = this.fb.group({
      searchText: new UntypedFormControl('', []),
    });

    this.duplicateOptionForm
      .get('searchText')
      .valueChanges.subscribe(() => this.handleSearch());
  }

  private getAgentLeads(query?: string) {
    const whereClause: any = {
      and: [
        { state: { neq: 'pendingOfVisit' } },
        { state: { neq: 'canceled' } },
        { state: { neq: 'canceledVisit' } },
        { 'modelActive.active': true },
        { archived: false },
        { agentId: this.selectedAgent.id}
      ],
    };

    if (query) {
      whereClause.and.push({ 'contact.name': { like: query , options: 'i' } });
    }

    this.leadService
      .getProjects({
        where: whereClause,
        limit: 20,
        order: 'createdAt DESC',
      })
      .subscribe((projects: any) => {
        const filteredProjects = projects
          .flat()
          .filter((project) => project.state !== 'pending');

        this._filteredProjects = filteredProjects;
        this.filteredProjects$.next(filteredProjects);
      });
  }

  handleSearch() {
    const searchResult = this._filteredProjects;
    const searchTextValue = this.duplicateOptionForm.get('searchText').value;

    if(searchTextValue.length >= 3){
      this.getAgentLeads(searchTextValue);

      this.filteredProjects$.next(searchResult);
      return;
    }

    this.getAgentLeads();
    this.filteredProjects$.next(searchResult);
  }

  clearSearch() {
    this.duplicateOptionForm.get('searchText').setValue('');
  }

  selectProject(project, projectIndex) {
    this.selected = projectIndex;
    this.projectSelected = `${project.contact.name} | ${project.address.address1}`;
    this.projectClicked.emit(project);
  }

  selectAgent(agent) {
    this.selectedAgent = agent;

    this.getAgentLeads();
  }
}

<ng-container *ngIf="timelineList?.length > 0">
  <ng-container *ngFor="
    let timeline of timelineList;
    let timelineIndex = index
  "
  >
    <div
      class="timeline"
      appEnterTheViewportNotifier
      (visibilityChange)="visibilityChangeHandler(timelineIndex)"
    >
      <div class="header">
        <div class="user">
          <span>{{timeline.user.username}}</span>
          <mat-icon
            *ngIf="
              timeline.user.withMagicLink &&
              context === 'agent' &&
              isSuperAdmin
            "
            [matTooltip]="'sales-team-action' | translate"
            matTooltipPosition="above"
          >
            support_agent
          </mat-icon>
        </div>

        <div class="date">
          {{ timeline.createdAt | date: 'shortDate':'UTC' }}
          {{ timeline.createdAt | momentDate: 'shortTime':'Europe/Madrid' }}
        </div>
        
      </div>
      <div class="body">
        <ng-container [ngSwitch]="timeline.action.name">
          <div class="content" *ngSwitchCase="'newMessage'">
            Se ha realizado un nuevo <span class="link" (click)="goToModel(timeline.action)">comentario</span>

            <span *ngIf="timeline.action?.model?.modelInstance.targetType === 'Tracking'">
               en el seguimiento
            </span>
          </div>
          <div class="content" *ngSwitchCase="'newBudgetItemMessage'">
            Se ha realizado un nuevo <span class="link" (click)="goToModel(timeline.action)">comentario</span>
            en la partida <b>{{timeline.action?.model?.metadata?.budgetItem.title}}</b> del presupuesto 
            <b>{{ timeline.action?.model?.metadata?.budget.code }}</b>
          </div>
          <div class="content img" *ngSwitchCase="'uploadNewFile'">
            <div>
              Se ha subido un nuevo <span class="link" (click)="goToModel(timeline.action)">Archivo</span>
            </div>

            <img
              *ngIf="timeline.action?.model?.modelInstance.mime.includes('image')"
              [src]="timeline.action?.model?.modelInstance.fileUrl"
              [matTooltip]="'Descargar ' + timeline.action?.model?.modelInstance.fileName"
              matTooltipPosition="above"
              (click)="downloadFile(timeline.action?.model?.modelInstance)"
            >


          </div>
          <div class="content" *ngSwitchCase="'certificationSended'">
            Ha enviado una nueva <span class="link" (click)="goToModel(timeline.action)">Certificación</span>
          </div>
          <div class="content" *ngSwitchCase="'certificationRejected'">
            Ha rechazado una <span class="link" (click)="goToModel(timeline.action)">Certificación</span>
          </div>
          <div class="content" *ngSwitchCase="'certificationAccepted'">
            Ha aceptado una <span class="link" (click)="goToModel(timeline.action)">Certificación</span>
          </div>
          <div class="content" *ngSwitchCase="'allTrackingsValidated'">
            Ha validado todos los trabajos realizados. 
          </div>
          <div class="content" *ngSwitchCase="'rejectBudget'">
            Ha rechazado el presupuesto 
            <span class="link" (click)="goToModel(timeline.action)">
              {{timeline.action?.model?.modelInstance.code}}
            </span>
            con importe total {{timeline.action?.model?.modelInstance.total | currency: 'EUR':'symbol':'1.2-2' }}.
          </div>
          <div class="content" *ngSwitchCase="'acceptBudget'">
            Ha aceptado el presupuesto 
            <span class="link" (click)="goToModel(timeline.action)">
              {{timeline.action?.model?.modelInstance.code}}
            </span>
            con importe total {{timeline.action?.model?.modelInstance.total | currency: 'EUR':'symbol':'1.2-2'}}.
          </div>
          <div class="content" *ngSwitchCase="'sendBudget'">
            Ha enviado el presupuesto 
            <span class="link" (click)="goToModel(timeline.action)">
              {{timeline.action?.model?.modelInstance.code}}
            </span>
          </div>
          <div class="content" *ngSwitchCase="'rejectEvent'">
            Ha rechazado la visita para el día {{timeline.action?.model?.metadata?.date | date: 'dd/MM/yyyy':'Europe/Madrid'}} en la calle
            {{timeline.action?.model?.metadata?.address}}
          </div>
          <div class="content" *ngSwitchCase="'acceptEvent'">
            Ha aceptado la visita para el día 
            <b>{{timeline.action?.model?.metadata?.date | date: 'dd/MM/yyyy':'Europe/Madrid'}}</b>
            en la calle 
            <b>{{timeline.action?.model?.metadata?.address?.address1}}, {{timeline.action?.model?.metadata?.address?.city}}</b>
          </div>
          <div class="content" *ngSwitchCase="'visitSuccess'">
            Ha confirmado que la visita se ha realizado. 
            <ng-container *ngIf="context === 'agent'">
              <br><br>
              El comentario sobre la visita: 
              <b>"{{timeline.action?.model?.metadata?.observations}}"</b>
            </ng-container>
          </div>
          <div class="content" *ngSwitchCase="'visitCancel'">
            Se ha cancelado una <span class="link" (click)="goToModel(timeline.action)">Visita</span>
          </div>
        </ng-container>
      </div>
      <div class="attachment"></div>
      <div class="read" [ngClass]="{
        'new': !timeline.read,
        'no-new': timeline.read
      }">
        <div class="state">{{ (timeline.read ? 'read' : 'new') | translate }}</div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="!timelineList">
  <div> Cargando el timeline...</div>
</ng-container>

<ng-container *ngIf="timelineList?.length === 0">
  <div> - No se ha generado ninguna actividad aún en este proyecto - </div>
</ng-container>
import { Injectable } from '@angular/core';

const attributes = [
  'offsetParent',
  'offsetHeight',
  'offsetLeft',
  'offsetTop',
  'offsetWidth',
  'clientHeight',
  'clientWidth',
];
const baitClass =
  'pub300x250 pub300x250m pub728x90 text-ad textAd textad textads text-ads text-ad-links';
const baitStyle =
  'width: 1000px !important; height: 1000px !important; position: absolute !important; left: -10000px !important; top: -1000px !important; display: block !important';
const defaultCount = 5;
const defaultTimeout = 1000;

@Injectable({
  providedIn: 'root',
})
export class AdblockService {
  private timeout = defaultTimeout;
  private _timeoutIdentifier;
  private _bait;

  constructor() {
    this._insertAd();
  }

  private checkAttributes(attributes, bait) {
    return attributes.reduce(
      (prev, cur) => prev || (bait[cur] === undefined ? false : !bait[cur]),
      false,
    );
  }

  detectAdBlock(count = defaultCount) {
    const check = this._check();

    if (check) {
      return true;
    }

    if (count > 0) {
      this._timeoutIdentifier = setTimeout(
        () => this.detectAdBlock(count - 1),
        this.timeout,
      );
    } else {
      this._removeAd();
    }
  }

  destroy() {
    this._removeAd();
  }

  _check() {
    if (!this._bait) {
      this._insertAd();
    }

    if (
      window.document.body.getAttribute('abp') ||
      this.checkAttributes(attributes, this._bait)
    ) {
      return true;
    }

    if (window.getComputedStyle !== undefined) {
      const baitTemp = window.getComputedStyle(this._bait, null);

      if (
        baitTemp &&
        (baitTemp.getPropertyValue('display') === 'none' ||
          baitTemp.getPropertyValue('visibility') === 'hidden')
      ) {
        return true;
      }
    }
  }

  _insertAd() {
    if (this._bait) {
      return;
    }

    const bait = window.document.createElement('div');
    bait.setAttribute('class', baitClass);
    bait.setAttribute('style', baitStyle);

    const [insertionPlace] = Array.from(
      window.document.getElementsByTagName('body'),
    );
    this._bait = insertionPlace.appendChild(bait);
  }

  _removeAd() {
    if (this._timeoutIdentifier) {
      clearTimeout(this._timeoutIdentifier);
    }

    window.document.body.removeChild(this._bait);
    this._bait = null;
  }
}

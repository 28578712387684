import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notice-modal',
  templateUrl: './notice-modal.component.html',
  styleUrls: ['./notice-modal.component.scss'],
})
export class NoticeModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NoticeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) {}

  ngOnInit() {}

  submit() {
    if (this.data.ctaUrl.includes('http')) {
      window.open(this.data.ctaUrl, '_blank');
      this.dialogRef.close();

      return;
    }

    this.router.navigate([this.data.ctaUrl]);
    this.dialogRef.close();
  }
}

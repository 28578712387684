<div
  class="empty-state"
  *ngIf="
    !document?.versions || document.versions.length === 0;
    else uploadedDocument
  "
>
  <button mat-flat-button color="accent" (click)="setAutomaticDocument()">
    <mat-icon>attachment</mat-icon> {{ 'upload-document' | translate }}
  </button>
</div>

<ng-template #uploadedDocument>
  <div class="uploaded-document">
    <div class="document-section">
      <div class="name" (click)="getFile(document.versions[0])">
        <mat-icon svgIcon="16px-pdf"></mat-icon>
        <span>{{ document.versions[0].fileName }}</span>
      </div>
      <div class="remove" (click)="delete(document.versions[0])">
        <button mat-icon-button>
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

    <form class="dates" [formGroup]="documentDatesFormGroup" *ngIf="showDates">
      <div class="custom-input text-sm">
        <p>{{ 'validate-date' | translate }}</p>

        <mat-form-field appearance="outline" color="primary">
          <input
            matInput
            [matDatepicker]="picker1"
            placeholder="{{ 'validate-date' | translate }}"
            formControlName="valueDate"
            (dateInput)="update()"
            (dateChange)="update()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="custom-input text-sm">
        <p>{{ 'due-date' | translate }}</p>

        <mat-form-field appearance="outline" color="primary">
          <input
            matInput
            [matDatepicker]="picker2"
            placeholder="{{ 'due-date' | translate }}"
            formControlName="dueDate"
            (dateInput)="update()"
            (dateChange)="update()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
    </form>
  </div>
</ng-template>

<input
  hidden
  (change)="handleFileSelect($event)"
  #fileInput
  type="file"
  id="file"
/>

<!-- <div class="document">
  <div class="row justify-content-end" *ngIf="kind == 'edit'">
    <div class="document-edit-buttons col-6" *ngIf="!multiple">
      <button mat-stroked-button class="btn btn-outline-primary" (click)="setAutomaticDocument()" *ngIf="document.versions && document.versions.length === 0">{{'upload-document' | translate}}</button>
    </div>
    <div class="document-edit-buttons col-6" *ngIf="multiple">
      <button mat-stroked-button class="btn btn-outline-primary" (click)="setAutomaticDocument()">{{'upload-document' | translate}}</button>
    </div>
  </div>

  <div class="row" *ngIf="showDates && kind === 'edit'">
    <mat-form-field class="col">
      <input matInput [matDatepicker]="picker1" placeholder="{{ 'validate-date' | translate }}" [(ngModel)]="document.valueDate" (dateInput)="update()" (dateChange)="update()">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="col">
      <input matInput [matDatepicker]="picker2" placeholder="{{'due-date' | translate }}" [(ngModel)]="document.dueDate" (dateInput)="update()" (dateChange)="update()">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="row" *ngIf="progress">
    <div class="col">
      <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
    </div>
  </div>

  <app-document-version [s3File]="version" *ngFor="let version of document.versions; trackBy: trackByFn" (deleteEmitter)="delete($event)" [kind]="kind"></app-document-version>
  <input hidden (change)="handleFileSelect($event)" #fileInput type="file" id="file">
</div>

<ng-template #documentForm>
  <h2 matDialogTitle background-color="grey">{{'new-document' | translate}}</h2>

  <mat-dialog-content>
    <app-document-form [(ngModel)]="document"></app-document-form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="btn btn-primary" (click)="update()" #b>{{'save' | translate}}</button>
    <button class="btn btn-danger" matDialogClose color="primary">{{'close' | translate}}</button>
  </mat-dialog-actions>
</ng-template> -->

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentSumaryService {
  subtotal$ = new BehaviorSubject<number>(0);
  taxPercent$ = new BehaviorSubject<number>(0);
  taxBase$ = new BehaviorSubject<number>(0);
  total$ = new BehaviorSubject<number>(0);
  advancePay$ = new BehaviorSubject<number>(0);
  advancePayPercent$ = new BehaviorSubject<number>(0);
  selectedPaymentMethod$ = new BehaviorSubject<'transfer' | 'card'>('card');
  isPaymentButtonDisabled$ = new BehaviorSubject<boolean>(true);
  isPaymentButtonVisible$ = new BehaviorSubject<boolean>(true);
  isSendContractButtonDisabled$ = new BehaviorSubject<boolean>(true);
  budgetState$ = new BehaviorSubject<string>('pendingOfAnswer');

  acceptBudget$ = new Subject<void>();
  rejectBudget$ = new Subject<void>();
  paymentCardCheckout$ = new Subject();
  paymentProcessStatus$ = new Subject<string>();
  downloadPDF$ = new Subject<void>();
  duplicateBudget$ = new Subject<void>();

  constructor() {}

  calculatePaymentSumary(subtotal: number, taxPercent: number) {
    const tax = taxPercent / 10000;
    const taxBase = subtotal * tax;

    this.subtotal$.next(subtotal);
    this.taxPercent$.next(tax);
    this.taxBase$.next(taxBase);
    this.total$.next(taxBase + subtotal);
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { OnboardingWelcomeResponseStatus } from './constants';
import { OnboardingWelcome, OnboardingWelcomeText } from './welcome';

@Component({
  selector: 'app-onboarding-welcome-modal',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class OnboardingWelcomeModal implements OnInit {
  
  messages: OnboardingWelcomeText = {};

  constructor(
    private dialogRef: MatDialogRef<OnboardingWelcomeModal>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: OnboardingWelcome
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.translate();
  }

  translate = (): Subscription =>
    this.translateService.get('onboarding.welcome.description2').subscribe((translation) => this.messages.description2 = translation);

  action = () => {
    this.dialogRef.close(OnboardingWelcomeResponseStatus.Action);
  }

  cancel = () => this.dialogRef.close(OnboardingWelcomeResponseStatus.Cancel);
}

<div class="contact-data"  *ngIf="currentProjectId && context == 'agent' && !isMobile && currentProject$ | async as project"  >
  <div>
  <p>
    {{project.contact.name }}
  </p>
  <p>
    {{project.address.address1}} 
  </p>
  <div class="start-end mb-[10px]">
    <span class="shortcode-input flex" *ngIf="!editShortCode" >
      {{project.shortCode}}
      <button *appHasPermission="'lead.update-shortcode'" (click)="toggleEditShortCode()">
        <mat-icon svgIcon="16px-edit"/>
      </button>
    </span>
    <div class="shortcode-input flex" *ngIf="editShortCode">
      <input *appHasPermission="'lead.update-shortcode'" id="shortCode" [value]="project.shortCode" (focusout)="updateShortCode($event)"/>
    </div>
  </div>
</div>
</div>

<div class="left">
  <button class="menu-button" mat-icon-button (click)="sidenavToggle()" *ngIf="hideMenu && isMobile && context !== 'agent'">
    <mat-icon>menu</mat-icon>
  </button> 

  <a *ngIf="context !== 'agent' && isMobile" [ngClass]="{ 'show-logo': showLogo }">
    <img [src]="mainLogoUrl$ | async" />
  </a>
</div>

<div class="right">
  <div *ngIf="context==='agent' && isMobile && currentProjectId" class="block lg:hidden">
    <mat-select class="w-full" (selectionChange)="redirectTo($event.value)" placeholder="{{getActiveLink() | translate | capitalizeFirst}}">
      <mat-option *ngFor="let link of links$ | async | orderBy: 'order'" [value]="link.routeTo">
        <cub-header-link
          [linkText]="link.slug | translate | capitalizeFirst"
          [isActive]="link.isActive"
          [dataCy]="link.dataCy"
          [badge]="link.badge"
          [permission]="link.permission"
        ></cub-header-link>
      </mat-option> 
    </mat-select>
  </div>

<div *ngIf="agent?.impersonated || loginClient?.impersonated" class="impersonated">
{{
(agent?.impersonated ? 'agent-impersonated' : 'client-impersonated')
| translate
}}
- {{ impersonatedName }}
-
<span (click)="returnToOldAgent()">VOLVER</span>
</div>

<div
  class="menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-2 hidden lg:flex"
  *ngFor="let link of links$ | async | orderBy: 'order'"
>
    <cub-header-link
      [linkText] = "link.slug | translate | capitalizeFirst"
      (linkClicked)="redirectTo(link.routeTo)"
      [isActive]= "link.isActive"
      [dataCy]="link.dataCy"
      [badge]="link.badge"
      [permission]="link.permission"
    >
    </cub-header-link>
</div>

<div *appHasPermission="'company.switch'" class="leads-selector icons-section">
<mat-select
  *ngIf="agentSelectedEmail"
  [(value)]="agentSelectedEmail"
  data-cy="project-state"
>
    <mat-option
        *ngFor="let agent of agents"
        [value]="agent.contact.email"
        (click)="switchTo(agent.contact.email)"
     >
     {{agent.address.city}}
      </mat-option>
  </mat-select>
</div>

<div *ngIf="context === 'login-client' || context === 'agent'" class="icons-section">
<button
  mat-flat-button color="accent"
  (click)="simulateBudget('ppv'); $event.stopPropagation()"
  *ngIf="ppv">
  {{ 'price-estimator' | translate }}
</button>
</div>
<div *ngIf="showRequest" class="icons-section">
  <button mat-flat-button color="primary" (click)="requestVisit(); $event.stopPropagation()">
    <mat-icon svgIcon="24px-customerService" [matTooltip]="'cubicup-shop' | translate" matTooltipPosition="above">
    </mat-icon>
    <ng-container *ngIf="viewMode === 'web' && !loginClient?.ppv || !ppv">
      {{ 'request-call' | translate }}
    </ng-container>
    <ng-container *ngIf="viewMode === 'web' && loginClient?.ppv || ppv">
      {{ 'request-visit' | translate }}
    </ng-container>
  </button>
</div>

<div class="icons-section">
<button mat-icon-button *ngIf="context === 'agent'" color="accent"
  (click)="toggleRemindersCenter()" data-cy="reminder-icon">
  <mat-icon svgIcon="24px-calendar" [matBadge]="notReadReminders?.length"
    [matBadgeHidden]="!notReadReminders || notReadReminders?.length === 0" matBadgeColor="warn"
    [matTooltip]="'reminders' | translate" matTooltipPosition="above">
  </mat-icon>
</button>

<button mat-icon-button color="accent" (click)="toggleNotificationsCenter()"
  *ngIf="context === 'login-client'" data-cy="notifications-icon">
  <mat-icon svgIcon="24px-notifications" [matBadge]="notReadNotifications?.length" [matBadgeHidden]="
      !notReadNotifications || notReadNotifications?.length === 0
    " matBadgeColor="warn" [matTooltip]="'notifications' | translate" matTooltipPosition="above">
  </mat-icon>
</button>
</div>

<app-header-person *ngIf="isAgent() || context === 'login-client'" [matMenuTriggerFor]="menu"
[isMenuClosed$]="isMenuClosed$" (menuClosed)="menuClose()" (menuOpened)="menuOpen()">
</app-header-person>


<button mat-stroked-button color="accent" (click)="goToLogin()" class="login-button" *ngIf="context === 'client'">
{{ 'login' | translate }}
</button>
</div>
<!-- </mat-toolbar> -->

<mat-menu #menu="matMenu" xPosition="before">
  <button mat-menu-item (click)="goToProfile()">
    <mat-icon svgIcon="24px-profile"></mat-icon>
    <span>{{ 'client-profile' | translate }}</span>
  </button>
  <button mat-menu-item (click)="goToPortfolio()" *ngIf="isAgent()">
    <mat-icon svgIcon="24px-settings"></mat-icon>
    <span>{{ 'my-landing' | translate }}</span>
  </button>
  <button mat-menu-item (click)="logout()" data-cy="logout">
  <mat-icon>power_settings_new</mat-icon>
  <span>{{ 'logout' | translate }}</span>
  </button>
</mat-menu>

<button mat-button matSuffix mat-icon-button aria-label="search" class="easy-calendar-close-button"
*ngIf="showEasyCalendarCloseButton" (click)="hideEasyCalendar()">
  <mat-icon>close</mat-icon>
</button>

<div class="easycalendar" #easyCalendarDiv id="easycalendar-calendar-widget" data-link=""
[hidden]="!showEasyCalendarModal"></div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { HelpModalComponent } from '../../dialogs/help-modal/help-modal.component';
import { ResponsiveService } from '@core/services/responsive.service';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { selectUrl } from 'src/app/store/router.selectors';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();

  viewMode: string;
  context: string;

  agentContent = {
    events: {
      slug: 'Gestiona todas tus visitas',
      modalConfig: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    },
    event: {
      slug: '',
      modalConfig: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    },
    projects: {
      slug: 'Descubre cómo gestionar proyectos',
      modalConfig: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    },
    project: {
      slug: '',
      modalConfig: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    },
    budgets: {
      slug: '',
      modalConfig: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    },
    budget: {
      slug: '',
      modalConfig: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    },
    budgetEdition: {
      slug: '',
      modalConfig: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    },
    templates: {
      slug: '',
      modalConfig: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    },
    profile: {
      slug: 'Gana puntos de reputación completando tu perfil',
      modalConfig: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    },
  };

  loginClientContent = {
    projects: {
      slug: '',
      modalConfig: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    },
    project: {
      slug: '',
      modalConfig: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    },
    budgets: {
      slug: '',
      modalConfig: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    },
    budget: {
      slug: '',
      modalConfig: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    },
    budgetEdition: {
      slug: '',
      modalConfig: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    },
    wizard: {
      slug: '',
      modalConfig: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    },
    profile: {
      slug: '',
      modalConfig: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    },
  };

  contentToShow: any;

  constructor(
    private dialog: MatDialog,
    private responsiveService: ResponsiveService,
    private store: Store<{ router: any; context: any }>,
  ) {}

  ngOnInit() {
    this.responsiveService.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );

    this.store
      .select('context')
      .subscribe(({ value: context }) => (this.context = context));

    this.store
      .select(selectUrl)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((url) => Boolean(url)),
      )
      .subscribe((url) => {
        const isLoggedClient = this.context === 'logged-client';
        const indexUrl = isLoggedClient ? 2 : 1;
        const realUrl = url.split('/')[indexUrl];

        this.contentToShow = isLoggedClient
          ? this.loginClientContent[realUrl]
          : this.agentContent[realUrl];
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  viewHelp() {
    const decisionModal = this.dialog.open(HelpModalComponent, {
      width: '750px',
      height: this.viewMode !== 'web' ? '200px' : '500px',
      data: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    });

    decisionModal.afterClosed().subscribe((data) => {
      if (!data) {
        return;
      }
    });
  }
}

/* eslint-disable */

declare var Object: any;
export interface CubicupStaffInterface {
  name: string;
  email: string;
  phoneNumber: string;
  phoneExtension: string;
  avatar?: string;
  id?: number;
  createdAt: Date;
  updatedAt: Date;
  modelActive?: any;
}

export class CubicupStaff implements CubicupStaffInterface {
  'name': string;
  'email': string;
  'phoneNumber': string;
  'phoneExtension': string;
  'avatar': string;
  'id': number;
  'createdAt': Date;
  'updatedAt': Date;
  'modelActive': any;
  constructor(data?: CubicupStaffInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CubicupStaff`.
   */
  public static getModelName() {
    return 'CubicupStaff';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of CubicupStaff for dynamic purposes.
   **/
  public static factory(data: CubicupStaffInterface): CubicupStaff {
    return new CubicupStaff(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'CubicupStaff',
      plural: 'CubicupStaffs',
      path: 'CubicupStaffs',
      idName: 'id',
      properties: {
        name: {
          name: 'name',
          type: 'string',
        },
        email: {
          name: 'email',
          type: 'string',
        },
        phoneNumber: {
          name: 'phoneNumber',
          type: 'string',
        },
        phoneExtension: {
          name: 'phoneExtension',
          type: 'string',
        },
        avatar: {
          name: 'avatar',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'number',
        },
        createdAt: {
          name: 'createdAt',
          type: 'Date',
        },
        updatedAt: {
          name: 'updatedAt',
          type: 'Date',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
      },
      relations: {},
    };
  }
}

import { OnInit, Component, Input, OnDestroy } from '@angular/core';
import { ResponsiveService } from '@core/services/responsive.service';
import { RemindersCenterService } from '@core/services/reminders-center.service';

@Component({
  selector: 'app-reminders-center',
  templateUrl: './reminders-center.component.html',
  styleUrls: ['./reminders-center.component.scss'],
})
export class RemindersCenterComponent implements OnInit, OnDestroy {
  @Input() reminders: any[] = [];

  notReadReminders: any[];
  fixedReminders: any[];
  normalReminders: any[];
  viewMode: string;

  constructor(
    private responsiveservice: ResponsiveService,
    private remindersCenterService: RemindersCenterService,
  ) {}

  ngOnInit() {
    this.responsiveservice.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );

    this.getFixedReminders();
  }

  ngOnDestroy() {
    const iterations = this.viewMode === 'web' ? 9 : 4;
    const readReminders = this.reminders.slice(0, iterations);

    this.remindersCenterService.markRemindersAsRead(readReminders);
  }

  private getFixedReminders() {
    this.fixedReminders = [];
    this.normalReminders = [];

    for (let index = 0; index < this.reminders.length; index++) {
      const reminder = this.reminders[index];

      if (reminder.fixed) {
        this.fixedReminders.push(reminder);

        continue;
      }

      this.normalReminders.push(reminder);
    }
  }

  toggleRemindersCenter() {
    this.remindersCenterService.toggleRemindersCenter();
  }

  handlerReminderClosed(reminderID) {
    this.reminders = this.reminders.filter(
      (reminder) => reminder.id !== reminderID,
    );
    this.getFixedReminders();
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { StoreModule, ActionReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import locales from '@angular/common/locales/es';

import { localStorageSync } from 'ngrx-store-localstorage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { MatPaginatorI18nService } from '@core/services/matpaginatdor.service';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import environment from '@environment';
import { EffectsModule } from '@ngrx/effects';
import { CookieService } from 'ngx-cookie-service';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { GlobalErrorHandlerModule } from './core/global-error-handler/global-error-handler.module';
import { SDKBrowserModule } from './core/sdk';
import { AuthenticationModule } from './pages/authentication/authentication.module';
import * as agentReducer from './store/reducers/agent.reducer';
import { clearState } from './store/reducers/meta.reducer';
import * as clientReducer from './store/reducers/client.reducer';
import { AuthEffects } from './store/effects/auth.effects';
import * as authReducer from './store/reducers/auth.reducer';
import * as projectsReducer from './store/reducers/project.reducer';
import * as budgetsReducer from './store/reducers/budget.reducer';
import * as quizConfiguratorReducer from './store/reducers/quiz-configurator.redurer';
import { ProjectEffects } from './store/effects/project.effects';
import { MarkdownModule } from 'ngx-markdown';
import { TestInterceptor } from '@core/interceptors/test.interceptor';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular-ivy";
import * as contextReducer from './store/context/context.reducer';
import { GoogleAnalyticsService } from '@core/services/google-analytics.service';
import { ContextStoreModule } from './store/context/context-store.module';
import { ErrorHandlerStoreModule } from './store/error-handler/error-handler.module';
import { certificationsReducer } from './store/certifications/certifications.reducer';
import { CertificationsEffects } from './store/certifications/certifications.effects';
import { RoleService } from '@core/services/role.service';
import { WebSocketService } from './@websocket/socket.service';

registerLocaleData(locales, 'es-ES');

export function localStorageSyncReducer(
  reducer: ActionReducer<any>,
): ActionReducer<any> {
  return localStorageSync({
    keys: ['agent', 'client', 'context', 'user'],
    rehydrate: true,
  })(reducer);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    GlobalErrorHandlerModule,
    SDKBrowserModule.forRoot(),
    ContextStoreModule,
    ErrorHandlerStoreModule,
    EffectsModule.forRoot([AuthEffects, ProjectEffects,CertificationsEffects]),
    StoreModule.forRoot(
      {
        certifications: certificationsReducer,
        agent: agentReducer.reducer,
        client: clientReducer.reducer,
        projects: projectsReducer.reducer,
        budgets: budgetsReducer.reducer,
        context: contextReducer.contextReducer,
        user: authReducer.reducer,
        router: routerReducer,
        quizConfigurator: quizConfiguratorReducer.reducer
      },
      {
        metaReducers: [clearState, localStorageSyncReducer],
      },
    ),
    AuthenticationModule,
    StoreRouterConnectingModule.forRoot({
      // serializer: CustomSerializer,
    }),
    !environment.production // Activate DevTools only on development and integration
    ? StoreDevtoolsModule.instrument({
        maxAge: 100, // Retains last 25 states
        logOnly: true, 
      })
    : [],
    MarkdownModule.forRoot({ loader: HttpClient }),
    NgxExtendedPdfViewerModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es-ES', // 'de-DE' for Germany, 'fr-FR' for France ...
    },
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TestInterceptor,
      multi: true,
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorI18nService },
    GoogleAnalyticsService,
    CookieService,
    RoleService,
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: APP_INITIALIZER,
      useFactory:  () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    WebSocketService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { CubicupStaff, Lead, LeadInterface } from '@core/sdk';
import { ResponsiveService } from '@core/services/responsive.service';
import { LeadService } from '@core/services/lead.service';
import { ConversationService } from '@core/services/conversation.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-banner-claim',
  templateUrl: './banner-claim.component.html',
  styleUrls: ['./banner-claim.component.scss'],
})
export class BannerClaimComponent implements OnInit {
  @Input() claimImagePath: string =
    'https://rlv.zcache.com.br/poster_joseph_decreux_meme-rb6c5fe16f64d41a6903a6d24f853abbd_861o_8byvr_307.jpg?rvtype=content';
  @Input() claimTextContent: {
    section?: string;
    title: string;
    claim: string;
  };

  @Input() mode: 'project' | 'events' | 'templates';
  @Input() project: LeadInterface;

  viewMode: string;
  hasNewMessages = false;
  context: string;

  constructor(
    private responsiveService: ResponsiveService,
    private projectService: LeadService,
    private conversationService: ConversationService,
    private router: Router,
    private store: Store<{ context: any; }>,
  ) { }

  ngOnInit() {
    this.responsiveService.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );

    if (this.mode === 'project' || 'events') {
      this.initializeClaim();
    }
    this.store
      .select('context')
      .subscribe(({ value: context }) => {
        this.context = context;
      });
  }

  private initializeClaim() {
    if (!this.project) {
      return;
    }

    if (!this.project.cubicupStaffConversation) {
      return;
    }

    if (
      !this.project.cubicupStaffConversation.message ||
      this.project.cubicupStaffConversation.message.length === 0
    ) {
      return;
    }

    const { message } = this.project.cubicupStaffConversation;

    message.forEach((message) => {
      if (!message.read) {
        this.hasNewMessages = true;
      }
    });
  }

  private openCubicupConversation() {
    this.conversationService.toggleConversation({
      conversation: this.project.cubicupStaffConversation,
      receptor: this.project.cubicupStaff,
      receptorIsStaff: true,
      targetModel: this.project,
      isDiscussion: false,
    });
  }

  callCubicupStaff() {
    if (this.project.cubicupStaffConversation) {
      this.openCubicupConversation();

      return;
    }

    this.projectService
      .createCubicupStaffConversation(this.project.id)
      .subscribe(() => this.openCubicupConversation());
  }
}

import { createAction, props } from '@ngrx/store';
import { QuizInterface } from 'src/app/shared/interfaces/quiz.interface';


export const AddOrUpdateCurrentQuizAction = createAction(
  '[QuizConfigurator Component] AddOrUpdateCurrentQuizAction',
  props<{ quiz: QuizInterface }>(),
);

export const AddOrUpdateCurrentQuestionAction = createAction(
  '[QuizConfigurator Component] AddOrUpdateCurrentQuestionAction',
  props<any>(),
);

export const AddOrUpdateResponsesAction = createAction(
  '[QuizConfigurator Onboarding] AddOrUpdateResponsesAction',
  props<any>(),
);

export const CleanQuizDataAction = createAction(
  '[QuizConfigurator Onboarding] CleanQuizDataAction'
);
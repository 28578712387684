/* eslint-disable */
import { BudgetItem, Budget } from './index';

declare var Object: any;
export interface ChapterInterface {
  title?: string;
  description?: string;
  order?: number;
  id?: string;
  agentId?: string;
  budgetId?: string;
  chapterTemplateId?: number;
  modelActive?: any;
  budgetItems?: Partial<BudgetItem>[];
  budget?: Budget;
  createdAt?: string;
  state?: string;
  type?: string;
  cype?: boolean;
}

export interface ChapterWithTrackingIdsInterface {
  title?: string;
  description?: string;
  order?: number;
  id?: string;
  agentId?: string;
  budgetId?: string;
  chapterTemplateId?: number;
  modelActive?: any;
  budgetItems?: Partial<BudgetItem>[];
  budget?: Budget;
  createdAt?: string;
  state?: string;
  type?: string;
  cype?: boolean;
  trackingIds?: string[];
}

export class Chapter implements ChapterInterface {
  title?: string;
  description?: string;
  order?: number;
  id?: string;
  agentId?: string;
  budgetId?: string;
  chapterTemplateId?: number;
  modelActive?: any;
  budgetItems?: Partial<BudgetItem>[];
  budget?: Budget;
  state?: string;
  createdAt?: string;
  type?: string;
  cype?: boolean;
  constructor(data?: ChapterInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Chapter`.
   */
  public static getModelName() {
    return 'Chapter';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Chapter for dynamic purposes.
   **/
  public static factory(data: ChapterInterface): Chapter {
    return new Chapter(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Chapter',
      plural: 'Chapters',
      path: 'Chapters',
      idName: 'id',
      properties: {
        title: {
          name: 'title',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        order: {
          name: 'order',
          type: 'number',
        },
        id: {
          name: 'id',
          type: 'number',
        },
        agentId: {
          name: 'agentId',
          type: 'number',
        },
        budgetId: {
          name: 'budgetId',
          type: 'number',
        },
        chapterTemplateId: {
          name: 'chapterTemplateId',
          type: 'number',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
      },
      relations: {
        budgetItems: {
          name: 'budgetItems',
          type: 'BudgetItem[]',
          model: 'BudgetItem',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'chapterId',
        },
        budget: {
          name: 'budget',
          type: 'Budget',
          model: 'Budget',
          relationType: 'belongsTo',
          keyFrom: 'budgetId',
          keyTo: 'id',
        },
      },
    };
  }
}

import { TeamInterface } from '@core/sdk/models/Team';
import { createAction, props, Action } from '@ngrx/store';

/************************/
/******** LOGIN *********/
/************************/

export const AuthLoginAction = createAction('[Login Page] Login', props<any>());

export const AuthLoginCompleteAction = createAction(
  '[Login Page] Login Complete',
  props<any>(),
);

export const AuthLoginSuccessAction = createAction(
  '[Auth API] Login Success',
  props<any>(),
);

export const AuthLoginFailureAction = createAction(
  '[Auth API] Login Failure',
  props<any>(),
);

export const AuthLoginFailureCleanAction = createAction(
  '[Auth API] Login Failure Clean',
);

export const AuthCheckLoginAction = createAction(
  '[Auth] Check Login',
  props<any>(),
);

export const AuthLogoutAction = createAction(
  '[Auth] Confirm Logout',
  props<any>(),
);

export const AuthSetRevisionDataAction = createAction(
  '[Auth] Set Revision Token',
  props<any>(),
);

/***********************************/
/******** REGISTER AGENT  *********/
/***********************************/

export const AuthRegisterAgentAction = createAction(
  '[Register Agent Page] Login',
  props<any>(),
);

export const AuthRegisterAgentCompleteAction = createAction(
  '[Register Agent Page] Login Complete',
  props<any>(),
);

export const AuthRegisterAgentSuccessAction = createAction(
  '[Auth API] Register Agent Success',
  props<any>(),
);

export const AuthRegisterAgentFailureAction = createAction(
  '[Auth API] Register Agent Failure',
  props<any>(),
);

export const AuthRegisterAgentFailureCleanAction = createAction(
  '[Auth API] Register Agent Failure Clean',
);

/***********************************/
/******** REGISTER CLIENT  *********/
/***********************************/

export const AuthRegisterClientAction = createAction(
  '[Register Client Page] Register Client',
  props<any>(),
);

export const AuthRegisterClientCompleteAction = createAction(
  '[Register Client Page] Register Client Complete',
  props<any>(),
);

export const AuthRegisterClientSuccessAction = createAction(
  '[Auth API] Register Client Success',
  props<any>(),
);

export const AuthRegisterClientFailureAction = createAction(
  '[Auth API] Register Client Failure',
  props<any>(),
);

export const AuthRegisterClientFailureCleanAction = createAction(
  '[Auth API] Register Client Failure Clean',
);

export const AuthUserUpdateTeamsRequestStarted = createAction(
  '[Teams API] Auth User added new Team request started',
  props<{ teamId: string, userId: string }>(),
)

export const AuthUserUpdateTeamsRequestSuccess = createAction(
  '[Teams API] Auth User added new Team request success',
  props<{ team: TeamInterface }>()
);

export const AuthUserRemoveTeamRequestStarted = createAction(
  '[Teams API] Auth User remove User from Team request started',
  props<{ teamId: string, userId: string }>()
);

export const AuthUserRemoveTeamRequestSuccess= createAction(
  '[Teams API] Auth User remove User from Team request success',
  props<{ teamId: string }>()
);



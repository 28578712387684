import { Action, createReducer, on, State } from '@ngrx/store';
import {
  AuthLoginSuccessAction,
  AuthLoginFailureAction,
  AuthRegisterClientFailureAction,
  AuthRegisterClientFailureCleanAction,
  AuthRegisterAgentFailureAction,
  AuthRegisterAgentFailureCleanAction,
  AuthLoginFailureCleanAction,
  AuthSetRevisionDataAction,
  AuthUserUpdateTeamsRequestSuccess,
  AuthUserRemoveTeamRequestSuccess,
} from '../actions/auth.actions';
import { logoutAction } from '../actions/meta.action';

interface UserAuth {
  agentId?: string;
  clientId?: string;
  created?: string;
  id?: string;
  notificationsQueue?: {
    id?: string;
    personId?: string;
    createdAt?: string;
    updatedAt?: string;
    modelActive?: any;
  };
  oneSignalUserId?: string;
  role?: string;
  ttl?: number;
  userId?: string;
  userType?: string;
  userIsLogged: boolean;
  revisionToken?: string;
}

export const initialState: UserAuth | any = {
  userIsLogged: false,
};

const authReducer = createReducer(
  initialState,
  on(AuthLoginSuccessAction, (state, result) => ({
    ...state,
    ...result,
    userIsLogged: true,
  })),
  on(AuthLoginFailureAction, (state, result) => ({
    ...state,
    errorMessage: 'Incorrect email and/or password.',
  })),
  on(AuthLoginFailureCleanAction, (state, result) => ({
    ...state,
    errorMessage: null,
  })),
  on(AuthRegisterClientFailureAction, (state, result) => ({
    ...state,
    error: result,
  })),
  on(AuthRegisterClientFailureCleanAction, (state, result) => ({
    userIsLogged: false,
  })),
  on(AuthRegisterAgentFailureAction, (state, result) => ({
    ...state,
    error: result,
  })),
  on(AuthRegisterAgentFailureCleanAction, (state, result) => ({
    ...state,
    error: null,
  })),
  on(AuthSetRevisionDataAction, (state, { revisionToken, agentIdToCheck }) => ({
    ...state,
    revisionToken: revisionToken,
    agentIdToCheck: agentIdToCheck,
  })),
  on(AuthUserUpdateTeamsRequestSuccess, (state, {team}) => ({
    ...state,
    teams: [...state.teams, team]
  })),
  on(AuthUserRemoveTeamRequestSuccess, (state, {teamId}) => ({
    ...state,
    teams: state.teams.filter(team => team.id !== teamId)
  })),
  
  on(logoutAction, (state) => initialState),
);

export function reducer(state: State<any> | undefined, action: Action) {
  return authReducer(state, action);
}

import { Component, OnInit } from '@angular/core';
import { LeadInterface } from '@core/sdk';
import { EventsService } from '@core/services/event.service';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { selectProjectById } from 'src/app/store/selectors/project.selectors';

@Component({
  selector: 'cub-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss']
})
export class EventsListComponent implements OnInit {
  context: any;
  leadId: string;
  events$: Observable<Event[]>
  constructor(
    private store: Store<{
      context: any;
      project: LeadInterface
    }>,
    private eventService: EventsService
  ) { }

  ngOnInit(): void {
    this.store.select('context').subscribe(({ value: context, currentProjectId }) => {
      this.context = context;

      if (currentProjectId) {
        this.leadId = currentProjectId;
        this.events$ = this.eventService.getLeadEvents(currentProjectId)
      }

    })
  }
}

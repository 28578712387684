import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LeadInterface, AgentInterface, ClientInterface, UserInterface, Budget, Reason } from '@core/sdk';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { LeadService } from '@core/services/lead.service';
import { NotificationsService } from '@core/services/notifications.service';
import { CreateProjectModalComponent } from '../../create-project/create-project.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ResponsiveService } from '@core/services/responsive.service';
import { NotificationsCenterService } from '@core/services/notifications-center.service';
import { Store } from '@ngrx/store';
import { DecisionModalComponent } from '@shared/dialogs/decision-modal/decision-modal.component';
import { UrnDialogComponent } from '../urn-dialog/urn-dialog.component';
import { DialogGeneralComponent } from "@shared/dialogs/dialog-component/dialog-general.component";
import { ClientService } from '@core/services/client.service';
import environment from '@environment';
import { LocalStorageService } from '@core/services/local-storage.service';
import { STATES_ACTIVATE_TRACKING } from '@core/constants';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { BudgetService } from '@core/services/budget.service';
import { EventsService } from '@core/services/event.service';
import { RejectedModalComponent } from 'src/app/components/standalone/modals/reject-modal/reject-modal.component';
import { ModalService } from '@core/services/modal.service';
import { TeamService } from '@core/services/team.service';
import { AuthUserRemoveTeamRequestStarted, AuthUserUpdateTeamsRequestStarted } from 'src/app/store/actions/auth.actions';

@Component({
  selector: 'app-project-row',
  templateUrl: './project-row.component.html',
  styleUrls: ['./project-row.component.scss'],
})
export class ProjectRowComponent implements OnInit {
  @Input() lead: LeadInterface;
  @Input() agentName: string;
  @Output() reload = new EventEmitter<void>();
  @Output() projectClicked = new EventEmitter<any>();

  getProjectStateKeys = Object.keys;
  projectStateForm: UntypedFormGroup;
  isPosibleTracking: boolean = false;
  isPosibleBilling: boolean = false;
  budgetAccepted: Budget;

  projectStates = {};
  isSuperAdmin = false;
  projectTypes = {};
  publicProjectStates = {};
  viewMode: string;
  agentAvatar: string;
  isCubicupAgent: boolean = false;
  notReadNotifications: any[] = [];
  isAssignedToTeam: boolean = false;
  stars = [
    { filled: false },
    { filled: false },
    { filled: false },
    { filled: false },
    { filled: false },
  ];
  context: any;
  user: UserInterface;

  constructor(
    private fb: UntypedFormBuilder,
    private projectService: LeadService,
    private notificationsService: NotificationsService,
    private dialog: MatDialog,
    private clientService: ClientService,
    private responsiveService: ResponsiveService,
    private notificationsCenterService: NotificationsCenterService,
    private leadService: LeadService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private store: Store<{
      agent: AgentInterface;
      client: ClientInterface;
      projects: LeadInterface[];
      context: any;
      user: UserInterface;
    }>,
    private budgetService: BudgetService,
    private teamService: TeamService,
  ) {
    this.translateService.setDefaultLang('es_ES');
    this.translateService.use('es_ES');
  }

  async openModal() {
    const leadData = await this.leadService.getMagicPlanInfo(this.lead);
    const actions = [
      {
        label: 'ok',
        closeModal: true,
      }
    ]

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = "magicplan-info-modal";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      titleLabel: 'magicplan.dialog.title',
      subtitleLabel: 'magicplan.dialog.subtitle',
      actionButtonLabel: "Ok",
      body: leadData['data'],
      actions: actions,
    }

    this.dialog.open(DialogGeneralComponent, dialogConfig);
  }

  ngOnInit() {

    this.projectStates = this.projectService.getProjectStates();
    this.publicProjectStates = this.projectService.getPublicProjectStates();
    this.projectTypes = this.projectService.getProjectTypes();
    this.isCubicupAgent = this.localStorageService.get('userEmail')?.endsWith('@cubicup.com');

    this.store.select('context').subscribe(({ value: context }) => {
      this.context = context;

      switch (context) {
        case 'agent':
          this.initializeAgentComponent();
          break;
        case 'login-client':
          this.initializeClientComponent();
          break;
        default:
          break;
      }
    });

    this.initializeForm();

    this.notificationsCenterService
      .getNotificationsByModelId(this.lead.id)
      .subscribe((notifications) => {
        this.notReadNotifications = notifications.filter(
          (notification) => !notification.read,
        );
      });

    this.responsiveService.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );
    
    this.store.select('user').subscribe((user) => {
      this.user = user;
    })
    
    const { invoices, state, code, shortCode } = this.lead;

    this.isPosibleTracking =  STATES_ACTIVATE_TRACKING.includes(state)

    this.isPosibleBilling = !invoices && STATES_ACTIVATE_TRACKING.includes(state) && code!=null && shortCode!=null;    
  }

  goToProject(lead: LeadInterface) {
    this.projectClicked.emit(lead);
  }

  getMagicplanDetails() {
    const dialogRef = this.openDialog(DialogGeneralComponent);
  }
  addUrn() {
    const dialogRef = this.openDialog(UrnDialogComponent);

    dialogRef.afterClosed().subscribe(async (result: { urnLink: any; }) => {
      if (!result) {
        return;
      }

      const urnValue = {
        urn: result.urnLink
      };

      this.leadService.updateProject(this.lead.id, urnValue)
        .subscribe(() => {
          this.notificationsService.showSuccess('URN actualizada');
        })
    });
  }
     /**
   * Open the project reject dialog with the reject reasons
   */
  handleRejectedProject() {
      this.modalService
        .getReasons('Lead')
        .subscribe((reasons: Reason) =>
          this.rejectProject(reasons),
        );
        this.reload.emit();
  }

  rejectProject(reasons: Reason) {
    const projectLostModal = this.dialog.open(RejectedModalComponent, {
      width: '456px',
      height: '460px',
      data: { 
          reasons,
          title: 'project-reject-title',
          subtitle: 'project-reject-subtitle'
      }
    });

    projectLostModal.afterClosed().subscribe((data: Reason) => {

      if (!data) {
        return;
      }
      
      this.projectService.updateProject(
        this.lead.id,
        {
          state:'lost', modelActive: { active:false, description: data.reason['reason'] }
        }).subscribe(()=>{
          this.notificationsService.showSuccess('Proyecto marcado como perdido');
        })
    });
  }

  /**
   * It opens a dialog box.
   * @param {any} any - any
   * @returns The dialogRef.
   */
   openDialog(any: any): any {
    const dialogRef: any = this.dialog.open(any, {
      width: '100%',
      data: {
        optionSelected: null,
        budget: null,
        file: null,
      },
    });

    return dialogRef;
  }

  /**
   * It subscribes to the agent state and sets the agentAvatar to the image in the state.
   */
  private initializeAgentComponent() {
    this.store
      .select('agent')
      .subscribe((agent) => {
        this.agentAvatar = agent.image;
      });
  }

  /**
   * It subscribes to the client state and sets the agent avatar to the client avatar.
   */
  private initializeClientComponent() {
    this.store.select('client').subscribe((client: any) => {
      this.agentAvatar = client.avatar;
    });
  }

  /**
   * * Create a form group with a form control for the project state.
   * * If the lead is owned by the user, set the form control to the lead's state.
   * * Otherwise, set the form control to a disabled option with the lead's state
   */
  private setViewProjectState() {
    let publicProjectState = '';

    if (this.lead.state) {
      publicProjectState = this.projectStates[this.lead.state]
        .publicProjectState;
    }

    const defaultProjectState = this.lead.own
      ? new UntypedFormControl(publicProjectState, [])
      : [{ value: this.lead.state, disabled: true }];

    this.projectStateForm = this.fb.group({
      projectState: defaultProjectState,
    });
  }

  /**
   * It sets the initial state of the form.
   */
  private initializeForm() {
    this.setViewProjectState();

    this.projectStateForm
      .get('projectState')
      .valueChanges.subscribe((value) => this.changeProjectState(value));
  }

  /**
   * Change the state of a project
   * @param newState - The new state of the project.
   */
  private changeProjectState(newState) {
    this.projectService
      .changeProjectState(this.lead.id, newState)
      .subscribe(() => {
        this.reload.emit();
        this.notificationsService.showSuccess('Estado cambiado');
      });
  }

  /**
   * It stops the propagation of the event.
   * @param event - The event object that was passed to the handler.
   */
  stopPropagation(event) {
    event.stopPropagation();
  }

  /**
   * Open a dialog that allows the user to create or edit a project
   */
  editProject(): void {
    const dialogRef: MatDialogRef<CreateProjectModalComponent> = this.dialog.open(
      CreateProjectModalComponent,
      {
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        data: { lead: this.lead },
      },
    );

    dialogRef.componentInstance.upsertButton = 'save';
    dialogRef.componentInstance.title = 'edit-project';

    dialogRef.afterClosed().subscribe(() => this.reload.emit());
  }

  /**
   * Delete a project
   */
  deleteProject(): void {
    const dialogRef: MatDialogRef<DecisionModalComponent> = this.dialog.open(
      DecisionModalComponent,
      {
        width: '650px',
        height: this.viewMode !== 'web' ? '270px' : '160px',
        data: {
          title: '¿Estás seguro/a de que quieres eliminar este proyecto?',
          cta: 'Eliminar proyecto',
          showCancelButton: true,
        },
      }
    );

    dialogRef.afterClosed().subscribe(
      (result) => {
        if (!result) {
          return;
        }

        this.projectService.deleteProject(this.lead.id).subscribe(
          () => {
            this.reload.emit();
            this.notificationsService.showSuccess('Proyecto borrado');
          });
      },
    );
  }
  setB2b() {
    this.clientService.setB2b( this.lead.client ).subscribe(
      ( response ) => {
        this.reload.emit();
        this.notificationsService.showSuccess( 'Client B2B actualizado' );
      }
    );
  }

  setPPV() {
      this.clientService.setPPV(this.lead.client).subscribe((response)=>{
        this.reload.emit();
        this.notificationsService.showSuccess('Client PPV actualizado');
      });
  }

  setTracking(){
      this.clientService.setTracking(this.lead).subscribe(
        () =>{
          //TODO: Update project row state in store
          return this.notificationsService.showSuccess('Traking actualizado');
        }
        ,
        (error) => {
          return this.notificationsService.showError('No se puede activar tracking en este proyecto'); 
        }
      )
    }

  setInvoices(){
      if(!environment.holded.apiCreate){

        return this.notificationsService.showError(this.translateService.instant("invoices-slugs.not-working-dev-int")); 

      }

      const filter = { state: "contractSended" }

      this.budgetService.getBudgetsByLeadId(this.lead.id, filter).pipe(
        switchMap(budgets => {
          if (budgets.length > 0) {
            this.budgetAccepted = budgets[0];
            return this.clientService.setCreateInvoicesStructure(this.lead, this.budgetAccepted);
          } 
            throw new Error(this.translateService.instant("invoices-slugs.project-without-contract-sended"));
          
        }),
        catchError(error => {
          this.notificationsService.showError(error.message);
          return of(null);
        })
      ).subscribe(response => {
        if (response && response.result.status === 200) {
          this.notificationsService.showSuccess(this.translateService.instant("invoices-slugs.invoices-activated"));
          return
        }
         this.notificationsService.showError(this.translateService.instant("invoices-slugs.invoices-cant-activated"));
      });
  }

  assignPersonToTeam(teamId?: string): void {
    if(!teamId){
      this.teamService.createTeamLead(this.lead.id).pipe(
        take(1)
      ).subscribe((team) => {
        this.store.dispatch(AuthUserUpdateTeamsRequestStarted({teamId: team.id, userId: this.user.userId}))
      })
      return;
    }
   this.store.dispatch(AuthUserUpdateTeamsRequestStarted({teamId, userId: this.user.userId}))
  }

  isPersonAssignedToTeam() {
    if(!this.lead.teamId){
      return; 
    }
   
    this.isAssignedToTeam = this.user.teams.some(team => team.id === this.lead.teamId)
  }

  removeMemberFromTeam(teamId: string) {
    this.store.dispatch(AuthUserRemoveTeamRequestStarted({teamId, userId: this.user.userId})
  )
  }
}

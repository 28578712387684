import { inject, NgModule } from '@angular/core';
import { Routes, RouterModule, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthGuard } from '@shared/guards/auth.guard';
import { ClientAuthGuard } from '@shared/guards/client-auth.guard';
import { PpvGuard } from '@shared/guards/ppv.guard';
import { ContextService } from '@core/services/context.service';
import { LeadService } from '@core/services/lead.service';
import { BudgetTableComponent } from './pages/project/budget-table/budget-table.component';
import { DocumentationGuard } from '@shared/guards/documentation.guards';
import { BudgetsGuard } from '@shared/guards/budgets.guard';
import { BudgetService } from '@core/services/budget.service';
import { HomeGuard } from '@shared/guards/home.guard';
import { InvoiceGuard } from '@shared/guards/invoice.guard';
import { CertificationGuard } from '@shared/guards/certification.guard';
import { PdfViewerComponent } from './pages/pdf-viewer/pdf-viewer.component';


const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/authentication/authentication.module').then(
        (page) => page.AuthenticationModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/authentication/authentication.module').then(
        (page) => page.AuthenticationModule,
      ),
  },
  {
    path: 'admin',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
    loadChildren: () =>
      import('./@administration/administration.module').then(
        (page) => page.AdministrationModule,
      ),
  },
  {
    path: 'budget',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
    loadChildren: () =>
      import('./pages/budget/budget.module').then((page) => page.BudgetModule),
  },
  {
    path: 'budgets/:leadID',
    component: BudgetTableComponent,
  },
  {
    path: 'budgets',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(BudgetsGuard).canActivate(next, state)],
    loadChildren: () =>
      import('./pages/budget/budget.module').then((page) => page.BudgetModule),
  },
  {
    path:'home',
    canActivate: [() => inject(HomeGuard).canActivate()],
    loadChildren: () =>
      import('./pages/home/home.module').then((page) => page.HomeModule),
  },
  {
    path: 'project',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
    loadChildren: () =>
      import('./pages/project/project.module').then(
        (page) => page.ProjectModule,
      ),
  },
  {
    path: 'tracking',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state),
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(ContextService).canActivate(next, state)
    ],
    loadChildren: () =>
      import('./pages/tracking/tracking.module').then(
        (page) => page.TrackingModule,
      ),
  },
  {
    path: 'invoices',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state),
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(InvoiceGuard).canActivate(next, state)
    ],
    loadChildren: () =>
      import('./pages/invoices-tables/invoices-tables.module').then(
        (page) => page.InvoicesTableModule,
      ),
  },
  {
    path: 'certification',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
    loadChildren: () =>
      import('./pages/certification/certification.module').then(
        (page) => page.CertificationModule,
      ),
  },
  {
    path: 'pdf-viewer',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
    component: PdfViewerComponent
  },
  {
    path: 'pdf-viewer/:pdfID/:projectId/:docType',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
    component: PdfViewerComponent
  },
  {
    path: 'estimation',
    loadChildren: () =>
      import('./pages/estimation/estimation.module').then(
        (page) => page.EstimationModule,
      ),
  },
  {
    path: 'client/:clientToken/budget/:budgetID',
    loadChildren: () =>
      import('./pages/budget/budget.module').then((page) => page.BudgetModule),
  },
  {
    path: 'client/:clientToken/:budgetID',
    loadChildren: () =>
      import('./pages/budget/budget.module').then((page) => page.BudgetModule),
  },
  {
    path: 'revision/:revisionToken/landing/:agentID',
    loadChildren: () =>
      import('./pages/landing/landing.module').then(
        (page) => page.LandingModule,
      ),
  },
  {
    path: 'revision/:revisionToken',
    loadChildren: () =>
      import('./pages/profile/profile.module').then(
        (page) => page.ProfileModule,
      ),
  },
  {
    path: 'on-boarding',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
    loadChildren: () =>
      import('./pages/agent-onboarding/agent-onboarding.module').then(
        (page) => page.AgentOnBoardingModule,
      ),
  },
  {
    path: 'events',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
    loadChildren: () =>
      import('./pages/events/events.module').then((page) => page.EventsModule),
  },
  {
    path: 'profile',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
    loadChildren: () =>
      import('./pages/profile/profile.module').then(
        (page) => page.ProfileModule,
      ),
  },
  {
    path: 'budget-edition',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state),
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(BudgetService).canActivate(next, state)
    ],
    loadChildren: () =>
      import('./pages/budget-edition/budget-edition.module').then(
        (page) => page.BudgetEditionModule,
      ),
  },
  {
    path: 'projects',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state),
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(ContextService).canActivate(next, state)
    ],
    loadChildren: () =>
      import('./pages/project-grid/project-grid.module').then(
        (page) => page.ProjectGridModule,
      ),
  },
  {
    path: 'certifications',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(CertificationGuard).canActivate(next, state)],
    loadChildren: () =>
      import('./pages/certification-table/certification-table.module').then(
        (page) => page.CertificationTableModule,
      ),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./pages/payment-response/payment-response.module').then(
        (page) => page.PaymentResponseModule,
      ),
  },
  {
    path: 'budget-list',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
    loadChildren: () =>
      import('./pages/budget-list/budget-list.module').then(
        (page) => page.BudgetListModule,
      ),
  },
  {
    path: 'tracking-list',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state),
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(ContextService).canActivate(next, state)
    ],
    loadChildren: () =>
      import('./pages/project-tracking/project-tracking.module').then(
        (page) => page.ProjectTrackingModule,
      ),
  },
  {
    path: 'landing/:agentID',
    loadChildren: () =>
      import('./pages/landing/landing.module').then(
        (page) => page.LandingModule,
      ),
  },
  {
    path: 'templates',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state),
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(ContextService).canActivate(next, state)
    ],
    loadChildren: () =>
      import('./pages/templates/templates.module').then(
        (page) => page.TemplatesModule,
      ),
  },
  {
    path: 'documentation',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(DocumentationGuard).canActivate(next, state)],
    loadChildren: () =>
    import('./pages/documentation/documentation.module').then(
      (page) => page.DocumentationModule,
    ),
  },
  {
    path: 'documentation2',
    loadChildren: () =>
    import('./pages/documentation2/documentation2.module').then(
      (page) => page.Documentation2Module,
    ),
  },
  {
    path: 'templates-quiz',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
    loadChildren: () =>
      import('./pages/templates-quiz/templates-quiz.module').then(
        (page) => page.TemplatesQuizModule,
      ),
  },

  {
    path: 'quizzes',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
    loadChildren: () =>
      import('./@quiz-configurator/quiz-configurator.module').then(
        (page) => page.QuizConfiguratorModule,
      ),
  },
  {
    // Prices calculator
    path: 'estimator',
    loadChildren: () =>
      import('./pages/quiz/quiz.module').then((page) => page.QuizModule),
  },
  {
    path: 'budgets/:leadID',
    component: BudgetTableComponent,
  },
  {
    path: 'designphase',
    redirectTo: '/logged-client/designphase',
  },
  {
    path: 'logged-client',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(ClientAuthGuard).canActivate(next, state)],
    children: [
      {
        path: 'projects',
        canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(PpvGuard).canActivate(next, state),
          (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(ContextService).canActivate(next, state)
        ],
        loadChildren: () =>
          import('./pages/projects-client/projects-client.module').then(
            (page) => page.ProjectsClientModule,
          ),
      },
      {
        path: 'certifications',
        loadChildren: () =>
          import('./pages/certification-table/certification-table.module').then(
            (page) => page.CertificationTableModule,
          ),
      },
      {
        path: 'project',
        canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(ClientAuthGuard).canActivate(next, state),
          (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(PpvGuard).canActivate(next, state)
        ],
        loadChildren: () =>
          import('./pages/project-client/project-client.module').then(
            (page) => page.ProjectClientModule,
          ),
      },
      {
        path: 'budget/:budgetID',
        canActivate:[(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(BudgetService).canActivate(next, state)],
        loadChildren: () =>
          import('./pages/budget/budget.module').then(
            (page) => page.BudgetModule,
          ),

      },
      {
        path: 'budgets/:leadID',
        canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(ClientAuthGuard).canActivate(next, state),
          (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(LeadService).canActivate(next, state)
        ],
        component: BudgetTableComponent,
      },

      {
        // Prices calculator
        path: 'quiz',
        loadChildren: () =>
          import('./pages/quiz/quiz.module').then((page) => page.QuizModule),
      },
      {
        path: 'howto',
        canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
        loadChildren: () =>
          import('./pages/how-to/how-to.module').then(
            (page) => page.HowToModule,
          ),
      },
      {
        path: 'designphase',
        canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
        loadChildren: () =>
          import('./pages/design-phase/design-phase.module').then(
            (page) => page.DesignPhaseModule,
          ),
      },
      {
        path: 'metrics',
        canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
        loadChildren: () =>
          import('./pages/metrics/metrics.module').then(
            (page) => page.MetricsModule,
          ),
      },
      {
        path: 'estimation',
        loadChildren: () =>
          import('./pages/estimation/estimation.module').then(
            (page) => page.EstimationModule,
          ),
      },
      {
        path: 'tracking',
        canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(ClientAuthGuard).canActivate(next, state)],
        loadChildren: () =>
          import('./pages/tracking/tracking.module').then(
            (page) => page.TrackingModule,
          ),
      },
      {
        path: 'invoices',
        loadChildren: () =>
          import('./pages/invoices-tables/invoices-tables.module').then(
            (page) => page.InvoicesTableModule,
          ),
      },
      {
        path: 'pdf-viewer/:pdfID/:projectId/:docType',
        canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
        component: PdfViewerComponent
      },
      {
        path: 'certification',
        canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(ClientAuthGuard).canActivate(next, state)],
        loadChildren: () =>
          import('./pages/certification/certification.module').then(
            (page) => page.CertificationModule,
          ),
      },
      {
        path: 'documentation/:projectId',
        loadChildren: () =>
          import('./pages/documentation/documentation.module').then(
            (page) => page.DocumentationModule,
          ),
      }
      ,{
        path:'home',
        canActivate:[],
        loadChildren: () =>
          import('./pages/home/home.module').then((page) => page.HomeModule),
      },
      {
        path: 'documentation2',
        loadChildren: () =>
        import('./pages/documentation2/documentation2.module').then(
          (page) => page.Documentation2Module,
        ),
      },
    ],
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(
        (page) => page.NotFoundModule,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    enableTracing: false
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

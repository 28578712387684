<ng-container *ngIf="!showCancelView; else cancelView">
  <div class="header">
    <div class="left">
      <div class="nav">
        <mat-icon
          *ngIf="steps.actualStep > 1"
          svgIcon="24px-arrow"
          class="back-step"
          (click)="goToBackStep()"
        ></mat-icon>
        <mat-icon svgIcon="24px-arrow" (click)="goToNextStep()"></mat-icon>
      </div>

      <ng-container *ngIf="viewMode === 'web'">
        <mat-icon svgIcon="illustration-createProject"></mat-icon>
        <span>{{ title | translate }}</span>
      </ng-container>
    </div>

    <button mat-icon-button (click)="toggleCancelView()">
      <mat-icon svgIcon="24px-close"></mat-icon>
    </button>
  </div>

  <div class="mobile-title" *ngIf="viewMode === 'handset'">
    <div class="up">
      <mat-icon svgIcon="illustration-createProject"></mat-icon>
      <span>{{ title | translate }}</span>
    </div>

    <div class="step-mark">
      <div class="step completed"></div>
      <div class="step" [ngClass]="{ completed: steps.step2.completed }"></div>
      <div class="step" [ngClass]="{ completed: steps.step3.completed }"></div>
      <div class="step" [ngClass]="{ completed: steps.step4.completed }"></div>
    </div>
  </div>

  <form [formGroup]="newProjectForm">
    <div class="step-mark" *ngIf="viewMode === 'web'">
      <div class="step completed"></div>
      <div class="step" [ngClass]="{ completed: steps.step2.completed }"></div>
      <div class="step" [ngClass]="{ completed: steps.step3.completed }"></div>
      <div class="step" [ngClass]="{ completed: steps.step4.completed }"></div>
    </div>

    <section *ngIf="steps.actualStep === 1">
      <div class="step-title">{{ 'about-project' | translate }}</div>

      <div class="project-selector">
        <ng-container
          *ngFor="let projectTypeKey of getObjectKeys(projectTypes)"
        >
          <div
            class="project-type"
            (click)="selectProjectType(projectTypeKey)"
            *ngIf="projectTypeKey !== 'others'"
          >
            <div
              class="icon-selector"
              [ngClass]="{
                'icon-active':
                  projectTypeKey ===
                  newProjectForm.get('projectType').value.slug
              }"
            >
              <mat-icon
                [svgIcon]="projectTypes[projectTypeKey].icon"
              ></mat-icon>
              <div class="icon-title" *ngIf="viewMode === 'handset'">
                {{ projectTypes[projectTypeKey].slug | translate }}
              </div>
            </div>
            <div class="icon-title" *ngIf="viewMode === 'web'">
              {{ projectTypes[projectTypeKey].slug | translate }}
            </div>
          </div>
        </ng-container>
      </div>

      <div class="phrase">{{ 'select-most-popular' | translate }}</div>

      <div class="custom-input text-xs">
        <p>
          {{ 'opportunity-type' | translate
          }}{{ requiredValidator('projectType') ? '*' : '' }}
        </p>

        <mat-form-field appearance="outline" color="primary">
          <input
            matInput
            placeholder="{{ 'opportunity-type' | translate }}"
            formControlName="projectType"
            data-cy="project-type"
            [matAutocomplete]="auto"
          />

          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <ng-container
              *ngFor="let projectTypeKey of getObjectKeys(projectTypes)"
            >
              <mat-option
                *ngIf="projectTypeKey !== 'others'"
                [value]="projectTypes[projectTypeKey]"
              >
                {{ projectTypes[projectTypeKey].translation }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>

          <mat-error
            *ngIf="newProjectForm.get('projectType').hasError('required')"
            [innerHTML]="'field-required' | translate"
            data-cy="project-required"
          >
          </mat-error>
        </mat-form-field>
      </div>

      <div class="custom-input text-xs">
        <p>{{ 'project-description' | translate }}</p>

        <mat-form-field appearance="outline" color="primary">
          <textarea
            rows="6"
            matInput
            [placeholder]="'project-description' | translate"
            formControlName="description"
            data-cy="project-description"
          >
          </textarea>
          <mat-error
            *ngIf="newProjectForm.get('description').hasError('maxlength')"
            [innerHTML]="'description-maxlength' | translate"
          >
          </mat-error>
        </mat-form-field>
      </div>
    </section>

    <section *ngIf="steps.actualStep === 2" formGroupName="clientContact">
      <div class="step-title">{{ 'client-data' | translate }}</div>

      <div class="custom-input text-xs">
        <p>
          {{ 'name' | translate
          }}{{ requiredValidator('clientName') ? '*' : '' }}
        </p>

        <mat-form-field appearance="outline" color="primary">
          <input
            matInput
            formControlName="clientName"
            placeholder="{{ 'name' | translate }}{{
              requiredValidator('clientName') ? '*' : ''
            }}"
            data-cy="project-client-name"
          />
          <mat-error
            *ngIf="
              newProjectForm
                .get('clientContact')
                .get('clientName')
                .hasError('required')
            "
            [innerHTML]="'field-required' | translate"
            data-cy="name-required"
          >
          </mat-error>
        </mat-form-field>
      </div>

      <div class="custom-input text-xs">
        <p>
          {{ 'email' | translate
          }}{{ requiredValidator('clientEmail') ? '*' : '' }}
        </p>

        <mat-form-field appearance="outline" color="primary">
          <input
            matInput
            formControlName="clientEmail"
            placeholder="{{ 'email' | translate }}{{
              requiredValidator('clientEmail') ? '*' : ''
            }}"
            data-cy="project-client-email"
          />
          <mat-error
            *ngIf="
              newProjectForm
                .get('clientContact')
                .get('clientEmail')
                .hasError('required')
            "
            [innerHTML]="'field-required' | translate"
          >
          </mat-error>
        </mat-form-field>
      </div>

      <div class="custom-input text-xs">
        <p>
          {{ 'phone-number' | translate
          }}{{ requiredValidator('clientPhoneNumber') ? '*' : '' }}
        </p>

        <mat-form-field appearance="outline" color="primary">
          <input
            matInput
            appInputPhoneFormat
            formControlName="clientPhoneNumber"
            placeholder="{{ 'phone-number' | translate }}{{
              requiredValidator('clientPhoneNumber') ? '*' : ''
            }}"
            data-cy="project-client-phone"
          />
          <mat-error
            *ngIf="
              newProjectForm
                .get('clientContact')
                .get('clientPhoneNumber')
                .hasError('required')
            "
            [innerHTML]="'field-required' | translate"
          >
          </mat-error>
          <mat-error
            class="password-required"
            *ngIf="
              newProjectForm
                .get('clientContact')
                .get('clientPhoneNumber')
                .hasError('minlength')
            "
            [innerHTML]="'phone-minlength-error' | translate"
          >
          </mat-error>
          <mat-error
            class="password-required"
            *ngIf="
              newProjectForm
                .get('clientContact')
                .get('clientPhoneNumber')
                .hasError('maxlength')
            "
            [innerHTML]="'phone-maxlength-error' | translate"
          >
          </mat-error>
        </mat-form-field>
      </div>
    </section>

    <section *ngIf="steps.actualStep === 3" formGroupName="clientAddress">
      <div class="step-title">{{ 'project-location' | translate }}</div>

      <div class="custom-input text-xs">
        <p>
          {{ 'address' | translate
          }}{{ requiredValidator('clientAddress1') ? '*' : '' }}
        </p>

        <mat-form-field appearance="outline" color="primary">
          <input
            matInput
            formControlName="clientAddress1"
            placeholder="{{ 'address' | translate }}{{
              requiredValidator('clientAddress1') ? '*' : ''
            }}"
            data-cy="project-client-address"
          />
          <mat-error
            *ngIf="
              newProjectForm
                .get('clientAddress')
                .get('clientAddress1')
                .hasError('required')
            "
            [innerHTML]="'field-required' | translate"
            data-cy="address-required"
          >
          </mat-error>
        </mat-form-field>
      </div>

      <div class="custom-input text-xs">
        <p>
          {{ 'address' | translate }}2{{
            requiredValidator('clientAddress2') ? '*' : ''
          }}
        </p>

        <mat-form-field appearance="outline" color="primary">
          <input
            matInput
            formControlName="clientAddress2"
            placeholder="{{ 'address' | translate }}{{
              requiredValidator('clientAddress2') ? '*' : ''
            }}"
            data-cy="project-client-address2"
          />
          <mat-error
            *ngIf="
              newProjectForm
                .get('clientAddress')
                .get('clientAddress2')
                .hasError('required')
            "
            [innerHTML]="'field-required' | translate"
          >
          </mat-error>
        </mat-form-field>
      </div>

      <div class="pair-fields">
        <div class="custom-input text-xs">
          <p>
            {{ 'city' | translate
            }}{{ requiredValidator('clientCity') ? '*' : '' }}
          </p>

          <mat-form-field appearance="outline" color="primary">
            <input
              matInput
              formControlName="clientCity"
              placeholder="{{ 'city' | translate }}{{
                requiredValidator('clientCity') ? '*' : ''
              }}"
              data-cy="project-client-city"
            />
            <mat-error
              *ngIf="
                newProjectForm
                  .get('clientAddress')
                  .get('clientCity')
                  .hasError('required')
              "
              [innerHTML]="'field-required' | translate"
            >
            </mat-error>
          </mat-form-field>
        </div>

        <div class="custom-input text-xs">
          <p>
            {{ 'postal-code' | translate
            }}{{ requiredValidator('clientZipCode') ? '*' : '' }}
          </p>

          <mat-form-field appearance="outline" color="primary">
            <input
              matInput
              formControlName="clientZipCode"
              placeholder="{{ 'postal-code' | translate }}{{
                requiredValidator('clientZipCode') ? '*' : ''
              }}"
              data-cy="project-client-zip-code"
            />
            <mat-error
              *ngIf="
                newProjectForm
                  .get('clientAddress')
                  .get('clientZipCode')
                  .hasError('required')
              "
              [innerHTML]="'field-required' | translate"
            >
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="pair-fields">
        <div class="custom-input text-xs">
          <p>
            {{ 'region' | translate
            }}{{ requiredValidator('clientRegion') ? '*' : '' }}
          </p>

          <mat-form-field appearance="outline" color="primary">
            <input
              matInput
              formControlName="clientRegion"
              placeholder="{{ 'region' | translate }}{{
                requiredValidator('clientRegion') ? '*' : ''
              }}"
              data-cy="project-client-region"
            />
            <mat-error
              *ngIf="
                newProjectForm
                  .get('clientAddress')
                  .get('clientRegion')
                  .hasError('required')
              "
              [innerHTML]="'field-required' | translate"
            >
            </mat-error>
          </mat-form-field>
        </div>

        <div class="custom-input text-xs">
          <p>
            {{ 'country' | translate
            }}{{ requiredValidator('clientCountry') ? '*' : '' }}
          </p>

          <mat-form-field appearance="outline" color="primary">
            <input
              matInput
              formControlName="clientCountry"
              placeholder="{{ 'country' | translate }}{{
                requiredValidator('clientCountry') ? '*' : ''
              }}"
              data-cy="project-client-country"
            />
            <mat-error
              *ngIf="
                newProjectForm
                  .get('clientAddress')
                  .get('clientCountry')
                  .hasError('required')
              "
              [innerHTML]="'field-required' | translate"
            >
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </section>

    <section *ngIf="steps.actualStep === 4">
      <div class="step-title">{{ 'project-state-question' | translate }}</div>
      <div class="filter-row">
        <app-project-filter
          [active]="projectState.value === 'new'"
          (click)="setProjectState('new')"
          [count]="null"
          [label]="'new' | translate"
          data-cy="state-new"
        ></app-project-filter>
        <app-project-filter
          [active]="projectState.value === 'pendingOfVisit'"
          (click)="setProjectState('pendingOfVisit')"
          [count]="null"
          [label]="'filter-pending-visit' | translate"
          data-cy="state-visit"
        ></app-project-filter>
        <app-project-filter
          [active]="projectState.value === 'pendingOfBudget'"
          (click)="setProjectState('pendingOfBudget')"
          [count]="null"
          [label]="'filter-pending-budget' | translate"
          data-cy="state-budget"
        ></app-project-filter>
        <app-project-filter
          [active]="projectState.value === 'negotiation'"
          (click)="setProjectState('negotiation')"
          [count]="null"
          [label]="'filter-negotiation' | translate"
          data-cy="state-negotiation"
        ></app-project-filter>
        <app-project-filter
          [active]="projectState.value === 'hired'"
          (click)="setProjectState('hired')"
          [count]="null"
          [label]="'hired' | translate"
          data-cy="state-hired"
        ></app-project-filter>
      </div>
    </section>

    <div class="submit">
      <button
        mat-flat-button
        data-cy="continue-button"
        color="accent"
        (click)="saveStep()"
        *ngIf="!data.lead; else editProject"
        [disabled]="disableSubmit"
      >
        {{
          (steps.actualStep === 4 ? 'create-project' : 'continue') | translate
        }}
      </button>

      <ng-template #editProject>
        <button
          mat-flat-button
          color="accent"
          (click)="saveStep()"
          [disabled]="disableSubmit"
        >
          {{ (steps.actualStep === 4 ? 'save' : 'continue') | translate }}
        </button>
      </ng-template>
    </div>
  </form>
</ng-container>

<ng-template #cancelView>
  <section class="cancel-view">
    <span>¿Cancelar nuevo proyecto?</span>
    <p>
      Recuerda que con nuestro gestor de proyectos podrás controlar todos tus
      proyectos, presupuestos y facturas. ¡Completamente gratis!
    </p>

    <div class="cancel-actions">
      <button mat-stroked-button color="accent" mat-dialog-close>
        Cancelar creación
      </button>
      <button mat-flat-button color="accent" (click)="toggleCancelView()">
        Continuar creación
      </button>
    </div>
  </section>
</ng-template>

/* eslint-disable */
import { Agent, Person, Action } from './index';

declare var Object: any;
export interface PositionInterface {
  role: string;
  name: string;
  professionalProfile?: string;
  avatar?: string;
  id?: number;
  agentId?: number;
  personId?: number;
  modelActive?: any;
  agent?: Agent;
  person?: Person;
  actions?: Action[];
  invited?: boolean;
}

export class Position implements PositionInterface {
  'role': string;
  'name': string;
  'professionalProfile': string;
  'avatar': string;
  'id': number;
  'agentId': number;
  'personId': number;
  'modelActive': any;
  agent?: Agent;
  person?: Person;
  actions?: Action[];
  invited?: boolean;
  constructor(data?: PositionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Position`.
   */
  public static getModelName() {
    return 'Position';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Position for dynamic purposes.
   **/
  public static factory(data: PositionInterface): Position {
    return new Position(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Position',
      plural: 'Positions',
      path: 'Positions',
      idName: 'id',
      properties: {
        role: {
          name: 'role',
          type: 'string',
          default: 'owner',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        professionalProfile: {
          name: 'professionalProfile',
          type: 'string',
        },
        avatar: {
          name: 'avatar',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'number',
        },
        agentId: {
          name: 'agentId',
          type: 'number',
        },
        personId: {
          name: 'personId',
          type: 'number',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
      },
      relations: {
        agent: {
          name: 'agent',
          type: 'Agent',
          model: 'Agent',
          relationType: 'belongsTo',
          keyFrom: 'agentId',
          keyTo: 'id',
        },
        person: {
          name: 'person',
          type: 'Person',
          model: 'Person',
          relationType: 'belongsTo',
          keyFrom: 'personId',
          keyTo: 'id',
        },
        actions: {
          name: 'actions',
          type: 'Action[]',
          model: 'Action',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'referenceId',
        },
      },
    };
  }
}

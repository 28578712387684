import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BudgetEditionEffects } from './budget-edition.effects';
import { reducer as BudgetsReducer } from '../reducers/budget.reducer';
import { BudgetEffects } from '../effects/budget.effects';
@NgModule({
  imports: [
    StoreModule.forFeature('budgets', BudgetsReducer ),
    EffectsModule.forFeature([BudgetEffects]),
  ],
})
export class BudgetsStoreModule {}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LeadInterface, Reason } from '@core/sdk';
import environment from '@environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReasonService {
  constructor(
    private httpClient: HttpClient,
  ) { }

  getReasons(filter: any): Observable<Reason[]> {
    const url = `${environment.apiBaseUrl}/api/Reasons`
    const filterString = JSON.stringify(filter);
    
    const headers = new HttpHeaders({
      'filter': filterString
    });

    return this.httpClient.get<Reason[]>(url, { headers });
  }

  sendDateModifiedEmail(actionId: string){
     const url = `${environment.apiBaseUrl}/api/Reasons/send-email`
     const body = {
      actionId
     }

     return this.httpClient.post<boolean>(url, body);
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PersonProperty } from '@core/sdk/models/PersonProperty';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { debounceTime, switchMap, take } from 'rxjs/operators';
import environment from '../../../environments/environment';
import { S3Service } from './s3.service';

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  constructor(
    private s3Service: S3Service,
    private httpClient: HttpClient,
    private store: Store<{
      user: any;
    }>
  ) {}

  createLogoDocument(documentID, file) {
    return this.s3Service
      .s3uploadfile(file, documentID)
      .pipe(switchMap((s3Result) => of(s3Result)));
  }

  isEmailInUse(email) {
    const url = `${environment.apiBaseUrl}/api/People/isEmailInUse`;
    const body = { email };

    return this.httpClient.post<boolean>(url, body);
  }

  async getPersonProperties() {
    return (await this.store.select('user').pipe(take(1)).toPromise()).personProperties.termsAndConditions;
  }
}

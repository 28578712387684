import {
  Component,
  OnInit,
  Input,
  Self,
  Optional,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { S3Service } from '@core/services/s3.service';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { NotificationsService } from '@core/services/notifications.service';
import { saveAs } from 'file-saver-es';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { selectUrl } from 'src/app/store/router.selectors';
import { CONTEXTS } from '@core/constants';
import * as Sentry from "@sentry/browser";
import { TranslateService } from '@ngx-translate/core';
import { MixpanelService } from '@core/services/mixpanel.service';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent
  implements OnInit, ControlValueAccessor, OnDestroy {
  private _image: string;
  private _onChange: Function;
  private _onTouched: Function;

  safeImage: SafeResourceUrl;
  type: string;
  emptyAvatarPath = 'assets/img/avatar.png';
  changeIcons = false;
  fileType$ = new BehaviorSubject<string>('');
  fileName$ = new BehaviorSubject<string>('');
  fileUrl$ = new BehaviorSubject<string>('');
  fileUrl = '';
  frontFile: any;
  showRemoveButton = true;

  @Input() viewMode: 'default' | 'mini' | 'mini-list' | 'mobile' | 'add' = 'default';
  @Input() imagePreviewTitle = 'company-logo';
  @Input() document: any;

  @Output() removeFileEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatedFileEmitter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('miniMode') previewImage: ElementRef<any>;

  @Input()
  get image(): any {
    return this._image;
  }

  set image(image: any) {
    if (this._onChange) {
      this._onChange(image);
    }

    this._image = image;
  }

  unsubscriber$ = new Subject<void>();

  constructor(
    @Self() @Optional() public controlDir: NgControl,
    private sanitizer: DomSanitizer,
    private s3Service: S3Service,
    private notificationsService: NotificationsService,
    private store: Store<{ context: any; router: any }>,
    private translateService: TranslateService,
    private mixpanelService: MixpanelService
  ) {
    if (controlDir) {
      controlDir.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.initializeImagePreview();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  private initializeImagePreview(file = null) {
    this.handleCloseButtonView();

    if (file) {
      this.getBase64Image(file);

      return;
    }

    this.image = this.document ?? this.image ?? false;

    if (this.image?.versions?.length > 0) {
      const [file] = this.image.versions;

      if (file) {
        this.fileName$.next(file.fileName);

        const mime = file.mime.split('/');

        if (mime[0] === 'image') {
          this.fileType$.next('image');
          this.fileUrl$.next(file.fileUrl);
          this.fileUrl = file.fileUrl;

          return;
        }

        if (mime[1] === 'pdf') {
          this.fileType$.next('pdf');
          this.fileUrl$.next(file.fileUrl);
          this.fileUrl = file.fileUrl;
          return;
        }


        const fileNameSplitted = file.fileName.split('.');
        const fileType = fileNameSplitted.pop();

        this.fileType$.next(fileType.toLowerCase());
        return;
      }
    }
  }

  private handleCloseButtonView() {
    combineLatest([
      this.store.select(selectUrl),
      this.store.select('context')
    ])
      .pipe(
        takeUntil(this.unsubscriber$),
        filter(([url, { value: context }]) => {
          return this.document?.ownerType === 'Agent' && context === CONTEXTS.LOGIN_CLIENT;
        }
        ),
      )
      .subscribe(() => (this.showRemoveButton = false));
  }

  private getBase64Image(file) {
    const fileReader = new FileReader();

    fileReader.addEventListener(
      'load',
      function (e) {
        this.fileUrl = e.target.result;
      }.bind(this),
    );

    fileReader.readAsDataURL(file);
  }

  writeValue(image: string) {
    this._image = image;
    this.safeImage = this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }

  registerOnChange(fn: Function) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  private isExtensionValid(fileName) {
    if (fileName.includes('.heic') || fileName.includes('.heif')) {
      this.notificationsService.showError('Formato HEIC no aceptado.');

      return;
    }

    if (fileName.includes('.psd')) {
      this.notificationsService.showError('Formato PSD no aceptado.');

      return;
    }

    if (fileName.includes('.gif')) {
      this.notificationsService.showError('Formato GIF no aceptado.');

      return;
    }

    return true;
  }

  handleFileSelect(evt) {
    const files = evt.target.files;
    const file: File = files.length > 0 ? files.item(0) : null;

    if (!file) {
      return;
    }

    if (!this.isExtensionValid(file.name)) {
      return;
    }

    const fileSize = Math.round(file.size / 1024);

    if (fileSize > 70) {
      this.notificationsService.showError(
        'El tamaño máximo para la subida de imágenes es de 70KB.',
      );

      return;
    }

    if (!this.image.id) {
      // Is new team member
      this.updatedFileEmitter.emit(file);
      this.initializeImagePreview(file);

      return;
    }

    this.s3Service
      .s3uploadfile(file, this.image.id)
      .subscribe((s3FileResult) => {
        const versions = [s3FileResult];

        const documentCopy = {
          ...this.image,
          versions,
        };

        this.image = documentCopy;
        this.update();
      });
  }

  removeFile(event: any) {
    event.stopPropagation();

    const [file] = this.document.versions;
    this.removeFileEmitter.emit(file);
  }

  private update() {
    this.initializeImagePreview();
    this.updatedFileEmitter.emit(this.image);
  }

  private downloadFile(fileBlob, filename) {
    const filenameArray = filename.split('.');
    const extension = filenameArray[filenameArray.length - 1].toLowerCase();

    let url;
    const element = document.createElement('a');

    if (extension === 'pdf') {
      // pdf previsualization
      url = window.URL.createObjectURL(new Blob([fileBlob], { type: 'application/pdf' }));
      this.mixpanelService.eventEmitter('client-opened-document', { type: 'pdf' });
      element.setAttribute('target', '_blank');
    } else {
      url = window.URL.createObjectURL(new Blob([fileBlob]));
      element.setAttribute('download', filename);
    }

    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    if (isIOS) {
      saveAs(url, filename);

      return;
    }

    element.setAttribute('href', url);

    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  getFile(event) {
    if (this.fileType$.getValue() === 'image') {
      // Should be open as carousel by father component
      return;
    }

    event.stopPropagation();

    if (!this.document && !this.image) {
      return;
    }

    const [{ fileName, file: fileID, fileUrl }] = this.document.versions;


    const fileNameSplitted = fileName.split('.');
    const fileExtension = fileNameSplitted.pop();
    const fileNameDownload = `${fileID}.${fileExtension}`;

    this.s3Service.getFileUrlOld(fileNameDownload, this.document.id).subscribe(
      (downloadUrl) => {
        this.downloadFile(downloadUrl, fileName);
      },
      (error) => {
        Sentry.withScope((scope) => {
          scope.setExtras({
            error,
          });
          Sentry.captureException(new Error('DocumentationDownloadError'), {
            tags: {
              document_download: 'true',
            },
          });
        });
        this.notificationsService.showError(this.translateService.instant('error-downloading-file'))
      }
    );
    
  }
}

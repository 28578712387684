import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-notifications',
  templateUrl: './header-notifications.component.html',
  styleUrls: ['./header-notifications.component.scss'],
})
export class HeaderNotificationsComponent implements OnInit {
  notificationBadget = 0;
  calendarBadget = 0;

  constructor() {}

  ngOnInit() {}
}

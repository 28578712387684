<ng-container>
  <div class="avatar" data-cy="cubicup-staff">
    <img [src]="staff?.avatar" alt="" />
    <div class="text">
      <span>{{ 'contact-with-agent' | translate }}</span>
      <span>{{ staff?.name }}</span>
    </div>
  </div>

  <mat-icon
    svgIcon="24px-customerService"
    [ngClass]="{ 'new-messages': hasNewMessages }"
  ></mat-icon>
</ng-container>

<!-- <ng-template #mobileView>
  <div class="avatar">
    <img [src]="staff?.avatar" alt="" />
  </div>
</ng-template> -->

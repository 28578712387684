<p>
  {{ 'general-terms-and-conditions' | translate }}
  <mat-icon
    svgIcon="24px-info"
    [matTooltip]="'general-terms-and-conditions-tooltip' | translate"
    matTooltipPosition="above"
  >
  </mat-icon>
  <span class="cdk-visually-hidden">
    No es necesario que vuelvas a indicar las condiciones de pago
  </span>
</p>
<div class="custom-input text-sm description">

  <mat-form-field appearance="outline" color="primary">
    <textarea
      rows="4"
      class="text-sm"
      matInput
      type="text"
      [formControl]="termsForm"
      (input) = "onInputChange($event)"
    ></textarea>
    <mat-error>{{ 'error-name-missing' | translate }}</mat-error>
  </mat-form-field>
</div>
<section class="images">
  <div class="header">
    <span class="title">{{ 'images' | translate }} ({{ images.length + videos.length }}) </span>
    <span class="subtitle">
      {{ options.imagesSubtitleSlug | translate }}
    </span>
  </div>
  <div class="external-wrapper">
    <div class="upload-button-wrapper">
      <button
      *ngIf="context === 'login-client' || options.uploadImages"
      (click)="fileInput.click()"
      [matTooltip]="'add-video-image' | translate"
      matTooltipPosition="above"
      mat-stroked-button
      class="upload-button"
      mat-flat-button
      color="primary"
      data-cy="upload-image"
    >
      {{ 'upload-image' | translate }}
      <mat-icon>add</mat-icon>
      <input
      hidden
      (change)="handleDrop($event, 'images')"
      #fileInput
      type="file"
      multiple
      id="file"
    />
    </button>
      <p>{{ 'ctrl+click' | translate }}</p>
    </div>
    <div *ngIf="images.length || videos.length" class="gallery-container">
      <cub-media-gallery
        [images]="images"
        [videos]="videos"
        [viewMode]="viewMode"
        [context]="context"
        (removeFileEmitter)="removeImage($event)"
      ></cub-media-gallery>
      </div>
    </div>
</section>

<section class="files flex flex-col max-h-[400px] overflow-y-scroll">
  <div class="header">
    <span class="title">
      {{ 'project-documentation' | translate }} ({{ files.length }})
    </span>
    <span class="subtitle">
      {{ options.filesSubtitleSlug | translate }}
    </span>
  </div>

  <div class="external-wrapper">
    <div class="upload-button-wrapper flex flex-col items-start">
      <div class="flex items-center">
        <button
        *ngIf="context === 'login-client' || options.uploadFiles"
        [matTooltip]="'upload-document' | translate"
        matTooltipPosition="above"
        mat-stroked-button
        (click)="fileInput.click()"
        class="upload-button"
        data-cy="upload-file"
        mat-flat-button
        color="primary"
        >
        {{ 'upload-file' | translate }}
        <mat-icon>file_upload</mat-icon>
        <input
        hidden
        (change)="handleDrop($event, 'documents')"
        #fileInput
        type="file"
        multiple
        id="file"
        />
      </button>
        
        
      <button class="h-8 px-1 mx-2 min-w-32 text-center text-white text-xs bg-red-500 hover:bg-red-600 rounded" (click)="toggleDeleteDocumentMode()">
        {{ deleteButtonMessage | translate }}
      </button>
    </div>
    <p>{{ 'ctrl+click' | translate }}</p>
  </div>
  
    <div class="grid md:grid-cols-3 grid-cols-2 gap-4 p-4 touch-pan-y">
      <div *ngFor="let file of files; index as fileIndex" class="raise flex items-center justify-between rounded-md"
      [ngClass]="{'bg-red-500' : deleteDocumentMode,'bg-[#F1F3F5]' : !deleteDocumentMode}">
        <app-image-preview
        [ngClass]="{'w-[92.5%]': deleteDocumentMode}"
        class="raise w-full animate duration-200 h-full"
        [viewMode]="'mini-list'"
        [document]="file"
        (removeFileEmitter)="removeFile(fileIndex)"
        [matTooltip]="file.versions[0].fileName"
        matTooltipPosition="above"
        >
        </app-image-preview>
        <div *ngIf="deleteDocumentMode" class="w-[7.5%] h-full flex justify-center items-center">
          <input
          type="checkbox"
          (change)="toggleFileSelection($event.target.checked, fileIndex)"
          />
        </div>
      </div>
    </div>
  </div>

</section>

<section class="links">
  <div class="header">
    <span class="title"
      >{{ 'interest-links' | translate }} ({{ links.length }})</span
    >
    <span class="subtitle">
      {{ options.urlsSubtitleSlug | translate }}
    </span>
  </div>

  <div class="external-wrapper">
    <button
      *ngIf="context === 'login-client' || options.uploadUrls"
      [matTooltip]="'add-link' | translate"
      matTooltipPosition="above"
      mat-stroked-button
      (click)="addLink()"
      class="upload-button"
      data-cy="upload-link"
      mat-flat-button
      color="primary"
    >
      {{ 'add-link' | translate }}
      <mat-icon>add</mat-icon>
    </button>

    <div class="wrapper">
      <div
        matRipple
        class="raise url-list rounded"
        *ngFor="let link of links; index as linkIndex"
        (click)="goToUrl(link.url)"
        data-cy="each-link"
      >
        <div class="icons-section">
          <mat-icon class="open">launch</mat-icon>
          <span>{{ link.urlName }}</span>
        </div>

        <button
          *ngIf="
            context === 'login-client' && link.ownerType === 'Client' ||
            context === 'agent' && link.ownerType === 'Agent'
          "
          mat-icon-button
          [matTooltip]="'remove-link' | translate"
          matTooltipPosition="above"
          (click)="removeLink($event, linkIndex)"
          data-cy="remove-link"
        >
          <mat-icon class="close" svgIcon="24px:delete"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</section>

<div class="memory-bar" *ngIf="context === 'login-client'">
  <mat-progress-bar
    mode="determinate"
    [value]="projectMemoryUse"
  ></mat-progress-bar>
  <span>
    <strong>Memoria en uso:</strong> {{ projectMemoryUse | number: '1.2-2' }}MB
    de 50MB
  </span>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, combineLatest } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import environment from 'src/environments/environment';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import * as Sentry from "@sentry/browser";
import { DocumentInterface } from '@core/sdk';
@Injectable({
  providedIn: 'root',
})
export class S3Service {
  constructor(
    private httpClient: HttpClient,
    private store: Store<{ user: any; context: any }>,
  ) { }

  s3uploadfile(file: File, documentID, storage: String = 'amazon', pathToFile?: string, fileName?: string, clientId: string = null, actionId?: string): Observable<any> {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    return new Observable(observer => {
      const uppy = new Uppy({
        restrictions: {
          maxFileSize: 2 * 1024 * 1024 * 1024, // 2GB
          maxNumberOfFiles: 1,
          allowedFileTypes: ['image/*', 'video/*', 'application/*', '*']
        },
        autoProceed: false
      });

      uppy.use(XHRUpload, {
        endpoint: `${environment.apiBaseUrl}/api/Documents/${documentID}/uploadFile/${storage}`,
        formData: true,
        fieldName: 'file',
        headers: {
          'Authorization': `${token}`,
          'path': pathToFile || '',
          'fileName': fileName || file.name,
          'userid': userId,
          'referrer': clientId ? `client-${clientId}` : '',
          'actionId': actionId
        }
      });
      try {
        uppy.on('file-added', file => {
          uppy.upload();
        });

        uppy.addFile({
          name: fileName || file.name,
          type: file.type,
          data: file,
        });

        uppy.on('upload-progress', (file, progress) => {
          observer.next({ type: 'progress', progress: progress.bytesUploaded / progress.bytesTotal * 100 });
        });

        uppy.on('upload-success', (file, response) => {
          observer.next({ type: 'success', response });
          observer.complete();
        });

        uppy.on('upload-error', (file, error, response) => {
          observer.error({ type: 'error', error, response });
        });

        uppy.on('complete', (result) => {
          observer.complete();
        });


      } catch (error) {
        observer.error(error);
        Sentry.withScope((scope) => {
          scope.setExtras({
            error,
          });
          Sentry.captureException(new Error('DocumentationUploadError'), {
            tags: {
              document_upload: 'true',
              new_library: true
            },
          });
        });
      }
    });
  }

  getFileUrlOld(fileID, documentID) {
    try {
      return this.httpClient.get(
        `${environment.apiBaseUrl}/api/Documents/${documentID}/downloadFile/${fileID}`,
        { responseType: 'blob' },
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  getFileUrl(url): Observable<Blob> {
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  removeFile(fileID, documentID) {
    return this.httpClient.delete(
      `${environment.apiBaseUrl}/api/Documents/${documentID}/versions/${fileID}`,
    );
  }

  removeAllOldVersions(fileID, documentID) {
    const url = `${environment.apiBaseUrl}/api/Documents/${documentID}/deleteOldVersions/${fileID}`;

    return this.httpClient.delete(url);
  }

  removeFileAndDocument(fileID, documentID) {
    const removeDocumentUrl = `${environment.apiBaseUrl}/api/Documents/${documentID}/deleteDocumentAndVersion`;

    return combineLatest(
      this.store.select('context'),
      this.store.select('user'),
    ).pipe(
      take(1),
      switchMap(([{ value: context }, { revisionToken }]) => {
        if (context === 'supervisor') {
          const httpOptions = {
            headers: new HttpHeaders({
              revisionToken,
            }),
          };

          return this.httpClient.delete(removeDocumentUrl, httpOptions);
        }

        return this.httpClient.delete(removeDocumentUrl);
      }),
    );
  }

  moveFileToFolder(documentId, originFolder, destinationFolder): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/documents/${documentId}/movefile`;
    const folders = {
      "originFolder": originFolder,
      "destinationFolder": destinationFolder,
    }

    try {
      return this.httpClient.patch(
        url,
        {originFolder, destinationFolder},
      );
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          error,
        });
        Sentry.captureException(new Error('DocumentationMoveError'), {
          tags: {
            document_move: 'true'
          },
        });
      });

    }
  }

  s3uploadMultipleFiles(files: File[], documents: DocumentInterface[], clientId: string = null, actionId?: string): Observable<any> {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    return new Observable(observer => {
        const formData = new FormData();

        files.forEach(file => {
            formData.append('files', file);
        });

        fetch(`${environment.apiBaseUrl}/api/Documents/upload2/google/multiple`, {
            method: 'POST',
            headers: {
                'Authorization': `${token}`,
                'userid': userId,
                'referrer': clientId ? `client-${clientId}` : '',
                'actionId': actionId,
                'documents': JSON.stringify(documents)
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error en la carga: ' + response.statusText);
            }
            return response.json();
        })
        .then(data => {
            observer.next(data);
            observer.complete();
        })
        .catch(error => {
            observer.error(error);
            Sentry.withScope((scope) => {
                scope.setExtras({ error });
                Sentry.captureException(new Error('DocumentationUploadError'), {
                    tags: { document_upload: 'true', new_library: true }
                });
            });
        });
    });
}
  

}

<div class="main-container mat-typography" data-cy="welcome-modal">
  <div>
    <img
      class="logo"
      mat-card-image src="assets/img/logo-primary-dark.svg"
      alt="{{ 'onboarding.welcome.logo.alt' | translate }}"
    />
  </div>
  <div class="content-container">
    <h1 mat-dialog-title>{{ 'onboarding.welcome.title' | translate }}</h1>
      <p>{{ 'onboarding.welcome.description1' | translate: { firstName: data.firstAgent.shortname, secondName: data.secondAgent.shortname } }}</p>
      <div class="flex flex-row justify-around content-center">
        <div class="agent-container">
          <img
            class="agent-img rounded-full"
            [src]="data.firstAgent.img"
            alt="{{ 'onboarding.welcome.logo.alt' | translate }}"
          />
          <b>{{ data.firstAgent.name | translate }}</b>
          <span class="agent-role">{{ data.firstAgent.role | translate }}</span>
        </div>
  
        <div class="agent-container">
          <img
            class="agent-img rounded-full"
            [src]="data.secondAgent.img"
            alt="{{ 'onboarding.welcome.logo.alt' | translate }}"
          />
          <b>{{ data.secondAgent.name | translate }}</b>
          <span class="agent-role">{{ data.secondAgent.role | translate }}</span>
        </div>
      </div>

      <markdown
        ngPreserveWhitespaces
        class="description2-text"
        [data]="messages.description2">
      </markdown>
      <div>
        <button
          mat-flat-button
          cdkFocusInitial
          color="primary"
          (click)="action()"
        >
          <mat-icon
            svgIcon="24px-customerService"
            [matTooltip]="'cubicup-shop' | translate"
            matTooltipPosition="above"
          >
          </mat-icon>
          <ng-container>
            {{ 'onboarding.welcome.button.action' | translate }}
          </ng-container>
        </button>
      </div>
      <span class="link" (click)="cancel()">{{ 'onboarding.welcome.button.skip' | translate }}</span>
  </div>
</div>

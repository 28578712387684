export interface PermissionInterface {
  id?: string;
  companyCode?: string;
  domain?: string;
  action?: string;
  createdAt?: Date;
  updatedAt?: Date;
  modelActive?: any;
}

export class Permission implements PermissionInterface {
  'id': string;
  'companyCode': string;
  'domain': string;
  'action': string;
  'slug': string;
  'createdAt': Date;
  'updatedAt': Date;
  'modelActive': any;

  constructor(data?: PermissionInterface) {
    Object.assign(this, data);
  }
}
import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import environment from '@environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';
import { Store } from '@ngrx/store';
import { Client, DocumentInterface, LeadInterface } from '@core/sdk';
import { concatMap, switchMap, take } from 'rxjs/operators';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  private showConversation = false;
  private conversationIsToggled: Subject<any> = new Subject<any>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private localStorage: LocalStorageService,
    private store: Store<{
      client: Client;
      projects: LeadInterface[];
      context: any;
      user: any;
    }>,
    private sessionStorage: SessionStorageService,
  ) {}

  getToggleConversation() {
    return this.conversationIsToggled.asObservable();
  }

  closeConversation() {
    this.conversationIsToggled.next(false);
  }

  toggleConversation({
    conversation,
    receptor,
    receptorIsStaff,
    targetModel,
    isDiscussion,
  }) {
    this.showConversation = !this.showConversation;

    this.conversationIsToggled.next({
      conversation,
      receptor,
      receptorIsStaff,
      targetModel,
      isDiscussion,
    });
  }

  private getAnonymousClientConversationMessages(conversationID) {
    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify({
          include: ['client', 'person', 'cubicupStaff'],
        }),
        'anonymous-client-token': this.sessionStorage.get('clientToken'),
      }),
    };

    const url = `${environment.apiBaseUrl}/api/Conversations/${conversationID}/message`;

    return this.httpClient.get<any[]>(url, httpOptions);
  }

  private getClientConversationMessages(conversationID) {
    let realClientID = this.localStorage.get('clientId');

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify({
          include: [
            'client',
            'person',
            'cubicupStaff',
            {
              relation: 'documents',
              scope: {
                fields: [
                  'id',
                  'versions'
                ],
                include: {
                  relation: 'versions',
                  scope: {
                    fields: ['fileName', 'fileUrl']
                  }
                }
              }
            }
          ],
        }),
        referrer: `client-${realClientID}`,
      }),
    };

    const url = `${environment.apiBaseUrl}/api/Conversations/${conversationID}/message`;

    return this.httpClient.get<any[]>(url, httpOptions);
  }

  private getAgentConversationMessages(conversationID) {
    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify({
          include: [
            'person',
            'cubicupStaff',
            'client',
            {
              relation: 'documents',
              scope: {
                fields: [
                  'id',
                  'versions'
                ],
                include: {
                  relation: 'versions',
                  scope: {
                    fields: ['fileName', 'fileUrl']
                  }
                }
              }
            }
            
          ],
        }),
      }),
    };

    const url = `${environment.apiBaseUrl}/api/Conversations/${conversationID}/message`;

    return this.httpClient.get<any[]>(url, httpOptions);
  }

  getConversationMessages(conversationID) {
    return this.store.select('context').pipe(
      take(1),
      concatMap(({ value: context }) => {
        switch (context) {
          case 'agent':
            return this.getAgentConversationMessages(conversationID);
          case 'client':
            return this.getAnonymousClientConversationMessages(conversationID);
          case 'login-client':
            return this.getClientConversationMessages(conversationID);
          default:
            break;
        }
      }),
    );
  }

  sendMessage({
    conversationID,
    messageBody,
    receptorIsStaff = null,
    firstMessageSession,
  }) {
    let httpOptions;

    return this.store.select('context').pipe(
      take(1),
      concatMap(({ value: context }) => {
        switch (context) {
          case 'agent':
            httpOptions = {
              headers: new HttpHeaders({}),
            };
            break;
          case 'client':
            httpOptions = {
              headers: new HttpHeaders({
                'anonymous-client-token': this.sessionStorage.get(
                  'clientToken',
                ),
              }),
            };
            break;
          case 'login-client':
            httpOptions = {
              headers: new HttpHeaders({
                referrer: `client-${this.localStorage.get('clientId')}`,
              }),
            };
            break;
          default:
            break;
        }

        const url = `${environment.apiBaseUrl}/api/Conversations/${conversationID}/message`;

        return combineLatest([
          this.store.select('client'),
          this.store.select('user'),
        ]).pipe(
          take(1),
          concatMap(([client, user]) => {
            const owner = user.userId
              ? { personId: user.userId }
              : { clientId: client.id };

            return this.httpClient.post<any>(
              url,
              {
                body: messageBody,
                ...owner,
                receptorIsStaff,
                read: false,
                firstMessageSession,
              },
              httpOptions,
            );
          }),
        );
      }),
    );
  }

  setMessageAsRead(messageID: string) {
    return this.store.select('context').pipe(
      concatMap(({ value: context }) => {
        let httpOptions;
        const url = `${environment.apiBaseUrl}/api/Messages/${messageID}`;

        switch (context) {
          case 'agent':
            return this.httpClient.patch(url, { read: true });
          case 'client':
            const clientToken = this.sessionStorage.get('clientToken');

            httpOptions = {
              headers: new HttpHeaders({
                'anonymous-client-token': clientToken,
              }),
            };

            return this.httpClient.patch(url, { read: true }, httpOptions);
          case 'login-client':
            httpOptions = {
              headers: new HttpHeaders({
                referrer: `client-${this.localStorage.get('clientId')}`,
              }),
            };

            return this.httpClient.patch(url, { read: true }, httpOptions);
          default:
            break;
        }
      }),
    );
  }

  addParticipantToConversation(conversationID, personID) {
    const url = `${environment.apiBaseUrl}/api/Conversations/${conversationID}/participants/rel/${personID}`;

    return this.httpClient.put(url, {});
  }

  uploadMessageDocument(imageKey, imageName, messageID) {
    return this.httpClient.post<DocumentInterface>(
      `${environment.apiBaseUrl}/api/Messages/${messageID}/documents`,
      {
        key: imageKey,
        name: imageName,
        description: '',
        valueDate: null,
        dueDate: null,
        showDates: false,
        ownerId: messageID,
        ownerType: 'Message',
      },
    );
  }
}

<div class="title">
  <mat-icon svgIcon="16px-payment"></mat-icon>
  {{ 'payment-terms' | translate }}
</div>
<span class="subtitle">{{ subtitle | translate }}</span>

<ng-container *ngIf="disableFields">
  <div class="payment-methods">
    <div class="method">
      <span>% {{ 'signal' | translate }}</span>
      <div class="numbers">
        <span> {{ budget.paymentMethod.signal }} % </span>
        <div class="price">
          {{
            (budget.paymentMethod.signal * budget.total) / 100
              | currency: 'EUR':'symbol':'1.0-2'
          }}
        </div>

        <mat-icon>help_outline</mat-icon>
      </div>
    </div>

    <div class="method">
      <span>% {{ 'ahead' | translate }}</span>
      <div class="numbers">
        <span> {{ budget.paymentMethod.ahead }} % </span>
        <div class="price">
          {{
            (budget.paymentMethod.ahead * budget.total) / 100
              | currency: 'EUR':'symbol':'1.0-2'
          }}
        </div>
        
        <mat-icon>help_outline</mat-icon>
      </div>
    </div>

    <div class="method">
      <span>% {{ 'progress' | translate }}</span>
      <div class="numbers">
        <span> {{ budget.paymentMethod.progress }} % </span>
        <div class="price">
          {{
            (budget.paymentMethod.progress * budget.total) / 100
              | currency: 'EUR':'symbol':'1.0-2'
          }}
        </div>

        <mat-icon>help_outline</mat-icon>
      </div>
    </div>

    <div class="method">
      <span>% {{ 'end' | translate }}</span>
      <div class="numbers">
        <span> {{ budget.paymentMethod.end }} % </span>
        <div class="price">
          {{
            (budget.paymentMethod.end * budget.total) / 100
              | currency: 'EUR':'symbol':'1.0-2'
          }}
        </div>

        <mat-icon>help_outline</mat-icon>
      </div>
    </div>
  </div>
</ng-container>

<form
  *ngIf="budget?.totalSigned && !disableFields"
  [formGroup]="paymentForm"
  class="payment-methode"
>
  <div class="ahead">
    <span>{{ 'signal' | translate }}</span>

    <div class="payment-fields">
      <div class="custom-input custom-suffix">
        <mat-form-field
          class="without-error-display"
          appearance="outline"
          color="primary"
        >
          <input
            matInput
            type="number"
            formControlName="signal"
            data-cy="payment-signal"
          />
          <span matSuffix>%</span>
        </mat-form-field>
      </div>
      <div class="ahead-value">
        {{
          (budget.paymentMethod.signal * budget.totalSigned) / 100
            | currency: 'EUR':'symbol':'1.0-2'
        }}
      </div>
    </div>
  </div>

  <div class="ahead">
    <span>{{ 'ahead' | translate }}</span>

    <div class="payment-fields">
      <div class="custom-input custom-suffix">
        <mat-form-field
          class="without-error-display"
          appearance="outline"
          color="primary"
        >
          <input
            matInput
            type="number"
            formControlName="ahead"
            data-cy="payment-ahead"
          />
          <span matSuffix>%</span>
        </mat-form-field>
      </div>
      <div class="ahead-value">
        {{
          (budget.paymentMethod.ahead * budget.totalSigned) / 100
            | currency: 'EUR':'symbol':'1.0-2'
        }}
      </div>
    </div>
  </div>

  <div class="ahead">
    <span>{{ 'progress' | translate }}</span>

    <div class="payment-fields">
      <div class="custom-input custom-suffix">
        <mat-form-field
          class="without-error-display"
          appearance="outline"
          color="primary"
        >
          <input matInput type="number" formControlName="progress" />
          <span matSuffix>%</span>
        </mat-form-field>
      </div>
      <div class="ahead-value">
        {{
          (budget.paymentMethod.progress * budget.total) / 100
            | currency: 'EUR':'symbol':'1.0-2'
        }}
      </div>
    </div>
  </div>

  <div class="ahead">
    <span>{{ 'end' | translate }}</span>

    <div class="payment-fields">
      <div class="custom-input custom-suffix">
        <mat-form-field
          class="without-error-display"
          appearance="outline"
          color="primary"
        >
          <input matInput type="number" formControlName="end" />
          <span matSuffix>%</span>
        </mat-form-field>
      </div>
      <div class="ahead-value">
        {{
          (budget.paymentMethod.end * budget.total) / 100
            | currency: 'EUR':'symbol':'1.0-2'
        }}
      </div>
    </div>
  </div>
</form>

<p
  class="percentage-mismatch"
  *ngIf="totalPercentages !== 100 && !disableFields"
>
  {{ 'payment-method-sum' | translate }}
</p>

import { EventEmitter, Injectable } from '@angular/core';
import environment from '@environment';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService extends Socket{

  private socket: WebSocket;
  private messageReceived = new BehaviorSubject<string>('');
  public messageReceived$ = this.messageReceived.asObservable();

  callback: EventEmitter<any> = new EventEmitter();

  constructor() {
    const userId = localStorage.getItem('userId');

    super({
      url: environment.apiBaseUrl,
      options: {
        reconnectionAttempts: environment.webSocket.options.reconnectionAttempts,
        transports: environment.webSocket.options.transports,
        query: { userId }
      }
    });

    this.ioSocket.on("connect_error", (err) => {
      console.log('Socket Connection Error: ', err.message);
      console.log(err.description);
      console.log('Error context: ', err.context);
    });
  }

  listen(channel) {
    this.ioSocket.on(channel, (data) => {
      this.messageReceived.next(data)
    });
  }

  emit(payload = {}) {
    this.ioSocket.emit('event', payload);
  }

  sendMessage(message: string) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    }
  }

  disconnect() {
    if(this.socket) {
      this.socket.close();
    }
  }
}

<p class="text-md">{{ 'attached-document' | translate }}</p>

<div
  class="dropzone"
  appFileDrop
  (filesDropped)="handleDrop($event)"
  (filesHovered)="dropzoneState($event)"
  [ngClass]="{
    'dropzone--active': dropzoneActive,
    'dropzone--empty': !documents || documents.length === 0
  }"
  (click)="fileInput.click()"
>
  <div
    *ngIf="!documents || documents.length === 0; else documentsList"
    class="empty-state"
  >
    <mat-icon>attach_file</mat-icon>
    {{ 'drag-drop-files' | translate }}
  </div>

  <input
    hidden
    type="file"
    multiple="multiple"
    #fileInput
    (change)="handleDrop($event.target.files)"
    data-cy="file-drop"
  />

  <ng-template #documentsList>
    <ng-container *ngFor="let document of documents">
      <app-image-preview
        viewMode="mini"
        [document]="document"
        (removeFileEmitter)="handleRemoveFile($event)"
        (click)="openCarousel($event, document)"
      >
      </app-image-preview>
    </ng-container>
  </ng-template>
</div>

import { ActionTypes } from '../actions/meta.action';

export function clearState(reducer) {
  return (state, action) => {
    if (action.type === ActionTypes.LOGOUT) {
      // state = {}; // this is causing an type error fixed adding an clear stat on loggout in every reducer
      // state = { router: state.router }; // this is causing an type error fixed adding an clear stat on loggout in every reducer
    }

    return reducer(state, action);
  };
}

import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { AgentInterface, ClientInterface } from '@core/sdk';
import { Message } from '@core/sdk/models/Message';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { saveAs } from 'file-saver-es';
import { S3Service } from '@core/services/s3.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit, AfterViewInit {
  @Input() message: any;

  @ViewChild('clipboardImageUploaded', { static: false }) clipboardImageUploaded: ElementRef;

  receptor: any;
  context: string;
  imageAttached;

  constructor(
    private store: Store<{
      user: any,
      context: any,
      agent: AgentInterface,
      client: ClientInterface
    }>,
    private s3Service: S3Service
  ) {}

  ngOnInit() {
    this.store.select('context')
    .pipe(take(1))
    .subscribe(({value: context}) => {
      this.context = context;

      this.initializeMessage();
    });
  }

  ngAfterViewInit() {
    this.handleImageMessage()
  }

  private handleImageMessage() {
    if (this.message.image) {
      const preview = this.clipboardImageUploaded;
      const reader  = new FileReader();

      reader.onloadend = function () {
        preview.nativeElement.src = reader.result;
      }

      reader.readAsDataURL(this.message.image);

      return;
    }

    if (this.message.documents?.length) {
      this.imageAttached = this.message.documents[0]?.versions[0];
    }
  }

  private setAgentAvatar(name) {
    this.store.select('agent')
    .pipe(take(1))
    .subscribe(
      ({image}) => this.receptor = { name, avatar: image }
    )
  }

  private setClientAvatar() {
    this.store.select('client')
    .pipe(take(1))
    .subscribe(
      (client) => this.receptor = { name: client.contact.name }
    )
  }

  private initializeMessage() {
    //this.handleImageMessage();

    this.store.select('user')
      .pipe(take(1))
      .subscribe(
        ({email}) => {
          let avatar = 'assets/img/avatar.png';

          const { person, cubicupStaff, client } = this.message;
          let name;

          if (person) {
            avatar = person.avatar;
            name = person.username.includes('-') ? person.name : person.username;

            if (person.email === email && this.context === 'agent') {
              this.setAgentAvatar(name);

              return;
            }

            if (person.email === email && this.context === 'login-client') {
              this.setClientAvatar();

              return;
            }
          }

          if (cubicupStaff) {
            avatar = cubicupStaff.avatar;
            name = cubicupStaff.name;
          }

          if (client) {
            name = client.contact.name;
          }

          this.receptor = { name, avatar };
        }
      )
  }

  private downloadFile(downloadUrl) {
    const url = window.URL.createObjectURL(new Blob([downloadUrl]));

    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    if (isIOS) {
      //window.open(url, "_self");
      saveAs(url, this.imageAttached.fileName);

      return;
    }

    const element = document.createElement('a');

    element.setAttribute('href', url);
    element.setAttribute('download', this.imageAttached.fileName);

    element.style.display = 'none';

    document.body.appendChild(element);

    element.click();

    window.URL.revokeObjectURL(url);
    element.remove();
  }

  downloadImage() {
    this.s3Service.getFileUrl(this.imageAttached.fileUrl).subscribe(
      (downloadUrl) => {
        this.downloadFile(downloadUrl);
    });
  }
}

import { Budget, Chapter } from '@core/sdk';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { of } from 'rxjs';
import { budgetEditorAdapter } from './budget-edition.adapter';
import { initialState, State } from './budget-edition.reducer';

const { selectEntities } = budgetEditorAdapter.getSelectors();

export const selectBudgetState = createFeatureSelector<State>('budget-editor');

export const getSelectedBudgetId = (state: State) => state.selectedBudgetId;

export const selectBudgetEntities = createSelector(
  selectBudgetState,
  selectEntities,
);

export const selectCurrentBudgetId = createSelector(
  selectBudgetState,
  getSelectedBudgetId,
);

export const selectCurrentBudget = createSelector(
  selectBudgetEntities,
  selectCurrentBudgetId,
  (budgetEntities, currentBudgetId) => budgetEntities[currentBudgetId],
);

export const selectBudgetChapter = createSelector(
  selectCurrentBudget,
  (budget: Budget) => {

    if (!budget || !budget.chapters){
      return [];
    }
    
    return budget.chapters.map((chapter) => chapter.id);
    
  },
);

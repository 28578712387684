import { Injectable } from '@angular/core';
import { BudgetInterface, Budget, ChapterInterface } from '@core/sdk';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import environment from '@environment';
import { of } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { BudgetItemInterface } from '../sdk/models/BudgetItem';
import { PAYMENT_METHOD } from '@core/constants';

@Injectable({
  providedIn: 'root',
})
export class BudgetsTemplateService {
  constructor(
    private httpClient: HttpClient,
    private localStorage: LocalStorageService,
  ) { }

  getBudgetTemplates(filter: object) {
    const agentID = this.localStorage.get('agentId');

    const filterHeader = {
      ...filter,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    return this.httpClient.get<BudgetInterface[]>(
      `${environment.apiBaseUrl}/api/Agents/${agentID}/budgets`,
      httpOptions,
    );
  }

  getBudgetTemplatesByText(agentID: string, text: string) {
    if (!text) {
      return of(null);
    }

    // console.log(text);

    const filterHeader = {
      where: {
        title: { like: text, options: 'i' }, // insensitive
        or: [{ agentId: agentID }, { agentId: null }],
      },
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    return this.httpClient.get<BudgetInterface[]>(
      `${environment.apiBaseUrl}/api/Budgets`,
      httpOptions,
    );
  }
  
    getBudgetTemplatesByFilter(filter: object) {
      const httpOptions = {
        headers: new HttpHeaders({
          filter: JSON.stringify(filter),
        }),
      };
  
      return this.httpClient.get<BudgetInterface[]>(
        `${environment.apiBaseUrl}/api/Budgets`,
        httpOptions,
      );
  }


  getBudgetTemplateById(budgetTemplateID) {
    const agentID = this.localStorage.get('agentId');

    return this.httpClient.get<BudgetInterface>(
      `${environment.apiBaseUrl}/api/Agents/${agentID}/Budgets/${budgetTemplateID}`,
      // /api/Agents/${ agentID }/BudgetTemplates/${budgetTemplateID}`
    );
  }

  saveBudgetTemplate(newValues, budgetId) {
    return this.httpClient.patch<BudgetInterface>(
      `${environment.apiBaseUrl}/api/Budgets/${budgetId}`,
      {
        ...newValues,
      },
    );
  }

  duplicateBudgetTemplate(budget = null) {
    const agentID = this.localStorage.get('agentId');

    const url = `${environment.apiBaseUrl}/api/Agents/${agentID}/duplicate/${budget.id}/${budget.leadId}`;

    return this.httpClient.post<BudgetInterface>(url, {});
  }

  deleteBudgetTemplateById(budgetID) {
    return this.httpClient.delete<BudgetInterface>(
      `${environment.apiBaseUrl}/api/Budgets/${budgetID}`,
    );
  }

  createDraftFromSratch(
    agentId: string,
    result: {
      optionSelected: boolean,
      nameTemplate: string
    }) {
    const agentID = this.localStorage.get('agentId');

    const draft = {
      agentId: agentID,
      title: result.nameTemplate,
      state: 'created',
      type: 'template',
      paymentMethod: PAYMENT_METHOD
    }

    return this.httpClient.post<BudgetInterface>(
      `${environment.apiBaseUrl}/api/Agents/${agentID}/budgets`,
      draft,
    );
  }

  createBudgetTemplateFromBC3(bc3Data: any) {
    const {
      file,
      indirectPercent,
      industrialBenefit,
      overheads,
      nameTemplate,
    } = bc3Data;

    const agentID = this.localStorage.get('agentId');
    const formData = new FormData();

    formData.append('bc3', file, file.name);
    formData.append('indirectPercent', indirectPercent);
    formData.append('industrialPercent', industrialBenefit + overheads);
    formData.append('budgetTemplateTitle', nameTemplate);

    return this.httpClient.post<BudgetInterface>(
      `${environment.apiBaseUrl}/api/Agents/${agentID}/budgets/bc3`,
      formData,
    );
  }

  createChapter(budgetId: string, chapter: ChapterInterface) {
    return this.httpClient.post<ChapterInterface>(
      `${environment.apiBaseUrl}/api/Budgets/${budgetId}/chapters`,
      chapter,
    );
  }

  createItem(chapterId: string, item: BudgetItemInterface) {
    return this.httpClient.post(
      `${environment.apiBaseUrl}/api/Chapters/${chapterId}/budgetItems`,
      item,
    );
  }
}

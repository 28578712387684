/* eslint-disable */
import { Injectable } from '@angular/core';
import { LoopBackConfig } from '../../lb.config';
import { HttpClient } from '@angular/common/http';
import environment from "@environment";

/**
 * @author Jonathan Casarrubias <twitter:@johncasarrubias> <github:@johncasarrubias>
 * @module LoggerService
 * @license MIT
 * @description
 * Console Log wrapper that can be disabled in production mode
 **/
@Injectable()
export class LoggerService {
  constructor(private httpClient: HttpClient) {}

  log(...args: any[]):void {
    if (LoopBackConfig.debuggable()) {
      console.log.apply(console, args);
    } 
  }

  info(...args: any[]):void {
    if (LoopBackConfig.debuggable()) {
      console.info.apply(console, args);
    } 
  }

  error(...args: any[]):void {
    if (LoopBackConfig.debuggable()) {
      console.error.apply(console, args);
    } 
  }

  count(arg: string):void {
    if (LoopBackConfig.debuggable()) {
      console.count(arg);
    } 
  }

  group(arg: string):void {
    if (LoopBackConfig.debuggable()) {
      console.count(arg);
    } 
  }

  groupEnd():void {
    if (LoopBackConfig.debuggable()) {
      console.groupEnd();
    }
  }

  profile(arg: string):void {
    if (LoopBackConfig.debuggable()) {
      console.count(arg);
    }
  }

  profileEnd():void {
    if (LoopBackConfig.debuggable()) {
      console.profileEnd();
    }
  }

  time(arg: string):void {
    if (LoopBackConfig.debuggable()) {
      console.time(arg);
    }
  }

  timeEnd(arg: string):void {
    if (LoopBackConfig.debuggable()){
      console.timeEnd(arg);
    }
  }

  sendLog = async (info: unknown) => await this.httpClient.put(`${environment.apiBaseUrl}/api/log/`, { info }).toPromise();
}

<div class="header">
  <mat-icon svgIcon="illustration-billingInfo"></mat-icon>
  <button mat-icon-button mat-dialog-close>
    <mat-icon svgIcon="24px-close"></mat-icon>
  </button>
</div>

<span>{{ 'select-create-budget-option' | translate }}</span>

<form [formGroup]="budgetCreateOptionForm" *ngIf="budgetCreateOptionForm">
  <mat-radio-group aria-label="Select an option">

    <mat-option [value]="'template'" (click)="ifChecked('template')">
      <div
        class="radio-row"
        *ngIf="this.checkSelected === 'template'; else elseTemplate"
        data-cy="template-budget"
      >
        <mat-icon svgIcon="16px-checkFilled"></mat-icon>
        {{
          (viewMode === 'web'
            ? 'select-a-template'
            : 'select-a-template-mobile'
          ) | translate
        }}
      </div>
      <ng-template #elseTemplate>
        <div class="radio-row">
          <mat-icon svgIcon="16px-checkEmpty"></mat-icon>
          {{
            (viewMode === 'web'
              ? 'select-a-template'
              : 'select-a-template-mobile'
            ) | translate
          }}
        </div>
      </ng-template>
      <mat-divider></mat-divider>
    </mat-option>

    <mat-option [value]="'bc3'" (click)="ifChecked('bc3'); fileInput.click()">
      <div class="radio-row" *ngIf="this.checkSelected === 'bc3'; else elseBc3">
        <mat-icon svgIcon="16px-checkFilled"></mat-icon>
        {{
          (viewMode === 'web' ? 'select-a-bc3' : 'select-a-bc3-mobile')
            | translate
        }}
      </div>
      <ng-template #elseBc3>
        <div class="radio-row">
          <mat-icon svgIcon="16px-checkEmpty"></mat-icon>
          {{
            (viewMode === 'web' ? 'select-a-bc3' : 'select-a-bc3-mobile')
              | translate
          }}
        </div>
      </ng-template>
      <input
        hidden
        (change)="handleDrop($event)"
        #fileInput
        type="file"
        id="file"
      />
      <mat-divider></mat-divider>
    </mat-option>
  </mat-radio-group>
</form>

<ng-container *ngIf="this.checkSelected === 'bc3'">
  <div class="bc3-selected">
    <span class="label">{{ 'file-selected' | translate }}: </span>
    <span class="file">{{ this.data.file?.name }}</span>
  </div>
  <div class="help" *ngIf="showKInput">
    <span>{{ 'bc3-text-1' | translate }}</span>
  </div>
  <div class="k-undefined" *ngIf="showKInput">
    <form [formGroup]="bc3KForm" class="k-form">
      <div class="k-row">
        <div class="custom-input text-sm">
          <p>
            {{ 'indirect-percent' | translate }}
            {{ requiredValidator('indirectPercent') ? '*' : '' }}
          </p>

          <mat-form-field appearance="outline" color="primary">
            <input
              matInput
              type="number"
              min="0"
              max="100"
              formControlName="indirectPercent"
              data-cy="phone"
            />
            <span matSuffix>%</span>

            <mat-error
              class="password-required"
              *ngIf="bc3KForm.get('indirectPercent').hasError('required')"
              [innerHTML]="'field-required' | translate"
            >
            </mat-error>
          </mat-form-field>
        </div>

        <div class="custom-input text-sm">
          <p>
            {{ 'industrial-benefit' | translate }}
            {{ requiredValidator('industrialBenefit') ? '*' : '' }}
          </p>

          <mat-form-field appearance="outline" color="primary">
            <input
              matInput
              type="number"
              min="0"
              max="100"
              formControlName="industrialBenefit"
              data-cy="phone"
            />
            <span matSuffix>%</span>

            <mat-error
              class="password-required"
              *ngIf="bc3KForm.get('industrialBenefit').hasError('required')"
              [innerHTML]="'field-required' | translate"
            >
            </mat-error>
          </mat-form-field>
        </div>

        <div class="custom-input text-sm">
          <p>
            {{ 'overheads' | translate }}
            {{ requiredValidator('overheads') ? '*' : '' }}
          </p>

          <mat-form-field appearance="outline" color="primary">
            <input
              matInput
              type="number"
              min="0"
              max="100"
              formControlName="overheads"
              data-cy="phone"
            />
            <span matSuffix>%</span>

            <mat-error
              class="password-required"
              *ngIf="bc3KForm.get('overheads').hasError('required')"
              [innerHTML]="'field-required' | translate"
            >
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="help">
        <span>{{ 'bc3-text-2' | translate }}</span>
      </div>

      <div>
        <p class="text-sm">
          {{ 'tax-select' | translate }}
        </p>
        <mat-form-field
          class="without-error-display"
          appearance="outline"
          color="primary"
        >
    
        <mat-select formControlName="taxPercent" class="text-sm">
          <div *ngFor="let option of taxesOptions">
            <mat-option  [value]="option.value">{{option.slug}}</mat-option>
          </div>
        </mat-select>

          <mat-error
              class="password-required separated-error"
              *ngIf="bc3KForm.get('taxPercent').hasError('required')"
              [innerHTML]="'field-required' | translate"
            >
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>

  <mat-checkbox
    class="text-sm separated-checkbox"
    [checked]="copyBC3BudgetsItems"
    (change)="toggleBudgetItemsCopy($event.checked)"
  >
    {{ 'save-budget-items-as-templates' | translate }}
  </mat-checkbox>
</ng-container>

<ng-container *ngIf="this.checkSelected === 'template'">
  <app-budget-template-selector
    (budgetClicked)="handleBudgetClicked($event)"
  ></app-budget-template-selector>
</ng-container>

<div class="buttons dialog-actions">
  <button mat-stroked-button color="accent" mat-dialog-close>
    {{ 'cancel' | translate }}
  </button>
  <button
    mat-flat-button
    color="accent"
    (click)="send()"
    [disabled]="createDisabled"
    data-cy="create-type-budget"
  >
    {{ 'create' | translate }}
  </button>
</div>

<!-- <div class="dialog-actions">
    <button mat-dialog-close mat-stroked-button color="accent">
      {{ 'cancel' | translate }}
    </button>

    <button (click)="send()" mat-flat-button color="primary">
      {{ 'create' | translate }}
    </button>
  </div> -->

<div class="header">
  <div class="left">
    <mat-icon *ngIf="data.svgIcon" [svgIcon]="data.svgIcon"></mat-icon>
    <div class="title">
      <span>{{ data.title | translate }}</span>
      <span class="subtitle">{{ data.subtitle | translate }}</span>
    </div>
  </div>

  <button
    mat-icon-button
    mat-dialog-close
    *ngIf="data.showCloseButton"
    (click)="submit()"
  >
    <mat-icon svgIcon="24px-close"></mat-icon>
  </button>
</div>

<div class="custom-dialog">
  <div class="video-carrousel">
    <iframe
      [width]="data.videoWidth"
      [height]="data.videoHeight"
      [src]="videosUrls[videoIndex].url | safeUrl"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    >
    </iframe>
  </div>

  <div class="video-carrousel-controls">
    <button mat-icon-button (click)="backVideo()">
      <mat-icon>chevron_left</mat-icon>
    </button>

    <span>{{videoIndex + 1}} / {{videosUrls.length}}</span>

    <button mat-icon-button (click)="nextVideo()">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>

<form [formGroup]="mustShowForm" class="checkbox-wrapper">
  <mat-checkbox formControlName="dontShowModal" data-cy="showroom-service">
    <span>{{ 'no-must-show' | translate }}</span>
  </mat-checkbox>
</form>


<ng-container *ngIf="context === 'agent'; else clientPerson">
  <div class="wrapper" matRipple data-cy="header-person">
    <ng-container *ngIf="user">
      <div
        *ngIf="user.documents; else defaultAvatar"
        class="profile-image"
        [ngStyle]="{
          'background-image': 'url(' + backgroundImage + ')'
        }"
      ></div>
      <ng-template #defaultAvatar>
        <app-initial-letters [name]="user.username"></app-initial-letters>
      </ng-template>

      <span class="text-sm">{{ user.username }}</span>
    </ng-container>

    <mat-icon [ngClass]="{ 'menu-open': !(isMenuClosed$ | async) }">
      keyboard_arrow_down
    </mat-icon>
  </div>
</ng-container>

<ng-template #clientPerson>
  <div 
    class="wrapper" 
    matRipple 
    data-cy="header-person"
  >
    <ng-container *ngIf="client">
      <div
        *ngIf="client.avatar; else defaultAvatar"
        class="profile-image"
        [ngStyle]="{
          'background-image':
            'url(' + (client.avatar ? client.avatar : defaultImage) + ')'
        }"
      ></div>
      <ng-template #defaultAvatar>
        <app-initial-letters
          *ngIf="client"
          [name]="client.contact?.email"
        ></app-initial-letters>
      </ng-template>

      <span class="text-sm">{{ client.contact?.name }}</span>
    </ng-container>

    <mat-icon [ngClass]="{ 'menu-open': !(isMenuClosed$ | async) }">
      keyboard_arrow_down
    </mat-icon>
  </div>
</ng-template>

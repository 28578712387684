import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import environment from '../../../environments/environment';
import { Certification } from '@core/sdk';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AnyLengthString } from 'aws-sdk/clients/comprehendmedical';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { CertificationChapter } from '@core/sdk/models/CertificationChapter';
import { certificationDeleteAction, certificationUpdateAction } from 'src/app/store/certifications/certifications.actions';

@Injectable({
  providedIn: 'root',
})
export class CertificationService {
  public certificationCount$: BehaviorSubject<any> = new BehaviorSubject(null);
  private currentProjectId$: BehaviorSubject<string> = new BehaviorSubject(null);
  private certIsCalculated = localStorage.getItem('certIsCalculated');
  constructor(
    private httpClient: HttpClient,
    private store: Store<{ context: any }>,
  ) {
    this.store.select('context').subscribe(({ currentProjectId }) => {
      if (!currentProjectId) {
        this.certificationCount$.next(null);
        localStorage.removeItem('certIsCalculated');
      }
      this.currentProjectId$.next(currentProjectId);
    })
  }

  getProjectCertifications(projectID: string) {
    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}/certifications`;

    return this.httpClient.get<Certification>(url);
  }

  updateProjectCertifications(
    certificationId: string,
    body: Partial<Certification>,
  ) {
    const url = `${environment.apiBaseUrl}/api/Certifications/${certificationId}`;

    return this.httpClient.patch<Certification>(url, body);
  }

  getCertificationsByProject(filter) {
    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
      }),
    };

    const url = `${environment.apiBaseUrl}/api/Certifications`;

    return this.httpClient.get<Certification[]>(url, httpOptions);
  }

  getCertificationById(certificationID) {
    const filterHeader = {
      include: ['discounts'],
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    const url = `${environment.apiBaseUrl}/api/Certifications/${certificationID}`;

    return this.httpClient.get<Certification>(url, httpOptions);
  }

  getCertificationChaptersCount(certificationID): any {
    const url = `${environment.apiBaseUrl}/api/Certifications/${certificationID}/certificationChapters/count`;

    return this.httpClient.get(url);
  }

  getCertificationCount(projectID: string): Observable<{ count: number }> {
    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}/certifications/count`;

    return this.httpClient.get<{ count: number }>(url);
  }

  createCertification({
    projectID,
    order,
    punchListSubtractPercentage,
  }: {
    projectID: string;
    order: number;
    punchListSubtractPercentage?: number;
  }): Observable<Certification> {
    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}/certifications`;
    const body = { order };

    if (punchListSubtractPercentage) {
      body['punchListSubtractPercentage'] = punchListSubtractPercentage;
    }

    return this.httpClient.post<Certification>(url, body);
  }

  createLastCertification(certificationID) {
    const url = `${environment.apiBaseUrl}/api/Certifications/${certificationID}/get-last-certification`;

    return this.httpClient.get<{ certificationId: string }>(url);
  }

  getCertificationChaptersWithConf(configuration) {
    const { certificationID, skip, limit } = configuration;

    const filterHeader = {
      skip,
      limit,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    const url = `${environment.apiBaseUrl}/api/Certifications/${certificationID}/certificationChapters`;

    return this.httpClient.get<CertificationChapter[]>(url, httpOptions);
  }

  getCertificationsChapters(certificationID) {
    return this.getCertificationChaptersCount(certificationID).pipe(
      switchMap(({ count }) => {
        const countLimit = 5;
        const requestArr: Observable<CertificationChapter[]>[] = [];
        if (count <= countLimit && count > 0) {
          return this.getCertificationChaptersWithConf({
            certificationID,
            limit: countLimit,
          });
        }
        if (count > countLimit) {
          for (let index = 0; index < count; index = index + countLimit) {
            requestArr.push(
              this.getCertificationChaptersWithConf({
                certificationID,
                skip: index,
                limit: countLimit,
              }),
            );
          }
        }
        return forkJoin(requestArr);
      }),
      map((chapters: CertificationChapter[]) => chapters.flat()),
    );
  }

  getCertificationItemsCount(certificationID): any {
    const url = `${environment.apiBaseUrl}/api/Certifications/${certificationID}/certification-items-count`;

    return this.httpClient.get(url);
  }

  getCertificationItemsWithConf(configuration) {
    const { certificationID, skip, limit } = configuration;

    const filterHeader = {
      skip,
      limit,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    const url = `${environment.apiBaseUrl}/api/Certifications/${certificationID}/certification-items`;

    return this.httpClient.get<any[]>(url, httpOptions);
  }

  getCertificationItems(certificationID) {
    return this.getCertificationItemsCount(certificationID).pipe(
      switchMap(({ count }) => {
        const countLimit = 5;
        const requestArr: Observable<CertificationChapter[]>[] = [];
        if (count <= countLimit && count > 0) {
          return this.getCertificationItemsWithConf({
            certificationID,
            limit: countLimit,
          });
        }
        if (count > countLimit) {
          for (let index = 0; index < count; index = index + countLimit) {
            requestArr.push(
              this.getCertificationItemsWithConf({
                certificationID,
                skip: index,
                limit: countLimit,
              }),
            );
          }
        }
        return forkJoin(requestArr);
      }),
      map((chapters: CertificationChapter[]) => chapters.flat()),
    );
  }

  sendCertification(certification) {
    const url = `${environment.apiBaseUrl}/api/Certifications/${certification.id}/send-certification`;
    try {
      return this.httpClient.post(url, {
        'terms': certification.terms
      }).pipe(
        tap(() => {
          this.store.dispatch(certificationUpdateAction({ update: { id: certification.id, changes: { state: 'pending' } } }))
        })
      )
    } catch (error) {
      return error;
    }

  }



  acceptCertification(certificationID) {
    const url = `${environment.apiBaseUrl}/api/Certifications/${certificationID}`;

    return this.httpClient.patch(url, {
      state: 'accepted',
    }).pipe(
      tap(() => {
        this.store.dispatch(certificationUpdateAction({ update: { id: certificationID, changes: { state: 'accepted' } } }))
      })
    );
  }

  rejectCertification(certificationID) {
    const url = `${environment.apiBaseUrl}/api/Certifications/${certificationID}`;

    return this.httpClient.patch(url, {
      state: 'rejected',
    }).pipe(
      tap(() => {
        this.store.dispatch(certificationUpdateAction({ update: { id: certificationID, changes: { state: 'rejected' } } }))
      })
    );
  }

  removeCertification(certificationID) {
    const url = `${environment.apiBaseUrl}/api/Certifications/${certificationID}`;

    return this.httpClient.delete(url).pipe(
      tap(() => {
        this.store.dispatch(certificationDeleteAction( { id: certificationID }))
      })
    );
  }

  createDiscount(certificationID, discountData) {
    const url = `${environment.apiBaseUrl}/api/Certifications/${certificationID}/discounts`;

    return this.httpClient.post(url, { ...discountData });
  }

  removeDiscount(certificationID, discountID) {
    const url = `${environment.apiBaseUrl}/api//discount-items/${discountID}`;

    return this.httpClient.delete(url);
  }

  getCertificationDiscounts(certificationID) {
    const url = `${environment.apiBaseUrl}/api/Certifications/${certificationID}/discounts`;

    return this.httpClient.get(url);
  }

  getCertificationsAndStateColor(leadId): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/leads/${leadId}/certifications/count/color`;
    return this.httpClient.get(url);
  }

  getCertificationTotal() {
    if (JSON.parse(localStorage.getItem('certIsCalculated'))) {
      return;
    }
  
    this.currentProjectId$
      .pipe(
        take(1),
        switchMap(projectId => this.getCertificationsAndStateColor(projectId)),
        tap((result: any) => {
          this.certificationCount$.next({ color: result.color, text: result.certifications, hide: 'block' });
          localStorage.setItem('certIsCalculated', 'true');
        })
      )
      .subscribe();
  }
  
  getCertificationViewById(certificationId: string){
    const url = `${environment.apiBaseUrl}/api/Certifications/${certificationId}/view`;
    return this.httpClient.get(url);
  }
  
}

<div class="header">
  <div class="left">
    <mat-icon *ngIf="data.svgIcon" [svgIcon]="data.svgIcon"></mat-icon>
    <div class="title">
      <span>{{ data.title | translate }}</span>
      <span class="subtitle">{{ data.subtitle | translate }}</span>
    </div>
  </div>
  <button mat-icon-button mat-dialog-close *ngIf="data.showCloseButton">
    <mat-icon svgIcon="24px-close"></mat-icon>
  </button>
</div>

<form [formGroup]="discountForm">
  <div class="custom-input text-sm">
    <p>{{ 'discount-title' | translate }}</p>
    <mat-form-field appearance="outline" color="primary">
      <input matInput type="text" formControlName="title" data-cy="discount" />
      <mat-error
        *ngIf="discountForm.get('title').hasError('required')"
        [innerHTML]="'field-required' | translate"
        data-cy="discount-required"
      >
      </mat-error>
      <!-- <mat-error
        *ngIf="
          discountForm.controls.email.hasError('pattern') &&
          discountForm.controls.email.value.length > 5
        "
        [innerHTML]="'text-field' | translate"
        data-cy="email-pattern"
      >
      </mat-error> -->
    </mat-form-field>
  </div>

  <div class="custom-input text-sm">
    <p>{{ 'discount-description' | translate }}</p>
    <mat-form-field appearance="outline" color="primary">
      <textarea
        matInput
        type="text"
        formControlName="description"
        data-cy="discount"
      ></textarea>
      <mat-error
        *ngIf="discountForm.get('description').hasError('required')"
        [innerHTML]="'field-required' | translate"
        data-cy="discount-required"
      >
      </mat-error>
      <mat-error
        *ngIf="discountForm.get('description').value.length > 300"
        [innerHTML]="'text-field' | translate"
        data-cy="email-pattern"
      >
      </mat-error>
    </mat-form-field>
  </div>

  <div class="custom-input text-sm">
    <p>{{ 'discount-price' | translate }}</p>
    <mat-form-field appearance="outline" color="primary">
      <input
        matInput
        type="number"
        formControlName="totalPrice"
        data-cy="totalPrice"
        min="0.01"
        [max]="data.certificationTotal"
      />
      <mat-error
        *ngIf="discountForm.get('totalPrice').hasError('required')"
        [innerHTML]="'field-required' | translate"
        data-cy="discount-totalPrice-required"
      >
      </mat-error>
      <mat-error
        *ngIf="discountForm.get('totalPrice').value > data.certificationTotal"
        [innerHTML]="'field-required' | translate"
        data-cy="email-pattern"
      >
      </mat-error>
    </mat-form-field>
  </div>
</form>

<div class="buttons">
  <button
    mat-stroked-button
    color="primary"
    mat-dialog-close
    *ngIf="data.showCancelButton"
  >
    {{ data.cancel ? data.cancel : ('cancel' | translate) }}
  </button>

  <button
    mat-flat-button
    color="accent"
    (click)="submit()"
    [disabled]="disableAddButton"
  >
    {{ data.cta ? (data.cta | translate) : ('accept' | translate) }}
  </button>
</div>

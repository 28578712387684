import { Injectable } from "@angular/core";
import environment from "@environment";
import * as _ from "lodash";
import { reject } from "lodash";

@Injectable({
  providedIn: 'root',
})
export class UtilsService {

  applyRegex(string='', regex):string{
    return  _.head(string.match(regex)) || '';
  }

  waitForHtmlElement = (element: HTMLElement, selector: string): Promise<Element> => new Promise(resolve => {
    if (!element || !selector) return reject(null);

    if (element.querySelector(selector)) {
        return resolve(element.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (element.querySelector(selector)) {
          resolve(element.querySelector(selector));
          observer.disconnect();
      }
    });

    observer.observe(element, {
        childList: true,
        subtree: true
    });
  });

  isDevelopment = (): boolean => {
    return environment.production || environment.integration ? false : true;
  }
}

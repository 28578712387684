<div class="modal modal-open">
  <div class="modal-box">
    <h3 class="font-bold text-lg">{{ 'add-annex-to-budget-and-tracking' | translate }}</h3>

    <div class="flex justify-around items-center mt-4">
      <label class="text-sm">{{ 'new-annex-date' | translate }}</label>  
      <input type="checkbox" class="checkbox" [value]="changeDate" (change)="onCheckboxChange()" data-cy="accept">
    </div>

    <div *ngIf="changeDate" class="flex items-center flex-row justify-around mt-4">
      <label class="text-sm">{{ 'new-end-date' | translate }}</label>  
      <input type="date" class="input input-bordered" (change)="dateChanged($event)" [value]="projectEndDate?.toString().slice(0,10)">
    </div>

    <div class="modal-action">
      <button class="btn bg-corporate-primary text-white hover:bg-corporate-hover" (click)="submit()">{{ 'add-annex-to-budget-and-tracking-confirmation' | translate }}</button>
      <button class="btn" (click)="closeModal()">{{ 'continue-editting' | translate }}</button>
    </div>
  </div>
</div>

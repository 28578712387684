<button
  mat-button
  color="primary"
  class="add-note"
  data-cy="add-note"
  (click)="openNotesModal()"
>
  <mat-icon svgIcon="24px-add"></mat-icon>
  {{ 'add-new-note' | translate }}
</button>

<div class="notes">
  <ng-container *ngIf="notes.length > 0; else emptyState">
    <ng-container *ngFor="let note of notes">
      <app-note
        [note]="note"
        (noteRemoved)="handleNoteRemoved($event)"
        data-cy="each-note"
      >
      </app-note>
    </ng-container>
  </ng-container>

  <ng-template #emptyState>
    <div class="empty-state">
      <span>{{ 'notes-empty-state' | translate }}</span>
    </div>
  </ng-template>
</div>

<div class="title">
  <p mat-dialog-title>{{ 'send-budget' | translate }}</p>

  <button mat-icon-button mat-dialog-close>
    <mat-icon svgIcon="16px-close"></mat-icon>
  </button>
</div>

<p>{{ 'send-cubicup-budget' | translate }}</p>

<mat-checkbox [formControl]="avoidSendToClient" class="text-wrap">
  {{
    (own.value
      ? 'no-send-client-budget-own'
      : 'no-send-client-budget-but-cubicup'
    ) | translate
  }}
</mat-checkbox>
<mat-checkbox [formControl]="saveTemplates" class="text-wrap">
  {{ 'save-chapters-pruducts-from-budget' | translate }}
</mat-checkbox>

<div class="custom-input text-xs" *ngIf="own.value && !avoidSendToClient.value">
  <p>
    {{ (own.value ? 'confirm-email-client' : 'confirm-email') | translate }}
  </p>
  <mat-form-field appearance="outline" color="primary">
    <input matInput type="text" [formControl]="email" />

    <mat-error
      *ngIf="email.hasError('pattern')"
      [innerHTML]="'email-field' | translate"
      data-cy="email-pattern"
    >
    </mat-error>
  </mat-form-field>
</div>

<div class="custom-input text-xs" *ngIf="!avoidSendToClient.value">
  <p>
    {{ 'optional-comments' | translate }}
  </p>
  <mat-form-field appearance="outline" color="primary">
    <textarea matInput type="text" [formControl]="comments"></textarea>
    <mat-error
      *ngIf="comments.hasError('minlength')"
      [innerHTML]="'2-characters-error' | translate"
    >
    </mat-error>
    <mat-error
      *ngIf="comments.hasError('maxlength')"
      [innerHTML]="'2000-characters-error' | translate"
    >
    </mat-error>
  </mat-form-field>
</div>

<p *ngIf="own.value && !avoidSendToClient.value" class="disclaimer">
  {{ 'email-client-disclaimer' | translate }}
</p>

<div class="dialog-actions">
  <button mat-dialog-close mat-stroked-button color="accent">
    {{ 'cancel' | translate }}
  </button>

  <button
    (click)="send()"
    mat-flat-button
    color="primary"
    [disabled]="
      (email.hasError('pattern') ||
        !email.value ||
        comments.hasError('minlength') ||
        comments.hasError('maxlength')) &&
      own.value &&
      !avoidSendToClient.value
    "
  >
    {{
      (avoidSendToClient.value && own.value ? 'download' : 'send') | translate
    }}
  </button>
</div>

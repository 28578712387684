<div class="header">
  <div class="left">
    <mat-icon *ngIf="data.svgIcon" [svgIcon]="data.svgIcon"></mat-icon>
    <div class="title">
      <span>{{ data.title | translate }}</span>
      <span class="subtitle">{{ data.subtitle | translate }}</span>
    </div>
  </div>
  <button mat-icon-button mat-dialog-close *ngIf="data.showCloseButton">
    <mat-icon svgIcon="24px-close"></mat-icon>
  </button>
</div>

<form [formGroup]="inputForm">
  <div class="custom-input text-sm">
    <p>{{ 'url-name' | translate }}</p>
    <mat-form-field appearance="outline" color="primary">
      <input
        matInput
        type="text"
        formControlName="urlName"
        data-cy="url-name"
      />
      <mat-error
        *ngIf="inputForm.get('urlName').hasError('required')"
        [innerHTML]="'field-required' | translate"
        data-cy="url-name-required"
      >
      </mat-error>
      <!-- <mat-error
        *ngIf="
          inputForm.controls.email.hasError('pattern') &&
          inputForm.controls.email.value.length > 5
        "
        [innerHTML]="'text-field' | translate"
        data-cy="email-pattern"
      >
      </mat-error> -->
    </mat-form-field>
  </div>

  <div class="custom-input text-sm">
    <p>{{ 'link' | translate }}</p>
    <mat-form-field appearance="outline" color="primary">
      <input 
        matInput 
        type="url" 
        formControlName="url" 
        data-cy="url-link" 
      />
      <mat-error
        *ngIf="inputForm.get('url').hasError('required')"
        [innerHTML]="'field-required' | translate"
        data-cy="url-link-required"
      >
      </mat-error>
      <mat-error
        *ngIf="inputForm.get('url').hasError('pattern')"
        [innerHTML]="'link-field' | translate"
        data-cy="url-link-pattern"
      >
      </mat-error>
    </mat-form-field>
  </div>
</form>

<div class="buttons">
  <button
    mat-stroked-button
    color="primary"
    mat-dialog-close
    *ngIf="data.showCancelButton"
  >
    {{ data.cancel ? data.cancel : ('cancel' | translate) }}
  </button>

  <button 
    mat-flat-button color="accent" 
    (click)="submit()"
    data-cy="submit"
  >
    {{ data.cta ? (data.cta | translate) : ('accept' | translate) }}
  </button>
</div>

import { CommonModule } from "@angular/common";
import { Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { LeadInterface, InvoiceInterface, ClientInterface, AgentInterface, UserInterface, Invoice } from "@core/sdk";
import { InvoiceService } from "@core/services/invoice.service";
import { LoaderService } from "@core/services/loader.service";
import { ResponsiveService } from "@core/services/responsive.service";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { SharedModule } from "@shared/shared.module";
import { NgxExtendedPdfViewerModule, NgxExtendedPdfViewerService, pdfDefaultOptions, LinkTarget } from "ngx-extended-pdf-viewer";
import { Subject, take, takeUntil, filter, map, switchMap, catchError, of } from "rxjs";
import { BudgetEditorStoreModule } from "src/app/store/budget/budget-editor-store.module";
import { selectRouteParams } from "src/app/store/router.selectors";
import { CONTEXTS } from "@core/constants";
import { NotificationsService } from "@core/services/notifications.service";


@Component({
  selector: 'cub-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  imports: [CommonModule,
    ReactiveFormsModule,
    SharedModule,
    BudgetEditorStoreModule,
    NgxExtendedPdfViewerModule],
  standalone: true,
})
export class PdfViewerComponent implements OnInit, OnDestroy, OnChanges {

  context: string;
  project: LeadInterface;
  projectId: string;
  invoice: InvoiceInterface;
  viewMode: string;
  showInvoice = false;
  client: ClientInterface;
  isSuperAdmin = false;
  base64: string
  unsubscriber$ = new Subject<void>();
  @Input() pdfId: string = null; 
  @Input() docType: string = 'invoice';

  constructor(
    translateService: TranslateService,
    private responsiveService: ResponsiveService,
    private store: Store<{
      context: any;
      router: any;
      client: ClientInterface;
      agent: AgentInterface;
      user: UserInterface;
    }>,
    private invoiceService: InvoiceService,
    private router: Router,
    private loaderService: LoaderService,
    private pdfService: NgxExtendedPdfViewerService,
    private notificationService: NotificationsService
  ) {
    pdfDefaultOptions.doubleTapZoomFactor = '130%'; // The default value is '200%'
    pdfDefaultOptions.externalLinkTarget = LinkTarget.BLANK;
    translateService.setDefaultLang('es_ES');
    translateService.use('es_ES');
  }

  ngOnInit(): void {
    this.store
      .select('context')
      .pipe(take(1))
      .subscribe(({ value: context }) => {
        this.context = context;

        if (this.context === CONTEXTS.LOGIN_CLIENT) {
          this.store
            .select('client')
            .pipe(take(1))
            .subscribe((client) => (this.client = client));

          return;
        }
      });

    this.responsiveService.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );

    this.initializeComponent();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if(this.viewMode !== 'web'){
      return;
    }
    if (changes['pdfId'] && !changes['pdfId'].isFirstChange()) {
      this.initializeComponent();
    }
  }

  initializeComponent() {
    if(this.pdfId) {
      this.invoiceService.getPdfInvoice(this.pdfId, this.docType)
        .subscribe((invoiceData: Invoice): void => {
          if(!invoiceData){
            this.loaderService.disable();
            this.notificationService.showError('Este PDF no existe');
          }
          const parsedata = JSON.parse(invoiceData.data)
          this.base64 = parsedata.data
          this.loaderService.disable();
        });
      return;
    }

    this.store
      .select(selectRouteParams)
      .pipe(
        takeUntil(this.unsubscriber$),
        filter((params) => Boolean(params && params.projectID)),
        map(({ projectID }) => projectID),
      )
      .subscribe((projectID: string) => {
        this.projectId = projectID
      });

      this.store
      .select(selectRouteParams)
      .pipe(
        takeUntil(this.unsubscriber$),
        filter((params) => Boolean(params && params.pdfID)),
        switchMap((params) => 
          this.invoiceService.getPdfInvoice(params.pdfID, params.docType).pipe(
            catchError((error) => {
              this.loaderService.disable();
              this.notificationService.showError('PDF no encontrado');
              return of(null);
            })
          )
        ),
        filter((invoiceData: Invoice | null) => !!invoiceData)
      )
      .subscribe((invoiceData: Invoice) => {
        const parsedata = JSON.parse(invoiceData.data);
        this.base64 = parsedata.data;
      });

    this.loaderService.disable();

  }

  goToInvoices() {
    if (this.context === CONTEXTS.LOGIN_CLIENT) {
      this.router.navigate([
        'logged-client',
        'invoices']);

      return;
    }
    this.router.navigate(['invoices']);
  }

}

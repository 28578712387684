import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ErrorHandlerEffects } from './error-handler.effects';


@NgModule({
  imports: [
    EffectsModule.forFeature([ErrorHandlerEffects]),
  ],
})
export class ErrorHandlerStoreModule {}
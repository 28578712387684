<div *ngIf="!pdfId" class="go-to-invoices">
  <span (click)="goToInvoices()">
    << {{ 'go-to-invoices' | translate }} </span>
</div>
<span></span>
<span></span>
<ngx-extended-pdf-viewer [base64Src]="base64" [height]="'90vh'" [showOpenFileButton]="false" [showRotateButton]="false"
  [showPropertiesButton]="false" [showPresentationModeButton]="false" [showPagingButtons]="false"
  [showSecondaryToolbarButton]="false" [contextMenuAllowed]="false" [showFindButton]="false" [showSidebarButton]="false"
  >
</ngx-extended-pdf-viewer>
import environment from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private cookieService: CookieService) {}

  eventEmitter(
    eventName: string,
    eventCategory: string = null,
    eventAction: string = null,
    eventLabel: string = null,
    eventValue: number = null,
    eventSentTo: string = null,
  ) {
    if (!environment.google.active) return;

    const cookieExists = this.cookieService.check('traficoInternoWebapp');

    if (cookieExists) {
      return;
    }

    const analyticsData = {};

    Object.assign(
      analyticsData,
      eventCategory ? { event_category: eventCategory } : undefined,
    );
    Object.assign(
      analyticsData,
      eventAction ? { event_action: eventAction } : undefined,
    );
    Object.assign(
      analyticsData,
      eventLabel ? { event_label: eventLabel } : undefined,
    );
    Object.assign(
      analyticsData,
      eventValue ? { event_value: eventValue } : undefined,
    );
    Object.assign(
      analyticsData,
      eventSentTo ? { send_to: eventSentTo } : undefined,
    );

    gtag('event', eventName, analyticsData);
  }
}
import { BudgetItem, BudgetItemChapter } from '@core/sdk';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

export enum budgetItemEditorActionsTypes {
  GetItemsFromBudgetIdRequestStarted = '[Budget Item Api] GET_ITEMS_FROM_BUDGET_ID_REQUEST_STARTED',
  GetItemsFromBudgetIdRequestSuccess = '[Budget Item Api] GET_ITEMS_FROM_BUDGET_ID_REQUEST_SUCCESS',
  GetBudgetItemRequestStarted = '[Budget Item Api] GET_BUDGET_ITEM_REQUEST_STARTED',
  GetChapterItemsRequestStarted = '[Budget Item Api] GET_CHAPTER_ITEMS_REQUEST_STARTED',
  GetChapterItemsRequestSuccess = '[Budget Item Api] GET_CHAPTER_ITEMS_REQUEST_SUCCESS',
  GetBudgetItemRequestSuccess = '[Budget Item Api] GET_BUDGET_ITEM_REQUEST_SUCCESS',
  GetBudgetItemsRequestStarted = '[Budget Item Api] GET_BUDGET_BUDGET_ITEMS_REQUEST_STARTED',
  GetBudgetItemsRequestSuccess = '[Budget Item Api] GET_BUDGET_BUDGET_ITEMS_REQUEST_SUCCESS',
  CreateBudgetItemRequestStarted = '[Budget Item Api] CREATE_BUDGET_BUDGET_ITEM_REQUEST_STARTED',
  CreateBudgetItemRequestSuccess = '[Budget Item Api] CREATE_BUDGET_BUDGET_ITEM_REQUEST_SUCCESS',
  CreateChapterBudgetItemRequestStarted = '[Budget Item Api] CREATE_CHAPTER_BUDGET_BUDGET_ITEM_REQUEST_STARTED',
  CreateChapterBudgetItemRequestSuccess = '[Budget Item Api] CREATE_CHAPTER_BUDGET_BUDGET_ITEM_REQUEST_SUCCESS',
  RemoveBudgetItemRequestStarted = '[Budget Item Api] REMOVE_BUDGET_BUDGET_ITEM_REQUEST_STARTED',
  RemoveBudgetItemRequestSuccess = '[Budget Item Api] REMOVE_BUDGET_BUDGET_ITEM_REQUEST_Success',
  RemoveBudgetItemListRequestStarted = '[Budget Item Api] REMOVE_BUDGET_BUDGET_ITEM_REQUEST_LIST_STARTED',
  RemoveBudgetItemListRequestSuccess = '[Budget Item Api] REMOVE_BUDGET_BUDGET_ITEM_REQUEST_LIST_SUCCESS',
  RemoveBudgetItemListFailure = '[Budget Item Api] REMOVE_BUDGET_BUDGET_ITEM_LIST_FAILURE',
  ShiftBudgetItemRequestStarted = '[Budget Item Api] SHIFT_BUDGET_BUDGET_ITEM_REQUEST_STARTED',
  InserReorderBudgetItemRequestStarted = '[Budget Item Api] INSERT_REORDER_BUDGET_ITEM_REQUEST_STARTED',
  ShiftBudgetItemRequestSuccess = '[Budget Item Api] SHIFT_BUDGET_BUDGET_ITEM_REQUEST_SUCCESS',
  UpdateBudgetItemRequestStarted = '[Budget Item Editor] UPDATE_BUDGET_ITEM_REQUEST_STARTED',
  UpdateBudgetItemRequestSuccess = '[Budget Item Editor] UPDATE_BUDGET_ITEM_REQUEST_SUCCESS',
  AddBudgetItem = '[Budget Item Editor] ADD_BUDGET_ITEM',
  AddBudgetItems = '[Budget Item Editor] ADD_BUDGET_ITEMS',
  UpdateBudgetItem = '[Budget Item Editor] UPDATE_BUDGET_ITEM',
  UpdateBudgetItems = '[Budget Item Editor] UPDATE_BUDGET_ITEMS',
  RemoveBudgetItem = '[Budget Item Editor] REMOVE_BUDGET_ITEM',
  RemoveBudgetItemList = '[Budget Item Editor] REMOVE_BUDGET_ITEM_LIST',
  RemoveChapterBudgetItem = '[Budget Item Editor] REMOVE_CHAPTER_BUDGET_ITEM',
  ClearEntities = '[Budget Item Editor] CLEAR_BUDGET_ITEM_STORE',
}

export const getItemsFromBudgetIdRequestStarted = createAction(
  budgetItemEditorActionsTypes.GetItemsFromBudgetIdRequestStarted,
  props<{ payload: string }>(),
);

export const getItemsFromBudgetIdRequestSuccess = createAction(
  budgetItemEditorActionsTypes.GetItemsFromBudgetIdRequestSuccess,
  props<{ payload: BudgetItem[] }>(),
);

export const getBudgetItemRequestStarted = createAction(
  budgetItemEditorActionsTypes.GetBudgetItemRequestStarted,
  props<{ payload: string }>(),
);

export const getBudgetItemRequestSuccess = createAction(
  budgetItemEditorActionsTypes.GetBudgetItemRequestSuccess,
  props<{ payload: BudgetItem }>(),
);

export const getBudgetItemsRequestStarted = createAction(
  budgetItemEditorActionsTypes.GetBudgetItemsRequestStarted,
  props<{ payload: string[] }>(),
);

export const getChapterItemsRequestStarted = createAction(
  budgetItemEditorActionsTypes.GetChapterItemsRequestStarted,
  props<{ payload: string }>(),
);

export const getChapterItemsRequestSuccess = createAction(
  budgetItemEditorActionsTypes.GetChapterItemsRequestSuccess,
  props<{ payload: BudgetItem[] }>(),
);

export const getBudgetItemsRequestSuccess = createAction(
  budgetItemEditorActionsTypes.GetBudgetItemsRequestSuccess,
  props<{ payload: BudgetItem[] }>(),
);

export const shiftBudgetItemRequestStarted = createAction(
  budgetItemEditorActionsTypes.ShiftBudgetItemRequestStarted,
  props<{
    budgetItemId: string;
    chapterId: string;
    budgetId: string;
    shift: number;
  }>(),
);

export const inserReorderBudgetItemRequestStarted = createAction(
  budgetItemEditorActionsTypes.InserReorderBudgetItemRequestStarted,
  props<{
    budgetItem: BudgetItem;
    budgetId: string;
  }>(),
);

export const shiftBudgetItemRequestSuccess = createAction(
  budgetItemEditorActionsTypes.ShiftBudgetItemRequestSuccess,
  props<any>(),
);

export const addBudgetItem = createAction(
  budgetItemEditorActionsTypes.AddBudgetItem,
  props<{ payload: BudgetItem }>(),
);

export const addBudgetItems = createAction(
  budgetItemEditorActionsTypes.AddBudgetItems,
  props<{ payload: BudgetItem[] }>(),
);

export const updateBudgetItem = createAction(
  budgetItemEditorActionsTypes.UpdateBudgetItem,
  props<{ payload: Update<BudgetItem> }>(),
);

export const updateBudgetItems = createAction(
  budgetItemEditorActionsTypes.UpdateBudgetItems,
  props<{ payload: Update<BudgetItem>[] }>(),
);

export const updateBudgetItemRequestStarted = createAction(
  budgetItemEditorActionsTypes.UpdateBudgetItemRequestStarted,
  props<{ payload: Partial<BudgetItem> }>(),
);

export const updateBudgetItemRequestSuccess = createAction(
  budgetItemEditorActionsTypes.UpdateBudgetItemRequestSuccess,
  props<{ payload: BudgetItem }>(),
);

export const createBudgetItemRequestStarted = createAction(
  budgetItemEditorActionsTypes.CreateBudgetItemRequestStarted,
  props<{ payload: Partial<BudgetItem> }>(),
);

export const createBudgetItemRequestSuccess = createAction(
  budgetItemEditorActionsTypes.CreateBudgetItemRequestSuccess,
  props<{ payload: BudgetItem }>(),
);

export const createChapterBudgetItemRequestStarted = createAction(
  budgetItemEditorActionsTypes.CreateChapterBudgetItemRequestStarted,
  props<{
    chapterId: string;
    budgetItem: Partial<BudgetItem>;
  }>(),
);

export const createChapterBudgetItemRequestSuccess = createAction(
  budgetItemEditorActionsTypes.CreateChapterBudgetItemRequestSuccess,
  props<{ payload: BudgetItem }>(),
);

export const removeBudgetItemRequestStarted = createAction(
  budgetItemEditorActionsTypes.RemoveBudgetItemRequestStarted,
  props<{
    budgetItemId: string;
    chapterId: string;
  }>(),
);

export const removeBudgetItemRequestSuccess = createAction(
  budgetItemEditorActionsTypes.RemoveBudgetItemRequestSuccess,
  props<{
    budgetItemId: string;
    chapterId: string;
  }>(),
);


export const removeBudgetItemListRequestStarted = createAction(
  budgetItemEditorActionsTypes.RemoveBudgetItemListRequestStarted,
  props<{
    budgetItemIds: BudgetItemChapter[];
  }>(),
);

export const removeBudgetItemListRequestSuccess = createAction(
  budgetItemEditorActionsTypes.RemoveBudgetItemListRequestSuccess,
  props<{
    budgetItemIds: BudgetItemChapter[];
  }>(),
);


export const removeBudgetItemListFailure = createAction(
  budgetItemEditorActionsTypes.RemoveBudgetItemListFailure,
  props<{ error: any }>()
);

export const removeBudgetItem = createAction(
  budgetItemEditorActionsTypes.RemoveBudgetItem,
  props<{
    budgetItemId: string;
    chapterId: string;
  }>(),
);

export const removeBudgetItemList = createAction(
  budgetItemEditorActionsTypes.RemoveBudgetItemList,
  props<{
    budgetItemIds: string[];
  }>(),
);

export const removeChapterBudgetItem = createAction(
  budgetItemEditorActionsTypes.RemoveChapterBudgetItem,
  props<{
    budgetItemId: string;
    chapterId: string;
  }>(),
);

export const clearEntities = createAction(
  budgetItemEditorActionsTypes.ClearEntities,
);

import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export const dniValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
  const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
  const nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
  const idNumber = control.value.toString().toUpperCase();

  const normalizedId = idNumber
    .replace(/^[X]/, '0')
    .replace(/^[Y]/, '1')
    .replace(/^[Z]/, '2');

  const letter = idNumber.substr(-1);
  const charIndex = parseInt(normalizedId.substr(0, 8), 10) % 23;

  return ((nifRexp.test(idNumber) || nieRexp.test(idNumber)) &&
    validChars.charAt(charIndex) === letter) ||
    control.pristine
    ? null
    : { dniValidator: true };
};

import {
  OnInit,
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { RemindersCenterService } from '@core/services/reminders-center.service';
import { ResponsiveService } from '@core/services/responsive.service';
import { Router } from '@angular/router';
import environment from '@environment';
import { LeadService } from '@core/services/lead.service';

@Component({
  selector: 'app-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.scss'],
})
export class ReminderComponent implements OnInit {
  @Input() reminder: any;
  @Output()
  reminderClosedEmitter: EventEmitter<string> = new EventEmitter<string>();

  showCloseButton = false;
  viewMode: string;
  rejectedOrAccepted = '';

  reminderTypes = {
    message: {
      icon: '24px-message',
      class: 'message',
    },
    project: {
      icon: 'illustration-homeReady',
      class: 'project',
    },
    activity: {
      icon: '24px-calendar',
      class: 'activity',
    },
    reminder: {
      icon: '24px-calendar',
      class: 'reminder',
    },
  };

  constructor(
    private remindersCenterService: RemindersCenterService,
    private responsiveService: ResponsiveService,
    private router: Router,
    private projectService: LeadService,
  ) {}

  ngOnInit() {
    this.responsiveService.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );
    this.checkRejectedOrAcceptedResult();
  }

  private checkRejectedOrAcceptedResult() {
    const { title } = this.reminder;

    if (title.includes('accepted') || title.includes('contract')) {
      this.rejectedOrAccepted = 'accepted';
      return;
    }

    if (title.includes('rejected')) {
      this.rejectedOrAccepted = 'rejected';
      return;
    }
  }

  closeReminder() {
    this.remindersCenterService
      .closeReminder(this.reminder.id)
      .subscribe(() => {
        this.reminderClosedEmitter.emit(this.reminder.id);
      });
  }

  private markAsClicked() {
    this.remindersCenterService
      .markReminderAsClicked(this.reminder.id)
      .subscribe(() => (this.reminder.clicked = true));
  }

  @HostListener('click') goToLink() {
    const [, link] = this.reminder.link.split(environment.frontBaseUrl);
    const [, parsedLink] = link.split('?');

    this.remindersCenterService.markRemindersAsRead([this.reminder]);

    if (this.reminder.type !== 'activity') {
      this.markAsClicked();
    }

    const currentURL = this.router.url;

    this.remindersCenterService.toggleRemindersCenter();

    if (
      (currentURL === parsedLink || currentURL === link) &&
      link.includes('openChat=true')
    ) {
      this.projectService.putOpenCubicupStaffConversation();

      return;
    }

    this.router.navigateByUrl(link);
  }
}

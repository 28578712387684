import { ChapterTemplate } from './ChapterTemplate';
/* eslint-disable */

declare var Object: any;
export interface ProductInterface {
  title?: string;
  description?: string;
  units: string;
  unitPrice: number;
  costPrice?: number;
  type?: string;
  id?: number;
  quizCode?: string;
  createdAt: Date;
  updatedAt: Date;
  modelActive?: any;
  chapterTemplateId?: string;
  agentId?: number;
  chapterTemplate?: ChapterTemplate;
  order?: number;
}

export class Product implements ProductInterface {
  title?: string;
  description?: string;
  units: string;
  unitPrice: number;
  costPrice?: number;
  type?: string;
  id?: number;
  quizCode?: string;
  createdAt: Date;
  updatedAt: Date;
  modelActive?: any;
  chapterTemplateId?: string;
  agentId?: number;
  chapterTemplate?: ChapterTemplate;
  order?: number;
  constructor(data?: ProductInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Product`.
   */
  public static getModelName() {
    return 'Product';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Product for dynamic purposes.
   **/
  public static factory(data: ProductInterface): Product {
    return new Product(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Product',
      plural: 'Products',
      path: 'Products',
      idName: 'id',
      properties: {
        title: {
          name: 'title',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        units: {
          name: 'units',
          type: 'string',
        },
        unitPrice: {
          name: 'unitPrice',
          type: 'number',
        },
        type: {
          name: 'type',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'number',
        },
        createdAt: {
          name: 'createdAt',
          type: 'Date',
        },
        updatedAt: {
          name: 'updatedAt',
          type: 'Date',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
        chapterTemplateId: {
          name: 'chapterTemplateId',
          type: 'number',
        },
        agentId: {
          name: 'agentId',
          type: 'number',
        },
      },
      relations: {
        chapterTemplate: {
          name: 'chapterTemplate',
          type: 'ChapterTemplate',
          model: 'ChapterTemplate',
          relationType: 'belongsTo',
          keyFrom: 'chapterTemplateId',
          keyTo: 'id',
        },
      },
    };
  }
}

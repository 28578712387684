import { Injectable } from '@angular/core';
import { Budget } from '@core/sdk';
import { EntityAdapter } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';
import {
  selectBudgetChapter,
  selectCurrentBudget,
} from './budget-edition.selectors';
import {
  clearEntities,
  getBudgetRequestStarted,
  updateBudgetRequestStarted,
} from './budget-editor.actions';

@Injectable()
export class BudgetEditionFacadeService {
  public budget$: Observable<Budget> = this.store
    .select(selectCurrentBudget)
    .pipe(filter<Budget>(Boolean));

  constructor(private store: Store<EntityAdapter<Budget>>) {}

  addBudget(payload: string) {
    this.store.dispatch(getBudgetRequestStarted({ payload }));
  }

  updateBudget(payload: Partial<Budget>) {
    this.store.dispatch(updateBudgetRequestStarted({ payload }));
  }

  getBudget() {
    return this.store.select(selectCurrentBudget).pipe(filter(Boolean));
  }

  getBudgetChapters() {
    return this.store.select(selectBudgetChapter);
  }

  clearBudgets() {
    this.store.dispatch(clearEntities());
  }
}

import { createAction, props } from '@ngrx/store';
import { Lead } from '@core/sdk';
import { Update } from '@ngrx/entity';

export enum projectActionsTypes {
  GetProjectRequestStarted = '[Project API] GET_PROJECT_REQUEST_STARTED',
  GetProjectRequestSuccess = '[Project API] GET_PROJECT_REQUEST_SUCCESS',
  GetProjectTimelineRequestStarted = '[Project API] GET_PROJECT_TIMELINE_REQUEST_STARTED',
  GetProjectTimelineRequestSuccess = '[Project API] GET_PROJECT_TIMELINE_REQUEST_SUCCESS',
  AddProject = '[Project Store] ADD_PROJECT',
}

export const getProjectRequestStarted = createAction(
  projectActionsTypes.GetProjectRequestStarted,
  props<{ projectId: string }>(),
);

export const getProjectRequestSuccess = createAction(
  projectActionsTypes.GetProjectRequestSuccess,
  props<{ project: Lead }>(),
);

export const addProject = createAction(
  projectActionsTypes.AddProject,
  props<{ project: Lead }>(),
);

export const ProjectAddAction = createAction(
  '[Projects Component] Add',
  props<Lead>(),
);

export const ProjectUpdateAction = createAction(
  '[Project/API] Update Project',
  props<{ update: Update<Lead>, showNotification?: Boolean }>(),
);

export const ProjectUpsertAction = createAction(
  '[Project/API] Upsert Project',
  props<{ project: Lead }>(),
);

export const ProjectsAddAction = createAction(
  '[Project/API] Add Projects',
  props<{ projects: Lead[] }>(),
);

export const ProjectDeleteAction = createAction(
  '[Project/API] Delete Project',
  props<{ id: string }>(),
);

export const getProjectTimelineRequestStarted = createAction(
  projectActionsTypes.GetProjectTimelineRequestStarted,
  props<{ projectId: string }>(),
);

export const getProjectTimelineRequestSuccess = createAction(
  projectActionsTypes.GetProjectTimelineRequestSuccess,
  props<{ project: Lead }>(),
);

export const ProjectUpdateSuccessAction = createAction(
  '[Project/API] Update Project Success',
  props<{ showNotification?: Boolean }>()
);

export const ProjectUpdateFailureAction = createAction(
  '[Project/API] Update Project Failure',
  props<{ error: any }>()
);

export const ProjectsClearAction = createAction('[Project/API] Clear Projects');

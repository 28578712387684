import { ValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export const matchPassword: ValidatorFn = (
  control: UntypedFormGroup,
): ValidationErrors | null => {
  const password = control.get('password') || control.get('newPassword');
  const confirmPassword = control.get('confirmPassword');

  if (
    password &&
    confirmPassword &&
    (password.touched || !password.pristine) &&
    (confirmPassword.touched || !confirmPassword.pristine) &&
    password.value !== confirmPassword.value
  ) {
    confirmPassword.setErrors({ matchPassword: true });

    return { matchPassword: true };
  } else {
    confirmPassword.setErrors({ matchPassword: null });
    confirmPassword.updateValueAndValidity({ onlySelf: true });

    return null;
  }
};

export const diferentPassword: ValidatorFn = (
  control: UntypedFormGroup,
): ValidationErrors | null => {
  const password = control.get('password') || control.get('newPassword');
  const oldPassword =
    control.get('oldPassword') || control.get('confirmPassword');

  if (
    password &&
    oldPassword &&
    (password.touched || !password.pristine) &&
    (oldPassword.touched || !oldPassword.pristine) &&
    password.value === oldPassword.value
  ) {
    password.setErrors({ diferentPassword: true });
    return { diferentPassword: true };
  } else {
    password.setErrors({ diferentPassword: null });
    password.updateValueAndValidity({ onlySelf: true });
    return null;
  }
};

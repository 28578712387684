import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
  AbstractControl,
} from '@angular/forms';
import { BudgetInterface } from '../../../core/sdk/models/Budget';
import { NotificationsService } from '@core/services/notifications.service';
import { ResponsiveService } from '@core/services/responsive.service';
import { untilDestroyed } from '@ngneat/until-destroy';
import { TAXES } from '@core/constants';

@Component({
  selector: 'app-budget-create-dialog',
  templateUrl: './budget-create-dialog.component.html',
  styleUrls: ['./budget-create-dialog.component.scss'],
})
export class BudgetCreateDialogComponent implements OnInit {
  budgetCreateOptionForm: UntypedFormGroup;
  bc3KForm: UntypedFormGroup;
  budgetSelected: BudgetInterface;

  checkSelected: string = 'new';
  createDisabled = false;
  showKInput = false;
  viewMode: string;
  copyBC3BudgetsItems = true;
  taxesOptions = TAXES

  constructor(
    public dialogRef: MatDialogRef<BudgetCreateDialogComponent>,
    private fb: UntypedFormBuilder,
    private notificationsService: NotificationsService,
    private responsiveService: ResponsiveService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.responsiveService.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );
    this.initializeForm();
  }

  private initializeForm() {
    this.budgetCreateOptionForm = this.fb.group({
      budgetOption: new UntypedFormControl('', [Validators.required]),
    });

    this.bc3KForm = this.fb.group({
      indirectPercent: new UntypedFormControl('', [Validators.required]),
      overheads: new UntypedFormControl('', [Validators.required]),
      industrialBenefit: new UntypedFormControl('', [Validators.required]),
      taxPercent: new UntypedFormControl('10', [Validators.required])
    });

    this.budgetCreateOptionForm.valueChanges.subscribe(({ budgetOption }) => {
      this.data.optionSelected = budgetOption;

      this.data.budget = null;
    });

    this.bc3KForm.statusChanges.subscribe((status) => {
      if (status === 'VALID') {
        this.createDisabled = false;
        return;
      }

      this.createDisabled = true;
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  send() {
    if (this.checkSelected === 'bc3') {
      this.data = {
        ...this.data,
        ...this.bc3KForm.value,
        copyBC3BudgetsItems: this.copyBC3BudgetsItems,
      };

      this.dialogRef.close(this.data);

      return;
    }

    if (this.budgetSelected) {
      this.dialogRef.close(this.budgetSelected);

      return;
    }

    this.dialogRef.close('new');
  }

  ifChecked(selected) {
    this.checkSelected = selected;
    this.budgetCreateOptionForm.get('budgetOption').setValue(selected);
    this.createDisabled = false;

    if (selected === 'template') {
      this.createDisabled = true;
      return;
    }

    if (selected === 'bc3') {
      this.bc3KForm.get('indirectPercent').setValue('');
      this.bc3KForm.get('overheads').setValue('');
      this.bc3KForm.get('industrialBenefit').setValue('');
      this.bc3KForm.get('taxPercent').setValue('');
    }
  }

  handleBudgetClicked(budget) {
    this.budgetSelected = budget;
    this.data.budget = budget;
    this.createDisabled = false;
  }

  private handleKBC3(evt) {
    const { result } = evt.target as any;
    const registers = result.split('~');

    if (!registers || registers.length === 0) {
      return;
    }

    let KIsDefined = false;
    this.createDisabled = false;

    for (let index = 0; index < registers.length; index++) {
      const register = registers[index];
      const [letter, emptySpace, data] = register.split('|');
      const [parts] = data ? data.split('|') : '';

      //K||3\13\6\0\16\|2\2\\-1\3\\2\2\2\-1\2\2\3\2\EUR\| (K BIEN DEFINIDO)
      //K|\2\2\3\2\2\2\2\EUR\|0| (K MAL DEFINIDO)

      if (letter === 'K') {
        if (emptySpace) {
          break;
        }

        KIsDefined = true;

        const [indirectPercent, overheads, industrialBenefit] = parts.split(
          '\\',
        );

        this.bc3KForm
          .get('indirectPercent')
          .setValue(parseInt(indirectPercent));
        this.bc3KForm.get('overheads').setValue(parseInt(overheads));
        this.bc3KForm
          .get('industrialBenefit')
          .setValue(parseInt(industrialBenefit));

        break;
      }
    }

    if (!KIsDefined) {
      this.showKInput = true;
      this.createDisabled = true;
    }
  }

  handleDrop(evt) {
    const files = evt.target.files;
    const file: File = files.length > 0 ? files.item(0) : null;

    if (!file) {
      return;
    }

    this.showKInput = false;

    if (!file.name.includes('.bc3')) {
      this.createDisabled = true;
      this.notificationsService.showError(
        'El archivo elegido no es de tipo BC3',
      );

      return;
    }

    this.data.file = file;

    const reader = new FileReader();

    reader.onload = this.handleKBC3.bind(this);
    reader.readAsText(files[0]);
  }

  requiredValidator(controlName) {
    const { validator } = this.bc3KForm.controls[controlName];

    if (!validator) {
      return false;
    }

    const validation = validator({} as AbstractControl);

    if (validation && validation.required) {
      return true;
    }

    return false;
  }

  toggleBudgetItemsCopy(checked) {
    this.copyBC3BudgetsItems = checked;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';
import { TimelineInterface } from '@core/sdk/models/Timeline';
import { LeadService } from '@core/services/lead.service';
import { Store } from '@ngrx/store';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { saveAs } from 'file-saver-es';
import { S3Service } from '@core/services/s3.service';
import { AgentInterface, UserInterface } from '@core/sdk';
import { selectRouteParams } from 'src/app/store/router.selectors';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-timeline-list',
  templateUrl: './timeline-list.component.html',
  styleUrls: ['./timeline-list.component.scss'],
})
export class TimelineListComponent implements OnInit {
  @Input() timelineList: TimelineInterface[];

  private unsubscriber$ = new Subject<void>();

  agent: AgentInterface;
  context: string;
  projectID: string;
  isSuperAdmin = false;

  constructor(
    private router: Router,
    private store: Store<{
      context: unknown;
      agent: AgentInterface,
      user: UserInterface
    }>,
    private projectService: LeadService,
    private s3Service: S3Service,
  ) {}

  ngOnInit(): void {
    this.store
      .select('agent')
      .pipe(untilDestroyed(this), take(1))
      .subscribe((agent) => (this.agent = agent));

    this.store
      .select(selectRouteParams)
      .pipe(
        takeUntil(this.unsubscriber$),
        map((params: Params) => params?.leadID),
        filter(Boolean),
      )
      .subscribe((projectID: string) => (this.projectID = projectID));

      this.store
      .select('context')
      .pipe(untilDestroyed(this), take(1))
      .subscribe(({ value: context }) => {
        this.context = context;
      });

      this.store
      .select('user')
      .pipe(take(1))
      .subscribe((user: UserInterface) => (this.isSuperAdmin = user.superAdmin));
  }

  goToModel(action: { model: any; name: string }): void {
    const url = [];

    if (this.context === 'login-client') {
      url.push('logged-client');
    }

    switch (action.name) {
      case 'newMessage':
        console.log(action.model);

        if (action.model.modelInstance.targetType === 'Tracking') {
          url.push('tracking', this.projectID);
          //url.push('tracking', action.model.modelInstance.targetId);
        }

        break;
      case 'newBudgetItemMessage':
        url.push('budget', action.model.metadata.budget.budgetId);
        break;
      case 'uploadNewFile':
        this.downloadFile(action.model.modelInstance);
        break;
      case 'certificationSended':
      case 'certificationRejected':
      case 'certificationAccepted':
        url.push('certification', action.model.modelInstance.id);
        break;
      case 'acceptBudget':
      case 'rejectBudget':
      case 'sendBudget':
        url.push('budget', action.model.modelInstance.id);
        break;
      case 'rejectEvent':
      case 'acceptEvent':
      case 'visitSuccess':
      case 'visitCancel':
        url.push('event', action.model.modelInstance.id);
        break;
      default:
        break;
    }

    void this.router.navigate(url);
  }

  visibilityChangeHandler(timelineIndex: number): void {
    setTimeout(() => {
      const { read, id } = this.timelineList[timelineIndex];

      if (read) {
        return;
      }

      this.projectService
        .markTimelineAsRead(id)
        .pipe(untilDestroyed(this))
        .subscribe();
    }, 2000);
  }

  downloadFile(s3File) {
    const { fileUrl, fileName } = s3File;

    this.s3Service
      .getFileUrl(fileUrl)
      .pipe(untilDestroyed(this))
      .subscribe((downloadUrl) => {
        const url = window.URL.createObjectURL(new Blob([downloadUrl]));

        const isIOS =
          /iPad|iPhone|iPod/.test(navigator.platform) ||
          (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        if (isIOS) {
          //window.open(url, "_self");
          saveAs(url, fileName);
          return;
        }

        const element = document.createElement('a');

        element.setAttribute('href', url);
        element.setAttribute('download', fileName);

        element.style.display = 'none';

        document.body.appendChild(element);

        element.click();

        window.URL.revokeObjectURL(url);
        element.remove();
      });
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  UntypedFormGroup,
} from '@angular/forms';
import { AgentService } from '@core/services/agent.service';
import { NotificationsService } from '@core/services/notifications.service';

@Component({
  selector: 'app-ask-for-review-dialog',
  templateUrl: './ask-for-review-dialog.component.html',
  styleUrls: ['./ask-for-review-dialog.component.scss'],
})
export class AskForReviewDialogComponent implements OnInit {
  askForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<AskForReviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private agentService: AgentService,
    private notificationsService: NotificationsService,
  ) {}

  ngOnInit() {
    this.askForm = this.formBuilder.group({
      clientName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          ),
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
      phoneNumber: [
        '',
        [
          Validators.minLength(11),
          Validators.maxLength(11),
          Validators.pattern(/^([0-9]{3} ){2}[0-9]{3}$/),
        ],
      ],
    });
  }

  sendRequest() {
    if (this.askForm.invalid) {
      return;
    }

    this.agentService.sendReviewRequest(this.askForm.value).subscribe(() => {
      this.notificationsService.showSuccess('Petición enviada');
      this.dialogRef.close();
    });
  }

  requiredValidator(controlName) {
    const { validator } = this.askForm.controls[controlName];

    if (!validator) {
      return false;
    }

    const validation = validator({} as AbstractControl);

    if (validation && validation.required) {
      return true;
    }

    return false;
  }
}

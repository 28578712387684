import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { ConversationService } from '@core/services/conversation.service';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import { ResponsiveService } from '@core/services/responsive.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { Store } from '@ngrx/store';
import { Client, Agent } from '@core/sdk';
import {
  filter,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import * as moment from 'moment';
import { NotificationsService } from '@core/services/notifications.service';
import { S3Service } from '@core/services/s3.service';
import { v4 as uuidv4 } from 'uuid';
import { selectUrl } from 'src/app/store/router.selectors';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
})
export class ConversationComponent implements OnInit, OnDestroy {
  @Input() conversation: any;

  @ViewChild('fileInput', { static: false }) fileInput;
  @ViewChild('clipboardImageUploaded', { static: false })
  clipboardImageUploaded: ElementRef;

  messages: any[];
  notReadMessages: any[];
  emitterForm: UntypedFormGroup;

  headerData: {
    title: string;
    subtitle: any;
  };
  viewMode: string;
  emitter: any;
  context: any;
  isDiscussion = false;

  images = [];
  clipboardImage: File;
  showTools = false;
  isEmptyMessage = true;

  unsubscriber$ = new Subject<void>();

  constructor(
    private conversationService: ConversationService,
    private formBuilder: UntypedFormBuilder,
    private responsiveservice: ResponsiveService,
    private localStorageService: LocalStorageService,
    private store: Store<{
      agent: Agent;
      client: Client;
      context: any;
      user: any;
      router: any;
    }>,
    private notificationsService: NotificationsService,
    private s3Service: S3Service,
  ) {}

  ngOnInit() {
    this.store
      .select('context')
      .pipe(take(1))
      .subscribe(({ value: context }) => (this.context = context));

    this.store
      .select(selectUrl)
      .pipe(takeUntil(this.unsubscriber$), filter(Boolean))
      .subscribe((url: string) => {
        if (url.includes('tracking')) {
          this.showTools = true;
        }
      });

    if (this.conversation) {
      this.initializeConversation();
    }

    this.responsiveservice.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  private setEmitter() {
    if (this.context === 'agent') {
      this.store
        .select('agent')
        .pipe(take(1))
        .subscribe((emitter: any) => {
          this.emitter = { ...emitter };

          if (this.emitter.image) {
            this.emitter.avatar = this.emitter.image;
            return;
          }

          let image = '';

          const [doc] = this.emitter.documents.filter(
            (doc) => doc.key === 'log',
          );

          if (
            !doc ||
            (doc && !doc.versions) ||
            (doc && doc.versions && doc.versions.length === 0)
          ) {
            return;
          }

          [{ fileUrl: image }] = doc.versions;
          this.emitter.avatar = image;
        });

      return;
    }

    this.store
      .select('client')
      .pipe(take(1))
      .subscribe((emitter: any) => {
        this.emitter = { ...emitter };
        this.emitter['username'] = emitter.contact.name;
      });
  }

  private initializeConversation() {
    this.isDiscussion = this.conversation.isDiscussion;

    this.conversationService
      .getConversationMessages(this.conversation.conversation.id)
      .subscribe((messages) => {
        this.messages = messages || [];
        this.notReadMessages = this.messages.filter((message) => !message.read);

        this.setMessagesAsRead();
        this.parseTargetModel();
      });

    this.emitterForm = this.formBuilder.group({
      draftMessage: new UntypedFormControl(''),
    });

    this.setEmitter();
  }

  private parseTargetModel() {
    const { targetModel, receptor } = this.conversation;
    const { targetType } = this.conversation.conversation;

    let conversationTitle = '';
    let subtitle = {};

    switch (targetType) {
      case 'Lead':
        conversationTitle = `Proyecto en c/ ${targetModel.address.address1}`;
        subtitle['name'] = `Comercial Cubicup - ${receptor.name}`;
        break;
      case 'BudgetItem':
        const {
          product: { title, description },
        } = targetModel;

        conversationTitle = title;
        subtitle['name'] = description || '- Sin descripción -';
        break;
      case 'Tracking':
        conversationTitle =
          targetModel.chapterTitle ?? 'Seguimiento de.........';
        subtitle['name'] = targetModel.title ?? '- Sin descripción -';

        break;
      default:
        break;
    }

    this.headerData = {
      title: conversationTitle,
      subtitle,
    };
  }

  private setMessagesAsRead() {
    this.store.select('user').subscribe((user) => {
      this.notReadMessages.forEach((message) => {
        if (this.context === 'agent' || this.context === 'login-client') {
          if (message.personId !== user.userId || message.clientId) {
            this.conversationService.setMessageAsRead(message.id).subscribe();
          }

          return;
        }

        // if (
        //   message.clientId !== user.userId ||
        //   message.personId
        // ) {
        //   this.conversationService.setMessageAsRead(message.id).subscribe();
        // }
      });
    });
  }

  closeConversation() {
    this.conversationService.closeConversation();
  }

  private getFirstMessageSession() {
    const budgetMessagesHash = this.localStorageService.get(
      'budgetMessagesHash',
    );

    if (!budgetMessagesHash) {
      return; // Is a conversation, not discussion
    }

    const date = budgetMessagesHash[this.conversation.targetModel.budgetId];

    if (!date) {
      budgetMessagesHash[this.conversation.targetModel.budgetId] = Date.now();
      this.localStorageService.set('budgetMessagesHash', budgetMessagesHash);

      return true;
    }

    const inTime = moment(date).diff(moment(Date.now()), 'minutes') < 30;

    if (inTime) {
      return;
    }

    budgetMessagesHash[this.conversation.targetModel.budgetId] = Date.now();
    this.localStorageService.set('budgetMessagesHash', budgetMessagesHash);

    return true;
  }

  sendMessage() {
    if (
      this.emitterForm.invalid ||
      !this.emitterForm.get('draftMessage').value
    ) {
      return;
    }

    this.conversationService
      .sendMessage({
        conversationID: this.conversation.conversation.id,
        messageBody: this.emitterForm.get('draftMessage').value,
        receptorIsStaff: this.conversation.receptorIsStaff,
        firstMessageSession: this.getFirstMessageSession(),
      })
      .subscribe((message: any) => {
        this.emitterForm.get('draftMessage').setValue('');

        if (!this.clipboardImage) {
          this.messages.push(message);
          return;
        }

        const messageWithImage = {
          ...message,
          image: this.clipboardImage,
        };

        this.messages.push(messageWithImage);
        this.handleFileUploaded(this.clipboardImage, message.id);
        this.clipboardImage = null;
      });
  }

  private isExtensionValid(fileName) {
    if (fileName.includes('.heic') || fileName.includes('.heif')) {
      this.notificationsService.showError('Formato HEIC no aceptado.');

      return;
    }

    if (fileName.includes('.psd')) {
      this.notificationsService.showError('Formato PSD no aceptado.');

      return;
    }

    if (fileName.includes('.gif')) {
      this.notificationsService.showError('Formato GIF no aceptado.');

      return;
    }

    return true;
  }

  uploadMessageDocument(fileInput, imageKey, imageName, messageId) {
    this.notificationsService.showSuccess('Subiendo fichero...');

    this.conversationService
      .uploadMessageDocument(imageKey, imageName, messageId)
      .pipe(
        switchMap((image: any) =>
          this.s3Service.s3uploadfile(fileInput, image.id),
        ),
      )
      .subscribe((s3FileResult: any) => {
        this.fileInput.nativeElement.value = '';
        this.clipboardImageUploaded.nativeElement.src = '';

        const { result } = s3FileResult;

        if (result) {
          this.notificationsService.showSuccess(
            'No hay suficiente memoria disponible',
          );

          return;
        }

        this.notificationsService.showSuccess('Fichero subido');
      });
  }

  private handleFileUploaded(file, messageID) {
    const imageKey = `conversation-${
      this.conversation.conversation.id
    }-${uuidv4()}`;
    const imageName = `conversation-${
      this.conversation.conversation.id
    }-attached-${uuidv4()}`;

    this.uploadMessageDocument(file, imageKey, imageName, messageID);
  }

  handleDrop(evt) {
    const files = evt.target.files;
    const file: File = files.length > 0 ? files.item(0) : null;

    if (!file) {
      this.clipboardImageUploaded.nativeElement.src = '';
      return;
    }

    if (!this.isExtensionValid(file.name)) {
      return;
    }

    this.clipboardImage = file;

    const preview = this.clipboardImageUploaded;
    const reader = new FileReader();

    reader.onloadend = function () {
      preview.nativeElement.src = reader.result;
    };

    reader.readAsDataURL(file);
  }
}

<div class="header">
  <div class="left">
    <mat-icon svgIcon="16px-notes"></mat-icon>
    <div class="title">
      <span>{{ 'your-personal-notes' | translate }}</span>
      <span class="subtitle">{{ 'notes-only-you' | translate }}</span>
    </div>
  </div>
  <button mat-icon-button mat-dialog-close>
    <mat-icon svgIcon="24px-close"></mat-icon>
  </button>
</div>

<form [formGroup]="editForm">
  <div class="angular-editor-wrapper" data-cy="angular-editor-wrapper">
    <angular-editor [config]="editorConfig" formControlName="editor">
    </angular-editor>
  </div>

  <div class="button-wrapper">
    <button
      type="submit"
      mat-flat-button
      color="primary"
      (click)="addNewNote()"
      data-cy="save-note"
    >
      {{ 'save-note' | translate }}
    </button>
  </div>
</form>

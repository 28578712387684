<form
  [formGroup]="registerForm"
  data-cy="register-form"
  (keyup.enter)="registerAgent()"
>
  <span class="title text-xxl">{{ 'create-your-account' | translate }}</span>
  <span class="title text-l">{{ 'register-subtitle' | translate }}</span>

  <div class="custom-input text-sm">
    <p>
      {{ 'user-name' | translate }}{{ requiredValidator('agentN') ? '*' : '' }}
    </p>

    <mat-form-field appearance="outline" color="primary">
      <input
        matInput
        type="text"
        formControlName="agentN"
        data-cy="user-name"
        autocomplete="name"
      />
      <mat-icon matSuffix svgIcon="24px-profile"></mat-icon>
      <mat-error
        class="email-required"
        *ngIf="registerForm.controls.agentN.hasError('required')"
        [innerHTML]="'field-required' | translate"
        data-cy="user-name-required"
      >
      </mat-error>
      <mat-error
        *ngIf="registerForm.controls.agentN.hasError('minlength')"
        [innerHTML]="'2-characters-error' | translate"
        data-cy="user-name-min-length"
      >
      </mat-error>
      <mat-error
        *ngIf="registerForm.controls.agentN.hasError('maxlength')"
        [innerHTML]="'100-characters-error' | translate"
        data-cy="user-name-max-length"
      >
      </mat-error>
    </mat-form-field>
  </div>

  <div class="custom-input text-sm">
    <p>{{ 'email' | translate }}{{ requiredValidator('email') ? '*' : '' }}</p>

    <mat-form-field appearance="outline" color="primary">
      <input
        matInput
        type="email"
        formControlName="email"
        data-cy="email"
        autocomplete="email"
      />
      <mat-icon matSuffix svgIcon="24px-mailClosed"></mat-icon>

      <mat-error
        class="email-required"
        *ngIf="registerForm.controls.email.hasError('required')"
        [innerHTML]="'field-required' | translate"
        data-cy="email-required"
      >
      </mat-error>
      <mat-error
        class="email-pattern"
        *ngIf="
          registerForm.controls.email.hasError('pattern') &&
          registerForm.controls.email.value.length > 5
        "
        [innerHTML]="'email-field' | translate"
        data-cy="email-pattern"
      >
      </mat-error>
    </mat-form-field>
  </div>

  <div class="custom-input text-sm">
    <p>
      {{ 'password' | translate }}{{ requiredValidator('password') ? '*' : '' }}
    </p>

    <mat-form-field appearance="outline" color="primary">
      <input
        matInput
        [type]="hidePassword ? 'password' : 'text'"
        formControlName="password"
        data-cy="password"
        autocomplete="new-password"
      />
      <mat-icon
        matSuffix
        *ngIf="hidePassword"
        (click)="hidePassword = !hidePassword"
        svgIcon="24px-hide"
      ></mat-icon>
      <mat-icon
        matSuffix
        *ngIf="!hidePassword"
        (click)="hidePassword = !hidePassword"
        svgIcon="16px-show"
      ></mat-icon>
      <mat-error
        class="password-required"
        *ngIf="registerForm.controls.password.hasError('required')"
        [innerHTML]="'field-required' | translate"
        data-cy="password-required"
      >
      </mat-error>
      <mat-error
        class="password-required"
        *ngIf="registerForm.controls.password.hasError('minlength')"
        [innerHTML]="'password-minlength-error' | translate"
        data-cy="password-min-length"
      >
      </mat-error>
      <mat-error
        class="password-required"
        *ngIf="registerForm.controls.password.hasError('maxlength')"
        [innerHTML]="'password-maxlength-error' | translate"
      >
      </mat-error>
    </mat-form-field>
  </div>

  <div class="two-fields">
    <div class="custom-input text-sm">
      <p>
        {{ 'phone-number' | translate
        }}{{ requiredValidator('phoneNumber') ? '*' : '' }}
      </p>

      <mat-form-field appearance="outline" color="primary">
        <input
          matInput
          appInputPhoneFormat
          type="telf"
          formControlName="phoneNumber"
          data-cy="phone"
        />
        <mat-icon matSuffix svgIcon="24px-phone"></mat-icon>

        <mat-error
          class="password-required"
          *ngIf="registerForm.controls.phoneNumber.hasError('required')"
          [innerHTML]="'field-required' | translate"
          data-cy="phone-required"
        >
        </mat-error>
        <mat-error
          class="password-required"
          *ngIf="registerForm.controls.phoneNumber.hasError('pattern')"
          [innerHTML]="'phone-number-field' | translate"
        >
        </mat-error>
        <mat-error
          class="password-required"
          *ngIf="registerForm.controls.phoneNumber.hasError('minlength')"
          [innerHTML]="'phone-minlength-error' | translate"
          data-cy="phone-min-length"
        >
        </mat-error>
        <mat-error
          class="password-required"
          *ngIf="registerForm.controls.phoneNumber.hasError('maxlength')"
          [innerHTML]="'phone-maxlength-error' | translate"
        >
        </mat-error>
      </mat-form-field>
    </div>

    <div class="custom-input text-sm">
      <p>
        {{ 'zip-code' | translate
        }}{{ requiredValidator('zipCode') ? '*' : '' }}
      </p>

      <mat-form-field appearance="outline" color="primary">
        <input
          matInput
          type="telf"
          formControlName="zipCode"
          data-cy="zip-code"
        />
        <mat-icon matSuffix svgIcon="24px-location"></mat-icon>

        <mat-error
          class="password-required"
          *ngIf="registerForm.get('zipCode').hasError('required')"
          [innerHTML]="'field-required' | translate"
          data-cy="zip-code-required"
        >
        </mat-error>
        <mat-error
          class="password-required"
          *ngIf="registerForm.get('zipCode').hasError('pattern')"
          [innerHTML]="'zip-code-field' | translate"
        >
        </mat-error>
        <mat-error
          class="password-required"
          *ngIf="registerForm.get('zipCode').hasError('minlength')"
          [innerHTML]="'zip-code-minlength-error' | translate"
          data-cy="zip-code-minlen-required"
        >
        </mat-error>
        <mat-error
          class="password-required"
          *ngIf="registerForm.get('zipCode').hasError('maxlength')"
          [innerHTML]="'zip-code-maxlength-error' | translate"
        >
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <mat-checkbox
    class="text-sm"
    formControlName="termsAndConditions"
    data-cy="terms-and-conditions"
  >
    {{ 'accept-terms-and-conditions' | translate }}

    <a
      target="no-blank"
      href="
      http://s3.eu-central-1.amazonaws.com/cubicup.filesystem.pro/CGS+Cubicup+2021.pdf"
      class="primary-link"
    >
      {{ 'terms-and-conditions' | translate }}
    </a>
  </mat-checkbox>
</form>

<div class="action">
  <button
    mat-flat-button
    color="primary"
    type="submit"
    (click)="registerAgent()"
    data-cy="submit"
    [disabled]="
      registerForm.invalid ||
      !registerForm.controls.termsAndConditions.value ||
      sendingForm
    "
  >
    {{ registerButtonLabel | translate }}
  </button>

  <span class="text-xs">
    {{ 'already-account' | translate }}

    <a
      *ngIf="utmSource; else noUtm"
      routerLink="/login"
      [queryParams]="utmSource"
      class="primary-link"
    >
      {{ 'enter-here' | translate }}
    </a>

    <ng-template #noUtm>
      <a routerLink="/login" class="primary-link">
        {{ 'enter-here' | translate }}
      </a>
    </ng-template>
  </span>
</div>

<!-- <section
  *ngIf="target && target.tracking"
  class="up tracking">
  <span (click)="goToTracking()">
    >> {{ 'go-to-tracking' | translate }}
  </span>
</section> -->

<section class="up">
  <mat-icon svgIcon="16px-locked"></mat-icon>
  <div class="title">
    <span>{{ 'your-personal-notes' | translate }}</span>
    <span class="subtitle">{{ 'notes-only-you' | translate }}</span>
  </div>
</section>

<section class="down">
  <div class="notes" matRipple (click)="openNotesModal()">
    <div class="left">
      <mat-icon svgIcon="16px-notes"></mat-icon>
      <span> {{ 'my-notes' | translate }}</span>
      <span data-cy="notes-count"> ({{ notes?.length }}) </span>
    </div>

    <button mat-button color="primary" data-cy="add-note-card">
      {{ 'add' | translate }}
    </button>
  </div>
</section>

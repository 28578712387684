
<ng-container *ngIf="lead || agent">
  <img *ngIf="image; else defaultAvatar" [src]="image" alt="avatar" />
  <ng-template #defaultAvatar>
    <app-initial-letters [name]="name"></app-initial-letters>
  </ng-template>

  <div class="details text-xs">
    <div class="identification">
      <p class="name">{{ name | titlecase }}</p>
      <p [innerHtml]="idNumber" class="id-number"></p>
    </div>
    <p>{{ address | titlecase }}</p>
    <p>{{ city | titlecase }}, {{ zipCode }}</p>
    <p>{{ email | lowercase }}</p>
    <p>{{ phoneNumber }}</p>
    <p>{{ code }}</p>
    <p>{{ shortCode }}</p>
  </div>
</ng-container>

import { Certification, DiscountItem } from '@core/sdk';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { certificationAdapter } from './certification.adapter';
import { State } from './certification.reducer';

export const selectCertificationState = createFeatureSelector<State>(
  'certification',
);

export const {
  selectAll: selectAllCertification,
  selectEntities: selectCertificationEntities,
} = certificationAdapter.getSelectors(selectCertificationState);

export const selectCertificationById = (certificationId: string) =>
  createSelector(
    selectCertificationEntities,
    (certificationEntities): Certification =>
      certificationEntities[certificationId],
  );

export const selectCertificationStateById = (certificationId: string) =>
  createSelector(
    selectCertificationEntities,
    (certificationEntities): string =>
      certificationEntities[certificationId].state,
  );

export const selectCertificationDiscountsById = (certificationId: string) =>
  createSelector(
    selectCertificationEntities,
    (certificationEntities): DiscountItem[] =>
      certificationEntities[certificationId].discounts,
  );

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { S3Service } from '@core/services/s3.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  AbstractControl,
} from '@angular/forms';
import environment from 'src/environments/environment';
import { S3File } from '@core/sdk';
import { NotificationsService } from '@core/services/notifications.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent implements OnInit, OnChanges {
  @Input() resetInputFile$: Subject<boolean> = new Subject<boolean>();
  @Input() document: any;
  @Input() showDates = false;
  @Input() datesRequired = false;
  @Input() editable = false;
  @Input() kind = 'edit';
  @Input() multiple = false;
  @Output()
  saveDocumentEmitter: EventEmitter<Document> = new EventEmitter<Document>();
  @Output()
  deleteDocumentEmitter: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('documentForm') documentForm;
  @ViewChild('fileInput') fileInput;
  //formDialog: MatDialogRef<ViewChild>;

  progress: number = null;
  downloading = false;
  documentDatesFormGroup: UntypedFormGroup;

  constructor(
    private s3Service: S3Service,
    private fb: UntypedFormBuilder,
    private notificationsService: NotificationsService,
  ) {}

  ngOnInit() {
    if (this.showDates) {
      this.initializeDatesForm();
    }

    this.resetInputFile$.subscribe((value) => {
      if (value) {
        this.fileInput.nativeElement.value = '';

        // Why??? Because the input file type doesn't clean himself when a user delete a file uploaded
      }
    });
  }

  ngOnChanges() {
    if (!this.document) {
      console.error('document not defined', this.document);
    }
  }

  private initializeDatesForm() {
    const datesAreRequired = this.datesRequired ? [Validators.required] : [];

    this.documentDatesFormGroup = this.fb.group({
      valueDate: new UntypedFormControl(
        this.document ? this.document.valueDate : '',
        datesAreRequired,
      ),
      dueDate: new UntypedFormControl(
        this.document ? this.document.dueDate : '',
        datesAreRequired,
      ),
    });

    this.documentDatesFormGroup.controls.valueDate.valueChanges.subscribe(
      (data) => {
        this.document.valueDate = data;
        this.update();
      },
    );

    this.documentDatesFormGroup.controls.dueDate.valueChanges.subscribe(
      (data) => {
        this.document.dueDate = data;
        this.update();
      },
    );
  }

  setAutomaticDocument() {
    this.fileInput.nativeElement.click();
  }

  handleFileSelect(evt) {
    const files = evt.target.files;
    const file: File = files.length > 0 ? files.item(0) : null;

    if (!file) {
      return;
    }

    this.s3Service
      .s3uploadfile(file, this.document.id)
      .subscribe((s3FileResult) => {
          this.fileInput.nativeElement.value=null;
        if (this.document.versions) {
          this.document.versions = [...this.document.versions, s3FileResult];

          this.update();
          return;
        }

        this.document.versions = [s3FileResult];        
        this.update();
      });
  }

  update() {
    this.saveDocumentEmitter.emit(this.document);
  }

  private downloadFile(fileBlob, filename) {
    const url = window.URL.createObjectURL(new Blob([fileBlob]));
    const element = document.createElement('a');

    element.setAttribute('href', url);
    element.setAttribute('download', filename);

    element.style.display = 'none';

    document.body.appendChild(element);

    element.click();

    window.URL.revokeObjectURL(url);
    element.remove();
  }

  getFile(fileInfo) {
    const { fileName, file: fileID, fileUrl } = fileInfo;

    const fileNameSplitted = fileName.split('.');
    const fileExtension = fileNameSplitted.pop();
    const fileNameDownload = `${fileID}.${fileExtension}`;

    this.s3Service
      .getFileUrlOld(fileNameDownload, this.document.id)
      .subscribe((downloadUrl) => this.downloadFile(downloadUrl, fileName));
  }

  delete(fileInfo) {
    const { id: versionToRemoveID } = fileInfo;

    this.s3Service
      .removeFile(versionToRemoveID, this.document.id)
      .subscribe(() => {
        const vesionsWithoutVersionRemoved = this.document.versions.filter(
          (version: S3File) => version.id !== versionToRemoveID,
        );

        this.document.versions = vesionsWithoutVersionRemoved;
        this.document.valueDate = null;
        this.document.dueDate = null;

        this.deleteDocumentEmitter.emit();
      });
  }

  requiredValidator(controlName) {
    const { validator } = this.documentDatesFormGroup.controls[controlName];

    if (!validator) {
      return false;
    }

    const validation = validator({} as AbstractControl);

    if (validation && validation.required) {
      return true;
    }

    return false;
  }
}

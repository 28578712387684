const integrationUrl = 'api-int.cubicup.com';

const environment = {
  production: false,
  integration: true,
  development: false,
  name: 'integration',
  frontBaseUrl: 'https://int.cubicup.com',
  apiBaseUrl: 'https://api-int.cubicup.com',
  test: false,
  maxFilesToUpload: 10,
  apiVersion: 'api/v2', // { 'api' | 'api/v2 }
  tpvService: 'https://tpv-service-xolybpgupq-no.a.run.app',
  subscriptions: {
    notifications: {
      clientEnabled: true,
      agentEnabled: false,
      default: true,
      frequency: 60000 * 10, // milliseconds // 10 minutes,
    },
    notices: {
      enabled: true,
      frequency: 60000 * 5, // 5 minutes
    },
  },
  microservices: {
    excelBaseUrl: 'https://api-int.cubicup.com/microservices/excel',
    pdfBaseUrl: 'https://api-int.cubicup.com/microservices/pdf',
    cubiFilesUrl: 'https://api-int.cubicup.com/microservices/filesmanager',
    cypeGeneratorUrl: 'https://generatebbdd-xolybpgupq-uc.a.run.app'
  },
  helpLink: {
    agent:
      'https://cubicup.zendesk.com/hc/es/categories/360002200159-Empresa-de-Reformas',
    client:
      'https://cubicup.zendesk.com/hc/es/categories/360002181540-Propietarios-',
  },
  shopLink: 'https://shop.cubicup.com',
  holded:{ apiFind: true, apiCreate: false },
  orchestrator:{ 
    autodeskForge:{ apiCalls: false }
  },
  stripe: {
    // Enabled added to avoid Sentry errors. We do not use Stripe anymore but there are a lot
    // of work that we do not want to remove. So we opt by adding this property to avoid errors
    enabled: false,
    clientId: 'ca_FWx2gPvHfCIKJnUAeXEwfLBLJs1q7zWJ',
    responseType: 'code',
    scope: 'read_write',
    authorizeUrl: '',
    publicSecret: 'pk_test_dddWKz7NbQZRILIMiSk7OUcS',
  },
  google: {
    analyticsCode: 'UA-159641191-4',
    maps: 'AIzaSyAQKtqJkxyna-RHQhOWQyxyZXLvMRG3fc8',
    active: false
  },
  bing: {
    ti: '15328073',
  },
  oneSignal: {
    appID: 'c7b6950f-6b8e-4310-842b-3d06cc0070d9',
  },
  mixpanel: {
    projectToken: 'eea251021cd1193ba7b0e0527f90c647',
    active: true
  },
  logRocket: {
    appID: 'gj4ges/cubicup-pre',
  },
  forgeApi: {
    defaultUrn: "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6bnJ5dWtueHdid3dtM2d4aXhsdGR2bmFhYXhjeGd0OWEtdGVtcGxhdGVzL1YlMjAtJTIwRTMlMjBDYXJsb3NfdjAzX0lGQ19XRUIlMjAoMSkuaWZj"
  },
  redsysRedirectionUrl: 'https://sis-t.redsys.es:25443/sis/realizarPago',
  webSocket: {
    options: {
      transports: ['websocket'],
      reconnectionAttempts: 1,
    },
    topics: {
      documentNotificationEvents: 'documents-notification-events',
      tpvNotificationEvents: 'tpv-notification-events'
    }
  },
};

environment.stripe.authorizeUrl = `https://connect.stripe.com/express/oauth/authorize?
client_id=${environment.stripe.clientId}
&state=#state#
&stripe_user[email]=#email#
&stripe_user[country]=ES
&stripe_user[phone_number]=#phoneNumber#
&stripe_user[business_name]=#businessName#
&stripe_user[first_name]=#firstName#
&stripe_user[last_name]=#lastName#
&stripe_user[physical_product]=false
&stripe_user[currency]=eur
&redirect_uri=https://${integrationUrl}/link/stripe`;

export default environment;

import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-budget-help',
  templateUrl: './budget-help.component.html',
  styleUrls: ['./budget-help.component.scss']
})
export class BudgetHelpComponent implements OnInit {
  viewMode: string;
  videosUrls = [];
  videoIndex = 0;
  mustShowForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<BudgetHelpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.videosUrls = this.data.videosUrls;

    this.mustShowForm = this.formBuilder.group({
      dontShowModal: new UntypedFormControl(false)
    });
  }

  backVideo() {
    if (this.videoIndex === 0) {
      return;
    }

    this.videoIndex--;
  }

  nextVideo() {
    if (this.videoIndex === (this.videosUrls.length - 1)) {
      return;
    }

    this.videoIndex++;
  }

  submit() {
    this.dialogRef.close({
      dontShowModal: this.mustShowForm.get('dontShowModal').value,
    });
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InvoiceGuard  {
  
  constructor(private store: Store<{context:any}>, private router: Router) {}

  containProjectId(route: ActivatedRouteSnapshot): boolean {
    return route.params?.projectID ? true : false;
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select('context'),
      take(1),
      map(({ value: context, currentProjectId }) => {
        switch (context) {
          case 'agent':
              return true;
          case 'login-client':
            this.router.navigate(['logged-client', 'invoices']);
            return false;
          default:
            return false;
        }
      })
    );
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Permission, Role } from "@core/sdk/models";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class HasPermissionService {
  private permissions: Permission[] = [];
  private roles: Role[] = [];
  private userType: string;
  private bannedPermissions: Permission[] = [];

  user$: Observable<any> = this.store.select(
    'user',
  );

  constructor(
    private store: Store<{ context: any; user: any }>,
    private http: HttpClient
  ) {
    this.user$
      .pipe(take(1))
      .subscribe((user) => {
        this.roles = user.roles;
        this.userType = user.userType;
        this.bannedPermissions = user.bannedPermissions
      })
  }

  /**
   * Checks if current user has a specified permission.
   * @param permissionSlug
   * @returns
   */
  checkPermission(permissionSlug: string): boolean {
    if (this.userType === 'client') return false;

    const hasPermissionInRoles = this.roles?.some(role =>
      role.permissions.some(permission => permission.slug === permissionSlug)
    ) ?? false;

    const hasPermissionInBannedlist = this.bannedPermissions?.some(permission => permission.slug === permissionSlug) ?? false;

    return hasPermissionInRoles && !hasPermissionInBannedlist;
  }


  /**
   * Checks if current user has a specified role.
   * @param roleName
   * @returns
   */
  checkRole(roleName: string): boolean {
    if (this.userType === 'client') return false;

    return this.roles?.some((role) => role.name === roleName)
  }

  /**
   * Check if current user can switch between the different agents of his company.
   * List of allowedRoles is declared in header component.
   * TODO: Move list allowedRoles to this service.
   * @param allowedRoles
   * @returns
   */
  isAllowedUserToSwitch(allowedRoles) {
    const res = this.roles?.map((r) => { return r.name })
      .some((roleName) => allowedRoles.includes(roleName));

    return res ?? false;
  }

  /**
   * Store current user roles in memory because of make all checks synchronous.
   * This is necessary because appHasPermission directive cannot be async.
   * @param roles
   */
  updateRoles(roles) {
    this.roles = roles;

    this.updatePermissions();
  }

  /**
   * Store all permissions of the current user in a list for simplify check permission process.
   */
  updatePermissions() {
    this.permissions = this.roles?.map((role) => {
      return role.permissions
    }).flat();
  }
}
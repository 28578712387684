import { OnInit, Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BudgetInterface } from '@core/sdk';

@Component({
  selector: 'app-payment-terms',
  templateUrl: './payment-terms.component.html',
  styleUrls: ['./payment-terms.component.scss'],
})
export class PaymentTermsComponent implements OnInit {
  @Input() budget: BudgetInterface;
  @Input() disableFields = false;
  @Input() subtitle: string = 'payment-terms-description';

  totalPercentages;
  paymentForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    const { signal, ahead, progress, end } = this.budget.paymentMethod;

    this.paymentForm = this.formBuilder.group({
      signal: [
        { value: signal, disabled: this.disableFields },
        Validators.required,
      ],
      ahead: [
        { value: ahead, disabled: this.disableFields },
        Validators.required,
      ],
      progress: [
        { value: progress, disabled: this.disableFields },
        Validators.required,
      ],
      end: [{ value: end, disabled: this.disableFields }, Validators.required],
      taxes: [
        { value: this.budget.taxPercent / 100, disabled: this.disableFields },
        Validators.required,
      ],
    });

    this.totalPercentages =
      parseInt(ahead, 10) +
      parseInt(signal, 10) +
      parseInt(progress, 10) +
      parseInt(end, 10);
  }
}

<ng-container *ngIf="hideSidebarAndHeader$ | async; else sidebarAndHeaderTemplate">
  <app-loader></app-loader>
  <router-outlet></router-outlet>
</ng-container>

<ng-template #sidebarAndHeaderTemplate>
  <ng-container *ngIf="hideSidebar$ | async; else sidebarTemplate">
    <app-header showLogo="true" hideMenu="true"></app-header>
    <app-loader></app-loader>
    <div class="router-outlet">
      <router-outlet></router-outlet>
    </div>
  </ng-container>
  
  <ng-template #sidebarTemplate>
    <ng-container *ngIf="!isMobile; else bottomnavTemplate">
      <app-sidenav>
        <router-outlet></router-outlet>
      </app-sidenav>
    </ng-container>

    <ng-template #bottomnavTemplate>
        <app-sidenav>
          <router-outlet></router-outlet>
        </app-sidenav>
      <app-bottomnav></app-bottomnav>
    </ng-template>


  <app-conversation
    *ngIf="toggleConversation$ | async as conversation"
    [conversation]="conversation"
  >
  </app-conversation>

  <app-conversation
    *ngIf="toggleDiscussion$ | async as conversation"
    [conversation]="conversation"
  >
  </app-conversation>

  <app-notifications-center
    *ngIf="(toggleNotificationsCenter$ | async).toggle"
    [notifications]="(toggleNotificationsCenter$ | async).notifications"
  >
  </app-notifications-center>

  <app-reminders-center
    *ngIf="(toggleRemindersCenter$ | async).toggle"
    [reminders]="(toggleRemindersCenter$ | async).reminders"
  >
  </app-reminders-center>
</ng-template>

<ng-container *ngIf="(toggleCarousel$ | async).toggle">
  <div class="carousel-container" (click)="closeCarousel()">
    <carousel
      [slides]="(toggleCarousel$ | async).images"
      [imageSelected]="(toggleCarousel$ | async).imageSelected"
    ></carousel>
  </div>
</ng-container>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  Lead,
  AgentInterface,
  ClientInterface,
  LeadInterface,
  UserInterface
} from 'src/app/core/sdk';
import { NotificationsService } from '@core/services/notifications.service';
import { LeadService } from '@core/services/lead.service';
import { Store } from '@ngrx/store';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CreateLongTextModalComponent } from '@shared/dialogs/create-long-text-dialog/create-long-text-modal.component';
import { ClientService } from '@core/services/client.service';
import { ResponsiveService } from '@core/services/responsive.service';
import { BudgetHelpComponent } from 'src/app/pages/budget-edition/budget-help/budget-help.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-summary',
  templateUrl: './project-summary.component.html',
  styleUrls: ['./project-summary.component.scss'],
})
export class ProjectSummaryComponent implements OnInit {
  @Input() project: Lead;
  @Output() newBudget = new EventEmitter<void>();
  @Output() estimateBudget = new EventEmitter<void>();

  clientProfileImage = '../assets/icons/PortraitPlaceholder.png';
  projectIcon = '24px:Bathroom';
  projectTypeDescription = 'bathroom';
  range = {
    lower: 0,
    top: 0,
  };
  clientName = '';
 avaliableBudget: string; 

  stars = [
    { filled: false },
    { filled: false },
    { filled: false },
    { filled: false },
    { filled: false },
  ];

  agentFeeling = 0;
  visitScoring = 0;
  clientScoring = 0;
  context: string;
  viewMode: string;
  isSuperAdmin = false;

  constructor(
    private notificationsService: NotificationsService,
    private leadService: LeadService,
    private clientService: ClientService,
    private matDialog: MatDialog,
    private responsiveService: ResponsiveService,
    private store: Store<{
      agent: AgentInterface;
      client: ClientInterface;
      projects: LeadInterface[];
      context: any;
      user: UserInterface;
    }>,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.store.select('context').subscribe(
      ({ value: context }) => {
        this.context = context;

        this.initializeSummary();
        this.setLowerTopRange();
        this.setAvalaibleBudget();
      });

    this.store
    .select('user')
    .subscribe((user) => (this.isSuperAdmin = user.superAdmin));

    this.responsiveService.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );
  }

  private initializeSummary() {
    switch (this.project.leadName) {
      case 'Baño':
        this.projectIcon = 'jobType:bathroom';
        this.projectTypeDescription = 'bathroom';

        break;
      case 'Cocina':
        this.projectIcon = 'jobType:kitchen';
        this.projectTypeDescription = 'kitchen';

        break;
      case 'Integral':
        this.projectIcon = 'jobType:integral';
        this.projectTypeDescription = 'integral';

        break;
      default:
        break;
    }

    this.agentFeeling = this.project.agentFeeling || 0;
    this.visitScoring = this.project.visitScoring || 0;
  }

  setLowerTopRange() {
    if (this.project.originalBriefing) {
      // TODO: Comprobar si tiene el formato normal o el formato del onboarding

      if (this.project.originalBriefing.quizId) {
        return;
      }

      const [lowerRange, topRange] = this.project.originalBriefing.filter(
        (field) => field.name === 'lowerRange' || field.name === 'topRange',
      );

      if (!lowerRange || !topRange) {
        return;
      }

      this.range = {
        lower: lowerRange.value.replace('€', '').trim(),
        top: topRange.value.replace('€', '').trim(),
      };

      this.clientName = this.project.contact.name;
    }
  }

  setAvalaibleBudget() {
    if (
      this.project.originalBriefing &&
      !this.project.originalBriefing.quizId
    ) {
      const [budgetAvailable] = this.project.originalBriefing.filter(
        (field) => field.name === 'budgetAvailable',
      );

      if (budgetAvailable) {
       this.avaliableBudget = budgetAvailable;
      }
    }

    if (this.project.checkedBriefing) {
      const [budgetAvailable] = this.project.checkedBriefing.filter(
        (field) => field.name === 'budgetAvailable',
      );

      if (budgetAvailable) {
       this.avaliableBudget = budgetAvailable.value;
      }
    }
  }

  generateBudget() {
    this.newBudget.emit();
  }

  simulateBudget() {
    this.estimateBudget.emit();
  }

  setProjectFeeling(agentFeelingIndex) {
    const finalFeeling = agentFeelingIndex + 1;

    this.leadService
      .setProjectFeeling(this.project.id, finalFeeling)
      .subscribe(() => {
        this.notificationsService.showSuccess('Puntuación actualizada');
        this.agentFeeling = finalFeeling;
      });
  }

  setProjectClientScoring(clientScoringIndex) {
    const finalClientScore = clientScoringIndex + 1;

    this.leadService
      .setProjectClientScoring(this.project.id, finalClientScore)
      .subscribe(() => {
        this.notificationsService.showSuccess('Puntuación actualizada');
        this.agentFeeling = finalClientScore;
      });
  }

  writeAgentReview() {
    const createReviewModal = this.dialog.open(CreateLongTextModalComponent, {
      width: '500px',
      height: '350px',
      data: {
        title: 'send-agent-review',
        subtitle: 'send-agent-review-subtitle',
        showCloseButton: true,
        showCancelButton: true,
        svgIcon: '24px-draw',
        cta: 'send-review',
      },
    });

    createReviewModal.afterClosed().subscribe((review) => {
      if (!review) {
        return;
      }

      this.clientService
        .sendAgentReview(review, this.project.agentId)
        .subscribe(() =>
          this.notificationsService.showSuccess('review-sended'),
        );
    });
  }

  goToTracking() {
    switch (this.context) {
      case 'agent':
        this.router.navigate(['tracking', this.project.id])
        return;
      case 'login-client':
        this.router.navigate(['logged-client', 'tracking', this.project.id])
        return;
    
      default:
        break;
    }
  }

  viewHelp() {
    let modalConfiguration = {
      width: '613px',
      height: '480px',
      maxWidth: '100vw',
      data: {
        title: 'help',
        subtitle: 'how-create-new-budget',
        videoWidth: '549',
        videoHeight: '308',
        showCancelButton: false,
        showCloseButton: true,
        videosUrls: [
          {
            slug: 'how-create-budget-scratch',
            url: 'https://www.youtube.com/embed/UKKDczYk5Ok'
          },
          {
            slug: 'how-create-budget-template',
            url: 'https://www.youtube.com/embed/jThtsFJ5cb8'
          },
          {
            slug: 'how-create-budget-BC3',
            url: 'https://www.youtube.com/embed/LylgLynk7N8'
          }
        ]
      },
    };

    if (this.viewMode === 'handset') {
      modalConfiguration = {
        ...modalConfiguration,
        width: '100%',
        maxWidth: '100vw',
        height: '100%',
        data: {
          ...modalConfiguration.data,
          videoWidth: '100%',
          videoHeight: '100%',
        }
      }
    }

    const decisionModal = this.matDialog.open(BudgetHelpComponent, modalConfiguration);

    decisionModal.afterClosed().subscribe((data) => {
      if (!data) {
        return;
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import environment from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BudgetExcelService {
  constructor(private httpClient: HttpClient) {}

  getExcel(budget) {
    const url = `${environment.microservices.excelBaseUrl}/jsontoexcel`;

    return this.httpClient.post(url, budget, { responseType: 'blob' });
  }
}

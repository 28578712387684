import { Component, OnInit, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EventInterface } from '@core/sdk';
import environment from '@environment';

@Component({
  selector: 'app-visit-activity',
  templateUrl: './visit-activity.component.html',
  styleUrls: ['./visit-activity.component.scss'],
})
export class VisitActivityComponent implements OnInit {
  @Input() visit: EventInterface;

  constructor(private router: Router) {}

  ngOnInit() {}

  goToAgentPortfolio() {
    if (this.visit.agent.portfolio.state !== 'public') {
      return;
    }

    window.open(
      `${environment.frontBaseUrl}/landing/${this.visit.agent.id}`,
      '_blank',
    );
  }
}

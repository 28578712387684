/* eslint-disable */

declare var Object: any;
export interface ReasonInterface {
  reason: string;
  reasonCode: string;
  type?: string;
  id?: number;
  createdAt: Date;
  updatedAt: Date;
  modelActive?: any;
  options?: any[string];
}

export class Reason implements ReasonInterface {
  'reason': string;
  'reasonCode': string;
  'type': string;
  'id': number;
  'createdAt': Date;
  'updatedAt': Date;
  'modelActive': any;
  'options': any[string];
  'index': number;
  constructor(data?: ReasonInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Reason`.
   */
  public static getModelName() {
    return 'Reason';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Reason for dynamic purposes.
   **/
  public static factory(data: ReasonInterface): Reason {
    return new Reason(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Reason',
      plural: 'Reasons',
      path: 'Reasons',
      idName: 'id',
      properties: {
        reason: {
          name: 'reason',
          type: 'string',
        },
        reasonCode: {
          name: 'reasonCode',
          type: 'string',
        },
        type: {
          name: 'type',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'number',
        },
        createdAt: {
          name: 'createdAt',
          type: 'Date',
        },
        updatedAt: {
          name: 'updatedAt',
          type: 'Date',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
        options: {
          name: 'description',
          type: 'array',
        },
        index: {
          name: 'index',
          type: 'number',
        }
      },
      relations: {},
    };
  }
}

import { Injectable } from '@angular/core';
import { Budget } from '@core/sdk';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap } from 'rxjs/operators';
import { BudgetEditionApiService } from './budget-edition-api.service';
import {
  addBudget,
  getBudgetRequestStarted,
  getBudgetRequestSuccess,
  updateBudget,
  updateBudgetRequestStarted,
  updateBudgetRequestSuccess,
} from './budget-editor.actions';

@Injectable()
export class BudgetEditionEffects {
  public getBudget$ = this.getBudget();
  public addBudget$ = this.addBudget();
  public updateBudget$ = this.updateBudgetStart();
  public updateBudgetSucced$ = this.updateBudgetSucced();

  constructor(
    private actions$: Actions,
    private budgetEditionApi: BudgetEditionApiService,
  ) {}

  private getBudget() {
    return createEffect(() => {
      return this.actions$.pipe(
        ofType(getBudgetRequestStarted),
        mergeMap(({ payload }: { payload: string }) =>
          this.budgetEditionApi.getBudget(payload)
        ),
        filter(Boolean),
        map((payload: Budget) => getBudgetRequestSuccess({ payload })),
      );
    });
  }

  private addBudget() {
    return createEffect(() => {
      return this.actions$.pipe(
        ofType(getBudgetRequestSuccess),
        map(({ payload }: { payload: Budget }) => addBudget({ payload })),
      );
    });
  }

  private updateBudgetStart() {
    return createEffect(() => {
      return this.actions$.pipe(
        ofType(updateBudgetRequestStarted),
        mergeMap(({ payload }: { payload: Partial<Budget> }) =>
          this.budgetEditionApi.updateBudget(payload),
        ),
        filter(Boolean),
        map((payload: Budget) => updateBudgetRequestSuccess({ payload })),
      );
    });
  }

  private updateBudgetSucced() {
    return createEffect(() => {
      return this.actions$.pipe(
        ofType(updateBudgetRequestSuccess),
        map(({ payload }: { payload: Budget }) =>
          updateBudget({ payload: { id: payload.id, changes: payload } }),
        ),
      );
    });
  }
}

import { createAction, props } from '@ngrx/store';
import { Budget } from '@core/sdk';
import { Update } from '@ngrx/entity';

export const getBudgetsRequestSuccess = createAction(
  '[Budgets Component] GET_BUDGETS_SUCCESS',
  props<{ budgets: any }>(),
);

export const getBudgetsRequestStarted = createAction(
  '[Budgets Component] GET_BUDGETS_STARTED',
  props<{ projectId: string; budgets?: Budget[] }>()
);

export const BudgetAddAction = createAction(
  '[Budgets Component] Add',
  props<Budget>(),
);

export const BudgetUpdateAction = createAction(
  '[Budget/API] Update User',
  props<{ update: Update<Budget> }>(),
);

export const BudgetsAddAction = createAction(
  '[Budget/API] Add Budgets',
  props<{ budgets: Budget[] }>(),
);

export const BudgetDeleteAction = createAction(
  '[Budget/API] Delete Budget',
  props<{ id: string }>(),
);
export const BudgetsClearAction = createAction('[Budget/API] Clear Budgets');

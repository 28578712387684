import { Budget, Chapter } from '@core/sdk';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { logoutAction } from '../actions/meta.action';
import {
  addBudget,
  clearEntities,
  deleteBudgetChapter,
  updateBudget,
  updateBudgetChapterList,
  updateBudgetChapters,
} from './budget-editor.actions';

export interface State extends EntityState<Budget> {
  selectedBudgetId: string | null;
}

export const budgetEditorAdapter: EntityAdapter<Budget> = createEntityAdapter<Budget>();

export const initialState = budgetEditorAdapter.getInitialState({
  selectedBudgetId: null,
});

const budgetEditionReducerFn = createReducer(
  initialState,
  on(addBudget, (state, { payload }) =>
    budgetEditorAdapter.addOne(payload, state),
  ),
  on(addBudget, (state, { payload }) => {
    return { ...state, selectedBudgetId: payload.id };
  }),
  on(updateBudget, (state, { payload }) =>
    budgetEditorAdapter.updateOne(payload, state),
  ),
  on(
    updateBudgetChapterList,
    (state, { chapterId, budgetId, chapterOrder }) => {
      const selectedBudget = state.entities[budgetId];

      const newChapter = {
        id: chapterId,
        budgetId,
        order: chapterOrder,
      } as Chapter;

      const newChapterArray = selectedBudget.chapters
        ? selectedBudget.chapters
            .concat(newChapter)
            .sort((a: Chapter, b: Chapter): number => a.order - b.order)
        : [newChapter];

      return budgetEditorAdapter.updateOne(
        { changes: { chapters: newChapterArray }, id: budgetId },
        state,
      );
    },
  ),
  on(updateBudgetChapters, (state, { payload }) => {
    const budgetId = payload[0].budgetId;

    return budgetEditorAdapter.updateOne(
      { changes: { chapters: payload }, id: budgetId },
      state,
    );
  }),
  on(clearEntities, (state) => budgetEditorAdapter.removeAll(state)),
  on(logoutAction, (state) => budgetEditorAdapter.removeAll(state)),

  on(deleteBudgetChapter, (state, { payload }) => {
    // This one need to be reviewed
    const currentBudget = state.entities[state.selectedBudgetId];
    const postDeleteChapters = currentBudget.chapters.filter(
      (chapter) => chapter.id !== payload,
    );
    return budgetEditorAdapter.updateOne(
      { changes: { chapters: postDeleteChapters }, id: state.selectedBudgetId },
      state,
    );
  }),
);

export function budgetEditionReducer(state = initialState, action: Action) {
  return budgetEditionReducerFn(state, action);
}

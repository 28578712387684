import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  OnDestroy,
  HostBinding,
  Optional,
  Self,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgControl } from '@angular/forms';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { Subject } from 'rxjs';
import environment from '../../../environments/environment';
import { CardErrorState } from '../interfaces';
import { StripCardInfo } from '../models';
import { PaymentSumaryService } from '../../core/services/payment-sumary.service';
import { takeUntil } from 'rxjs/operators';
import { StripeService } from '../../core/services/stripe.service';

declare const stripe;
declare const elements;

@Component({
  selector: 'app-stripe-input',
  templateUrl: './stripe-input.component.html',
  styleUrls: ['./stripe-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: StripeInputComponent },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripeInputComponent
  implements OnInit, OnDestroy, MatFormFieldControl<StripCardInfo> {
  static nextId = 0;

  @ViewChild('cardElement', { static: true }) cardElement: ElementRef;

  @HostBinding() id = `strip-input-component-${StripeInputComponent.nextId++}`;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @HostBinding('attr.aria-describedby') describedBy = '';

  @Input()
  get value(): StripCardInfo | null {
    if (this.card) {
      return new StripCardInfo(this.card, '', '');
    }
    return null;
  }

  set value(cardInfo: StripCardInfo | null) {
    this.card = elements.create('card', {
      style: { base: { fontSmoothing: 'antialiased', lineHeight: '20px' } },
    });

    cardInfo = cardInfo || new StripCardInfo(this.card, '', '');
    this.stateChanges.next();
    this.changeDetector.markForCheck();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
    this.changeDetector.markForCheck();
  }

  @Input()
  get required() {
    return this._required;
  }

  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
    this.changeDetector.markForCheck();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
    this.changeDetector.markForCheck();
  }

  @Input() clientSecret: string;

  @Output() cardErrors: EventEmitter<CardErrorState> = new EventEmitter();
  @Output() cardInfo: EventEmitter<StripCardInfo> = new EventEmitter();
  @Output() paymentProcessStatus: EventEmitter<string> = new EventEmitter();

  private _placeholder: string;
  private _empty = true;
  private _required = false;
  private _disabled = false;

  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'stripe-input-component';
  card;

  unsubscriber$ = new Subject();

  onChange = (_: any) => {};
  onTouch = () => {};

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private paymentSumaryService: PaymentSumaryService,
    private stripeService: StripeService,
    private changeDetector: ChangeDetectorRef,
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (elements.getElement('card')) {
      this.card = elements.getElement('card');
    } else {
      this.card = elements.create('card', {
        style: { base: { fontSmoothing: 'antialiased', lineHeight: '20px' } },
      });
    }

    this.card.mount('#cardElement');

    this.card.on('focus', () => {
      this.focused = true;
      this.stateChanges.next();
      this.changeDetector.markForCheck();
    });

    this.card.on('blur', () => {
      this.focused = false;
      this.stateChanges.next();
      this.changeDetector.markForCheck();
    });

    this.card.on('change', ({ empty, complete, error, value, brand }) => {
      if (empty) {
        this._empty = true;
        this.stateChanges.next();
        this.changeDetector.markForCheck();
      } else {
        this._empty = false;
        this.stateChanges.next();
        this.changeDetector.markForCheck();
      }

      if (complete) {
        this.errorState = false;
        this.stateChanges.next();
        this.changeDetector.markForCheck();
        this.cardErrors.emit();
        this.cardInfo.emit(new StripCardInfo(this.card, brand, ''));
      } else {
        if (error) {
          this.cardErrors.emit(error);
        } else {
          this.cardErrors.emit({
            code: 'card_incomplete',
            type: 'validation_error',
          });
        }

        this.errorState = true;
        this.stateChanges.next();
        this.changeDetector.markForCheck();
      }
    });

    this.paymentSumaryService.paymentCardCheckout$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => this.confirmCardPayment());
  }

  writeValue(value: any): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  get empty() {
    return this._empty;
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.card.focus();
    }
  }

  confirmCardPayment() {
    this.paymentProcessStatus.emit('processing');

    this.stripeService
      .confirmCardPayment(this.clientSecret, this.card)
      .then((response) => {
        if (response.error) {
          const { error } = response;

          this.paymentProcessStatus.emit('error');

          if (error.code === 'card_declined') {
            this.cardErrors.emit({
              code: 'card_declined',
              type: 'validation_error',
              message: error.message,
            });

            return;
          }

          this.cardErrors.emit({
            code: 'card_incomplete',
            type: 'validation_error',
          });

          return;
        }

        if (response.paymentIntent.status === 'succeeded') {
          this.paymentProcessStatus.emit('succeeded');
        }
      });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.unsubscriber$.next(1);
  }
}

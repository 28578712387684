import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from "@angular/core";
import { EASY_CALENDAR_SELECTORS, EASY_CALENDAR_STYLE, EASY_CALENDAR_URL } from "./constants";
import { User } from "./easy-calendar";
import * as _ from 'lodash';
import { UtilsService } from "@shared/services/utils.service";
import { addObservers, fillOutUserForm } from "./helper";

declare const EasyCalendarParentObj;

@Component({
  selector: 'app-easy-calendar',
  templateUrl: './easy-calendar.component.html'
})
export class EasyCalendarComponent implements AfterViewInit, OnDestroy {
  @Output() onInitEasyCalendar = new EventEmitter<Function>();
  @Output() onClose = new EventEmitter<void>();
  @Output() onCallScheduled = new EventEmitter<void>();

  @ViewChild('easyCalendarDiv') easyCalendarDiv: ElementRef;

  private styleObserver: MutationObserver;

  constructor(private utilsService: UtilsService) {}

  ngAfterViewInit(): void {
    this.onInitEasyCalendar.emit(this.initializeEasyCalendar);
  }

  ngOnDestroy(): void {
    this.styleObserver?.disconnect();
  }

  private initializeEasyCalendar = (user: User): void => {
    this.addDataLink(user);
    EasyCalendarParentObj.init({type: 'popup', classname: EASY_CALENDAR_SELECTORS.initButton});
    EasyCalendarParentObj.resizeTheframe();
    this.addCloseButtonStyle();
    addObservers(user, this.utilsService, this.styleObserver, EASY_CALENDAR_SELECTORS.modal, fillOutUserForm, this.emitCallScheduled);
  }

  private addDataLink = (user: User): void => {
    if (!user?.email || !user?.name) return;

    const easyCalendarUrl = `${EASY_CALENDAR_URL}email=${user.email}&fname=${user.name}`;
    this.easyCalendarDiv.nativeElement.attributes['data-link'].value = encodeURI(easyCalendarUrl);
  }

  closeEasyCalendar = (): void => this.onClose.emit();

  addCloseButtonStyle = (): void => {
    const closeButton: HTMLElement = document.querySelector(EASY_CALENDAR_SELECTORS.closeButton);

    if (!closeButton) return;

    closeButton.style.background = EASY_CALENDAR_STYLE.button.background;
    closeButton.style.border = EASY_CALENDAR_STYLE.button.border;

    const closeSpan: HTMLElement = _.first(closeButton.getElementsByTagName('span'));

    if (!closeSpan) return;

    closeSpan.style.color = EASY_CALENDAR_STYLE.icon.color;
  }

  emitCallScheduled = () => this.onCallScheduled.emit();
}

<div class="header" data-cy="review-dialog">
  <div class="title">
    <div class="left">
      <mat-icon svgIcon="review"></mat-icon>
      <span>{{ 'ask-for-reviews' | translate }}</span>
    </div>

    <button mat-icon-button mat-dialog-close>
      <mat-icon svgIcon="24px-close"></mat-icon>
    </button>
  </div>
  <div class="subtitle">
    <span>{{ 'ask-for-reviews-subtitle' | translate }}</span>
  </div>
</div>

<form [formGroup]="askForm">
  <div class="custom-input text-sm">
    <p>
      {{ 'client-name' | translate
      }}{{ requiredValidator('clientName') ? '*' : '' }}
    </p>

    <mat-form-field appearance="outline" color="primary">
      <input
        matInput
        type="text"
        formControlName="clientName"
        data-cy="user-name"
        autocomplete="name"
      />
      <mat-icon matSuffix svgIcon="24px-profile"></mat-icon>
      <mat-error
        class="email-required"
        *ngIf="askForm.get('clientName').hasError('required')"
        [innerHTML]="'field-required' | translate"
        data-cy="user-name-required"
      >
      </mat-error>
      <mat-error
        *ngIf="askForm.get('clientName').hasError('minlength')"
        [innerHTML]="'2-characters-error' | translate"
        data-cy="user-name-min-length"
      >
      </mat-error>
      <mat-error
        *ngIf="askForm.get('clientName').hasError('maxlength')"
        [innerHTML]="'100-characters-error' | translate"
        data-cy="user-name-max-length"
      >
      </mat-error>
    </mat-form-field>
  </div>

  <div class="custom-input text-sm">
    <p>
      {{ 'email-client' | translate
      }}{{ requiredValidator('email') ? '*' : '' }}
    </p>

    <mat-form-field appearance="outline" color="primary">
      <input
        matInput
        type="email"
        formControlName="email"
        data-cy="email"
        autocomplete="email"
      />
      <mat-icon matSuffix svgIcon="24px-mailClosed"></mat-icon>

      <mat-error
        class="email-required"
        *ngIf="askForm.get('email').hasError('required')"
        [innerHTML]="'field-required' | translate"
        data-cy="email-required"
      >
      </mat-error>
      <mat-error
        class="email-pattern"
        *ngIf="
          askForm.get('email').hasError('pattern') &&
          askForm.get('email').value.length > 5
        "
        [innerHTML]="'email-field' | translate"
        data-cy="email-pattern"
      >
      </mat-error>
    </mat-form-field>
  </div>

  <div class="custom-input text-sm">
    <p>
      {{ 'phone-number-client' | translate }} ({{ 'optional' | translate }})
    </p>

    <mat-form-field appearance="outline" color="primary">
      <input
        matInput
        appInputPhoneFormat
        type="telf"
        formControlName="phoneNumber"
        data-cy="phone"
      />
      <mat-icon matSuffix svgIcon="24px-phone"></mat-icon>

      <mat-error
        class="password-required"
        *ngIf="askForm.get('phoneNumber').hasError('required')"
        [innerHTML]="'field-required' | translate"
      >
      </mat-error>
      <mat-error
        class="password-required"
        *ngIf="askForm.get('phoneNumber').hasError('pattern')"
        [innerHTML]="'phone-number-field' | translate"
        data-cy="phone-pattern"
      >
      </mat-error>
      <mat-error
        class="password-required"
        *ngIf="askForm.get('phoneNumber').hasError('minlength')"
        [innerHTML]="'phone-minlength-error' | translate"
        data-cy="phone-min-length"
      >
      </mat-error>
      <mat-error
        class="password-required"
        *ngIf="askForm.get('phoneNumber').hasError('maxlength')"
        [innerHTML]="'phone-maxlength-error' | translate"
        data-cy="phone-max-length"
      >
      </mat-error>
    </mat-form-field>
  </div>

  <div class="button-wrapper">
    <button
      type="submit"
      mat-flat-button
      color="accent"
      (click)="sendRequest()"
      [disabled]="askForm.invalid"
      data-cy="send-request"
    >
      {{ 'request-review' | translate }}
    </button>
  </div>
</form>

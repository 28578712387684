<section>
  <div>
    <p class="title">{{ (helloLabel | translate) + clientName + '!' }}</p>
  </div>
  <!--<div class="info">
    <p class="section">
      {{ address.address1 }}
    </p>
    <p class="section">
      {{ address.zipCode + ', ' + address.city }}
    </p>
  </div>-->
</section>

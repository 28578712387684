import { Action, createReducer, on, State } from '@ngrx/store';
import * as _ from 'lodash-es';
import { AddOrUpdateCurrentQuizAction, AddOrUpdateCurrentQuestionAction, AddOrUpdateResponsesAction, CleanQuizDataAction } from '../actions/quiz-configurator.actions';
import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import { QuizInterface } from '../../shared/interfaces/quiz.interface';

export interface QuizConfiguratorState extends EntityState<QuizInterface> {}

export const quizConfiguratorAdapter: EntityAdapter<QuizInterface> = createEntityAdapter<QuizInterface>();
export const initialProjectsState: QuizConfiguratorState = quizConfiguratorAdapter.getInitialState();

const quizConfiguratorReducer = createReducer(
  initialProjectsState,
  on(AddOrUpdateCurrentQuizAction, (state, quiz) => {
    return quizConfiguratorAdapter.upsertOne(quiz.quiz, state);
  }),
  on(AddOrUpdateCurrentQuestionAction, (state, question) => ({
    ...state,
    ...question,
  })),
  on(AddOrUpdateResponsesAction, (state, { field, value }) => {
    const newState = _.cloneDeep(state);

    _.set(newState, field, value);

    return {
      ...newState,
    };
  }),
  on(CleanQuizDataAction, (state) => {
    return quizConfiguratorAdapter.removeAll({ ...state });
  }),
);

export function reducer(state: QuizConfiguratorState | undefined, action: Action) {
  return quizConfiguratorReducer(state, action);
}

const {
  selectEntities,
} = quizConfiguratorAdapter.getSelectors();

// select the dictionary of quizzes entities
export const selectQuizzesEntitiesSelector = selectEntities;

/* eslint-disable */
import { Agent, Lead } from './index';

declare var Object: any;
export interface ActivityInterface {
  activity: string;
  date: Date;
  id?: number;
  agentId?: number;
  leadId?: number;
  state?: string;
  stateLog?: Array<any>;
  createdAt: Date;
  updatedAt: Date;
  modelActive?: any;
  agent?: Agent;
  lead?: Lead;
}

export class Activity implements ActivityInterface {
  'activity': string;
  'date': Date;
  'id': number;
  'agentId': number;
  'leadId': number;
  'state': string;
  'stateLog': Array<any>;
  'createdAt': Date;
  'updatedAt': Date;
  'modelActive': any;
  agent?: Agent;
  lead?: Lead;
  constructor(data?: ActivityInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Activity`.
   */
  public static getModelName() {
    return 'Activity';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Activity for dynamic purposes.
   **/
  public static factory(data: ActivityInterface): Activity {
    return new Activity(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Activity',
      plural: 'Activities',
      path: 'Activities',
      idName: 'id',
      properties: {
        activity: {
          name: 'activity',
          type: 'string',
        },
        date: {
          name: 'date',
          type: 'Date',
        },
        id: {
          name: 'id',
          type: 'number',
        },
        agentId: {
          name: 'agentId',
          type: 'number',
        },
        leadId: {
          name: 'leadId',
          type: 'number',
        },
        state: {
          name: 'state',
          type: 'string',
          default: 'todo',
        },
        stateLog: {
          name: 'stateLog',
          type: 'Array&lt;any&gt;',
        },
        createdAt: {
          name: 'createdAt',
          type: 'Date',
        },
        updatedAt: {
          name: 'updatedAt',
          type: 'Date',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
      },
      relations: {
        agent: {
          name: 'agent',
          type: 'Agent',
          model: 'Agent',
          relationType: 'belongsTo',
          keyFrom: 'agentId',
          keyTo: 'id',
        },
        lead: {
          name: 'lead',
          type: 'Lead',
          model: 'Lead',
          relationType: 'belongsTo',
          keyFrom: 'leadId',
          keyTo: 'id',
        },
      },
    };
  }
}

<div class="custom-header" *ngIf="viewMode !== 'handset'">
  <div class="codes" (click)="sortProjectsByField('shortCode')">
    <span>
      {{ 'codes' | translate }}
      <mat-icon [ngClass]="{ hidden: projectsOrder.field !== 'shortCode' }">
        {{ projectsOrder.field === 'shortCode' && projectsOrder.order === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
      </mat-icon>
    </span>
  </div>

  <div class="name" (click)="sortProjectsByField('contact.name')">
    <span>
      {{ 'name' | translate }}

      <mat-icon [ngClass]="{ hidden: projectsOrder.field !== 'contact.name' }">
        {{ projectsOrder.field === 'contact.name' && projectsOrder.order === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
      </mat-icon>
    </span>
  </div>

  <div class="address" (click)="sortProjectsByField('address.address1')">
    <span>
      {{ 'address' | translate }}

      <mat-icon [ngClass]="{ hidden: projectsOrder.field !== 'address.address1' }">
        {{ projectsOrder.field === 'address.address1' && projectsOrder.order === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
      </mat-icon>
    </span>
  </div>

  <div class="state" (click)="sortProjectsByField('state')">
    <span>
      {{ 'state' | translate }}

      <mat-icon [ngClass]="{ hidden: projectsOrder.field !== 'state' }">
        {{ projectsOrder.field === 'state' && projectsOrder.order === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
      </mat-icon>
    </span>
  </div>

  <div class="date" (click)="sortProjectsByField('updatedAt')">
    <span>
      {{ 'date' | translate }}

      <mat-icon [ngClass]="{ hidden: projectsOrder.field !== 'updatedAt' }">
        {{ projectsOrder.field === 'updatedAt' && projectsOrder.order === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
      </mat-icon>
    </span>
  </div>

  <div class="roles">
    <span>
      {{ 'Roles' | translate }}
    </span>
  </div>
</div>


<app-project-row [lead]="project" [agentName]="agentName" (reload)="emitReloadEmitter()"
  *ngFor="let project of projects" (projectClicked)="handleProjectClicked($event)"
  data-cy="each-project"></app-project-row>
import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { AuthenticationComponent } from './authentication.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { LoginComponent } from './login/login.component';
import { RegisterAgentComponent } from './register-agent/register-agent.component';
import { AuthGuard } from '@shared/guards/auth.guard';
import { RegisterClientComponent } from './register-client/register-client.component';
import { ReviewRequestComponent } from '../review-request/review-request.component';
import { NewPasswordComponent } from './new-password/new-password.component';

//TODO: Review the need or not of a guard
const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: AuthenticationComponent,
        children: [
          {
            path: 'login',
            component: LoginComponent,
          },
          {
            path: 'register',
            component: RegisterAgentComponent,
            canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
          },
          {
            path: 'new-password/:token/:email',
            component: NewPasswordComponent,
          },
          {
            path: 'review-request/:token/:email/:agentID',
            component: ReviewRequestComponent,
          },
          {
            path: 'register/client',
            component: RegisterClientComponent,
            canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
          },
          {
            path: 'forget-password',
            component: ForgetPasswordComponent,
          },
          {
            // Onboarding
            path: 'quiz',
            loadChildren: () =>
              import('../quiz/quiz.module').then((page) => page.QuizModule),
          },
          {
            // PPV:
            // REMOVE FROM AUTH MODULE - DO NOT MAKE SENSE
            path: 'logged-client/quiz',
            loadChildren: () =>
              import('../quiz/quiz.module').then((page) => page.QuizModule),
          },
          {
            path: '',
            redirectTo: '/login',
            pathMatch: 'full',
          }
        ]
      },
      // ... otras rutas específicas del módulo de autenticación
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }

import { Injectable } from '@angular/core';

declare const stripe;

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  billingName = '';

  constructor() {}

  confirmCardPayment(clientSecret: string, card) {
    return stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card,
        billing_details: {
          name: this.billingName,
        },
      },
    });
  }
}

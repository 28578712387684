<div class="project-selected mt-1">
  <span class="text-m">{{ 'project-selected' | translate }}:</span>
  <span class="label">{{ projectSelected }}</span>
</div>

<form [formGroup]="duplicateOptionForm" *ngIf="duplicateOptionForm" class="flex">
  <div class="search-section">
    <mat-form-field
      appearance="outline"
      class="searchField without-error-display"
    >
      <input
      class="pt-2"
        matInput
        type="text"
        [placeholder]="'search-project-to-copy' | translate"
        formControlName="searchText"
        (keydown.enter)="handleSearch()"
      />
      <mat-icon
        matSuffix
        *ngIf="
          duplicateOptionForm.get('searchText').value === '';
          else clearSearchButton
        "
        svgIcon="24px-search"
      ></mat-icon>

      <ng-template #clearSearchButton>
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="search"
          (click)="clearSearch()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
    </mat-form-field>
  </div>

  <div class="dropdown w-full text-end">
    <div tabindex="0" role="button" class="btn m-1">{{ selectedAgent.address.city }}</div>
    <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
      <li *ngFor="let agent of agents" (click)="selectAgent(agent)">
        <a>{{ agent.address.city }}</a>
      </li>
    </ul>
  </div>
</form>

<ng-container >
  <div
    class="projects-list-wrapper"
    *ngIf="filteredProjects$ | async as projects; else emptyState"
  >
    <ng-container *ngFor="let project of projects; index as projectIndex">
      <div
        class="project-option"
        (click)="selectProject(project, projectIndex)"
      >
        <div class="data">
          <div class="name">
            <span>{{ project.contact.name }}</span>
          </div>
          <div class="address">
            {{ project.address.address1 }}, {{ project.address.city }},
            {{ project.address.zipCode }}
          </div>
        </div>

        <div class="selector">
          <div
            class="radio-row"
            *ngIf="projectIndex === selected; else elseButton"
          >
            <mat-icon svgIcon="16px-checkFilled"></mat-icon>
          </div>
          <ng-template #elseButton>
            <div class="radio-row">
              <mat-icon svgIcon="16px-checkEmpty"></mat-icon>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #emptyState> Ningún resultado </ng-template>
function getCookie(cookieName) {
  // Add the = sign
  const name = cookieName + '=';

  // Get the decoded cookie
  const decodedCookie = decodeURIComponent(document.cookie);

  // Get all cookies, split on ; sign
  const cookies = decodedCookie.split(';');

  // Loop over the cookies
  for (let i = 0; i < cookies.length; i++) {
    // Define the single cookie, and remove whitespace
    const cookie = cookies[i].trim();

    // If this cookie has the name of what we are searching
    if (cookie.indexOf(name) == 0) {
      // Return everything after the cookies name
      return cookie.substring(name.length, cookie.length);
    }
  }
}

export function googleAnalyticsHeadScripts(environment) {
  const internalCookie = getCookie('traficoInternoWebapp');

  if (internalCookie) {
    const googleField = `ga-disable-${environment.google.analyticsCode}`;

    window[googleField] = true;

    // TODO - to remove after Dani's confirmation
    if (environment.google.analyticsCode2) {
      const googleField2 = `ga-disable-${environment.google.analyticsCode2}`;
      window[googleField2] = true;
    }
  }

  const [head] = document.getElementsByTagName('head');

  const googleAnalyticsFirstScript = document.createElement('script');
  googleAnalyticsFirstScript.async = true;
  googleAnalyticsFirstScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.google.analyticsCode}`;

  const googleAnalyticsSecondScript = document.createElement('script');

  googleAnalyticsSecondScript.innerHTML =
    '    window.dataLayer = window.dataLayer || [];\n' +
    '    function gtag(){dataLayer.push(arguments);}\n' +
    "    gtag('js', new Date());\n" +
    '\n' +
    "    gtag('config', '" +
    environment.google.analyticsCode +
    "');";

  head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
  head.insertBefore(googleAnalyticsFirstScript, head.firstChild);

  // TODO to remove after Dani's confirmation
  if (environment.google.analyticsCode2) {
    const googleAnalyticsFirstScript2 = document.createElement('script');
    googleAnalyticsFirstScript2.async = true;
    googleAnalyticsFirstScript2.src = `https://www.googletagmanager.com/gtag/js?id=${environment.google.analyticsCode2}`;
  
    const googleAnalyticsSecondScript2 = document.createElement('script');
  
    googleAnalyticsSecondScript2.innerHTML =
      '    window.dataLayer = window.dataLayer || [];\n' +
      '    function gtag(){dataLayer.push(arguments);}\n' +
      "    gtag('js', new Date());\n" +
      '\n' +
      "    gtag('config', '" +
      environment.google.analyticsCode2 +
      "');";
  
    head.insertBefore(googleAnalyticsSecondScript2, head.firstChild);
    head.insertBefore(googleAnalyticsFirstScript2, head.firstChild); 
  }
}

export function googleAnalytics(environment, url) {
  const internalCookie = getCookie('traficoInternoWebapp');

  if (internalCookie) {
    gtag('config', environment.google.analyticsCode, {
      send_page_view: false,
    });
  }

  gtag('config', environment.google.analyticsCode, {
    page_path: url,
    send_page_view: !internalCookie,
  });

  // TODO to remove after Dani's confirmation
  if (environment.google.analyticsCode2) {
    if (internalCookie) {
      gtag('config', environment.google.analyticsCode2, {
        send_page_view: false,
      });
    }
  
    gtag('config', environment.google.analyticsCode2, {
      page_path: url,
      send_page_view: !internalCookie,
    });
  }
}
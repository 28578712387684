import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { InfoAreaData } from './info-area.type';

@Component({
  selector: 'app-info-area',
  templateUrl: './info-area.component.html',
  styleUrls: ['./info-area.component.scss']
})
export class InfoAreaComponent implements OnInit {

  @Input('header') infoAreaHeader: string;
  @Input() infoAreaData: InfoAreaData;

  @Output() onCta = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onCtaClicked = (): void => this.onCta.emit();
}

import { Pipe, PipeTransform } from '@angular/core';
import { log } from 'console';

@Pipe({
  name: 'stateLog',
})
export class StateLogPipe implements PipeTransform {
  transform(object: Array<any>, stateQuery: string): Date | null {

    if (!object || object.length === 0) {
      return ;
    }

    const matchingLog = object.find((stateLog) => stateQuery === stateLog.state);

    return matchingLog ? matchingLog.date : null;
   
  }
}

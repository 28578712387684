import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserContextService {
  constructor(private localStorage: LocalStorageService) {}

  clearViewContext(view: string) {
    this.localStorage.set(view, {});
      return;
  }

  setViewContext(view: string, context: any) {
    const viewContext = this.localStorage.get(view);

    if (!viewContext) {
      this.localStorage.set(view, context);
      return;
    }

    this.localStorage.set(view, {
      ...viewContext,
      ...context,
    });
  }

  getViewContext(view: string) {
    return this.localStorage.get(view);
  }
}

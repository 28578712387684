/* eslint-disable */

declare var Object: any;
export interface ContactInterface {
  name: string;
  email: string;
  phoneNumber: string;
  idNumber?: string;
  modelActive?: any;
}

export class Contact implements ContactInterface {
  'name': string;
  'email': string;
  'phoneNumber': string;
  'idNumber': string;
  'modelActive': any;
  constructor(data?: ContactInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Contact`.
   */
  public static getModelName() {
    return 'Contact';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Contact for dynamic purposes.
   **/
  public static factory(data: ContactInterface): Contact {
    return new Contact(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Contact',
      plural: 'Contacts',
      path: 'Contacts',
      idName: 'id',
      properties: {
        name: {
          name: 'name',
          type: 'string',
        },
        email: {
          name: 'email',
          type: 'string',
        },
        phoneNumber: {
          name: 'phoneNumber',
          type: 'string',
        },
        idNumber: {
          name: 'idNumber',
          type: 'string',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
      },
      relations: {},
    };
  }
}

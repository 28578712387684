import { Component, OnInit, Inject } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Reason } from '@core/sdk';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-reject-modal',
  templateUrl: './reject-modal.component.html',
  styleUrls: ['./reject-modal.component.scss'],
  imports: [TranslateModule, CommonModule, MatLegacyDialogModule, MatDividerModule , MatIconModule, MatOptionModule, MatRadioModule, ReactiveFormsModule, MatButtonModule],
  standalone: true
})
export class RejectedModalComponent implements OnInit {
  rejectedForm: UntypedFormGroup;
  reasons: Reason[];

  selected: number;
  reasonSelected: Reason;

  nameCollection: string;

  questionTile: string;
  questionSubtitle: string;

  constructor(
    public dialogRef: MatDialogRef<RejectedModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.reasons = this.data.reasons;

    this.questionTile = this.data.title
    this.questionSubtitle = this.data.subtitle

    this.rejectedForm = this.fb.group({
      reason: new UntypedFormControl('', [Validators.required]),
    });

    this.rejectedForm.valueChanges.subscribe(
      (result) => (this.data.reasonSelected = result),
    );
  }

  ifChecked(reasonIndex, reason) {
    this.selected = reasonIndex;
    this.rejectedForm.get('reason').setValue(reason);
  }

}

<div class="header" data-cy="decision-modal">
  <div class="left">
    <div class="up">
      <mat-icon *ngIf="data.svgIcon" [svgIcon]="data.svgIcon"></mat-icon>
      <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>

      <span *ngIf="data.title" class="title">
        {{ data.title | translate }}
      </span>
      <span *ngIf="data.titleHTML" class="title" [innerHTML]="data.titleHTML">
      </span>
    </div>

    <span *ngIf="data.subtitle" class="subtitle">
      {{ data.subtitle | translate }}
    </span>
    <span
      *ngIf="data.subtitleHTML"
      class="subtitle"
      [innerHTML]="data.subtitleHTML"
    >
    </span>
  </div>
  <button
    mat-icon-button
    mat-dialog-close
    *ngIf="data.showCloseButton"
    data-cy="close-modal"
  >
    <mat-icon svgIcon="24px-close"></mat-icon>
  </button>
</div>

<div class="body" *ngIf="data.image">
  <img [src]="data.image" alt="" />
</div>

<div class="buttons">
  <button
    mat-stroked-button
    color="primary"
    mat-dialog-close
    *ngIf="data.showCancelButton"
    data-cy="cancel"
  >
    {{ (data.cancelLabel ? data.cancelLabel : 'cancel') | translate }}
  </button>

  <button 
    mat-flat-button color="accent" 
    (click)="submit()" 
    data-cy="accept"
  >
    {{ (data.ctaLabel ? data.ctaLabel : 'accept') | translate }}
  </button>
</div>

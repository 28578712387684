<section class="notifications-center" data-cy="notifications-center">
  <div class="header">
    <div class="left">
      <mat-icon svgIcon="24px-notifications"></mat-icon>
      <span>{{ 'notifications-center' | translate }}</span>
    </div>

    <button
      mat-icon-button
      (click)="toggleNotificationsCenter()"
      data-cy="close-notifications"
    >
      <mat-icon svgIcon="24px-close"></mat-icon>
    </button>
  </div>

  <div class="fixed-notifications-list" *ngIf="fixedNotifications.length > 0">
    <span>{{ 'fixed-notifications' | translate }}</span>
    <ng-container *ngFor="let notification of fixedNotifications">
      <app-notification
        [notification]="notification"
        (notificationClosedEmitter)="handlerNotificationClosed($event)"
        [ngClass]="{ read: notification.read }"
        matRipple
      ></app-notification>
    </ng-container>
  </div>

  <div class="notifications-list">
    <ng-container *ngFor="let notification of normalNotifications">
      <app-notification
        [notification]="notification"
        (notificationClosedEmitter)="handlerNotificationClosed($event)"
        [ngClass]="{ read: notification.read }"
        matRipple
      ></app-notification>
    </ng-container>
  </div>
</section>

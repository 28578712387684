<div class="title">
    <p mat-dialog-title>{{ 'urn-link' | translate }}</p>
    <button mat-icon-button (click)="close()">
      <mat-icon svgIcon="16px-close"></mat-icon>
    </button>
  </div>
  <div class="custom-input text-xs">
    <p>
      {{ 'input-urn-link' | translate }}
    </p>
    <mat-form-field appearance="outline" color="primary">
      <input
        matInput
        type="text"
        [formControl]="urnLink"
        placeholder=""
        data-cy="name-template"
      />
    </mat-form-field>
  </div>

  <div class="dialog-actions">
    <button (click)="close()" mat-dialog-close mat-stroked-button color="accent">
      {{ 'cancel' | translate }}
    </button>

    <button (click)="save()" mat-flat-button color="primary" data-cy="save">
      {{ 'save' | translate }}
    </button>
  </div>

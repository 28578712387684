import {
  BudgetAddAction,
  BudgetDeleteAction,
  BudgetsAddAction,
  BudgetUpdateAction,
  BudgetsClearAction,
  getBudgetsRequestSuccess,
} from '../actions/budget.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import { Budget } from '@core/sdk';
import { logoutAction } from '../actions/meta.action';

export interface BudgetsState extends EntityState<Budget> {}

export const budgetsAdapter: EntityAdapter<Budget> = createEntityAdapter<Budget>();
export const initialBudgetsState: BudgetsState = budgetsAdapter.getInitialState();

const budgetsReducer = createReducer(
  initialBudgetsState,
  on(BudgetAddAction, (state, budget) => {
    return budgetsAdapter.addOne(budget, state);
  }),
  on(BudgetUpdateAction, (state, { update }) => {
    return budgetsAdapter.updateOne(update, state);
  }),
  on(BudgetsAddAction, (state, { budgets }) => {
    return budgetsAdapter.addMany(budgets, state);
  }),
  on(BudgetDeleteAction, (state, { id }) => {
    return budgetsAdapter.removeOne(id, state);
  }),
  on(BudgetsClearAction, (state) => {
    return budgetsAdapter.removeAll({ ...state });
  }),
  on(getBudgetsRequestSuccess,(state, payload) => {
    return budgetsAdapter.setAll(payload.budgets,state)
  }),
  on(logoutAction, (state) => budgetsAdapter.removeAll({ ...state }))
);

export function reducer(state: BudgetsState | undefined, action: Action) {
  return budgetsReducer(state, action);
}

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = budgetsAdapter.getSelectors();

// select the array of user ids
export const selectBudgetsIdsSelector = selectIds;

// select the dictionary of user entities
export const selectBudgetsEntitiesSelector = selectEntities;

// select the array of users
export const selectAllBudgetsSelector = selectAll;

// select the total user count
export const selectBudgetsTotalSelector = selectTotal;

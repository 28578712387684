import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Agent, Budget, BudgetInterface, Certification, ChapterInterface, Client, DiscountItem, LeadInterface } from '@core/sdk';
import { BudgetService } from '@core/services/budget.service';
import { LoaderService } from '@core/services/loader.service';
import { ResponsiveService } from '@core/services/responsive.service';
import { Store } from '@ngrx/store';
import { selectProjectById } from 'src/app/store/selectors/project.selectors';
import { first, map, take } from 'rxjs/operators';
import { BudgetCreateDialogComponent } from '@shared/dialogs/budget-create-dialog/budget-create-dialog.component';
import { NotificationsService } from '@core/services/notifications.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { saveAs } from 'file-saver-es';
import { BudgetExcelService } from '@core/services/budget-excel.service';
import { DecisionModalComponent } from '@shared/dialogs/decision-modal/decision-modal.component';
import { SelectDuplicateOptionComponent } from 'src/app/pages/budget/payment-summary/select-duplicate-option/select-duplicate-option.component';
import { PdfService } from '@core/services/pdf.service';
import { TrackingComponentStore } from 'src/app/pages/tracking/tracking.store';
import { CertificationService } from '@core/services/certification.service';
import { CERTIFICATION_STATES } from '@core/constants';
import { Observable } from 'rxjs';
import { CertificationFacadeService } from 'src/app/store/certification/certification-facade.service';
import { ActionSelect } from '@core/sdk/models/Navigation';
import { selectCertificationById } from 'src/app/store/certification/certification.selectors';


@Component({
  selector: 'app-select-action',
  templateUrl: './select-action.component.html',
  styleUrls: ['./select-action.component.scss']
})
export class SelectActionComponent implements OnInit {
  project:LeadInterface;
  certification: Certification;
  @Input() chapters: ChapterInterface[];
  @Input() actions$: Observable<ActionSelect>;
  @Input() budget: BudgetInterface;

  currentProjectId: string;
  currentBudgetId:string;
  currentCertificationId:string;

  context: any;
  options =  new Array<ActionSelect>();
  viewMode: string;
  duplicateBudgetModeSelected: string;
  certifications = [];
  pendingCertifications = [];

  certificationDiscounts$: Observable<DiscountItem>;
  
  taxPrice = 0;
  finalTotal = 0;
  showCommentsInput = false;
  
  showCertification = false;

  subtotalWithCredit: number;
  subtotalWithEndPaymentMethodCredit: number;
  certificationDiscounts: DiscountItem[];

  constructor(    
    private readonly trackingComponentStore: TrackingComponentStore,
    private responsiveService: ResponsiveService,
    private notificationsService: NotificationsService,
    private certificationService: CertificationService,
    private certificationFacadeService: CertificationFacadeService,
    private budgetExcel: BudgetExcelService,
    private matDialog: MatDialog,
    private loader: LoaderService,
    private pdfService: PdfService,
    private loaderService: LoaderService,
    private store: Store<{
      agent: Agent;
      client: Client;
      projects: LeadInterface;
      context: any;
      router: any;
    }>,
    private router: Router,
    private budgetService : BudgetService) { }

  ngOnInit(): void {
    this.responsiveService.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );
    this.store.select('context').subscribe(({ value: context, currentProjectId, currentBudgetId, currentCertificationId }) => {
      if (!context) {
        return;
      }

      this.currentProjectId = currentProjectId;
      this.currentBudgetId = currentBudgetId;
      this.currentCertificationId = currentCertificationId;
      this.context = context;

      if (this.currentProjectId)  {
        this.store.select(selectProjectById(this.currentProjectId))
        .subscribe((project) => this.project = project)
      }
     
      if (this.currentCertificationId)  {
        this.store.select(selectCertificationById(this.currentCertificationId))
        .subscribe((certification) => this.certification = certification)
      }
    });

   
  }

  handleNewBudgetBySubtype(subType) {
    this.loader.enable();
    
    const filter = {
      where: {
          leadId: this.currentProjectId,
          subType: subType
      },
    };

    this.store
    .select(selectProjectById(this.currentProjectId))
    .pipe(
      first() //TODO: Handle first() error
    ).subscribe((currentLead) => {
      this.budgetService.getBudgetsByFilter(filter)
      .subscribe((budgetsAnnex)=>{
        this.budgetService.createDraft(currentLead, subType, budgetsAnnex['count'])
        .subscribe(async (budgetResult:Budget)=>{
            await this.budgetService.createPristineItems(budgetResult.id, subType, budgetsAnnex['count'])
            this.router.navigate(['budget-edition/', budgetResult.id]);
          })
        })
      })
  }

  /**
   * * Open a dialog to create a new budget.
   * * If the user selects the "Duplicate" option, duplicate the selected budget.
   * * If the user selects the "BC3" option, create a new budget using the BC3 template
   */
  newBudgetOptions() {
    const dialogRef = this.openDialog(BudgetCreateDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      if (result.id) {
        this.handleDuplicateBudget(result);

        return;
      }

      if (result.optionSelected === 'bc3') {
        this.handleBC3Budget(result);
      }
    });
  }

  /**
   * Create a new budget from scratch
   */
  async handleNewBudget(subType?:string) {
    const filter = {
      where: {
          leadId: this.currentProjectId,
          subType: subType
      },
    };
    this.budgetService.getBudgetsByFilter(filter).subscribe((budgetsAnnex)=>{
          this.budgetService.createDraft(this.project, subType, budgetsAnnex['count'])
          .subscribe(async (budgetResult:Budget)=>{
              await this.budgetService.createPristineItems(budgetResult.id, subType, budgetsAnnex['count'])
              this.router.navigate(['budget-edition/', budgetResult.id]);
      })
    })
  }

    /**
   * Create a new automated budget from scratch
   */
    async createAutomatedBudget(subType?:string, type?:string, leadId?:string) {
      console.log('quiz', subType, type, leadId);
      this.router.navigate(['quiz', type, leadId]);
    }

      /**
   * Create a new automated budget from scratch
   */
      async createConfiguredBudget(subType?:string, type?:string, leadId?:string) {
        this.router.navigate(['quiz', type, leadId, 'new']);
      }
  

    /**
   * It creates a draft from the BC3 file.
   * @param result - The result of the BC3 file.
   */
    private handleBC3Budget(result) {
      this.loader.enable();
      this.notificationsService.showSuccess('Generando borrador...');
  
      this.budgetService.createDraftFromBC3(result, this.currentProjectId).subscribe(
        (draftID) => {
          this.notificationsService.showSuccess('Presupuesto generado');
          this.router.navigate(['budget-edition', draftID]);
          this.loader.disable();
        },
        (error) => {
          this.loader.disable();
          this.notificationsService.showError(
            'Ha ocurrido un error con el archivo introducido, por favor revise que no esté dañado y vuélvalo a intentar',
          );
        },
      );
    }

    /**
   * * Duplicate a budget in a project
   * @param result - The budget that was duplicated.
   */
  private handleDuplicateBudget(result) {
    this.budgetService
      .duplicateBudgetInProject(result.id, this.currentProjectId)
      .subscribe((newBudget) => {

        this.router.navigate(['budget-edition/', newBudget.id]);
      
      });
  }

  /**
   * Print the budget
   * @param event - The event that triggered the print.
   */
  handleBudgetPrinted() {

    const budget$ = this.budgetService.getBudget(this.currentBudgetId, [
      {
        relation: 'agent',
        scope: {
          fields: {
            tradeName: true,
            vatId: true,
            address: true,
            contact: true,
            documents: true,
            image: false,
          },
        },
      },
      'lead',
      [
        {
          relation: 'chapters',
          scope: {
            include: {
              relation: 'budgetItems',
              scope: {
                order: 'createdAt ASC',
                include: ['measurements']
              },
            },
            order: 'createdAt ASC',
          },
        },
        {
          relation: 'documents',
          scope: {
            include: {
              relation: 'versions',
            },
          },
        },
      ],
    ]);

    budget$.subscribe((budget) => {
      if (this.context === 'agent') {
        this.store
          .select('agent')
          .pipe(take(1))
          .subscribe((agent) =>
            this.pdfService.pdfmake(agent, budget, this.project, 'Budget'),
          );

        return;
      }

      this.pdfService.pdfmake(budget.agent, budget, this.project, 'Budget');
    });
  }

  /**
   * It opens a dialog box that allows the user to select a duplicate option.
   * @param budgetType - The budget type that was selected.
   */
  handleBudgetDuplicate(budgetType) {
    const duplicateOptionModal = this.matDialog.open(
      SelectDuplicateOptionComponent,
      {
        width: '562px',
        height: '70vh',
        data: {
          optionSelected: null,
          project: null,
          budgetType,
        },
      },
    );

    duplicateOptionModal.afterClosed().subscribe(
      (data) => {
        if (!data) {
          return;
        }

        const { optionSelected, project } = data;

        this.duplicateBudgetModeSelected = optionSelected;

        if (optionSelected === 'same-project') {
          this.budgetService
            .duplicateBudgetInProject(this.currentBudgetId, this.currentProjectId)
            .subscribe(this.handleDuplicateBudgetResult.bind(this));

          return;
        }

        this.budgetService
          .duplicateBudgetInProject(this.currentBudgetId, project.id)
          .subscribe(this.handleDuplicateBudgetResult.bind(this));
      }
    );
  }


  /**
   * This function is called when a user duplicates a budget
   * @param budget - The budget that was duplicated.
   */
  private handleDuplicateBudgetResult(budget) {
    this.notificationsService.showSuccess('Presupuesto duplicado');

    if (
      !this.duplicateBudgetModeSelected ||
      (this.duplicateBudgetModeSelected &&
        this.duplicateBudgetModeSelected === 'same-project')
    ) {
      this.router.navigate(['budget-edition', budget.id]);

      return;
    }

    const decisionModal = this.matDialog.open(DecisionModalComponent, {
      width: '650px',
      height: this.viewMode !== 'web' ? '270px' : '150px',
      data: {
        title: 'Has duplicado el presupuesto en otro proyecto',
        subtitle: '¿Quieres seguir editándolo?',
        cta: 'Sí, continuar ahora',
        cancel: 'No, continuaré más tarde',
        showCancelButton: true,
        showCloseButton: true,
      },
    });

    decisionModal.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      this.router.navigate(['budget-edition', budget.id]);
    });
  }


  /**
   * It gets the budget from the backend and then uses the budgetExcel service to get the excel file.
   * @param event - The event object that was triggered.
   */
  handleBudgetToExcel() {
    this.budgetService
      .getBudget(this.currentBudgetId, [
        {
          relation: 'agent',
          scope: {
            fields: {
              tradeName: true,
              vatId: true,
              address: true,
              contact: true,
              image: false,
            },
          },
        },
        {
          relation: 'lead',
          scope: {
            include: {
              relation: 'client',
            },
          },
        },
        [
          {
            relation: 'chapters',
            scope: {
              include: {
                relation: 'budgetItems',
                scope: {
                  order: 'createdAt ASC',
                  include: ['measurements']
                },
              },
              order: 'createdAt ASC',
            },
          },
        ],
      ])
      .subscribe(
        (budget) => {
          this.budgetExcel.getExcel(budget).subscribe(
            (res) => {
              const responseType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
              const blob = new Blob([res], {
                type: responseType,
              });

              const file = new File([blob], `${budget.title}.xlsx`, {
                type: responseType,
              });

              saveAs(file);
            }
          );
        });
  }


  /**
   * Open a dialog and return a reference to the dialog
   * @param {any} any - any
   * @returns The dialogRef.
   */
    openDialog(any: any): any {
      const dialogRef: any = this.matDialog.open(any, this.getDialogConfig());
  
      return dialogRef;
    }

    /**
   * It creates a dialog config object that is used to configure the dialog.
   * @returns The dialog config is being returned.
   */
  private getDialogConfig() {
    if (this.viewMode === 'web') {
      return {
        width: '610px',
        height: '70vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        data: {
          optionSelected: null,
          budget: null,
          file: null,
        },
      };
    }

    return {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: {
        optionSelected: null,
        budget: null,
        file: null,
      },
    };
  }
  

  generateCertification(): void {
    const filter = {
      where: {
        leadId: this.currentProjectId,
      },
      include: {
        relation: 'lead',
      },
    };
    this.certificationService
    .getCertificationsByProject(filter)
    .subscribe((certifications: any) => {
      this.pendingCertifications = certifications.filter(
        (certification) =>
          certification.state === CERTIFICATION_STATES.PENDING ||
          certification.state === CERTIFICATION_STATES.CREATED,
      )
       if (this.pendingCertifications.length > 0) {
      const dialog = this.matDialog.open(DecisionModalComponent, {
        width: '650px',
        height: this.viewMode !== 'web' ? '270px' : '160px',
        data: {
          title:
            'El cliente debe aceptar o rechazar la certificación anterior para poder generar una nueva',
          cta: 'De acuerdo',
          showCancelButton: false,
        },
      });

      return;
    }
    this.loaderService.enable();
    this.trackingComponentStore.createCertification(this.currentProjectId);
    this.loaderService.disable();

    });
  }

  private calculateCertificationPrices() {
    if (!this.certification) {
      throw new Error('Certification is undefined');
    }

    const { credit, total, endPaymentMethodCredit } = this.certification;

    let totalDiscount = 0;

    // TODO: 
    /*if (this.certification.discounts?.length) {
      totalDiscount = this.applyDiscounts();
    }*/

    if (credit && endPaymentMethodCredit) {
      this.subtotalWithCredit = total - credit;

      this.subtotalWithEndPaymentMethodCredit =
        this.subtotalWithCredit - endPaymentMethodCredit;
      this.taxPrice = this.subtotalWithEndPaymentMethodCredit * (this.budget.taxPercent / 10000);
      this.finalTotal = this.subtotalWithEndPaymentMethodCredit + this.taxPrice; // - totalDiscount;

      return;
    }

    if (credit) {
      this.subtotalWithCredit = credit ? total - credit : total;
      this.taxPrice = this.subtotalWithCredit * (this.budget.taxPercent / 10000);
      this.finalTotal = this.subtotalWithCredit + this.taxPrice; // - totalDiscount;

      return;
    }

    if (endPaymentMethodCredit) {
      this.subtotalWithEndPaymentMethodCredit = endPaymentMethodCredit
        ? total - endPaymentMethodCredit
        : total;
      this.taxPrice = this.subtotalWithEndPaymentMethodCredit * (this.budget.taxPercent / 10000);
      this.finalTotal = this.subtotalWithEndPaymentMethodCredit + this.taxPrice; // - totalDiscount;

      return;
    }

    this.taxPrice = total * (this.budget.taxPercent / 10000);
    this.finalTotal = total + this.taxPrice - totalDiscount;
  }
  generatePdf() {
    // TODO: Use certificationFacade

    const certificationWithRelations = {
      ...this.certification,
      subtotalWithCredit: this.subtotalWithCredit,
      subtotalWithEndPaymentMethodCredit: this
        .subtotalWithEndPaymentMethodCredit,
      taxPrice: this.taxPrice,
      finalTotal: this.finalTotal,
      chapters: this.chapters,
      discounts: this.certificationDiscounts,
      taxPercent: this.budget.taxPercent,
    };

    if (this.context === 'agent') {
      this.store
        .select('agent')
        .pipe(take(1))
        .subscribe((agent) => {
          this.pdfService.pdfmake(
            agent,
            certificationWithRelations,
            this.project,
            'certification',
          );
        });

      return;
    }

    this.pdfService.pdfmake(
      this.project.agent,
      certificationWithRelations,
      this.project,
      'certification',
    );
  }
    
  sendCertification() {
    const sendCertificationModal = this.matDialog.open(DecisionModalComponent, {
      width: '650px',
      height: this.viewMode !== 'web' ? '270px' : '180px',
      data: {
        title: 'Estas seguro de querer enviar al cliente esta certificación?',
        subtitle: 'Esta acción no se puede deshacer',
        cta: 'Sí, enviar certificación',
        cancel: 'Cancelar',
        showCancelButton: true,
        showCloseButton: true,
      },
    });

    sendCertificationModal.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      this.certificationFacadeService.sendCertification( this.certification );
    });
  }

  setMethod(method) {
    this.loaderService.enable();

    switch (method) {
      case 'create-annex':
        this.handleNewBudgetBySubtype('annex');
        break;
      case 'create-budget':
        this.handleNewBudget();
        break;
      case 'create-budget-options':
        this.newBudgetOptions();
        break;
      case 'create-budget-automated-budget':
          this.createAutomatedBudget(null,'automated','65a671bb44966f1885ed9f5d');
          break;
      case 'create-budget-configured-calculator':
          this.createConfiguredBudget(null,'configured','Nueva calculadora');
          break;
      case 'download-pdf':
        this.handleBudgetPrinted();
        break;
      case 'download-excel':
        this.handleBudgetToExcel();
        break;
      case 'duplicate-budget':
        this.handleBudgetDuplicate('draft');
        break;        
      case 'generate-certification':
        this.generateCertification();
        break;
      case 'download-pdf-certification':
        this.calculateCertificationPrices();
        this.generatePdf();
        break;
      case 'send-certification':
        this.sendCertification();
        break;
    }
    this.loaderService.disable();

  }
}


import { Component, OnInit, Input } from '@angular/core';
import {
  PositionInterface,
  AgentInterface,
  ClientInterface,
  LeadInterface,
} from '@core/sdk';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-header-person',
  templateUrl: './header-person.component.html',
  styleUrls: ['./header-person.component.scss'],
})
export class HeaderPersonComponent implements OnInit {
  @Input() isMenuClosed$: BehaviorSubject<boolean>;

  position$: Observable<PositionInterface>;
  defaultImage = 'assets/img/avatar.png';
  context: any;
  client: ClientInterface;
  user: any;
  backgroundImage: any;

  constructor(
    private store: Store<{
      agent: AgentInterface;
      client: ClientInterface;
      projects: LeadInterface[];
      context: any;
      user: any;
    }>,
  ) {}

  ngOnInit() {
    this.store.select('context')
    .subscribe(({ value: context }) => {
      this.context = context;

      switch (context) {
        case 'agent':
          this.initializeAgentComponent();
          break;
        case 'login-client':
          this.initializeLoginClientComponent();
          break;
        default:
          break;
      }
    });
  }

  private initializeAgentComponent() {
    combineLatest([
      this.store.select('user'),
      this.store.select('agent')
    ])
    .subscribe(([user, agent]) => {
      this.user = user;
      this.backgroundImage = this.defaultImage;

      if (user.documents) {
        const [logoDocument] = user.documents;

        if (logoDocument && logoDocument.versions) {
          const [logoVersion] = logoDocument.versions;

          if (!logoVersion) {
            return;
          }

          this.backgroundImage = logoVersion.fileUrl;

          return;
        }
      }

      if (agent.documents) {
        const [logoDocument] = agent.documents.filter(
          (document) => document.key === 'log',
        );

        if (logoDocument && logoDocument.versions) {
          const [logoVersion] = logoDocument.versions;

          if (!logoVersion) {
            return;
          }

          this.backgroundImage = logoVersion.fileUrl;
        }
      }
    });
  }

  private initializeLoginClientComponent() {
    this.store.select('client').subscribe((client) => (this.client = client));
  }
}

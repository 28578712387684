import {
  Component,
  OnInit,
  Input,
  Self,
  Optional,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
} from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ResponsiveService } from '@core/services/responsive.service';

@Component({
  selector: 'app-chip-list-input',
  templateUrl: './chip-list-input.component.html',
  styleUrls: ['./chip-list-input.component.scss'],
})
export class ChipListInputComponent implements OnInit, ControlValueAccessor {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  private _chips: string[];
  private _onChange: Function;
  private _onTouched: Function;

  chipFormGroup: UntypedFormGroup;
  filteredOptions: Observable<string[]>;
  viewMode: string;

  @Input() autocompleteOptions = [];
  @Input() buttonSlug: string = 'add-profile';
  @Input() inputPlaceholderSlug: string = 'write-select';

  @Input()
  get chips(): string[] {
    return this._chips;
  }

  set chips(chips: string[]) {
    this._onChange(chips);
    this._chips = chips;
  }

  @ViewChild('input') chipInput: ElementRef;

  constructor(
    private fb: UntypedFormBuilder,
    private responsiveService: ResponsiveService,
    @Self() @Optional() public controlDir: NgControl,
  ) {
    controlDir.valueAccessor = this;
  }

  ngOnInit() {
    this.chipFormGroup = this.fb.group(
      {
        chipName: new UntypedFormControl('', { updateOn: 'change' }),
      },
      { updateOn: 'submit' },
    );

    this.filteredOptions = this.chipName.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value)),
      map((options) => this._arrayRemove(options)),
    );

    this.responsiveService.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );
  }

  writeValue(chips: string[]) {
    this._chips = chips;
  }

  registerOnChange(fn: Function) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  addChip(event: MatChipInputEvent = null) {
    if (!event && !this.chipInput.nativeElement.value) {
      return;
    }

    const value =
      event && event.value ? event.value : this.chipInput.nativeElement.value;

    if (value.trim() !== '') {
      const chipsCopy = [...this.chips];
      chipsCopy.push(value);

      this.chips = chipsCopy;

      if (event) {
        event.input.value = '';
      }

      this.chipFormGroup.patchValue({ chipName: '' });
    }
  }

  removeChip(chip: string) {
    const index = this.chips.indexOf(chip, 0);

    if (index > -1) {
      const chipsCopy = [...this.chips];
      chipsCopy.splice(index, 1);

      this.chips = chipsCopy;

      this.chipName.updateValueAndValidity({
        onlySelf: false,
        emitEvent: true,
      });
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.autocompleteOptions.filter((option) =>
      option.toLowerCase().includes(filterValue),
    );
  }

  private _arrayRemove(options: string[]): string[] {
    return options.filter((option) =>
      this.chips.indexOf(option) === -1 ? true : false,
    );
  }

  get chipName() {
    return this.chipFormGroup.get('chipName');
  }

  selectedOption(event) {
    const chips = this.chips;
    chips.push(event.option.value);

    this.chips = chips;
    this.chipFormGroup.patchValue({ chipName: '' });

    this.chipInput.nativeElement.blur();
    // input.blur();
  }
}

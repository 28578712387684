import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-project-filter',
  templateUrl: './project-filter.component.html',
  styleUrls: ['./project-filter.component.scss'],
})
export class ProjectFilterComponent implements OnInit {
  private _first = false;
  private _last = false;
  private _active = false;

  @Input()
  set first(value) {
    this._first = typeof value !== 'undefined' && value !== false;
  }
  get first(): boolean {
    return this._first;
  }

  @Input()
  set last(value) {
    this._last = typeof value !== 'undefined' && value !== false;
  }
  get last(): boolean {
    return this._last;
  }

  @Input()
  set active(value) {
    this._active = typeof value !== 'undefined' && value !== false;
  }
  get active(): boolean {
    return this._active;
  }

  @Input() count = 0;
  @Input() label = '';

  constructor() {}

  ngOnInit() {}
}

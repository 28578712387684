import {
  ContextAddOrUpdateAction,
  ContextRemoveAction,
  ContextUpdateProjectAction,
  ContextUpdateBudgetAction,
  ContextUpdateHeaderLinksRequestSuccess,
  ContextUpdateActionButtonRequestSuccess,
  ContextUpdateCertificationAction,
  ContextUpdateActiveView,
  ContextUpdateClientAction,
  ContextUpdateSelectedRoleId,
} from './context.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { logoutAction } from '../actions/meta.action';
import { ActionSelect, Link } from '@core/sdk/models/Navigation';

export interface NavigationState {
  header: {
    links: Link[];
  },
  actions: ActionSelect[];
  leftSidebar: {};
}

export interface ContextState {
  value: string;
  currentProjectId: string | null;
  activeView: string;
  navigation: NavigationState;
  currentCertificationId: string | null;
  selectedRoleId: string | null;
}

export const initialState: ContextState = {
  value: null,
  currentProjectId: null,
  currentCertificationId: null,
  selectedRoleId: null,
  activeView: '',
  navigation: {
    header: {
      links: [],
    },
    actions: [],
    leftSidebar: {},
  },
  
};

const contextReducerFn = createReducer(
  initialState,
  on(ContextAddOrUpdateAction, (state, { value }) => ({
    ...state,
    value,
  })),
  on(ContextRemoveAction, () => initialState),
  on(logoutAction, () => initialState),
  on(ContextUpdateProjectAction, (state, { currentProjectId }) => ({ ...state, currentProjectId })),
  on(ContextUpdateActionButtonRequestSuccess, (state, { payload }) => ({
    ...state,
    navigation: {
      ...state.navigation,
      actions: payload,
    },
  })),
  on(
    ContextUpdateHeaderLinksRequestSuccess,
    (state, { payload }) => updateNavigationHeaderLinks(state, payload)
  ),
  on(ContextUpdateBudgetAction, (state, { currentBudgetId }) => ({ ...state, currentBudgetId })),
  on(ContextUpdateClientAction, (state, { currentClientId }) => ({ ...state, currentClientId })),
  on(ContextUpdateCertificationAction, (state, { currentCertificationId }) => ({ ...state, currentCertificationId })),
  on(ContextUpdateActiveView, (state, { activeView }) => ({ ...state, activeView})),
  on(ContextUpdateSelectedRoleId, (state, { selectedRoleId }) => ({ ...state, selectedRoleId })),
);

function updateNavigationHeaderLinks(state: ContextState, payload: any): ContextState {
  const updatedHeader: NavigationState['header'] = {
    ...state.navigation.header,
    links: payload,
  };

  const updatedNavigation: NavigationState = {
    ...state.navigation,
    header: updatedHeader,
  };
  
  return {
    ...state,
    navigation: updatedNavigation,
  };
}

export function contextReducer(state: ContextState | undefined, action: Action) {
  return contextReducerFn(state, action);
}
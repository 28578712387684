import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ValidationErrors,
} from '@angular/forms';
import { PersonService } from '@core/services/person.service';
import { Observable, timer } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class IsEmailInUseValidator implements AsyncValidator {
  constructor(private personService: PersonService) {}
  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return timer(100).pipe(
      switchMap(() => {
        let { email } = control.value;

        if (!email) {
          email = control.value;
        }

        return this.personService.isEmailInUse(email).pipe(
          tap( () => control.markAsTouched() ),
          map((result: boolean) => (result ? { 'email-in-use': true } : null)),
          catchError(() => null),
        );
      }),
    );
  }
}

export const EASY_CALENDAR_URL = 'https://easycalendar.com/pedir-cita-comercial-cly?';

export const EASY_CALENDAR_LM_URL = 'https://easycalendar.com/llamada-lm-cubicup';

export const EASY_CALENDAR_DEVELOPMENT = 'https://cal.so/cubicup-dev-obo';


export const EASY_CALENDAR_SELECTORS = {
  modal: '[id*="myModal-"]',
  modalHeader: '[id*="easycalendar-calendar-widget"]',
  closeButton: '#closeclcmodal',
  success: '#successbooking',
  form: '#bookingform',
  initButton: 'initButton',
  iframe: 'iframe'
}

export const EASY_CALENDAR_STYLE = {
  button: {
    background: 'white',
    border: 'solid 1px black'
  },
  icon: {
    color: 'black'
  }
}

export const USER_FORM_MAP = {
  firstName: '#form-fname',
  lastName: '#form-lname',
  email: '#form-email',
  phoneNumber: '#form-phonenumber',
  address: '#custom_text_0'
}

/* eslint-disable */

declare var Object: any;
export interface S3FileInterface {
  fileName?: string;
  file: string;
  mime: string;
  size: number;
  bucket: string;
  id?: number;
  createdAt: Date;
  updatedAt: Date;
  modelActive?: any;
  documentId?: number;
  fileUrl?: string;
}

export class S3File implements S3FileInterface {
  'fileName': string;
  'file': string;
  'mime': string;
  'size': number;
  'bucket': string;
  'id': number;
  'createdAt': Date;
  'updatedAt': Date;
  'modelActive': any;
  'documentId': number;
  'fileUrl': string;
  constructor(data?: S3FileInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `S3File`.
   */
  public static getModelName() {
    return 'S3File';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of S3File for dynamic purposes.
   **/
  public static factory(data: S3FileInterface): S3File {
    return new S3File(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'S3File',
      plural: 'S3Files',
      path: 'S3Files',
      idName: 'id',
      properties: {
        fileName: {
          name: 'fileName',
          type: 'string',
        },
        file: {
          name: 'file',
          type: 'string',
        },
        mime: {
          name: 'mime',
          type: 'string',
        },
        size: {
          name: 'size',
          type: 'number',
        },
        bucket: {
          name: 'bucket',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'number',
        },
        createdAt: {
          name: 'createdAt',
          type: 'Date',
        },
        updatedAt: {
          name: 'updatedAt',
          type: 'Date',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
        documentId: {
          name: 'documentId',
          type: 'number',
        },
      },
      relations: {},
    };
  }
}

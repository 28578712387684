import {
  Component,
  OnInit,
  Self,
  Optional,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormControl,
} from '@angular/forms';
import { debounceTime, concatMap, switchMap } from 'rxjs/operators';
import { AgentService } from '@core/services/agent.service';
import { S3Service } from '@core/services/s3.service';
import { NotificationsService } from '@core/services/notifications.service';
import { CarouselService } from '@core/services/carousel.service';

const newImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG8AAAByCAYAAABdjY8lAAAABHNCSVQICAgIfAhkiAAAABl0RVh0U29mdHdhcmUAZ25vbWUtc2NyZWVuc2hvdO8Dvz4AAAIzSURBVHic7d3BjqJAFEDRVyWCGuP//6PbFmIQalbTmVmZ2NBw4Z41qbx4hVSQSLrf7yWElJceQJ8zHpjxwIwHZjww44EZD8x4YMYDMx6Y8cCMB2Y8MOOBGQ/MeGDGAzMemPHAjAdmPDDjgRkPzHhgxgMzHpjxwIwHZjww44EZD8x4YMYDMx7YpuNdLpe43W5LjzGbzcbLOcf5fI66ruN0Oi09ziw2G28PjAdmPDDjge0iXkpp6RFmsYt4W2U8MOOBGQ/MeGDGAzMe2KbjlbLt/4HddLytMx6Y8cCMB1YtPcC/UkpxyIe3x5V4vxFJKX3fkM45R87TfE9LKavZCK0mXkoprtdrVNX0IzVNE3VdT7LWMAzx9fUV4zhOst5PrCZezjmqqorD4f2Z98naU641DMMqfmZaTbxhGKJt2//i/eQDSilF0zSRUoq+7+P1er09/p1SSozjGDnnVVw6VxMvIuL5fE62Vs456rr+jte27WRrr4W7TTDjgRkPzHhgxgMzHpjxwIwHZjww44HtIt4abiLPYRfxtsp4YMYDMx6Y8cCMB7bZeH8fWYiIVTwsNIdVPQYxpVJKPB6POB6P0XXd0uPMYrPxIiL6vo++75ceYzabvWzugfHAjAdmPDDjgRkPzHhgxgMzHpjxwIwHZjww44EZD8x4YMYDMx6Y8cCMB2Y8MOOBGQ/MeGDGAzMemPHAjAdmPDDjgRkPzHhgxgMzHlie8p0D+j0550hd1y3/ggB95A99qlXyWMzEXgAAAABJRU5ErkJggg==';

@Component({
  selector: 'app-portfolio-image-gallery',
  templateUrl: './portfolio-image-gallery.component.html',
  styleUrls: ['./portfolio-image-gallery.component.scss'],
})
export class PortfolioImageGalleryComponent
  implements OnInit, ControlValueAccessor {
  private _onChange: Function;
  private _onTouched: Function;

  formGroup: UntypedFormGroup;
  showCarousel = false;
  carouselImages = [];

  @Output() fileDeleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() imageUploadedEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Self() @Optional() public controlDir: NgControl,
    private fb: UntypedFormBuilder,
    private agentService: AgentService,
    private s3Service: S3Service,
    private notificationsService: NotificationsService,
    private carouselService: CarouselService,
  ) {
    controlDir.valueAccessor = this;

    this.formGroup = this.fb.group({
      images: new UntypedFormArray([]),
      newImage: new UntypedFormControl(newImage),
    });
  }

  ngOnInit() {
    this.images.valueChanges.subscribe((images: string[]) =>
      this._onChange(images),
    );

    this.newImage.valueChanges
      .pipe(debounceTime(100))
      .subscribe((image: Document) => this.setNewImage(image));

    this.images.value.forEach(
      (image) => {
        const [version] = image.versions;

        this.carouselImages.push({
          src: version.fileUrl,
          name: version.fileName
        });
      }
    );
  }

  get images(): UntypedFormArray {
    return this.formGroup.get('images') as UntypedFormArray;
  }

  get newImage(): UntypedFormControl {
    return this.formGroup.get('newImage') as UntypedFormControl;
  }

  prepare(images: string[]): UntypedFormControl[] {
    if (!images || !Array.isArray(images)) {
      images = [];
    }

    return images.map((image: string) => new UntypedFormControl(image));
  }

  setNewImage(image: Document) {
    this.images.push(new UntypedFormControl(image));
    this.newImage.setValue(newImage, { emitEvent: false });
  }

  remove(index: number) {
    this.images.removeAt(index);
  }

  writeValue(images: string[]) {
    this.formGroup.setControl('images', new UntypedFormArray(this.prepare(images)));
  }

  registerOnChange(fn: Function) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  private isExtensionValid(fileName) {
    if (fileName.includes('.heic') || fileName.includes('.heif')) {
      this.notificationsService.showError('Formato HEIC no aceptado.');

      return;
    }

    if (fileName.includes('.psd')) {
      this.notificationsService.showError('Formato PSD no aceptado.');

      return;
    }

    if (fileName.includes('.gif')) {
      this.notificationsService.showError('Formato GIF no aceptado.');

      return;
    }

    return true;
  }

  handleDrop(evt) {
    const files = evt.target.files;
    const file: File = files.length > 0 ? files.item(0) : null;

    if (!file) {
      return;
    }

    if (!this.isExtensionValid(file.name)) {
      return;
    }

    this.handleFileUploaded(file);
  }

  private handleFileUploaded(file) {
    const imagesLength = this.images.value.length;
    const imageKey = `portfolio-image-${imagesLength}`;
    const imageName = `portfolio-image-attached-${imagesLength}`;

    this.agentService
      .createPortfolioImage(imageKey, imageName)
      .pipe(
        switchMap((image: any) => this.s3Service.s3uploadfile(file, image.id)),
      )
      .subscribe((s3FileResult: any) => {
        this.notificationsService.showSuccess('Imagen subida');
        this.imageUploadedEmitter.emit();
      });
  }

  openCarousel(event) {
    this.carouselService.toggleCarousel({ images: this.carouselImages });
  }
}

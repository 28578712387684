import { Injectable, NgZone } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private _snackBar: MatSnackBar, private zone: NgZone) {}

  showSuccess(message: string): void {
    this._snackBar.open(message, 'X', {
      duration: 3000,
    });
  }

  showError(message: string): void {
    this.zone.run(() => {
      this._snackBar.open(message, 'X', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
    });
  }
}

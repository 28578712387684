import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import environment from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { Budget, Client, Invoice, ProjectData } from '@core/sdk';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ExpenseInterface } from '@core/sdk/models/Expense';


@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  public base64 = new Subject<string>();

  constructor(
    private httpClient: HttpClient,
    private store: Store<{ context: any }>,
  ) { }

  getDocumentsByProject(projectID: string, docType: string) {
    const url = `${environment.apiBaseUrl}/api/invoices/${projectID}?docType=${docType}`;

    return this.httpClient.get<Invoice[] | ExpenseInterface[]>(url);
  }

  getPdfInvoice(invoiceID: string, docType: string) {
    const url = `${environment.apiBaseUrl}/api/invoice/pdf/${invoiceID}?docType=${docType}`;

    return this.httpClient.get<Invoice>(url);
}



  newProjectInvoice(projectId, body: ProjectData) {

    const url = `${environment.apiBaseUrl}/api/invoice/newproject/${projectId}`;
   
    return this.httpClient.post<Invoice>(url, body)
  }
}

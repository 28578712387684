<div class="card-custom" matRipple>
  <div class="header">
    <mat-icon  svgIcon="illustration:workman"></mat-icon>
    <span class="title">
      {{ contentToShow.slug | translate }}
    </span>
  </div>


  <button type="submit" mat-button color="accent" (click)="viewHelp()">
    {{ 'watch-video' | translate }}
  </button>
</div>
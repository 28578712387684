import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NoteService } from '@core/services/note.service';
import { NotificationsService } from '@core/services/notifications.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ResponsiveService } from '@core/services/responsive.service';
import { Store } from '@ngrx/store';
import { AgentInterface, ClientInterface, LeadInterface } from '@core/sdk';

@Component({
  selector: 'app-notes-modal',
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.scss'],
})
export class NotesModalComponent implements OnInit {
  editMode = false;
  editForm: UntypedFormGroup;
  editingNote: any;
  viewMode: string;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '250px',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Escribe aquí tu nota...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [],
      ['customClasses', 'insertImage', 'insertVideo', 'toggleEditorMode'],
    ],
  };
  context: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NotesModalComponent>,
    private noteService: NoteService,
    private notificationsService: NotificationsService,
    private formBuilder: UntypedFormBuilder,
    private responsiveService: ResponsiveService,
    private store: Store<{
      agent: AgentInterface;
      client: ClientInterface;
      projects: LeadInterface[];
      context: any;
    }>,
  ) {}

  ngOnInit() {
    this.editForm = this.formBuilder.group({
      editor: new UntypedFormControl(''),
    });

    this.store
      .select('context')
      .subscribe(({ value: context }) => (this.context = context));

    this.responsiveService.mode.subscribe((viewMode) => {
      this.viewMode = viewMode;
      this.initializeEditor();
    });
  }

  private initializeEditor() {
    this.editingNote = this.data.note;

    if (this.data.note) {
      this.editForm.get('editor').setValue(this.editingNote.body);
    }

    if (this.viewMode !== 'web') {
      this.editorConfig.showToolbar = false;

      return;
    }
  }

  addNewNote() {
    if (this.editForm.invalid) {
      return;
    }

    if (this.data.targetType === 'Lead') {
      this.setNoteToProject();

      return;
    }
  }

  private setNoteToProject() {
    const noteBody = this.editForm.get('editor').value;

    if (this.editingNote) {
      this.noteService
        .editNote(noteBody, this.editingNote.id)
        .subscribe((newNote: any) => {
          this.notificationsService.showSuccess('Nota actualizada');
          this.dialogRef.close({ result: 'update', note: newNote });
        });

      return;
    }

    switch (this.context) {
      case 'agent':
        this.noteService
          .addNewNoteToProject(this.data.target.id, noteBody)
          .subscribe(this.handleNewNote.bind(this));
        break;
      case 'login-client':
        this.noteService
          .addLoginClientNewNoteToProject(this.data.target.id, noteBody)
          .subscribe(this.handleNewNote.bind(this));
        break;

      default:
        break;
    }
  }

  private handleNewNote(newNote) {
    this.notificationsService.showSuccess('Nota creada');
    this.dialogRef.close({ result: 'create', note: newNote });
  }
}

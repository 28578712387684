/* eslint-disable */
import { Budget, Action, Agent, Event, Activity, Client, Certification } from './index';
import { Conversation } from './Conversation';
import { CubicupStaff } from './CubicupStaff';
import { TimelineInterface } from './Timeline';

declare var Object: any;
export interface LeadInterface {
  leadName: string;
  contact?: any;
  address?: any;
  description?: string;
  observations?: string;
  archived?: boolean;
  cubicupLeadId?: string;
  originalBriefing?: any;
  checkedBriefing?: Array<any>;
  id?: string;
  state?: string;
  stateLog?: Array<any>;
  createdAt?: Date;
  updatedAt?: Date;
  modelActive?: any;
  agentId?: string;
  clientId?: string;
  budgets?: Budget[];
  actions?: Action[];
  agent?: Agent;
  events?: Event[];
  activities?: Activity[];
  client?: Client;
  active?: Boolean;
  own?: Boolean;
  isNew?: Boolean;
  cubicupStaffId?: CubicupStaff;
  cubicupStaff?: CubicupStaff;
  cubicupStaffConversation?: Conversation;
  agentFeeling?: number;
  visitComment?: string;
  tracking?: Boolean;
  invoices?: Boolean;
  code?: string;
  shortCode?:string;
  startDate?: string;
  endDate?: string;
  budgetTrackingId?: string;
  trackingLeadId?: string;
  timeline?: TimelineInterface[]
  urn?: string;
  ambientalCost?: {
    weight: number,
    price: number
  };
  callScheduled?: Boolean;
  booking?: {
    status: string,
    date: string,
    amount: string,
    taxPercent: number
  },
  economicStatus?: any;
  pendingCertification?: Certification;
  latestAcceptedCertification?: Certification;

  teamId?: string;
}

export class Lead implements LeadInterface {
  'leadName': string;
  'contact'?: any;
  'address'?: any;
  'description'?: string;
  'observations'?: string;
  'archived'?: boolean;
  'cubicupLeadId'?: string;
  'originalBriefing'?: any;
  'checkedBriefing'?: Array<any>;
  'id'?: string;
  'state'?: string;
  'stateLog'?: Array<any>;
  'createdAt'?: Date;
  'updatedAt'?: Date;
  'modelActive'?: any;
  'agentId'?: string;
  'clientId'?: string;
  budgets?: Budget[];
  actions?: Action[];
  agent?: Agent;
  events?: Event[];
  activities?: Activity[];
  client?: Client;
  active?: Boolean;
  own?: Boolean;
  isNew?: Boolean;
  cubicupStaffId?: CubicupStaff;
  cubicupStaff?: CubicupStaff;
  cubicupStaffConversation?: Conversation;
  agentFeeling?: number;
  visitScoring?: number;
  visitComment?: string;
  tracking?: Boolean;
  invoices?: Boolean;
  code?: string;
  shortCode?:string;
  startDate?: string;
  endDate?: string;
  budgetTrackingId?: string;
  trackingLeadId?: string;
  timeline?: TimelineInterface[];
  urn?: string;
  ambientalCost?: {
    weight: number,
    price: number
  };
  callScheduled?: Boolean;
  booking?: {
    status: string,
    date: string,
    amount: string,
    taxPercent: number
  };
  economicStatus?: any;
  pendingCertification?: Certification;
  latestAcceptedCertification?: Certification;

  teamId?: string;

  constructor(data?: LeadInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Lead`.
   */
  public static getModelName() {
    return 'Lead';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Lead for dynamic purposes.
   **/
  public static factory(data: LeadInterface): Lead {
    return new Lead(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Lead',
      plural: 'Leads',
      path: 'Leads',
      idName: 'id',
      properties: {
        leadName: {
          name: 'leadName',
          type: 'string',
        },
        contact: {
          name: 'contact',
          type: 'any',
        },
        address: {
          name: 'address',
          type: 'any',
        },
        observations: {
          name: 'observations',
          type: 'string',
        },
        archived: {
          name: 'archived',
          type: 'boolean',
          default: false,
        },
        cubicupLeadId: {
          name: 'cubicupLeadId',
          type: 'string',
          default: '523',
        },
        originalBriefing: {
          name: 'originalBriefing',
          type: 'Array&lt;any&gt;',
        },
        checkedBriefing: {
          name: 'checkedBriefing',
          type: 'Array&lt;any&gt;',
        },
        id: {
          name: 'id',
          type: 'number',
        },
        state: {
          name: 'state',
          type: 'string',
          default: 'pending',
        },
        stateLog: {
          name: 'stateLog',
          type: 'Array&lt;any&gt;',
        },
        createdAt: {
          name: 'createdAt',
          type: 'Date',
        },
        updatedAt: {
          name: 'updatedAt',
          type: 'Date',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
        agentId: {
          name: 'agentId',
          type: 'number',
        },
        clientId: {
          name: 'clientId',
          type: 'number',
        },
      },
      relations: {
        budgets: {
          name: 'budgets',
          type: 'Budget[]',
          model: 'Budget',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'leadId',
        },
        actions: {
          name: 'actions',
          type: 'Action[]',
          model: 'Action',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'referenceId',
        },
        agent: {
          name: 'agent',
          type: 'Agent',
          model: 'Agent',
          relationType: 'belongsTo',
          keyFrom: 'agentId',
          keyTo: 'id',
        },
        events: {
          name: 'events',
          type: 'Event[]',
          model: 'Event',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'leadId',
        },
        activities: {
          name: 'activities',
          type: 'Activity[]',
          model: 'Activity',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'leadId',
        },
        client: {
          name: 'client',
          type: 'Client',
          model: 'Client',
          relationType: 'belongsTo',
          keyFrom: 'clientId',
          keyTo: 'id',
        },
      },
    };
  }
}

import {
  ProjectAddAction,
  ProjectDeleteAction,
  ProjectsAddAction,
  ProjectUpdateAction,
  ProjectsClearAction,
  ProjectUpsertAction,
  addProject,
} from '../actions/project.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import { Lead } from '@core/sdk';
import { logoutAction } from '../actions/meta.action';

export interface ProjectsState extends EntityState<Lead> {}

export const projectsAdapter: EntityAdapter<Lead> = createEntityAdapter<Lead>();
export const initialProjectsState: ProjectsState = projectsAdapter.getInitialState();

const projectsReducer = createReducer(
  initialProjectsState,
  on(ProjectAddAction, (state, project) => {
    return projectsAdapter.addOne(project, state);
  }),
  on(addProject, (state, { project }) => {
    return projectsAdapter.upsertOne(project, state);
  }),
  on(ProjectUpdateAction, (state, { update }) => {
    return projectsAdapter.updateOne(update, state);
  }),
  on(ProjectUpsertAction, (state, { project }) => {
    return projectsAdapter.upsertOne(project, state);
  }),
  on(ProjectsAddAction, (state, { projects }) => {
    return projectsAdapter.addMany(projects, state);
  }),
  on(ProjectDeleteAction, (state, { id }) => {
    return projectsAdapter.removeOne(id, state);
  }),
  on(ProjectsClearAction, (state) => {
    return projectsAdapter.removeAll({ ...state });
  }),
  on(logoutAction, (state) => projectsAdapter.removeAll({ ...state })),
);

export function reducer(state: ProjectsState | undefined, action: Action) {
  return projectsReducer(state, action);
}

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = projectsAdapter.getSelectors();

// select the array of projects ids
export const selectProjectsIdsSelector = selectIds;

// select the dictionary of projects entities
export const selectProjectsEntitiesSelector = selectEntities;

// select the array of projects
export const selectAllProjectsSelector = selectAll;

// select the total projects count
export const selectProjectsTotalSelector = selectTotal;

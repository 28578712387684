/* eslint-disable */
import { Agent } from './index';
import { DocumentInterface } from './Document';
import { BannedPermission } from './RoleWithAssignedPeople';

declare var Object: any;
export interface PersonInterface {
  cubicupPartnerCode?: string;
  superAdmin?: boolean;
  realm?: string;
  username?: string;
  email: string;
  emailVerified?: boolean;
  id?: number;
  modelActive?: any;
  password?: string;
  avatar?: string;
  accessTokens?: any[];
  agents?: Agent[];
  documents?: DocumentInterface[];
  phoneNumber?: string;
  name?: string;
  termsAndConditions?: boolean;
  newsLetter?: boolean;
  address?: any;
  userType?: string;
  bannedPermissions?: BannedPermission[];
}

export class Person implements PersonInterface {
  'cubicupPartnerCode': string;
  'superAdmin': boolean;
  'realm': string;
  'username': string;
  'email': string;
  'emailVerified': boolean;
  'id': number;
  'modelActive': any;
  'password': string;
  'avatar'?: string;
  clientId?: string;
  accessTokens?: any[];
  agents?: Agent[];
  phoneNumber?: string;
  name?: string;
  documents?: DocumentInterface[];
  termsAndConditions?: boolean;
  newsLetter?: boolean;
  address?: any;
  constructor(data?: PersonInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Person`.
   */
  public static getModelName() {
    return 'Person';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Person for dynamic purposes.
   **/
  public static factory(data: PersonInterface): Person {
    return new Person(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Person',
      plural: 'People',
      path: 'People',
      idName: 'id',
      properties: {
        cubicupPartnerCode: {
          name: 'cubicupPartnerCode',
          type: 'string',
        },
        superAdmin: {
          name: 'superAdmin',
          type: 'boolean',
          default: false,
        },
        realm: {
          name: 'realm',
          type: 'string',
        },
        username: {
          name: 'username',
          type: 'string',
        },
        email: {
          name: 'email',
          type: 'string',
        },
        emailVerified: {
          name: 'emailVerified',
          type: 'boolean',
        },
        id: {
          name: 'id',
          type: 'number',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
        password: {
          name: 'password',
          type: 'string',
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'userId',
        },
        agents: {
          name: 'agents',
          type: 'Agent[]',
          model: 'Agent',
          relationType: 'hasMany',
          modelThrough: 'Position',
          keyThrough: 'agentId',
          keyFrom: 'id',
          keyTo: 'personId',
        },
      },
    };
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action } from "@core/sdk";
import environment from "@environment";

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  constructor(
    private httpClient: HttpClient,
  ) {}

  generateNewAction(actionData: Partial<Action>) {

    return this.httpClient.post<Action>(
      `${environment.apiBaseUrl}/api/Actions`, 
      actionData,
      {
        headers: { 'Content-Type': 'application/json' }  
      }
    );
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd, Event } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { ConversationService } from '@core/services/conversation.service';
import { NotificationsCenterService } from '@core/services/notifications-center.service';
import { RemindersCenterService } from '@core/services/reminders-center.service';
import { CarouselService } from '@core/services/carousel.service';
import { DiscussionService } from '@core/services/discussion.service';
import { AuthService } from '@core/services/auth.service';
import { Store } from '@ngrx/store';
import environment from '../environments/environment';
import { LoopBackConfig } from './core/sdk';
import { AppService } from './app.service';
import { ResponsiveService } from '@core/services/responsive.service';
import { googleAnalytics } from 'src/assets/js/google-analytics';
import { WebSocketService } from './@websocket/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'cubi-wapp';
  hideSidebarAndHeader$: Observable<boolean>;
  hideSidebar$ = new BehaviorSubject<boolean>(true);
  toggleConversation$: Observable<boolean>;
  toggleDiscussion$: Observable<boolean>;
  toggleNotificationsCenter$: Observable<any>;
  toggleRemindersCenter$: Observable<any>;
  toggleCarousel$: Observable<any>;
  unsubscriber$ = new Subject<void>();
  isMobile : boolean = true;
  context: string;

  constructor(
    translateService: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private conversationService: ConversationService,
    private discussionService: DiscussionService,
    private notificationsCenterService: NotificationsCenterService,
    private remindersCenterService: RemindersCenterService,
    private carouselService: CarouselService,
    private authService: AuthService,
    private store: Store<{ context: any }>,
    private appService: AppService,
    private responsiveService: ResponsiveService,
    private webSocketService: WebSocketService
  ) {
    webSocketService.callback.subscribe((data) => {
      console.log('fileUploadCompleted', data);
    });
    translateService.setDefaultLang('es_ES');
    translateService.use('es_ES');

    LoopBackConfig.setBaseURL(environment.apiBaseUrl);

    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/sprite.svg',
      ),
    );

    this.matIconRegistry.addSvgIconSetInNamespace(
      '10px',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/sprites/10px-sprite.svg',
      ),
    );

    this.matIconRegistry.addSvgIconSetInNamespace(
      '12px',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/sprites/12px-sprite.svg',
      ),
    );

    this.matIconRegistry.addSvgIconSetInNamespace(
      '16px',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/sprites/16px-sprite.svg',
      ),
    );

    this.matIconRegistry.addSvgIconSetInNamespace(
      '24px',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/sprites/24px-sprite.svg',
      ),
    );

    this.matIconRegistry.addSvgIconSetInNamespace(
      'docType',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/sprites/docType-sprite.svg',
      ),
    );

    this.matIconRegistry.addSvgIconSetInNamespace(
      'illustration',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/sprites/illustration-sprite.svg',
      ),
    );

    this.matIconRegistry.addSvgIconSetInNamespace(
      'jobType',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/sprites/jobType-sprite.svg',
      ),
    );

    this.matIconRegistry.addSvgIconSetInNamespace(
      'misc',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/sprites/misc-sprite.svg',
      ),
    );

    this.matIconRegistry.addSvgIcon(
      'logo-main',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/logo-logo-primary.svg',
      ),
      { viewBox: '0 0 342 88' },
    );

    this.matIconRegistry.addSvgIcon(
      'logo-mini',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/img/logo-mini.svg',
      ),
    );

    this.matIconRegistry.addSvgIcon(
      '12px-star',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/12px-star.svg',
      ),
    );

    this.matIconRegistry.addSvgIcon(
      '12px-emptyStar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/12px-emptyStar.svg',
      ),
    );

    this.matIconRegistry.addSvgIcon(
      'chapter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/chapter.svg',
      ),
    );

    this.matIconRegistry.addSvgIcon(
      'line-down',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/line-down.svg',
      ),
    );

    this.matIconRegistry.addSvgIcon(
      'annex',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/icon-24-px-annex.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'camera',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/icons-sample/camera.svg',
      ),
    );


    this.matIconRegistry.registerFontClassAlias(
      'cubicup-icons',
      'cubicup-icons',
    );
  }

  ngOnInit() {
    this.authService.selectContext();
    this.store
      .select('context')
      .subscribe(({ value: context }) => (this.context = context));

    this.router.events
      .pipe(
        filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        const { url } = event;

        if (
          url !== null &&
          url !== undefined &&
          url !== '' &&
          url.indexOf('null') < 0
        ) {
          googleAnalytics(environment, url);
        }
      });

    this.hideSidebarAndHeader$ = this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map<NavigationEnd, boolean>((event: NavigationEnd) => this.appService.isFullScreenRoute(event.urlAfterRedirects)),
    );
    this.toggleConversation$ = this.conversationService.getToggleConversation();
    this.toggleDiscussion$ = this.discussionService.getToggleConversation();
    this.toggleNotificationsCenter$ = this.notificationsCenterService.getToggleNotificationsCenter();
    this.toggleRemindersCenter$ = this.remindersCenterService.getToggleRemindersCenter();
    this.toggleCarousel$ = this.carouselService.getToggleCarousel();
    this.isMobile = this.responsiveService.mobileCheck();
    // Without sidenav but with header
    this.router.events
      .pipe(
        takeUntil(this.unsubscriber$),
        filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
        map<NavigationEnd, boolean>((event: NavigationEnd) => this.appService.doNotShowSideBar(event.urlAfterRedirects, this.context)),
      )
      .subscribe((hidden:boolean) =>
        this.hideSidebar$.next(hidden)
      );
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
  }

  closeCarousel() {
    this.carouselService.toggleCarousel();
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONTEXTS, DEFAULT_ICON, DOCUMENT_TYPE_ICONS, IMAGE_EXTENSIONS, VIDEO_EXTENSIONS } from '@core/constants';
import { Client, DocumentInterface } from '@core/sdk';
import environment from '@environment';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, switchMap, take, tap } from 'rxjs';
import { DOCUMENTATION_FOLDER_STRUCTURE } from 'src/app/pages/documentation2/documentation-folder-struture';

@Injectable({
  providedIn: 'root',
})
export class DocumentationService {
  documentationCount$ = new Subject()
  folderNotifications$ = new BehaviorSubject<any[]>([])
  private context$: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(private httpClient: HttpClient, private store: Store<{ client: Client, context: any }>,) {
    this.store.select('context').subscribe(({value: context, currentProjectId }) => {
      if (!context || !currentProjectId) {
        this.documentationCount$.next(null);
      }

      this.context$.next({
        context: context,
        currentProjectId: currentProjectId
      });
    })
  }

  getOptionsByContext(context: string) {
    if (context === CONTEXTS.AGENT) {
      return {
        uploadImages: true,
        removeImages: true,
        imagesSubtitleSlug: '',
        imagesKey: 'tracking',
        uploadFiles: true,
        removeFiles: true,
        filesKey: 'tracking',
        filesSubtitleSlug: '',
        uploadUrls: true,
        removeUrls: true,
        urlsSubtitleSlug: '',
      };
    }

    if (context === CONTEXTS.LOGIN_CLIENT) {
      return {
        uploadImages: false,
        imagesSubtitleSlug: 'images-documents-subtitle',
        removeImages: true,
        imagesKey: 'project',
        uploadFiles: false,
        filesSubtitleSlug: 'files-documents-subtitle',
        filesKey: 'project',
        uploadUrls: false,
        urlsSubtitleSlug: 'links-documents-subtitle',
      };
    }
  }

  isExtensionValidToInputType(
    fileName: string,
    inputType: 'images' | 'documents' | 'all'
  ) {
    if (inputType === 'all') return true;

    const fileExtension = fileName.split('.').pop();

    if (
      inputType === 'images' &&
      !(VIDEO_EXTENSIONS.includes(fileExtension) ||
        IMAGE_EXTENSIONS.includes(fileExtension))
    ) {
      return false;
    }

    if (
      inputType === 'documents' &&
      (IMAGE_EXTENSIONS.includes(fileExtension) ||
        VIDEO_EXTENSIONS.includes(fileExtension))
    ) {
      return false;
    }

    return true;
  }

  isGalleryItem(itemType): boolean {
    const mime = itemType.split('/')[1];
    return VIDEO_EXTENSIONS.includes(mime) ||
        IMAGE_EXTENSIONS.includes(mime);
  }

  isImageItem(itemType): boolean {
    const mime = itemType.split('/')[1];

    return IMAGE_EXTENSIONS.includes(mime);
  }

  isVideoItem(itemType): boolean {
    const mime = itemType.split('/')[1];

    return VIDEO_EXTENSIONS.includes(mime);
  }

  getDocumentIcon(itemType: string) {
    return DOCUMENT_TYPE_ICONS.find((t) => itemType?.includes(t.id))?.path ?? DEFAULT_ICON;
  }

  isExtensionValid(fileName) {
    if (fileName.includes('.heic') || fileName.includes('.heif')) {
      return 'Formato HEIC no aceptado.';
    }

    if (fileName.includes('.psd')) {
      return 'Formato PSD no aceptado.';
    }

    if (fileName.includes('.gif')) {
      return 'Formato GIF no aceptado.';
    }

    return null;
  }

  getFolderTitle(folderName) {
    let title = '';

    DOCUMENTATION_FOLDER_STRUCTURE.forEach((phase) => {
      const folder = phase.folders.find((folder) => folder.name === folderName);

      if (folder) {
        title = folder.title;

        return;
      }
    })

    return title;
  }

   uploadMultipleDocuments(documents: Partial<DocumentInterface>[]): Observable<DocumentInterface[]>{
    const url = `${environment.apiBaseUrl}/api/Documents/multiple`;

    return this.httpClient.post<DocumentInterface[]>(url, documents, {
      headers: { 'Content-Type': 'application/json' }  
    })
  }

  getNotificableDocumentsCount(leadId: string){
    const url = `${environment.apiBaseUrl}/api/Documents/notifications/count?leadid=${leadId}`;

    return this.httpClient.get<number>(url);
  }

  getNotificableDocuments() {
    this.context$
      .pipe(
        take(1),
        switchMap(({ context, currentProjectId }) => {
          if (!context || !currentProjectId) {
            throw new Error('Context or Project ID is missing');
          }
  
          return this.getNotificableDocumentsCount(currentProjectId);
        }),
        tap((result: any) => {
          this.documentationCount$.next({
            color: 'warn',
            text: result.length, 
            hide: result.length > 0 ? 'block' : 'none'
          });
          
          this.folderNotifications$.next(result);
        })
      )
      .subscribe({
        error: (err) => console.error('Error fetching notifications count:', err)
      });
  }

  getLastDocuments(leadId: string, limit: number = 4): Observable<DocumentInterface[]> {
    const url = `${environment.apiBaseUrl}/api/Documents`;
    const filter: any = {
      where: {
        leadId,
        s3Saved: true
      },
      order: 'createdAt DESC',
      limit,
      include: ['versions'],
    };

    const httpOptions = {
      params: {
        filter: JSON.stringify(filter)
      },
      observe: 'body' as const 
    };

    return this.httpClient.get<DocumentInterface[]>(url, httpOptions);
  }
}
/* eslint-disable */
import { Action, Chapter, Lead, Agent, Document } from './index';

declare var Object: any;
export interface BudgetInterface {
  type?: string;
  leadName?: string;
  position?: string;
  code?: string;
  title?: string;
  description?: string;
  observations?: string;
  budgetChapters?: any;
  budgetDocuments?: any;
  paymentMethod?: any;
  duration?: number;
  taxPercent?: number;
  total?: number;
  totalSigned?: number;
  sendToClient?: boolean;
  subType?:string;
  owner?:string;
  isOwnProject?: boolean;
  emailToSend?: boolean;
  commentsToSend?: string;
  id?: string;
  parentId?: string;
  state?: string;
  stateLog?: Array<any>;
  createdAt?: Date;
  updatedAt?: Date;
  modelActive?: any;
  leadId?: string;
  clientId?: string;
  agentId?: string;
  terms?: string;
  parent?: Budget;
  versions?: Budget[];
  actions?: Action[];
  chapters?: Partial<Chapter>[];
  lead?: Lead;
  agent?: Agent;
  documents?: Document[];
  archived?: boolean;
  totalBenefit?: number;
  totalCost?: number;
  originalBriefing?: any;
  quizCoefficients?:any;
}

export class Budget implements BudgetInterface {
  'type'?: string;
  'leadName'?: string;
  'position'?: string;
  'code'?: string;
  'title'?: string;
  'description'?: string;
  'observations'?: string;
  'budgetChapters'?: any;
  'budgetDocuments'?: any;
  'paymentMethod'?: any;
  'duration'?: number;
  'taxPercent'?: number;
  'total'?: number;
  'sendToClient'?: boolean;
  'subType'?:string;
  'owner'?:string;
  'isOwnProject'?: boolean;
  'emailToSend'?: boolean;
  'id'?: string;
  'parentId'?: string;
  'state'?: string;
  'stateLog'?: Array<any>;
  'createdAt'?: Date;
  'updatedAt'?: Date;
  'modelActive'?: any;
  'leadId'?: string;
  'clientId'?:string;
  'agentId'?: string;
  terms?: string;
  parent?: Budget;
  versions?: Budget[];
  actions?: Action[];
  chapters?: Partial<Chapter>[];
  lead?: Lead;
  agent?: Agent;
  documents?: Document[];
  totalBenefit?: number;
  totalCost?: number;
  quizCoefficients?:any;

  constructor(data?: BudgetInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Budget`.
   */
  public static getModelName() {
    return 'Budget';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Budget for dynamic purposes.
   **/
  public static factory(data: BudgetInterface): Budget {
    return new Budget(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Budget',
      plural: 'Budgets',
      path: 'Budgets',
      idName: 'id',
      properties: {
        type: {
          name: 'type',
          type: 'string',
        },
        leadName: {
          name: 'leadName',
          type: 'string',
        },
        terms: {
          name: 'terms',
          type: 'string',
        },
        position: {
          name: 'position',
          type: 'string',
        },
        code: {
          name: 'code',
          type: 'string',
        },
        title: {
          name: 'title',
          type: 'string',
          default: 'title',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        observations: {
          name: 'observations',
          type: 'string',
        },
        budgetChapters: {
          name: 'budgetChapters',
          type: 'any',
        },
        budgetDocuments: {
          name: 'budgetDocuments',
          type: 'any',
        },
        paymentMethod: {
          name: 'paymentMethod',
          type: 'any',
        },
        duration: {
          name: 'duration',
          type: 'number',
          default: 0,
        },
        taxPercent: {
          name: 'taxPercent',
          type: 'number',
          default: 2100,
        },
        total: {
          name: 'total',
          type: 'number',
          default: 0,
        },
        sendToClient: {
          name: 'sendToClient',
          type: 'boolean',
          default: false,
        },
        subType: {
          name: 'subType',
          type: 'string',
        },
        owner: {
          name: 'owner',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'number',
        },
        parentId: {
          name: 'parentId',
          type: 'number',
        },
        state: {
          name: 'state',
          type: 'string',
          default: 'created',
        },
        stateLog: {
          name: 'stateLog',
          type: 'Array&lt;any&gt;',
        },
        createdAt: {
          name: 'createdAt',
          type: 'Date',
        },
        updatedAt: {
          name: 'updatedAt',
          type: 'Date',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
        leadId: {
          name: 'leadId',
          type: 'number',
        },
        agentId: {
          name: 'agentId',
          type: 'number',
        },
      },
      relations: {
        parent: {
          name: 'parent',
          type: 'Budget',
          model: 'Budget',
          relationType: 'belongsTo',
          keyFrom: 'parentId',
          keyTo: 'id',
        },
        versions: {
          name: 'versions',
          type: 'Budget[]',
          model: 'Budget',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'parentId',
        },
        actions: {
          name: 'actions',
          type: 'Action[]',
          model: 'Action',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'referenceId',
        },
        chapters: {
          name: 'chapters',
          type: 'Chapter[]',
          model: 'Chapter',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'budgetId',
        },
        lead: {
          name: 'lead',
          type: 'Lead',
          model: 'Lead',
          relationType: 'belongsTo',
          keyFrom: 'leadId',
          keyTo: 'id',
        },
        agent: {
          name: 'agent',
          type: 'Agent',
          model: 'Agent',
          relationType: 'belongsTo',
          keyFrom: 'agentId',
          keyTo: 'id',
        },
        documents: {
          name: 'documents',
          type: 'Document[]',
          model: 'Document',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'ownerId',
        },
      },
    };
  }
}

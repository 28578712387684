<div *ngIf="data.postmodernImage" class="postmodern">
  <img [src]="data.postmodernImage" alt="">
</div>

<div class="header" [ngClass]="{'postmodern-mode': data.postmodernImage}" data-cy="decision-modal">
  <div class="left">
    <mat-icon *ngIf="data.svgIcon" [svgIcon]="data.svgIcon"></mat-icon>
    <div class="title">
      <span *ngIf="data.title">{{ data.title | translate }}</span>
      <span *ngIf="data.titleHTML" [innerHTML]="data.titleHTML"></span>
      <span *ngIf="data.subtitle" class="subtitle">{{ data.subtitle | translate }}</span>
      <span *ngIf="data.subtitleHTML" class="subtitle" [innerHTML]="data.subtitleHTML"></span>
    </div>
  </div>

  <button mat-icon-button mat-dialog-close *ngIf="data.showCloseButton" data-cy="close-modal">
    <mat-icon svgIcon="24px-close"></mat-icon>
  </button>
</div>

<div class="buttons">
  <button mat-stroked-button [color]="data.colorCancelButton ? data.colorCancelButton : 'accent'" mat-dialog-close
    *ngIf="data.showCancelButton">
    {{ data.cancel ? ( data.cancel | translate ) : ('cancel' | translate) }}
  </button>

  <button mat-flat-button [color]="data.colorCtaButton ? data.colorCtaButton : 'accent'" [mat-dialog-close]="true"
    (click)="submit()" data-cy="accept">
    {{ data.cta ? ( data.cta | translate ) : ('accept' | translate) }}
  </button>

  <form [formGroup]="mustShowForm" class="checkbox-wrapper" *ngIf="data.userCanHideModal">
    <mat-checkbox formControlName="dontShowModal" data-cy="showroom-service">
      <span>{{ 'no-must-show' | translate }}</span>
    </mat-checkbox>
  </form>
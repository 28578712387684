<form [formGroup]="loginForm" (keyup.enter)="login()">
  <span class="title text-xxl">{{ 'login' | translate }}</span>

  <div class="custom-input text-sm">
    <p>{{ 'email' | translate }}</p>
    <mat-form-field appearance="outline" color="primary">
      <input
        matInput
        type="email"
        formControlName="email"
        data-cy="email"
        autocomplete="email"
      />
      <mat-icon matSuffix svgIcon="24px:mailClosed"></mat-icon>
      <mat-error
        class="email-required"
        *ngIf="loginForm.controls.email.hasError('required')"
        [innerHTML]="'field-required' | translate"
        data-cy="email-required"
      >
      </mat-error>
      <mat-error
        class="email-pattern"
        *ngIf="
          loginForm.controls.email.hasError('pattern') &&
          loginForm.controls.email.value.length > 5
        "
        [innerHTML]="'email-field' | translate"
        data-cy="email-pattern"
      >
      </mat-error>
    </mat-form-field>
  </div>

  <div class="custom-input text-sm">
    <p>{{ 'password' | translate }}</p>
    <mat-form-field appearance="outline" color="primary">
      <input
        matInput
        type="password"
        [type]="hidePassword ? 'password' : 'text'"
        formControlName="password"
        data-cy="password"
        autocomplete="current-password"
      />
      <mat-icon
        matSuffix
        *ngIf="hidePassword"
        (click)="hidePassword = !hidePassword"
        svgIcon="24px-hide"
      ></mat-icon>
      <mat-icon
        matSuffix
        *ngIf="!hidePassword"
        (click)="hidePassword = !hidePassword"
        svgIcon="16px-show"
      ></mat-icon>
      <mat-error
        class="password-required"
        *ngIf="loginForm.controls.password.hasError('required')"
        [innerHTML]="'field-required' | translate"
        data-cy="password-required"
      >
      </mat-error>
      <mat-error
        class="password-required"
        *ngIf="loginForm.controls.password.hasError('minlength')"
        [innerHTML]="'password-minlength-error' | translate"
        data-cy="password-minlength"
      >
      </mat-error>
      <mat-error
        class="password-required"
        *ngIf="loginForm.controls.password.hasError('maxlength')"
        [innerHTML]="'password-maxlength-error' | translate"
        data-cy="password-maxlength"
      >
      </mat-error>
    </mat-form-field>
  </div>

  <a
    data-cy="forget-password"
    routerLink="/forget-password"
    class="text-xs primary-link forget-password"
  >
    {{ 'forget-password-question' | translate }}
  </a>

  <mat-checkbox class="text-sm" formControlName="remember">
    {{ 'remember-me' | translate }}
  </mat-checkbox>
</form>

<div class="flex flex-col items-center h-full justify-start lg:justify-between">
  <button
    class="self-stretch mb-8 uppercase"
    #loginButton
    mat-flat-button
    color="primary"
    type="submit"
    (click)="login()"
    data-cy="login-submit"
    [disabled]="loginForm.invalid"
  >
    {{ loginButtonLabel | translate }}
  </button>

  <div class="flex flex-col text-center mt-8 lg:mt-0">
    <span class="text-xs">
      {{ 'not-account' | translate }}
    </span>
    <span class="text-xs" data-cy="client-enter-here">
      {{ 'if-are-propietary' | translate }}

      <a
        *ngIf="utmSource; else noUtm"
        routerLink="/quiz/on-boarding"
        [queryParams]="utmSource"
        class="primary-link"
        data-cy="click-enter-here"
      >
        {{ 'enter-here' | translate }}
      </a>

      <ng-template #noUtm>
        <a routerLink="/quiz/on-boarding" class="primary-link" data-cy="click-enter-here">
          {{ 'enter-here' | translate }}
        </a>
      </ng-template>
    </span>

  </div>
</div>
<div
  class="wrapper"
  [ngClass]="{
    'active-filter': active,
    'first-filter': first,
    'last-filter': last
  }"
>
  <div class="project-filter-content">
    <div class="inner-triangle"></div>
    <p class="project-count">{{ count }}</p>
    <p class="project-state">{{ label }}</p>
  </div>
  <div class="triangle"></div>
</div>

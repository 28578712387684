/* eslint-disable */
import { S3File } from './index';

declare var Object: any;
export interface DocumentInterface {
  key?: string;
  kind?: string;
  name: string;
  description?: string;
  valueDate?: Date;
  dueDate?: Date;
  id?: number;
  ownerId?: string;
  ownerType?: string;
  createdAt: Date;
  updatedAt: Date;
  modelActive?: any;
  versions?: S3File[];
  owner?: any;
  path?: string;
  urlName?: string;
  url?: string;
  tag?: string;
  targetId?: string;
  targetType?: string;
  checked?: boolean;
  originalFileName?: string;
}

export class Document implements DocumentInterface {
  'key'?: string;
  'kind'?: string;
  'name': string;
  'description': string;
  'valueDate': Date;
  'dueDate': Date;
  'id'?: number;
  'ownerId'?: string;
  'ownerType': string;
  'createdAt': Date;
  'updatedAt': Date;
  'modelActive': any;
  'originalFileName'?: string;
  versions?: S3File[];
  owner: any;
  constructor(data?: DocumentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Document`.
   */
  public static getModelName() {
    return 'Document';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Document for dynamic purposes.
   **/
  public static factory(data: DocumentInterface): Document {
    return new Document(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Document',
      plural: 'Documents',
      path: 'Documents',
      idName: 'id',
      properties: {
        key: {
          name: 'key',
          type: 'string',
        },
        kind: {
          name: 'kind',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        valueDate: {
          name: 'valueDate',
          type: 'Date',
        },
        dueDate: {
          name: 'dueDate',
          type: 'Date',
        },
        id: {
          name: 'id',
          type: 'number',
        },
        ownerId: {
          name: 'ownerId',
          type: 'number',
        },
        ownerType: {
          name: 'ownerType',
          type: 'string',
        },
        createdAt: {
          name: 'createdAt',
          type: 'Date',
        },
        updatedAt: {
          name: 'updatedAt',
          type: 'Date',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
      },
      relations: {
        versions: {
          name: 'versions',
          type: 'S3File[]',
          model: 'S3File',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'documentId',
        },
        owner: {
          name: 'owner',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
          keyFrom: 'ownerId',
          keyTo: 'id',
        },
      },
    };
  }
}

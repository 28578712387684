<div class="header">
  <mat-icon svgIcon="illustration-billingInfo"></mat-icon>
  <button mat-icon-button mat-dialog-close>
    <mat-icon svgIcon="24px:close"></mat-icon>
  </button>
</div>

<span>{{ 'question-duplicate-budget' | translate }}</span>

<form [formGroup]="duplicateOptionForm" *ngIf="duplicateOptionForm">
  <mat-radio-group
    aria-label="Select an option"
    formControlName="duplicateOption"
  >
    <mat-option
      *ngFor="let option of options; index as optionIndex"
      [value]="option.id"
      (click)="ifChecked(optionIndex, option)"
    >
      <div class="radio-row" *ngIf="optionIndex === selected; else elseButton">
        <mat-icon svgIcon="16px-checkFilled"></mat-icon>
        {{ option.slug | translate }}
      </div>
      <ng-template #elseButton>
        <div class="radio-row">
          <mat-icon svgIcon="16px-checkEmpty"></mat-icon>
          {{ option.slug | translate }}
        </div>
      </ng-template>
      <mat-divider></mat-divider>
    </mat-option>
  </mat-radio-group>

  <ng-container
    *ngIf="duplicateOptionForm.get('duplicateOption').value === 'other-project'"
  >
    <app-project-selector
      (projectClicked)="handleProjectClicked($event)"
    ></app-project-selector>
  </ng-container>
</form>

<div class="buttons">
  <button mat-stroked-button color="accent" mat-dialog-close>
    {{ 'cancel' | translate }}
  </button>
  <button
    mat-flat-button
    color="accent"
    [mat-dialog-close]="data"
    [disabled]="
      (duplicateOptionForm.get('duplicateOption').value !== 'same-project' &&
        !data.project) ||
      !duplicateOptionForm.get('duplicateOption').value
    "
  >
    {{ 'accept' | translate }}
  </button>
</div>

import { CONTEXTS } from '@core/constants';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  Observable,
  Subject,
  BehaviorSubject,
} from 'rxjs';

import {
  Lead,
  AgentInterface,
  Client,
  UserInterface,
  LeadInterface,
} from '@core/sdk/models';
import { ResponsiveService } from '@core/services/responsive.service';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectProjectById } from 'src/app/store/selectors/project.selectors';
import { TranslateService } from '@ngx-translate/core';
import { MixpanelService } from '@core/services/mixpanel.service';
import { getProjectRequestStarted } from 'src/app/store/actions/project.actions';
import * as Sentry from "@sentry/browser";
import { NotificationsService } from '@core/services/notifications.service';


@Component({
  selector: 'app-bottomnav',
  templateUrl: './bottomnav.component.html',
  styleUrls: ['./bottomnav.component.scss'],
})
export class BottomnavComponent implements OnInit, OnDestroy {
  project$: Observable<Lead>;
  loginClient$: Observable<Client>;
  context$ = new BehaviorSubject<string>(null);
  projectId$ = new BehaviorSubject<string>(null);
  agent$ = new BehaviorSubject<AgentInterface>(null);
  projects$ = new BehaviorSubject<Lead[]>([]);

  currentUrl: string;
  unsubscriber$ = new Subject<void>();
  agent: AgentInterface;

  b2b: boolean;
  ppv: boolean;
  context: any;
  project: LeadInterface;
  currentProjectId: string = null;
  activeView: string;
  superAdmin: boolean;

  constructor(
    private router: Router,
    private responsiveService: ResponsiveService,
    private store: Store<{
      client: Client;
      context: any;
      agent: AgentInterface;
      projects: Lead[];
      user: UserInterface;
    }>,
    private mixpanelService: MixpanelService,
    private translateService : TranslateService,
    private notificationsService : NotificationsService
  ) {
    this.translateService.setDefaultLang('es_ES');
    this.translateService.use('es_ES');
   }

  ngOnInit() {
    try {
      this.store
        .select('context')
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe(({ value: context, currentProjectId }) => {
          if (!context) {
            return;
          }

          this.context = context;

          if(context === CONTEXTS.AGENT) {
            this.initializeAgent();
          }

          if(currentProjectId && !this.currentProjectId){
            this.currentProjectId = currentProjectId;
            this.store.dispatch(getProjectRequestStarted({ projectId: currentProjectId }));
            this.store.select(selectProjectById(currentProjectId)).subscribe((project) => this.project = project)
          }

          this.context$.next(context);
          this.projectId$.next(currentProjectId);
        });

      this.store
        .select('user')
        .pipe(
          takeUntil(this.unsubscriber$)
        )
        .subscribe((user) => {this.superAdmin = user.superAdmin});

      this.loginClient$ = this.store.select('client');
      this.loginClient$
        .pipe(
          takeUntil(this.unsubscriber$)
        )
        .subscribe(({b2b, ppv}) => {
          this.b2b = b2b;
          this.ppv = ppv;
        });
    } catch (error) {
        const errorInfo =  {
          user: this.project?.contact?.email,
          error: error
        }
          Sentry.withScope((scope) => {
          scope.setExtras({
            errorInfo,
          });
          Sentry.captureException(new Error('BottomNavNotWorking'), {
            tags: {
              bottomnav: 'true',
            },
          });
        });
    }
  }

  ngOnDestroy() {
    this.responsiveService.unsubscribeAll();
    this.unsubscriber$.next();
  }

  goTo(url: string) {
    switch (this.context) {
      case 'agent':
        if (url === 'landing') {
          this.router.navigate([url, this.agent.id]);

          return;
        }

        this.router.navigate([url]);
        break;

      case 'login-client':
        this.activeView = url;
        this.mixpanelService.eventEmitter(`customer-mobile-bottomnav-clicked-${url}`, {});

        if (url === 'home') {
          this.router.navigate(['home']);
          break;
        }

        if(!this.currentProjectId) {
          this.notificationsService.showSuccess('Selecciona un proyecto');

          return;
        }

        if(url === 'documentation2') {
          this.router.navigate(['logged-client', url]);

          return;
        }

        this.router.navigate(['logged-client', url, this.currentProjectId]);
        break;

      default:
        this.router.navigate([url]);
        break;
    }
  }

  private initializeAgent() {
    this.store.select('agent').subscribe((agent) => {
      if (!agent || Object.keys(agent).length === 0) {
        return;
      }

      this.agent = agent;
      this.agent$.next(agent);
    });
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectCurrentRoute, selectRouteParams } from 'src/app/store/router.selectors';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();

  context: string;

  constructor(
    private store: Store<{ context: any }>,
    private router: Router,
  ) { }

  ngOnInit() {
    combineLatest([
      this.store.select('context'),
      this.store.select(selectCurrentRoute)
    ])
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([{ value: context }, { url: [path1, path2] }]: [any, any]) => {
        this.context = context;

        if (!path2 || path1?.path === 'calculator') {
          return;
        }

        const isRegisterAgent = path2?.path === 'agent';
        let suffix = isRegisterAgent ? 'agent' : 'client;'
        
        this.context = `${this.context}-${suffix}`;
        if (path1?.path === 'ppv' || 'automated' || 'configured') {
          this.context = path1?.path
        }

      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

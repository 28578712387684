import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { HasPermissionService } from '@core/services/has-permission.service';

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private hasPermissionService: HasPermissionService
  ) {  }

  @Input() set appHasPermission(permission: string) {
    if (this.hasPermissionService.checkPermission(permission)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}

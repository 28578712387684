/* eslint-disable */
import {
  Budget,
  Lead,
  Action,
  Product,
  Event,
  Activity,
  Person,
  Position,
  Document,
  CubicupStaff,
} from './index';

declare var Object: any;
export interface MessageInterface {
  id?: string;
  createdAt: Date;
  updatedAt: Date;
  modelActive?: any;
  person?: Person;
  cubicupStaff?: CubicupStaff;
  read?: boolean;
  body?: string;
  client?: any;
}

export class Message implements MessageInterface {
  'id': string;
  'createdAt': Date;
  'updatedAt': Date;
  'modelActive': any;
  person?: Person;
  cubicupStaff?: CubicupStaff;
  read?: boolean;
  body?: string;
  client?: any;
  constructor(data?: MessageInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Agent`.
   */
  public static getModelName() {
    return 'Message';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Agent for dynamic purposes.
   **/
  public static factory(data: MessageInterface): Message {
    return new Message(data);
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { PositionInterface } from '@core/sdk';

@Component({
  selector: 'app-select-duplicate-option',
  templateUrl: './select-duplicate-option.component.html',
  styleUrls: ['./select-duplicate-option.component.scss'],
})
export class SelectDuplicateOptionComponent implements OnInit {
  duplicateOptionForm: UntypedFormGroup;
  selected: number;
  options = [
    {
      slug: 'duplicate-to-this',
      option: 'same-project',
    },
    {
      slug: 'duplicate-to-project',
      option: 'other-project',
    },
  ];

  position$: Observable<PositionInterface> = new Observable<PositionInterface>();
  projectSelected = '';

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SelectDuplicateOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.initializeForm();
  }

  private initializeForm() {
    this.duplicateOptionForm = this.fb.group({
      duplicateOption: new UntypedFormControl('', [Validators.required]),
    });

    this.duplicateOptionForm.valueChanges.subscribe(({ duplicateOption }) => {
      this.data.optionSelected = duplicateOption;

      this.data.project = null;
    });

    if (this.data.budgetType === 'template') {
      this.options.shift();

      this.duplicateOptionForm.get('duplicateOption').setValue('other-project');
      this.selected = 0;
    }
  }

  handleProjectClicked(project) {
    this.data.project = project;
  }

  ifChecked(optionIndex, option) {
    this.selected = optionIndex;
    this.duplicateOptionForm.get('duplicateOption').setValue(option.option);
  }
}

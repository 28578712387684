import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-discount-modal',
  templateUrl: './discount-modal.component.html',
  styleUrls: ['./discount-modal.component.scss'],
})
export class DiscountModalComponent implements OnInit {
  discountForm: UntypedFormGroup;
  disableAddButton = false;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DiscountModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.discountForm = this.fb.group({
      title: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl('', []),
      totalPrice: new UntypedFormControl('', [Validators.required]),
    });

    this.discountForm.get('totalPrice').valueChanges.subscribe((newValue) => {
      if (this.data.certificationTotal - newValue < 0) {
        this.disableAddButton = true;

        return;
      }

      this.disableAddButton = false;
    });
  }

  submit() {
    if (this.discountForm.invalid) {
      return;
    }

    this.dialogRef.close({
      formValues: this.discountForm.value,
    });
  }
}

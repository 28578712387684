import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import environment from '@environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';
import { Store } from '@ngrx/store';
import { Client, LeadInterface } from '@core/sdk';
import { SessionStorageService } from './session-storage.service';
import { concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DiscussionService {
  private showDiscussion = false;
  private discussionIsToggled: Subject<any> = new Subject<any>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private store: Store<{
      client: Client;
      projects: LeadInterface[];
      context: any;
    }>,
    private sessionStorage: SessionStorageService,
  ) {}

  getToggleConversation() {
    return this.discussionIsToggled.asObservable();
  }

  private findOrCreateAgentDiscussion(targetType, itemID, conversationID = null) {
    if (conversationID) {
      const httpOptions = {
        headers: new HttpHeaders({
          filter: JSON.stringify({
            where: {
              targetId: itemID,
              targetType,
            },
          }),
        }),
      };

      const url = `${environment.apiBaseUrl}/api/Conversations/${conversationID}/message`;

      return this.httpClient.get<any[]>(url, httpOptions);
    }

    const url = `${environment.apiBaseUrl}/api/Conversations`;

    return this.httpClient.post<any[]>(url, {
      targetId: itemID,
      targetType,
    });
  }

  private findOrCreateAnonymousClientDiscussion(
    targetType,
    itemID,
    conversationID = null,
  ) {
    if (conversationID) {
      const httpOptions = {
        headers: new HttpHeaders({
          filter: JSON.stringify({
            where: {
              targetId: itemID,
              targetType,
            },
          }),
          'anonymous-client-token': this.sessionStorage.get('clientToken'),
        }),
      };

      const url = `${environment.apiBaseUrl}/api/Conversations/${conversationID}/message`;

      return this.httpClient.get<any[]>(url, httpOptions);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'anonymous-client-token': this.sessionStorage.get('clientToken'),
      }),
    };

    const url = `${environment.apiBaseUrl}/api/Conversations`;

    return this.httpClient.post<any[]>(
      url,
      {
        targetId: itemID,
        targetType,
      },
      httpOptions,
    );
  }

  private findOrCreateClientDiscussion(targetType, itemID, conversationID = null) {
    let realClientID = this.localStorageService.get('clientId');

    if (conversationID) {
      const httpOptions = {
        headers: new HttpHeaders({
          filter: JSON.stringify({
            where: {
              targetId: itemID,
              targetType,
            },
            include: {
              relation: 'message',
            },
          }),
          referrer: `client-${realClientID}`,
        }),
      };

      const url = `${environment.apiBaseUrl}/api/Conversations/${conversationID}/message`;

      return this.httpClient.get<any[]>(url, httpOptions);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        referrer: `client-${realClientID}`,
      }),
    };

    const url = `${environment.apiBaseUrl}/api/Conversations`;

    return this.httpClient.post<any[]>(
      url,
      {
        targetId: itemID,
        targetType,
      },
      httpOptions,
    );
  }

  findOrCreateDiscussion(targetType, itemID, conversationID = null) {
    return this.store.select('context').pipe(
      concatMap(({ value: context }) => {
        switch (context) {
          case 'agent':
            return this.findOrCreateAgentDiscussion(
              targetType,
              itemID,
              (conversationID = null),
            );
          case 'client':
            return this.findOrCreateAnonymousClientDiscussion(
              targetType,
              itemID,
              (conversationID = null),
            );
          case 'login-client':
            return this.findOrCreateClientDiscussion(
              targetType,
              itemID,
              (conversationID = null),
            );
          default:
            break;
        }
      }),
    );
  }

  // getConversationMessages(conversationID) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       filter: JSON.stringify({
  //         include: ['person', 'cubicupStaff']
  //       }),
  //     }),
  //   };

  //   const url = `${environment.apiBaseUrl}/api/Conversations/${conversationID}/message`;

  //   return this.httpClient.get<any[]>(url, httpOptions);
  // }

  // sendMessage(conversationID, messageBody, receptorIsStaff = null) {
  //   const url = `${environment.apiBaseUrl}/api/Conversations/${conversationID}/message`;

  //   return this.httpClient.post<any>(url, {
  //     body: messageBody,
  //     personId: this.localStorage.get('user'),
  //     receptorIsStaff,
  //     read: true
  //   });
  // }

  // setMessageAsRead(messageID: string) {
  //   const url = `${environment.apiBaseUrl}/api/Messages/${messageID}`;

  //   return this.httpClient.patch(url, {
  //     read: true
  //   });
  // }

  // addParticipantToConversation(conversationID, personID) {
  //   const url = `${environment.apiBaseUrl}/api/Conversations/${conversationID}/participants/rel/${personID}`;

  //   return this.httpClient.put(url, {});
  // }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BudgetsGuard  {
  

  constructor(private store: Store<{context:any}>, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select('context'),
      take(1),
      map(({ value: context, currentProjectId }) => {
        switch(context){
          case 'agent':
            this.router.navigate(['budgets', currentProjectId]);
            return false;
    
          case 'login-client':
            this.router.navigate(['logged-client','budgets', currentProjectId]);
            return false;
        }
      })
    );
  }
}
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class AppService {

  isFullScreenRoute(route):boolean{
    const fullScreenRoutes = [
      '/login',
      '/register/agent',
      '/register',
      '/register/client',
      '/new-password',
      '/forget-password',
      '/quiz/on-boarding',
      '/quiz/calculator',
      '/client-onboarding',
    ];

    return fullScreenRoutes.includes(route.split('?')[0]);
  }

  doNotShowSideBar(route, context):boolean{
    let hideSidebarRegex = /^(\/on-boarding|\/client|\/landing|\/home-old|\/review-request)/gm;

    if (context === 'agent') {
      hideSidebarRegex = /^(\/on-boarding|\/client|\/review-request)/gm;
    }

    return hideSidebarRegex.test(route);
  }
}
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { S3Service } from '@core/services/s3.service';
import { BehaviorSubject } from 'rxjs';
import { NotificationsService } from '@core/services/notifications.service';
import { saveAs } from 'file-saver-es';

@Component({
  selector: 'app-portfolio-image-preview',
  templateUrl: './portfolio-image-preview.component.html',
  styleUrls: ['./portfolio-image-preview.component.scss'],
})
export class PortfolioImagePreviewComponent implements OnInit {
  safeImage: SafeResourceUrl;
  type: string;
  emptyAvatarPath = 'assets/img/avatar.png';
  changeIcons = false;
  fileType$ = new BehaviorSubject<string>('');
  fileName$ = new BehaviorSubject<string>('');
  fileUrl$ = new BehaviorSubject<string>('');

  @Input() document: any;

  @Output() removeFileEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() openCarouselEmitter: EventEmitter<any> = new EventEmitter<any>();

  image: any;

  constructor(
    private sanitizer: DomSanitizer,
    private s3Service: S3Service,
    private notificationsService: NotificationsService,
  ) {}

  ngOnInit() {
    this.initializeImagePreview();
  }

  /**
   * Initialize the image preview by setting the image property to the first version of the document
   */
  private initializeImagePreview() {
    if (!this.document) {
      throw new Error('No document found.');
    }

    const [file] = this.document.versions;
    this.image = file;
  }

  /**
   * Reads the file and sets the type of the file
   * @param evt - The event object.
   * @returns Nothing.
   */
  handleFileSelect(evt) {
    const { files } = evt.target;

    if (!files) {
      return;
    }

    const [file] = files;

    if (!file) {
      return;
    }

    const fileSize = Math.round(file.size / 1024);

    if (fileSize > 70) {
      this.notificationsService.showError(
        'El tamaño máximo para la subida de imágenes es de 70KB.',
      );

      return;
    }

    const reader = new FileReader();

    this.type = file.type; // TODO:
    reader.onload = this._handleReaderLoaded.bind(this);

    reader.readAsBinaryString(file);
  }

  /**
   * The function takes the binary string from the reader event and converts it to a base64 string.
   * @param readerEvt - The FileReader object that contains the file data.
   */
  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;

    // this.image = 'data:' + this.type + ';base64,' + btoa(binaryString);
    // this.safeImage = this.sanitizer.bypassSecurityTrustResourceUrl(this.image);
  }

  /**
   * Download a file from the browser
   * @param fileBlob - The file blob to be downloaded.
   * @param filename - The name of the file to be downloaded.
   * @returns a promise.
   */
  private downloadFile(fileBlob, filename) {
    const url = window.URL.createObjectURL(new Blob([fileBlob]));

    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    if (isIOS) {
      //window.open(url, "_self");
      saveAs(url, filename);
      return;
    }

    const element = document.createElement('a');

    element.setAttribute('href', url);
    element.setAttribute('download', filename);

    element.style.display = 'none';

    document.body.appendChild(element);

    element.click();

    window.URL.revokeObjectURL(url);
    element.remove();
  }

  /**
   * Get the file url from the S3 service and download it
   * @returns The downloadUrl and the fileName
   */
  getFile() {
    if (!this.document) {
      return;
    }

    const [{ fileName, file: fileID, fileUrl }] = this.document.versions;

    const fileNameSplitted = fileName.split('.');
    const fileExtension = fileNameSplitted.pop();
    const fileNameDownload = `${fileID}.${fileExtension}`;

    this.s3Service
      .getFileUrlOld(fileNameDownload, this.document.id)
      .subscribe((downloadUrl) => this.downloadFile(downloadUrl, fileName));
  }

  /**
   * Open the carousel
   */
  openCarousel() {
    this.openCarouselEmitter.emit();
  }

  /**
   * Delete the image from the database and the file from the server
   */
  deleteImage() {
    this.s3Service
      .removeFileAndDocument(this.image.id, this.document.id)
      .subscribe(() => {
        this.notificationsService.showSuccess('Imagen eliminada');
        this.removeFileEmitter.emit();
      });
  }
}

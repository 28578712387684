import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  forgetForm: UntypedFormGroup;
  sendButtonLabel = 'send';
  sendingForm = false;
  showSuccesView = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.initializeTranslations();
    this.initializeForm();
  }

  /**
   * The function sets the default language to Spanish and then sets the current language to Spanish
   */
  private initializeTranslations() {
    this.translateService.setDefaultLang('es_ES');
    this.translateService.use('es_ES');
  }

  /**
   * It creates a form group with an email field.
   */
  private initializeForm() {
    this.forgetForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          ),
        ],
      ],
    });
  }

  /**
   * Toggle the label of the send button depending on the state of the form
   * @returns Nothing.
   */
  private toggleSendButtonLabel() {
    this.sendingForm = !this.sendingForm;

    if (!this.sendingForm) {
      this.forgetForm.controls.email.enable();

      return;
    }

    this.forgetForm.controls.email.disable();
  }

  /**
   * Send a password reset email to the user
   * @returns Nothing.
   */
  sendForm() {
    if (this.forgetForm.invalid) {
      return;
    }

    this.toggleSendButtonLabel();

    this.authService.setPersonPassword(this.forgetForm.value.email).subscribe(
      () => (this.showSuccesView = true),
      () => this.toggleSendButtonLabel(),
    );
  }
}

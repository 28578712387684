import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import environment from '@environment';

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  constructor(
    private httpClient: HttpClient,
    private localStorage: LocalStorageService,
  ) {}

  addNewNoteToProject(projectID, body) {
    const agent = this.localStorage.get('agentId');
    const url = `${environment.apiBaseUrl}/api/Agents/${agent}/notes`;

    return this.httpClient.post(url, {
      targetId: projectID,
      targetType: 'Lead',
      body,
    });
  }

  addLoginClientNewNoteToProject(projectID, body) {
    const clientID = this.localStorage.get('clientId');
    const url = `${environment.apiBaseUrl}/api/Clients/${clientID}/notes`;

    const httpOptions = {
      headers: new HttpHeaders({
        referrer: `client-${clientID}`,
      }),
    };

    return this.httpClient.post(
      url,
      {
        targetId: projectID,
        targetType: 'Lead',
        body,
      },
      httpOptions,
    );
  }

  editNote(body, noteID) {
    const url = `${environment.apiBaseUrl}/api/Notes/${noteID}`;

    return this.httpClient.patch(url, {
      body,
    });
  }

  removeNote(noteID) {
    const agent = this.localStorage.get('agentId');
    const url = `${environment.apiBaseUrl}/api/Agents/${agent}/notes/${noteID}`;

    return this.httpClient.delete(url);
  }

  removeLoginClienteNote(noteID) {
    const clientID = this.localStorage.get('clientId');
    const url = `${environment.apiBaseUrl}/api/Clients/${clientID}/notes/${noteID}`;

    const httpOptions = {
      headers: new HttpHeaders({
        referrer: `client-${clientID}`,
      }),
    };

    return this.httpClient.delete(url, httpOptions);
  }
}

<div class="header">
  <div class="left">
    <mat-icon>warning_amber</mat-icon>
    <div class="title">
      <span>{{ 'choose-agent-client' | translate }}</span>
      <span class="subtitle">{{
        'choose-agent-client-subtitle' | translate
      }}</span>
    </div>
  </div>
  <button mat-icon-button mat-dialog-close *ngIf="data.showCloseButton">
    <mat-icon svgIcon="24px-close"></mat-icon>
  </button>
</div>

<form [formGroup]="inputForm">
  <div class="custom-input text-sm">
    <p>{{ 'Buscador' | translate }}</p>

    <mat-form-field class="example-full-width">
      <input
        type="text"
        placeholder="Ej: EGM Grupo / egmgrupo@gmail.com / 123456123"
        aria-label="Number"
        matInput
        formControlName="finder"
        data-cy="impersonate-search"
      />
    </mat-form-field>
  </div>
</form>

<mat-accordion class="selector-wrapper agent">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span class="title">{{ 'agents-list' | translate }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-selection-list #agentSelector [multiple]="false" data-cy="agent-selector">
      <mat-list-option *ngFor="let agent of agents" [value]="agent" data-cy="each-agent-option">
        {{ agent.tradeName }}
      </mat-list-option>
    </mat-selection-list>

    <p>
      <span class="label"> {{ 'agent-selected' | translate }}: </span>
      <br />
      <span
        class="result"
        *ngIf="agentSelector.selectedOptions.selected.length > 0"
      >
        {{ agentSelector.selectedOptions.selected[0]?.value.tradeName }}
      </span>
    </p>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion class="selector-wrapper client" data-cy="client-section">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span class="title">{{ 'clients-list' | translate }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-selection-list
      #clientSelector 
      [multiple]="false" 
      data-cy="client-selector"
    >
      <mat-list-option 
        *ngFor="let client of clients" 
        [value]="client" 
        data-cy="each-client-option"
      >
        {{ client.contact.name }}
        <span class="email">({{ client.contact.email }})</span>
      </mat-list-option>
    </mat-selection-list>

    <p>
      <span class="label"> {{ 'client-selected' | translate }}: </span>
      <br />
      <span
        class="result"
        *ngIf="clientSelector.selectedOptions.selected.length > 0"
      >
        {{ clientSelector.selectedOptions.selected[0]?.value.contact.name }}
        ({{ clientSelector.selectedOptions.selected[0]?.value.contact.email }})
      </span>
    </p>
  </mat-expansion-panel>
</mat-accordion>

<div class="buttons">
  <button mat-stroked-button color="primary" mat-dialog-close>
    {{ 'cancel' | translate }}
  </button>

  <button 
    mat-flat-button color="accent" 
    (click)="submit()" 
    data-cy="submit"
  >
    {{ 'accept' | translate }}
  </button>
</div>

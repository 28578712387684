import {
  OnInit,
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { NotificationsCenterService } from '@core/services/notifications-center.service';
import { ResponsiveService } from '@core/services/responsive.service';
import { Router } from '@angular/router';
import environment from '@environment';
import { LeadService } from '@core/services/lead.service';
import { Store } from '@ngrx/store';
import { AgentInterface, ClientInterface, LeadInterface } from '@core/sdk';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input() notification: any;
  @Output()
  notificationClosedEmitter: EventEmitter<string> = new EventEmitter<string>();

  showCloseButton = false;
  viewMode: string;
  rejectedOrAccepted = '';

  notificationTypes = {
    message: {
      icon: '24px-message',
      class: 'message',
    },
    project: {
      icon: 'illustration-homeReady',
      class: 'project',
    },
    activity: {
      icon: '24px-calendar',
      class: 'activity',
    },
    reminder: {
      icon: '24px-calendar',
      class: 'reminder',
    },
    review: {
      icon: '24px-message',
      class: 'message',
    },
  };

  constructor(
    private notificationsCenterService: NotificationsCenterService,
    private responsiveService: ResponsiveService,
    private router: Router,
    private projectService: LeadService,
    private store: Store<{
      agent: AgentInterface;
      client: ClientInterface;
      projects: LeadInterface[];
      context: any;
    }>,
  ) {}

  ngOnInit() {
    this.responsiveService.mode.subscribe(
      (viewMode) => (this.viewMode = viewMode),
    );

    this.checkRejectedOrAcceptedResult();
  }

  private checkRejectedOrAcceptedResult() {
    const { title } = this.notification;

    if (title.includes('accepted') || title.includes('contract')) {
      this.rejectedOrAccepted = 'accepted';
      return;
    }

    if (title.includes('rejected')) {
      this.rejectedOrAccepted = 'rejected';
      return;
    }
  }

  closeNotification() {
    this.notificationsCenterService
      .closeNotification(this.notification.id)
      .subscribe(() => {
        this.notificationClosedEmitter.emit(this.notification.id);
      });
  }

  private markAsClicked() {
    this.notificationsCenterService
      .markNotificationAsClicked(this.notification.id)
      .subscribe(() => (this.notification.clicked = true));
  }

  @HostListener('click') goToLink() {
    const [, link] = this.notification.link.split(environment.frontBaseUrl);
    const [, parsedLink] = link.split('?');

    // token to force an event is fired in every click, even if you are allready in that route
    const tokenizedLink = link.includes('openChat=true') ?
      link.concat('&refreshToken=', new Date().getTime())
      : link;

    this.notificationsCenterService.markNotificationsAsRead([
      this.notification,
    ]);

    if (this.notification.type !== 'activity') {
      this.markAsClicked();
    }

    const currentURL = this.router.url;

    this.notificationsCenterService.toggleNotificationsCenter();

    if (
      (currentURL === parsedLink || currentURL === link) &&
      link.includes('openChat=true')
    ) {
      this.projectService.putOpenCubicupStaffConversation();

      return;
    }

    this.store
      .select('context')
      .pipe(take(1))
      .subscribe(({ value: context }) => {
        if (context === 'login-client') {
          this.router.navigateByUrl(`/logged-client${tokenizedLink}`, {
            replaceUrl: true,
          });

          return;
        }

        this.router.navigateByUrl(tokenizedLink, { replaceUrl: true });
      });
  }
}

<form [formGroup]="duplicateOptionForm" *ngIf="duplicateOptionForm">
  <div class="search-section">
    <mat-form-field
      appearance="outline"
      class="searchField without-error-display"
    >
      <input
        matInput
        type="text"
        [placeholder]="'search-template-to-copy' | translate"
        formControlName="searchText"
        (keydown.enter)="handleSearch()"
      />
      <mat-icon
        matSuffix
        *ngIf="
          duplicateOptionForm.get('searchText').value === '';
          else clearSearchButton
        "
        svgIcon="24px-search"
      ></mat-icon>
      <ng-template #clearSearchButton>
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="search"
          (click)="clearSearch()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
    </mat-form-field>

    <div class="budget-selected">
      <span>{{ 'template-selected' | translate }}:</span>
      <span class="label">{{ budgetSelected }}</span>
    </div>
  </div>
</form>
<ng-container>
  <div
    class="budgets-list-wrapper"
    *ngIf="filteredBudgets$ | async as budgets; else emptyState"
  >
    <ng-container *ngFor="let budget of budgets; index as budgetIndex">
      <div class="budget-option" (click)="selectBudget(budget, budgetIndex)">
        <div class="data">
          <div class="name">
            <mat-icon svgIcon="24px-myInvoices"></mat-icon>
            <ng-template #defaultAvatar>
              <img alt="" />
            </ng-template>
            <span>{{ budget.title }} | {{ budget.total }}€</span>
          </div>
        </div>
        <div class="selector">
          <div
            class="radio-row"
            *ngIf="budgetIndex === selected; else elseButton"
          >
            <mat-icon svgIcon="16px-checkFilled"></mat-icon>
          </div>
          <ng-template #elseButton>
            <div class="radio-row">
              <mat-icon svgIcon="16px-checkEmpty"></mat-icon>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #emptyState> Ningún resultado </ng-template>

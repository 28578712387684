import { Injectable } from '@angular/core';

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';

import { Observable, combineLatest } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';
import { Store } from '@ngrx/store';
import { mergeMap, take } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService,
    private store: Store<{ user: any; context: any }>,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return combineLatest([
      this.store.select('user'),
      this.store.select('context'),
    ]
    ).pipe(
      take(1),
      mergeMap(([user, {value: context}]: [any, any]) => {
        const { id: token, userIsLogged, token: userToken } = user;

        if (userToken) {
          this.localStorageService.set('token', userToken);
        }

        const clientID = this.localStorageService.get('clientId');

        let headers;

        if (token && userIsLogged) {
          if (
            !req.url.includes(
              'https://s3.eu-central-1.amazonaws.com/cubicup.filesystem',
            )
            &&
            !req.url.includes(
              'autodesk',
            )
          ) {
            headers = req.headers.set('Authorization', token);
          }

          if (
            context === 'login-client' &&
            !req.url.includes('developer.api.autodesk.com')
          ) {
            headers = headers
              ? headers.set('referrer', `client-${clientID}`)
              : req.headers.set('referrer', `client-${clientID}`);
          }

          const cloned = req.clone({ headers });

          return next.handle(cloned);
        }

        const tokenInStorage = this.localStorageService.get('token');

        if (!tokenInStorage) {
          if (
            context === 'login-client' &&
            !req.url.includes('developer.api.autodesk.com')
          ) {
            headers = headers
              ? headers.set('referrer', `client-${clientID}`)
              : req.headers.set('referrer', `client-${clientID}`);

            const cloned3 = req.clone({ headers });

            return next.handle(cloned3);
          }

          return next.handle(req);
        }

        if (
          !req.url.includes(
            'https://s3.eu-central-1.amazonaws.com/cubicup.filesystem',
          )
          &&
          !req.url.includes(
            'autodesk',
          )
        ) {
          headers = req.headers.set('Authorization', tokenInStorage);
        }

        if (
          context === 'login-client' &&
          !req.url.includes('developer.api.autodesk.com')
        ) {
          headers = headers
            ? headers.set('referrer', `client-${clientID}`)
            : req.headers.set('referrer', `client-${clientID}`);

          const cloned4 = req.clone({ headers });

          return next.handle(cloned4);
        }

        const cloned5 = req.clone({ headers });

        return next.handle(cloned5);
      }),
    );
  }
}

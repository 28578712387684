export function loadOneSignal(environment) {
  const [head] = document.getElementsByTagName('head');
  const oneSignalScript = document.createElement('script');

  oneSignalScript.innerHTML = `
    var OneSignal = window.OneSignal || [];

    if (!window.location.href.includes('/client')) {
      OneSignal.push(function() {
        OneSignal.init({
          appId: "${environment.oneSignal.appID}",
          notifyButton: {
            enable: true,
            position: 'bottom-left',
            displayPredicate: function() {
              return OneSignal.isPushNotificationsEnabled()
                  .then(function(isPushEnabled) {
                      /* The user is subscribed, so we want to return "false" to hide the notify button */
                      return !isPushEnabled;
                  });
          },
          },
          allowLocalhostAsSecureOrigin: true,
        });
      });
    }
  `;

  head.insertBefore(oneSignalScript, head.firstChild);
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-long-text-modal',
  templateUrl: './create-long-text-modal.component.html',
  styleUrls: ['./create-long-text-modal.component.scss'],
})
export class CreateLongTextModalComponent implements OnInit {
  textForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<CreateLongTextModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.textForm = this.formBuilder.group({
      longText: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(1000),
        ],
      ],
    });
  }
}

<section [ngClass]="{
    templates: mode === 'templates',
    events: mode === 'events',
    project: mode === 'project'
  }">
  <div class="info">
    <ng-container *ngIf="mode === 'project'">
      <p class="title" *ngIf="(context === 'agent' && project?.code);">{{ project?.code }}</p>
      <p class="section">
        {{ claimTextContent?.section | translate }}
        <span *ngIf="project.archived" class="archived"> {{ 'archived' | translate }} </span>
      </p>
      <p class="title">{{ claimTextContent?.title | translate }}</p>
      <p class="claim">{{ claimTextContent?.claim | translate }}</p>
    </ng-container>

    <ng-container *ngIf="mode === 'events'">
      <p class="section">{{ claimTextContent?.section | translate }}</p>
      <p class="title">{{ claimTextContent?.title | translate }}</p>
      <p class="claim">{{ claimTextContent?.claim | translate }}</p>
    </ng-container>

    <ng-container *ngIf="mode === 'templates'">
      <p class="title">{{ claimTextContent?.title | translate }}</p>
      <p class="claim">{{ claimTextContent?.claim | translate }}</p>
    </ng-container>

    <img [src]="project?.cubicupStaff.avatar" alt="" matRipple *ngIf="viewMode === 'handset' && project?.cubicupStaff"
      (click)="callCubicupStaff()" [ngClass]="{ 'new-messages': hasNewMessages }" />
  </div>
</section>
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-banner',
  templateUrl: './client-banner.component.html',
  styleUrls: ['./client-banner.component.scss']
})
export class ClientBannerComponent implements OnInit {
  @Input() fullClientName: string;
  @Input() address: any;

  clientName: string;
  helloLabel: string;

  constructor() { }

  ngOnInit(): void {
    this.clientName = this.parseName(this.fullClientName);
    this.helloLabel = this.setHelloByTime();
  }

  parseName(clientName: string) {
    const parsedName = clientName.split(' ');

    return parsedName[0]; //only the name
  }

  setHelloByTime() {
    const hours = new Date().getHours();

    if ((0 <= hours && hours <= 5) || (21 <= hours)) {
      return 'good-night';
    }

    if (6 <= hours && hours <= 12) {
      return 'good-morning';
    }

    if (13 <= hours && hours <= 20) {
      return 'good-afternoon';
    }

    return 'Hola '
  }
}

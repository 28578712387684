/* eslint-disable */
import { Lead, Action, Budget, ContactInterface } from './index';

declare var Object: any;
export interface ClientInterface {
  contact: ContactInterface;
  address: any;
  id?: string;
  own?: boolean;
  modelActive?: any;
  leads?: Lead[];
  budgets?: Budget[];
  actions?: Action[];
  avatar?: any;
  memoryUse?: any;
  impersonated?: boolean;
  b2b?: boolean;
  ppv?: boolean;
}

export class Client implements ClientInterface {
  'contact': ContactInterface;
  'id': string;
  'own'?: boolean;
  'modelActive': any;
  leads?: Lead[];
  budgets?: Budget[];
  actions?: Action[];
  avatar?: any;
  memoryUse?: any;
  impersonated?: boolean;
  b2b?: boolean;
  ppv?: boolean;
  constructor(data?: ClientInterface) {
    Object.assign(this, data);
  }
  address: any;
  /**
   * The name of the model represented by this $resource,
   * i.e. `Client`.
   */
  public static getModelName() {
    return 'Client';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Client for dynamic purposes.
   **/
  public static factory(data: ClientInterface): Client {
    return new Client(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Client',
      plural: 'Clients',
      path: 'Clients',
      idName: 'id',
      properties: {
        contact: {
          name: 'contact',
          type: 'any',
        },
        id: {
          name: 'id',
          type: 'number',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
      },
      relations: {
        leads: {
          name: 'leads',
          type: 'Lead[]',
          model: 'Lead',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'clientId',
        },
        actions: {
          name: 'actions',
          type: 'Action[]',
          model: 'Action',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'referenceId',
        },
      },
    };
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor() {}

  get(key: string) {
    const data: string = sessionStorage.getItem(key);
    return this.parse(data);
  }

  set(key: string, value: any): void {
    sessionStorage.setItem(
      key,
      typeof value === 'object' ? JSON.stringify(value) : value,
    );
  }

  remove(key: string): void {
    if (sessionStorage[key]) {
      sessionStorage.removeItem(key);
    } else {
      console.log('Trying to remove unexisting key: ', key);
    }
  }

  removeAll() {
    sessionStorage.clear();
  }

  private parse(value: any) {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }
}

import { createAction, props } from '@ngrx/store';

export const ContextAddOrUpdateAction = createAction(
  '[Context Component] Add',
  props<any>(),
);

export const ContextRemoveAction = createAction(
  '[Context Component] Remove',
  props<any>(),
);

export const ContextUpdateProjectAction = createAction(
  '[Context Component] Project Selected',
  props<any>(),
);

export const ContextUpdateHeaderLinksRequestStarted = createAction(
  '[Context Component] Header Link Clicked Started'
);
export const ContextUpdateHeaderLinksRequestSuccess = createAction(
  '[Context Component] Header Link Clicked Success',
  props<{ payload }>(),
);

export const ContextUpdateActionButtonRequestStarted = createAction(
  '[Context Component] Action Button Started',
);
export const ContextUpdateActionButtonRequestSuccess = createAction(
  '[Context Component] Action Button Success',
  props<{ payload }>(),
);

export const ContextUpdateBudgetAction = createAction(
  '[Context Component] Budget Selected',
  props<any>(),
);
export const ContextUpdateClientAction = createAction(
  '[Context Component] Client Selected',
  props<any>(),
);
export const ContextUpdateCertificationAction = createAction(
  '[Context Component] Certification Selected',
  props<{ currentCertificationId }>(),
);

export const ContextUpdateActiveViewSuccess = createAction(
  '[Context Component] Update Context Active View',
  props<{ payload }>(),
)
export const ContextUpdateActiveView = createAction(
  '[Context Component] ActiveView Selected',
  props<any>(),
);

export const ContextUpdateSelectedRoleId = createAction(
  '[Context Component] ActiveView Selected',
  props<{ selectedRoleId }>(),
);

export interface InvoiceInterface {
  id: string;
  contactName: string;
  amount?: number;
  status?: string;
  subtotal?: number;
  tax?: number;
  paymentsTotal?: number;
  paymentsPending?: number;
  date?: number;
  products?: InvoiceProduct;
  data?: string;
  docId?: string;
  docNumber?: string;
  total?: number;

}
export class Invoice implements InvoiceInterface {
  id!: string;
  contactName: string;
  desc?: string;
  amount?: number;
  subtotal?: number;
  tax?: number;
  paymentsTotal?: number;
  paymentsPending?: number;
  status?: string;
  date?: number;
  products?: InvoiceProduct;
  data?: string;
  docNumber?: string;
  total?: number;

  constructor(data?: InvoiceInterface) {
    Object.assign(this, data);
  }

  public static getModelName() {
    return 'Invoice';
  }
}

export interface InvoiceProductInterface {
  name?: string;
  desc?: string;

}

export class InvoiceProduct implements InvoiceProductInterface {
  name?: string;
  desc?: string;
}


export type ProjectData = {
	contact: {
		name: string,
		email: string,
		phoneNumber: number,
		idNumber: string
	},
	address: {
		address1: string,
		city: string,
		zipCode: number,
		region: string,
		country: string
	},
	tax: string,
	totalBudget: string,
	shortCode: string
}
import { Budget, Chapter } from '@core/sdk';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

export enum budgetEditorActionsTypes {
  GetBudgetRequestStarted = '[Budget Api] GET_BUDGET_REQUEST_STARTED',
  GetBudgetRequestSuccess = '[Budget Api] GET_BUDGET_REQUEST_SUCCESS',
  AddBudget = '[Budget Editor] ADD_BUDGET',
  UpdateBudgetRequestStarted = '[Budget Api] UPDATE_BUDGET_REQUEST_STARTED',
  UpdateBudgetRequestSuccess = '[Budget Api] UPDATE_BUDGET_REQUEST_SUCCESS',
  UpdateBudget = '[Budget Editor] UPDATE_BUDGET',
  DeleteBudgetChapter = '[Budget Editor] DELETE_BUDGET_CHAPTER',
  ClearEntities = '[Budget Editor] CLEAR_BUDGET_STORE',
  UpdateBudgetChapterList = '[Budget Editor] UPDATE_BUDGET_CHAPTER_LIST',
  UpdateBudgetChapters = '[Budget Editor] UPDATE_BUDGET_CHAPTERS',
}

export const getBudgetRequestStarted = createAction(
  budgetEditorActionsTypes.GetBudgetRequestStarted,
  props<{ payload: string }>(),
);

export const deleteBudgetChapter = createAction(
  budgetEditorActionsTypes.DeleteBudgetChapter,
  props<{ payload: string }>(),
);

export const getBudgetRequestSuccess = createAction(
  budgetEditorActionsTypes.GetBudgetRequestSuccess,
  props<{ payload: Budget }>(),
);

export const addBudget = createAction(
  budgetEditorActionsTypes.AddBudget,
  props<{ payload: Budget }>(),
);

export const updateBudgetRequestStarted = createAction(
  budgetEditorActionsTypes.UpdateBudgetRequestStarted,
  props<{ payload: Partial<Budget> }>(),
);

export const updateBudgetRequestSuccess = createAction(
  budgetEditorActionsTypes.UpdateBudgetRequestSuccess,
  props<{ payload: Budget }>(),
);

export const updateBudget = createAction(
  budgetEditorActionsTypes.UpdateBudget,
  props<{ payload: Update<Budget> }>(),
);

export const clearEntities = createAction(
  budgetEditorActionsTypes.ClearEntities,
);

export const updateBudgetChapterList = createAction(
  budgetEditorActionsTypes.UpdateBudgetChapterList,
  props<{ chapterId: string; budgetId: string; chapterOrder: number }>(),
);

export const updateBudgetChapters = createAction(
  budgetEditorActionsTypes.UpdateBudgetChapters,
  props<{ payload: Partial<Chapter>[] }>(),
);

/* eslint-disable */
import { Injectable } from '@angular/core';
import { User } from '../../models/User';
import { Action } from '../../models/Action';
import { Budget } from '../../models/Budget';
import { Product } from '../../models/Product';
import { BudgetItem } from '../../models/BudgetItem';
import { Chapter } from '../../models/Chapter';
import { Address } from '../../models/Address';
import { Lead } from '../../models/Lead';
import { Agent } from '../../models/Agent';
import { Person } from '../../models/Person';
import { Test } from '../../models/Test';
import { Contact } from '../../models/Contact';
import { Event } from '../../models/Event';
import { Activity } from '../../models/Activity';
import { BriefingItem } from '../../models/BriefingItem';
import { Reason } from '../../models/Reason';
import { Series } from '../../models/Series';
import { Position } from '../../models/Position';
import { PaymentMethod } from '../../models/PaymentMethod';
import { CubicupStaff } from '../../models/CubicupStaff';
import { Client } from '../../models/Client';
import { S3File } from '../../models/S3File';
import { Document } from '../../models/Document';

export interface Models {
  [name: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class SDKModels {
  private models: Models = {
    User: User,
    Action: Action,
    Budget: Budget,
    Product: Product,
    BudgetItem: BudgetItem,
    Chapter: Chapter,
    Address: Address,
    Lead: Lead,
    Agent: Agent,
    Person: Person,
    Test: Test,
    Contact: Contact,
    Event: Event,
    Activity: Activity,
    BriefingItem: BriefingItem,
    Reason: Reason,
    Series: Series,
    Position: Position,
    PaymentMethod: PaymentMethod,
    CubicupStaff: CubicupStaff,
    Client: Client,
    S3File: S3File,
    Document: Document,
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Agent, TrackingSummary } from '@core/sdk';
import { CertificationService } from '@core/services/certification.service';
import { TrackingService } from '@core/services/tracking.service';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { CertificationFacadeService } from 'src/app/store/certification/certification-facade.service';
import { ContextUpdateCertificationAction } from 'src/app/store/context/context.actions';
import {
  setTrackingsToCompleteFromItemArrRequestSuccess,
  updateTrackingRequestSuccess,
} from 'src/app/store/tracking/tracking.actions';

export interface TrackingState extends TrackingSummary {
  /** Project/Lead ID */
  projectId: string;
}

@Injectable()
export class TrackingComponentStore extends ComponentStore<TrackingState> {
  constructor(
    private trackingService: TrackingService,
    private certificationService: CertificationService,
    private certificationFacadeService: CertificationFacadeService,
    private actions$: Actions,
    private router: Router,
    private store: Store<{ agent: Agent , context:any }>,
  ) {
    // set defaults
    super({
      projectId: '',
      emittedCertification: {
        totalCertificationAccepted: 0,
        totalBudget: 0,
      },
      paymentPendingInvoices: {
        totalCertificationPending: 0,
        projectTotal: 0,
      },
      nextInvoice: {
        totalNextCertificationPrice: 0,
        totalRemainingBalance: 0,
      },
      remainingBalance: {
        totalRemainingBalance: 0,
        projectTotal: 0,
      },
      punchListData: {
        isPunchListTime: false,
        isPenultimateCertificationTime: false,
        isLastCertification: false,
        penultimateCertificationId: '',
        lastCertificationIsAccepted: false,
        metadata: {
          aheadSignalProgress: 0,
          aheadSignalProgressPercentage: 0,
          totalCertificatePrice: 0,
          totalCertificatePricePercentage: 0,
        },
        penultimateCertificationIsAccepted: false,
        punchListSubtractPercentage: 0,
        allTrackingsPunchListAreValidated: false
        //showTrackingStates: false,
      },
      certificationsMilestones: [],
      canCertificate: false,
      totalAverageAdvancePercentage: 0,
      projectTitle: '',
      leadClientTitle: '',
      resumePaymentMethod: {
        price: 0,
        percent: 0,
      },
    });
  }

  // *********** Updaters *********** //
  readonly addTrackingSummary = this.updater(
    (state, trackingSummary: TrackingSummary) => ({
      ...state,
      ...trackingSummary,
    }),
  );

  readonly setProjectId = this.updater((state, projectId: string) => ({
    ...state,
    projectId,
  }));

  // *********** Effects *********** //
  readonly getTrackingSummary = this.effect(
    (projectId$: Observable<string>) =>
      projectId$.pipe(
        switchMap((id) =>
          this.trackingService
            .getTrackingSummary(id)
            .pipe(
              tap((projectSummary) => this.addTrackingSummary(projectSummary)),
            ),
        ),
      ),
    );

  readonly getTrackingSummaryOnce = this.effect(
    (projectId$: Observable<string>) =>
      projectId$.pipe(
        take(1),
        switchMap((id) =>
          this.trackingService
            .getTrackingSummary(id)
            .pipe(
              tap((projectSummary) => this.addTrackingSummary(projectSummary)),
            ),
        ),
      ),
    );

  readonly getTrackingSummaryOnStoreChanges = this.effect(() =>
    this.actions$.pipe(
      ofType(
        setTrackingsToCompleteFromItemArrRequestSuccess,
        updateTrackingRequestSuccess,
      ),
      switchMap(() => this.selectProjectId()),
      switchMap((id) =>
        this.trackingService
          .getTrackingSummary(id)
          .pipe(
            tap((projectSummary) => this.addTrackingSummary(projectSummary)),
          ),
      ),
    ),
  );

  readonly createCertification = this.effect((projectId$: Observable<string>) =>
    projectId$.pipe(
      switchMap((projectID) =>
        this.certificationService.getCertificationCount(projectID).pipe(
          switchMap(({ count }) =>
            this.certificationService.createCertification({
              projectID,
              order: count,
            }),
          ),
          tap((certification) => 
            this.certificationFacadeService.addLocalCertification(
              certification,
            ),
          ),
          tap((certification) => 
            this.store.dispatch(ContextUpdateCertificationAction({ currentCertificationId: certification.id }))
          ), 
          tap((certification) =>
            this.router.navigate(['certification', certification.id]),
          ),
        ),
      ),
    ),
  );

  // *********** Selectors *********** //
  selectProjectId() {
    return this.select((state) => state.projectId);
  }

  selectEmittedCertification() {
    return this.select((state) => state.emittedCertification);
  }

  selectPaymentPendingInvoices() {
    return this.select((state) => state.paymentPendingInvoices);
  }

  selectNextInvoice() {
    return this.select((state) => state.nextInvoice);
  }

  selectRemainingBalance() {
    return this.select((state) => state.remainingBalance);
  }

  selectCertificationsMilestones() {
    return this.select((state) => state.certificationsMilestones);
  }

  selectCanCertificate() {
    return this.select((state) => state.canCertificate);
  }

  selectTotalAverageAdvancePercentage() {
    return this.select((state) => state.totalAverageAdvancePercentage);
  }

  selectProjectTitle() {
    return this.select((state) => state.projectTitle);
  }

  selectClientLeadTitle() {
    return this.select((state) => state.leadClientTitle);
  }

  selectResumePaymentMethod() {
    return this.select((state) => state.resumePaymentMethod);
  }

  isPunchListTime() {
    // return of(true);
    return this.select((state) => state.punchListData.isPunchListTime);
  }

  selectPunchListData() {
    return this.select((state) => state.punchListData);
  }

  selectAgentId() {
    return this.store.select((store) => store.agent.id);
  }
}

import { Directive, OnInit, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appInputAccountNumberFormat]',
})
export class InputAccountNumberFormatDirective implements OnInit {
  constructor(private control: NgControl) {}

  ngOnInit() {
    this.format();
  }

  @HostListener('keypress') onInputChange() {
    this.format();
  }

  format() {
    const val = this.control.value;

    if (!val) {
      return;
    }

    const raw = val.replace(/\W/g, '').replace(/[^0-9]/g, '');
    const formatted =
      'ES' +
      raw.slice(0, 2) +
      ' ' +
      raw.slice(2, 6) +
      ' ' +
      raw.slice(6, 10) +
      ' ' +
      raw.slice(10, 12) +
      ' ' +
      raw.slice(12, 21);

    this.control.valueAccessor.writeValue(formatted);
  }
}

/* eslint-disable */
import { Chapter } from './index';

declare var Object: any;
export interface DiscountItemInterface {
  title?: string;
  description?: string;
  totalPrice?: number;
  totalPercentage?: number;
  id?: string;
  certificationId?: string;
  certificationID?: string;
  modelActive?: any;
  createdAt?: string;
  order?: number;
  updatedAt?: Date;
}

export class DiscountItem implements DiscountItemInterface {
  title?: string;
  description?: string;
  totalPrice?: number;
  totalPercentage?: number;
  id?: string;
  certificationId?: string;
  certificationID?: string;
  modelActive?: any;
  createdAt?: string;
  order?: number;
  updatedAt?: Date;
  constructor(data?: DiscountItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DiscountItem`.
   */
  public static getModelName() {
    return 'DiscountItem';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of DiscountItem for dynamic purposes.
   **/
  public static factory(data: DiscountItemInterface): DiscountItem {
    return new DiscountItem(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'DiscountItem',
      plural: 'DiscountItems',
      path: 'DiscountItems',
      idName: 'id',
      properties: {
        quantity: {
          name: 'quantity',
          type: 'number',
        },
        product: {
          name: 'product',
          type: 'any',
        },
        taxPercent: {
          name: 'taxPercent',
          type: 'number',
          default: 2100,
        },
        id: {
          name: 'id',
          type: 'number',
        },
        modelActive: {
          name: 'modelActive',
          type: 'any',
          default: <any>null,
        },
        chapterId: {
          name: 'chapterId',
          type: 'number',
        },
      },
      relations: {
        chapter: {
          name: 'chapter',
          type: 'Chapter',
          model: 'Chapter',
          relationType: 'belongsTo',
          keyFrom: 'chapterId',
          keyTo: 'id',
        },
      },
    };
  }
}

import { Injectable } from '@angular/core';
import { Certification, CertificationView } from '@core/sdk';
import { EntityAdapter } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';
import {
  selectAllCertification,
  selectCertificationById,
  selectCertificationDiscountsById,
  selectCertificationStateById,
} from './certification.selectors';
import {
  acceptCertificationRequestStarted,
  addCertification,
  clearEntities,
  createDiscountRequestStarted,
  getCertificationRequestStarted,
  getCertificationDiscountsRequestStarted,
  rejectCertificationRequestStarted,
  removeDiscountRequestStarted,
  sendCertificationRequestStarted,
  updateCertificationRequestStarted,
  createLastCertificationRequestStarted,
} from './certification.actions';

@Injectable()
export class CertificationFacadeService {
  public certifications$: Observable<Certification[]> = this.store
    .select(selectAllCertification)
    .pipe(filter<Certification[]>(Boolean));

  constructor(private store: Store<EntityAdapter<Certification>>) {}

  addCertification(payload: string) {
    this.store.dispatch(getCertificationRequestStarted({ payload }));
  }

  addLocalCertification(payload: Certification) {
    this.store.dispatch(addCertification({ payload }));
  }

  updateCertification(payload: Partial<Certification>) {
    this.store.dispatch(updateCertificationRequestStarted({ payload }));
  }

  getCertification(certificationId: string): Observable<CertificationView> {
    return this.store
      .select(selectCertificationById(certificationId))
      .pipe(filter<Certification>(Boolean));
  }

  getCertificationState(certificationId: string) {
    return this.store.select(selectCertificationStateById(certificationId));
  }

  clearCertifications() {
    this.store.dispatch(clearEntities());
  }

  sendCertification(payload) {
    this.store.dispatch(sendCertificationRequestStarted({ payload }));
  }

  acceptCertification(payload) {
    this.store.dispatch(acceptCertificationRequestStarted({ payload }));
  }

  rejectCertification(payload) {
    this.store.dispatch(rejectCertificationRequestStarted({ payload }));
  }

  getCertificationDiscounts(certificationId: string): Observable<any> {
    return this.store
      .select(selectCertificationDiscountsById(certificationId))
      .pipe(filter<any>(Boolean));
  }

  addDiscount(payload) {
    this.store.dispatch(createDiscountRequestStarted({ payload }));
  }

  removeDiscount(payload: { certificationID: string; discountID: string }) {
    this.store.dispatch(removeDiscountRequestStarted(payload));
  }

  createLastCertification(payload: string) {
    this.store.dispatch(createLastCertificationRequestStarted({ payload }));
  }
}


  <div *ngIf="agent$ | async as agent" class="bottomnav">
    <div class="spacing"></div>
    <mat-tab-group class="mat-tab-mobile" mat-stretch-tabs>
      <ng-container >
        <mat-tab label="{{ 'events' | translate }}" [routerLink]="['events']" routerLinkActive #rla="routerLinkActive" [ngClass]="{'current-route-active': rla.isActive}">
          <ng-template mat-tab-label>
            <div (click)="goTo('events')">
              <mat-icon svgIcon="16px-calendar"></mat-icon>
              <span>
                {{ 'events' | translate }}
              </span>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="{{ 'projects' | translate }}" [routerLink]="['projects']" routerLinkActive #rla2="routerLinkActive" [ngClass]="{'current-route-active': rla2.isActive}">
          <ng-template mat-tab-label>
            <div (click)="goTo('projects')" >
              <mat-icon svgIcon="16px-projects"></mat-icon>
              <span>
                {{
                (context === 'login-client'
                ? 'project'
                : 'projects')
                | translate }}
              </span>
          </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="{{ 'templates' | translate }}" [routerLink]="['templates/products']" routerLinkActive #rla4="routerLinkActive" [ngClass]="{'current-route-active': rla4.isActive}">
          <ng-template mat-tab-label>
            <div (click)="goTo('templates')">
              <mat-icon>view_list</mat-icon>
              <span>
                {{ 'templates' | translate }}
              </span>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="{{ 'templates-quiz' | translate }}" [routerLink]="['templates-quiz']" routerLinkActive #rla5="routerLinkActive" *ngIf="superAdmin" [ngClass]="{'current-route-active': rla5.isActive}">
          <ng-template mat-tab-label>
            <div (click)="goTo('templates-quiz')">
              <mat-icon>view_list</mat-icon>
              <span>
                {{ 'Templates-Quiz'}}
              </span>
          </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="{{ 'company-profile' | translate }}" [routerLink]="['on-boarding']" routerLinkActive #rla3="routerLinkActive" *ngIf="!agent.cubicupPartnerCode" [ngClass]="{'current-route-active': rla3.isActive}">
          <ng-template mat-tab-label>
            <div (click)="goTo('on-boarding')">
              <mat-icon svgIcon="24px-settings"></mat-icon>
              <span>
                {{ 'company-profile' | translate }}
              </span>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab [routerLink]="['tracking-list']" routerLinkActive #rla6="routerLinkActive" *ngIf="superAdmin" [ngClass]="{'current-route-active': rla6.isActive}">
          <ng-template mat-tab-label>
            <div (click)="goTo('tracking-list')">
              <mat-icon svgIcon="24px-arrow"></mat-icon>
              <span>
                {{ 'Tracking' }}
              </span>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="{{ 'my-landing' | translate }}" [routerLink]="['landing', agent.id]" routerLinkActive #rla6="routerLinkActive" *ngIf="agent.cubicupPartnerCode">
          <ng-template mat-tab-label>
            <div (click)="goTo('landing')">
              <mat-icon>settings</mat-icon>
              <span>
                {{ 'my-landing' | translate }}
              </span>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="{{ 'company-profile' | translate }}" [routerLink]="['profile']" routerLinkActive #rla6="routerLinkActive" [ngClass]="{'current-route-active': rla6.isActive}" *ngIf="agent.cubicupPartnerCode">
          <ng-template mat-tab-label>
            <div (click)="goTo('profile')">
              <mat-icon svgIcon="24px-profile"></mat-icon>
              <span>
                {{ 'company-profile' | translate }}
              </span>
            </div>
          </ng-template>
        </mat-tab>
      </ng-container>

      <ng-container *ngIf="loginClient$ | async as client">

          <mat-tab *ngIf="ppv" data-cy="client-estimation-page" >
              <ng-template mat-tab-label>
                <div (click)="goTo('estimation')">
                  <mat-icon>view_list</mat-icon>
                  <span>{{ 'estimations' | translate }}</span>
                </div>
              </ng-template>
          </mat-tab>

          <mat-tab *ngIf="ppv" data-cy="client-demo-page" >
            <ng-template mat-tab-label>
              <div (click)="goTo('demo')">
                <mat-icon>notes</mat-icon>
                <span>{{ 'how-it-works' | translate }}</span>
              </div>
            </ng-template>
          </mat-tab>

        </ng-container>
    </mat-tab-group>
  </div>

  <div *ngIf="loginClient$ | async as client">
    <div class="fixed inset-x-0 bottom-0 bg-white shadow-lg z-10">
      <div class="grid grid-cols-4 gap-2 justify-between items-center pl-1 pr-3 pt-2 pb-2">
        <!-- Tab Inicio -->
        <div  [ngClass]="{'active-tab': activeView === 'home'}" class="flex flex-col items-center text-gray-500" (click)="goTo('home')">
          <mat-icon aria-hidden="false" aria-label="home" fontIcon="dashboard" data-cy="cy-bottomnav-home"></mat-icon>
          <a class="text-[0.70rem] important">{{'home' | translate }}</a>
        </div>

        <!-- Tab Proyectos -->
        <div  [ngClass]="{'active-tab': activeView === 'budgets'}" class="flex flex-col items-center text-gray-500" (click)="goTo('budgets')">
          <mat-icon aria-hidden="false" aria-label="home" fontIcon="receipt" data-cy="cy-bottomnav-budgets"></mat-icon>
          <a class="text-[0.70rem] important">{{'budgets' | translate }}</a>
        </div>

        <!-- Tab Plantillas -->
        <div  [ngClass]="{'active-tab': activeView === 'project'}" class="flex flex-col items-center text-gray-500" (click)="goTo('project')">
          <mat-icon data-cy="cy-bottomnav-3d"> business</mat-icon>
          <a class="text-[0.70rem] important">{{'3D' | translate }}</a>
        </div>

        <!-- Tab Ajustes -->
        <div [ngClass]="{'active-tab': activeView === 'documentation2'}" class="flex flex-col items-center text-gray-500" (click)="goTo('documentation2')">
          <mat-icon  data-cy="cy-bottomnav-documentation">work</mat-icon>
          <a class="text-[0.70rem] important">{{'documentation' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
<button mat-icon-button>
  <mat-icon
    [matBadge]="notificationBadget"
    matBadgeColor="warn"
    matBadgeSize="small"
  >
    notifications_none
  </mat-icon>
</button>
<button mat-icon-button>
  <mat-icon
    [matBadge]="calendarBadget"
    matBadgeColor="warn"
    matBadgeSize="small"
  >
    calendar_today
  </mat-icon>
</button>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LeadInterface } from '@core/sdk';
import { ResponsiveService } from '@core/services/responsive.service';
import * as _ from 'lodash-es';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit {
  viewMode: string;
  
  @Input() projects: LeadInterface[];
  @Input() agentName: string;
  @Output() reload = new EventEmitter<void>();
  @Output() projectClicked = new EventEmitter<any>();

  projectsOrder: ProjectsOrders = {
    field: 'updatedAt',
    order: 'desc',
  };

  constructor(
    private responsiveService: ResponsiveService,
  ) {}

  ngOnInit() {
    this.responsiveService.mode.subscribe((viewMode) => {
      this.viewMode = viewMode;
    });
  }

  /**
   * Sort the projects by a field
   * @param field - The field to sort by.
   */
  sortProjectsByField(field) {
  this.projects = _.orderBy(
    this.projects,
    [field],
    [this.projectsOrder.order === 'desc' ? 'desc' : 'asc']
  );

  this.projectsOrder.order = this.isOrderDescent() ? 'asc' : 'desc';
  this.projectsOrder.field = field;
}


  /**
   * Sort the projects by the given field
   * @param field - The field to sort by.
   * @returns Nothing.
   */
  sortProjectsByDate(field) {
    if (this.projectsOrder.field === field) {
      if (this.projectsOrder.order === 'desc') {
        this.projects.sort((o1, o2) => {
          const date1 = new Date(o1[field]).getTime();
          const date2 = new Date(o2[field]).getTime();

          return date1 - date2;
        });

        this.projectsOrder.order = this.isOrderDescent() ? 'asc' : 'desc';
        this.projectsOrder.field = field;
        return;
      }

      this.projects.sort((o1, o2) => {
        const date1 = new Date(o1[field]).getTime();
        const date2 = new Date(o2[field]).getTime();

        return date2 - date1;
      });

      this.projectsOrder.order = this.isOrderDescent() ? 'asc' : 'desc';
      this.projectsOrder.field = field;
      return;
    }

    this.projects.sort((o1, o2) => {
      const date1 = new Date(o1[field]).getTime();
      const date2 = new Date(o2[field]).getTime();

      this.projectsOrder.field = field;
      return date2 - date1;
    });
  }

  /**
   * Returns true if the projects are ordered in descending order, false otherwise
   * @returns The order of the projects.
   */
  isOrderDescent() {
    return this.projectsOrder.order === 'desc';
  }

  /**
   * Emit the project that was clicked on
   * @param project - The project that was clicked.
   */
  handleProjectClicked(project) {
    this.projectClicked.emit(project);
  }

  /**
   * Emit a reload event
   */
  emitReloadEmitter() {
    this.reload.emit();
  }
}

interface ProjectsOrders {
  field: string;
  order: 'asc' | 'desc';
}

<div
  *ngIf="viewMode === 'handset'"
  class="flex justify-between gap-2 w-full bg-white mb-4 p-2"
  (click)="goToProject(lead)"
>   
  
    <div class="h-full flex flex-col items-start">
      <button mat-icon-button>
        <mat-icon
        svgIcon="24px:notifications"
        [matBadge]="notReadNotifications.length"
        [matBadgeHidden]="notReadNotifications.length === 0"
        matBadgeColor="warn"
        [matTooltip]="'project-notifications-tooltip' | translate"
        [matTooltipDisabled]="notReadNotifications.length === 0"
        matTooltipPosition="above"
        ></mat-icon>
      </button>
    </div>
    <div class="w-full">
      <div class="flex justify-between [&>*]:w-1/2 mb-2">
        <span class="font-bold text-xl">{{ lead.contact.name }}</span>
        <span class="w-auto text-right" *ngIf="viewMode === 'handset'">{{ lead.address.address1 }}</span>
      </div>
      
      <span class="flex flex-col justify-center">
        <ng-container [ngSwitch]="lead.archived">
          <div *ngSwitchCase="true" class="lost">
            <span>{{ 'lost-singular' | translate }}</span>
          </div>
          <div *ngSwitchCase="false">
            <form
            class="flex items-stretch mb-1"
            [formGroup]="projectStateForm"
            *ngIf="projectStateForm && lead.own; else projectState"
            >
            <mat-form-field
            appearance="outline"
            class="without-error-display"
            [ngClass]="{ archived: lead.archived }"
            >
            <mat-select
            formControlName="projectState"
            data-cy="project-state"
            (click)="stopPropagation($event)"
            >
            <ng-container *ngIf="lead.own">
              <mat-option
              *ngFor="
              let projectStateKey of getProjectStateKeys(
                publicProjectStates
                )
                "
                [value]="projectStateKey"
                (click)="stopPropagation($event)"
                >
                {{
                  (projectStateKey === 'negotiation'
                  ? 'filter-negotiation'
                  : projectStateKey
                  ) | translate
                }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </form>
      
      <ng-template #projectState>
        <div class="rounded-sm py-1 font-semibold bg-[#9ff2e5] text-[#00a883] flex items-center justify-center min-w-28">
          <span class="text-center">{{ lead.state | translate }}</span>
        </div>
      </ng-template>
      <div class="flex items-center content-center flex-col mb-2 p-1">
        <span *ngIf="lead?.code">{{ lead?.code }} </span>
        <div *ngIf="lead?.code && lead?.shortCode" class="divider max-w-20 h-0 mt-1 mb-1"></div>
        <span *ngIf="lead?.shortCode">{{ lead?.shortCode }}</span>
      </div>
    </div>
  </ng-container>
  </span>


  <div class="flex justify-between w-auto pr-2">
    <span>{{ lead.updatedAt | date }}</span>
    
    <div class="text-[0.875rem] font-medium">
      <span *ngIf="lead.client?.b2b">B{{ lead?.invoices || lead?.tracking || lead.client?.ppv ? "·" : ""}}</span>
      <span *ngIf="lead.client?.ppv">P{{ lead?.invoices || lead?.tracking ? "·" : ""}}</span>
      <span *ngIf="lead?.tracking">T{{ lead?.invoices ? "·" : ""}}</span>
      <span *ngIf="lead?.invoices">F</span>
    </div>
</div>

</div>
  <div class="h-full flex flex-col items-start m-1">
    <button
      *appHasPermission="'lead.update'"
      mat-icon-button
      [matMenuTriggerFor]="menu"
      (click)="stopPropagation($event); isPersonAssignedToTeam()"
      data-cy="open-project-menu"
    >
      <mat-icon svgIcon="24px:options"></mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="goToProject(lead)" data-cy="menu-go-project">
        {{ 'view-project' | translate }}
      </button>

      <ng-container *ngIf="isCubicupAgent">
        <button mat-menu-item (click)="addUrn()" data-cy="input-urn">
          {{ 'input-urn' | translate }}
        </button>
      </ng-container>
      <ng-container>
        <button mat-menu-item (click)="setB2b()"  *ngIf="lead.client?.b2b">
          {{ lead.client?.b2b ? 'b2b-desactivated' : 'b2b-activated' | translate }}
        </button>
      </ng-container>
      <ng-container>
        <button mat-menu-item (click)="setTracking()" *ngIf="isPosibleTracking && !lead?.tracking">
          {{ 'traking-activated'| translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="isCubicupAgent">
        <button mat-menu-item (click)="openModal()" data-cy="input-urn">
          {{ 'input-magicplan' | translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="lead.own">
        <button mat-menu-item (click)="editProject()" data-cy="menu-edit-project">
          {{ 'edit-project' | translate }}
        </button>
        <button mat-menu-item (click)="deleteProject()" data-cy="menu-delete-project">
          {{ 'delete-project' | translate }}
        </button>
      </ng-container>
    </mat-menu>
  </div>

</div>

<div
  *ngIf="viewMode !== 'handset'"
  class="card-custom items-center"
  (click)="goToProject(lead)"
>
  
<div class="flex items-left content-center flex-column ml-4 p-1">
  <span *ngIf="lead?.code">{{ lead?.code }} </span>
  <div *ngIf="lead?.code && lead?.shortCode" class="divider max-w-20 h-0 mt-1 mb-1"></div>
  <span *ngIf="lead?.shortCode">{{ lead?.shortCode }}</span>
</div>

  <div class="principal name items-center mr-1">
    <mat-icon
      svgIcon="24px:logoCircle"
      *ngIf="!lead.own; else defaultAvatar"
    ></mat-icon>
    <ng-template #defaultAvatar>
      <img
        *ngIf="agentAvatar; else initialLetters"
        [src]="agentAvatar"
        alt=""
      />
      <ng-template #initialLetters>
        <app-initial-letters [name]="agentName"></app-initial-letters>
      </ng-template>
    </ng-template>
    <div class="name-score">
      <span>{{ lead.contact.name }}</span>
      <div class="stars" *ngIf="context !== 'login-client'">
        <ng-container *ngFor="let star of stars; let feelingStarIndex = index">
          <mat-icon
            svgIcon="{{
              feelingStarIndex >= lead.agentFeeling || !lead.agentFeeling
                ? '12px-emptyStar'
                : '12px-star'
            }}"
          ></mat-icon>
        </ng-container>
      </div>
    </div>
  </div>


  <span class="address">{{ lead.address.address1 }}</span>

  <span class="state">
    <ng-container [ngSwitch]="lead.archived">
      <div *ngSwitchCase="true" class="lost">
        <span>{{ 'lost-singular' | translate }}</span>
      </div>
      <div *ngSwitchCase="false">
        <form
          [formGroup]="projectStateForm"
          *ngIf="projectStateForm && lead.own; else projectState"
        >
          <mat-form-field
            appearance="outline"
            class="without-error-display"
            [ngClass]="{ archived: lead.archived }"
          >
            <mat-select
              formControlName="projectState"
              data-cy="project-state"
              (click)="stopPropagation($event)"
            >
              <ng-container *ngIf="lead.own">
                <mat-option
                  *ngFor="
                    let projectStateKey of getProjectStateKeys(
                      publicProjectStates
                    )
                  "
                  [value]="projectStateKey"
                  (click)="stopPropagation($event)"
                >
                  {{
                    (projectStateKey === 'negotiation'
                      ? 'filter-negotiation'
                      : projectStateKey
                    ) | translate
                  }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </form>

        <ng-template #projectState>
          <div class="project-state">
            <span>{{ lead.state | translate }}</span>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </span>

  <span class="date">{{ lead.updatedAt | date }}</span>
  
    <div class="roles">
      <span *ngIf="lead.client?.b2b">B{{ lead?.invoices || lead?.tracking || lead.client?.ppv ? "·" : ""}}</span>
      <span *ngIf="lead.client?.ppv">P{{ lead?.invoices || lead?.tracking ? "·" : ""}}</span>
      <span *ngIf="lead?.tracking">T{{ lead?.invoices ? "·" : ""}}</span>
      <span *ngIf="lead?.invoices">F</span>
    </div>
  
  <div class="menu flex-row" *ngIf="!lead.archived">
    <mat-icon
      class="new-icon"
      [matTooltip]="'new' | translate"
      matTooltipPosition="above"
      *ngIf="lead.isNew"
    >
      fiber_manual_record
    </mat-icon>

    <button mat-icon-button>
      <mat-icon
        svgIcon="24px:notifications"
        [matBadge]="notReadNotifications.length"
        [matBadgeHidden]="notReadNotifications.length === 0"
        matBadgeColor="warn"
        [matTooltip]="'project-notifications-tooltip' | translate"
        [matTooltipDisabled]="notReadNotifications.length === 0"
        matTooltipPosition="above"
      >
      </mat-icon>
    </button>

    <button
      *appHasPermission="'lead.update'"
      mat-icon-button
      [matMenuTriggerFor]="menu"
      (click)="stopPropagation($event); isPersonAssignedToTeam()"
      data-cy="open-project-menu"
    >
      <mat-icon svgIcon="24px:options"></mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="goToProject(lead)" data-cy="menu-go-project">
        {{ 'view-project' | translate }}
      </button>

      <ng-container *ngIf="isCubicupAgent">
        <button mat-menu-item (click)="addUrn()" data-cy="input-urn">
          {{ 'input-urn' | translate }}
        </button>
      </ng-container>
      <ng-container *appHasPermission="'lead.update'">
        <button mat-menu-item (click)="handleRejectedProject()">
          {{ 'mark-lost' | translate }}
        </button>
      </ng-container>
      <ng-container>
        <button *ngIf="isCubicupAgent" mat-menu-item (click)="isAssignedToTeam ? removeMemberFromTeam(lead.teamId) : assignPersonToTeam(lead.teamId)" data-cy="assign-self-to-team">
          {{ isAssignedToTeam ? ('remove-self-from-team' | translate) : ('assign-self-to-team' | translate) }}
        </button>
      </ng-container>
        <ng-container >
          <button mat-menu-item (click)="setB2b()"  *ngIf="lead.client?.b2b">
            {{ lead.client?.b2b ? ('b2b-desactivated' | translate) : ('b2b-activated' | translate) }}
          </button>
      </ng-container>
      <ng-container >
        <button mat-menu-item (click)="setTracking()" *ngIf="isPosibleTracking && !lead?.tracking">
          {{ 'traking-activated'| translate }}
        </button>
      </ng-container>
      <ng-container *appHasPermission="'lead.activate-billing'">
        <button mat-menu-item (click)="setInvoices()" *ngIf="isPosibleBilling">
          {{ 'billing-activated'| translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="isCubicupAgent">
        <button mat-menu-item (click)="openModal()" data-cy="input-urn">
          {{ 'input-magicplan' | translate }}
        </button>
      </ng-container>

      <ng-container *ngIf="lead.own">
        <button mat-menu-item (click)="editProject()" data-cy="menu-edit-project">
          {{ 'edit-project' | translate }}
        </button>
        <button mat-menu-item (click)="deleteProject()" data-cy="menu-delete-project">
          {{ 'delete-project' | translate }}
        </button>
      </ng-container>
    </mat-menu>
  </div>
</div>

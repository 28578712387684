<ng-container *ngIf="!showSuccesView; else successView">
  <p class="title">{{ 'forget-password-subtitle' | translate }}</p>

  <form [formGroup]="forgetForm">
    <div class="custom-input text-sm">
      <p>{{ 'email' | translate }} ({{ 'required' | translate }})</p>

      <mat-form-field appearance="outline" color="primary">
        <input
          matInput
          type="email"
          formControlName="email"
          data-cy="email"
          autocomplete="email"
        />
        <mat-icon matSuffix svgIcon="24px-mailClosed"></mat-icon>
        <mat-error
          class="email-required"
          *ngIf="forgetForm.get('email').hasError('required')"
          [innerHTML]="'field-required' | translate"
          data-cy="email-required"
        >
        </mat-error>
        <mat-error
          class="email-pattern"
          *ngIf="
            forgetForm.get('email').hasError('pattern') &&
            forgetForm.get('email').value.length > 5
          "
          [innerHTML]="'email-field' | translate"
          data-cy="email-pattern"
        >
        </mat-error>
      </mat-form-field>
    </div>

    <button
      type="submit"
      mat-flat-button
      color="primary"
      (click)="sendForm()"
      [disabled]="sendingForm"
    >
      {{ (sendingForm ? 'sending' : 'send') | translate }}
    </button>
  </form>

  <a routerLink="/login" class="text-xs primary-link forget-password">
    <mat-icon>chevron_left</mat-icon>
    {{ 'return-to-login' | translate }}
  </a>
</ng-container>

<ng-template #successView>
  <p class="title success">
    {{ 'check-inbox' | translate }}
  </p>

  <p class="subtitle">
    {{ 'cubicup-send-email' | translate }}
  </p>

  <a routerLink="/login" class="text-xs primary-link forget-password">
    <mat-icon>chevron_left</mat-icon>
    {{ 'return-to-login' | translate }}
  </a>
</ng-template>

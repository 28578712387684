import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { Lead, ActionInterface, Reason, UserInterface, LeadInterface } from "@core/sdk";
import { ActionService } from "@core/services/action.service";
import { ReasonService } from "@core/services/reason.service";
import { Update } from "@ngrx/entity";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { Subject, takeUntil } from "rxjs";
import { ProjectUpdateAction } from "src/app/store/actions/project.actions";

@Component({
  selector: 'cub-annex-to-tracking',
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule],
  templateUrl: './annex-to-tracking-modal.component.html',
  styleUrls: ['.//annex-to-tracking-modal.component.scss']
})

export class AnnexToTrackingModalComponent implements OnInit, OnDestroy {
  changeDate: boolean = false;
  newEndDate: string;
  projectEndDate: DateTime;
  user: UserInterface;
  @Output() addAnnexToTrackingEmitter: EventEmitter<void> = new EventEmitter();
  @Output() closeModalEmitter: EventEmitter<void> = new EventEmitter();
  @Input() project: LeadInterface;
  unsuscriber$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<{ user: UserInterface }>, 
    private actionService: ActionService, 
    private reasonService: ReasonService
  ) {}

  ngOnInit(): void {
    if (this.project ) {
      this.initNewDate();
    }
  }

  ngOnDestroy(): void {
    this.unsuscriber$.next();
    this.unsuscriber$.complete();
  }
  onCheckboxChange() {
    this.changeDate = !this.changeDate;
  }

  dateChanged(event: any) {
    this.newEndDate = event.target.value;
  }

  submit() {
    if (this.newEndDate && this.changeDate) {
      this.store.select('user').
      pipe(
        takeUntil(this.unsuscriber$)
      )
      .subscribe((user) => {
        this.user = user;

        const update: Update<Lead> = {
          id: this.project.id,
          changes: {
            endDate: this.newEndDate,
          },
        };

        const newAction: Partial<ActionInterface> = {
          action: 'endDateModified',
          referenceId: this.project.id,
          referenceType: 'Lead',
          authorType: 'Person',
          authorId: this.user.userId,
          agentId: this.user.agentId,
          data: {
            reason: 'annex-to-budget',
            clientInfo: {
              name: this.project.contact.name,
              email: this.project.contact.email
            },
            previousEndDate: this.projectEndDate,
            newDate: this.newEndDate,
          },
          notificable: false
        };

        this.actionService.generateNewAction(newAction).subscribe();

        this.store.dispatch(ProjectUpdateAction({ update }));
      });
    }
    this.addAnnexToTrackingEmitter.emit();
  }

  initNewDate(): void {
      this.projectEndDate = DateTime.fromISO(this.project.endDate);
  }
  closeModal(){
    this.closeModalEmitter.emit();
    return;
  }

}
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CarouselService {
  private carouselIsToggled: BehaviorSubject<any> = new BehaviorSubject<any>({
    toggle: false,
  });

  constructor() {}

  getToggleCarousel() {
    return this.carouselIsToggled.asObservable();
  }

  toggleCarousel(data = null) {
    const { toggle } = this.carouselIsToggled.getValue();

    if (!data) {
      this.carouselIsToggled.next({
        toggle: !toggle,
      });

      return;
    }

    this.carouselIsToggled.next({
      toggle: !toggle,
      images: data.images,
      imageSelected: data.imageSelected,
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterAgentComponent } from './register-agent/register-agent.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { NewPasswordComponent } from './new-password/new-password.component';
import { AuthenticationComponent } from './authentication.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { RegisterClientComponent } from './register-client/register-client.component';
import { AuthenticationRoutingModule } from './authentication-routing.module';

@NgModule({
  declarations: [
    RegisterAgentComponent,
    RegisterClientComponent,
    LoginComponent,
    NewPasswordComponent,
    ForgetPasswordComponent,
    AuthenticationComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDividerModule,
    AuthenticationRoutingModule,
  ],
})
export class AuthenticationModule {}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, timer, BehaviorSubject, forkJoin, of } from 'rxjs';
import environment from '@environment';
import { LocalStorageService } from './local-storage.service';
import { exhaustMap, tap, takeUntil, map, concatMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Agent } from 'http';

@Injectable({
  providedIn: 'root',
})
export class NotificationsCenterService {
  private notifications: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    [],
  );
  private notificationsCenterIsToggled: BehaviorSubject<any> = new BehaviorSubject<any>(
    { toggle: false },
  );
  private unsubscribe$: Subject<any> = new Subject<any>();

  private enableNotifications: boolean = environment.subscriptions.notifications.default;

  constructor(
    private httpClient: HttpClient,
    private localStorage: LocalStorageService,
    private store: Store<{ agent: Agent; context: any; user: any }>,
  ) {}

  getNewNotifications() {
    return this.notifications.asObservable();
  }

  private removeOneSignalUserId() {
    this.store.select('user').subscribe((user) => {
      const url = `${environment.apiBaseUrl}/api/People/${user.userId}`;

      this.httpClient
        .patch<any>(url, {
          oneSignalID: null,
        })
        .subscribe();
    });
  }

  private saveOneSignalUserID(oneSignalID) {
    this.localStorage.set('oneSignalUserID', oneSignalID);

    this.store.select('user').subscribe((user) => {
      const url = `${environment.apiBaseUrl}/api/People/${user.userId}`;

      this.httpClient
        .patch<any>(url, {
          oneSignalUserId: oneSignalID,
        })
        .subscribe();
    });
  }

  private getOneSignalUserId() {
    OneSignal.getUserId(this.saveOneSignalUserID.bind(this));
  }

  private getOneSignalEvents(isSubscribed) {
    if (!isSubscribed) {
      this.localStorage.remove('oneSignalUserID');
      this.removeOneSignalUserId();

      return;
    }

    OneSignal.push(this.getOneSignalUserId.bind(this));
  }

  private startOneSignalUserIDSubscription() {
    try {
      OneSignal.on('subscriptionChange', this.getOneSignalEvents.bind(this));
    } catch (error) {}
  }

  private loadNotifications() {
    this.store.select('context').subscribe(({ value: context }) => {
      if(context === 'agent'){
        this.enableNotifications = environment.subscriptions.notifications.agentEnabled;
      }
    });

    const filterHeader = {
      where: {
        closed: false,
      },
      order: 'createdAt DESC',
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filterHeader),
      }),
    };

    if (this.enableNotifications) {
      timer(0, environment.subscriptions.notifications.frequency)
        .pipe(
          takeUntil(this.unsubscribe$),
          exhaustMap(() => {
            const notificationsQueueID = this.localStorage.get(
              'notificationsQueueID',
            );

            if (!notificationsQueueID) {
              return of([]);
            }

            const url = `${environment.apiBaseUrl}/api/NotificationsQueues/${notificationsQueueID}/notifications`;
            return this.httpClient.get<any[]>(url, httpOptions);
          }),
        )
        .subscribe(
          (notifications) => {
            this.notifications.next(notifications);
          },
          () => {},
        );
    }
  }

  startUserNotificationsSubscription() {
    this.startOneSignalUserIDSubscription();

    this.loadNotifications();
  }

  getToggleNotificationsCenter() {
    return this.notificationsCenterIsToggled.asObservable();
  }

  toggleNotificationsCenter(notifications = null) {
    const { toggle } = this.notificationsCenterIsToggled.getValue();

    this.notificationsCenterIsToggled.next({
      toggle: !toggle,
      notifications,
    });
  }

  closeAgentNotification(notificationID) {
    const url = `${environment.apiBaseUrl}/api/Notifications/${notificationID}`;

    return this.httpClient
      .patch<any>(url, {
        closed: true,
      })
      .pipe(tap(() => this.loadNotifications()));
  }

  closeLoginClientNotification(notificationID) {
    const url = `${environment.apiBaseUrl}/api/Notifications/${notificationID}`;

    const httpOptions = {
      headers: new HttpHeaders({
        referrer: `client-${this.localStorage.get('clientId')}`,
      }),
    };

    return this.httpClient
      .patch<any>(
        url,
        {
          closed: true,
        },
        httpOptions,
      )
      .pipe(tap(() => this.loadNotifications()));
  }

  closeNotification(notificationID) {
    return this.store.select('context').pipe(
      concatMap(({ value: context }) => {
        switch (context) {
          case 'agent':
            return this.closeAgentNotification(notificationID);
          case 'login-client':
            return this.closeLoginClientNotification(notificationID);
          default:
            break;
        }
      }),
    );
  }

  markAgentNotificationAsClicked(notificationID) {
    const url = `${environment.apiBaseUrl}/api/Notifications/${notificationID}`;

    return this.httpClient
      .patch<any>(url, {
        clicked: true,
      })
      .pipe(tap(() => this.loadNotifications()));
  }

  markLoginClientNotificationAsClicked(notificationID) {
    const url = `${environment.apiBaseUrl}/api/Notifications/${notificationID}`;
    const httpOptions = {
      headers: new HttpHeaders({
        referrer: `client-${this.localStorage.get('clientId')}`,
      }),
    };

    return this.httpClient
      .patch<any>(
        url,
        {
          clicked: true,
        },
        httpOptions,
      )
      .pipe(tap(() => this.loadNotifications()));
  }

  markNotificationAsClicked(notificationID) {
    return this.store.select('context').pipe(
      concatMap(({ value: context }) => {
        switch (context) {
          case 'agent':
            return this.markAgentNotificationAsClicked(notificationID);
          case 'login-client':
            return this.markLoginClientNotificationAsClicked(notificationID);
          default:
            break;
        }
      }),
    );
  }

  markAgentNotificationsAsRead(notifications) {
    const calls = notifications.map((notification) => {
      const url = `${environment.apiBaseUrl}/api/Notifications/${notification.id}`;
      // this.mixpanelService.eventEmitter(`notification-read`, {});
      // Generate a lot Mixpanel MTUs --> €€€€€€

      return this.httpClient.patch(url, {
        read: true,
      });
    });

    forkJoin(calls).subscribe(() => this.loadNotifications());
  }

  markLoginClientNotificationsAsRead(notifications) {
    const calls = notifications.map((notification) => {
      const url = `${environment.apiBaseUrl}/api/Notifications/${notification.id}`;
      const httpOptions = {
        headers: new HttpHeaders({
          referrer: `client-${this.localStorage.get('clientId')}`,
        }),
      };

      // this.mixpanelService.eventEmitter(`notification-read`, {});
      // Generate a lot Mixpanel MTUs --> €€€€€€

      return this.httpClient.patch(
        url,
        {
          read: true,
        },
        httpOptions,
      );
    });

    forkJoin(calls).subscribe(() => this.loadNotifications());
  }

  markNotificationsAsRead(notifications) {
    if (!notifications || notifications.length === 0) {
      return;
    }

    this.store.select('context').subscribe(({ value: context }) => {
      switch (context) {
        case 'agent':
          this.markAgentNotificationsAsRead(notifications);
          break;
        case 'login-client':
          this.markLoginClientNotificationsAsRead(notifications);
        default:
          break;
      }
    });
  }

  createNotificationsQueue(personID) {
    const url = `${environment.apiBaseUrl}/api/People/${personID}/notificationsQueue`;

    this.httpClient
      .post<any>(url, {})
      .subscribe((notificationsQueue) =>
        this.localStorage.set('notificationsQueueID', notificationsQueue.id),
      );
  }

  getNotificationsByModelId(modelID) {
    return this.notifications.pipe(
      map((notifications) => {
        return notifications.filter(
          (notification) => notification.targetId === modelID,
        );
      }),
    );
  }
}

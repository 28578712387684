import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as Sentry from "@sentry/browser";
import { ErrorLoggedAction, ErrorOccurredAction, ErrorSendingToSentryAction } from './error-handler.actions';
import { Action, select, Store } from '@ngrx/store';

@Injectable()
export class ErrorHandlerEffects {
  public logError$ = this.logError();
  constructor(
    private actions$: Actions,
    private store: Store<{ context: any;}>
  ) {}

  private logError(): Observable<Action> {
     return createEffect(() =>
      this.actions$.pipe(
        ofType(ErrorOccurredAction),
        withLatestFrom(this.store.pipe(select(state => state))),
        map(([errorInfo, currentState]) => {
          Sentry.withScope((scope) => {
            scope.setExtras({
              currentState,
              errorInfo,
            });
            Sentry.captureException(new Error('StoreError'), {
              tags: {
                ngrx_error: 'true',
              },
            });
          });

          return ErrorLoggedAction();
        })
      ), {dispatch: false}
    );
  }
}
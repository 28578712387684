import { ProgressBarModule } from './../pages/tracking/progress-bar/progress-bar.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkyModule, LinkyPipe } from 'ngx-linky';

// Material imports
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';

import { MarkdownModule } from 'ngx-markdown';
import { GalleryModule } from  'ng-gallery';
import { LightboxModule } from  'ng-gallery/lightbox';
import { NgxMasonryModule } from 'ngx-masonry';

// Shared module import
import { SharedTranslateModule } from './modules/translate.module';

// Components imports
import { HeaderComponent } from './header/header.component';
import { HeaderNotificationsComponent } from './header/header-notifications/header-notifications.component';
import { HeaderPersonComponent } from './header/header-person/header-person.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { StripeInputComponent } from './stripe-input/stripe-input.component';
import { MiniPersonDataComponent } from './mini-person-data/mini-person-data.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { CapitalizeFirstPipe } from './pipes/capitalize-first.pipe';
import { MaterialModule } from './modules/material.module';
import { FileDropComponent } from './file-drop/file-drop.component';
import { FileDropDirective } from './directive/file-drop.directive';
import { InputPhoneFormatDirective } from './directives/input-phone-format.directive';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { ChipListInputComponent } from './chip-list-input/chip-list-input.component';
import { BannerClaimComponent } from './banner-claim/banner-claim.component';
import { MomentDatePipe } from './pipes/date.pipe';
import { StateLogPipe } from './pipes/state-log.pipe';
import { ProjectSummaryComponent } from './project-summary/project-summary.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InputAccountNumberFormatDirective } from './directives/input-account-number-format.directive';
import { DocumentComponent } from './document/document.component';
import { RouterModule } from '@angular/router';
import { MapLocationDirective } from './directives/map-location.directive';
import { OpportunityComponent } from '../pages/project/opportunity/opportunity.component';
import { MapZoneDefinitionDirective } from './directives/map-zone-definition.directive';
import { BudgetSentClientDialogComponent } from './dialogs/budget-sent-client-dialog/budget-sent-client-dialog.component';
import { LoaderComponent } from './loader/loader.component';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { ConversationComponent } from './conversation/conversation.component';
import { MessageComponent } from './conversation/message/message.component';
import { InitialLettersComponent } from './initial-letters/initial-letters.component';
import { NotificationsCenterComponent } from './notification-center/notifications-center.component';
import { NotificationComponent } from './notification-center/notification/notification.component';
import { BudgetTableComponent } from '../pages/project/budget-table/budget-table.component';
import { CleanPhoneNumberPipe } from './pipes/clean-phone-number.pipe';
import { RemindersCenterComponent } from './reminders-center/reminders-center.component';
import { ReminderComponent } from './reminders-center/reminder/reminder.component';
import { ProjectListComponent } from '../pages/project-grid/project-list/project-list.component';
import { ProjectRowComponent } from '../pages/project-grid/project-list/project-row/project-row.component';
import { ProjectSelectorComponent } from '../pages/budget/payment-summary/select-duplicate-option/project-selector/project-selector.component';
import { EditableFieldComponent } from './editable-field/editable-field.component';
import { ViewModeDirective } from './editable-field/view-mode.directive';
import { EditModeDirective } from './editable-field/edit-mode.directive';
import { EditableOnEnterDirective } from './editable-field/editable-on-enter.directive';
import { BudgetTemplateDialogComponent } from './dialogs/budget-template-dialog/budget-template-dialog.component';
import { BudgetCreateDialogComponent } from './dialogs/budget-create-dialog/budget-create-dialog.component';
import { BudgetTemplateSelectorComponent } from './dialogs/budget-create-dialog/budget-template-selector/budget-template-selector.component';
import { SelectDuplicateOptionComponent } from '../pages/budget/payment-summary/select-duplicate-option/select-duplicate-option.component';
import { DecisionModalComponent } from './dialogs/decision-modal/decision-modal.component';
import { ObfuscatePipe } from './pipes/obfuscate.pipe';
import { CarouselComponent } from './carousel/carousel.component';
import { HelpComponent } from './sidenav/help/help.component';
import { HelpModalComponent } from './dialogs/help-modal/help-modal.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { PortfolioImagePreviewComponent } from '../pages/agent-onboarding/steps/portfolio-step/portfolio-image-preview/portfolio-image-preview.component';
import { PortfolioImageGalleryComponent } from '../pages/agent-onboarding/steps/portfolio-step/portfolio-image-gallery/portfolio-image-gallery.component';
import { NotesCenterComponent } from './notes-center/notes-center.component';
import { NoteComponent } from './notes-center/note/note.component';
import { NotesModalComponent } from './notes-center/notes-modal/notes-modal.component';
import { RemoveNoteModalComponent } from './notes-center/note/remove-note-modal/remove-note-modal.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NotesComponent } from './notes-center/notes/notes.component';
import { CreateLongTextModalComponent } from './dialogs/create-long-text-dialog/create-long-text-modal.component';
import { AskForReviewDialogComponent } from './dialogs/ask-for-review-dialog/ask-for-review-dialog.component';
import { ReviewRequestComponent } from '../pages/review-request/review-request.component';
import { CubicupStaffComponent } from './cubicup-staff/cubicup-staff.component';
import { BudgetHelpComponent } from '../pages/budget-edition/budget-help/budget-help.component';
import { RequestVisitComponent } from './request-visit/request-visit.component';
import { ProjectDocumentsComponent } from './project-documents/project-documents.component';
import { InputModalComponent } from './dialogs/input-modal/input-modal.component';
import { NoticeModalComponent } from './dialogs/notice-modal/notice-modal.component';
import { AgentsListModalComponent } from './dialogs/agents-list-modal/agents-list-modal.component';
import { LogPipe } from './pipes/log-pipe.pipe';
import { AllowTabDirective } from './editable-field/allow-tab.directive';
import { ReadMoreModule } from './read-more/read-more.module';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { PreviewHeaderComponent } from '../pages/budget/preview/preview-header/preview-header.component';
import { PaymentTermsComponent } from './payment-terms/payment-terms.component';
import { CustomTermsComponent } from '../pages/budget-edition/custom-terms/custom-terms.component';
import { DiscountModalComponent } from './dialogs/discount-modal/discount-modal.component';
import { MaterialExtensionModule } from './modules/material-extension.module';
import { EnterTheViewportNotifierDirective } from './directives/inside-viewport.directive';
import { TimelineListComponent } from '../pages/tracking/timeline-list/timeline-list.component';
import { ObfuscationPipe } from './directives/string-obfuscation.directive';
import { ForgeComponent } from '../pages/project/forge/forge.component';
import { MyDecimalsPipe } from './my-decimals-pipe/my-decimals-pipe.pipe';
import { OnboardingWelcomeModal } from './dialogs/onboarding/welcome/welcome.component';
import { InfoAreaComponent } from './info-area/info-area.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import {MatGridListModule} from '@angular/material/grid-list';
import { CdkTreeModule } from '@angular/cdk/tree';
import { BottomnavComponent } from './bottomnav/bottomnav.component';
import { HasPermissionDirective } from './directive/has-permision.directive';
import { RoleService } from '@core/services/role.service';
import { GoBackComponent } from './go-back/go-back.component';
import { SelectActionComponent } from './select-action/select-action.component';
import { BudgetsStoreModule } from '../store/budget/budgets-store.module';
import { HeaderLinkComponent } from '../components/standalone/header-link.component';
import { TrackingComponentStore } from '../pages/tracking/tracking.store';
import { CertificationFacadeService } from '../store/certification/certification-facade.service';
import { ClientBannerComponent } from './@mobile/client-banner/client-banner.component';
import { EventsListComponent } from './@mobile/events-list/events-list.component';
import { VisitActivityComponent } from './visit-activity/visit-activity.component';
import { EasyCalendarComponent } from './easy-calendar/easy-calendar.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { MediaGalleryComponent } from "../components/standalone/media-gallery/media-gallery.component";
import { AnnexToTrackingModalComponent } from '../components/standalone/modals/annex-to-tracking-modal/annex-to-tracking-modal.component';
@NgModule({
  declarations: [
    HeaderComponent,
    HeaderNotificationsComponent,
    HeaderPersonComponent,
    SidenavComponent,
    StripeInputComponent,
    MiniPersonDataComponent,
    BannerClaimComponent,
    ProjectSummaryComponent,
    OpportunityComponent,
    ForgeComponent,
    BudgetTableComponent,
    FileSizePipe,
    CapitalizeFirstPipe,
    MomentDatePipe,
    StateLogPipe,
    ImagePreviewComponent,
    ChipListInputComponent,
    DocumentComponent,
    ConversationComponent,
    MessageComponent,
    NotificationsCenterComponent,
    NotificationComponent,
    ReminderComponent,
    RemindersCenterComponent,
    PhoneNumberPipe,
    CleanPhoneNumberPipe,
    InputPhoneFormatDirective,
    EnterTheViewportNotifierDirective,
    InputAccountNumberFormatDirective,
    FileDropComponent,
    FileDropDirective,
    MapLocationDirective,
    MapZoneDefinitionDirective,
    BudgetSentClientDialogComponent,
    LoaderComponent,
    DebounceClickDirective,
    InitialLettersComponent,
    ProjectListComponent,
    ProjectRowComponent,
    ProjectSelectorComponent,
    EditableFieldComponent,
    ViewModeDirective,
    EditModeDirective,
    EditableOnEnterDirective,
    HasPermissionDirective,
    BudgetTemplateDialogComponent,
    BudgetCreateDialogComponent,
    BudgetTemplateSelectorComponent,
    SelectDuplicateOptionComponent,
    DecisionModalComponent,
    CreateLongTextModalComponent,
    ObfuscatePipe,
    CarouselComponent,
    HelpModalComponent,
    SafeUrlPipe,
    PortfolioImagePreviewComponent,
    PortfolioImageGalleryComponent,
    NotesCenterComponent,
    NoteComponent,
    NotesModalComponent,
    RemoveNoteModalComponent,
    NotesComponent,
    AskForReviewDialogComponent,
    ReviewRequestComponent,
    CubicupStaffComponent,
    BudgetHelpComponent,
    HelpComponent,
    RequestVisitComponent,
    ProjectDocumentsComponent,
    InputModalComponent,
    NoticeModalComponent,
    AgentsListModalComponent,
    LogPipe,
    AllowTabDirective,
    PreviewHeaderComponent,
    PaymentTermsComponent,
    CustomTermsComponent,
    DiscountModalComponent,
    TimelineListComponent,
    ObfuscationPipe,
    MyDecimalsPipe,
    OnboardingWelcomeModal,
    InfoAreaComponent,
    BottomnavComponent,
    GoBackComponent,
    SelectActionComponent,
    ClientBannerComponent,
    EventsListComponent,
    VisitActivityComponent,
    EasyCalendarComponent,
    OrderByPipe
  ],
  imports: [
    CommonModule,
    HeaderLinkComponent, //Standalone Component
    SharedTranslateModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatBadgeModule,
    MatSidenavModule,
    MatExpansionModule,
    MatListModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    AngularEditorModule,
    ReadMoreModule,
    MatBottomSheetModule,
    MaterialExtensionModule,
    MarkdownModule,
    GalleryModule,
    CdkTreeModule,
    LightboxModule,
    BudgetsStoreModule,
    MediaGalleryComponent,
    AnnexToTrackingModalComponent
],
  providers: [PhoneNumberPipe, LinkyPipe, ObfuscationPipe, RoleService, TrackingComponentStore, CertificationFacadeService],
  exports: [
    MaterialModule,
    HeaderComponent,
    SidenavComponent,
    SharedTranslateModule,
    StripeInputComponent,
    MiniPersonDataComponent,
    BannerClaimComponent,
    ProjectSummaryComponent,
    OpportunityComponent,
    ForgeComponent,
    BudgetTableComponent,
    FileSizePipe,
    MomentDatePipe,
    StateLogPipe,
    ImagePreviewComponent,
    ChipListInputComponent,
    DocumentComponent,
    PhoneNumberPipe,
    CleanPhoneNumberPipe,
    FileDropComponent,
    ConversationComponent,
    MessageComponent,
    NotificationsCenterComponent,
    NotificationComponent,
    ReminderComponent,
    RemindersCenterComponent,
    InputPhoneFormatDirective,
    EnterTheViewportNotifierDirective,
    InputAccountNumberFormatDirective,
    MapLocationDirective,
    MapZoneDefinitionDirective,
    DebounceClickDirective,
    LoaderComponent,
    CapitalizeFirstPipe,
    InitialLettersComponent,
    ProjectListComponent,
    ProjectRowComponent,
    ProjectSelectorComponent,
    EditableFieldComponent,
    ViewModeDirective,
    EditModeDirective,
    HasPermissionDirective,
    EditableOnEnterDirective,
    DecisionModalComponent,
    CreateLongTextModalComponent,
    ObfuscatePipe,
    SafeUrlPipe,
    CarouselComponent,
    PortfolioImagePreviewComponent,
    PortfolioImageGalleryComponent,
    NotesCenterComponent,
    NoteComponent,
    NotesComponent,
    NotesModalComponent,
    RemoveNoteModalComponent,
    AskForReviewDialogComponent,
    ReviewRequestComponent,
    CubicupStaffComponent,
    BudgetHelpComponent,
    HelpComponent,
    RequestVisitComponent,
    ProjectDocumentsComponent,
    InputModalComponent,
    NoticeModalComponent,
    AgentsListModalComponent,
    LogPipe,
    AllowTabDirective,
    ReadMoreModule,
    PreviewHeaderComponent,
    PaymentTermsComponent,
    CustomTermsComponent,
    DiscountModalComponent,
    MaterialExtensionModule,
    TimelineListComponent,
    ObfuscationPipe,
    MyDecimalsPipe,
    InfoAreaComponent,
    BottomnavComponent,
    GoBackComponent,
    SelectActionComponent,
    ClientBannerComponent,
    VisitActivityComponent,
    EventsListComponent,
    EasyCalendarComponent,
    // NgxMasonryModule,
    GalleryModule,
    OrderByPipe
  ],
})
export class SharedModule {}

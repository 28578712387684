import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash-es';
import { saveAs } from 'file-saver-es';


@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  // animations: [
  //   trigger("carouselAnimation", [
  //     transition("void => *", [useAnimation(fadeIn, {params: { time: '500ms' }} )]),
  //     transition("* => void", [useAnimation(fadeOut, {params: { time: '500ms' }})]),
  //   ])
  // ]
})
export class CarouselComponent implements OnInit {
  @Input() slides: any[];
  @Input() imageSelected: any;

  currentSlide = 0;

  constructor() {}

  ngOnInit() {
    if (this.imageSelected) {
      this.currentSlide = this.slides.findIndex(
        (slide) => slide.src === this.imageSelected.src,
      );
    }
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
  }

  downloadImg(event) {
    event.stopPropagation();

    const imageToDownload = this.slides[this.currentSlide];

    saveAs(imageToDownload.src, imageToDownload.name);
  }
}


<section class="reminders-center" data-cy="reminders-center">
  <div class="header">
    <div class="left">
      <mat-icon svgIcon="24px-calendar"></mat-icon>
      <span>{{ 'reminders-center' | translate }}</span>
    </div>

    <button
      mat-icon-button
      (click)="toggleRemindersCenter()"
      data-cy="close-reminder"
    >
      <mat-icon svgIcon="24px-close"></mat-icon>
    </button>
  </div>

  <div class="fixed-reminders-list">
    <span>{{ 'fixed-reminders' | translate }}</span>
    <ng-container *ngFor="let reminder of fixedReminders">
      <app-reminder
        [reminder]="reminder"
        (reminderClosedEmitter)="handlerReminderClosed($event)"
        [ngClass]="{ read: reminder.read }"
        matRipple
      ></app-reminder>
    </ng-container>
  </div>

  <div class="reminders-list">
    <ng-container *ngFor="let reminder of normalReminders">
      <app-reminder
        [reminder]="reminder"
        (reminderClosedEmitter)="handlerReminderClosed($event)"
        [ngClass]="{ read: reminder.read }"
        matRipple
      ></app-reminder>
    </ng-container>
  </div>
</section>

/* eslint-disable */

declare var Object: any;
export interface BriefingItemInterface {
  type: string;
  name: string;
  description?: string;
  value: string;
}

export class BriefingItem implements BriefingItemInterface {
  'type': string;
  'name': string;
  'description': string;
  'value': string;
  constructor(data?: BriefingItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `BriefingItem`.
   */
  public static getModelName() {
    return 'BriefingItem';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of BriefingItem for dynamic purposes.
   **/
  public static factory(data: BriefingItemInterface): BriefingItem {
    return new BriefingItem(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'BriefingItem',
      plural: 'BriefingItems',
      path: 'BriefingItems',
      idName: 'id',
      properties: {
        type: {
          name: 'type',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        value: {
          name: 'value',
          type: 'string',
        },
      },
      relations: {},
    };
  }
}

import { Component, OnInit, HostBinding } from '@angular/core';
import { LoaderService } from '@core/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @HostBinding('style.display') display = 'none';

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.loaderService.active$.subscribe((active) =>
      active ? (this.display = 'flex') : (this.display = 'none'),
    );
  }
}

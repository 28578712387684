import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import environment from '../../../environments/environment';
import { Action, Reason } from '@core/sdk';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private httpClient: HttpClient) {}

  getAgentEventById(eventID: string, agentID: string): Observable<any> {
    return this.httpClient.get(
      `${environment.apiBaseUrl}/api/Agents/${agentID}/Events/${eventID}`,
    );
  }

  acceptEvent(eventID, date) {
    const action: Action = new Action({
      action: 'accept',
      data: { date },
      createdAt: new Date(),
      updatedAt: new Date(),
    });

    const url = `${environment.apiBaseUrl}/api/Events/${eventID}/actions`;

    return this.httpClient.post(url, action).pipe(
      catchError((err) => {
        console.log('error', err);
        return of(err);
      }),
    );
  }

  rejectEvent(eventID, Reason:Reason) {
    const action: Action = new Action({
      action: 'reject',
      data: { Reason: Reason.reasonCode },
      createdAt: new Date(),
      updatedAt: new Date(),
    });

    const url = `${environment.apiBaseUrl}/api/Events/${eventID}/actions`;

    return this.httpClient.post(url, action).pipe(
      catchError((err) => {
        console.log('error', err);
        return of(err);
      }),
    );
  }

  setEventSuccess(projectID, observations) {
    const action: Action = new Action({
      action: 'visitSuccess',
      data: { observations },
      createdAt: new Date(),
      updatedAt: new Date(),
    });

    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}/actions`;

    return this.httpClient.post(url, action).pipe(
      catchError((err) => {
        console.log('error', err);
        return of(err);
      }),
    );
  }

  setEventCanceled(projectID) {
    const action: Action = new Action({
      action: 'visitCancel',
      data: {},
      createdAt: new Date(),
      updatedAt: new Date(),
    });

    const url = `${environment.apiBaseUrl}/api/Leads/${projectID}/actions`;

    return this.httpClient.post(url, action).pipe(
      catchError((err) => {
        console.log('error', err);
        return of(err);
      }),
    );
  }


  getLeadEvents(leadId : string): Observable<any>{
    const filter = {
      where: {
        leadId,
      },
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
      }),
    };

    const url = `${environment.apiBaseUrl}/api/Events`;

    return this.httpClient.get(url, httpOptions);
  }
}

<div class="new-password">
  <p class="text-xl">
    {{ 'change-password' | translate }}
  </p>
  <p class="description">
    {{ 'change-password-page-description' | translate }}
  </p>

  <form [formGroup]="newPasswordFormGroup">
    <div class="custom-input text-sm">
      <p>{{ 'email' | translate }}</p>

      <mat-form-field appearance="outline" color="primary">
        <input matInput type="email" formControlName="email" />

        <mat-error
          *ngIf="newPasswordFormGroup.controls.email.hasError('required')"
          [innerHTML]="'field-required' | translate"
        >
        </mat-error>
      </mat-form-field>
    </div>

    <div class="custom-input text-sm">
      <p>{{ 'new-password' | translate }}</p>

      <mat-form-field appearance="outline" color="primary">
        <input
          matInput
          type="password"
          [type]="hidePassword ? 'password' : 'text'"
          formControlName="newPassword"
        />
        <mat-icon
          matSuffix
          *ngIf="hidePassword"
          (click)="hidePassword = !hidePassword"
          svgIcon="24px-heart"
        ></mat-icon>
        <mat-icon
          matSuffix
          *ngIf="!hidePassword"
          (click)="hidePassword = !hidePassword"
          svgIcon="24px-shortcutBudget"
        ></mat-icon>

        <mat-error *ngIf="newPasswordFormGroup.errors?.diferentPassword">
          {{ 'error-old-new-password-equal' | translate }}
        </mat-error>
        <mat-error
          *ngIf="newPasswordFormGroup.controls.newPassword.value === ''"
        >
          {{ 'error-new-password-missing' | translate }}
        </mat-error>
        <mat-error
          *ngIf="newPasswordFormGroup.controls.newPassword.hasError('required')"
          [innerHTML]="'field-required' | translate"
        >
        </mat-error>
        <mat-error
          *ngIf="
            newPasswordFormGroup.controls.newPassword.hasError('minlength')
          "
          [innerHTML]="'password-minlength-error' | translate"
        >
        </mat-error>
        <mat-error
          *ngIf="
            newPasswordFormGroup.controls.newPassword.hasError('maxlength')
          "
          [innerHTML]="'password-maxlength-error' | translate"
        >
        </mat-error>
      </mat-form-field>
    </div>
    {{ newPasswordFormGroup.errors?.diferentPassword }}

    <div class="custom-input text-sm">
      <p>{{ 'confirm-password' | translate }}</p>

      <mat-form-field appearance="outline" color="primary">
        <input matInput type="password" formControlName="confirmPassword" />

        <mat-error *ngIf="newPasswordFormGroup.errors?.matchPassword">
          {{ 'error-password-not-match' | translate }}
        </mat-error>
        <mat-error
          *ngIf="newPasswordFormGroup.controls.confirmPassword.value === ''"
        >
          {{ 'error-confirmation-password-missing' | translate }}
        </mat-error>
        <mat-error
          *ngIf="
            newPasswordFormGroup.controls.confirmPassword.hasError('required')
          "
          [innerHTML]="'field-required' | translate"
        >
        </mat-error>
        <mat-error
          *ngIf="
            newPasswordFormGroup.controls.confirmPassword.hasError('minlength')
          "
          [innerHTML]="'password-minlength-error' | translate"
        >
        </mat-error>
        <mat-error
          *ngIf="
            newPasswordFormGroup.controls.confirmPassword.hasError('maxlength')
          "
          [innerHTML]="'password-maxlength-error' | translate"
        >
        </mat-error>
      </mat-form-field>
    </div>

    <button
      mat-flat-button
      color="accent"
      (click)="submitChangePassword()"
      [disabled]="false"
    >
      {{ 'change-password' | translate }}
    </button>
  </form>
</div>

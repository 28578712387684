<form
  [formGroup]="registerForm"
  data-cy="register-form"
  (keyup.enter)="registerClient()"
>
  <span class="title text-xxl">{{
    'create-your-account-client' | translate
  }}</span>
  <span class="title text-l">{{ 'register-subtitle-client' | translate }}</span>

  <div [ngClass]="{ hidden: fromQuiz }" class="custom-input text-sm">
    <p>
      {{ 'user-name' | translate }}{{ requiredValidator('clientN') ? '*' : '' }}
    </p>

    <mat-form-field appearance="outline" color="primary">
      <input
        matInput
        type="text"
        formControlName="clientN"
        data-cy="user-name"
        autocomplete="name"
      />
      <mat-icon matSuffix svgIcon="24px-profile"></mat-icon>
      <mat-error
        class="email-required"
        *ngIf="registerForm.controls.clientN.hasError('required')"
        [innerHTML]="'field-required' | translate"
        data-cy="user-name-required"
      >
      </mat-error>
      <mat-error
        *ngIf="registerForm.controls.clientN.hasError('minlength')"
        [innerHTML]="'2-characters-error' | translate"
        data-cy="user-name-min-length"
      >
      </mat-error>
      <mat-error
        *ngIf="registerForm.controls.clientN.hasError('maxlength')"
        [innerHTML]="'100-characters-error' | translate"
        data-cy="user-name-max-length"
      >
      </mat-error>
    </mat-form-field>
  </div>

  <div class="custom-input text-sm">
    <p>{{ 'email' | translate }}{{ requiredValidator('email') ? '*' : '' }}</p>

    <mat-form-field appearance="outline" color="primary">
      <input
        matInput
        type="email"
        formControlName="email"
        data-cy="email"
        autocomplete="email"
      />
      <mat-icon matSuffix svgIcon="24px-mailClosed"></mat-icon>

      <mat-error
        class="email-required"
        *ngIf="registerForm.controls.email.hasError('required')"
        [innerHTML]="'field-required' | translate"
        data-cy="email-required"
      >
      </mat-error>
      <mat-error
        class="email-pattern"
        *ngIf="
          registerForm.controls.email.hasError('pattern') &&
          registerForm.controls.email.value.length > 5
        "
        [innerHTML]="'email-field' | translate"
        data-cy="email-pattern"
      >
      </mat-error>
    </mat-form-field>
  </div>

  <div class="custom-input text-sm">
    <p>
      {{ 'password' | translate }}{{ requiredValidator('password') ? '*' : '' }}
    </p>

    <mat-form-field appearance="outline" color="primary">
      <input
        matInput
        [type]="hidePassword ? 'password' : 'text'"
        formControlName="password"
        data-cy="password"
        autocomplete="new-password"
      />
      <mat-icon
        matSuffix
        *ngIf="hidePassword"
        (click)="hidePassword = !hidePassword"
        svgIcon="24px-heart"
      ></mat-icon>
      <mat-icon
        matSuffix
        *ngIf="!hidePassword"
        (click)="hidePassword = !hidePassword"
        svgIcon="24px-shortcutBudget"
      ></mat-icon>
      <mat-error
        class="password-required"
        *ngIf="registerForm.controls.password.hasError('required')"
        [innerHTML]="'field-required' | translate"
        data-cy="password-required"
      >
      </mat-error>
      <mat-error
        class="password-required"
        *ngIf="registerForm.controls.password.hasError('minlength')"
        [innerHTML]="'password-minlength-error' | translate"
        data-cy="password-min-lenght"
      >
      </mat-error>
      <mat-error
        class="password-required"
        *ngIf="registerForm.controls.password.hasError('maxlength')"
        [innerHTML]="'password-maxlength-error' | translate"
      >
      </mat-error>
    </mat-form-field>
  </div>

  <div [ngClass]="{ hidden: fromQuiz }" class="custom-input text-sm">
    <p>
      {{ 'phone-number' | translate
      }}{{ requiredValidator('phoneNumber') ? '*' : '' }}
    </p>

    <mat-form-field appearance="outline" color="primary">
      <input
        matInput
        appInputPhoneFormat
        type="telf"
        formControlName="phoneNumber"
        data-cy="phone"
      />
      <mat-icon matSuffix svgIcon="24px-phone"></mat-icon>

      <mat-error
        class="password-required"
        *ngIf="registerForm.controls.phoneNumber.hasError('required')"
        [innerHTML]="'field-required' | translate"
        data-cy="phone-required"
      >
      </mat-error>
      <mat-error
        class="password-required"
        *ngIf="registerForm.controls.phoneNumber.hasError('pattern')"
        [innerHTML]="'phone-number-field' | translate"
      >
      </mat-error>
      <mat-error
        class="password-required"
        *ngIf="registerForm.controls.phoneNumber.hasError('minlength')"
        [innerHTML]="'phone-minlength-error' | translate"
        data-cy="phone-min-length"
      >
      </mat-error>
      <mat-error
        class="password-required"
        *ngIf="registerForm.controls.phoneNumber.hasError('maxlength')"
        [innerHTML]="'phone-maxlength-error' | translate"
      >
      </mat-error>
    </mat-form-field>
  </div>

  <mat-checkbox
    class="text-sm"
    formControlName="termsAndConditions"
    data-cy="term-conditions"
  >
    {{ 'accept-terms-and-conditions' | translate }}

    <a
      target="no-blank"
      href="
      https://s3.eu-central-1.amazonaws.com/cubicup.filesystem.pro/Terminos+y+Condiciones+2020.pdf"
      class="primary-link"
    >
      {{ 'terms-and-conditions' | translate }}
    </a>
  </mat-checkbox>
</form>

<div class="action">
  <button
    mat-flat-button
    color="primary"
    type="submit"
    (click)="registerClient()"
    [disabled]="
      registerForm.invalid ||
      !registerForm.controls.termsAndConditions.value ||
      sendingForm
    "
    data-cy="send-form"
  >
    {{ registerButtonLabel | translate }}
  </button>

  <span class="text-xs">
    {{ 'already-account' | translate }}

    <a
      *ngIf="utmSource; else noUtm"
      routerLink="/login"
      [queryParams]="utmSource"
      class="primary-link"
    >
      {{ 'enter-here' | translate }}
    </a>

    <ng-template #noUtm>
      <a routerLink="/login" class="primary-link">
        {{ 'enter-here' | translate }}
      </a>
    </ng-template>
  </span>
</div>

import { ConversationInterface, Tracking, TrackingInterface } from '@core/sdk';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

export enum TrackingActionsTypes {
  SetTrackingsToCompleteFromItemArrRequestStarted = '[Tracking Api] SET_TRACKING_TO_COMPLETE_FROM_BUDGET_ITEMS_ID_REQUEST_STARTED',
  SetTrackingsToCompleteFromItemArrRequestSuccess = '[Tracking Api] SET_TRACKING_TO_COMPLETE_FROM_BUDGET_ITEMS_ID_REQUEST_SUCCESS',
  GetTrackingFromBudgetItemIdsRequestStarted = '[Tracking Api] GET_TRACKING_FROM_BUDGET_ITEM_IDS_REQUEST_STARTED',
  GetTrackingFromBudgetItemIdsRequestSuccess = '[Tracking Api] GET_TRACKING_FROM_BUDGET_ITEM_IDS_REQUEST_SUCCESS',
  GetTrackingFromLeadIdRequestStarted = '[Tracking Api] GET_TRACKING_FROM_LEAD_ID_REQUEST_STARTED',
  GetTrackingFromLeadIdRequestSuccess = '[Tracking Api] GET_TRACKING_FROM_LEAD_ID_REQUEST_SUCCESS',
  GetTrackingRequestStarted = '[Tracking Api] GET_TRACKING_REQUEST_STARTED',
  GetTrackingRequestSuccess = '[Tracking Api] GET_TRACKING_REQUEST_SUCCESS',
  GetTrackingsRequestStarted = '[Tracking Api] GET_TRACKINGS_REQUEST_STARTED',
  GetTrackingsRequestSuccess = '[Tracking Api] GET_TRACKINGS_REQUEST_SUCCESS',
  UpdateTrackingRequestStarted = '[Tracking Api] UPDATE_TRACKING_REQUEST_STARTED',
  UpdateTrackingRequestSuccess = '[Tracking Api] UPDATE_TRACKING_REQUEST_SUCCESS',
  CancelTrackingRequestStarted = '[Tracking Api] CANCEL_TRACKING_REQUEST_STARTED',
  CancelTrackingRequestSuccess = '[Tracking Api] CANCEL_TRACKING_REQUEST_SUCCESS',
  AddTracking = '[Tracking Store] ADD_TRACKING',
  AddTrackings = '[Tracking Store] ADD_TRACKINGS',
  UpdateTracking = '[Tracking Store] UPDATE_TRACKING',
  UpdateTrackings = '[Tracking Store] UPDATE_TRACKINGS',
  RemoveTracking = '[Tracking Store] REMOVE_TRACKING',
  RemoveTrackings = '[Tracking Store] REMOVE_TRACKINGS',
  ClearEntities = '[Tracking Store] CLEAR_TRACKING_STORE',
  SetTrackingsToCompleteFromItemArr = '[Tracking Store] SET_TRACKING_TO_COMPLETE_FROM_BUDGET_ITEMS_ID',
  AddTrackingConversationRequestStarted = '[Tracking Api] ADD_TRACKING_CONVERSATION_STARTED',
  AddTrackingConversationRequestSuccess = '[Tracking Api] ADD_TRACKING_CONVERSATION_SUCCESS',
  CompleteAllTrackingsRequestStarted = '[Tracking Api] COMPLETE_ALL_TRACKINGS_REQUEST_STARTED',
  CompleteAllTrackingsRequestSuccess = '[Tracking Api] COMPLETE_ALL_TRACKINGS_REQUEST_SUCCESS',
}

export const completeAllTrackingsRequestStarted = createAction(
  TrackingActionsTypes.CompleteAllTrackingsRequestStarted,
);

export const completeAllTrackingsRequestSuccess = createAction(
  TrackingActionsTypes.CompleteAllTrackingsRequestSuccess,
  props<{ payload: string[] }>(),
);

export const setTrackingsToCompleteFromItemArrRequestStarted = createAction(
  TrackingActionsTypes.SetTrackingsToCompleteFromItemArrRequestStarted,
  props<{ payload: string[] }>(),
);

export const setTrackingsToCompleteFromItemArrRequestSuccess = createAction(
  TrackingActionsTypes.SetTrackingsToCompleteFromItemArrRequestSuccess,
  props<{ payload: string[] }>(),
);

export const setTrackingsToCompleteFromItemArr = createAction(
  TrackingActionsTypes.SetTrackingsToCompleteFromItemArr,
  props<{ payload: string[] }>(),
);

export const getTrackingFromBudgetItemIdsRequestStarted = createAction(
  TrackingActionsTypes.GetTrackingFromBudgetItemIdsRequestStarted,
  props<{ payload: string[] }>(),
);

export const getTrackingFromBudgetItemIdsRequestSuccess = createAction(
  TrackingActionsTypes.GetTrackingFromBudgetItemIdsRequestSuccess,
  props<{ payload: Tracking[] }>(),
);

export const getTrackingFromLeadIdRequestStarted = createAction(
  TrackingActionsTypes.GetTrackingFromLeadIdRequestStarted,
  props<{ payload: string }>(),
);

export const getTrackingFromLeadIdRequestSuccess = createAction(
  TrackingActionsTypes.GetTrackingFromLeadIdRequestSuccess,
  props<{ payload: Tracking[] }>(),
);

export const getTrackingRequestStarted = createAction(
  TrackingActionsTypes.GetTrackingRequestStarted,
  props<{ payload: string }>(),
);

export const getTrackingRequestSuccess = createAction(
  TrackingActionsTypes.GetTrackingRequestSuccess,
  props<{ payload: Tracking }>(),
);

export const getTrackingsRequestStarted = createAction(
  TrackingActionsTypes.GetTrackingsRequestStarted,
  props<{ payload: string[] }>(),
);

export const getTrackingsRequestSuccess = createAction(
  TrackingActionsTypes.GetTrackingsRequestSuccess,
  props<{ payload: Tracking[] }>(),
);

export const updateTrackingRequestStarted = createAction(
  TrackingActionsTypes.UpdateTrackingRequestStarted,
  props<{ payload: Partial<Tracking> }>(),
);

export const updateTrackingRequestSuccess = createAction(
  TrackingActionsTypes.UpdateTrackingRequestSuccess,
  props<{ payload: Tracking }>(),
);

export const addTracking = createAction(
  TrackingActionsTypes.AddTracking,
  props<{ payload: Tracking }>(),
);

export const addTrackings = createAction(
  TrackingActionsTypes.AddTrackings,
  props<{ payload: Tracking[] }>(),
);

export const updateTracking = createAction(
  TrackingActionsTypes.UpdateTracking,
  props<{ payload: Update<Tracking> }>(),
);

export const updateTrackings = createAction(
  TrackingActionsTypes.UpdateTrackings,
  props<{ payload: Update<Tracking>[] }>(),
);

export const removeTracking = createAction(
  TrackingActionsTypes.RemoveTracking,
  props<{
    trackingId: string;
    chapterId: string;
  }>(),
);

export const removeTrackings = createAction(
  TrackingActionsTypes.RemoveTrackings,
  props<{
    trackingId: string;
    chapterId: string;
  }>(),
);

export const cancelTrackingRequestStarted = createAction(
  TrackingActionsTypes.CancelTrackingRequestStarted,
  props<{
    trackingId: string;
    notify?: boolean;
  }>(),
);

export const cancelTrackingRequestSuccess = createAction(
  TrackingActionsTypes.CancelTrackingRequestSuccess,
  props<{
    payload: string;
  }>(),
);

export const clearEntities = createAction(TrackingActionsTypes.ClearEntities);

export const addTrackingConversationRequestStarted = createAction(
  TrackingActionsTypes.AddTrackingConversationRequestStarted,
  props<{
    payload: TrackingInterface;
  }>(),
);

export const addTrackingConversationRequestSuccess = createAction(
  TrackingActionsTypes.AddTrackingConversationRequestSuccess,
  props<{ discussion: ConversationInterface }>(),
);

<mat-sidenav-container class="sidenav" [ngClass]="{ 'icon-mode': iconMode$.getValue() }">
  <mat-sidenav
    *ngIf="sidenavMode$ | async as sidenavMode"
    #sidenav
    class="text-sm"
    [ngClass]="{ 'icon-mode': iconMode$.getValue() }"
    [mode]="sidenavMode === 'web' ? 'side' : 'over'"
    [opened]="sidenavMode === 'web' ? true : false"
    [disableClose]="sidenavMode === 'web' ? true : false"
  >
    <a class="logo-link" *ngIf="mainLogoUrl$ | async as logo">
      <img [src]="primaryLogoPath" [ngClass]="{ 'not-display': logo != 'primary' }" />
      <img [src]="miniLogoPath" [ngClass]="{ 'not-display': logo != 'mini' }" />
    </a>

    <button
      *ngIf="sidenavMode === 'web'"
      mat-icon-button class="sidenav-toggle-button"
      (click)="toggleIconMode()"
      disableRipple
      data-cy="toggle-sidenav"
    >
      <mat-icon>
        {{ iconMode$.getValue() ? 'chevron_right' : 'chevron_left' }}
      </mat-icon>
    </button>

    <ng-container *ngIf="agent$ | async as agent">
      <mat-accordion hideToggle *ngIf="sidebarType === 'agent'">
        <mat-expansion-panel *ngIf="role === 'sales'">
          <mat-expansion-panel-header
            (click)="goTo('estimation')"
            [matTooltip]="'agent-estimations' | translate"
            matTooltipPosition="right"
            [matTooltipDisabled]="!iconMode$.getValue()"
          >
            <mat-panel-title>
              <a [routerLink]="['estimation']" [routerLinkActive]="'active'" data-cy="agnet-estimation">
                <mat-icon>view_list</mat-icon>
                <span *ngIf="!iconMode$.getValue()">
                  {{ 'estimations' | translate }}
                </span>
              </a>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel
          [ngClass]="{ 'current-route-active': currentUrl === 'events' }">
          <mat-expansion-panel-header
            (click)="goTo('events')"
            [matTooltip]="'events' | translate"
            matTooltipPosition="right"
            [matTooltipDisabled]="!iconMode$.getValue()"
          >
            <mat-panel-title>
              <a [routerLink]="['events']" [routerLinkActive]="'active'" data-cy="events-page">
                <mat-icon svgIcon="16px-calendar"></mat-icon>
                <span *ngIf="!iconMode$.getValue()">
                  {{ 'events' | translate }}
                </span>
              </a>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel [ngClass]="{
            'current-route-active':
              currentUrl === 'projects' || currentUrl === 'project'
          }">
          <mat-expansion-panel-header
            (click)="goTo('projects')"
            [matTooltip]="'projects' | translate"
            matTooltipPosition="right"
            [matTooltipDisabled]="!iconMode$.getValue()"
          >
            <mat-panel-title>
              <a [routerLink]="['projects']" [routerLinkActive]="'active'" data-cy="projects-page">
                <mat-icon svgIcon="16px-projects"></mat-icon>
                <span *ngIf="!iconMode$.getValue()">
                  {{
                  context === 'login-client'
                  ? 'project'
                  : ('projects' | translate)
                  }}
                </span>
              </a>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="!agent.cubicupPartnerCode"
          [ngClass]="{ 'current-route-active': currentUrl === 'on-boarding' }"
        >
          <mat-expansion-panel-header
            (click)="goTo('on-boarding')"
            [matTooltip]="'company-profile' | translate"
            matTooltipPosition="right"
            [matTooltipDisabled]="!iconMode$.getValue()"
          >
            <mat-panel-title>
              <a [routerLink]="['on-boarding']" [routerLinkActive]="'active'" data-cy="company-profile-page">
                <mat-icon svgIcon="24px-settings"></mat-icon>
                <span *ngIf="!iconMode$.getValue()">
                  {{ 'company-profile' | translate }}
                </span>
              </a>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel [ngClass]="{ 'current-route-active': currentUrl === 'templates' }"
          *appHasPermission="'product.create'">
          <mat-expansion-panel-header
            (click)="goTo('templates/products')"
            [matTooltip]="'templates' | translate"
            matTooltipPosition="right"
            [matTooltipDisabled]="!iconMode$.getValue()"
          >
            <mat-panel-title>
              <a [routerLink]="['templates']" [routerLinkActive]="'active'" data-cy="templates-page">
                <mat-icon>view_list</mat-icon>
                <span *ngIf="!iconMode$.getValue()">
                  {{ 'templates' | translate }}
                </span>
              </a>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="superAdmin" [ngClass]="{ 'current-route-active': currentUrl === 'templates-quiz' }">
          <mat-expansion-panel-header
            (click)="goTo('templates-quiz')"
            [matTooltip]="'templates-quiz' | translate"
            matTooltipPosition="right"
            [matTooltipDisabled]="!iconMode$.getValue()"
          >
            <mat-panel-title>
              <a [routerLink]="['templates-quiz']" [routerLinkActive]="'active'" data-cy="templates-quiz-page">
                <mat-icon>view_list</mat-icon>
                <span *ngIf="!iconMode$.getValue()">
                  {{ 'templates-quiz' | translate }}
                </span>
              </a>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel [ngClass]="{ 'current-route-active': currentUrl === 'templates-quiz' }" *appHasPermission="'quiz.create'">
          <mat-expansion-panel-header
            (click)="goTo('quizzes/list')"
            [matTooltip]="'quizzes' | translate"
            matTooltipPosition="right"
            [matTooltipDisabled]="!iconMode$.getValue()"
          >
            <mat-panel-title>
              <a [routerLink]="['quizzes', 'list']" [routerLinkActive]="'active'" data-cy="quizzes-page">
                <mat-icon>view_list</mat-icon>
                <span *ngIf="!iconMode$.getValue()">
                  {{ 'quizzes' | translate }}
                </span>
              </a>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel
          *appHasPermission="'tracking-list.read'"
          [ngClass]="{
            'current-route-active': currentUrl === 'tracking-list'
          }"
        >
          <mat-expansion-panel-header
            (click)="goTo('tracking-list')"
            [matTooltip]="'tracking' | translate"
            matTooltipPosition="right"
            [matTooltipDisabled]="!iconMode$.getValue()"
          >
            <mat-panel-title>
              <a [routerLink]="['tracking-list']" [routerLinkActive]="'active'" data-cy="tracking-page">
                <mat-icon svgIcon="24px-arrow"></mat-icon>
                <span *ngIf="!iconMode$.getValue()">
                  {{ 'tracking' | translate }}
                </span>
              </a>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="superAdmin">
          <mat-expansion-panel-header
            (click)="goTo('admin/roles')"
            [matTooltip]="'administration' | translate"
            matTooltipPosition="right"
            [matTooltipDisabled]="!iconMode$.getValue()"
          >
            <mat-panel-title>
              <a [routerLink]="['admin', 'roles']" [routerLinkActive]="'active'" data-cy="agent-administration">
                <mat-icon>view_list</mat-icon>
                <span *ngIf="!iconMode$.getValue()">
                  {{ 'administration' | translate }}
                </span>
              </a>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </mat-accordion>

    </ng-container>
    <ng-container *ngIf="loginClient$ | async as client">
      <mat-accordion hideToggle *ngIf="sidebarType === 'login-client'">
        <mat-expansion-panel *ngIf="!ppv || (ppv && b2b)">
          <mat-expansion-panel-header
            (click)="goTo('projects')"
            [matTooltip]="'projects' | translate"
            matTooltipPosition="right"
            [matTooltipDisabled]="!iconMode$.getValue()"
            data-cy="client-project-page"
          >
            <mat-panel-title>
              <a
                [routerLink]="['logged-client/projects']" [routerLinkActive]="'active'">
                <mat-icon svgIcon="16px-projects"></mat-icon>
                <span *ngIf="!iconMode$.getValue()">
                  {{
                  (context === 'login-client' ? 'project' : 'projects')
                  | translate
                  }}
                </span>
              </a>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header *ngIf="ppv || role === 'sales'"
            (click)="goTo('estimation')"
            [matTooltip]="'client-estimations' | translate"
            matTooltipPosition="right"
            [matTooltipDisabled]="!iconMode$.getValue()"
          >
            <mat-panel-title>
              <a [routerLink]="['logged-client', 'estimation']" [routerLinkActive]="'active'" data-cy="client-profile-page">
                <mat-icon>view_list</mat-icon>
                <span *ngIf="!iconMode$.getValue()">
                  {{ 'estimations' | translate }}
                </span>
              </a>
            </mat-panel-title>
          </mat-expansion-panel-header>
           <mat-expansion-panel-header
            (click)="goTo('howto')"
            [matTooltip]="'client-howto' | translate"
            matTooltipPosition="right"
            [matTooltipDisabled]="!iconMode$.getValue()"
          >
            <mat-panel-title>
              <a [routerLink]="['logged-client', 'howto']" [routerLinkActive]="'active'" data-cy="client-profile-page">
                <mat-icon>notes</mat-icon>
                <span *ngIf="!iconMode$.getValue()">
                  {{ 'how-it-works' | translate }}
                </span>
              </a>
            </mat-panel-title>
          </mat-expansion-panel-header>
           <mat-expansion-panel-header
            *ngIf="context === 'login-client' && !ppv"
            (click)="goTo('designphase')"
            [matTooltip]="'client-designphase' | translate"
            matTooltipPosition="right"
            [matTooltipDisabled]="!iconMode$.getValue()"
          >
            <mat-panel-title>
              <a [routerLinkActive]="'active'" data-cy="design-phase-page">
                <mat-icon>computer</mat-icon>
                <span *ngIf="!iconMode$.getValue()">
                  {{ 'design-phase' | translate }}
                </span>
              </a>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-expansion-panel-header *ngIf="ppv"
          (click)="goTo('metrics')"
          [matTooltip]="'client-howto' | translate"
          matTooltipPosition="right"
          [matTooltipDisabled]="!iconMode$.getValue()"
        >
          <mat-panel-title>
            <a [routerLink]="['logged-client', 'metrics']" [routerLinkActive]="'active'" data-cy="client-profile-page">
              <mat-icon>assessment</mat-icon>
              <span *ngIf="!iconMode$.getValue()">
                {{ 'metrics' | translate }}
              </span>
            </a>
          </mat-panel-title>
        </mat-expansion-panel-header>
        </mat-expansion-panel>
      </mat-accordion>

    </ng-container>
  </mat-sidenav>

  <mat-sidenav-content [ngClass]="{ 'icon-mode': iconMode$.getValue() }" (scroll)="handleScroll($event)" #thatOtherSideNav>
    <app-loader [ngStyle]="{ 'max-width': loaderWidth$ | async }"></app-loader>
    <app-header showLogo="true" hideMenu="false"></app-header>
    <div class="router-outlet">
      <ng-content></ng-content>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-initial-letters',
  templateUrl: './initial-letters.component.html',
  styleUrls: ['./initial-letters.component.scss'],
})
export class InitialLettersComponent implements OnInit {
  @Input() name = 'Cubi Cup';

  initials: string;

  constructor() {}

  ngOnInit() {
    this.initials = this.getInitials(this.name);
  }

  getInitials(name: string): string {
    if (!name) {
      return;
    }

    const nameArray = name.trim().replace('  ', ' ').split(' ');

    if (nameArray.length > 1) {
      const [[firstLetter], [[secondLetter]]] = nameArray;

      return `${firstLetter}${secondLetter}`;
    }

    const [[firstLetter]] = nameArray;

    return firstLetter;
  }
}

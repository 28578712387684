/* eslint-disable */
/**
 * @module SDKModule
 * @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
 * @license MIT 2016 Jonathan Casarrubias
 * @version 2.1.0
 * @description
 * The SDKModule is a generated Software Development Kit automatically built by
 * the LoopBack SDK Builder open source module.
 *
 * The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
 * can import this Software Development Kit as follows:
 *
 *
 * APP Route Module Context
 * ============================================================================
 * import { NgModule }       from '@angular/core';
 * import { BrowserModule }  from '@angular/platform-browser';
 * // App Root
 * import { AppComponent }   from './app.component';
 * // Feature Modules
 * import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
 * // Import Routing
 * import { routing }        from './app.routing';
 * @NgModule({
 *  imports: [
 *    BrowserModule,
 *    routing,
 *    SDK[Browser|Node|Native]Module.forRoot()
 *  ],
 *  declarations: [ AppComponent ],
 *  bootstrap:    [ AppComponent ]
 * })
 * export class AppModule { }
 *
 **/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { UserApi } from './services/custom/User';
import { ActionApi } from './services/custom/Action';
import { BudgetApi } from './services/custom/Budget';
import { ProductApi } from './services/custom/Product';
import { BudgetItemApi } from './services/custom/BudgetItem';
import { ChapterApi } from './services/custom/Chapter';
import { AddressApi } from './services/custom/Address';
import { LeadApi } from './services/custom/Lead';
import { AgentApi } from './services/custom/Agent';
import { PersonApi } from './services/custom/Person';
import { TestApi } from './services/custom/Test';
import { ContactApi } from './services/custom/Contact';
import { EventApi } from './services/custom/Event';
import { ActivityApi } from './services/custom/Activity';
import { BriefingItemApi } from './services/custom/BriefingItem';
import { ReasonApi } from './services/custom/Reason';
import { SeriesApi } from './services/custom/Series';
import { PositionApi } from './services/custom/Position';
import { PaymentMethodApi } from './services/custom/PaymentMethod';
import { CubicupStaffApi } from './services/custom/CubicupStaff';
import { ClientApi } from './services/custom/Client';
import { S3FileApi } from './services/custom/S3File';
import { DocumentApi } from './services/custom/Document';
/**
 * @module SDKBrowserModule
 * @description
 * This module should be imported when building a Web Application in the following scenarios:
 *
 *  1.- Regular web application
 *  2.- Angular universal application (Browser Portion)
 *  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
 **/
@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
  providers: [ErrorHandler],
})
export class SDKBrowserModule {
  static forRoot(
    internalStorageProvider: any = {
      provide: InternalStorage,
      useClass: CookieBrowser,
    },
  ): ModuleWithProviders<SDKBrowserModule> {
    return {
      ngModule: SDKBrowserModule,
      providers: [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        UserApi,
        ActionApi,
        BudgetApi,
        ProductApi,
        BudgetItemApi,
        ChapterApi,
        AddressApi,
        LeadApi,
        AgentApi,
        PersonApi,
        TestApi,
        ContactApi,
        EventApi,
        ActivityApi,
        BriefingItemApi,
        ReasonApi,
        SeriesApi,
        PositionApi,
        PaymentMethodApi,
        CubicupStaffApi,
        ClientApi,
        S3FileApi,
        DocumentApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser },
      ],
    };
  }
}
/**
 * Have Fun!!!
 * - Jon
 **/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';

<div class="header">
  <span class="title">{{ 'write-your-review' | translate }}</span>
  <span class="subtitle" *ngIf="agentName.value">{{
    'evaluate-experience' | translate: agentName
  }}</span>
  <span class="description">{{ 'review-score-description' | translate }}</span>
</div>

<section class="body">
  <span class="title">{{ 'your-review' | translate }}</span>
  <div class="score">
    <div class="stars">
      <ng-container *ngFor="let star of stars; let reviewScoringIndex = index">
        <mat-icon
          svgIcon="{{
            reviewScoringIndex >= reviewRequestForm.get('score').value
              ? '12px-emptyStar'
              : '12px-star'
          }}"
          (click)="setReviewScoring(reviewScoringIndex)"
        ></mat-icon>
      </ng-container>
      <span
        >{{
          reviewRequestForm.get('score').value
            ? reviewRequestForm.get('score').value
            : 0
        }}
        / 5</span
      >
    </div>
  </div>
  <span class="review-comment-title">{{
    'put-your-comments' | translate
  }}</span>
  <span class="review-comment-description">{{
    'review-comment-description' | translate
  }}</span>
  <form [formGroup]="reviewRequestForm">
    <div class="custom-input text-sm">
      <mat-form-field appearance="outline" color="primary">
        <textarea
          matInput
          type="text"
          formControlName="comment"
          data-cy="comment"
          [disabled]="disabledAll"
        ></textarea>

        <mat-error
          class="email-required"
          *ngIf="reviewRequestForm.get('comment').hasError('required')"
          [innerHTML]="'field-required' | translate"
        >
        </mat-error>
        <mat-error
          *ngIf="reviewRequestForm.get('comment').hasError('minlength')"
          [innerHTML]="'2-characters-error' | translate"
          data-cy="user-name-min-length"
        >
        </mat-error>
        <mat-error
          *ngIf="reviewRequestForm.get('comment').hasError('maxlength')"
          [innerHTML]="'100-characters-error' | translate"
          data-cy="user-name-max-length"
        >
        </mat-error>
      </mat-form-field>
    </div>

    <div class="button-wrapper">
      <button
        type="submit"
        mat-flat-button
        color="primary"
        (click)="sendResponse()"
        [disabled]="reviewRequestForm.invalid || disabledAll"
      >
        {{ 'send-my-review' | translate }}
      </button>
    </div>
  </form>
</section>

{{ reviewRequestForm | log }}

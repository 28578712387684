<img src="assets/img/logo-primary-dark.svg" alt="" />


<div class="router-outlet">
  <router-outlet></router-outlet>
</div>
<div [ngClass]="{
    'login-image': context === 'login' || context === '',
    'register-image-agent': context === 'register-agent',
    'register-image-client': context === 'register-client',
    'quiz-image-onboarding': context === 'on-boarding-quiz',
    'quiz-image-calculator': context === 'calculator-quiz',
    'quiz-image-ppv': context === 'ppv',
    'quiz-image-automated': context === 'automated' || context === 'configured',
  }" class="auth-image"></div>
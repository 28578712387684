import { IProjectDocumentsOptions } from "@shared/interfaces/projectDocumentOptions.interface";

export const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'svg'];
export const VIDEO_EXTENSIONS = ['mp4', 'ogg', 'avi', 'mkv','webm'];

export const DEBOUNCE_TIME = 450;

export const PAYMENT_METHOD = {
    signal: 0,
    ahead: 40,
    progress: 55,
    end: 5,
}
export const ROLES = {
  SUPERADMIN: 'superadmin',
  OWNER: 'owner',
  EMPLOYEE: 'employee',
  SALES: 'sales',
  DESIGNER: 'design',
  B2B: 'b2b',
  ADMINISTRATION: 'administration'
};


export const CONTEXTS = {
  AGENT: 'agent',
  LOGIN_CLIENT: 'login-client',
  CLIENT: 'client',
};


export const  URLS_NOTION = {
  PPV_LEROY: "https://v46j13we0m7opp.embednotionpage.com/C-MO-FUNCIONA-14b71afa082f465a8bccbce24f35fe1a",
  CLIENT: "https://vwgv221egp1kxp.embednotionpage.com/cubicup-team/C-mo-es-el-proceso-0a4ab1c46513470cb464d202786e4ba6",
  DESIGN_PHASE: "https://pwn6j6e9542rv0.embednotionpage.com/Fase-de-Dise-o-79e318c50017474c81cdc1e964600c2d"
}

export const  URLS_MONDAY = {
  PPV_LEROY: "https://view.monday.com/embed/4616869117-05fa0d10b4e75ea8441819c4ad5174d5?r=use1",
}

export const LEAD_STATES = {
  PENDING: 'pending',
  CREATED: 'created',
  NEW: 'new'
}

export const LB4_ERRORS = {
  BAD_REQUEST: 400
}

export const STATES_ACTIVATE_TRACKING = [
  'pendingOfSignature',
  'pendingOfPayment',
  'signalPaid',
  'aheadPaid',
  'won',
]

export const STATES_SHOW_CLIENT_BUDGETS =  [
  'pendingOfAnswer',
  'pendingOfSignal',
  'pendingOfAhead',
  'contractSended',
  'accepted',
  'signalPaid',
  'aheadPaid',
  'created',
  'blocked',
  'rejected'
]

export const ALL_STATES = ['all'];

export const BUDGET_TYPES = {
  DRAFT: 'draft',
  BUDGET:'budget'
}

export const CERTIFICATION_STATES = {
  ACCEPTED: 'accepted',
  CREATED: 'created',
  PENDING: 'pending',
  LOCKED: 'locked',
  REJECTED: 'rejected'
}

export const ROUTES = {
  CLIENT_HOME: ['logged-client','home'],
  CLIENT_PROJECT: ['logged-client','project']
}
export const BUDGET_STATES_CLASSES = {
  'state--pending': ['pendingOfSend', 'pendingOfAttend', 'finished', 'pendingOfAnswer'],
  'state--blocked': ['blocked', 'rejected', 'deleted'],
  'state--accepted': ['accepted', 'finished', 'pendingOfSignal', 'pendingOfAhead', 'signalPaid', 'aheadPaid', 'questioned', 'contractSended']
};

export const DOCUMENTS_FOLDERS = [
  {
    value: 'legal',
    label: 'Legal',
  },
  {
    value: 'co2',
    label: 'Co2',
  },
  {
    value: 'tracking',
    label: 'tracking'
  }
]

export const TAXES = [ 
  {slug: '21%', value: 21},
  {slug: '10%', value: 10},
  {slug: '0%', value: 0}
]
export const HEADER_LINKS = {
  CERTIFICATIONS: 'Certificaciones',
  PROJECT: 'Proyecto',
  BUDGETS: 'Presupuestos',
  TRACKING: 'Seguimiento',
  DOCUMENTATION: 'Documentacion',
  INVOICES: 'Facturas',
}

export const DATE_CHANGED_CODES = {
  MATERIAL_DELAY: 'Retraso en la entrega de Materiales',
  PROJECT_CHANGES: 'Cambios solicitados en el proyecto',
  EXTERNAL_ISSUES: 'Factores externos (clima, permisos, etc..)',
  OTHER: 'Otra razón (especifica cual)'
}

export const DOCUMENT_TYPE_ICONS = [
  {id: 'pdf', path: 'assets/icons/PDF.svg'},
  {id: 'txt', path: 'assets/icons/Details.svg'},
  {id: 'docx', path: 'assets/icons/Details.svg'},
  {id: 'doc', path: 'assets/icons/Details.svg'},
  {id: 'xls', path: 'assets/icons/Details.svg)'},
  {id: 'xlsx', path: 'assets/icons/Details.svg'},
  {id: 'ods', path: 'assets/icons/Details.svg'},
  {id:'pptx', path: 'assets/icons/Details.svg'},
  {id: 'odp', path: 'assets/icons/Details.svg'},
];
export const DEFAULT_ICON = 'assets/icons/Details.svg';

export const PROJECT_DOCUMENTS_OPTIONS = {
  uploadImages: false,
  removeImages: false,
  imagesKey: 'project',
  imagesSubtitleSlug: 'project',
  uploadFiles: false,
  filesKey: 'project',
  filesSubtitleSlug: '',
  removeFiles: false,
  uploadUrls: false,
  removeUrls: false,
  urlsSubtitleSlug: '',
};
import { Role } from "./Role";

export interface CompanyInterface {
  id?: string;
  name?: string;
  roles?: Role[];
  slug?: string;
  createdAt?: Date;
  updatedAt?: Date;
  modelActive?: any;
}

export class Company implements CompanyInterface {
  'id': string;
  'name': string;
  'roles': Role[];
  'slug': string;
  'createdAt': Date;
  'updatedAt': Date;
  'modelActive': any;

  constructor(data?: CompanyInterface) {
    Object.assign(this, data);
  }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TeamInterface } from '@core/sdk/models/Team';
import environment from '@environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  constructor(
    private httpClient: HttpClient,
  ) { }

  assignPersonToTeam(teamId: string, userId: string): Observable<TeamInterface> {
    const url = `${environment.apiBaseUrl}/api/Teams/${teamId}/members/add`;

    if(!userId){
      return;
    }

    const body = {
      members: [userId]
    }

    return this.httpClient.post<TeamInterface>(`${url}`, body)
  }

  getLeadsAssignedToMyTeam(userId: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/Teams/members/${userId}/leads`;

    return this.httpClient.get(`${url}`)
  }

  getTeamsAssigned(userId: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/Teams/members/${userId}`;

    return this.httpClient.get(`${url}`)
  }

  createTeamLead(leadId: string): Observable<TeamInterface>{
    const url = `${environment.apiBaseUrl}/api/Teams/lead/${leadId}/add`;

    return this.httpClient.get<TeamInterface>(`${url}`)
  }

  removeMemberFromTeam(teamId: string, userId: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/Teams/${teamId}/members/delete`;
    const body = {
      member: [userId]
    }
    
    return this.httpClient.patch(`${url}`, body)
  }
}

import { Certification, DiscountItem } from '@core/sdk';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

export enum certificationActionsTypes {
  GetCertificationRequestStarted = '[Certification Api] GET_CERTIFICATION_REQUEST_STARTED',
  GetCertificationRequestSuccess = '[Certification Api] GET_CERTIFICATION_REQUEST_SUCCESS',
  AddCertification = '[Certification] ADD_CERTIFICATION',
  UpdateCertificationRequestStarted = '[Certification Api] UPDATE_CERTIFICATION_REQUEST_STARTED',
  UpdateCertificationRequestSuccess = '[Certification Api] UPDATE_CERTIFICATION_REQUEST_SUCCESS',
  UpdateCertification = '[Certification] UPDATE_CERTIFICATION',
  UpdateCertificationDiscounts = '[Certification] UPDATE_CERTIFICATION_DISCOUNTS',
  ClearEntities = '[Certification] CLEAR_CERTIFICATION_STORE',
  SendCertificationResquestStarted = '[Certification Api] SEND_CERTIFICATION_REQUEST_STARTED',
  SendCertificationRequestSuccess = '[Certification Api] SEND_CERTIFICATION_REQUEST_SUCCESS',
  SendCertification = '[Certification Api] SEND_CERTIFICATION',
  GetCertificationDiscountsRequestStarted = '[Certification Api] GET_CERTIFICATION_DISCOUNTS_REQUEST_STARTED',
  GetCertificationDiscountsRequestSuccess = '[Certification Api] GET_CERTIFICATION_DISCOUNTS_REQUEST_SUCCESS',
  CreateDiscountRequestStarted = '[Certification Api] CREATE_DISCOUNT_REQUEST_STARTED',
  CreateDiscountRequestSuccess = '[Certification Api] CREATE_DISCOUNT_REQUEST_SUCCESS',
  RemoveDiscountRequestStarted = '[Certification Api] REMOVE_DISCOUNT_REQUEST_STARTED',
  RemoveDiscountRequestSuccess = '[Certification Api] REMOVE_DISCOUNT_REQUEST_SUCCESS',
  RemoveCertificationDiscount = '[Certification Api] REMOVE_CERTIFICATION_DISCOUNT',
  AcceptCertificationRequestStarted = '[Certification Api] ACCEPT_CERTIFICATION_REQUEST_STARTED',
  AcceptCertificationRequestSuccess = '[Certification Api] ACCEPT_CERTIFICATION_REQUEST_SUCCESS',
  AcceptCertification = '[Certification Api] ACCEPT_CERTIFICATION',
  RejectCertificationRequestStarted = '[Certification Api] REJECT_CERTIFICATION_REQUEST_STARTED',
  RejectCertificationRequestSuccess = '[Certification Api] REJECT_CERTIFICATION_REQUEST_SUCCESS',
  RejectCertification = '[Certification Api] REJECT_CERTIFICATION',
  CreateLastCertificationRequestStarted = '[Certification Api] CREATE_LAST_CERTIFICATION_REQUEST_STARTED',
  CreateLastCertificationRequestSuccess = '[Certification Api] CREATE_LAST_CERTIFICATION_REQUEST_SUCCESS',
}

export const createLastCertificationRequestStarted = createAction(
  certificationActionsTypes.CreateLastCertificationRequestStarted,
  props<{ payload: string }>(),
);

export const createLastCertificationRequestSuccess = createAction(
  certificationActionsTypes.CreateLastCertificationRequestSuccess,
  props<{ payload: string }>(),
);

export const getCertificationRequestStarted = createAction(
  certificationActionsTypes.GetCertificationRequestStarted,
  props<{ payload: string }>(),
);

export const getCertificationRequestSuccess = createAction(
  certificationActionsTypes.GetCertificationRequestSuccess,
  props<{ payload: Certification }>(),
);

export const addCertification = createAction(
  certificationActionsTypes.AddCertification,
  props<{ payload: Certification }>(),
);

export const updateCertificationRequestStarted = createAction(
  certificationActionsTypes.UpdateCertificationRequestStarted,
  props<{ payload: Partial<Certification> }>(),
);

export const updateCertificationRequestSuccess = createAction(
  certificationActionsTypes.UpdateCertificationRequestSuccess,
  props<{ payload: Certification }>(),
);

export const updateCertification = createAction(
  certificationActionsTypes.UpdateCertification,
  props<Update<Certification>>(),
);

export const updateCertificationDiscounts = createAction(
  certificationActionsTypes.UpdateCertificationDiscounts,
  props<{ payload: DiscountItem }>(),
);

export const sendCertificationRequestStarted = createAction(
  certificationActionsTypes.SendCertificationResquestStarted,
  props<{ payload: string }>(),
);

export const sendCertificationRequestSuccess = createAction(
  certificationActionsTypes.SendCertificationRequestSuccess,
  props<Certification>(),
);

export const sendCertification = createAction(
  certificationActionsTypes.SendCertification,
  props<{ payload: Certification }>(),
);

export const getCertificationDiscountsRequestStarted = createAction(
  certificationActionsTypes.GetCertificationDiscountsRequestStarted,
  props<{ payload: string }>(),
);

export const getCertificationDiscountsRequestSuccess = createAction(
  certificationActionsTypes.GetCertificationDiscountsRequestSuccess,
  props<{ payload: DiscountItem[] }>(),
);

export const createDiscountRequestStarted = createAction(
  certificationActionsTypes.CreateDiscountRequestStarted,
  props<{ payload: string }>(),
);

export const createDiscountRequestSuccess = createAction(
  certificationActionsTypes.CreateDiscountRequestSuccess,
  props<{ payload: DiscountItem }>(),
);

export const removeDiscountRequestStarted = createAction(
  certificationActionsTypes.RemoveDiscountRequestStarted,
  props<{ certificationID: string; discountID: string }>(),
);

export const removeDiscountRequestSuccess = createAction(
  certificationActionsTypes.RemoveDiscountRequestSuccess,
  props<{ certificationID: string; discountID: string }>(),
);

export const removeCertificationDiscount = createAction(
  certificationActionsTypes.RemoveCertificationDiscount,
  props<{ certificationID: string; discountID: string }>(),
);

export const acceptCertificationRequestStarted = createAction(
  certificationActionsTypes.AcceptCertificationRequestStarted,
  props<{ payload: string }>(),
);

export const acceptCertificationRequestSuccess = createAction(
  certificationActionsTypes.AcceptCertificationRequestSuccess,
  props<Certification>(),
);

export const acceptCertification = createAction(
  certificationActionsTypes.AcceptCertification,
  props<{ payload: Certification }>(),
);

export const rejectCertificationRequestStarted = createAction(
  certificationActionsTypes.RejectCertificationRequestStarted,
  props<{ payload: string }>(),
);

export const rejectCertificationRequestSuccess = createAction(
  certificationActionsTypes.RejectCertificationRequestSuccess,
  props<Certification>(),
);

export const rejectCertification = createAction(
  certificationActionsTypes.RejectCertification,
  props<{ payload: Certification }>(),
);

export const clearEntities = createAction(
  certificationActionsTypes.ClearEntities,
);

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ROLES } from '@core/constants';
import { AgentInterface, UserInterface } from '@core/sdk';
import environment from '@environment';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  agentProfileStatus = false;
  subscribedToNotices = false;

  companyUsers$: Observable<any>;

  constructor(
    private httpClient: HttpClient,
    private store: Store<{ agent: AgentInterface, user: UserInterface }>,
  ) {}

  /**
   * It gets the agent ID from the user and then gets the clients for that agent.
   * @returns An Observable of an array of clients.
   */
  async switchAgent(leadsFilter, agentEmail) {
    const {companyId} = await this.store.select('agent').pipe(take(1)).toPromise();
    const url = `${environment.apiBaseUrl}/api/companies/${companyId}/switch`;

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(leadsFilter),
        agentEmail,
      }),
    };

    return this.httpClient.get<any>(url, httpOptions);
  }

  getCompanyAgents(companyId: string) {
    const url = `${environment.apiBaseUrl}/api/companies/${companyId}/agents`;

    return this.httpClient.get<any>(url);
  }

  getStates = async (role) => {
    const agent = await this.store.select('agent').toPromise();

    if (!agent) return [];

    const roles = agent.company?.roles;

    const { leadStates } = roles.find((r) => r.name === role);

    return leadStates !== undefined ? leadStates : [];
  };

  checkRouteAcess = async (role: string, accessedRoute: string) => {
    if (
      role !== ROLES.SALES ||
      role === ROLES.SUPERADMIN ||
      role === ROLES.EMPLOYEE ||
      role === ROLES.OWNER
    ) {
      return true;
    }

    const {roles} = await this.store.select('user').toPromise();

    if (!roles) return true;

    const allow = roles[0].routes.some((route: string) => accessedRoute.includes(route));

    return allow;
  };

  getCompanies() {
    const url = `${environment.apiBaseUrl}/api/companies`;

    return this.httpClient.get<any>(url);
  }

  getCompanyUsers(companyId:string) {
    this.companyUsers$ = this.getCurrentCompanyUsersInfo(companyId);
  }

  getCurrentCompanyUsersInfo(companyId: string) {
    const filter = {
      include: ['person'],
      order: 'updatedAt DESC',
    };

    const httpOptions = {
      headers: new HttpHeaders({
        filter: JSON.stringify(filter),
      }),
    };

    return this.httpClient.get<any[]>(
      `${environment.apiBaseUrl}/api/companies/${companyId}/users`,
      httpOptions,
    );
  }
}

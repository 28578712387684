import { BudgetItem, Chapter } from '@core/sdk';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

export enum chapterEditorActionsTypes {
  GetChapterFromBudgetIdRequestStarted = '[Chapter Api] GET_CHAPTER_FROM_BUDGET_ID_REQUEST_STARTED',
  GetChapterFromBudgetIdRequestSuccess = '[Chapter Api] GET_CHAPTER_FROM_BUDGET_ID_REQUEST_SUCCESS',
  GetChapterRequestStarted = '[Chapter Api] GET_CHAPTER_REQUEST_STARTED',
  GetChapterRequestSuccess = '[Chapter Api] GET_CHAPTER_REQUEST_SUCCESS',
  CreateBudgetChapterRequestStarted = '[Chapter Api] CREATE_BUDGET_CHAPTER_REQUEST_STARTED',
  CreateBudgetChapterRequestSuccess = '[Chapter Api] CREATE_BUDGET_CHAPTER_REQUEST_SUCCESS',
  CreateBudgetAnnexRequestStarted = '[Chapter Api] CREATE_BUDGET_ANNEX_REQUEST_STARTED',
  CreateBudgetAnnexRequestSuccess = '[Chapter Api] CREATE_BUDGET_ANNEX_REQUEST_SUCCESS',
  GetBudgetChaptersRequestStarted = '[Chapter Api] GET_BUDGET_CHAPTERS_REQUEST_STARTED',
  GetBudgetChaptersRequestSuccess = '[Chapter Api] GET_BUDGET_CHAPTERS_REQUEST_SUCCESS',
  DeleteChapterRequestStarted = '[Chapter Api] DELETE_CHAPTER_REQUEST_STARTED',
  DeleteChapterRequestSuccess = '[Chapter Api] DELETE_CHAPTER_REQUEST_SUCCESS',
  UpdateChapterRequestStarted = '[Chapter Api] UPDATE_CHAPTER_REQUEST_STARTED',
  UpdateChapterRequestSuccess = '[Chapter Api] UPDATE_CHAPTER_REQUEST_SUCCESS',
  SendAnnexRequestStarted = '[Chapter Api] SEND_ANNEX_REQUEST_STARTED',
  SendAnnexRequestSuccess = '[Chapter Api] SEND_ANNEX_REQUEST_SUCCESS',
  RejectAnnexRequestStarted = '[Chapter Api] REJECT_ANNEX_REQUEST_STARTED',
  RejectAnnexRequestSuccess = '[Chapter Api] REJECT_ANNEX_REQUEST_SUCCESS',
  AcceptAnnexRequestStarted = '[Chapter Api] ACCEPT_ANNEX_REQUEST_STARTED',
  AcceptAnnexRequestSuccess = '[Chapter Api] ACCEPT_ANNEX_REQUEST_SUCCESS',
  ShiftChapterRequestStarted = '[Chapter Api] SHIFT_CHAPTER_REQUEST_STARTED',
  ShiftChapterRequestSuccess = '[Chapter Api] SHIFT_CHAPTER_REQUEST_SUCCESS',
  AddChapter = '[Chapter Editor] ADD_CHAPTER',
  AddBudgetChapter = '[Chapter Editor] ADD_BUDGET_CHAPTER',
  AddChapters = '[Chapter Editor] ADD_CHAPTERS',
  UpdateChapter = '[Chapter Editor] UPDATE_CHAPTER',
  UpdateChapters = '[Chapter Editor] UPDATE_CHAPTERS',
  UpdateChapterBudgetItemList = '[Chapter Editor] UPDATE_CHAPTER_BUDGET_ITEM_LIST',
  UpdateChapterBudgetItemsList = '[Chapter Editor] UPDATE_CHAPTER_BUDGET_ITEMS_LIST',
  DeleteChapter = '[Chapter Editor] DELETE_CHAPTER',
  DeleteChapterBudgetItem = '[Chapter Editor] DELETE_CHAPTER_BUDGET_ITEM',
  ClearEntities = '[Chapter Editor] CLEAR_CHAPTER_STORE',
}

export const getChapterFromBudgetIdRequestStarted = createAction(
  chapterEditorActionsTypes.GetChapterFromBudgetIdRequestStarted,
  props<{ payload: string }>(),
);

export const getChapterFromBudgetIdRequestSuccess = createAction(
  chapterEditorActionsTypes.GetChapterFromBudgetIdRequestSuccess,
  props<{ payload: Chapter[] }>(),
);

export const getChapterRequestStarted = createAction(
  chapterEditorActionsTypes.GetChapterRequestStarted,
  props<{ payload: string }>(),
);

export const getChapterRequestSuccess = createAction(
  chapterEditorActionsTypes.GetChapterRequestSuccess,
  props<{ payload: Chapter }>(),
);

export const createBudgetAnnexRequestStarted = createAction(
  chapterEditorActionsTypes.CreateBudgetAnnexRequestStarted,
  props<{
    budgetId: string;
  }>(),
);

export const createBudgetAnnexRequestSuccess = createAction(
  chapterEditorActionsTypes.CreateBudgetAnnexRequestSuccess,
  props<{ payload: Chapter }>(),
);

export const createBudgetChapterRequestStarted = createAction(
  chapterEditorActionsTypes.CreateBudgetChapterRequestStarted,
  props<{
    budgetId: string;
    chapter: Partial<Chapter>;
  }>(),
);

export const createBudgetChapterRequestSuccess = createAction(
  chapterEditorActionsTypes.CreateBudgetChapterRequestSuccess,
  props<{ payload: Chapter }>(),
);

export const getBudgetChaptersRequestStarted = createAction(
  chapterEditorActionsTypes.GetBudgetChaptersRequestStarted,
  props<{ payload: string }>(),
);

export const getBudgetChaptersRequestSuccess = createAction(
  chapterEditorActionsTypes.GetBudgetChaptersRequestSuccess,
  props<{ payload: Chapter[] }>(),
);

export const addBudgetChapter = createAction(
  chapterEditorActionsTypes.AddBudgetChapter,
  props<{ payload: Chapter }>(),
);

export const addChapter = createAction(
  chapterEditorActionsTypes.AddChapter,
  props<{ payload: Chapter }>(),
);

export const addChapters = createAction(
  chapterEditorActionsTypes.AddChapters,
  props<{ payload: Chapter[] }>(),
);

export const shiftChapterRequestStarted = createAction(
  chapterEditorActionsTypes.ShiftChapterRequestStarted,
  props<{
    chapterId: string;
    budgetId: string;
    shift: number;
  }>(),
);

export const shiftChapterRequestSuccess = createAction(
  chapterEditorActionsTypes.ShiftChapterRequestSuccess,
  props<any>(),
);

export const updateChapter = createAction(
  chapterEditorActionsTypes.UpdateChapter,
  props<{ payload: Update<Chapter> }>(),
);

export const updateChapters = createAction(
  chapterEditorActionsTypes.UpdateChapters,
  props<{ payload: Update<Chapter>[] }>(),
);

export const deleteChapterRequestStarted = createAction(
  chapterEditorActionsTypes.DeleteChapterRequestStarted,
  props<{ payload: string }>(),
);

export const deleteChapterRequestSuccess = createAction(
  chapterEditorActionsTypes.DeleteChapterRequestSuccess,
  props<{ payload: string }>(),
);

export const updateChapterRequestStarted = createAction(
  chapterEditorActionsTypes.UpdateChapterRequestStarted,
  props<{ payload: Partial<Chapter> }>(),
);

export const updateChapterRequestSuccess = createAction(
  chapterEditorActionsTypes.UpdateChapterRequestSuccess,
  props<{ payload: Chapter }>(),
);

export const sendAnnexRequestStarted = createAction(
  chapterEditorActionsTypes.SendAnnexRequestStarted,
);

export const sendAnnexRequestSuccess = createAction(
  chapterEditorActionsTypes.SendAnnexRequestSuccess,
  props<{ payload: Chapter }>(),
);

export const acceptAnnexRequestStarted = createAction(
  chapterEditorActionsTypes.AcceptAnnexRequestStarted,
);

export const acceptAnnexRequestSuccess = createAction(
  chapterEditorActionsTypes.AcceptAnnexRequestSuccess,
  props<{ payload: Chapter }>(),
);

export const rejectAnnexRequestStarted = createAction(
  chapterEditorActionsTypes.RejectAnnexRequestStarted,
);

export const rejectAnnexRequestSuccess = createAction(
  chapterEditorActionsTypes.RejectAnnexRequestSuccess,
  props<{ payload: Chapter }>(),
);

export const deleteChapter = createAction(
  chapterEditorActionsTypes.DeleteChapter,
  props<{ payload: string }>(),
);

export const updateChapterBudgetItemList = createAction(
  chapterEditorActionsTypes.UpdateChapterBudgetItemList,
  props<{ chapterId: string; budgetItemId: string; budgetItemOrder: number }>(),
);

export const updateChapterBudgetItemsList = createAction(
  chapterEditorActionsTypes.UpdateChapterBudgetItemsList,
  props<{ payload: Partial<BudgetItem>[] }>(),
);

export const deleteChapterBudgetItem = createAction(
  chapterEditorActionsTypes.DeleteChapterBudgetItem,
  props<{
    budgetItemId: string;
    chapterId: string;
  }>(),
);

export const clearEntities = createAction(
  chapterEditorActionsTypes.ClearEntities,
);

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import environment from '../../../environments/environment';
import { LoginInterface } from '../../shared/interfaces';
import { LocalStorageService } from './local-storage.service';
import {
  tap,
  shareReplay,
  switchMap,
  catchError,
  map,
  filter,
  withLatestFrom,
  take,
} from 'rxjs/operators';
import * as moment from 'moment';
import { Person, Agent, Client, Lead, UserInterface } from '../sdk';
import { Observable, throwError, ReplaySubject } from 'rxjs';
import { Router, Event, NavigationEnd } from '@angular/router';
import { SessionStorageService } from './session-storage.service';
// import { NotificationsCenterService } from './notifications-center.service';
import { Logout } from 'src/app/store/actions/meta.action';
import { Store } from '@ngrx/store';
import { QuizResponse } from '@shared/interfaces/quiz.interface';
import { ContextAddOrUpdateAction, ContextUpdateProjectAction } from 'src/app/store/context/context.actions';
import { AgentService } from './agent.service';
import { ClientService } from './client.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from './notifications.service';
import { LoaderService } from './loader.service';
import { MixpanelService } from './mixpanel.service';
import * as LogRocket from 'logrocket';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NoticeModalComponent } from '@shared/dialogs/notice-modal/notice-modal.component';
import { ResponsiveService } from './responsive.service';
import {
  AgentAddOrUpdateFieldAction,
  AgentRemoveAction,
} from 'src/app/store/actions/agent.actions';
import {
  ClientAddOrUpdateFieldAction,
  ClientRemoveAction,
} from 'src/app/store/actions/client.actions';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { getProjectRequestStarted } from 'src/app/store/actions/project.actions';
import { GoogleAnalyticsService } from './google-analytics.service';

declare function sendFacebookPixel(registerType): any;
declare function pintrk(action, event): any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private timeToLogout: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _quizResponse: QuizResponse;
  private b2b = false;
  private webViewMode: boolean = false

  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private router: Router,
    private store: Store<{ agent: Agent; context: any, user: UserInterface }>,
    private agentService: AgentService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private mixpanelService: MixpanelService,
    private localStorage: LocalStorageService,
    private clientService: ClientService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private loaderService: LoaderService,
    private dialog: MatDialog,
    private responsiveService: ResponsiveService
  ) {}

  /**
   * The function is subscribing to the router events and mapping the url after redirects to the store.
   * The function is also subscribing to the store and mapping the current context to the url.
   *  - If the url is /login or /login?redirectUrl, then the current context is login.
   *  - If the url is /register, then the current context is register.
   *  - If the url is /quiz/on-boarding, then the current context is on-boarding-quiz.
   *  - If the url is /quiz/calculator, then the current context is calculator-quiz.
   *  - If the url is /client/projects, then the current context is client.
   *  - If the url is /logged-client/projects, then the current context is login-client.
   *  - If the url is /client, then the current context is client.
   */
  selectContext() {
    this.router.events
      .pipe(
        filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects),
        withLatestFrom(this.store.select('context')),
      )
      .subscribe(async ([url, { value: currentContext }]) => {
        const newContext = await this.mapUrlToContext(url);

        if (!newContext) return;

        if (newContext !== currentContext) {
          this.store.dispatch(ContextAddOrUpdateAction({ value: newContext }));
        }
      });
  }

  async mapUrlToContext(url: string): Promise<string> {
    if (url.includes('/login')) {
      return 'login';
    }

    if (url.endsWith('/register')) {
      return 'register';
    }

    if (/^(\/quiz\/on-boarding)/gm.test(url)) {
      return 'on-boarding-quiz';
    }

    if (/^(\/quiz\/calculator)/gm.test(url)) {
      return 'calculator-quiz';
    }

    if (/^(\/client\/projects)/gm.test(url) || /^(\/client\/budgets)/gm.test(url)) {
        return 'client';
    }

    // Anonymous client. Do not remove
    if (/^(\/client)/gm.test(url)) {
        this.cleanLocalStorage();
        this.cleanSessionStorage();
        return 'client';
    }

    return await this.getUserContext();
  }

  /**
   * Get the context state from the store
   * @returns The context state.
   */
  // TODO: TO REMOVE
  getContext() {
    return this.store.select('context');
  }

  /**
   * Save the agent login info in the local storage
   * @param {string} email - The email of the user.
   * @param {any} loginInfo - any
   */
  private saveAgentLoginInfo(email: string, loginInfo: any) {
    this.localStorageService.set('token', loginInfo.token);
    this.localStorageService.set(
      'expire',
      this.expireAt(loginInfo.created, loginInfo.ttl),
    );
    this.localStorageService.set('userId', loginInfo.userId);
    this.localStorageService.set('oneSignalUserID', loginInfo.oneSignalUserId);
    this.localStorageService.set('agentId', loginInfo.agentId);
    this.localStorageService.set('userEmail', email);

    this.store.dispatch(ContextAddOrUpdateAction({ value: 'agent' }));

    if (loginInfo.notificationsQueue && loginInfo.notificationsQueue.id) {
      this.localStorageService.set(
        'notificationsQueueID',
        loginInfo.notificationsQueue.id,
      );

      return;
    }
  }

  /**
   * Save the client login info in the local storage
   * @param {string} email - The email of the user.
   * @param {any} loginInfo - The login info object that is returned from the server.
   */
  private saveClientLoginInfo(email: string, loginInfo: any) {
    this.localStorageService.set('token', loginInfo.token);
    this.localStorageService.set(
      'expire',
      this.expireAt(loginInfo.created, loginInfo.ttl),
    );
    this.localStorageService.set('userId', loginInfo.userId);
    this.localStorageService.set('clientId', loginInfo.clientId);
    this.localStorageService.set('oneSignalUserID', loginInfo.oneSignalUserId);
    this.localStorageService.set('userEmail', email);

    this.store.dispatch(ContextAddOrUpdateAction({ value: 'login-client' }));

    if (loginInfo.notificationsQueue && loginInfo.notificationsQueue.id) {
      this.localStorageService.set(
        'notificationsQueueID',
        loginInfo.notificationsQueue.id,
      );

      return;
    }
  }

  /**
   * It redirects the user to the dashboard page.
   * @param {string} [redirectUrl=null] - string = null
   */
  redirectUser(redirectUrl: string = null) {
    const user = this.localStorageService.get('user');
    if (user.userType === 'client') {
      this.redirectClient(this.localStorageService.get('clientId'), redirectUrl);
    } else {
      this.redirectAgent(this.localStorageService.get('agentId'), redirectUrl);
    }
    return;
  }

  /**
   * It redirects the user to the dashboard page.
   * @param {string} agentID - string, redirectUrl: string = null
   * @param {string} [redirectUrl=null] - string = null
   */
  private redirectAgent(agentID: string, redirectUrl: string = null) {
    this.agentService
      .getAgent(agentID)
      .subscribe((result: [Agent, any]) => {
        const [agent] = result;

        this.googleAnalyticsService.eventEmitter('agent-logged-in', 'agent');

        this.loaderService.disable();

        this.router.navigate(['events']);
      });
  }

  /**
   * It removes the current agent from the store and redirects to the new agent.
   * @param {string} agentID - The ID of the agent to impersonate.
   * @param [markAsImpersonated=true] - boolean
   */
  impersonateAgent(agentID: string, markAsImpersonated = true) {
    this.store.dispatch(ClientRemoveAction({}));

    this.store
      .select('agent')
      .pipe(take(1))
      .subscribe((agent) => {
        const oldAgentID = agent.id;

        this.redirectAgent(agentID);
        this.store.dispatch(
          AgentAddOrUpdateFieldAction({
            field: 'impersonated',
            value: markAsImpersonated,
          }),
        );

        this.store.dispatch(
          AgentAddOrUpdateFieldAction({
            field: 'fromOldAgent',
            value: oldAgentID,
          }),
        );
      });
  }

  /**
   * * Impersonate a client by removing the current agent from the store and redirecting to the
   * client's page
   * @param {string} clientID - The ID of the client to impersonate.
   */
  impersonateClient(clientID: string) {
    this.store.dispatch(AgentRemoveAction({}));

    this.redirectClient(clientID);
    this.store.dispatch(
      ClientAddOrUpdateFieldAction({
        field: 'impersonated',
        value: true,
      }),
    );
  }

  /**
   * The function takes a client ID and a redirect URL.
   * If the redirect URL is not null, it will redirect the user to the URL.
   * If the redirect URL is null, it will redirect the user to the first project of the client.
   * If the client has no projects, it will redirect the user to the projects page
   * @param {string} clientID - string, redirectUrl: string = null
   * @param {string} [redirectUrl=null] - string = null
   */
  private redirectClient(clientID: string, redirectUrl: string = null) {
    this.clientService
      .getClientById(clientID)
      .pipe(
        switchMap((client) => {
          this.b2b = client.b2b ||false;
          this.mixpanelService.register({
            ...client,
          });

          return this.clientService.getClientLeads(client)
        }),
      )
      .subscribe((projects : Lead[]) => {
        this.googleAnalyticsService.eventEmitter('client-logged-in', 'client');
        this.mixpanelService.eventEmitter('client-logged-in', {
          clientID: clientID,
        });

        if (!projects?.length || this.b2b) {
          this.router.navigate(['logged-client', 'projects']);

          return;
        }
        
        const project = projects.find((project) => project.tracking && !project.trackingLeadId) ?? projects[0]; //TODO: TrackingLeadId is not being used anymore, remove it from the condition

        this.loaderService.disable();


        if (redirectUrl) {
          if (redirectUrl.includes('/client/')) {
            const [, , , ...redirectUrlRest] = redirectUrl.split('/');

            this.router.navigate(['logged-client', ...redirectUrlRest]);

            return;
          }

          if (!redirectUrl.includes('logged-client')) {
            this.router.navigate(['logged-client', 'project', project.id]);

            return;
          }

          this.router.navigate([redirectUrl]);

          return;
        }

        this.responsiveService.mode
            .pipe(take(1))
            .subscribe(viewMode => {
              this.webViewMode = viewMode === 'web';
            })

        if (project.tracking) {
          if (!this.webViewMode) {
              this.store.dispatch(getProjectRequestStarted({projectId: project.id}));
              this.store.dispatch(ContextUpdateProjectAction({currentProjectId: project.id}));
              this.router.navigate(['home']);
              
              return;
            }
            
            this.router.navigate(['logged-client', 'tracking', project.id]);
            
            return;
          }
          
          if(!this.webViewMode && !this.router.url.includes('/quiz/on-boarding')){
            this.store.dispatch(getProjectRequestStarted({projectId: project.id}));
            this.store.dispatch(ContextUpdateProjectAction({currentProjectId: project.id}));
            this.router.navigate(['home']);

          return;
        }

        this.router.navigate(['logged-client', 'project', project.id]);
      });
  }

  private handleAgentLogin(email: string, loginInfo: any) {
    if (environment.integration || environment.production) {
      LogRocket.identify(loginInfo.userId, {
        email: loginInfo.email,
      });
    }

    this.saveAgentLoginInfo(email, loginInfo);
    this.redirectAgent(loginInfo.agentId, loginInfo.redirectUrl);
  }

  private handleLoginClientLogin(email: string, loginInfo: any) {
    if (environment.integration || environment.production) {
      LogRocket.identify(loginInfo.userId, {
        email: loginInfo.email,
      });
    }

    this.saveClientLoginInfo(email, loginInfo);

    if (loginInfo.isAnonymous) {
      this.redirectToNewPassword(loginInfo);

      return;
    }
    this.redirectClient(loginInfo.clientId, loginInfo.redirectUrl);
  }

  redirectToNewPassword(loginInfo) {
    this.router.navigate([`new-password/${loginInfo.newPasswordToken}/${loginInfo.email}`], loginInfo);

    return;
  }

  continueAnonymousClientLogin(clientId: string) {
    this.redirectClient(clientId);
  }

  handleLoginInfo(loginInfo: any) {
    if (loginInfo === 'Action:Logout') {
      this.translateService
        .get('platform-access-blocked')
        .subscribe((errorMessage: string) => {
          this.notificationsService.showError(errorMessage);
        });
    }

    if (loginInfo.userType === 'agent') {
      return this.handleAgentLogin(loginInfo.email, loginInfo);
    }

    return this.handleLoginClientLogin(loginInfo.email, loginInfo);
  }

  login(email: string, password: string, remember?: boolean) {
    this.cleanSessionStorage();
    this.cleanLocalStorage();

    const isRemember = remember ?? false;

    return this.httpClient
      .post<LoginInterface>(`${environment.apiBaseUrl}/api/People/login`, {
        email,
        password,
        isRemember,
      })
      .pipe(
        map((loginInfo: any) => {
          return {
            ...loginInfo,
            email,
          };
        }),
        tap((loginInfo) => this.mixpanelService.identify(loginInfo.userId)),
        shareReplay(),
      );
  }

  isLogged() {
    if (moment().isBefore(this.getExpiration())) {
      return true;
    }

    this.cleanLocalStorage();
    this.cleanSessionStorage();

    return false;
  }

  setLogoutLogic() {
    return this.timeToLogout.asObservable();
  }

  logout() {
    this.timeToLogout.next({ result: true });
    this.dialog.closeAll();
    this.store.dispatch(new Logout());

    this.mixpanelService.reset();

    this.httpClient
      .post<any>(`${environment.apiBaseUrl}/api/People/logout`, {})
      .subscribe(
        () => {
          this.cleanLocalStorage();
          this.cleanSessionStorage();

          this.router.navigate(['login']);
        },
        () => {
          this.cleanLocalStorage();
          this.cleanSessionStorage();
          this.router.navigate(['login']);
        },
      );
  }

  cleanLocalStorage() {
    this.localStorageService.removeAll();
  }

  cleanSessionStorage() {
    this.sessionStorage.removeAll();
  }

  private registerExternalAgent(person, utmSource, isFacebookLead) {
    return this.httpClient
    // 1 - Create the Agent
      .post<Agent>(`${environment.apiBaseUrl}/api/Agents`, {
        contact: {
          email: person.email,
          phoneNumber: person.phoneNumber,
        },
        address: {
          zipCode: person.address.zipCode,
        },
      })
      // 2 - Create the person
      .pipe(
        switchMap((agent: Agent) =>
          this.httpClient.post<Person>(`${environment.apiBaseUrl}/api/People`, {
            ...person,
            agentId: agent.id,
            utms: utmSource,
          }),
        ),
        switchMap(() => {
          environment.production && isFacebookLead && sendFacebookPixel('LeadProfesional');
          
          this.googleAnalyticsService.eventEmitter('agent-registered', 'agent');

          // 3 - Login the new agent
          return this.login(person.email, person.password);
        }),
        tap(() =>
          this.localStorageService.set(
            'agentModelActive',
            '{"active":true,"description":"fresh register"}',
          ),
        ),
        catchError((error) => throwError(error)),
      );
  }

  private registerCubicupAgent(person, utmSource: any, isFacebookLead) {
    return this.httpClient
      .post<Person>(`${environment.apiBaseUrl}/api/People`, {
        ...person,
        utms: utmSource,
      })
      .pipe(
        switchMap(() => {
          if (environment.production && isFacebookLead) {
            sendFacebookPixel('LeadProfesional');
          }

          return this.login(person.email, person.password);
        }),
        tap(() =>
          this.localStorageService.set(
            'agentModelActive',
            '{"active":true,"description":"fresh register"}',
          ),
        ),
        catchError((error) => throwError(error)),
      );
  }

  register(
    person: Person,
    isExternal: boolean,
    utmSource: any,
    isFacebookLead: boolean,
  ): Observable<any> {
    this.cleanLocalStorage();
    this.cleanSessionStorage();

    if (isExternal) {
      return this.registerExternalAgent(person, utmSource, isFacebookLead);
    }

    return this.registerCubicupAgent(person, utmSource, isFacebookLead);
  }

  private expireAt(created: string | Date, ttl: number) {
    return moment(created).add(ttl, 'second').valueOf();
  }

  private getExpiration() {
    return moment(this.localStorageService.get('expire'));
  }

  isAuthenticated() {
    const result = this.localStorageService.get('token');

    return !!result;
  }

  async getUserContext(): Promise<string> {
    const context = await this.store
      .select('context')
      .pipe(take(1))
      .toPromise();

    return context.value;
  }

  getUserEmail() {
    return this.localStorageService.get('userEmail');
  }

  changePersonPassword(token, userEmail, newPassword) {
    const url = `${environment.apiBaseUrl}/api/People/getChange-password`;

    return this.httpClient.post<any>(url, {
      token,
      userEmail,
      newPassword,
    });
  }

  changePersonLogguedPassword(userEmail, newPassword) {
    const url = `${environment.apiBaseUrl}/api/People/change-loggued-password`;

    return this.httpClient.post<any>(url, {
      userEmail,
      newPassword,
    });
  }

  setPersonPassword(userEmail) {
    const url = `${environment.apiBaseUrl}/api/People/forget-password`;

    return this.httpClient.post<any>(url, {
      userEmail,
    });
  }

  registerClient(person: Person, utmSource: any, isFacebookLead: boolean) {
    const clientAddress = { ...person.address };
    const personCopy = { ...person };

    delete personCopy.address;

    const quiz = this.localStorageService.get('quizAnswers');
    const budgetRangeEmptyQuestion = {
      questionId: "question4",
      questionLabel: "¿Cuál es tu presupuesto?",
      questionCode: "range",
      type: "radio",
      responseFor: [],
      responseLabel: "No lo tengo decidido",
      products: []
    };

    quiz['on-boarding'].questions.splice(4, 0, budgetRangeEmptyQuestion);

    return this.httpClient
      .post<Client>(`${environment.apiBaseUrl}/api/Clients/registerClient`, {
        contact: {
          email: person.email,
          phoneNumber: person.phoneNumber,
          name: person.name,
        },
        utms: utmSource,
        address: clientAddress,
        quiz
      })
      .pipe(
        switchMap((client: Client) =>
          this.httpClient.post<Person>(`${environment.apiBaseUrl}/api/People`, {
            ...personCopy,
            clientId: client.id,
            utms: utmSource,
            personType: 'client',
          }),
        ),
        tap(() => {
          this.localStorageService.set(
            'clientModelActive',
            '{"active":true,"description":"fresh register"}',
          );

          if (environment.production && isFacebookLead) {
            sendFacebookPixel('CompleteRegistration');
            pintrk('track', 'signup');
          }
          this.googleAnalyticsService.eventEmitter(
            'client-registered',
            'client',
          );

        }),
        switchMap(() => this.login(person.email, person.password)),
        tap((loginInfo) => {
          this.mixpanelService.identify(loginInfo.userId);
          this.mixpanelService.setPeople({
            $email: person.email, // only reserved properties need the $
            registerDate: new Date(), // Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
            personId: loginInfo.userId, // use human-readable names
            clientType: 'logged', // ...or numbers
          });

          this.mixpanelService.eventEmitter('client-registered', {});
        }),
        catchError((error) => throwError(error)),
      );
  }

  async setQuizResponse(quizResponse: QuizResponse, quizToLoad) {
    this._quizResponse = quizResponse;

    const quizAnswers = this.localStorageService.get('quizAnswers') || {};

    const questions = await Promise.all(quizResponse.questions.map(async question => {
      const translatedQuestion = {...question, questionLabel: await this.translateService.get(question.questionLabel).toPromise()};
      if (_.isObject(question.responseLabel) || _.isNil(question.responseLabel)) return translatedQuestion;
      return {...translatedQuestion, responseLabel: await this.translateService.get(question.responseLabel).toPromise()};
    }));

    quizAnswers[quizToLoad] = {...quizResponse, questions};
    quizAnswers.quizToLoadCode = quizToLoad;

    this.localStorageService.set('quizAnswers', quizAnswers);
  }

  get quizResponse() {
    return this._quizResponse;
  }

  sendQuizResponse(clientId: string) {
    return this.httpClient.post<QuizResponse>(
      `${environment.apiBaseUrl}/api/QuizAnswers`,
      {
        ...this.quizResponse,
        clientId,
      },
    );
  }

  private userIsWithOpenDialog() {
    return this.dialog.openDialogs.length > 0;
  }

  private userIsInReadOrWriteView() {
    const { url: currentURL } = this.router;

    return (
      currentURL.includes('/budget/') ||
      currentURL.includes('/budget-edition/') ||
      currentURL.includes('/budget-edition/') ||
      currentURL.includes('/project/') ||
      currentURL.includes('/events/') ||
      currentURL.includes('/certification/') ||
      currentURL.includes('/tracking/') ||
      currentURL.includes('/templates/products/') ||
      currentURL.includes('/templates/chapters/')
    );
  }

  startPendingNoticesSubscription() {
    if (!environment.subscriptions.notices.enabled) {
      return;
    }

    this.store
      .select('context')
      .pipe(take(1))
      .subscribe(({ value: context }) => {
        if (context === 'agent') {
          this.agentService
            .startPendingNoticesSubscription()
            .subscribe((notices) => {
              if (
                this.userIsInReadOrWriteView() ||
                this.userIsWithOpenDialog()
              ) {
                return;
              }
              this.handleNoticeModals(notices);
            });
        }

        if (context === 'login-client') {
          this.clientService
            .startPendingNoticesSubscription()
            .subscribe((notices) => {
              if (
                this.userIsInReadOrWriteView() ||
                this.userIsWithOpenDialog()
              ) {
                return;
              }
              this.handleNoticeModals(notices);
            });
        }
      });
  }

  private upsertNoticeIntoLocalStorage(notice, lastShow = null) {
    const noticeModals = this.localStorage.get('noticeModals');
    const modalToInsert = {};

    if (!noticeModals) {
      modalToInsert[notice.id] = { ...notice, lastShow };
      this.localStorage.set('noticeModals', modalToInsert);

      return;
    }

    const oldNotice = noticeModals[notice.id];

    if (oldNotice) {
      return;
    }

    noticeModals[notice.id] = { ...notice, lastShow };
    this.localStorage.set('noticeModals', noticeModals);
  }

  private validateNoticeFrequency(noticeID: string) {
    const noticesModals = this.localStorage.get('noticeModals');
    const { lastShow, dueDate, frequency } = noticesModals[noticeID];
    const noticeDate = lastShow
      ? DateTime.fromJSDate(new Date(lastShow))
      : null;
    const noticeDueDate = DateTime.fromISO(dueDate);
    const todayDate = DateTime.fromJSDate(new Date());

    switch (frequency) {
      case 'dueDate':
      case 'oncePerDay':
        if (!lastShow) {
          return true;
        }

        if (!dueDate || (dueDate && noticeDueDate < todayDate)) {
          return;
        }

        if (
          todayDate.startOf('month') > noticeDate.startOf('month') ||
          noticeDate.hasSame(todayDate, 'month')
        ) {
          if (todayDate.startOf('day') > noticeDate.startOf('day')) {
            return true;
          }

          return;
        }

        break;
      case 'oncePerWeek':
        if (!lastShow) {
          return true;
        }

        if (!dueDate || (dueDate && noticeDueDate < todayDate)) {
          return;
        }

        if (todayDate.startOf('week') > noticeDate.startOf('week')) {
          return true;
        }

        break;

      default:
        return true;
    }
  }

  private handleNoticeModals(notices) {
    if (!notices || (notices && notices.length === 0)) {
      return;
    }

    // Show priorities
    const basedInRead = [];
    const basedInCompleted = [];
    const basedInDueDate = [];
    const basedInFrequency = [];

    notices.forEach((notice) => {
      const { id, showCondition, frequency, targetType } = notice;

      switch (frequency) {
        case 'read':
          basedInRead.push(notice);
          break;
        case 'completed':
          basedInCompleted.push(notice);
          break;
        case 'dueDate':
          basedInDueDate.push(notice);
          this.upsertNoticeIntoLocalStorage(notice);
          break;
        case 'oncePerDay':
        case 'oncePerWeek':
          this.upsertNoticeIntoLocalStorage(notice);
          basedInFrequency.push(notice);

          break;
        default:
          break;
      }
    });

    const reorderedNotices = [
      ...basedInCompleted,
      ...basedInDueDate,
      ...basedInFrequency,
      ...basedInRead,
    ];

    reorderedNotices.forEach((notice, index) => {
      const {
        id,
        showCondition,
        frequency,
        targetType,
        showConditionModelType,
      } = notice;

      if (frequency !== 'read' && !this.validateNoticeFrequency(id)) {
        return;
      }

      // Add delay between notices
      setTimeout(() => {
        if (this.userIsInReadOrWriteView()) {
          return;
        }

        if (showCondition) {
          if (typeof showCondition !== 'object') {
            return;
          }

          const showConditionString = JSON.stringify(showCondition);

          if (
            showConditionString.includes('===') ||
            showConditionString.includes('<') ||
            showConditionString.includes('>') ||
            showConditionString.includes('<=') ||
            showConditionString.includes('>=') ||
            showConditionString.includes('!==') ||
            showConditionString.includes('const') ||
            showConditionString.includes('let') ||
            showConditionString.includes('var')
          ) {
            return;
          }

          if (targetType.toLowerCase() === 'agent') {
            this.agentService
              .evalNoticeModalCondition(showCondition, showConditionModelType)
              .subscribe((result) => {
                if (!result) {
                  return;
                }

                this.openNotice(notice);
              });
          }

          if (targetType.toLowerCase() === 'client') {
            this.clientService
              .evalNoticeModalCondition(showCondition, showConditionModelType)
              .subscribe((result) => {
                if (!result) {
                  return;
                }

                this.openNotice(notice);
              });
          }

          return;
        }

        this.openNotice(notice);
      }, 60000 * index);
    });
  }

  private upsertNotice(notice) {
    const noticeModals = this.localStorage.get('noticeModals');
    const modalToInsert = {};

    if (!noticeModals) {
      modalToInsert[notice.id] = { ...notice };
      this.localStorage.set('noticeModals', modalToInsert);

      return;
    }

    noticeModals[notice.id] = { ...notice };
    this.localStorage.set('noticeModals', noticeModals);
  }

  openNotice(notice) {
    if (!notice) {
      return;
    }

    const {
      title,
      titleHTML,
      subtitle,
      subtitleHTML,
      ctaLabel,
      ctaUrl,
      cancelLabel,
      image,
      icon,
      id,
      showCondition,
      frequency,
      targetType,
      lastShow,
    } = notice;

    let desktopHeight = '320px';

    if (image) {
      desktopHeight = '550px';
    }

    this.responsiveService.mode
      .pipe(take(1), withLatestFrom(this.store.select('context').pipe(take(1))))
      .subscribe(([viewMode, { value: context }]) => {
        const modalConfig = {
          width: '650px',
          height: viewMode !== 'web' ? '100vh' : desktopHeight,
          maxWidth: '100vw',
          data: {
            title,
            titleHTML,
            subtitle,
            subtitleHTML,
            icon,
            ctaLabel,
            ctaUrl: context === 'agent' ? ctaUrl : `logged-client/${ctaUrl}`,
            cancelLabel,
            image,
            showCancelButton: true,
            showCloseButton: false,
          },
        };

        setTimeout(() => {
          const dialogRef = this.dialog.open(NoticeModalComponent, modalConfig);

          dialogRef.afterClosed().subscribe(() => {
            if (!id) {
              return;
            }

            if (!lastShow) {
              this.upsertNotice({ ...notice, lastShow: new Date() });
            }

            if (context === 'agent') {
              this.agentService.markNoticeAsRead(id).subscribe();
              return;
            }

            if (context === 'login-client') {
              this.clientService.markNoticeAsRead(id).subscribe();
              return;
            }
          });
        }, 1000);
      });
  }
}

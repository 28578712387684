import { Injectable } from '@angular/core';
import { Budget } from '@core/sdk';
import { BudgetService } from '@core/services/budget.service';
import { Observable } from 'rxjs';

@Injectable()
export class BudgetEditionApiService {
  constructor(private budgetService: BudgetService) {}

  getBudget(budgetID: string): Observable<Budget> {
    const filter = [
      {
        relation: 'chapters',
        scope: {
          fields: { id: true, order: true },
          order: 'order ASC',
        },
      },
      {
        relation: 'documents',
        scope: {
          include: 'versions',
        },
      },
    ];

    return this.budgetService.getBudget(budgetID, filter);
  }

  updateBudget(update: Partial<Budget>) {
    return this.budgetService.updateBudget(update.id, update);
  }
}
